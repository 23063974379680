import projectManagerMessages from '../../../../common/app/projectManagerMessages';
import webActions from '../../../webActions';
import { Section, Field } from './projectManagerConfig';


const { projectDetails, displaySettings, featuresConfigurations, clientServerConnectivity } = projectManagerMessages.sections;

const {
	companyLogo,
	companyName,
	enableDistributionList,
	hideChecklistTitleOnIssueDesc,
	projectImage,
	projectMembers,
	projectTimeZone,
	projectLanguage,
	projectAddress,
	projectCreationTs,
	projectCountry,
	projectName,
	projectRegion,
	showOnlyWhenAdmin,
	showSafetyTab,
	showQualityTab,
	showDailyReportTab,
	archiveProject,
	showFloorInReverseOrder,
  showSpecTab,
  customerName,
  includeInQACompanyViewLabel,
  includeInSafetyCompanyViewLabel,
  enableGeneralReportLabel,
  activateDrawings,
  showDrawingsTab, 
  isDrawingsInV2
} = projectManagerMessages.inputLabels;

// Intermediate object for values coming from async functions
const specialValues = {
	countries: {
    fetching: false,
		values: [{ id: null, title: '-' }],
	},
};

async function getCountries() {
	if (!specialValues.countries.fetching) {
		specialValues.countries.fetching = true;

		let resp = await webActions.net.fetch('https://restcountries.eu/rest/v2/all?fields=name;nativeName', {
			method: 'GET',
			headers: {},
		});

		let data = await resp.json();

		if (data && data.length > 0)
      specialValues.countries.values = data.map(country => ({
				id: country.name,
				title: country.name + ' (' + country.nativeName + ')',
			}));
	}
}

///////////////////////////////////////////////////////
/////////////////////// Fields ////////////////////////
///////////////////////////////////////////////////////

const companyNameField                   = new Field(companyName, 'String', ['projectDetails', 'company', 'title'])

const projectNameField                   = new Field(projectName, 'String', ['projectDetails', 'title'])

const showSpecTabField                   = new Field(showSpecTab, 'Boolean', ['configurations', 'features', 'spec', 'isActive'])

const projectImageField                  = new Field(projectImage, 'FilesDropzone', ['projectDetails', 'images', 'main'], null, { picsOnly: true })

const showSafetyTabField                 = new Field(showSafetyTab, 'Boolean', ['configurations', 'features', 'safety', 'isActive'])

const showQualityTabField                 = new Field(showQualityTab, 'Boolean', ['configurations', 'features', 'quality', 'isActive'], null, {}, true)

const enableGeneralReport                = new Field(enableGeneralReportLabel, 'Boolean', ['configurations', 'features', 'safety', 'generalReport', 'isActive'])

const projectAddressField                = new Field(projectAddress, 'String', ['projectDetails', 'address'])

const projectRegionField                 = new Field(projectRegion, 'String', ['projectDetails', 'region'])

const projectStartingTsField             = new Field(projectCreationTs, 'Date', ['projectDetails', 'createdAt'], null, { editable: false, adminOnly: true })

const showOnlyWhenAdminField             = new Field(showOnlyWhenAdmin, 'Boolean', ['projectDetails', 'showOnAdmin'])

const isArchived            						 = new Field(archiveProject, 'Boolean', ['configurations', 'metadata', 'isArchived'], null, {}, false)

const includeInSafetyCompanyView     		 = new Field(includeInSafetyCompanyViewLabel, 'Boolean', ['configurations', 'features', 'companyView', 'safety', 'isActive'], null, {}, false)

const includeInQACompanyView     			   = new Field(includeInQACompanyViewLabel, 'Boolean', ['configurations', 'features', 'companyView', 'qa', 'isActive'], null, {}, false)

const isDrawingsActive =	new Field(activateDrawings, 'Boolean', ['configurations', 'features', 'drawings', 'isActive'], null, {}, false);

const showDrawingsTabField = new Field(showDrawingsTab, 'Boolean', ['configurations', 'features', 'drawings', 'web', 'isVisible'], null, {}, false);

const projectDrawingsVersion = new Field(isDrawingsInV2, 'Boolean', ['configurations', 'features', 'drawings', 'v2', 'isActive'], null, {"disabled": true}, false)

const isFloorReverse            				 = new Field(showFloorInReverseOrder, 'Boolean', ['configurations', 'mobileUIParams', 'reverseFloorOrder'], null, {}, false)

const showDailyReportTabField            = new Field(showDailyReportTab, 'Boolean', ['configurations', 'features', 'dailyReport', 'isActive'], null, {}, true)

const enableDistributionListField        = new Field(enableDistributionList, 'Boolean', ['configurations', 'checklists', 'enableDistributionList'])

const hideChecklistTitleOnIssueDescField = new Field(hideChecklistTitleOnIssueDesc, 'Boolean', ['configurations', 'checklists', 'hideChecklistTitleOnIssueDesc'], null, { tooltip: { title: 'Hey, I\'m a Tooltip! I don\'t do much for now, but soon enough I\'ll give you info!' }, adminOnly: true })

const projectLanguageField               = new Field(projectLanguage, 'SelectionList', ['projectDetails', 'lang'], [ { id: 'en', title: 'English' }, { id: 'es', title: 'Spanish' }, { id: 'he', title: 'Hebrew' }], { adminOnly: true })

const projectTimeZoneField               = new Field(projectTimeZone, 'SelectionList', ['projectDetails', 'tzLocation'], [{ id: 'Asia/Jerusalem', title: 'Asia/Jerusalem' } ], { adminOnly: true })

const companyLogoField                   = new Field(companyLogo, 'FilesDropzone', ['projectDetails', 'company', 'logo'], null, {picsOnly: true})

const projectCountryField                = new Field(projectCountry, 'SelectionList', ['projectDetails', 'country'], null, { adminOnly: true });

const projectCustomerNameField           = new Field(customerName, 'SelectionList', ['projectDetails', 'companyId'], [], { adminOnly: true });

const serverConnectivityListnersTooltip = { title: `Whether the client should listen to changes in data from the server - If set to "Fetch from server", the client will receive only a notification that it should fetch the data. - If set to "Receive data", the client will receive the updated data as part of the notification.` }
const serverConnectivityListnersValues = [{ id: 'disabled', title: 'Disabled' }, { id: 'full', title: 'Receive data' }, { id: 'envelope', title: 'Fetch from server' }];
// const checklistsListener                 = new Field('Checklists listener', 'SelectionList', ['configurations', 'clientMS', 'V2', 'listeners', 'checklists'], serverConnectivityListnersValues, { tooltip: serverConnectivityListnersTooltip, adminOnly: true }, false);
// const configurationsListener             = new Field('Configurations listener', 'SelectionList', ['configurations', 'clientMS', 'V2', 'listeners', 'configurations'], serverConnectivityListnersValues, { tooltip: serverConnectivityListnersTooltip, adminOnly: true }, false);
const checklistInstancesListener         = new Field('Checklist instances listener', 'SelectionList', ['configurations', 'clientMS', 'V2', 'listeners', 'checklistInstances'], serverConnectivityListnersValues, { tooltip: serverConnectivityListnersTooltip, adminOnly: true }, false);
const employeesListener                  = new Field('Employees listener', 'SelectionList', ['configurations', 'clientMS', 'V2', 'listeners', 'employees'], serverConnectivityListnersValues, { tooltip: serverConnectivityListnersTooltip, adminOnly: true }, false);
const equipmentListener                  = new Field('Equipment listener', 'SelectionList', ['configurations', 'clientMS', 'V2', 'listeners', 'equipment'], serverConnectivityListnersValues, { tooltip: serverConnectivityListnersTooltip, adminOnly: true }, false);
const propertiesInstancesListener        = new Field('Properties instances listener', 'SelectionList', ['configurations', 'clientMS', 'V2', 'listeners', 'propertiesInstances'], serverConnectivityListnersValues, { tooltip: serverConnectivityListnersTooltip, adminOnly: true }, false);
const postsListener                      = new Field('Posts listener', 'SelectionList', ['configurations', 'clientMS', 'V2', 'listeners', 'posts'], serverConnectivityListnersValues, { tooltip: serverConnectivityListnersTooltip, adminOnly: true }, false);
// const propertiesListener                 = new Field('Properties types listener', 'SelectionList', ['configurations', 'clientMS', 'V2', 'listeners', 'properties'], serverConnectivityListnersValues, { tooltip: serverConnectivityListnersTooltip, adminOnly: true }, false);
// const propertiesMappingsListener         = new Field('Properties mappings listener', 'SelectionList', ['configurations', 'clientMS', 'V2', 'listeners', 'propertiesMappings'], serverConnectivityListnersValues, { tooltip: serverConnectivityListnersTooltip, adminOnly: true }, false);

const serverConnectivityBulkTooltip = { title: `Whether the client should fetch bulk data from the server (if true, will fetch from the server)` }
const checklistsStagesBulk               = new Field('Checklist stages bulk', 'Boolean', ['configurations', 'clientMS', 'V2', 'bulk', 'stages', 'isActive'], null, { tooltip: serverConnectivityBulkTooltip, adminOnly: true }, false);
const checklistsBulk                     = new Field('Checklists bulk', 'Boolean', ['configurations', 'clientMS', 'V2', 'bulk', 'checklists', 'isActive'], null, { tooltip: serverConnectivityBulkTooltip, adminOnly: true }, false);
const checklistsItemsBulk                = new Field('Checklist items bulk', 'Boolean', ['configurations', 'clientMS', 'V2', 'bulk', 'checklistItems', 'isActive'], null, { tooltip: serverConnectivityBulkTooltip, adminOnly: true }, false);
const configurationsBulk                 = new Field('Configurations bulk', 'Boolean', ['configurations', 'clientMS', 'V2', 'bulk', 'configurations', 'isActive'], null, { tooltip: serverConnectivityBulkTooltip, adminOnly: true }, false);
const drawingsBulk                       = new Field('Drawings bulk', 'Boolean', ['configurations', 'clientMS', 'V2', 'bulk', 'drawings', 'isActive'], null, { tooltip: serverConnectivityBulkTooltip, adminOnly: true }, false);
const buildingsBulk                      = new Field('Buildings bulk', 'Boolean', ['configurations', 'clientMS', 'V2', 'bulk', 'buildings', 'isActive'], null, { tooltip: serverConnectivityBulkTooltip, adminOnly: true }, false);
const floorsBulk                         = new Field('Floors bulk', 'Boolean', ['configurations', 'clientMS', 'V2', 'bulk', 'floors', 'isActive'], null, { tooltip: serverConnectivityBulkTooltip, adminOnly: true }, false);
const unitsBulk                          = new Field('Units bulk', 'Boolean', ['configurations', 'clientMS', 'V2', 'bulk', 'units', 'isActive'], null, { tooltip: serverConnectivityBulkTooltip, adminOnly: true }, false);
const permissionsBulk                    = new Field('Permissions bulk', 'Boolean', ['configurations', 'clientMS', 'V2', 'bulk', 'permissions', 'isActive'], null, { tooltip: serverConnectivityBulkTooltip, adminOnly: true }, false);
const physicalIdsBulk                    = new Field('PhysicalIds bulk', 'Boolean', ['configurations', 'clientMS', 'V2', 'bulk', 'physicalIds', 'isActive'], null, { tooltip: serverConnectivityBulkTooltip, adminOnly: true }, false);
const quasiStaticsBulk                   = new Field('Quasi Statics bulk', 'Boolean', ['configurations', 'clientMS', 'V2', 'bulk', 'quasiStatics', 'isActive'], null, { tooltip: serverConnectivityBulkTooltip, adminOnly: true }, false);
const titlesBulk                       	 = new Field('Titles bulk', 'Boolean', ['configurations', 'clientMS', 'V2', 'bulk', 'titles', 'isActive'], null, { tooltip: serverConnectivityBulkTooltip, adminOnly: true }, false);
const tradesBulk                         = new Field('Trades bulk', 'Boolean', ['configurations', 'clientMS', 'V2', 'bulk', 'trades', 'isActive'], null, { tooltip: serverConnectivityBulkTooltip, adminOnly: true }, false);
const propertiesBulk                     = new Field('Properties types bulk', 'Boolean', ['configurations', 'clientMS', 'V2', 'bulk', 'properties', 'isActive'], null, { tooltip: serverConnectivityBulkTooltip, adminOnly: true }, false);
const propertiesMappingsBulk             = new Field('Properties mappings bulk', 'Boolean', ['configurations', 'clientMS', 'V2', 'bulk', 'propertiesMappings', 'isActive'], null, { tooltip: serverConnectivityBulkTooltip, adminOnly: true }, false);

///////////////////////////////////////////////////////
///////////////////// Sections ////////////////////////
///////////////////////////////////////////////////////

const projectDetailsSection = new Section(projectDetails, [
  projectNameField,
  projectAddressField,
  projectRegionField,
  projectImageField,
  projectStartingTsField,
  projectCustomerNameField,
  companyNameField,
  companyLogoField,
]);

const displaySettingsSection = new Section(displaySettings, [
  projectCountryField,
  projectTimeZoneField,
	projectLanguageField,
	isFloorReverse,	
]);

const featuresConfigurationsSection = new Section(featuresConfigurations, [
  showSpecTabField,
	showSafetyTabField,
	showQualityTabField,
  enableGeneralReport,
  showOnlyWhenAdminField,
  showDailyReportTabField,
  enableDistributionListField,
	hideChecklistTitleOnIssueDescField,
  isArchived,
  includeInSafetyCompanyView,
  includeInQACompanyView,
  isDrawingsActive,
  showDrawingsTabField,
  projectDrawingsVersion
], { adminOnly: true });




const clientServerConnectivityListenersSection = new Section(clientServerConnectivity.listeners, [
	// configurationsListener,
	// checklistsListener,
	checklistInstancesListener,
	employeesListener,
	equipmentListener,
	propertiesInstancesListener,
	postsListener,
	// propertiesListener,
	// propertiesMappingsListener,
], { adminOnly: true });

const clientServerConnectivityBulkSection = new Section(clientServerConnectivity.bulk, [
	checklistsStagesBulk,
	checklistsBulk,
	checklistsItemsBulk,
	configurationsBulk,
	buildingsBulk,
	floorsBulk,
	unitsBulk,
	propertiesBulk,
	propertiesMappingsBulk,
	titlesBulk,
	tradesBulk,
	drawingsBulk,
	permissionsBulk,
	physicalIdsBulk,
	quasiStaticsBulk,
], { adminOnly: true });






export default [
  projectDetailsSection,
  featuresConfigurationsSection,
  displaySettingsSection,
	clientServerConnectivityListenersSection,
	clientServerConnectivityBulkSection,
];