import PropertySelectionValue from '../../../common/propertiesTypes/propertySelectionValue';
import PropertySection from '../../../common/propertiesTypes/propertySection';
import Property from '../../../common/propertiesTypes/property';
import * as propertyTypes from '../../../common/propertiesTypes/propertiesTypes';
import { validatePropType } from '../../../common/propertiesTypes/funcs';
import _ from 'lodash';
import { lokiInstance } from '../../../common/configureMiddleware';
import { getLocationTitle } from '../Locations/funcs';

function parseTrueDefaultBoolean(val = true) {
  let ret;
  try {
    ret = JSON.parse(String(val).toLowerCase());
  }
  catch (e) { }
  finally {
    ret = _.isBoolean(ret) ? ret : true;
    return ret;
  }
}

export function convertCSVtoSectionsArray(csv, selectedProjectId, lang, getNewId) {
  let sections = [];
  let lines = csv;
  let prevSectionId = null;
  let prevIndependentPropIndex = null;
  let errors = [];
  if (lines.length > 0) lines.forEach((splitCurrLine, lineIndex) => {
    if (splitCurrLine.length < 8 || lineIndex === 0) return null;

    let obj = {
      externalId: splitCurrLine[0],
      type: splitCurrLine[1],
      title: splitCurrLine[2],
      values: Boolean(!splitCurrLine[3] || splitCurrLine[3].trim() == "") ? null : splitCurrLine[3].split(','),
      isGroups: Boolean(splitCurrLine[4]),
      showOnNullValue: Boolean(splitCurrLine[5]),
      hideOnMobile: Boolean(splitCurrLine[6]),
      universalId: splitCurrLine[7],
      isExtra: Boolean(splitCurrLine[8]),
      editable: parseTrueDefaultBoolean(splitCurrLine[9]),
      defaultContentType: splitCurrLine[10],
      businessType: splitCurrLine[11],
      isMulti: splitCurrLine[12]
    };

    if (obj.type == -1) {
      let newId = getNewId(selectedProjectId, 'locationsInfo', true).payload.id;
      let newSection = new PropertySection({
        id: newId,
        title: { [lang]: obj.title }
      });
      prevSectionId = newId;
      sections.push({
        parent: newSection,
        childs: []
      });
    }
    else {
      let newId = obj.isGroups ? 'groups' : getNewId(selectedProjectId, 'locationsInfo').payload.id;

      if (!validatePropType(obj))
        errors.push(`Property ${obj.title} was found with an invalid property type "${obj.type}"`);

      let newProperty = new Property({
        id: newId,
        externalId: obj.externalId,
        isExtra: obj.isExtra,
        editable: obj.editable,
        sectionId: prevSectionId,
        defaultContentType: obj.defaultContentType,
        businessType: obj.businessType,
        type: obj.type,
        mandatory: obj.isGroups,
        title: { [lang]: obj.title },
        hideOnMobile: obj.hideOnMobile,
        showOnNullValue: obj.showOnNullValue,
        universalId: Boolean(obj.universalId.trim()) ? obj.universalId.trim() : null,
        values: obj.values && Array.isArray(obj.values) ? obj.values.map(v => new PropertySelectionValue({
          id: getNewId(selectedProjectId, 'locationsInfo').payload.id,
          title: { [lang]: v },
        })) : null
      });

      if (obj.isGroups) {
        newProperty = newProperty.setNested('mandatory', true);
        newProperty = newProperty.setNested('hideOnMobile', true);
      }

      if (obj.isMulti)
        newProperty = newProperty.setNested('settings', { isMulti: obj.isMulti });
      if (obj.universalId == 'isActive') {
        newProperty = newProperty.setNested('settings', { defaultVal: true });
        newProperty = newProperty.setNested('hideOnMobile', true);
      }
      if (obj.type == propertyTypes.CERTIFICATION)
        newProperty = newProperty.setNested('settings', { isExpiration: true, isWarning: true });

      if (obj.isExtra) {
        let lastIndependent = sections[sections.length - 1].childs[prevIndependentPropIndex];
        let extraTypes = lastIndependent.extraTypes || [];
        extraTypes.push(newProperty.id);
        lastIndependent = lastIndependent.setNested('extraTypes', extraTypes);
        sections[sections.length - 1].childs[prevIndependentPropIndex] = lastIndependent;
      }

      sections[sections.length - 1].childs.push(newProperty);
      if (!obj.isExtra)
        prevIndependentPropIndex = sections[sections.length - 1].childs.length - 1;
    }
  });

  return { sections, errors };
}

export function getObjectsTitlesMap(props, subjectName) {
  let objectsMap = {}
  if (subjectName === 'companiesInfo') {
    Object.values(props.projectCompanies || {}).forEach(v => objectsMap[v.id] = { id: v.id, title: v.name });
  }
  else if (subjectName != 'locationsInfo') {
    let objectsType = subjectName.indexOf('Info') != -1 ? subjectName.replace('Info','') : subjectName;
    let objects = lokiInstance?.getCollection(objectsType)?.cementoFind({'projectId' : props.selectedProjectId }) || [];
    objects.forEach(object => {
      objectsMap[object.id] = { id: object.id, title: object.name };
      if (subjectName === 'postsInfo')
        _.set(objectsMap, [object.id, 'trade'], object.trade);
    })
  }
  else {
    let locationsTitlesMap = {};
    let isMultiBuilding = props.buildings.size > 1;
    let unitsIdsMapByNum = {};
    let index = 0;
    props.buildings.loopEach(bid => {
      let buildingLocationTitle = getLocationTitle(props.buildings, props.floors, props.units, bid, null, null, null, props.intl, false);
      locationsTitlesMap[bid] = { id: bid, title: buildingLocationTitle.buildingTitle, ordinalNo: index++}
      props.getNested(['floors',bid],{}).loopEach((fid, curr) => {
        unitsIdsMapByNum[curr.num] = fid;
        let floorLocationTitle = getLocationTitle(props.buildings, props.floors, props.units, bid, fid, null, null, props.intl, false);
        let title = isMultiBuilding ? floorLocationTitle.buildingTitle + ' \\ ' : '';
        title = title + floorLocationTitle.floorTitle;
        locationsTitlesMap[fid] = { id: fid, title: title, ordinalNo: index++}
      })
      props.getNested(['units',bid],{}).loopEach((uid, curr) => {
        let unitLocationTitle = getLocationTitle(props.buildings, props.floors, props.units, bid, unitsIdsMapByNum.getNested([curr.getNested(['floor','num'])]), uid, null, props.intl, false);
        let title = isMultiBuilding ? unitLocationTitle.buildingTitle + ' \\ ' : '';
        title = title + unitLocationTitle.floorTitle + ' \\ ' + unitLocationTitle.unitTitle
        locationsTitlesMap[uid] = { id: uid, title: title, ordinalNo: index++}
      })
    })
    objectsMap = locationsTitlesMap;
  }
  return objectsMap;
}

export function getGroupsInstances(selectedProjectId, objectsMap = {}, allGroupsArray, lokiPropertyInstances) {
  if (!selectedProjectId || !objectsMap || (allGroupsArray || []).length === 0)
    return {};
    
  let allGroupsIds = (allGroupsArray || []).map(g => g.id);
  let groupsInstancesMap = {};
  allGroupsIds.forEach(gid => groupsInstancesMap[gid] = { ...objectsMap });
  lokiPropertyInstances.forEach(curr => {
    allGroupsIds.forEach(gid => {
      groupsInstancesMap[gid][curr.parentId] = { id: curr.parentId, ...(objectsMap[curr.parentId] || {}), instance: curr, checked: Boolean(curr.data && curr.data[gid])}
    });
  });
  return groupsInstancesMap;
}

export function getGroupsInstancesMapByParentId(groupsInstancesMap) {
  let instancesMapByParentId = {};
    // groupsInstancesMap contains [groupId](הקבוצה של דרור, הקבוצה של דרור 2) : locations
    groupsInstancesMap.loopEach((groupId, allInstances) => {
      allInstances.loopEach((parentId, info) => {
        if (info.instance) {
          instancesMapByParentId[parentId] = { ...info.instance, data: instancesMapByParentId[parentId] ? instancesMapByParentId[parentId].data : null }
          if (info.checked) {
            instancesMapByParentId[parentId] = instancesMapByParentId[parentId].setNested(['data', groupId], groupId);
          } else {
            if (instancesMapByParentId[parentId].data && instancesMapByParentId[parentId].data[groupId])
              delete instancesMapByParentId[parentId].data[groupId];
            if (instancesMapByParentId[parentId].data && Object.keys(instancesMapByParentId[parentId].data).length == 0)
              instancesMapByParentId[parentId].data = null;
          }
        }
        else if (info.checked) {
          instancesMapByParentId[parentId] = { data: {[groupId]:groupId}, subjectName: 'locationsInfo', propType:'SelectionList', propId: 'groups', parentId: parentId }
        }
      })
    })
  return instancesMapByParentId;
}

export function getDefaultScopeId(scope, projectDetails) {
  let defaultScopeId;

  switch (scope) {
    case "companies":
      defaultScopeId = projectDetails.getNested(["companyId"]);
      break;

    default:
      defaultScopeId = projectDetails.getNested(["id"]);
      break;
  }
  return defaultScopeId;
};
