import React from "react";
import { connect } from "react-redux";
import { compose, hoistStatics } from "recompose";
import { connectContext } from "react-connect-context";
import { ProjectContext } from "../../../common/projects/contexts";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import MenuScrollbar from "../../components/CementoComponents/MenuScrollbar";
import { saveURLParams, clearAllUrlParams } from "../../../common/ui/actions";
import {
  getUserProjects,
  endUserProjectsListener,
} from "../../../common/projects/actions";
import {
  getLastUpdatesForProjects,
  getLastUpdatesByViewer,
} from "../../../common/lastUpdates/actions";
import { startLoading } from "../../../common/app/actions";
import projectsMessages from "../../../common/projects/projectsMessages";
import defaultProject from "../../assets/img/defaultProject.png";
import theme from "../../assets/css/theme";
import Text from "../../components/CementoComponents/Text";
import toggleDown from "../../assets/img/icons/toggleDown.png";
import companiesMessages from "../../../common/companies/companiesMessages";
import { navigateToProject } from "../../../common/projects/funcs";
import _ from "lodash";

class ProjectsSelector extends React.Component {
  constructor(props) {
    super(props);
    this.setComponentData = this.setComponentData.bind(this);
    this.onProjectSelect = this.onProjectSelect.bind(this);
    this.onCompanySelect = this.onCompanySelect.bind(this);
    this.state = {
      viewersCompany: null,
      isCompanyViewPermitted: false,
      scope: null,
      selectedCompany: null,
    };
  }

  componentDidMount() {
    const { selectedProjectId } = this.props;
    if (selectedProjectId) this.navigateToProject(selectedProjectId, false);
    this.endHistoryListenerFunction = this.props.history.listen(this.handleUrlChange);
  }

  componentWillUnmount() {
    const { endUserProjectsListener, viewer } = this.props;
    if (viewer) endUserProjectsListener(viewer);
    this.endHistoryListenerFunction();
  }

  UNSAFE_componentWillMount() {
    
    this.setComponentData({ firstMount: true }, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  handleUrlChange = (location, action) => {
    const storedUrlParams = this.props.urlParams;
    const storedUrl = storedUrlParams.get('containerUrl') || "";

    if ((location.pathname.localeCompare(storedUrl) !== 0) && (action === "PUSH")) {
      let newURLParams = Object.fromEntries(storedUrlParams);
      newURLParams.containerUrl = location.pathname;

      this.props.saveURLParams(newURLParams);
    }
  }

  async setComponentData(props, nextProps) {
    const { projects, viewer, selectedProjectId, location } = props;
    let newStateChanges = {};

    let differentViewer = Boolean(
      _.get(props, ["viewer", "id"]) != _.get(nextProps, ["viewer", "id"])
    );
    let differentViewerMode = Boolean(
      _.get(props, ["viewer", "adminMode"]) !=
        _.get(nextProps, ["viewer", "adminMode"])
    );
    let prevProjects = props.getNested(["viewerProjects"]);
    let nextProjects = nextProps.getNested(["viewerProjects"]);
    let prevViewersProjectsCompanies = [];
    (prevProjects || []).forEach((p) =>
      prevViewersProjectsCompanies.push(p.companyId)
    );
    prevViewersProjectsCompanies = _.uniq(
      prevViewersProjectsCompanies.filter(_.identity)
    ).sort();
    let nextViewersProjectsCompanies = [];
    (nextProjects || []).forEach((p) =>
      nextViewersProjectsCompanies.push(p.companyId)
    );
    nextViewersProjectsCompanies = _.uniq(
      nextViewersProjectsCompanies.filter(_.identity)
    ).sort();
    const isAdminMode = !!nextProps.getNested(["viewer", "adminMode"], false);

    if (nextProps.viewer && (differentViewer || differentViewerMode)) {
      nextProps.getUserProjects(nextProps.viewer, nextProps.viewer.adminMode);
      isAdminMode
        ? nextProps.getLastUpdatesForProjects()
        : nextProps.getLastUpdatesByViewer(nextProps.viewer);
    }

    if (
      nextProps.viewer &&
      nextProps.companies &&
      (differentViewer ||
        differentViewerMode ||
        _.difference(prevViewersProjectsCompanies, nextViewersProjectsCompanies)
          .length ||
        !_.isEqual(
          props.companiesConfigurations,
          nextProps.companiesConfigurations
        ) ||
        !_.isEqual(props.companies, nextProps.companies))
    ) {
      newStateChanges.viewersCompanies = {};
      nextViewersProjectsCompanies.forEach((companyId) => {
        let isCompanyViewPermitted =
          nextProps.companiesConfigurations.getNested([
            companyId,
            "features",
            "companyView",
          ]);
        let company = nextProps.getNested(["companies", companyId]);
        if (
          company &&
          (isAdminMode ||
            (isCompanyViewPermitted &&
              !newStateChanges.viewersCompanies[companyId]))
        ) {
          newStateChanges.viewersCompanies[companyId] = company.toJS
            ? company.toJS()
            : company;
        }
      });
    }

    if (props.isValDiff(nextProps, ["urlParams", "scope"]))
      newStateChanges.scope = nextProps.getNested(
        ["urlParams", "scope"],
        "project"
      );

    if (props.isValDiff(nextProps, ["urlParams", "selectedCompanyId"])) {
      let selectedCompanyId = nextProps.getNested([
        "urlParams",
        "selectedCompanyId",
      ]);
      newStateChanges.selectedCompany = nextProps.getNested([
        "companies",
        selectedCompanyId,
      ]);
    }

    let scope = newStateChanges.scope || this.state.scope;
    if (
      (scope != "company" &&
        nextProps.projects &&
        projects != nextProps.projects) ||
      selectedProjectId != nextProps.selectedProjectId
    ) {
      newStateChanges.viewerProjects = nextProps.viewerProjects.safeToJS();
      if (
        !_.isEmpty(newStateChanges.viewerProjects) &&
        (!nextProps.selectedProjectId ||
          nextProps.selectedProjectId == "_" ||
          props.firstMount) &&
        !(
          location &&
          location.pathname &&
          location.pathname.includes("main/membersManager")
        )
      ) {
        newStateChanges.selectedProjectId = this.navigateToProject(
          nextProps.getNested(["lastVisitedProject", "id"]) ||
            _.values(newStateChanges.getNested(["viewerProjects"]))[0].id,
          !nextProps.selectedProjectId || nextProps.selectedProjectId == "_",
          nextProps,
          props.firstMount
        );
      }
    }

    if (Object.keys(newStateChanges).length > 0) this.setState(newStateChanges);
  }

  onProjectSelect(selectedProjectId) {
    this.navigateToProject(selectedProjectId, true, null, null, true);
    this.setState({ selectedProjectId });
  }

  onCompanySelect(selectedCompany) {
    this.navigateToCompany(selectedCompany);
    this.setState({ selectedProjectId: null, isSelected: false });
  }

  navigateToCompany(selectedCompanyId) {
    const { saveURLParams, clearAllUrlParams, history } = this.props;
    clearAllUrlParams();
    let redirectToUrl = `/main/companyContainerPage/${
      selectedCompanyId || "_"
    }/_`;
    let newUrlParams = {
      selectedCompanyId,
      containerUrl: redirectToUrl,
      scope: "company",
    };

    saveURLParams(newUrlParams);
    history.push(redirectToUrl);
  }

  navigateToProject(
    projectId,
    navigate,
    nextProps,
    firstMount,
    userSelectAction
  ) {
    const { saveURLParams, location } = nextProps || this.props;
    if (
      !projectId ||
      (!userSelectAction && location.pathname.includes("companyContainerPage"))
    ) {
      return null;
    }

    if (navigate) {
      let overwriteURL =
        (!firstMount && userSelectAction) ||
        location.pathname.length <= "/main/projectContainerPage/".length
          ? "/main/projectContainerPage/" + projectId + "/_"
          : location.getNested(["pathname"], "") +
            location.getNested(["search"], "") +
            location.getNested(["hash"], "");
      navigateToProject(this.props, projectId, null, overwriteURL);
    } else saveURLParams({ selectedProjectId: projectId, scope: "project" });

    return projectId;
  }

  render() {
		const { viewer, rtl, selectedProjectId, urlParams } = this.props;
		const { viewersCompanies, scope, selectedCompany, viewerProjects } = this.state;
    const currUrl = urlParams.get('containerUrl') || "";
		const shouldHideProjectSelector = ['propertiesManager', 'projectManager/checklistManagement', 'formsManager'].some((page) => currUrl.includes(page));
    
		return (
			<div style={{ height: 60 }}>
				{!shouldHideProjectSelector && (
					<MySelect
						isSearchable={true}
						selectedProjectId={selectedProjectId}
						selectedCompany={scope == 'company' ? selectedCompany : null}
						onProjectSelect={this.onProjectSelect}
						onCompanySelect={this.onCompanySelect}
						projects={viewerProjects}
						companies={viewersCompanies}
						viewer={viewer}
						rtl={rtl}
					/>
				)}
			</div>
		);
	}
}

ProjectsSelector = withRouter(ProjectsSelector);
const enhance = compose(
  connectContext(ProjectContext.Consumer),
  connect(
    (state) => ({
      lastVisitedProject: state.users.lastVisitedProject,
      companies: state.companies.map,
      companiesConfigurations: state.configurations.companiesMap,
      urlParams: state.ui.urlParams,
      viewerProjects: state.projects.map,
      viewer: state.users.viewer,
      url: state.ui
    }),
    {
      startLoading,
      saveURLParams,
      clearAllUrlParams,
      getUserProjects,
      getLastUpdatesForProjects,
      getLastUpdatesByViewer,
      endUserProjectsListener,
    }
  )
);
export default enhance(ProjectsSelector);

const ITEM_HEIGHT = 60;
class MySelect extends React.Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      isOpen: false,
      filter: "",
    };
  }

  handleClose() {
    this.setState({ isOpen: false, filter: "" });
  }

  render() {
    const {
      projects,
      selectedProjectId,
      onProjectSelect,
      rtl,
      intl,
      viewer = {},
      companies = {},
      onCompanySelect,
      selectedCompany,
      isCompanyViewPermitted,
    } = this.props;
    const { isOpen, filter } = this.state;
    const menuWidth = theme.sidebarWidth;
    if (_.isEmpty(projects)) return null;

    const projectsObject = projects;
    let projectsArray = Object.values(projectsObject || {}).filter(
      (x) =>
        !filter ||
        filter == "" ||
        (x.title &&
          x.title.toLowerCase().indexOf((filter || "").toLowerCase()) != -1) ||
        (x.address &&
          x.address.toLowerCase().indexOf((filter || "").toLowerCase()) !=
            -1) ||
        ((viewer || {}).adminMode &&
          x.id &&
          x.id.toLowerCase().indexOf((filter || "").toLowerCase()) != -1)
    );
    projectsArray = projectsArray.sort((a, b) =>
      (a.title || a.address || "").localeCompare(b.title || b.address || "")
    );
    let companiesArray = _.values(companies);
    companiesArray = companiesArray.filter(
      (x) =>
        !filter ||
        filter == "" ||
        (x.name &&
          x.name.toLowerCase().indexOf((filter || "").toLowerCase()) != -1) ||
        x.address ||
        ((viewer || {}).adminMode &&
          x.id &&
          x.id.toLowerCase().indexOf((filter || "").toLowerCase()) != -1)
    );
    companiesArray = companiesArray.sort(
      (a, b) => a.name && b.name && a.name.localeCompare(b.name)
    );

    const closedComponent = (
      <Item
        key={selectedCompany ? selectedCompany.id : selectedProjectId}
        scopeId={selectedCompany ? selectedCompany.id : selectedProjectId}
        title={
          selectedCompany
            ? selectedCompany.name
            : projectsObject.getNested(
                [selectedProjectId, "title"],
                projectsObject.getNested([selectedProjectId, "address"])
              )
        }
        img={
          selectedCompany
            ? selectedCompany.darkLogo || selectedCompany.logo
            : projectsObject.getNested(
                [selectedProjectId, "images", "main"],
                defaultProject
              )
        }
        onClick={() => this.setState({ isOpen: true })}
        isHeader={true}
        rtl={rtl}
        imageStyle={{ objectFit: "contain", objectPosition: "center" }}
      />
    );

    return (
      <>
        {Boolean(isOpen) && (
          <div
            onClick={this.handleClose}
            style={{
              zIndex: theme.zIndexes.projectSelectBackdrop,
              top: 0,
              bottom: theme.headerHeight,
              right: 0,
              left: 0,
              height: theme.headerHeight,
              width: "100wh",
              position: "absolute",
            }}
          />
        )}
        {Boolean(isOpen) && (
          <div
            onClick={this.handleClose}
            style={{
              zIndex: theme.zIndexes.projectSelectBackdrop,
              top: theme.headerHeight,
              bottom: 0,
              right: 0,
              left: 0,
              height: "calc(100vh - " + theme.headerHeight + "px)",
              width: "100wh",
              position: "absolute",
              backgroundColor: "black",
              opacity: 0.55,
            }}
          />
        )}

        <div
          style={{ backgroundColor: theme.headerColorDark, width: menuWidth }}
        >
          {Boolean(!isOpen) ? (
            closedComponent
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100vh",
                position: "absolute",
                zIndex: theme.zIndexes.projectSelector,
                backgroundColor: theme.headerColorDark,
              }}
            >
              <div
                style={{
                  direction: rtl ? "rtl" : "ltr",
                  display: "flex",
                  height: ITEM_HEIGHT,
                  alignItems: "center",
                  justifyContent: "flex-start",
                  padding: theme.margin,
                }}
              >
                <input
                  type="text"
                  autoFocus={true}
                  placeholder={intl.formatMessage(
                    projectsMessages.searchProject
                  )}
                  style={{ color: theme.backgroundColorBright }}
                  value={filter}
                  onChange={(e) => this.setState({ filter: e.target.value })}
                />
              </div>
              <div
                style={{
                  height: "calc(100vh - " + theme.headerHeight + "px)",
                  overflowY: "auto",
                  overflowX: "hidden",
                  direction: rtl ? "rtl" : "ltr",
                  width: menuWidth,
                }}
              >
                {Boolean(
                  projectsArray.length == 0 && companiesArray.length == 0
                ) ? (
                  <div style={{ direction: rtl ? "rtl" : "ltr" }}>
                    <Text
                      style={{
                        margin: theme.margin,
                        fontSize: 14,
                        color: theme.backgroundColorBright,
                      }}
                    >
                      {projectsMessages.noProjectsFound}
                    </Text>
                  </div>
                ) : (
                  <MenuScrollbar
                    verticalStyle={{
                      left: rtl ? 0 : null,
                      right: rtl ? null : 0,
                    }}
                  >
                    {Boolean(companiesArray.length) && (
                      <>
                        {companiesArray.map((currCompany, currCompanyIndex) => (
                          <div
                            key={`company_${currCompanyIndex}`}
                            style={{
                              direction: rtl ? "rtl" : "ltr",
                              borderBottom: 1,
                              borderBottomColor: theme.darkSeparator,
                              borderBottomStyle: "solid",
                              width: menuWidth,
                            }}
                          >
                            <Item
                              key={currCompany.id}
                              title={currCompany.name}
                              subtitle={
                                selectedCompany &&
                                currCompany.id == selectedCompany.id
                                  ? null
                                  : companiesMessages.companyView
                              }
                              isSelected={
                                selectedCompany &&
                                currCompany.id == selectedCompany.id
                              }
                              img={currCompany.darkLogo || currCompany.logo}
                              scopeId={currCompany.id}
                              onClick={() => {
                                onCompanySelect(currCompany.id);
                                this.handleClose();
                              }}
                              imageStyle={{
                                objectFit: "contain",
                                objectPosition: "center",
                              }}
                            />
                            <div></div>
                          </div>
                        ))}
                      </>
                    )}
                    {projectsArray.map((project) => (
                      <div
                        key={project.id}
                        style={{
                          direction: rtl ? "rtl" : "ltr",
                          borderBottom: 1,
                          borderBottomColor: theme.darkSeparator,
                          borderBottomStyle: "solid",
                          width: menuWidth,
                        }}
                      >
                        <Item
                          title={project.title || project.address}
                          subtitle={project.address}
                          img={project.getNested(
                            ["images", "main"],
                            defaultProject
                          )}
                          scopeId={project.id}
                          onClick={(e) => {
                            onProjectSelect(e.value);
                            this.handleClose();
                          }}
                          isSelected={project.id == selectedProjectId}
                        />
                      </div>
                    ))}
                  </MenuScrollbar>
                )}
              </div>
            </div>
          )}
          <img
            src={toggleDown}
            onClick={() =>
              isOpen ? this.handleClose() : this.setState({ isOpen: true })
            }
            style={{
              cursor: "pointer",
              zIndex: theme.zIndexes.projectSelector + 1,
              position: "absolute",
              top: (ITEM_HEIGHT - 7) / 2,
              [rtl ? "right" : "left"]: menuWidth - theme.margin * 2,
              transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
              margin: 1,
              height: 7,
            }}
          />
        </div>
      </>
    );
  }
}
MySelect = injectIntl(MySelect);

class Item extends React.Component {
  constructor(props) {
    super(props);
    this.setHover = this.setHover.bind(this);
    this.state = {
      isHover: false,
    };
  }

  setHover(event, isHover) {
    const { onHover } = this.props;

    this.setState({ isHover });
    if (onHover) onHover(isHover);
  }

  render() {
    const {
      title,
      subtitle,
      img,
      scopeId,
      isHeader,
      onClick,
      isSelected,
      rtl,
      imageStyle = {},
    } = this.props;
    const { isHover } = this.state;

    return (
      <div
        onClick={(e) => onClick({ ...e, value: scopeId })}
        onMouseOver={(e) => this.setHover(e, true)}
        onMouseOut={(e) => this.setHover(e, false)}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          height: ITEM_HEIGHT,
          cursor: "pointer",
          [rtl ? "paddingLeft" : "paddingRight"]: isHeader
            ? theme.padding + theme.margin * 2
            : 0,
        }}
      >
        <div
          style={{
            display: "flex",
            height: ITEM_HEIGHT,
            width: 100,
            overflow: "hidden",
          }}
        >
          <img
            type={"project"}
            style={{
              objectPosition: "top",
              objectFit: "cover",
              height: ITEM_HEIGHT,
              width: 100,
              ...imageStyle,
            }}
            src={img}
          />
        </div>
        <div
          style={{
            flex: 1,
            marginLeft: theme.padding,
            marginRight: theme.padding,
          }}
        >
          <Text
            style={{
              ...theme.defaultFont,
              fontWeight: 500,
              fontSize: isHeader ? 18 : 14,
              color:
                (isHover || isSelected) && !isHeader
                  ? theme.brandPrimary
                  : theme.backgroundColorBright,
              textTransform: "capitalize",
            }}
          >
            {title || subtitle}
          </Text>
          {Boolean(!isHeader && title && subtitle && title != subtitle) && (
            <Text
              style={{
                ...theme.defaultFont,
                fontWeight: 500,
                fontSize: 12,
                color:
                  (isHover || isSelected) && !isHeader
                    ? theme.brandPrimary
                    : theme.backgroundColorBright,
              }}
            >
              {subtitle}
            </Text>
          )}
        </div>
      </div>
    );
  }
}
