import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { compose, hoistStatics } from "recompose";
import { connectContext } from "react-connect-context";
import _ from "lodash";

// Components

// Other
import sections from "./config/projectPropertiesSectionsConfig";
import systemMessages from "../../../common/app/systemMessages";
import { ProjectManagerContext } from "../../../common/projects/contexts";
import Button from "../../../web/app/standardComponents/Button";
import { migrateDrawingsToV2 } from "../../../common/drawings/funcs";
import theme from "../../../common/app/theme";
import { hideLoading, startLoading } from "../../../common/app/actions";

class ProjectProperties extends React.Component {
  constructor(props) {
    super(props);
    this.handleConfirmSave = this.handleConfirmSave.bind(this);
    this.recalcHeader = this.recalcHeader.bind(this);
    this.handleMigrationDrawingToV2 = this.handleMigrationDrawingToV2.bind(this);

    this.state = {};
  }

  componentDidMount() {
    this.recalcHeader();
  }

  componentWillUnmount() {
    const { handleConfirmCancel } = this.props;
    if (handleConfirmCancel) handleConfirmCancel();
  }

  async handleConfirmSave() {
    const { changes, objectSavedSuccessfully } = systemMessages;
    const {
      startToast,
      selectedProjectId,
      updateProjectFields,
      updateConfigurations,
      toggleEdit,
      modifiedValues,
      uploadImgIfNecessaryAndReturnUpdated,
    } = this.props;

    let modifiedValuesCopy = _.cloneDeep(modifiedValues);

    modifiedValuesCopy = await uploadImgIfNecessaryAndReturnUpdated(
      modifiedValuesCopy,
      {
        pathInObj: ["company/logo"],
        get targetFileName() {
          return "project_company/" + selectedProjectId + "/" + Date.now();
        },
        serverFolder: "companies",
      }
    );

    modifiedValuesCopy = await uploadImgIfNecessaryAndReturnUpdated(
      modifiedValuesCopy,
      {
        pathInObj: ["images/main"],
        get targetFileName() {
          return "project_main/" + selectedProjectId + "/" + Date.now();
        },
        serverFolder: "projects",
      }
    );

    if (selectedProjectId) {
      const { projectDetails, configurations } = modifiedValuesCopy;

      if (projectDetails && updateProjectFields)
        await updateProjectFields(
          projectDetails.setNested(["city"], null),
          selectedProjectId
        );
      if (configurations && updateConfigurations)
        await updateConfigurations(configurations, selectedProjectId);
    }

    startToast({
      title: objectSavedSuccessfully,
      values: { objectName: changes },
    });
  }

  recalcHeader() {
    const { recalcHeaderWithOptions } = this.props;

    if (recalcHeaderWithOptions) {
      let options = {
        confirmSaveFunc: this.handleConfirmSave,
      };

      recalcHeaderWithOptions(options);
    }
  }

  componentDidUpdate(prevProps) {
    const { isEditMode } = this.props;
    if (isEditMode != prevProps.isEditMode) this.recalcHeader();
  }

  async handleMigrationDrawingToV2() {
    const { project, startLoading, hideLoading } = this.props;
    startLoading({ title: systemMessages.loadingMessage });
    await migrateDrawingsToV2(project.id);
    hideLoading();
  }

  render() {
    const { calcSection, wrapperStyles, configurations, project } = this.props;
    return (
      <>
        <div style={wrapperStyles}>
          {sections.map(calcSection)}
          {/* <div style={{ display: 'flex', justifyContent: 'center', padding: theme.padding }}>
            <Button
              title={'Migrate drawings to V2'}
              isDisabled={configurations.getNested(['features', 'drawings', 'v2', 'isActive'])}
              onClick={this.handleMigrationDrawingToV2}
            />
          </div> */}
        </div>
      </>
    );
  }
}

ProjectProperties = injectIntl(ProjectProperties);

const enhance = compose(
  connectContext(ProjectManagerContext.Consumer),
  connect((state) => ({}), { startLoading, hideLoading })
);

export default enhance(ProjectProperties);
