import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux'

// Components
import Text from '../../components/CementoComponents/Text';
import Image from '../../components/CementoComponents/Image';
import { Tooltip } from '../../components';

// Others
import theme from '../../assets/css/theme';
import _ from 'lodash';
import { getObjectSources } from '../../../web/app/funcs';

const scopesDetails = {
  sets: { color: "#6EFAFB", level: 1, logo: false },
  set: { color: "#6EFAFB", level: 1, logo: false },
  templates: { color: "#FF9000", level: 2, logo: false }, // blue
  template: { color: "#FF9000", level: 2, logo: false }, // blue
  set: { color: "#6EFAFB", level: 3, logo: false },
  sets: { color: "#6EFAFB", level: 3, logo: false },
  companies: { color: "#228CDB", level: 4, logo: true }, // orange
  company: { color: "#228CDB", level: 4, logo: true }, // orange
  set: { color: "#6EFAFB", level: 5, logo: false },
  sets: { color: "#6EFAFB", level: 5, logo: false },
  projects: { color: "#9C95DC", level: 6, logo: false }, // purple
  project: { color: "#9C95DC", level: 6 , logo: false}, // purple
}

const sortScopesByLevel = (firstScope, secScope) => {
  return _.get(scopesDetails, [secScope, "level"], 1) - _.get(scopesDetails, [firstScope, "level"], 1);
}

const getTagToolTipText = (objectSourcesArray) => {
  let tagToolTipText = '';
  
  if (!_.isEmpty(objectSourcesArray)) {
    if (objectSourcesArray.length === 1) {
      const scope = _.head(objectSourcesArray);
      tagToolTipText = `Came from ${scope} level`;
    }
    else {
      const [highestScope, ...restOfScopes] = objectSourcesArray.sort(sortScopesByLevel);
      tagToolTipText = `Came from ${highestScope} level and overwritten those levels: ${restOfScopes.join(', ')}`;
    }
  }

  return tagToolTipText;
}

const ObjectSourceTag = (props) => {
  const currScopeId = useSelector(state => state.ui.currProject);
  const projectsMap = useSelector(state => state.projects.map);
  const companiesMap = useSelector(state => state.companies.map);

  const [companyLogo, setCompanyLogo] = useState([]);

  useEffect(() => {
    let currCompanyId = currScopeId; // Our company id is stored as project id in ui

    let currCompany = null;

    if (!companiesMap.has(currScopeId)) { 
      let currProject = projectsMap.get(currCompanyId);
      if (!currProject) return;
      currCompanyId = currProject.companyId;
    } 

    currCompany = companiesMap.get(currCompanyId);

    if (!currCompany) return;

    setCompanyLogo(currCompany.logo);
  }, [currScopeId, projectsMap, companiesMap]);
  
  const { objectPath, isMultiSources, subType } = props;

  const [objectSourcesArray, setObjectSourcesArray] = useState([]);
  const [tagToolTipText, setTagToolTipText] = useState();
  
  const { objectsSourcesMap } = useSelector((state) => ({
    objectsSourcesMap: state.app.objectsSourcesMap
  }));

  const memorizedObjectPath = useMemo(() => objectPath, [objectPath]);
  const memorizedSubType = useMemo(() => subType, [subType]);

  useEffect(() => {
		let _sourcesArray = getObjectSources(memorizedObjectPath, objectsSourcesMap[memorizedSubType]);
    
    const toolTipText = getTagToolTipText(_sourcesArray);
    setTagToolTipText(toolTipText);

    if (!isMultiSources && _sourcesArray.length > 1) {
      const [highstedScope, ...rest] = _sourcesArray.sort(sortScopesByLevel);
      _sourcesArray = [highstedScope];
    }

    setObjectSourcesArray(_sourcesArray);
  }, [memorizedObjectPath, objectsSourcesMap[memorizedSubType], isMultiSources]);

  if (_.isEmpty(objectSourcesArray)) return null;

  const objSrcTags = objectSourcesArray.map((objectSource, index) => {
    const sourceRepresentingChar = (objectSource[0] || '').toUpperCase();
    const scopeConfig = _.get(scopesDetails, [objectSource], {});
    const tagBackground = _.get(scopeConfig, ["color"], theme.brandPrimary);
    
    return (
      <div key={`object_source_${(objectPath|| [].join('_'))}_${index}`} style={{...styles.tagsContainerStyle, background: tagBackground }}>
        {Boolean(scopeConfig.logo) ? 
          <Image 
            src={companyLogo} 
            style={{width: '18px', height:'18px', flexDirection: '18px', borderRadius: 100}} 
            imageStyle={{borderRadius: 100, width: '100%', height: '100%', objectFit: 'contain'}}
          /> : 
          <Text withTooltip={false} style={styles.tagsTextStyle}>{sourceRepresentingChar}</Text>}
      </div>
    );
  });

  return (
    <Tooltip title={tagToolTipText} mainContainerStyle={{ maxWidth: tagToolTipText.length > 30 ? '50%' : '100%' }}>
      <div style={{display: 'flex', flexDirection: 'row'}}>
      {objSrcTags}
      </div>
    </Tooltip>
  );
}

const styles = {
  tagsContainerStyle: {
    width: "20px",
    height: "20px",
    borderRadius: 100,
    border: '1px solid black',
    margin: theme.verticalMargin,
    display: "flex",
    flex: 1,
    alignContent: "center",
    justifyContent: "center",
  },
  tagsTextStyle: {
    fontWeight: 'bold',
    fontSize: theme.fontSize + 1,
    textAlign: 'center',
    color: 'black'
  }
}

export default React.memo(ObjectSourceTag);

