import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { connectContext } from 'react-connect-context';
import { ProjectContext } from '../../../common/projects/contexts';
import { PROJECT_TYPE_COMPLEX_BUILDINGS } from '../../../common/projects/actions';
import { compose, hoistStatics } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import Creatable from 'react-select/creatable';
import Selectable from 'react-select';

import Datetime from 'react-datetime';
import FormControl from '@material-ui/core/FormControl';
import Image from '../../components/CementoComponents/Image';
import { checkError } from '../../../common/propertiesTypes/funcs';
import { checkPropSettings } from '../../../common/propertiesInstances/funcs.js';
import { CustomInput, PictureUpload } from '../../components';
import theme from '../../assets/css/theme';
import extendedFormsStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import PDF from '../../assets/img/icons/pdf.png';
import Text from './Text';
import radioEmpty from '../../assets/img/icons/radio-empty.png';
import radioFull from '../../assets/img/icons/radio-full.png';
import calendarIcon from '../../assets/img/icons/calendar.png';
import issue_full from '../../assets/img/icons/issue_full.png';
import issue_warn from '../../assets/img/icons/issue_warn.png';
import HomeBlackPNG from '../../assets/img/locations/home_black.png';
import Modal from '../../components/CementoComponents/Modal';
import Buildings from '../../views/Locations/Buildings.js';
import close from '../../assets/img/icons/close.png';
import { getLocationTitle } from '../../views/Locations/funcs';

import Tooltip from './Tooltip';
import Fade from '@material-ui/core/Fade';
import tooltipInfo from '../../assets/img/icons/tooltipInfo.svg';

import propertiesMessages from '../../../common/propertiesTypes/propertiesMessages';
import systemMessages from '../../../common/app/systemMessages';
import TradeBadge from './TradeBadge';
import { newId } from './funcs';

import FilesDropzone from './FilesDropzone';
import ImportExportExcel from './ImportExportExcel';
import * as propertyTypes from '../../../common/propertiesTypes/propertiesTypes';
import _ from 'lodash';
import _fp from 'lodash/fp';
import { getUniqueFirebaseId } from "../../../common/lib/utils/utils";
import { startToast } from "../../../common/app/actions";
import StandardInput from "./StandardInput";
import Button from "../../app/standardComponents/Button";
import ObjectSourceTag from "../../views/ProjectManager/ObjectSourceTag";

const DEFAULT_MAX_CHARS = 20;

class InputField extends React.Component {
	constructor(props) {
		super(props);
		this.getRef = this.getRef.bind(this);
		this.onChange = this.onChange.bind(this);
		this.checkErrors = this.checkErrors.bind(this);
		this.onRemoveFile = this.onRemoveFile.bind(this);
		this.setExtraComponent = this.setExtraComponent.bind(this);
		// this.onCreateOption = this.onCreateOption.bind(this);
		this.inputFieldRef = React.createRef();

		this.state = {
			key: null,
			value: null,
			values: [],
			error: null,
			isMulti: false,
		};
	}

	UNSAFE_componentWillMount() {
		this.setComponentData({ firstMount: true }, this.props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setComponentData(this.props, nextProps);
	}

	componentDidMount() {
		const { onRef } = this.props;
		if (onRef && this.inputFieldRef) {
			onRef(this.inputFieldRef, this);
		}
	}

	setComponentData(props, nextProps) {
		const { intl, lang, children, value, autoSort = true, subjectName, propId } = nextProps;

		let newStateChanges = {};

		let isControlled = !nextProps.hasOwnProperty('defaultValue');
		if (this.state.componentControlled != nextProps.hasOwnProperty('defaultValue'))
			newStateChanges.componentControlled = !nextProps.hasOwnProperty('defaultValue');

		if (props.name != nextProps.name) newStateChanges.name = nextProps.name;

		if (
			!isControlled &&
			(props.firstMount || props.inputKey != nextProps.inputKey || props.defaultValue != nextProps.defaultValue)
		)
			newStateChanges.value = nextProps.defaultValue != undefined ? nextProps.defaultValue : null;

		if (isControlled && (props.value != nextProps.value || props.inputKey != nextProps.inputKey))
			newStateChanges.value = nextProps.value;

		if (props.isValDiff(nextProps, ['isMulti']) || props.isValDiff(nextProps, ['settings', 'isMulti']))
			newStateChanges.isMulti = Boolean(
				typeof nextProps.isMulti !== 'undefined' ? nextProps.isMulti : _.get(nextProps, ['settings', 'isMulti'], false),
			);

		let nextStateValue = newStateChanges.value || this.state.value;
		let prevStateValue = this.state.value;

		const mapValues = v => (v && v.toJS ? v.toJS() : v);

		const pathToValues = subjectName && propId ? ['propertiesTypes', subjectName, propId, 'values'] : ['values'];
		let prevValues = _.values(props.getNested(pathToValues)).map(mapValues);
		let nextValues = _.values(nextProps.getNested(pathToValues)).map(mapValues);

		if (!_.isEqual(prevValues, nextValues) || (nextValues && nextStateValue != prevStateValue)) {
			newStateChanges.options = [];
			_.forIn(nextValues, curr => {
				if (!curr) return;

				let label = null;
				if (typeof curr.title == 'string') label = curr.title;
				else if (curr.title instanceof Object && curr.title.hasOwnProperty('defaultMessage'))
					label = intl.formatMessage(curr.title);
				else if (curr.title && lang && Boolean(curr.title[lang])) label = curr.title[lang];

				let currOption = {
					value: curr.id,
					label,
					title: label,
					id: curr.id,
					isDeleted: curr.isDeleted,
				};
				if (label && label != '') newStateChanges.options.push(currOption);
			});
			if (autoSort)
				newStateChanges.options = newStateChanges.options.sort((a, b) => (a.label || '').localeCompare(b.label || ''));
		}

		//if (nextProps.onError && nextProps.prop && (props.firstMount || props.inputKey != nextProps.inputKey)) {
		//	let error = checkError(nextProps.prop, nextStateValue);
		//	nextProps.onError(error, nextProps.propId);
		//	newStateChanges.error = error;
		//}

		if (Boolean(children) && value && props.value != value) this.onChange(nextProps.value);
		if (props.prop && nextProps.prop && props.prop.mandatory != nextProps.prop.mandatory) {
			this.checkErrors(this.state.value, nextProps);
		}

		if (Object.keys(newStateChanges).length > 0) this.setState(newStateChanges);
	}

	checkErrors(value, props) {
		const { prop, propertiesTypes, subjectName, intl } = props || this.props;
		let errors = null;
		if (prop) {
			errors = checkError(
				value == undefined ? this.state.value : value,
				prop,
				propertiesTypes ? propertiesTypes[subjectName] || {} : {},
				intl,
			);
			this.setState({ error: errors });
		}
		return errors;
	}

	onChange(changedValue, changeProps) {
		const { onChange, propId, prop, onError } = this.props;
		const { componentControlled } = this.state;

		if (prop) {
			let errors = this.checkErrors(changedValue);
			if (onError) onError(errors, propId);
		}

		if (!componentControlled) this.setState({ value: changedValue });
		if (onChange) onChange(changedValue, propId, changeProps);
	}

	onRemoveFile(changedValue, nextStateValue) {
		const { onRemoveFile, propId } = this.props;
		const { componentControlled } = this.state;
		if (!componentControlled) this.setState({ value: nextStateValue });
		if (onRemoveFile) onRemoveFile(changedValue, propId);
	}

	setExtraComponent(comp) {
		this.setState({ InputExtraComp: comp });
	}

	getRef(e) {
		this.inputFieldRef = e;
	}

	render() {
		const { name, value, options, InputExtraComp, error, componentControlled, isMulti } = this.state;
		const { children, mode, subjectName, isExtra, onChange, prop, inputInfo, onRemoveFile, addMode, intl, lang, rtl, type, extraTypes, businessType, id, inputKey, settings, openPDFInWebPage, } = this.props;
		const { placeholder, onImageSelect, alignCenter, withResize, resizePercent, height,  noBorder, style, innerStyle, onRef, isCreatable: _isCreatable, createLabel, } = this.props;
		const { tooltip = false, multiple = false, maxNumOfFiles, accept, picsOnly, getDataForExport, autoFocus = false, tagsMode, linksMode, allowBlank, valueFontSize, onCreateOption, isClearable } = this.props;
		const { isMandatory, propId, visible, extraComponentsStyle, autoSort, disabledValueStyle, inputComponentContainer, noValueComponent, prefix, suffix, titleAsPlaceholder, titleContainerStyle, valueStyle, titleStyle, multiline, shouldShowObjectSource, objectSourcePath } = this.props; // StandardInput
		
		const isCreatable = _isCreatable || prop && prop.getNested(['isCreatable']);
		const key = this.props.inputKey || this.props.key || this.props.id;

		const readOnly = _.get(prop, 'editable', true) === false && !addMode;
		const disabled = this.props.disabled || readOnly;

		const intlPlaceholder =
			placeholder && typeof placeholder === 'object' && placeholder.defaultMessage
				? intl.formatMessage(placeholder)
				: placeholder;
		let size = height || 50;
		let inputComponent = null;
		if (children) inputComponent = children;
		else if (type !== propertyTypes.CERTIFICATION) {
			return (
				<StandardInput
					id={id}
					type={type}
					title={name}
					onChange={(val, changeProps) => this.onChange(val, changeProps)}
					value={value}
					addMode={addMode}
					autoFocus={autoFocus}
					disabled={disabled}
					businessType={businessType}
					error={error}
					extraComponents={this.props.extraComponents}
					isMandatory={isMandatory}
					mode={mode}
					noBorder={noBorder}
					settings={settings}
					placeholder={placeholder}
					propId={propId}
					options={options}
					onRef={onRef}
					extraTypes={extraTypes}
					subjectName={subjectName}
					visible={visible}
					containerStyle={style}
					titleStyle={titleStyle}
					extraComponentsStyle={extraComponentsStyle}
					accept={accept}
					alignCenter={alignCenter}
					autoSort={autoSort}
					disabledValueStyle={disabledValueStyle}
					getDataForExport={getDataForExport}
					innerStyle={innerStyle}
					inputComponentContainer={inputComponentContainer}
					inputKey={inputKey}
					inputInfo={inputInfo}
					isExtra={isExtra}
					maxNumOfFiles={maxNumOfFiles}
					multiple={multiple}
					noValueComponent={noValueComponent}
					onImageSelect={onImageSelect}
					onRemoveFile={onRemoveFile}
					picsOnly={picsOnly}
					openPDFInWebPage={openPDFInWebPage}
					prefix={prefix}
					suffix={suffix}
					prop={prop}
					resizePercent={resizePercent}
					titleAsPlaceholder={titleAsPlaceholder}
					titleContainerStyle={titleContainerStyle}
					tooltip={tooltip}
					valueStyle={valueStyle}
					withResize={withResize}
					allowBlank={allowBlank}
					onCreateOption={onCreateOption}
					shouldShowObjectSource={shouldShowObjectSource}
					objectSourcePath={objectSourcePath}
					isClearable={isClearable}
					multiline={multiline}
					isMulti={isMulti}
					isCreatable={isCreatable}
					createLabel={createLabel}
				/>
			);
		} else {
			switch (type) {
				case 'Link': {
					break;
				}
				case propertyTypes.NUMBER:
				case propertyTypes.STRING: {
					inputComponent = (
						<InputsComponent
							valueFontSize={valueFontSize}
							tagsMode={tagsMode}
							linksMode={linksMode}
							style={innerStyle}
							mode={mode}
							type={type}
							value={value}
							placeholder={intlPlaceholder}
							disabled={disabled}
							onChange={this.onChange}
							autoFocus={autoFocus}
						/>
					);
					break;
				}
				case propertyTypes.SELECTION_LIST: {
					inputComponent = (
						<SelectComponent
							valueFontSize={valueFontSize}
							style={innerStyle}
							placeholder={intlPlaceholder}
							businessType={businessType}
							mode={mode}
							key={key}
							value={value}
							allowBlank={allowBlank}
							options={options}
							disabled={disabled}
							noBorder={noBorder}
							onChange={this.onChange}
							isCreatable={isCreatable}
							onCreateOption={this.onCreateOption}
							createLabel={createLabel}
							isMulti={isMulti}
							isClearable={isClearable}
						/>
					);
					break;
				}
				case propertyTypes.PICTURE:
				case propertyTypes.PDF:
				case propertyTypes.FILES_ARRAY:
				case propertyTypes.DRAWINGS_ARRAY: {
					inputComponent = (
						<UploadsComponent
							valueFontSize={valueFontSize}
							settings={settings}
							componentControlled={componentControlled}
							style={innerStyle}
							rtl={rtl}
							type={type}
							mode={mode}
							isExtra={isExtra}
							inputKey={inputKey}
							multiple={type == propertyTypes.DRAWINGS_ARRAY || type == propertyTypes.FILES_ARRAY}
							value={(Array.isArray(value) ? value : value ? [value] : []) || []}
							onChange={this.onChange}
							onRemove={this.onRemoveFile}
							extraComponent={this.setExtraComponent}
							disabled={disabled}
							size={size}
							withResize={withResize}
							resizePercent={resizePercent}
							onImageSelect={onImageSelect}
							openPDFInWebPage={openPDFInWebPage}
						/>
					);
					break;
				}
				case propertyTypes.DATE: {
					inputComponent = (
						<DateComponent
							valueFontSize={valueFontSize}
							style={innerStyle}
							rtl={rtl}
							type={type}
							settings={settings}
							value={value}
							mode={mode}
							intl={intl}
							placeholder={intlPlaceholder || intl.formatMessage(propertiesMessages.datePlaceholder)}
							disabled={disabled}
							onChange={this.onChange}
						/>
					);
					break;
				}
				case propertyTypes.BOOLEAN: {
					inputComponent = (
						<BooleanComponent
							placeholder={intlPlaceholder}
							style={innerStyle}
							rtl={rtl}
							value={value}
							mode={mode}
							type={type}
							disabled={disabled}
							settings={settings}
							onChange={this.onChange}
						/>
					);
					break;
				}
				case propertyTypes.CERTIFICATION: {
					inputComponent = (
						<CertificationComponent
							lang={lang}
							extraTypes={extraTypes}
							withResize={withResize}
							subjectName={subjectName}
							resizePercent={resizePercent}
							onRemoveFile={onRemoveFile}
							onImageSelect={onImageSelect}
							style={innerStyle}
							addMode={addMode}
							rtl={rtl}
							settings={settings}
							value={value}
							mode={mode}
							intl={intl}
							disabled={disabled}
							onChange={this.onChange}
						/>
					);
					break;
				}
				case 'Location': {
					inputComponent = (
						<LocationPickerComponent
							{...this.props}
							style={innerStyle}
							placeholder={intlPlaceholder}
							mode={mode}
							key={key}
							value={value}
							options={options}
							disabled={disabled}
							rtl={rtl}
							//noBorder={noBorder}
							onChange={this.onChange}
						/>
					);
					break;
				}
				case 'FilesDropzone': {
					inputComponent = (
						<FilesDropzone
							rtl={rtl}
							multiple={multiple}
							key={key}
							id={id}
							value={Array.isArray(value) ? value : [value]}
							maxNumOfFiles={maxNumOfFiles || 10}
							accept={accept || '.png,.jpg,.jpeg'}
							disabled={disabled}
							picsOnly={picsOnly}
							onChange={this.onChange}
						/>
					);
					break;
				}
				case 'Excel': {
					inputComponent = (
						<ImportExportExcel
							key={key}
							id={id}
							style={innerStyle}
							accept={accept || '.xlsx'}
							disabled={disabled}
							rtl={rtl}
							onChange={onChange}
							mode={mode || {}}
							settings={settings}
							getDataForExport={getDataForExport}
						/>
					);
					break;
				}
				default: {
					inputComponent = null;
				}
			}
		}

		//let ratio = 12 * (nameSizeRatio || 0.25)
		//let categoryNameRatio = ratio;
		//let valueRatio = name ? (12-ratio) : 12;
		//if (direction != 'column') {
		//	categoryNameRatio = 12
		//	valueRatio = 12
		//}

		let extraComponents = null;
		if (extraTypes && extraTypes.length > 0 && type != propertyTypes.CERTIFICATION) {
			extraComponents = extraTypes.map(extraProp => {
				return (
					<InjectedInputField
						lang={lang}
						key={extraProp.id}
						id={extraProp.id}
						propId={extraProp.id}
						subjectName={subjectName}
						rtl={rtl}
						name={typeof extraProp.title == 'string' ? extraProp.title : extraProp.getNested(['getTitle'])}
						style={{ padding: 0, paddingTop: 10 }}
						mode={mode}
						isExtra={true}
						inputKey={extraProp.id + '_' + disabled}
						disabled={disabled}
						alignCenter={alignCenter}
						withResize={withResize}
						resizePercent={resizePercent}
						type={extraProp.type}
						settings={extraProp.settings}
						businessType={extraProp.businessType}
						values={extraProp.values}
						value={this.props.hasOwnProperty('value') ? extraProp.value : null}
						defaultValue={this.props.hasOwnProperty('defaultValue') ? extraProp.value : null}
						onChange={onChange}
						openPDFInWebPage={openPDFInWebPage}
						onRemoveFile={onRemoveFile}
						onImageSelect={onImageSelect}
					/>
				);
			});
		}

		let mainStyle = {
			display: 'flex',
			flex: 1,
			flexDirection: mode == 'table' ? 'row' : 'column',
			padding: 5,
			justifyContent: 'center',
			alignItems: mode == 'table' ? 'center' : null,
		};
		if (style) mainStyle = Object.assign(mainStyle, style);

		const tooltipFont = theme.mediumFontSize;
		const LightTooltip = withStyles(theme => ({
			tooltip: {
				backgroundColor: theme.palette.common.white,
				color: 'rgba(0, 0, 0, 0.87)',
				boxShadow: theme.shadows[1],
				fontSize: tooltipFont,
			},
		}))(Tooltip);

		let cursor = 'arrow';
		let customTooltip;
		if (!disabled && readOnly) {
			cursor = 'not-allowed';
			customTooltip = intl.formatMessage(systemMessages.toolTips.unEditableField);
		}

		return (
			<div id={id} key={key} style={mainStyle} ref={this.getRef} title={inputInfo}>
				{Boolean(shouldShowObjectSource) && <ObjectSourceTag objectPath={objectSourcePath} />}
				{Boolean(name || InputExtraComp) && 
					<div style={{ transition: "all 150ms ease 0s", color: (error && !disabled) ? 'red' : theme.headerColorDark, display: 'flex', justifyContent: 'space-between', marginBottom: 5, textAlign: alignCenter ? 'right' : 'none', fontWeight: theme.strongBold,...((mode || {}).labelStyle) }}>
					<div style={{display:'flex'}}>
						{Boolean(!disabled && prop && prop.mandatory) && <Text>*</Text>}
						<Text title={(error || []).join(', ')}>{name}</Text>
					</div>
					</div>
				}
            {Boolean(tooltip) && (
              <LightTooltip 
                interactive
                leaveDelay={500}
                title={(tooltip || {}).title} 
                placement={rtl ? 'left' : 'right'}
                TransitionComponent={Fade}
              >
                <img 
                  src={tooltipInfo} 
                  style={{[rtl ? 'marginRight' : 'marginLeft']: theme.verticalMargin, height: '12px', opacity: '80%', alignSelf: 'center'}} 
                />
              </LightTooltip>
            )}
				<div style={{ flex: 1, cursor }} title={customTooltip}>
					{inputComponent}
				</div>
				{Boolean(extraComponents) && <div>{extraComponents}</div>}
			</div>
		);
	}
}

const enhance = compose(
	injectIntl,
	connectContext(ProjectContext.Consumer),
	connect(
		state => ({
			rtl: state.app.rtl,
		}),
		{
			startToast,
		},
	),
);
const InjectedInputField = enhance(InputField);
export default InjectedInputField;

class InputsComponent extends React.Component {
	constructor(props) {
		super(props);
		this.onStringChange = this.onStringChange.bind(this);
	}

	onStringChange(e) {
		const { onChange, type } = this.props;
		let inputType = type == 'String' ? 'text' : 'number';

		let newVal = e.target.value;
		if (inputType == 'number') {
			if (newVal && isNaN(newVal)) return;
			else if (!newVal) newVal = null;
			else newVal = parseInt(newVal);
		}

		if (onChange) onChange(newVal);
	}

	stringValidator = obj => {
		return toString.call(obj) == '[object String]';
	};

	render() {
		const { value, placeholder, disabled, type, autoFocus, tagsMode, linksMode, valueFontSize } = this.props;

		let inputType = type == 'String' ? 'text' : 'number';

		// if (mode == "table")
		// 	return <Text style={{ fontWeight: theme.noBold, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', ...style }} enableTitle={true} tagsMode={tagsMode}>{value}</Text>

		if (disabled)
			return (
				<NoValueComponent
					valueFontSize={valueFontSize}
					valueText={value || ''}
					defaultText={propertiesMessages.empty}
					tagsMode={tagsMode}
					linksMode={linksMode}
				/>
			);

		let emptyValue = Number.isInteger(value) ? 0 : '';
		return (
			<CustomInput
				noClasses
				formControlProps={{ fullWidth: true }}
				inputProps={{
					autoFocus: autoFocus,
					disabled: disabled,
					placeholder: placeholder,
					value: value || emptyValue,
					type: inputType,
					disableUnderline: disabled,
					multiline: true,
					onChange: this.onStringChange,
				}}
			/>
		);
	}
}

class BooleanComponent extends React.Component {
	constructor(props) {
		super(props);
		this.onBoolChange = this.onBoolChange.bind(this);
	}

	onBoolChange(newVal) {
		const { onChange } = this.props;
		if (onChange) onChange(newVal);
	}

	render() {
		const { value, disabled, settings, mode, rtl, style, intlPlaceholder, intl } = this.props;

		let valueToShow = value;
		if ((valueToShow == null || valueToShow == undefined) && settings && settings.hasOwnProperty('defaultVal'))
			valueToShow = settings.defaultVal;
		valueToShow = Boolean(valueToShow);

		if (disabled)
			return (
				<InputsComponent
					style={style}
					mode={mode}
					type={'String'}
					value={intl.formatMessage(valueToShow ? systemMessages.yes : systemMessages.no)}
					placeholder={intlPlaceholder}
					disabled={disabled}
				/>
			);

		return (
			<div style={{ display: 'flex' }}>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						cursor: disabled ? 'default' : 'pointer',
						[rtl ? 'marginLeft' : 'marginRight']: 5,
					}}
					onClick={disabled ? undefined : () => this.onBoolChange(true)}
				>
					<div>
						<img src={valueToShow ? radioFull : radioEmpty} />
					</div>
					<Text
						style={{
							padding: 5,
							color: valueToShow ? theme.headerColorDark : theme.brandInfo,
						}}
					>
						{systemMessages.yes}
					</Text>
				</div>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						cursor: disabled ? 'default' : 'pointer',
					}}
					onClick={disabled ? undefined : () => this.onBoolChange(false)}
				>
					<div>
						<img src={valueToShow ? radioEmpty : radioFull} />
					</div>
					<Text
						style={{
							padding: 5,
							color: valueToShow ? theme.brandInfo : theme.headerColorDark,
						}}
					>
						{systemMessages.no}
					</Text>
				</div>
			</div>
		);
	}
}

BooleanComponent = withStyles(extendedFormsStyle)(BooleanComponent);
BooleanComponent = injectIntl(BooleanComponent);

class DateComponent extends React.Component {
	constructor(props) {
		super(props);
		this.onDateChange = this.onDateChange.bind(this);
		this.renderInput = this.renderInput.bind(this);
		this.todayTS = moment(moment().format('YYYY-MM-DD')).utc().valueOf();
		this.state = {};
	}

	onDateChange(e) {
		const { onChange } = this.props;
		let newVal = e ? e.valueOf() : null;
		if (typeof newVal == 'string') {
			newVal = newVal.replace(/[,\.\-/]/g, '');
			if (newVal.length == 8) {
				let ts = momentTZ.tz(newVal);
				if (onChange && ts && ts.valueOf && ts.valueOf()) {
					let longTS = ts.valueOf();
					onChange(longTS);
				}
			}
		} else if (onChange) onChange(newVal);
	}

	renderInput(props, openCalendar, closeCalendar) {
		const { rtl } = this.props;

		return (
			<div style={{ position: 'relative' }}>
				<input {...props} style={{ fontSize: 'inherit' }} />
				<div
					style={{
						position: 'absolute',
						[rtl ? 'left' : 'right']: 0,
						top: 0,
						bottom: 0,
						display: 'flex',
						alignItems: 'center',
						margin: 8,
					}}
				>
					<img src={calendarIcon} />
					<img
						style={{ marginRight: theme.verticalMargin }}
						src={close}
						onClick={() => {
							props.onChange({ target: { value: null } });
						}}
					/>
				</div>
			</div>
		);
	}

	render() {
		const { placeholder, disabled, mode, intl, settings, rtl, value, type, valueFontSize } = this.props;
		const { val } = this.state;

		let info = checkPropSettings({ type, settings }, value, intl);

		if (disabled) {
			let dateText = !value ? null : moment(value).format(intl.formatMessage(systemMessages.onlyDateFormat));
			let style = info.isExpired
				? { fontWeight: theme.strongBold, color: theme.brandDanger }
				: info.isWarning
				? { fontWeight: theme.strongBold, color: '#deac00' }
				: {};
			if (dateText)
				return (
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<Text
							style={{
								color: info.isExpired ? theme.brandDanger : theme.headerColorDark,
								fontWeight: info.isExpired ? theme.strongBold : null,
								fontSize: 18,
								[rtl ? 'marginLeft' : 'marginRight']: theme.paddingSize * 3,
							}}
						>
							{dateText}
						</Text>
						{Boolean(info.isWarning || info.isExpired) && (
							<img style={{ [rtl ? 'marginLeft' : 'marginRight']: 5 }} src={info.isExpired ? issue_full : issue_warn} />
						)}
						{Boolean(info.isWarning || info.isExpired) && (
							<Text style={style}>
								{info.isExpired ? propertiesMessages.expired : propertiesMessages.closeExpiration}
							</Text>
						)}
					</div>
				);
			return (
				<NoValueComponent valueFontSize={valueFontSize} valueText={dateText} defaultText={propertiesMessages.empty} />
			);
		}

		return (
			<FormControl fullWidth>
				<Datetime
					closeOnSelect={true}
					renderInput={this.renderInput}
					timeFormat={false}
					value={value ? moment(value) : null}
					onChange={this.onDateChange}
					inputProps={{
						placeholder: disabled ? null : placeholder,
						disabled: disabled,
						fullWidth: true,
					}}
				/>
			</FormControl>
		);
	}
}

DateComponent = injectIntl(DateComponent);

let houseHeight = 18;
let houseWidth = 21;
class LocationPickerComponent extends React.Component {
	constructor(props) {
		super(props);
		this.onSelectedLocationChange = this.onSelectedLocationChange.bind(this);
		this.renderInput = this.renderInput.bind(this);
		const { buildingId, floorId, unitId } = props.value || {};
		this.state = { isFocused: false, buildingId, floorId, unitId };
	}

	onSelectedLocationChange(event) {
		const { onChange } = this.props;
		if (onChange) onChange(event);

		const { buildingId, floorId, unitId } = event;

		this.setState({ buildingId, floorId, unitId });
	}

	renderInput(props) {
		const { rtl, buildings, floors, units, intl, project } = this.props;
		const { isFocused, buildingId, floorId, unitId } = this.state;

		let locationsTitle = getLocationTitle(
			buildings,
			floors,
			units,
			buildingId,
			floorId,
			unitId,
			null,
			intl,
			project && project.type != PROJECT_TYPE_COMPLEX_BUILDINGS,
		);

		return (
			<>
				<div
					style={{
						position: 'relative',
						paddingBottom: theme.verticalMargin,
						borderBottom: Boolean(isFocused) ? '1.5px solid' : '1px solid',
						borderColor: Boolean(isFocused) ? theme.brandPrimary : theme.placeholderTextColor,
					}}
					onClick={() => this.setState({ isFocused: !Boolean(isFocused) })}
				>
					{Boolean(buildingId || floorId || unitId) ? (
						<span style={{ fontSize: 14 }}>{Object.values(locationsTitle).join('/ ')}</span>
					) : (
						<input {...props} value='' style={{ fontSize: 14 }} />
					)}
					<div
						style={{
							position: 'absolute',
							[rtl ? 'left' : 'right']: 0,
							top: 0,
							bottom: theme.verticalMargin,
							display: 'flex',
							alignItems: 'center',
							margin: 8,
						}}
					>
						<img
							src={HomeBlackPNG}
							style={{
								height: houseHeight,
								width: houseWidth,
								maxWidth: '60px',
								zIndex: 1,
							}}
						/>
					</div>
				</div>

				{Boolean(isFocused) && (
					<Modal onClose={() => this.setState({ isFocused: false })} open={isFocused}>
						<div style={{ height: '75vh', width: '35vh' }}>
							<Buildings
								showBadges={false}
								style={{
									marginTop: 0,
									visibility: 'visible',
									width: '100%',
									height: 'inherit',
									backgroundColor: theme.backgroundColor,
								}}
								maxUnitsInFloor={4}
								onClick={this.onSelectedLocationChange}
								selectedLocation={{ buildingId, floorId, unitId }}
							/>
						</div>
						<div
							style={{
								textAlign: 'center',
								alignSelf: 'center',
								width: 90,
								cursor: 'pointer',
								margin: 5,
								border: '1px solid #00000010',
								backgroundColor: theme.backgroundColorBright,
								color: theme.brandPrimary,
								padding: '5px 15px',
								borderRadius: 25,
								boxShadow: '#00000015 0px 0px 10px 0px',
							}}
							onClick={() => this.setState({ isFocused: false })}
						>
							<Text>{systemMessages.save}</Text>
						</div>
					</Modal>
				)}
			</>
		);
	}

	render() {
		return <FormControl fullWidth>{this.renderInput(this.props)}</FormControl>;
	}
}

class SelectComponent extends React.Component {
	constructor(props) {
		super(props);
		this.onSelectChange = this.onSelectChange.bind(this);
		this.state = {};
	}

	onSelectChange(event) {
		const { onChange, isMulti } = this.props;

		const newValArr = Array.isArray(event) ? event : [event];

		const newValObj = newValArr.reduce((acc, currVal) => {
			let val = currVal.value ? currVal.value : Object.values(currVal || {})[0];

			if (typeof val == 'object') acc = val;
			// TODO: Workaround, for value on the selection list in signatures flow on the manager
			else if (val || !isMulti) acc[val] = val;

			return acc;
		}, {});

		if (onChange) onChange(newValObj);
	}

	render() {
		const {
			key,
			disabled,
			noBorder,
			businessType,
			intl,
			placeholder,
			isCreatable = false,
			onCreateOption,
			createLabel,
			allowBlank = true,
			valueFontSize,
			isMulti,
			isClearable = false,
		} = this.props;


		const TradeOptionComponent = props => {
			const { innerProps, innerRef } = props;
			return (
				<div
					ref={innerRef}
					{...innerProps}
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						padding: theme.verticalMargin,
						cursor: 'pointer',
					}}
				>
					<Text>{props.label}</Text>
					<TradeBadge mode={'min'} ids={[props.value]} />
				</div>
			);
		};

		const TradeLabelComponent = props => {
			return (
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						cursor: 'pointer',
					}}
				>
					<Text>{props.data.label}</Text>
					<TradeBadge mode={'min'} ids={[props.data.value]} />
				</div>
			);
		};

		let options = this.props.options;
		let value = this.props.value;
		if (options && Array.isArray(options)) {
			if (!isMulti && allowBlank && !isCreatable) options = [{ value: null, label: '-' }, ...options];

			if (value && !value.value)
				value = options.reduce((acc, option) => {
					if (value[option.value]) acc.push(option);

					return acc;
				}, []);
		}

		let valueText = (value || {}).label || null;
		if (!valueText && Array.isArray(value)) valueText = value.map(opt => opt.label).join(', ');

		if (noBorder) {
			selectStyles.container = styles => ({
				...styles,
				width: '100%',
				border: '0px',
				borderRadius: '0px',
			});
			selectStyles.option = styles => ({
				...styles,
				zIndex: 500,
				cursor: 'pointer',
				color: theme.headerColorDark,
			});
			selectStyles.control = styles => ({
				...styles,
				backgroundColor: 'transparent',
				border: '0px',
				boxShadow: '0px',
				cursor: 'pointer',
			});
		}

		if (disabled)
			return (
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<NoValueComponent
						valueFontSize={valueFontSize}
						valueText={valueText}
						defaultText={propertiesMessages.empty}
					/>
					{Boolean(value && businessType == 'trades') && <TradeBadge mode={'min'} ids={[value.value]} />}
				</div>
			);

		let componentProps = {
			key,
			value,
			placeholder: placeholder || intl.formatMessage(systemMessages.selectPlaceholder),
			onChange: this.onSelectChange,
			styles: selectStyles,
			options,
			components: businessType == 'trades' ? { Option: TradeOptionComponent, SingleValue: TradeLabelComponent } : {},
			theme: theme => ({
				...theme,
				colors: { ...theme.colors, primary: theme.brandPrimary },
			}),
			isClearable,
			isMulti,
			...(isCreatable && {
				onCreateOption,
				createOptionPosition: 'first',
				formatCreateLabel: input => (createLabel ? createLabel : input),
				// getNewOptionData: (customText) => {
				// 	this.setState({customText})
				// },
				//inputValue: (input) => {this.state.customText || value.label || ""},
			}),
		};
		
		return isCreatable ? <Creatable {...componentProps} /> : <Selectable {...componentProps} />;
	}
}
SelectComponent = injectIntl(SelectComponent);

let selectStyles = {
	valueContainer: styles => ({
		...styles,
		padding: '0px',
		margin: '0px',
		border: '0px',
		borderRadius: '0px',
	}),
	container: styles => ({
		...styles,
		width: '100%',
		border: '0px',
		borderRadius: '0px',
	}),
	option: (styles, { data, isDisabled, isFocused, isSelected }) => {
		return {
			...styles,
			zIndex: 500,
			cursor: 'pointer',
			backgroundColor: theme.backgroundColorBright,
			color: isDisabled
				? '#ccc'
				: isSelected
				? theme.brandPrimary
				: isFocused
				? theme.brandPrimary
				: theme.brandNeutralDark,
			cursor: isDisabled ? 'not-allowed' : 'default',
		};
	},
	control: (styles, { data, isDisabled, isFocused, isSelected }) => {
		return {
			...styles,
			backgroundColor: 'transparent',
			border: '0px',
			borderBottom: isFocused || isSelected ? '1.5px solid ' + theme.brandPrimary : '1px solid hsl(0,0%,80%)',
			borderRadius: '0px',
			boxShadow: '0px',
			cursor: 'pointer',
		};
	},
	menuList: styles => ({ ...styles, zIndex: 500 }),
	menu: styles => ({ ...styles, zIndex: 500 }),
};

class UploadsComponent extends React.Component {
	constructor(props) {
		super(props);
		this.onRemoveFile = this.onRemoveFile.bind(this);
		this.onFileUploadSubmit = this.onFileUploadSubmit.bind(this);
		this.setComponentData = this.setComponentData.bind(this);
		this.id = newId('UploadsComponent');
		this.state = {
			valArray: [],
			isArchivable: false,
			visibleArchives: {},
			replace: false,
			fileRows: [],
		};
	}

	UNSAFE_componentWillMount() {
		this.setComponentData({ firstMount: true }, this.props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setComponentData(this.props, nextProps);
	}

	setComponentData(props, nextProps) {
		let newStateChanges = {};

		if (_.get(props, ['settings', 'isArchivable']) !== _.get(nextProps, ['settings', 'isArchivable']))
			newStateChanges.isArchivable = _.get(nextProps, ['settings', 'isArchivable'], false);

		if (props.type !== nextProps.type) newStateChanges.replace = nextProps.type == 'Picture' || nextProps.type == 'PDF';

		if (
			_.get(props, ['settings', 'accept']) != _.get(nextProps, ['settings', 'accept']) ||
			props.type !== nextProps.type
		)
			newStateChanges.accept = _.get(
				nextProps,
				['settings', 'accept'],
				nextProps.type == 'Picture' ? '.png,.jpeg,.jpg' : nextProps.type == 'PDF' ? '.pdf' : '.png,.jpeg,.jpg,.pdf',
			);

		if (Object.values(newStateChanges).length != 0) this.setState(newStateChanges);
	}

	onFileUploadSubmit(uploadedChange, refFileIndex) {
		const { onChange, value, multiple } = this.props;
		const { replace, isArchivable } = this.state;

		let valArray = value;
		let uploadedArray = Array.isArray(uploadedChange) ? uploadedChange : [uploadedChange];
		let newValArray = [];
		const hasRefFileIndex = !_.isNil(refFileIndex);

		if (isArchivable)
			uploadedArray = uploadedArray.map(file =>
				Object.assign({}, file, {
					version: 1,
					fileRefId: getUniqueFirebaseId(),
				}),
			);

		if (replace) newValArray = uploadedArray;
		else {
			newValArray = valArray.slice();
			if (hasRefFileIndex) {
				const originalRef = newValArray[refFileIndex];
				if (originalRef) {
					const updatedOriginalFileRef = Object.assign({}, originalRef, {
						isArchive: true,
						fileRefId: originalRef.fileRefId || getUniqueFirebaseId(),
						version: originalRef.version || 1,
					});
					newValArray[refFileIndex] = updatedOriginalFileRef;
					uploadedArray[0].version = updatedOriginalFileRef.version + 1;
					uploadedArray[0].fileRefId = updatedOriginalFileRef.fileRefId;
				}
			}

			newValArray = newValArray.concat(uploadedArray);
		}

		if (hasRefFileIndex) this.setState({ visibleArchives: {} });

		if (onChange) onChange(multiple ? newValArray : newValArray[0]);
	}

	onRemoveFile(index) {
		const { onChange, value, multiple } = this.props;
		const { visibleArchives } = this.state;

		let newValArray = null;

		const isArchivesVisible = Object.values(visibleArchives).filter(Boolean).length;

		if (multiple && Number.isInteger(index)) {
			newValArray = _fp.set([index, 'isDeleted'], true, value);
			const file = _.get(newValArray, [index], null);
			if (file && file.fileRefId) {
				const [indexOfLatestFileVersion, latestArchiveFile] = newValArray.reduce(
					([indexOfLatestArchiveFile, latestFileArchive], val, index) =>
						val &&
						!val.isDeleted &&
						val.isArchive &&
						val.fileRefId === file.fileRefId &&
						(!latestFileArchive || val.version > latestFileArchive.version)
							? [index, val]
							: [indexOfLatestArchiveFile, latestFileArchive],
					[],
				);
				if (latestArchiveFile) newValArray = _fp.set([indexOfLatestFileVersion, 'isArchive'], false, newValArray);
				else if (isArchivesVisible) this.setState({ visibleArchives: {} });
			}
		}

		if (onChange) onChange(newValArray);
	}

	getFileRows = (nextState = this.state, nextProps = this.props) => {
		const { disabled, mode, value } = nextProps;
		const { isArchivable, visibleArchives } = nextState;

		const isArchivesVisible = Object.values(visibleArchives).filter(Boolean).length;
		const filesArray = value;
		let maxImagesPerRow = mode == 'card' ? 3 : 5;
		let filesByRefId = filesArray.reduce(
			(acc, file, index) =>
				!file || (file && file.isDeleted)
					? acc
					: _.set(
							acc,
							[
								file.fileRefId || this.getFileSrc(file),
								_.get(acc, [file.fileRefId || this.getFileSrc(file)], []).length || 0,
							],
							{ ...file, originalIndex: index },
					  ),
			{},
		);
		let fileComponents = [];
		Object.entries(filesByRefId).forEach(([fileRefId, files]) => {
			files
				.sort((a, b) => b.originalIndex - a.originalIndex) // Because we sort, first file is the original file
				.forEach((file, index) => {
					if (isArchivable && !disabled && index === 0 && isArchivesVisible && visibleArchives[fileRefId])
						fileComponents.push({
							fileRefId,
							renewButton: true,
							component: this.getUploadImageComponent(file.originalIndex),
						});

					if ((isArchivesVisible && visibleArchives[fileRefId]) || (!isArchivesVisible && !file.isArchive))
						fileComponents.push(file);
				});
		});

		let fileRows = [[]];
		fileComponents.forEach((file, index) => {
			if (fileRows[fileRows.length - 1].length == maxImagesPerRow) fileRows.push([]);
			fileRows[fileRows.length - 1].push(file);
		});

		if (!disabled && !isArchivesVisible) {
			if (fileRows[fileRows.length - 1].length == maxImagesPerRow) fileRows.push([]);

			fileRows[fileRows.length - 1].push({
				addButton: true,
				component: this.getUploadImageComponent(),
			});
		}

		return fileRows;
	};

	getImageIcon = (file, index, src, onSelect) => {
		const { multiple, disabled, onImageSelect } = this.props;
		const { isArchivable, visibleArchives } = this.state;
		src = src || this.getFileSrc(file);
		const isArchivesVisible = Object.values(visibleArchives).filter(Boolean).length;
		return (
			<Image
				regularImage={true}
				onRemove={
					Boolean(!isArchivable || (isArchivesVisible && !file.isArchive)) &&
					(() => this.onRemoveFile(multiple ? index : null))
				}
				showButtonsOnHover={!disabled}
				onClick={(onSelect || onImageSelect) ? (onSelect || onImageSelect) : undefined}
				src={src}
			/>
		);
	};

	getUploadImageComponent = refFileIndex => {
		const { mode, multiple, size, withResize, resizePercent } = this.props;
		const { accept } = this.state;

		return (
			<div
				style={{
					display: 'flex',
					flex: 1,
					justifyContent: 'center',
					border: '1px solid ' + theme.brandNeutralLight + '80',
					height: '100%',
				}}
			>
				<div style={{ alignSelf: 'center', padding: 10 }}>
					<PictureUpload
						mode={mode}
						multiple={!_.isNil(refFileIndex) ? false : multiple}
						accept={accept}
						height={size}
						withResize={withResize}
						resizePercentFromDefaultResize={resizePercent}
						onSubmit={uploadedArray => this.onFileUploadSubmit(uploadedArray, refFileIndex)}
					/>
				</div>
			</div>
		);
	};

	getFileSrc = file => file && (file.data ? file.data : file.uri);
	render() {
		const { onImageSelect, openPDFInWebPage, mode, disabled, intl } = this.props;
		const { visibleArchives, isArchivable } = this.state;

		const maxImagesPerRow = mode == 'card' ? 3 : 5;
		const fileRows = this.getFileRows();
		const isArchivesVisible = Object.values(visibleArchives).filter(Boolean).length;
		return (
			<div
				style={{
					display: 'flex',
					flex: 1,
					flexDirection: 'column',
					justifyContent: 'flex-start',
				}}
			>
				{fileRows.map(row => (
					<>
						<div
							style={{
								display: 'flex',
								flex: 1,
								flexDirection: 'row',
								justifyContent: 'flex-start',
							}}
						>
							{row.map(file => {
								const fileUri = file.uriPdf || file.uri;
								let imageComp = null;

								if (file.addButton || file.renewButton) imageComp = file.component;
								else if (
									['pdf', 'application/pdf'].includes(file.type) ||
									['pdf', 'application/pdf'].includes(file.extension) ||
									file.uriPdf
								)
									imageComp = (
										<div
											style={{
												display: 'flex',
												flex: 1,
												flexDirection: 'column',
												position: 'relative',
												width: '100%',
												height: '100%',
											}}
										>
											{Boolean(openPDFInWebPage) ? (
												this.getImageIcon(file, file.originalIndex, PDF, () => {
													if (onImageSelect) onImageSelect(fileUri, true);
												})
											) : Boolean(fileUri) ? (
												<a href={fileUri} target='_blank'>
													{this.getImageIcon(file, file.originalIndex, PDF, () => {})}
												</a>
											) : (
												this.getImageIcon(file, file.originalIndex, PDF, () => {})
											)}
										</div>
									);
								else
									imageComp = (
										<div
											style={{
												display: 'flex',
												flex: 1,
												flexDirection: 'column',
												position: 'relative',
											}}
										>
											{this.getImageIcon(file, file.originalIndex)}
										</div>
									);
								return (
									<div
										key={file.uri || file.data}
										style={{
											padding: 5,
											flex: row.length == 1 ? 'none' : '1 0 ' + 100 / maxImagesPerRow + '%',
											maxWidth: 100 / maxImagesPerRow + '%',
										}}
									>
										{imageComp}
									</div>
								);
							})}
						</div>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'flex-start',
							}}
						>
							{row.map(file => {
								const isShowArchivesButton =
									isArchivable &&
									((isArchivesVisible && file.renewButton) ||
										(disabled && !file.isArchive) ||
										(!isArchivesVisible && !file.isArchive && !file.addButton));
								const fileRefId = file.fileRefId || this.getFileSrc(file);
								const fileTitle =
									file.title || file.addButton
										? ''
										: moment(file.uploadTS || file.updatedTS).format(intl.formatMessage(systemMessages.fullDateFormat));
								return (
									<div
										key={file.uri || file.data}
										style={{
											display: 'flex',
											justifyContent: file.renewButton ? 'flex-start' : 'space-between',
											flexDirection: 'column',
											padding: 5,
											flex: row.length == 1 ? 'none' : '1 0 ' + 100 / maxImagesPerRow + '%',
											maxWidth: 100 / maxImagesPerRow + '%',
										}}
									>
										<div style={{ display: 'flex', flexDirection: 'column' }}>
											{Boolean(isArchivesVisible && !file.renewButton) && (
												<div
													style={{
														fontWeight: 'bold',
														padding: 5,
														overflow: 'hidden',
														textOverflow: 'ellipsis',
														whiteSpace: 'pre-wrap',
													}}
												>
													{intl.formatMessage(systemMessages.version)}: {file.version || 1}{' '}
													{Boolean(!file.isArchive) && `(${intl.formatMessage(systemMessages.latest).toLowerCase()})`}
												</div>
											)}
											<Text
												style={{
													padding: 5,
													overflow: 'hidden',
													textOverflow: 'ellipsis',
													whiteSpace: 'pre-wrap',
												}}
												maxChars={DEFAULT_MAX_CHARS}
												maxLines={1}
											>
												{fileTitle}
											</Text>
										</div>
										{Boolean(isShowArchivesButton) ? (
											<Button
												style={{
													marginLeft: 0,
													marginRight: 0,
													maxWidth: '100%',
													minWidth: 'unset',
												}}
												onClick={() =>
													this.setState({
														visibleArchives: Object.assign({}, visibleArchives, {
															[fileRefId]: !visibleArchives[fileRefId],
														}),
													})
												}
											>
												{visibleArchives[fileRefId]
													? systemMessages.goBack
													: systemMessages[disabled ? 'archive' : 'edit']}
											</Button>
										) : (
											<div />
										)}
									</div>
								);
							})}
						</div>
					</>
				))}
			</div>
		);
	}
}

UploadsComponent = injectIntl(UploadsComponent);

class CertificationComponent extends React.Component {
	constructor(props) {
		super(props);
		this.today = moment(moment().format('YYYY-MM-DD')).utc().valueOf();
		this.setTemporaryCertificationMonthsTTL = this.setTemporaryCertificationMonthsTTL.bind(this);
		this.addMonth = this.addMonth.bind(this);
		this.onChange = this.onChange.bind(this);
		this.state = {
			certificationsArr: [{}],
		};
	}

	UNSAFE_componentWillMount() {
		this.setComponentData({ firstMount: true }, this.props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setComponentData(this.props, nextProps);
	}

	setComponentData(props, nextProps) {
		const { lang } = nextProps;

		let newStateChanges = {};
		if (Array.isArray(nextProps.value)) {
			if (props.value != nextProps.value && !this.state.lastCertObj)
				newStateChanges.certificationsArr = (nextProps.value || []).slice();

			if (props.addMode != nextProps.addMode && nextProps.addMode) {
				newStateChanges.certificationsArr = (nextProps.value || []).slice();
				newStateChanges.certificationsArr.push({
					signatureTS: this.today,
					certificationTTL:
						nextProps.settings && nextProps.settings.certificationMonthsTTL
							? this.addMonth(this.today, nextProps.settings.certificationMonthsTTL)
							: null,
				});
			}
		}

		if (Object.keys(newStateChanges).length > 0) this.setState(newStateChanges);
	}

	setTemporaryCertificationMonthsTTL(months) {
		const { certificationsArr } = this.state;
		let lastCert = certificationsArr[certificationsArr.length - 1];
		let signatureTS = lastCert.signatureTS;
		if (months == '') months = '0';
		let futureDate = this.addMonth(signatureTS, months);
		let stateChanges = { temporaryCertificationMonthsTTL: months };
		if (signatureTS) stateChanges = Object.assign(stateChanges, this.onChange(futureDate, 'certificationTTL', true));
		this.setState(stateChanges);
	}

	addMonth(val, monthsCount) {
		if (isNaN(monthsCount)) return null;
		let d = val ? new Date(val) : new Date();
		d.setMonth(d.getMonth() + Number(monthsCount));
		return d.getTime();
	}

	onChange(val, path, skipSetState) {
		const { onChange, settings } = this.props;
		const { certificationsArr, temporaryCertificationMonthsTTL } = this.state;
		let newCertificationsArray = certificationsArr.slice();
		let lastCert = Object.assign({}, newCertificationsArray[newCertificationsArray.length - 1] || {});
		lastCert[path] = val;

		if (path == 'signatureTS' && ((settings && settings.certificationMonthsTTL) || temporaryCertificationMonthsTTL)) {
			let monthsTTL = settings ? settings.certificationMonthsTTL : null;
			monthsTTL = temporaryCertificationMonthsTTL ? temporaryCertificationMonthsTTL : monthsTTL;
			let futureDate = this.addMonth(val, monthsTTL);
			lastCert.certificationTTL = futureDate;
		}

		if (newCertificationsArray.length > 0) newCertificationsArray[newCertificationsArray.length - 1] = lastCert;

		if (onChange) onChange(newCertificationsArray);

		let stateChanges = { certificationsArr: newCertificationsArray };
		if (!skipSetState) this.setState(stateChanges);
		return stateChanges;
	}

	// renderDateInput(props, openCalendar, closeCalendar) {
	// 	const { rtl } = this.props;

	// 	return (
	// 		<div style={{ position:'relative' }}>
	// 			<input {...props}/>
	// 			<div style={{ position:'absolute', [rtl?'left':'right']:0, top:0, bottom: 0, display: 'flex', alignItems: 'center' }}>
	// 				<img src={calendarIcon}/>
	// 			</div>
	// 		</div>
	// 	);
	// }

	render() {
		const {
			extraTypes,
			disabled,
			intl,
			mode,
			rtl,
			lang,
			style,
			prop,
			withResize,
			resizePercent,
			onRemoveFile,
			onImageSelect,
			settings,
			subjectName,
		} = this.props;
		const { certificationsArr, temporaryCertificationMonthsTTL } = this.state;

		const iconSize = 16;
		let lastCertObj = certificationsArr[certificationsArr.length - 1] || {};
		const isRoleAppointment = Boolean(
			_.get(settings, ['certificationType']) == propertyTypes.CERTIFICATIONS_TYPES.roleAppointment,
		);
		let newSettings = Object.assign({}, { isWarning: true, isExpiration: true }, settings, { roundingMode: 'end' });

		let extraComponents = null;
		if (extraTypes && extraTypes.length > 0)
			extraComponents = extraTypes.map(extraProp => {
				return (
					<InjectedInputField
						lang={lang}
						key={extraProp.id}
						id={extraProp.id}
						style={{ marginTop: 5, marginBottom: 5 }}
						name={typeof extraProp.title == 'string' ? extraProp.title : extraProp.getNested(['getTitle'])}
						propId={extraProp.id}
						subjectName={subjectName}
						rtl={rtl}
						mode={mode}
						isExtra={true}
						inputKey={extraProp.id + '_' + disabled}
						disabled={disabled}
						withResize={withResize}
						resizePercent={resizePercent}
						type={extraProp.type}
						settings={extraProp.settings}
						businessType={extraProp.businessType}
						values={extraProp.values}
						value={lastCertObj[extraProp.id]}
						onChange={(val, propId) => this.onChange(val, propId)}
						onRemoveFile={onRemoveFile}
						onImageSelect={onImageSelect}
					/>
				);
			});

		return (
			<div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
				<InjectedInputField
					type={'Date'}
					style={null}
					settings={isRoleAppointment ? null : newSettings}
					disabled={disabled}
					subjectName={subjectName}
					key={isRoleAppointment ? 'signatureDate' : 'expirationDate'}
					rtl={rtl}
					mode={mode}
					name={intl.formatMessage(
						isRoleAppointment ? propertiesMessages.signatureDate : propertiesMessages.expirationDate,
					)}
					defaultValue={isRoleAppointment ? lastCertObj.signatureTS : lastCertObj.certificationTTL}
					todayIfNull={true}
					onChange={val => this.onChange(val, isRoleAppointment ? 'signatureTS' : 'certificationTTL')}
				/>

				{Boolean(isRoleAppointment && mode != 'table' && !disabled) && (
					<InjectedInputField
						type={'Date'}
						style={null}
						settings={Object.assign({}, settings, { roundingMode: 'end' })}
						disabled={disabled}
						subjectName={subjectName}
						key={'expirationDate'}
						rtl={rtl}
						mode={mode}
						name={intl.formatMessage(propertiesMessages.expirationDate)}
						defaultValue={temporaryCertificationMonthsTTL}
						onChange={val => this.onChange(val, 'certificationTTL')}
					/>
				)}

				{Boolean(mode != 'table' && extraComponents) && extraComponents}

				{Boolean(mode != 'table' && !isRoleAppointment) && (
					<InjectedInputField
						type={'DrawingsArray'}
						//settings={{accept:'.pdf'}}
						onImageSelect={onImageSelect}
						style={null}
						mode={mode}
						disabled={disabled}
						subjectName={subjectName}
						key={'drawingArrayCert'}
						withResize={false}
						name={intl.formatMessage(propertiesMessages.attachedFiles)}
						defaultValue={lastCertObj.attachments}
						onChange={arr => this.onChange(arr, 'attachments')}
					/>
				)}
			</div>
		);
	}
}

CertificationComponent = withStyles(extendedFormsStyle)(CertificationComponent);
CertificationComponent = injectIntl(CertificationComponent);






























export const NoValueComponent = (props) => {
	let { valueText, defaultText, tagsMode, linksMode, valueFontSize, noValueComponentValueStyle } = props;
	  if (valueText && typeof valueText != 'string' && typeof valueText != 'number' && !(valueText.id && valueText.defaultMessage))
		  valueText = null;
	  
	return (<Text tagsMode={tagsMode} linksMode={linksMode} style={{ color: valueText ? theme.headerColorDark : theme.brandNeutral, fontSize: valueFontSize || 18, lineHeight: '18px', ...(noValueComponentValueStyle || {}) }}>{valueText || defaultText}</Text>)
	
}
