import React from "react";
import TimeInput from "material-ui-time-picker";
import { MuiThemeProvider, createTheme } from "@material-ui/core";
import clock_icon from "../../assets/img/icons/clock.png";
import theme from "../../assets/css/theme";
import systemMessages from "../../../common/app/systemMessages";
import { injectIntl } from "react-intl";
import moment from "moment";
import { NoValueComponent } from "./InputField";
import propertiesMessages from "../../../common/propertiesTypes/propertiesMessages";

const MUItheme = createTheme({
  palette: {
    primary: {
      main: theme.brandPrimary,
    },
    common: {
      black: theme.backgroundColorBright,
    },
  },
});

const CLOCK_ICON_HEIGHT = 17;

/**
 * @typedef TimePickerProps
 * @property {boolean} is24Hours
 * @property {(newValue: Date) => void} onChange
 * @property {Date} value
 * @property {boolean} isDisabled
 * @param {TimePickerProps} props
 * @returns
 */
let TimePicker = (props) => {
  const { is24Hours, onChange, value, intl, isDisabled } = props;

  const okLabel = intl.formatMessage(systemMessages.ok);
  const cancelLabel = intl.formatMessage(systemMessages.cancel);

  if (isDisabled) {
    let valueToDisplay = "";
    if (value) {
      const timeFormat = intl.formatMessage(systemMessages.onlyTimeFormat);
      valueToDisplay = moment(value).format(timeFormat);
    }

    return (
      <NoValueComponent
        valueText={valueToDisplay}
        defaultText={propertiesMessages.empty}
      />
    );
  }

  return (
    <>
      <MuiThemeProvider theme={MUItheme}>
        <div id="main-container">
          <TimeInput
            mode={is24Hours ? "24h" : "12h"}
            onChange={onChange}
            value={value}
            okLabel={okLabel}
            cancelLabel={cancelLabel}
          />
          <img
            style={{
              height: CLOCK_ICON_HEIGHT,
              marginInline: theme.padding - 1,
            }}
            src={clock_icon}
            alt="clock"
          />
        </div>
      </MuiThemeProvider>

      {styles}
    </>
  );
};

TimePicker = injectIntl(TimePicker);
export default TimePicker;

const styles = (
  <style jsx="true">{`
    #main-container {
      border-bottom: 1px solid ${theme.inputBorderBottomColor};
      width: 100%;
      display: flex;
      alignItems: center;
      justifyContent: space-between;
    }
    #main-container:focus-within {
      border-bottom-color: ${theme.brandPrimary};
      border-bottom-width: 2px;
    }

    #main-container > div::after,
    #main-container > div::before {
      display: none;
    }

    #main-container > div {
      width: 100%;
    }

    div[class*="MuiDialog-root"]:last-child, /* this selector is for in localhost */
    div[class*="jss"][role="presentation"]:last-child {
      /* this is a bit hacky but should work 100% of the time to bring the modal on top of everything else */
      z-index: ${theme.zIndexesLevels.max} !important;
    }
  `}</style>
);
