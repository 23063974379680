import { Range } from 'immutable';
import Comment from './comment.js';
import ExtraError, { errorCodes } from '../lib/errors/extraError';
import { uploadImage } from '../images/actions';
import { getResourceById } from '../lib/utils/utils';
import _ from 'lodash';
import { encodeBase64, onError } from '../app/funcs.js';
import { platformActions } from '../platformActions.js';

export const UPLOAD_NEW_POST_COMMENT 			   = 'UPLOAD_NEW_POST_COMMENT';
export const UPLOAD_NEW_POST_COMMENT_STARTED = 'UPLOAD_NEW_POST_COMMENT_STARTED';
export const UPLOAD_NEW_CHECKLIST_STATUS_COMMENT = 'UPLOAD_NEW_CHECKLIST_STATUS_COMMENT';
export const GET_COMMENTS   			 	 = 'GET_COMMENTS';
export const GET_COMMENTS_STARTED   = 'GET_COMMENTS_STARTED';
export const END_COMMENTS_LISTENER  = 'END_COMMENTS_LISTENER';
export const UPDATE_IMAGE_STARTED = 'UPDATE_IMAGE_STARTED';
export const GET_NEW_COMMENT_ID = 'GET_NEW_COMMENT_ID';
export const CREATE_NEW_COMMENT = 'CREATE_NEW_COMMENT';
export const GET_LOCAL_COMMENT = 'GET_LOCAL_COMMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';

export function getComments(projectId, parentId) {	
	return ({ dispatch }) => {
		const getPromise = async () => {
			let comments = await getResourceById({api:'comments', firebase: 'comments'}, projectId, parentId, (comments) => {
				dispatch({ type: GET_COMMENTS + '_SUCCESS', payload: { comments, projectId, parentId }});
			});
			delete comments.projectId;
			let payloadComments = _.head(Object.values(comments || {}));

			return {
				comments: payloadComments,
				projectId,
				parentId,
			};
		};
		return {
			type: GET_COMMENTS,
			payload: getPromise()
		}
	};
}

export function createLocalComment(viewer, projectId, parentId, comment) {
	return ({ firebaseDatabase, getState }) => {
		const getPromise = async () => {
			if ((comment.parentType !== "checklistItemInstance" && (!comment.content || comment.content == '')) && 
				(!comment.images || Object.values(comment.images).length == 0))
				throw new ExtraError('createLocalComment - missing content', {comment});
	
			if (Object.keys(comment.images || {}).length)
				await Promise.all(Object.values(comment.images).map(async commImage => {
					if (!commImage.uri || !commImage.uri.startsWith || commImage.uri.startsWith('http') || commImage.uri.indexOf(';base64,') !== -1)
						return;
					
					const base64Str = await encodeBase64(commImage.uri, 'image/jpeg');
					if (base64Str)
						commImage.uri = base64Str;
				}));
	
			if (!comment.id) {
				comment.id = firebaseDatabase().ref('comments/' + projectId + '/' + parentId).push().key;
				comment.createdAt = new Date().getTime();
				comment.owner = {id: viewer.id};
			}
	
			let projectTitle = getProjectTitle(getState, projectId);

			return { projectId, parentId, comment, projectTitle, immediateGlobalStorageSave: true };
		}

		return {
			type: CREATE_NEW_COMMENT,
			payload: getPromise()
		};
	}
}

export function uploadComment(viewer, projectId, parentId, comment, postComments, dontSendNotification) {
	
	if (comment.parentType == "checklistItemInstance")
		return uploadNewChecklistStatusComment(viewer, projectId, parentId, comment, null, dontSendNotification);
	else
		return uploadNewPostComment(viewer, projectId, parentId, comment, postComments, dontSendNotification);
}

export function uploadNewChecklistStatusComment(viewer, projectId, parentId, comment) {
	return ({ validate, firebase, firebaseDatabase, getState, sendNotification, removeEmpty, bugsnag }) => {
		const getPromise = async () => {
			try {
				var newComment = Object.assign({}, comment);
				newComment = removeEmpty(newComment, 'uploadNewChecklistStatusComment');
				var updates = {};
				updates['comments/' + projectId + '/' + parentId + '/' + newComment.id] = newComment;
				await firebase.update(updates)

				var projectTitle = getProjectTitle(getState, projectId);

				return { projectId, parentId, comment: newComment, projectTitle };
			} catch (error) {
				console.error("uploadComment error: " + error)
				throw new ExtraError('uploadComment error', { viewer, projectId, parentId, comment }, error)				
			}
		};

		return {
			type: UPLOAD_NEW_CHECKLIST_STATUS_COMMENT,
			payload: getPromise()
		};
	};
}


export function deleteComment(projectId, parentType, parentId, commentId) {
	return ({ firebase, getState }) => {
		const getPromise = async () => {
			try {
				var updates = {};
				updates['comments/' + projectId + '/' + parentId + '/' + commentId] = null;

				if (parentType == "post") {
					updates['posts/' + projectId + '/' + parentId + '/comments/' + commentId] = null;
					updates['posts/' + projectId + '/' + parentId + '/updatedTS'] = new Date().getTime();
				}
				
				await firebase.update(updates);

				var projectTitle = getProjectTitle(getState, projectId);

				return { projectId, parentId, commentId, projectTitle };
			} catch (error) {
				console.error("deleteComment error: " + error)
				throw new ExtraError('deleteComment error', { projectId, parentId, commentId }, error)				
			}
		};

		return {
			type: DELETE_COMMENT,
			payload: getPromise()
		};
	};
}

export function uploadNewPostComment(viewer, projectId, postId, comment, postComments, dontSendNotification) {
	return ({ firebase, getState, sendNotification, removeEmpty, dispatch }) => {
		const getPromise = async () => {
			try {
				if ((comment.parentType !== "checklistItemInstance") && (!comment.content || comment.content == '') && (!comment.images || Object.values(comment.images).length == 0))
					throw new ExtraError('uploadComment - missing content', {comment})

				if (!projectId || !postId || postId == 'undefined')
					throw new ExtraError('uploadComment - missing postId', {comment})

			  if (comment.images && Object.keys(comment.images).length > 0) {
					var currImage = Object.assign({}, Object.values(comment.images)[0]);
					try{
						if (currImage.uri && !currImage.uri.startsWith('http')) {
				  		const uploadImageRes = await uploadImage({ data: currImage.uri, extension: 'jpeg' }, projectId + "_" + comment.id + "_" + currImage.id, "comments");
							if (uploadImageRes)
								currImage.uri = uploadImageRes;
						}

						if (!currImage.uri || (currImage.uri.startsWith && currImage.uri.startsWith('file:/') && !(await platformActions.fs.exists(currImage.uri.replace('file:/', '')))))
							throw new ExtraError('post comment missing image', null, null, errorCodes.MISSING_FILE);
						else if (currImage.uri.startsWith('http')) {
				    	delete currImage['uploading'];
							comment.images = {};
				    	comment.images[currImage.id] = currImage;
						}
				  } catch(error) {
						if (_.get(error, ['errorCode']) == errorCodes.MISSING_FILE) {
							platformActions.sentry.notify('post comment with missing image',{ uri: currImage.uri, viewer, projectId }, error);
							delete comment.images
						}
						else {
							onError({
								error,
								errorMessage: 'Comments: error during uploadImage',
								errorMetaData: {
									images: comment.images,
								},
								methodMetaData: {
									args: {
										comment,
										projectId,
										postId,
										dontSendNotification,
										viewer,
									},
									name: 'uploadNewPostComment',
								},
							});
							throw new ExtraError('uploadComment-uploadImage error', {viewer, projectId, postId, comment, postComments}, error);
						}
				  }
			  }

				// Get all comments counter for update
				var projectComments = getState().comments.map.get(projectId);
				var postComments = null;
				if (projectComments)
					postComments = projectComments.get(postId);

				var commentsCounterBefore = postComments ? postComments.size - 1 : 0;

				var updates = {};
				var currTime = new Date().getTime();
				updates['comments/' + projectId + '/' + postId + '/' + comment.id] = comment;
				updates['posts/' + projectId + '/' + postId + '/commentsCounter'] = commentsCounterBefore + 1;
				updates['posts/' + projectId + '/' + postId + '/updatedTS'] = currTime;
				updates['posts/' + projectId + '/' + postId + '/editedAt'] = currTime;

				var comments = postComments ? postComments.takeLast(3).toJS() : {};
			
				comment = removeEmpty(comment, 'uploadNewPostComment');
				//delete comment['isLocal'];
				comments[comment.id] = comment;
				updates['posts/' + projectId + '/' + postId + '/comments'] = comments;
				await firebase.update(updates)

				if (!dontSendNotification)
					sendNotification('comment', { projectId, postId, commentId: comment.id });

				return { projectId, parentId: postId, comment };
			} catch (error) {
				console.log("uploadComment error: " + error)
				throw new ExtraError('uploadComment error', {viewer, projectId, postId, comment, postComments}, error)				
			}
		};

		return {
			type: UPLOAD_NEW_POST_COMMENT,
			payload: getPromise()
		};
	};
}

export function endCommentsListener(projectId, parentId) {	
  return ({ dispatch, firebaseDatabase, bugsnag }) => {
  	const getPromise = async () => {
			try {	  	
		  	var commentsRef = firebaseDatabase().ref('comments/' + projectId);
		  	if (parentId)
		  		commentsRef = commentsRef.child(parentId);

		  	commentsRef.off('value');
				commentsRef.off('child_added');
				commentsRef.off('child_changed');

		  	return ({ projectId, parentId });

			} catch (error) {
				console.log("endCommentsListener error: " + error)
				throw new ExtraError('endCommentsListener error', {projectId, parentId}, error)				
			}
	  };

    return {
      type: END_COMMENTS_LISTENER,
      payload: getPromise()
    };
  };
}

export function getLocalComments() {
	return ({ getState }) => {
    var localComments = getState().comments.localComments;
		return {
			type: GET_LOCAL_COMMENT,
			payload: { localComments }
		};
	}
}



function getProjectTitle(getState, projectId) {
	var projectTitle = null;
	if (getState().projects)
		var projects = getState().projects.map;

	if (projects) {
		var project = projects.get(projectId);

	if (project)
		projectTitle = project.title;
	}

	
	return projectTitle;
}