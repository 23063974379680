import Button from '../../app/standardComponents/Button';
import theme from '../../assets/css/theme';
import React from 'react';
import useUniqueId from '../../hooks/useUniqueId';

/**
 * @param {{ buttons: (import("../../app/standardComponents/Button").CementoButtonProps & { type: 'select' | 'add' })[], isBoxed: boolean }} props
 */
const ComponentActionButtons = (props) => {
  const { isBoxed, buttons } = props;
  const uniqueComponentId = useUniqueId();

  const renderedButtons = (buttons || []).filter(Boolean).map((button, index) =>
    <Button
      key={`${uniqueComponentId}-${index}-${button.type}`}
      withPlus={button.type === 'add'}
      style={button.type === 'select' ? { backgroundColor: button.isDisabled ? theme.placeholderTextColor : theme.brandPrimary } : {}}
      textStyle={button.type === 'select' ? { color: theme.inverseTextColor, fontWeight: theme.strongBold } : {}}
      {...button}
    />
  );

  return (
    <div style={Object.assign({ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }, isBoxed ? { padding: theme.padding * 2 } : { marginTop: theme.margin * 2 })}>
      {renderedButtons}
    </div>
  );
};

export default ComponentActionButtons;