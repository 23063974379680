import React from "react";
import { injectIntl } from 'react-intl';
import OriginalTooltip from "@material-ui/core/Tooltip";
import { safeFormatMessage } from '../../../common/app/funcs';
import _ from 'lodash';

class Tooltip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toolTipTitle : ""
    }
  }
  
  setToolTipTitle = (value) => {
    this.setState({toolTipTitle : value})
  }

  render() {
    const { title, intl, values, children, titleComponent, classes, titleGeneratorFunction } = this.props;
    const { toolTipTitle } = this.state;

    let titleString = "";
    if (title && typeof title === 'object' && title.defaultMessage) {
      let innerValues = undefined;
      if (values) {
        innerValues = {};
        values.loopEach((key, value) => innerValues[key] = safeFormatMessage(intl, value));
      }
      titleString = intl.formatMessage(title, innerValues);
    } else if (typeof title == 'string')
      titleString = title;

    let TooltipProps = Object.assign({}, this.props);
    ['intl', 'values', 'title', 'children', 'style', 'titleGeneratorFunction', 'titleComponent'].forEach(key => delete TooltipProps[key]);

    const chousenTitle = titleComponent ? titleComponent : titleGeneratorFunction ? toolTipTitle : titleString;

    return <OriginalTooltip {...TooltipProps} title={chousenTitle} classes={{ tooltip: classes?.tooltip }}
              onOpen={Boolean(_.isFunction(titleGeneratorFunction) && !titleComponent) && (() => this.setToolTipTitle(titleGeneratorFunction()))}>{children}</OriginalTooltip>
  }
}

export default injectIntl(Tooltip);
