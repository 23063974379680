import React from 'react';
import { ReactSortable } from 'react-sortablejs';
import PropTypes from 'prop-types';
import _ from 'lodash';
import _fp  from 'lodash/fp';

// Default values
const defaultAnimation = 150;
const defaultDisabled = false;
const defaultEmptyInsertThreshold = 1;
/**
 * @param {number} a Some number
 */

const defaultProps = {
  sortableProps: {
    animation: defaultAnimation,
    disabled: defaultDisabled,
    emptyInsertThreshold: defaultEmptyInsertThreshold,
  },
  style: {},
}

class CrossListSortable extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
  }
  
  static propTypes = {
    keyId: PropTypes.string,
    masterList: PropTypes.array.isRequired,
    pathToListToSort: PropTypes.array,
    animation: PropTypes.number,
    onChange: PropTypes.func,
    sortableProps: PropTypes.object,
    disabled: PropTypes.bool,
    style: PropTypes.object,
    emptyInsertThreshold: PropTypes.number,
  }

	setStateChunk(newStateChunk, oldStateChunk) {
    const { masterList, pathToListToSort, onChange, sortableProps: { disabled } } = this.props;
    
    if (!disabled && 
        (oldStateChunk.length !== newStateChunk.length ||
        !_.isMatchWith(newStateChunk, oldStateChunk, (val1, val2, key) => key === 'chosen' || key === 'selected' ? true : undefined ))) {
      newStateChunk.loopEach((i, item) => {delete item.chosen; delete item.selected;});
      
      let newMasterList;
      if (pathToListToSort)
        newMasterList = _fp.set(pathToListToSort, newStateChunk, masterList);
      else newMasterList = newStateChunk;
      
			if (onChange) onChange(newMasterList);
		}
	}


	render() {
		const { pathToListToSort, children, sortableProps, masterList, keyId, listEmptyComp, style = {} } = this.props;
		const { animation = defaultAnimation, disabled = defaultDisabled, emptyInsertThreshold = defaultEmptyInsertThreshold, ...restSortableProps } = sortableProps;
		const { transitionAnimation } = styles;

    let currList = (pathToListToSort ? _.get(masterList, pathToListToSort, []) : masterList) || [];
    if (!currList.length && listEmptyComp)
      return listEmptyComp;
    
    let key = 'sortable-baby-' + (keyId || (pathToListToSort || []).join('-')) 
    if (!restSortableProps.handle)
      key += disabled;
    
		return (
      <>
        <ReactSortable
          key={key}
          style={{ ...style, ...transitionAnimation }}
          list={currList}
          setList={newStateChunk => this.setStateChunk(newStateChunk, currList)}
          sort={Boolean(restSortableProps.handle) || !disabled}
          animation={animation}
          emptyInsertThreshold={emptyInsertThreshold}
          {...restSortableProps}
        >
          {currList.map((item, index) => 
            <div key={"crosslist_children_" + index}>
              {children(item, index, currList)}
            </div>
          )}
        </ReactSortable>
      </>
    );
	}
}

CrossListSortable.defaultProps = defaultProps;

const styles = {
	transitionAnimation: {
		transitionDuration: '1s',
		transitionTimingFunction: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
	},
};

export default CrossListSortable;