import { platformActions } from "../platformActions";


export const globalScopeSaveOnProjectStateToSave = [
  ['members', 'map'],
  ['members', 'lastClientUpdatePerProject'],

  ['companies', 'map'],
  ['companies', 'lastClientUpdatePerProject'],
] 


const globalStatePathes = [
  ['members', 'map'],
  ['members', 'lastClientUpdatePerProject'],

  ['companies', 'map'],
  ['companies', 'lastClientUpdatePerProject'],

  //['contacts', 'didLoad'],        // Remove at all
  ['contacts', 'lastClientUpdate'], // Remove at all

  ['intl', 'currentLocale'],
  ['users', 'viewer'],
  ['users', 'lastVisitedProject'],

  ['projects', 'map'],
  ['comments', 'localComments'],   

  ['trades', 'map'], 
  ['trades', 'didLoad'], 
  ['trades', 'lastUpdateTS'],   

  ['quasiStatics', 'subCategoriesMap'], 
  ['quasiStatics', 'requiredActionsMap'], 
  ['quasiStatics', 'formUniversalIdsMap'], 
  ['quasiStatics', 'didLoad'], 
  ['quasiStatics', 'lastUpdateTS'],   


  ['titles', 'map'],
  ['titles', 'lastUpdateTS'],

  ['auth', 'authToken'],
  ['auth', 'accessToken'],
  ['auth', 'userId'],
  ['auth', 'number'],
  ['auth', 'refreshToken'],
  ['auth', 'firebaseToken'],
  ['auth', 'inviterUid'],  
  ['auth', 'fCMRegistrationToken'],
 
  ['issues', 'isAdmin'],
  
  ['posts', 'isAdmin'],

  ['reporting', 'isAdmin'],

  ['app', 'lang'],
  ['app', 'rtl'],
  
  ['lastUpdates', 'appVersion'],

  ['permissions', 'map'],
  ['permissions', 'lastUpdateTS'],
  
  ['propertiesTypes', 'lastUpdateTS'],
];

const projectStatePathes = [
  ['projects', 'detailsMap'],
  ['projects', 'lastUpdated'],

  ['configurations', 'lastUpdated'],
  ['configurations', 'map'],
  ['configurations', 'debugConfigurations'],
  
  ['buildings', 'map'],  
  ['buildings', 'lastUpdated'],

  ['floors', 'map'],  
  ['floors', 'lastUpdated'],
  
  ['units', 'map'],  
  ['units', 'lastUpdated'],

  ['drawings', 'map'],
  ['drawings', 'lastUpdated'],
  ['drawings', 'settings'],

  ['stages', 'map'],
  ['stages', 'lastUpdated'],

  ['checklists', 'subscriptions'],
  ['checklists', 'subscriptionsLastUpdated'],

  ['checklists', 'map'],
  ['checklists', 'lastUpdated'],
  
  ['checklistItems', 'map'],
  ['checklistItems', 'lastUpdated'],

	['propertiesTypes', 'projectSections'],
	['propertiesTypes', 'projectProperties'],
  ['propertiesTypes', 'lastUpdated'],
  ['propertiesTypes', 'specificPropertiesUpdatesAvailable'],

  ['propertiesMappings', 'map'],
  ['propertiesMappings', 'lastUpdated'],
  
  ['checklistItemsInstances', 'lastRevokeAvailable'],
  ['checklistItemsInstances', 'lastRevoked'],
  ['checklistItemsInstances', 'sync'],
  ['checklistItemsInstances', 'lastSynced'],

	['propertiesInstances', 'lastRevokeAvailable'],
	['propertiesInstances', 'lastRevoked'],
	['propertiesInstances', 'sync'],
	['propertiesInstances', 'lastSynced'],
	['propertiesInstances', 'recentlySigningUsers'],
	
	['posts', 'lastRevokeAvailable'],
	['posts', 'lastRevoked'],
	['posts', 'sync'],
	['posts', 'lastSynced'],
  
	['employees', 'lastRevokeAvailable'],
  ['employees', 'lastRevoked'],
  
	['equipment', 'lastRevokeAvailable'],
  ['equipment', 'lastRevoked'],
  
  ['trades', 'recentlyTrades'],
  ['trades', 'recentlyUsersPerTrades'],

  ['safety', 'safetyGrades'],

  ['reports', 'map'],
	['reports', 'lastUpdateTS'],
	['reports', 'inspectorReportsLastUpdateTS'],
  ['reports', 'inspectorReports'],

  ['forms', 'map'],
	['forms', 'didLoad'],
	['forms', 'lastUpdated'],
	['forms', 'lastUpdateAvailable'],
  
  ['quasiStatics', 'recentlySubCategories'], 
]; 


const platformProjectStatePathes = {
  web: [
    ['projects', 'didLoad'],
    ['buildings', 'didLoad'],
    ['floors', 'didLoad'],
    ['units', 'didLoad'],
    ['stages', 'didLoad'],
		['checklists', 'didLoad'],
    ['checklistItems', 'didLoad'],
    ['configurations', 'didLoad'],

    ['checklistItemsInstances', 'map'],
    ['checklistItemsInstances', 'lastUpdateTS'],
  ]
}

export const getGlobalStatePathes = () => {
  return Array.from(globalStatePathes);
}

export const getProjectStatePathes = () => {
  const platform = platformActions.app.getPlatform();
  let statePathes = Array.from(projectStatePathes);
  if (platformProjectStatePathes[platform])
    statePathes = statePathes.concat(platformProjectStatePathes[platform]);

  return statePathes;
}

