import * as instancesStatus from './clItemInstanceStatus'
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { getAppState } from '../configureMiddleware';
import ExtraError from '../lib/errors/extraError';
import { removeProjectsStorage } from '../projects/actions';
import { updateRetryRealm } from '../../common/lib/realm/funcs.js';
import _ from 'lodash';
import { uploadPropertiesInstances } from '../propertiesInstances/actions';
import { fetchByTS, debugParams, replaceMaxUpdateTSIfNeeded } from '../lib/utils/utils';
import { platformActions } from "../platformActions";
import { safeToJS } from '../permissions/funcs';

export const CREATE_CHECKLIST_ITEM_INSTANCE 		  		= 'CREATE_CHECKLIST_ITEM_INSTANCE';
export const FETCH_CHECKLIST_ITEM_INSTANCES   		  	= 'FETCH_CHECKLIST_ITEM_INSTANCES';
export const GET_CHECKLIST_ITEM_INSTANCES   		  		= 'GET_CHECKLIST_ITEM_INSTANCES';
export const END_CHECKLIST_ITEM_INSTANCES_LISTENER    = 'END_CHECKLIST_ITEM_INSTANCES_LISTENER';
export const UPDATE_CHECKLIST_ITEM_INSTANCE_STATUS    = 'UPDATE_CHECKLIST_ITEM_INSTANCE_STATUS';
export const UPDATE_BULK_STATUS    										= 'UPDATE_BULK_STATUS';
export const DELETE_CHECKLIST_INSTANCE    						= 'DELETE_CHECKLIST_INSTANCE';
export const GET_NEW_CHECKLIST_ITEM_INSTANCE_ID       = 'GET_NEW_CHECKLIST_ITEM_INSTANCE_ID';
export const CLEAN_CACHED_ITEMS_INSTANCES       			= 'CLEAN_CACHED_ITEMS_INSTANCES';
export const CHECKLIST_ITEM_INSTANCES_DONE_LOADING		= 'CHECKLIST_ITEM_INSTANCES_DONE_LOADING';
export const RETRY_UPLOAD_LOCAL_CHECKLISTS = 'RETRY_UPLOAD_LOCAL_CHECKLISTS';
export const CLEAN_CHECKLIST_ITEMS_INSTANCES_CACHE_DATA = 'CLEAN_CHECKLIST_ITEMS_INSTANCES_CACHE_DATA';

let bouncerCollector = {};

export function getChecklistItemsInstances(viewer, projectId, cleanAll) {
	return ({ dispatch, lokiInstance, realmInstance, platformActions }) => {
		// if (cleanAll)
		// 	setChecklistInstanceValues({}, projectId, platformActions, realmInstance, lokiInstance, dispatch, cleanAll, true, "getChecklistItemsInstances - cleanAll");

		// const saveFunc = (_data) => {
		// 	if (debugParams.disableFetchByTSSave) return;
		// 	return prepareDebouncingSave(_data, projectId, platformActions, realmInstance, lokiInstance, dispatch, true, undefined, undefined, "getChecklistItemsInstances - prepareDebouncingSave");
		// };

		// let fetchParams = {
		// 	projectId,
		// 	viewer,
		// 	resource: {
		// 		name: 'checklistInstances',
		// 		doneLoading: CHECKLIST_ITEM_INSTANCES_DONE_LOADING,
		// 		subjectName: 'itemInstances',
		// 		firebasePath: 'checklists'
		// 	},
		// 	saveFunc,
		// 	getLastUpdateTS: (dataFromApi) => getLastUpdateTS(realmInstance, lokiInstance, projectId, dataFromApi)
		// };

		// fetchByTS(fetchParams);

		return {
			type: CHECKLIST_ITEM_INSTANCES_DONE_LOADING,
			payload: { projectId, scopeId: projectId }
		};
	};
}

function getLastUpdateTS(realmInstance, lokiInstance, scopeId, dataFromApi) {
	let lastUpdateTS = 0;

	if (platformActions.app.getPlatform() == "web") {
		// Get the lastTS from the objects
		Object.values(dataFromApi || {}).forEach(x => {if (x && x.updatedTS && x.updatedTS > lastUpdateTS) lastUpdateTS = x.updatedTS});

		let chkInstances = getAppState().getNested(['checklistItemsInstances','map', scopeId]);
		_.forEach(chkInstances, checklist => {
			_.forEach(checklist, checklistItem => {
				_.forEach(checklistItem, instance => {
						if (instance && instance.updatedTS > lastUpdateTS)
							lastUpdateTS = instance.updatedTS;
					});
			});
		});
	} else {
		lastUpdateTS = realmInstance.checklistItemsInstances.objects('checklistItemsInstance1').filtered(`projectId = "${scopeId}"`).max('updatedTS');
	}
	return lastUpdateTS || 0;
}

function prepareDebouncingSave(checklistItemsInstances, projectId, platformActions, realmInstance, lokiInstance, dispatch, immediateSave, cleanAll, forceLocalSave, stackTraceLog) {
	if (!cleanAll && Object.values(checklistItemsInstances || {}).length == 0) return;

	if (!bouncerCollector[projectId])
		bouncerCollector[projectId] = {};
	Object.values(checklistItemsInstances || {}).forEach(x => {bouncerCollector[projectId][x.id] = x});
	
	if (immediateSave) 
		setChecklistInstanceValues(bouncerCollector, projectId, platformActions, realmInstance, lokiInstance, dispatch, cleanAll, forceLocalSave, stackTraceLog);
	else
		setChecklistInstanceValuesDebounced(bouncerCollector, projectId, platformActions, realmInstance, lokiInstance, dispatch, cleanAll, forceLocalSave, stackTraceLog);
	return;
}

function setChecklistInstanceValues(bouncerCollector, projectId, platformActions, realmInstance, lokiInstance, dispatch, cleanAll, forceLocalSave, stackTraceLog) {
	let checklistItemsInstances = [];
	let cleanedFromBouncer = [];
	if (!bouncerCollector[projectId])
		bouncerCollector[projectId] = {};
	Object.keys(bouncerCollector[projectId]).forEach(key => {
		checklistItemsInstances.push(bouncerCollector[projectId][key]);
		cleanedFromBouncer.push(key);
	})
	cleanedFromBouncer.forEach(key => {delete bouncerCollector[projectId][key]});

	if (platformActions.app.getPlatform() != "web") {
		saveToRealm(checklistItemsInstances, undefined, projectId, realmInstance, null, cleanAll, platformActions, forceLocalSave, stackTraceLog);
	}
	else
		dispatch({ type: GET_CHECKLIST_ITEM_INSTANCES, payload: { checklistItemsInstances, cleanAll, projectId}});
}

const setChecklistInstanceValuesDebounced = AwesomeDebouncePromise(setChecklistInstanceValues, 400);

function saveToRealm(checklistItemsInstances, newLastUpdateTS, projectId, realmInstance, ignoreTimestamp, cleanAll, platformActions, forceLocalSave, stackTraceLog) {
	if (!cleanAll && (checklistItemsInstances || []).length == 0)
		return;

	checklistItemsInstances = (checklistItemsInstances || []).sort((checklistItemsInstanceA, checklistItemsInstanceB) => (checklistItemsInstanceA.updatedTS || 0) > (checklistItemsInstanceB.updatedTS || 0) ? -1 : 1);

	let realm = realmInstance.checklistItemsInstances;
	let currBatchMaxLastUpdateTS = _.get(checklistItemsInstances, [0 , 'updatedTS'], 0);
	checklistItemsInstances = checklistItemsInstances.map(itemsInstance => {
		let extraData = itemsInstance.extraData ? Object.values(itemsInstance.extraData).filter(item => (item.id && item.instanceId)) : [];
		return Object.assign(itemsInstance, { extraData });
	})

	updateRetryRealm(checklistItemsInstances, projectId, realmInstance.retry_checklistItemsInstances, 'checklistItemsInstance1');
	let ignoreList = {};
	if (!forceLocalSave && checklistItemsInstances.length == 1) { // TODO: Performance: Update the quary to check for all ids that are on checklistItemsInstances on 1 quary, and remove the limit of check only on 1 checklistItemsInstance case
		checklistItemsInstances.forEach(inChecklistItemsInstance => {
			let savedInstances = realm.objects('checklistItemsInstance1').filtered(`projectId = "${projectId}" AND id = "${inChecklistItemsInstance.id}"`);
			if (savedInstances.length == 1) {
				let savedInstance = savedInstances[0].realmToObject();
				
				if (!inChecklistItemsInstance.updatedTS ||
  				((Boolean(inChecklistItemsInstance.isLocal) == Boolean(savedInstance.isLocal)) &&
				   (savedInstance.updatedTS && savedInstance.updatedTS.getTime && (inChecklistItemsInstance.updatedTS == savedInstance.updatedTS.getTime()))))
					ignoreList[inChecklistItemsInstance.id] = true;
			}
		});
	}

	if (Object.keys(ignoreList || {}).length < checklistItemsInstances.length) {
		realm.beginTransaction();
		try {
			if (cleanAll) {
				let allChecklistInstances = realm.objects('checklistItemsInstance1').filtered(`projectId = "${projectId}"`);
				//	let allLastUpdateTS = realm.objects('lastUpdateTS').filtered('projectId = "' + projectId + '" AND type = "checklistItemsInstance"');
				//realm.delete(allLastUpdateTS);
				realm.delete(allChecklistInstances); 
			}

			// All current local items
			checklistItemsInstances.forEach(checklistItemsInstance => {
				if (ignoreList[checklistItemsInstance.id])
					return;

				if (checklistItemsInstance && checklistItemsInstance.id && checklistItemsInstance.checklistItemId) {
					if (!checklistItemsInstance.isDeleted) {
						let newObj = { ...checklistItemsInstance.realmToObject(), isLocal: Boolean(checklistItemsInstance.isLocal), projectId };
						realm.create('checklistItemsInstance1', newObj, 'modified');
					} else {
						let allChecklistInstances = realm.objects('checklistItemsInstance1').filtered(`projectId = "${projectId}" AND id = "${checklistItemsInstance.id}"`);
						realm.delete(allChecklistInstances);
					}
				} else {
					console.warn('checklistItemsInstance missing ID') // TODO: Send to bugsnag
					platformActions.sentry.notify('checklistItemsInstance missing ID', {...(checklistItemsInstance || {}).realmToObject()});
				}
			});

			// if (projectId)
			// 	realm.create('lastUpdateTS', {id: projectId + '_checklistItemsInstance', lastUpdateTS: newLastUpdateTS, projectId, type: 'checklistItemsInstance'}, true)
			// else {
			// 	platformActions.sentry.notify('checklistItemsInstance lastUpdateTS projectId missing', newLastUpdateTS);
			// 	console.warn('checklistItemsInstance lastUpdateTS ID') // TODO: Send to bugsnag
			// }
			replaceMaxUpdateTSIfNeeded(currBatchMaxLastUpdateTS, realm, 'checklistItemsInstance1', `projectId = "${projectId}"`);
			realm.commitTransaction();
		} catch (e) {

			realm.cancelTransaction();
			throw e;
		}
	}
}

// export async function removeInstancesFromLoki(lokiInstance) { // TODO: CEM-5125
// 	//await lokiInstance.getCollection('lastUpdateTS').cementoFullDelete({ type: 'checklistItemsInstances' });
// 	await lokiInstance.getCollection('checklistItemsInstances').cementoFullDelete();
// }

export function removeInstancesFromRealm(realmInstance) {

	let checklistItemsInstances = realmInstance.checklistItemsInstances;

	let allChecklistInstances = checklistItemsInstances.objects('checklistItemsInstance1');
//	let allLastUpdateTS = checklistItemsInstances.objects('lastUpdateTS').filtered('type = "checklistItemsInstance"');

	checklistItemsInstances.write(() => {
	//	checklistItemsInstances.delete(allLastUpdateTS);
		checklistItemsInstances.delete(allChecklistInstances);
	});
}


export function endChecklistItemsInstancesListener(projectId) {	
	return ({ dispatch, firebaseDatabase }) => {
		firebaseDatabase().ref('checklists/' + projectId + '/itemInstances').off('value');
		firebaseDatabase().ref('checklists/' + projectId + '/itemInstances').off('child_added');
		firebaseDatabase().ref('checklists/' + projectId + '/itemInstances').off('child_changed');

		return {
			type: END_CHECKLIST_ITEM_INSTANCES_LISTENER,
			payload: { projectId }
		};
	};
}


export function getNewChecklistItemInstanceId(projectId) {	
	return ({ dispatch, firebaseDatabase }) => {
		let push = firebaseDatabase().ref('checklists/' + projectId + '/itemInstances').push();

		return {
			type: GET_NEW_CHECKLIST_ITEM_INSTANCE_ID,
			payload: { key:push.key }
		};
	};
}


let uploadExtraDataInstances = async function (projectId, extraData, dispatch, checklistItemInstanceId) {
	const extraDataInstancesBySubjectName = _.groupBy(extraData, item => item.subjectName);

	let uploadedInstancesArr = [];
	await Promise.all(
		_.entries(extraDataInstancesBySubjectName).map(async ([subjectName, instances]) => {
			const uploadActionRes = await dispatch(uploadPropertiesInstances(projectId, instances, subjectName, false));
			uploadedInstancesArr = uploadedInstancesArr.concat(uploadActionRes.instances);
		})
	);

	const mappedExtraDataInstances = uploadedInstancesArr.reduce((acc, instance) => _.set(acc, instance.propId, { id: instance.propId, instanceId: instance.id }), {});

	return mappedExtraDataInstances;
}


let createNewChecklistItemInstance = async function (firebaseDatabase, removeEmpty, projectId, inChecklistItemInstance, extraData, dispatch) {
	let currTime = new Date().getTime();
	if (!(projectId && inChecklistItemInstance && inChecklistItemInstance.checklistItemId))
		throw new ExtraError('createNewChecklistItemInstance - missing values', { projectId, inChecklistItemInstance });

	let checklistItemInstance = _.clone(inChecklistItemInstance.realmToObject());
	checklistItemInstance.id = inChecklistItemInstance.id || firebaseDatabase().ref('checklists/' + projectId + '/itemInstances').push().key;
	checklistItemInstance.isLocal = true;

	if (extraData)
		checklistItemInstance.extraData = await uploadExtraDataInstances(projectId, extraData, dispatch, checklistItemInstance.id);
	
	checklistItemInstance.createdTS = currTime;
	checklistItemInstance = removeEmpty(checklistItemInstance, 'createNewChecklistItemInstance');
	return checklistItemInstance;

}


export function uploadFailedChecklistItemInstance(checklistItemInstance) {
	return ({ firebase, removeEmpty, platformActions, realmInstance, lokiInstance, dispatch }) => {
		let newChecklistItemInstance;
		let projectId = checklistItemInstance.projectId;
    
		if (projectId && checklistItemInstance) {
			newChecklistItemInstance = checklistItemInstance.realmToObject();
			if (newChecklistItemInstance.extraData) {
				let extraData = {};
				newChecklistItemInstance.extraData.loopEach((key, currExtraData) => {
					const { id, instanceId } = currExtraData;
					extraData[id] = { id, instanceId };
				});
				newChecklistItemInstance.extraData = extraData;
			}
			delete newChecklistItemInstance['projectId'];
			delete newChecklistItemInstance['isLocal'];
			newChecklistItemInstance = removeEmpty(newChecklistItemInstance, 'uploadFailedChecklistItemInstance');

			let updates = {}; 
			updates['checklists/' + projectId + '/itemInstances/' + checklistItemInstance.id] = newChecklistItemInstance;
      
			firebase.update(updates, () => {
				prepareDebouncingSave({'0':newChecklistItemInstance}, projectId, platformActions, realmInstance, lokiInstance, dispatch, false, undefined, undefined, "uploadFailedChecklistItemInstance - firebase.update callback");
			});
		}

		return {
			type: CREATE_CHECKLIST_ITEM_INSTANCE,
			payload: { checklistItemInstance: newChecklistItemInstance, projectId }
		};
	};
}


export function uploadNewChecklistItemInstance(projectId, inChecklistItemInstance, instanceParentChecklistId, issueCreationIndication, totalItems, immediateSave, extraData) {
	return ({ validate, firebase, firebaseDatabase, removeEmpty, lokiInstance, dispatch, realmInstance, platformActions }) => {
		const getPromise = async () => {
			let checklistItemInstance;
			if (projectId && inChecklistItemInstance) {
				checklistItemInstance = await createNewChecklistItemInstance(firebaseDatabase, removeEmpty, projectId, inChecklistItemInstance, extraData, dispatch);
				let localClone = {...checklistItemInstance.realmToObject()};
				if (immediateSave)
					prepareDebouncingSave({ '0': localClone }, projectId, platformActions, realmInstance, lokiInstance, dispatch, true, false, true, undefined, 'uploadNewChecklistItemInstance-immidiateSave');
				let updates = {};
				delete checklistItemInstance['isLocal'];
				updates['checklists/' + projectId + '/itemInstances/' + checklistItemInstance.id] = checklistItemInstance;
				firebase.update(updates, () => {
					prepareDebouncingSave({ '0': checklistItemInstance }, projectId, platformActions, realmInstance, lokiInstance, dispatch, true, undefined, undefined, 'uploadNewChecklistItemInstance - firebase.update callback');
				});
			}

			dispatch({ type: CREATE_CHECKLIST_ITEM_INSTANCE, payload: { checklistItemInstance, projectId, totalItems, instanceParentChecklistId, issueCreationIndication } });
			return { checklistItemInstance, projectId, totalItems, instanceParentChecklistId, issueCreationIndication };
		};
		return {
			type: CREATE_CHECKLIST_ITEM_INSTANCE,
			payload: getPromise()
		};
	};
}


// TODO: Merge uploadNewChecklistItemInstance and this one
export function updateChecklistItemInstanceStatus(projectId, instanceId, newStatus, inChecklistItemInstance, instanceParentChecklistId, issueCreationIndication, totalItems, prevStatus, extraData) {
	return ({ validate, firebase, firebaseDatabase, removeEmpty, lokiInstance, dispatch, realmInstance, platformActions }) => {
		const getPromise = async () => { 
			let newChecklistItemInstance = null;
			let checklistItemInstance = inChecklistItemInstance || getChecklistItemInstance(platformActions, realmInstance, lokiInstance, instanceId, projectId);
			if (projectId && (checklistItemInstance && checklistItemInstance.id) || instanceId) {
				let id = instanceId || checklistItemInstance.id;

				if (checklistItemInstance) {
					newChecklistItemInstance = removeEmpty({ ...checklistItemInstance.realmToObject(), id, status: newStatus, checklistItemId: checklistItemInstance.checklistItemId || instanceParentChecklistId }, 'updateChecklistItemInstanceStatus');
					newChecklistItemInstance.isLocal = true;
					prepareDebouncingSave({'0':newChecklistItemInstance}, projectId, platformActions, realmInstance, lokiInstance, dispatch, true, false, true, "updateChecklistItemInstanceStatus");
				}

				let updates = {};
				if (extraData)
					updates['checklists/' + projectId + '/itemInstances/' + id + '/extraData'] = await uploadExtraDataInstances(projectId, extraData, dispatch, id);
				updates['checklists/' + projectId + '/itemInstances/' + id + '/status'] = newStatus;
				updates['checklists/' + projectId + '/itemInstances/' + id + '/updatedTS'] = null;
				firebase.update(updates, () => {
					if (newChecklistItemInstance)
						prepareDebouncingSave({'0':{...newChecklistItemInstance, isLocal:false}}, projectId, platformActions, realmInstance, lokiInstance, dispatch, true, undefined, undefined, "updateChecklistItemInstanceStatus - firebase.update callback")
				});			}

			dispatch({ type: UPDATE_CHECKLIST_ITEM_INSTANCE_STATUS, payload: { checklistItemInstance: newChecklistItemInstance, projectId, newStatus, prevStatus, totalItems, instanceParentChecklistId, issueCreationIndication } });
			return { checklistItemInstance: newChecklistItemInstance, projectId, newStatus, prevStatus, totalItems, instanceParentChecklistId, issueCreationIndication };
		};

		return {
			type: UPDATE_CHECKLIST_ITEM_INSTANCE_STATUS,
			payload: getPromise()
		};
	};
}


export function bulkStatusUpdate(newStatus, projectId, locationId, allRelevantItems, allRelevantInstances, immediateSave, checklistId, timeFilter) {
	return ({ validate, firebase, firebaseDatabase, removeEmpty, lokiInstance, dispatch, realmInstance, platformActions }) => {
		const getPromise = async () => {
			let map = {};
			let updates = {};
			let instancesByItems = {};
			let requirementsExists = false;
			let didSetEmptyCell = false;

			for (let inst of (allRelevantInstances || [])) {
				if (inst)
					instancesByItems[inst.checklistItemId] = inst;
			}

			for (let item of (allRelevantItems || [])) {
				if (!item) continue;
				let currRequirementExists = Object.values(item.requirements || {}).filter(Boolean).length > 0 && !instancesByItems[item.id];
				let currExtraDataRequirementExists = Object.values(item.extraData || {}).filter(prop => !prop.optional && !prop.readOnly).length > 0;
				requirementsExists = requirementsExists || currRequirementExists || currExtraDataRequirementExists;
				if (currRequirementExists || currExtraDataRequirementExists) continue;
				if (!instancesByItems[item.id]) {
					let newInstance = await createNewChecklistItemInstance(firebaseDatabase, removeEmpty, projectId, { checklistItemId: item.id, status: newStatus, locationId, checklistId, targetTS: timeFilter });
					delete newInstance['isLocal'];
					updates['checklists/' + projectId + '/itemInstances/' + newInstance.id] = newInstance;
					map[newInstance.id] = Object.assign({}, newInstance, { isLocal: true });
					didSetEmptyCell = true;
				} else if (!instancesByItems[item.id].status || instancesByItems[item.id].status == instancesStatus.CLI_STATUS_NONE) {
					let clone = instancesByItems[item.id].toJS ? instancesByItems[item.id].toJS() : { ...instancesByItems[item.id] };
					clone.updatedTS = null;
					clone.status = newStatus;
					delete clone['isLocal'];
					updates['checklists/' + projectId + '/itemInstances/' + clone.id] = clone;
					map[clone.id] = Object.assign({}, clone, { isLocal: true });
				}
			};

			if (immediateSave)
				prepareDebouncingSave(map, projectId, platformActions, realmInstance, lokiInstance, dispatch, false, false, true, "bulkStatusUpdate - immidateSave");


			if (Object.values(updates).length)
				firebase.update(updates, () => {
					let newMap = {};
					Object.keys(map || {}).forEach(instanceId => { newMap[instanceId] = Object.assign({}, map[instanceId], { isLocal: false }); });
					prepareDebouncingSave(newMap, projectId, platformActions, realmInstance, lokiInstance, dispatch, false, undefined, undefined, "bulkStatusUpdate - firebase.update");
				});

			dispatch({ type: UPDATE_BULK_STATUS, payload: { didCompleteChecklistForTheFirstTime: Boolean(!requirementsExists && didSetEmptyCell), requirementsExists, projectId, newStatus, locationId } });
			return { didCompleteChecklistForTheFirstTime: Boolean(!requirementsExists && didSetEmptyCell), requirementsExists, projectId, newStatus, locationId };
		};
		return {
			type: UPDATE_BULK_STATUS,
			payload: getPromise()
		};
	};
}

export function getAllFailedToUploadChecklists(locationId) {
	return ({ getState, platformActions, realmInstance, lokiInstance }) => {
		const getPromise = async () => {
			try {
				let viewer = getState().users.viewer;
				if (platformActions.app.getPlatform() == "web") { // TODO: CEM-5125
					// if (viewer) {
					// 	let localChecklistItemsInstance = lokiInstance.getCollection('checklistItemsInstances').cementoFind({ 'isLocal': true, ...locationId ? { locationId } : {} });
					// 	return ({localChecklistItemsInstance, counter: localChecklistItemsInstance.length});
					// }
				} else {
					let localChecklistItemsInstance = [];
					if (viewer) {
						// Delete all uploaded checklists
						let realmRetry = realmInstance.retry_checklistItemsInstances;
						realmRetry.write(() => {
							let uploadedLocalChecklistItemsInstance = realmRetry.objects('checklistItemsInstance1').filtered('isLocal = false');
							realmRetry.delete(uploadedLocalChecklistItemsInstance);
						});

						let query = 'isLocal = true';
						if (locationId) query += ` AND locationId = "${locationId}"`;
						
						localChecklistItemsInstance = realmInstance.retry_checklistItemsInstances.objects('checklistItemsInstance1').filtered(query).sorted('createdTS');
						return ({localChecklistItemsInstance, counter: localChecklistItemsInstance.length});
					}
					return {};
				}
				
			} catch (error) {
				platformActions.sentry.notify('getAllFailedToUploadChecklists error', {error});
				throw error;
			}
		}
		return {
			type: RETRY_UPLOAD_LOCAL_CHECKLISTS,
			payload: getPromise()
		};
	};	
}


export function getChecklistItemInstance(platformActions, realmInstance, lokiInstance, instanceId, projectId) {
	let instance = null;
	if (platformActions.app.getPlatform() == "web") {
		let checklistInstances = getAppState().getNested(['checklistItemsInstances','map', projectId]);
				checklistInstances = safeToJS(checklistInstances);
		_.forIn(checklistInstances, locations => {
			if (instance)
				return false; // break
			_.forIn(locations, checklistInstances => {
				if (instance)
					return false; // break

				instance = _.values(checklistInstances).find(instance => instance.id === instanceId);
			});
		});
	} else {
		let instances = realmInstance.checklistItemsInstances.objects('checklistItemsInstance1').filtered('projectId = "' + projectId + '" AND id = "' + instanceId + '"');
		if (instances.length)
			instance = instances[0];
	}

	return instance;
}


  