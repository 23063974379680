import {
  getUtilsConfigObject,
  getAppState,
  apiListenersDeps,
  firebaseDeps
} from "../../configureMiddleware";
import ExtraError from "../errors/extraError";
import _ from "lodash";
import { platformActions } from "../../platformActions";
import { getFromRealm, saveToRealm } from "../realm/funcs";
import moment from "moment";
import { schemasInfo } from '../offline-mode/config';
import mime from 'react-native-mime-types';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { isEmptyValue } from '../../app/funcs';

const isProd = process.env.NODE_ENV == 'production';
export const debugParams = {
  disableSaveToStorage: !isProd && false,
  disableMixpanel: !isProd && false,
  disableFirebaseFetch: !isProd && false,
  disableFirebaseListener: !isProd && false,
  disableRetries: !isProd && false,
  disableFetchByTSSave: !isProd && false,
  disableGetLastUpdateTS: !isProd && false,
  disableProjectTabNavigationStateParamsUpdate: !isProd && false
}

const getBase64StringInfo = base64String => {
  let base64Info = {};

  const find = ';base64,';
  if (typeof base64String === 'string' && base64String.indexOf(find) !== -1) {
    const splitB64 = base64String.replace('data:', '').split(find);
    if (splitB64.length === 2) {
      base64Info.type = splitB64[0];
      base64Info.extension = mime.extension(base64Info.type) || null;
      base64Info.uri = splitB64[1];
    } else if (splitB64.length === 1) base64Info.uri = splitB64[0];

    base64Info.dataString = base64String;
  }

  return Object.keys(base64Info).length ? base64Info : null;
};

export const uploadFileToStorage = async function (pathInStorage, localFilePath, titleOnDownload) {
  let url;
  let uploadTask;
  const base64Info = getBase64StringInfo(localFilePath);
  if (base64Info) {
    const fileExtension = `${base64Info.extension ? '.' + base64Info.extension : ''}`;
    const fullFileName = `${titleOnDownload}${fileExtension}`;
    uploadTask = await firebaseDeps.firebaseStorage().ref(pathInStorage).child(fullFileName).putString(base64Info.uri, 'base64');
  }
  else{
    // hasn't been tested yet
    uploadTask = await firebaseDeps.firebaseStorage().ref(serverFolder).child(targetFileName).putFile(localFilePath);
  }
  url = _.get(uploadTask, ['downloadURL']);
  if (!url)
    url = await firebaseDeps.firebaseStorage().ref(_.get(uploadTask, ['metadata', 'fullPath'])).getDownloadURL();
  return url;
};

export const threadSleep = function (delay) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      resolve(true);
    }, delay);
  });
};

export const getResourceById = async (
  resource,
  projectId,
  resourceId,
  callback
) => {
  let api = resource.api + "/" + resourceId;
  let firebaseSuffix = resource.firebaseSuffix;
  firebaseSuffix = (firebaseSuffix ? firebaseSuffix + "/" : "") + resourceId;
  return await getSnapshotData(
    { ...resource, api, firebaseSuffix },
    projectId,
    "projectId",
    callback
  );
};

const getSnapshotDataFirebase = async ({
	resourceFirebase,
	scopeId,
	resourceFirebaseSuffix,
	resourceName,
	firebaseDatabase,
	scope,
	saveFunc,
  subjectName,
}) => {
	let result = null;
	let firebasePath = _.compact([resourceFirebase, scopeId, resourceFirebaseSuffix]).join('/');
	let locFirebaseRef = firebaseDatabase().ref(firebasePath);
	getSnapshotData_listeners[firebasePath] = locFirebaseRef;

	if (!saveFunc) {
		result = await locFirebaseRef.once('value');
		if (!result || !result.val) {
			throw ExtraError('No result.val() returned from firebase', {
				scope,
				resourceFirebase,
			});
		}
		result = result.val();
	} else {
		locFirebaseRef.on('child_added', data => {
			let val = data.val();
			if (val.id) saveFunc({ 0: val }, undefined, undefined, resourceName, 'child_added', subjectName);
		});

		locFirebaseRef.on('child_changed', data => {
			let val = data.val();
			if (val.id) saveFunc({ 0: val }, undefined, undefined, resourceName, 'child_changed', subjectName);
		});
	}

	return result;
};

const getSnapshotData_listeners = {};

export const getSnapshotData = async (
  resource,
  scopeId,
  scope = "projectId",
  saveFunc
) => {
  const { firebaseDatabase, platformActions, apiServer } =
    getUtilsConfigObject();
  let result;
  let url;
  let resourceApi = resource.api;
  let resourceFirebase = resource.firebase || resource.api;
  let resourceName = resource.resourceName || resource.api;
  let subjectName = resource.subjectName;
  let resourceFirebaseSuffix = resource.firebaseSuffix;
  let queryParams = resource.queryParams;
  if (isFetchInProgress(scopeId, resourceApi, resourceName, queryParams)) {
    return;
  }
  try{
    setFetchInProgress(scopeId, resourceApi, resourceName, true, queryParams);
  let MSClientConfig =
    _.isFunction(getAppState) &&
    getAppState().getNested([
      "configurations",
      scopeId,
      "clientMS",
      "bulk",
      resourceName,
    ]);
   
  if (MSClientConfig) {
    try {
      url = `${apiServer}/v1/${resourceApi}?${scope}=${scopeId}`;
      _.forIn(
        queryParams,
        (val, key) =>
          (url += `&${key}=${_.isArray(val) ? JSON.stringify(val) : val}`)
      );
      result = await platformActions.net.fetch(url);
      result = await result.getJson();

    } catch (err) {
      // Fallback to firebase
      result = await getSnapshotDataFirebase({
        resourceFirebase,
        scopeId,
        resourceFirebaseSuffix,
        resourceName,
        firebaseDatabase,
        scope,
        saveFunc,
        subjectName,
      });
    }
  } else {
    result = await getSnapshotDataFirebase({
			resourceFirebase,
			scopeId,
			resourceFirebaseSuffix,
			resourceName,
			firebaseDatabase,
			scope,
			saveFunc,
      subjectName
		});
  }

  let retObj = {};
  retObj[scope] = scopeId;
  retObj[resourceName] = result;

  return retObj;
}
finally{
    setFetchInProgress(scopeId, resourceApi, resourceName, false, queryParams);
}
};

let io = require("socket.io-client");
const versionPrefix = "v1/";

//TODO: VERY TEMP serverSocket
export const setListeners = async (resource, settings, func) => {
  //projectId,scope,scopeId)=>{
  if (!resource.startsWith(versionPrefix)) resource = versionPrefix + resource;
  const { id: viewerId, phoneNumber } = settings.viewer || {};
  const { apiServer } = getUtilsConfigObject();
  const token = viewerId;
 
  const serverSocket = io.connect(apiServer, {
    query: `clientId=${viewerId}&phoneNumber=${phoneNumber}&token=${token}`,
  });

  serverSocket.on("connect", () => {
    let {  scope, scopeId, lastUpdateTS } = settings;
    //move the fetchData into setListeners - IDAN
    serverSocket.on(`change_${resource}`, (object) => func(object));
    serverSocket.emit("subscribe", {
      to: resource,
      updatedTS: lastUpdateTS || 0,
      projectId: scopeId,
      scope,
      scopeId,
    });
    // ||(new Date().getTime()) ?
  });
};

export async function replaceMaxUpdateTSIfNeeded(currBatchMaxLastUpdateTS, realm, realmSchemaName, quary, subjectName) {
  // Check if the new maxLastUpdateTS is saved in db, and if not (In case it was from the isDeletd - Replace the current max)
  if (currBatchMaxLastUpdateTS && (realm.objects(realmSchemaName).filtered(quary).max('updatedTS') < currBatchMaxLastUpdateTS)) {
    let currObjects = realm.objects(realmSchemaName).filtered(quary).sorted('updatedTS', true);
    if (currObjects.length) {
      let maxCurrObject = currObjects[0];
      realm.create(realmSchemaName, { ...maxCurrObject.realmToObject(), updatedTS:currBatchMaxLastUpdateTS }, 'modified');
    }
  }
};

const sortedStringify = obj => _.isObject(obj) ? JSON.stringify(obj, Object.keys(obj).sort()) : obj;
const generateResourceFullName = (resourceName, subjectName, query) => `${resourceName}_${subjectName || ""}_${sortedStringify(query)}`;


const MAX_FETCH_TIME = 60 * 1000;
const isFetchInProgress = (scopeId, resourceName, subjectName, queryParams) => {
  let fetchStartTS = _.get(this, ['isFetchingInProgress', scopeId, generateResourceFullName(resourceName, subjectName, queryParams)]);
  return (fetchStartTS && (Date.now() - fetchStartTS < MAX_FETCH_TIME));
}

const setFetchInProgress = (scopeId, resourceName, subjectName, status, queryParams) => {
  _.set(this, ['isFetchingInProgress', scopeId, generateResourceFullName(resourceName, subjectName, queryParams)], status ? Date.now() : null);
}



var allListeners = {};

/**
 *
 * @param {string} projectId
 * @param {string} firebasePath
 * @param {import('firebase').database.EventType} eventType
 * @param {function} onUpdate - callback called with the update
 * @returns {Promise<function>} function to call to remove the listener
 */

export const startProjectFirebaseListener = (
  projectId,
  firebasePath,
  eventType,
  onUpdate
) => {
  const { firebaseDatabase } = apiListenersDeps;
  if (!_.get(allListeners, [projectId, firebasePath])) {
    _.set(allListeners, [projectId, firebasePath], true);
    
    const ref = firebaseDatabase().ref(firebasePath);
    ref.on(
      eventType,
      (snapshot) => Boolean(onUpdate) && onUpdate(snapshot.val(), snapshot.key, snapshot)
    );
  }

  return () => {
    endProjectListener(projectId, firebasePath);
  };
};

export const endProjectListener = (projectId, firebasePath) => {
  const { firebaseDatabase } = apiListenersDeps;
  if (!_.get(allListeners, [projectId, firebasePath])) 
    return;

  const ref = firebaseDatabase().ref(firebasePath);
        ref.off("value");
        ref.off("child_added");
        ref.off("child_changed");
  _.set(allListeners, [projectId, firebasePath], false);
}

export function endAllProjectListeners(projectId) {
  const { firebaseDatabase } = apiListenersDeps;

  Object.keys(allListeners[projectId] || {}).forEach((firebasePath) => {
    const ref = firebaseDatabase().ref(firebasePath);
          ref.off("value");
          ref.off("child_added");
          ref.off("child_changed");
    _.set(allListeners, [projectId, firebasePath], false);
  });
}

let bouncerCollector = {};
const BOUNCER_TIMING = 400; // ms
const firebaseListenerCallback = ({ resource, event, saveFunc, data, scopeId, useDebouncer = true }) => {
  let val = data.val();
  if (val.id) {
    if (_.get(resource, ['schemaInfo', 'schemaName'])) {
      const local = getLocal({
        idsToGet: [val.id],
        projectId: scopeId,
        schemaType: _.get(resource, ['schemaInfo', 'schemaType']),
        schemaName: _.get(resource, ['schemaInfo', 'schemaName']),
        query: 'isLocal == TRUE AND lastUploadTS == 0'
      });

      const isWaitingToBeUploaded = !_.isEmpty(_.get(local, ['objects'], {}));
      if (isWaitingToBeUploaded)
        return;

    }

    const resourceName = resource?.name || 'default';
    if (useDebouncer) {
      const bouncerCollectorRootPathArr = [scopeId, resourceName, (resource.subjectName || ""), event];
      const objectsPathArr = [...bouncerCollectorRootPathArr, 'objects'];
      let debouncedSaveFunc = AwesomeDebouncePromise(() => {
        const objectsToSave = _.get(bouncerCollector, objectsPathArr);
        if (isEmptyValue(objectsToSave))
          return;

        _.set(bouncerCollector, objectsPathArr, {}); // clean before save in case save takes a while and other calls come in
        saveFunc(objectsToSave, undefined, undefined, resourceName, event, resource.subjectName);
      }, 
      BOUNCER_TIMING,
        { key: (resourceName, subjcetName) => (resourceName || "").concat(subjcetName || "")
      });

      _.set(bouncerCollector, [...objectsPathArr, val.id], val);
      debouncedSaveFunc(resourceName, resource?.subjectName);
    }
    else
      saveFunc({ '0': val }, undefined, undefined, resourceName, event, resource.subjectName);
  }
}


const fetchByTSFirebase = async ({
	firebasePath,
	scopeId,
	subjectName,
	firebaseDatabase,
	getLastUpdateTS,
	lastUpdateTS,
	resourceName,
	saveFunc,
	resource,
  fetchCompressedDataFromServer,
  queryParams
}) => {
  let dataFromApi = await fetchCompressedDataFromServer(
    scopeId,
    lastUpdateTS + 1,
    resourceName,
    subjectName,
    queryParams
  );

  let didFindResult = Boolean(Object.keys(dataFromApi || {}).length);
  if (!didFindResult) {
    dataFromApi = {};
  }

  saveFunc(dataFromApi, lastUpdateTS, true, resourceName, 'fetchByTS', subjectName);

	//firebase
	let path = `${firebasePath}/${scopeId}${subjectName ? '/' + subjectName : ''}`;
	let firebaseRef = firebaseDatabase().ref(path);

	// Get the lastUpdateTS
	let newLastUpdateTS = (didFindResult ? getLastUpdateTS(dataFromApi) : lastUpdateTS) + 1;
	if (debugParams.disableFirebaseFetch) return;
	let locFirebaseRef = firebaseRef.orderByChild('updatedTS').startAt(newLastUpdateTS);
	let snapshot = await locFirebaseRef.once('value');
	snapshot = snapshot.val();

	if (snapshot) saveFunc(snapshot, undefined, undefined, resourceName, 'snapshot', subjectName);

	if (debugParams.disableFirebaseListener) return;

	if (!_.get(allListeners, [scopeId, path])) {
		_.set(allListeners, [scopeId, path], true);
		const eventsToListen = ['child_added', 'child_changed'];
		eventsToListen.forEach(event =>
			locFirebaseRef.on(event, data => firebaseListenerCallback({ resource, event, saveFunc, data, scopeId })),
		);
	}
};

const shouldSimulateWSConnection = () => {
	return _.isFunction(getAppState) && getAppState().getNested(['configurations', 'fetchByTS', 'simulateWSConnection']);
}

const simulateWSConnection = ({ resource, scopeId, scope, saveFunc }) => {
	const interval =
		_.isFunction(getAppState) && getAppState().getNested(['configurations', 'fetchByTS', 'simulateInterval']);

	setInterval(async () => {
		await getSnapshotData(resource, scopeId, scope, saveFunc);
	}, interval);
};

export async function fetchByTS(params) {
  let {
    resource,
    saveFunc,
    getLastUpdateTS,
    projectId,
    scopeId: inScopeId,
    scope,
    viewer,
    queryParams,
  } = params;
  const {
    name: resourceName,
    doneLoading,
    firebasePath,
    subjectName,
  } = resource;
  const {
    dispatch,
    fetchCompressedDataFromServer,
    errorReport,
    firebaseDatabase,
  } = apiListenersDeps;
  
  let scopeId = inScopeId || projectId;
  if (isFetchInProgress(scopeId, resourceName, subjectName, queryParams))
    return;

  
  try {
    setFetchInProgress(scopeId, resourceName, subjectName, true, queryParams);
    let lastUpdateTS = getLastUpdateTS();

    try {
      let MSClientConfig =
        _.isFunction(getAppState) &&
        getAppState().getNested([
          "configurations",
          scopeId,
          "clientMS",
          "listeners",
          resourceName,
        ]);

      if (MSClientConfig) {
				try {
					if (shouldSimulateWSConnection()) {
						return simulateWSConnection(resource, scopeId, scope, saveFunc);
					}

					setListeners(resourceName, { lastUpdateTS, scopeId, scope, viewer }, data => {
						// TODO: Make sure we do not sign to the same event twice, and add it to the unsubscribe from the event
						// if (!_.get(allListeneres, [projectId, path])) {
						//   _.set(allListeneres, [projectId, path], true);
            // }
						lastUpdateTS = saveFunc({ 0: data }, lastUpdateTS++, undefined, resourceName, subjectName);
					});
				} catch (error) {
					await fetchByTSFirebase({
						firebasePath,
						scopeId,
						subjectName,
						firebaseDatabase,
						getLastUpdateTS,
						lastUpdateTS,
						resourceName,
						saveFunc,
						resource,
						fetchCompressedDataFromServer,
						queryParams,
					});
				}
			} else {
				await fetchByTSFirebase({
					firebasePath,
					scopeId,
					subjectName,
					firebaseDatabase,
					getLastUpdateTS,
					lastUpdateTS,
					resourceName,
					saveFunc,
					resource,
					fetchCompressedDataFromServer,
					queryParams,
				});
			}
    } catch (err) {
      errorReport(
        "listeners",
        new ExtraError(
          "listeners error",
          {
            function: "fetchByTS",
            queryParams,
            firebasePath,
            subjectName,
            resourceName,
            lastUpdateTS,
            scopeId,
            scope,
            viewerId: viewer ? viewer.id : "---",
          },
          err
        )
      );
    }
  } catch (err) {
    errorReport(
      "fetchCompressedDataFromServer",
      new ExtraError(
        "fetchCompressedDataFromServer error",
        {
          function: "fetchByTS",
          queryParams,
          firebasePath,
          subjectName,
          resourceName,
          scopeId,
          scope,
          viewerId: viewer ? viewer.id : "---",
        },
        err
      )
    );
  }
  finally {
    dispatch({
      type: doneLoading,
      payload: {
        beforeProcessing: false,
        success: true,
        scopeId,
        subjectName,
      },
    });
    setFetchInProgress(scopeId, resourceName, subjectName, false, queryParams);
  }
}

export const saveLocal = ({
  objectsToSave,
  lastUpdateTStypeId,
  projectId,
  schemaName,
  schemaType,
  preProcessObjectForLocalSaveFunc = null,
}) => {
  if (Object.keys(objectsToSave).length && projectId) {
    if (platformActions.app.getPlatform() !== "web")
      saveToRealm({
        objectsToSave,
        lastUpdateTStypeId,
        projectId,
        schemaName,
        schemaType,
        preProcessObjectForLocalSaveFunc,
      });
  }
};

/**
 *
 * @param {{
 *  idsToGet?: string[],
 *  projectId: string,
 *  schemaType: 'propertyInstances' | 'posts' | 'checklistItemInstances' | 'equipment' | 'employees',
 *  schemaName: 'post24' | 'equipment1' | 'employee1' | 'propertyInstance1' | 'checklistItemInstance1',
 *  query?: string,
 * }} param0
 * @returns
 */

export const getLocal = ({
  idsToGet = [],
  projectId,
  schemaName,
  schemaType,
  query = "",
}) => {
  // TODO: update query mechanism so its more robust
  let objects = {};
  if (platformActions.app.getPlatform() !== "web")
    objects = getFromRealm({
      idsToGet,
      projectId,
      schemaName,
      schemaType,
      query,
    }).objects;

  return { objects };
};

/**
 * @param {string} [path]
 * @returns {string} New firebase id
 */
export const getUniqueFirebaseId = (path = "uniqueIds") => {
  const { firebaseDatabase } = getUtilsConfigObject();
  return firebaseDatabase().ref(path).push().key;
};

/**
 * Removes nested "isLocal" property
 * @param {any} object
 * @param {boolean} [shouldCloneObject] - If true, will clone the object with lodash.cloneDeep function
 */
export const removeNestedIsLocal = (object, shouldCloneObject = true) => {
  if (_.isNil(object) || typeof object !== "object") return object;

  if (shouldCloneObject) object = _.cloneDeep(object);

  const mapFunc = (value, key) => {
    delete (value || {}).isLocal;
    return removeNestedIsLocal(value, false);
  };

  if (Array.isArray(object)) return object.map(mapFunc);
  else return _.mapValues(object, mapFunc);
};

/**
 * @param {string} path - Firebase path to get
 * @returns {Promise<any>}
 */
export const firebaseGet = async (path) => {
  const { firebaseDatabase } = apiListenersDeps;
  let ret = null;

  if (path) {
    ret = (await firebaseDatabase().ref(path).once("value")).val();
  }

  return ret;
};

/**
 * @typedef GetRoundedDateReturn
 * @property {number} day - day of month (1-31)
 * @property {number} month - month of year (1-12)
 * @property {number} year - year
 * @property {number} timestamp - UTC timestamp representing 00:00 AM of this date
 * @param {Date} [date]
 * @returns {GetRoundedDateReturn}
 */
export const getRoundedDate = (date) => {
  date = date || new Date();

  const currMoment = moment(date);
  const roundedDate = new Date(
    currMoment.year(),
    currMoment.month(),
    currMoment.date(),
    0,
    date.getTimezoneOffset() * -1,
    0
  );

  return {
    day: currMoment.date(),
    month: currMoment.month() + 1,
    year: currMoment.year(),
    timestamp: roundedDate.getTime(),
    date: roundedDate,
  };
};

// TODO: nail down the type cause for now it doesnt understand that the array return can container any kind of type
/**
 * @template T
 * @param {<C>(() => T extends C)[] | Promise<T>[]} actions
 * @returns {T extends Promise ? Promise<{ data: T | T[] | null, error: any | null }> : { data: T | T[] | null, error: any | null }}
 */
export const tryCatch = (...actions) => {
  if (actions[0] instanceof Promise) return promiseWrapper(...actions);
  else {
    let data = [];
    try {
      actions.forEach((action) => {
        try {
          data.push(action());
        } catch (err) {
          throw err;
        }
      });

      return { data: actions.length === 1 ? data[0] : data, error: null };
    } catch (err) {
      return { data: null, error: err };
    }
  }
};

/**
 * @template T
 * @param {Promise<T>[]} promises
 * @returns {Promise<{ data: T | T[] | null, error: any | null }>}
 */
export const promiseWrapper = (...promises) => {
  return Promise.all(...promises)
    .then((data) => ({
      data: promises.length === 1 ? data[0] : data,
      error: null,
    }))
    .catch((error) => ({ data: null, error }));
};


export const removeEmpty = (obj, uniqStringForDebugPurposes, _parentObject, _pathInParentObject = []) => {

  _.keys(obj).forEach((key) => {

    if (obj[key] && typeof obj[key] === "object") {
      return removeEmpty(obj[key], uniqStringForDebugPurposes, _parentObject ? _parentObject : obj, [..._pathInParentObject, key]);
    }
    else if (obj[key] === undefined) {
      delete obj[key];
    }
    else if (obj[key] && typeof obj[key] === "string" && moment(obj[key], moment.ISO_8601, true).isValid()) {

      // this function used to validate and convert ISO_8601 Dates to timestamp probably because Realm holds Dates that way.
      // we moved it because it is irrelevant to removeEmpty and since moment update it also changed none-Date values.
      // if you arrived here because log in sentry, please make sure that the place where its called is 
      // handling Dates with the function convertDateToTS.
      // also check if there was data that was uploaded in that format and needed to be converted to timestamps

      platformActions.sentry.notify('RemoveEmpty got a ISO date value, make it be handled before remove empty ', {
        parentObject: _parentObject,
        pathInParentObject: _pathInParentObject,
        currObject: obj,
        currKey: key,
        currValue: obj[key],
        uniqStringForDebugPurposes
      });
    }
  }
  );

  return obj;
};

export const convertDateToTS = val => moment(val, moment.ISO_8601, true).isValid()
  ? new Date(val).getTime()
  : val;