import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: "Checklists",
    id: 'checklists.tabs.title'
  },
  checklistsStatus: {
    defaultMessage: "Checklists Status",
    id: 'checklists.checklistsStatus'
  },
  loading: {
    defaultMessage: 'Loading Checklists',
    id: 'checklists.tabs.loading'
  },
  extraInfo: {
    defaultMessage: 'Edit additional description',
    id: 'checklists.menu.editExtraInfo'
  },
  duplicateChecklist: {
    defaultMessage: 'Duplicate checklist',
    id: 'checklists.menu.duplicateChecklist'
  },
  removeDuplicatedChecklist: {
    defaultMessage: 'Remove duplicated checklist',
    id: 'checklists.menu.removeChecklistDuplication'
  },
  exportReport: {
    defaultMessage: 'Export checklist report',
    id: 'checklists.menu.exportReport'
  },
  signChecklist: {
    defaultMessage: 'Sign Checklist',
    id: 'checklists.menu.signChecklist'
  },
  signChecklistRecordTitle: {
    defaultMessage: '\"{checklistTitle}\" was signed by the tenants',
    id: 'checklists.menu.signChecklistRecordTitle'
  },
  routineCheck: {
    defaultMessage: "Routine Check {date}",
    id: 'checklists.routineCheck.title'
  },
  subtitles: {
    unit: {
      defaultMessage: "Unit checklists",
      id: 'checklists.subtitles.units'
    },
    floor: {
      defaultMessage: "Floor checklists",
      id: 'checklists.subtitles.floors'
    },
    building: {
      defaultMessage: "Building checklists",
      id: 'checklists.subtitles.building'
    },
    general: {
      defaultMessage: "General checklists",
      id: 'checklists.subtitles.general'
    },
  },
  empty: {
    unit: {
      defaultMessage: "There are no checklists for this unit yet",
      id: 'checklists.empty.unit'
    },
    floor: {
      defaultMessage: "There are no checklists for this floor yet",
      id: 'checklists.empty.floors'
    },
    building: {
      defaultMessage: "There are no checklists for the building itself yet",
      id: 'checklists.empty.building'
    }
  },
  issueAlert: {
    title: {
      defaultMessage: "Create Task",
      id: 'checklists.issueAlert.title'
    },
    content: {
      defaultMessage: "Would you like to create a new task to follow up on this item?",
      id: 'checklists.issueAlert.content'
    }
  },
  employeesHealthyPostTemplate: {
    default: {
      defaultMessage: 'Daily fever check was found to be abnormal.\nEmployee Details: {fullName}',
      id: 'checklists.issueTemplate.employeesHealthyPostTemplate.default'
    },
    id: {
      defaultMessage: ', Id: {id} {nationality}',
      id: 'checklists.issueTemplate.employeesHealthyPostTemplate.id'
    },
    sub: {
      defaultMessage: '\nSubcontractor {sub}',
      id: 'checklists.issueTemplate.employeesHealthyPostTemplate.sub'
    },
    fever: {
      defaultMessage: '\nHeat measured: ',
      id: 'checklists.issueTemplate.employeesHealthyPostTemplate.fever'
    },
  },
  issueTemplate: {
    defaultMessage: 'Discrepancy found in \"{ChecklistName} / {description}\".',
    id: 'checklists.issueTemplate.description'
  },
  issueTemplateNoChecklistName: {
    defaultMessage: 'Discrepancy found in "{description}".',
    id: 'checklists.issueTemplateNoChecklistName.description'
  },
  checklistPostTemplate: {
    defaultMessage: 'The following checklist item \"{ChecklistName} / {ChecklistItem}\" was attached with a record.',
    id: 'checklists.checklistPostTemplate.description'
  },
  checklistPostTemplate100: {
    defaultMessage: 'The following checklist item \"{ChecklistName} / {ChecklistItem}\" was verified and attached with a record.',
    id: 'checklists.checklistPostTemplate.description100'
  },
  checklistPostTemplateNoChecklistName: {
    defaultMessage: 'The following checklist item \"{ChecklistItem}\" was attached with a record.',
    id: 'checklists.checklistPostTemplate.descriptionNoChecklistName'
  },
  checklistPostTemplate100NoChecklistName: {
    defaultMessage: 'The following checklist item \"{ChecklistItem}\" was verified and attached with a record.',
    id: 'checklists.checklistPostTemplate.description100NoChecklistName'
  },
  checklistCard: {
    connectedTask: {
      defaultMessage: "Related tasks",
      id: 'checklists.checklistCard.connectedTask'
    },
    records: {
      defaultMessage: "Records details",
      id: 'checklists.checklistCard.records'
    },
    info: {
      defaultMessage: "Info",
      id: 'checklists.checklistCard.info'
    }
  },
  duplicate: {
    title: {
      defaultMessage: 'Duplicate \"{description}\"',
      id: 'checklists.duplicate.title'
    },
    extraInfo: {
      defaultMessage: 'Add additional description',
      id: 'checklists.duplicate.extraInfo'
    },
    extraInfoPlaceholder: {
      defaultMessage: 'Additional description',
      id: 'checklists.duplicate.extraInfoPlaceholder'
    },
    button: {
      defaultMessage: 'Duplicate',
      id: 'checklists.duplicate.button'
    },
    confirmTitle: {
      defaultMessage: 'Are you sure you want to duplicate checklist \"{description}\"  ?',
      id: 'checklists.duplicate.confirmTitle'
    },
    confirmMessage: {
      defaultMessage: 'Additional description: \"{extraInfo}\"',
      id: 'checklists.duplicate.confirmMessage'
    }
  },
  removeDuplication: {
    confirmTitle: {
      defaultMessage: 'Are you sure you want to delete \"{title}\"?',
      id: 'checklists.removeDuplication.confirmTitle'
    },
    confirmMessage: {
      defaultMessage: 'Additional description: \"{extraInfo}\"',
      id: 'checklists.removeDuplication.confirmMessage'
    },
    alertTitle: {
      defaultMessage: 'Cannot remove duplication',
      id: 'checklists.removeDuplication.alertTitle'
    },
    notLastDuplicationMessage: {
      defaultMessage: 'Only the last duplication of a checklist can be removed',
      id: 'checklists.removeDuplication.notLastDuplicationMessage'
    },
    notEmptyMessage: {
      defaultMessage: 'Only empty duplication can be removed',
      id: 'checklists.removeDuplication.notEmptyMessage'
    }
  },
  editExtraInfo: {
    extraInfo: {
      defaultMessage: 'Edit Additional description',
      id: 'checklists.edit.extraInfo'
    },
    button: {
      defaultMessage: 'Edit',
      id: 'checklists.edit.button'
    },
    confirmTitle: {
      defaultMessage: 'Are you sure you want to edit checklist \"{description}\" additional description to \"{extraInfo}\"?',
      id: 'checklists.edit.confirmTitle'
    }
  },
  distributionList: {
    title: {
      defaultMessage: 'Send report via email',
      id: 'checklists.distributionList.title'
    },
    listHeader: {
      defaultMessage: 'Distribution list:',
      id: 'checklists.distributionList.listHeader'
    },
    addNewMailTitle: {
      defaultMessage: 'Add new email',
      id: 'checklists.distributionList.addNewMailTitle'
    },
    editMailTitle: {
      defaultMessage: 'edit email',
      id: 'checklists.distributionList.editMailTitle'
    },
    mailInputPlaceholder: {
      defaultMessage: 'Enter emaill address here',
      id: 'checklists.distributionList.mailInputPlaceholder'
    },
    successMessage: {
      defaultMessage: 'Your request is accepted. An email will be sent shortly',
      id: 'checklists.distributionList.successMessage'
    },
    emptyList: {
      defaultMessage: 'The list have no emails yet. Add email to the list by clicking on "Add new email" button',
      id: 'checklists.distributionList.emptyList'
    }
  }
});
