import React from "react";

// Components
import GridItem from "../../components/Grid/GridItem.jsx";
import Text from "../../components/CementoComponents/Text";
import Collapse from "@kunukn/react-collapse";
import EditStrip from "../../components/CementoComponents/EditStrip";
import Dragger from "../../components/CementoComponents/Dragger";

// Icons
import foldArrow from "../../assets/img/icons/foldArrow.png";

// Others
import theme from "../../assets/css/theme";
import "../../assets/css/collapse.css";
import { injectIntl } from "react-intl";
import NoItemsFound from "./NoItemsFound.js";
import { compose, hoistStatics } from "recompose";
import { connect } from "react-redux";
import _ from "lodash";

const UNMOUNT_DEALY = 300;
const defaultProps = {
  keyId: null,
  title: "",
  extraTitle: null,
  minMode: false,
  isEditMode: false,
  isSelected: false,
  isFocused: false,
  leftSideComp: null,
  rightSideComp: null,
  rightSideOpenComp: null,
  editIcons: null,
  mainContainerStyle: {},
  fullWidth: false,
  onClick: () => {},
  closeOnSecondClick: false,
  isCollapsible: true,
  noHeader: false,
};

/**
 * @typedef InnerCollapsibleProps
 * @property {string} [keyId]
 * @property {string | { id: string, defaultMessage: string }} [title]
 * @property {string} [extraTitle]
 * @property {boolean} [minMode]
 * @property {boolean} [isEditMode]
 * @property {boolean} [isSelected]
 * @property {boolean} [isFocused]
 * @property {React.ReactNode} [leftSideComp]
 * @property {React.ReactNode} [rightSideComp]
 * @property {React.ReactNode} [rightSideOpenComp]
 * @property {React.ReactNode} [footerComponent]
 * @property {import('./EditStrip').Icon[]} [editIcons]
 * @property {React.CSSProperties} [mainContainerStyle]
 * @property {React.CSSProperties} [footerContainerStyle]
 * @property {boolean} [fullWidth]
 * @property {() => void} [onClick]
 * @property {boolean} [closeOnSecondClick]
 * @property {boolean} [isCollapsible]
 * @property {boolean} [noHeader]
 * @property {boolean} [noOpenMargin]
 * @property {boolean} [alwaysShowEditStrip]
 */

/**
 * @extends React.Component<InnerCollapsibleProps>
 */
class InnerCollapsible extends React.Component {
  constructor(props) {
    super(props);
    this.collapseTransition = this.collapseTransition.bind(this);
    this.setOpen = this.setOpen.bind(this);
    this.setHoverOn = this.setHoverOn.bind(this);
    this.setHoverOff = this.setHoverOff.bind(this);
    this.setComponentData = this.setComponentData.bind(this);

    this.state = {
      collapseClassName: "collapse-css-transition",
      open: !props.isCollapsible,
      renderInnerCells: !props.isCollapsible,
      deg: 0,
      hover: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.setComponentData({}, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  setComponentData(props, nextProps) {
    if (!_.isNil(nextProps.open) && this.state.open !== nextProps.open) {
      const collapseClassName = nextProps.open ? 'collapse-css-transition-visible' : 'collapse-css-transition';
      this.setState({
        open: nextProps.open,
        renderInnerCells: nextProps.open,
        collapseClassName: "collapse-css-transition",
      });
    }
  }

  setHoverOn() {
    if (!this.state.hover) this.setState({ hover: true });
  }

  setHoverOff() {
    if (this.state.hover) this.setState({ hover: false });
  }

  setOpen(e) {
    const { open } = this.state;
    const { onClick, closeOnSecondClick, isSelected, isCollapsible } =
      this.props;
    e.stopPropagation();

    if (e.target.id === "dragger") return;

    if (onClick) onClick();

    if (!isCollapsible || (closeOnSecondClick && open && !isSelected)) return;

    this.setState({
      open: !open,
      renderInnerCells: true,
      collapseClassName: "collapse-css-transition",
    });
  }

  collapseTransition({ isMoving, hasReversed }) {
    if (!isMoving && !hasReversed && this.state.open) {
      this.setState({ collapseClassName: "collapse-css-transition-visible", renderInnerCells: true });
    } else if (!this.state.open) {
      setTimeout(async () => {
        this.setState({ renderInnerCells: false })
      }, UNMOUNT_DEALY);  }
    }

  render() {
    const {
      keyId,
      rtl,
      intl,
      children,
      minMode,
      isEditMode,
      isSelected,
      isFocused,
      title,
      extraTitle,
      leftSideComp,
      rightSideComp,
      rightSideOpenComp,
      editIcons,
      mainContainerStyle = {},
      fullWidth = false,
      footerComponent,
    } = this.props;
    const {
      isCollapsible,
      footerContainerStyle,
      noHeader,
      noOpenMargin,
      alwaysShowEditStrip,
      onClick,
    } = this.props;
    const { open, hover, collapseClassName, renderInnerCells } = this.state;

    const isOpen = !isCollapsible || (isCollapsible && open);

    const {
      mainContainer,
      selected,
      titleFocus,
      titleMainContainer,
      titleContainer,
      titleLeftIconStyle,
      arrow,
    } = styles;
    const isCollapseContainer =
      isCollapsible ||
      ((!isCollapsible || noHeader) && this.props.open === false);
    const hoverOrOpen = open || hover;
    const collapseContent = (
      <>
        <div style={styles.childrenContainer}>
          {Boolean(children && children.length !== 0) ? (
            children
          ) : (
            <NoItemsFound
              onClick={() => onClick && onClick()}
              compStyles={{ height: theme.headerHeight }}
            />
          )}
        </div>
        {Boolean(footerComponent) && (
          <div
            style={{
              minHeight: titleMainContainer.height,
              borderTop:
                "1px solid " +
                (isFocused
                  ? theme.innerCollapsibleBrandPrimaryBorders
                  : theme.headerInfoColor),
              display: "flex",
              padding: titleMainContainer.padding,
              alignItems: "center",
              ...footerContainerStyle,
            }}
          >
            {footerComponent}
          </div>
        )}
      </>
    );

    return (
      <GridItem
        xs={12}
        key={keyId}
        onMouseEnter={this.setHoverOn}
        onMouseLeave={this.setHoverOff}
        style={{
          ...mainContainer,
          ...mainContainerStyle,
          boxShadow:
            hoverOrOpen && isFocused ? theme.webShadow.boxShadow : "none",
          ...(isOpen && !noOpenMargin ? { marginBottom: theme.margin } : {}),
          ...(isSelected
            ? { backgroundColor: theme.backgroundColorSelected }
            : { backgroundColor: theme.backgroundColorBright }),
          ...(isSelected || isFocused || hover
            ? {
                border:
                  "1px solid " + theme.innerCollapsibleBrandPrimaryBorders,
              }
            : {}),
        }}
      >
        {Boolean(!noHeader) && (
          <div
            style={{
              ...titleMainContainer,
              ...(isOpen
                ? isFocused
                  ? titleFocus
                  : { borderBottom: theme.borderLineHeaderInfo }
                : {}),
            }}
            onClick={this.setOpen}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                textOverflow: "ellipsis",
                height: "inherits",
                alignItems: "center",
              }}
            >
              {Boolean(!fullWidth) && (
                <div
                  style={{
                    ...titleLeftIconStyle,
                    [rtl ? "marginLeft" : "marginRight"]: theme.margin - 5,
                  }}
                >
                  {Boolean(isEditMode && hover) ? (
                    <Dragger
                      style={{ maxHeight: "20px", maxWidth: "20px" }}
                      dragId={"dragger"}
                    />
                  ) : (
                    Boolean(leftSideComp) && leftSideComp
                  )}
                </div>
              )}
              <div
                style={{
                  ...titleContainer,
                  ...(isSelected || (hover && !isFocused) ? selected : {}),
                  ...(!minMode ? { fontSize: theme.fontSizeH6Plus2 } : {}),
                }}
              >
                {" "}
                {/* TODO: apply small font only when in sidebar */}
                {Boolean(extraTitle) && (
                  <div
                    style={{
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      fontWeight: theme.strongBold,
                      [rtl ? "marginLeft" : "marginRight"]: 3,
                    }}
                  >
                    {typeof extraTitle === "object"
                      ? intl.formatMessage(extraTitle)
                      : extraTitle + " - "}
                  </div>
                )}
                <Text
                  style={{
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    maxWidth: minMode ? (isOpen ? 160 : 200) : null,
                  }}
                  title={minMode ? title : null}
                >
                  {title}
                </Text>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: isOpen ? 0 : "100%",
                maxWidth: 400,
                height: "inherits",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                {Boolean(isEditMode && (alwaysShowEditStrip || hover))
                  ? Boolean(editIcons) && <EditStrip icons={editIcons} />
                  : Boolean(isOpen)
                  ? Boolean(rightSideOpenComp) && rightSideOpenComp
                  : Boolean(rightSideComp) && rightSideComp}
              </div>
              {Boolean(isCollapsible) && (
                <img
                  style={{
                    ...(isOpen ? {} : { transform: "rotate(" + 180 + "deg)" }),
                    ...arrow,
                  }}
                  src={foldArrow}
                />
              )}
            </div>
          </div>
        )}

        {Boolean(isCollapseContainer) ? (
          <Collapse
            className={collapseClassName}
            isOpen={isOpen}
            onChange={this.collapseTransition}
          >
            {Boolean(renderInnerCells) && collapseContent}
          </Collapse>
        ) : (
          Boolean(renderInnerCells) && collapseContent
        )}
      </GridItem>
    );
  }
}

const styles = {
  childrenContainer: {
    backgroundColor: theme.backgroundColorBright,
    borderRadius: theme.borderRadius,
  },
  mainContainer: {
    marginBottom: theme.margin - 5,
    borderWidth: "1px",
    borderStyle: "solid",
    border: theme.borderLineNeutralLight + "30",
    borderRadius: theme.borderRadius,
  },
  selected: {
    color: theme.brandPrimary,
  },
  titleFocus: {
    borderBottom: "1px solid " + theme.innerCollapsibleBrandPrimaryBorders,
  },
  titleMainContainer: {
    overflow: "hidden",
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
    flex: 1,
    padding: `${theme.verticalMargin}px ${theme.paddingSize}px`,
    minHeight: 50,
    cursor: "pointer",
  },
  titleContainer: {
    display: "flex",
    fontWeight: theme.strongBold,
    // color: 'rgba(0, 0, 0, 0.55)',
    margin: "0 " + theme.verticalMargin + "px",
  },
  titleLeftIconStyle: {
    height: 23,
    width: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  arrow: {
    height: "min-content",
    alignSelf: "center",
    margin: "0 5px",
    transitionDuration: "0.2s",
    transitionDelay: "0.3s",
  },
};

const enhance = compose(
  injectIntl,
  connect(
    (state) => ({
      rtl: state.app.rtl,
    }),
    {}
  )
);

InnerCollapsible = enhance(InnerCollapsible);
InnerCollapsible.defaultProps = defaultProps;
export default InnerCollapsible;
