import React from "react";
import { connect } from "react-redux";
import { compose, hoistStatics } from "recompose";
import { connectContext } from "react-connect-context";
import { ProjectContext } from "../../../common/projects/contexts";
import { companiesGroupsOptions } from "../Projects/ProjectManager";
import withStyles from "@material-ui/core/styles/withStyles";
import MembersManager from "./MembersManager";
import buttonStyle from "../../assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";
import SplitViewPage from "../../layouts/SplitViewPage";

class MembersManagerPage extends React.Component {
  constructor(props) {
    super(props);
    this.setComponentData = this.setComponentData.bind(this);
    this.state = {};
  }

  UNSAFE_componentWillMount() {
    this.setComponentData({}, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  setComponentData(props, nextProps) {
    let newStateChanges = {};
    if (Object.keys(newStateChanges).length > 0) this.setState(newStateChanges);
  }

  render() {
    return <SplitViewPage Main={<MembersManager projectScope={false} usersGroupsOptions={companiesGroupsOptions}/>} />;
  }
}

MembersManagerPage = withStyles(buttonStyle)(MembersManagerPage);
const enhance = compose(
  connectContext(ProjectContext.Consumer),
  connect(null, {})
);
export default enhance(MembersManagerPage);
