import { getSnapshotData } from '../lib/utils/utils';

export const LOAD_PROJECT_QUASISTATICS = 'LOAD_PROJECT_QUASISTATICS';
export const CLEAN_CACHED_QUASISTATICS = 'CLEAN_CACHED_QUASISTATICS';
export const GET_QUASISTATICS = 'GET_QUASISTATICS';

export function getQuasiStatics(inLastUpdateTS) {
  const getPromise = async () => {
    const snapShot = await getSnapshotData({ api: 'quasiStatics' }, 'global');
    return { ...(snapShot || {}), lastUpdateTS: inLastUpdateTS };
  };

  return {
    type: GET_QUASISTATICS,
    payload: getPromise()
  };
}

