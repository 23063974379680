import ExtraError from '../lib/errors/extraError'
import systemMessages from '../app/systemMessages';
import { startLoading } from '../app/actions';
import {getSnapshotData} from '../lib/utils/utils';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { getProjectStatePathes } from '../configureStorage/statePathes';


export const GET_PROJECTS_DETAILS_BY_COMPANY = 'GET_PROJECTS_DETAILS_BY_COMPANY';
export const SET_TASK_DETAILS = 'SET_TASK_DETAILS';
export const UPDATE_PROJECT_FIELDS = 'UPDATE_PROJECT_FIELDS';
export const UPDATE_LOCAL_PROJECT = 'UPDATE_LOCAL_PROJECT';
export const UPDATE_EXTERNAL_SETTINGS_FIELDS = 'UPDATE_EXTERNAL_SETTINGS_FIELDS';
export const SET_NEW_PROJECT = 'SET_NEW_PROJECT';
export const GET_PROJECTS = 'GET_PROJECTS';
export const GET_PROJECTS_STARTED = 'GET_PROJECTS_STARTED';
export const SET_PROJECT_ADDRESS = 'SET_PROJECT_ADDRESS';
export const SET_PROJECT_CLIENTS = 'SET_PROJECT_CLIENTS';
export const ADD_PROJECT_VIDEO = 'ADD_PROJECT_VIDEO'; 
export const ADD_PROJECT_IMAGE = 'ADD_PROJECT_IMAGE';
export const ADD_TASK_VIDEO = 'ADD_TASK_VIDEO';
export const ADD_TASK_IMAGE = 'ADD_TASK_IMAGE';
export const SET_TASK_CATEGORY = 'SET_TASK_CATEGORY';
export const GET_PROJECT = 'GET_PROJECT';
export const GET_EXTERNAL_SETTINGS = 'GET_EXTERNAL_SETTINGS';
export const END_PROJECTS_LISTENER = 'END_PROJECTS_LISTENER';
export const GET_PROJECT_STARTED = GET_PROJECT + '_START';
export const GET_PROJECT_SUCCESS = GET_PROJECT + '_SUCCESS';
export const GET_PROJECT_ERROR = GET_PROJECT + '_ERROR';
export const GET_PROJECT_DETAILS = 'GET_PROJECT_DETAILS';
export const GET_LOCAL_PROJECT_DETAILS = 'GET_LOCAL_PROJECT_DETAILS';
export const END_PROJECT_DETAILS_LISTENER = 'END_PROJECT_DETAILS_LISTENER';
export const GET_PROJECT_DETAILS_STARTED = GET_PROJECT_DETAILS + '_START';
export const GET_PROJECT_DETAILS_SUCCESS = GET_PROJECT_DETAILS + '_SUCCESS';
export const GET_PROJECT_DETAILS_ERROR   = GET_PROJECT_DETAILS + '_ERROR';
export const SET_PROJECT_IMAGE = 'SET_PROJECT_IMAGE';
export const SET_PROJECT_IMAGE_SUCCESS = 'SET_PROJECT_IMAGE_SUCCESS';
export const SET_PROJECT_IMAGE_ERROR = 'SET_PROJECT_IMAGE_ERROR';
export const SET_PROJECT_IMAGE_START = 'SET_PROJECT_IMAGE_START';
export const GET_NEW_PROJECT_ID = 'GET_NEW_PROJECT_ID';
export const CREATE_NEW_PROJECT = 'CREATE_NEW_PROJECT';
export const CLEAN_NEW_PROJECT = 'CLEAN_NEW_PROJECT';
export const UPDATE_PROJECT_MEMBERS = 'UPDATE_PROJECT_MEMBERS';
export const UPDATE_PROJECT_IMAGE = 'UPDATE_PROJECT_IMAGE';
export const SET_CURRENT_PROJECT = 'SET_CURRENT_PROJECT';
export const SET_PROJECT_PERMISSIONS = 'SET_PROJECT_PERMISSIONS';
export const GET_FULL_PROJECT_REPORT = 'GET_FULL_PROJECT_REPORT';

// TODO: If really only for local - change the action names
export const UPDATE_PROJECT_ADDRESS = 'UPDATE_PROJECT_TYPE';
export const UPDATE_PROJECT_TYPE 		= 'UPDATE_PROJECT_TYPE';
export const UPDATE_PROJECT_FLOORS  = 'UPDATE_PROJECT_FLOORS';

export const LEAVE_PROJECT  = 'LEAVE_PROJECT';
export const ENTER_PROJECT  = 'ENTER_PROJECT';
export const SAVE_PROJECT_STORAGE  = 'SAVE_PROJECT_STORAGE';
export const LOAD_PROJECT_STORAGE  = 'LOAD_PROJECT_STORAGE';
export const REMOVE_PROJECTS_STORAGE  = 'REMOVE_PROJECTS_STORAGE';
export const PROJECT_STORAGE_NOT_LOAD = 'PROJECT_STORAGE_NOT_LOAD';
export const LOKI_DID_LOAD = 'LOKI_DID_LOAD';
export const SET_PROJECT_INTL = 'SET_PROJECT_INTL';

export const PROJECT_TYPE_HOUSE 	 					= 1;
export const PROJECT_TYPE_COMPLEX  					= 2;
export const PROJECT_TYPE_BUILDING 					= 3;
export const PROJECT_TYPE_COMPLEX_BUILDINGS = 4;

var IS_ALREADY_RUNNING_A_SAVE = false;
export function saveProjectStorage(projectId) {
  return ({ lokiInstance, platformActions }) => {
		const getPromise = async () => {
			if (!IS_ALREADY_RUNNING_A_SAVE) {
				IS_ALREADY_RUNNING_A_SAVE = true;
	
				try {
					await lokiInstance.saveProjectDataToStorage(projectId);
					await lokiInstance.saveProjectDataToStorage('global');
				} catch (error) {
					platformActions.sentry.notify(error, {projectId});
					console.log('saveProjectStorage error:', error);
				}
				finally {
					IS_ALREADY_RUNNING_A_SAVE = false;
				}
			}
			
			return { projectId };
		}

		return {
			type: SAVE_PROJECT_STORAGE,
			payload: getPromise()
		};
	};
}

export function leaveProject(projectId) {
	return {
		type: LEAVE_PROJECT,
		payload: { projectId }
	};
}

export function loadProjectStorage(projectId, forceLoad, prevProjectId) {
  return ({ getState, platformActions, lokiInstance, dispatch }) => {
    const getPromise = async () => {
      try {
        var projectSavedJson = [];
        if (forceLoad || !(getState().projects.projectReducersLoaded && getState().projects.projectReducersLoaded.get(projectId))) {
					const platform = platformActions.app.getPlatform();
					let projectPathesToLoad = getProjectStatePathes();
					if (platform == "web") 
						projectPathesToLoad.unshift(['loki']);

					await Promise.all(projectPathesToLoad.map(async ([feature, ...featurePath]) => {
				    try {
							if (feature == 'loki')  {
								if (prevProjectId)
									await lokiInstance.unloadProjectDataFromStorage(prevProjectId, (loadedCollections) => dispatch({ type: LOKI_DID_LOAD, payload: { loadedCollections, projectId: prevProjectId, status: false } }));
								await lokiInstance.loadProjectDataFromStorage(projectId, (loadedCollections) => dispatch({ type: LOKI_DID_LOAD, payload: { loadedCollections, projectId, status: true } }));
							} else {
								let configKey = '@' + feature + '_' + featurePath + ':' + projectId;
								let value = null;
								if (platform == "ios" || platform == "web")
									value = await platformActions.storage.getItem(configKey);
								else {
									let configName = 'cemento_' + configKey + '.cfg';
									let fileLocation = platformActions.fs.getDocumentDirectoryPath() + '/cemento/' + configName;
									let fileExist = await platformActions.fs.exists(fileLocation);
									if (fileExist)
										value  = await platformActions.fs.readFile(fileLocation, 'utf8');
								}
		
								if (value)
									projectSavedJson.push({feature, featurePath, value});
							}	
				    }
				    catch (err) {
				      console.warn('PROJECT_STORAGE_LOAD error:' + projectId);
				      console.warn(err);
				    }
					}));
				}

				return { projectSavedJson, projectId, didFind: projectSavedJson.length > 0 }
      } catch (error) {
        throw error;
      }
    }

    return {
      type: LOAD_PROJECT_STORAGE,
      payload: getPromise()
    };
  };
}


export function removeProjectsStorage(projectIdsArray, projectStateToRemove) {
  return ({ getState, platformActions }) => {
    const getPromise = async () => {
      try {
        
				await Promise.all(projectIdsArray.map(async (projectId) => {
					await Promise.all(projectStateToRemove.map(async ([feature, ...featurePath]) => {
						try {
							var configKey = '@' + feature + '_' + featurePath + ':' + projectId;
							if (platformActions.app.getPlatform() == "ios" || platformActions.app.getPlatform() == "web")
								await platformActions.storage.removeItem(configKey);
							else {
								var configName = 'cemento_' + configKey +  '.cfg';
								var fileLocation = platformActions.fs.getDocumentDirectoryPath() + '/cemento/' + configName;
								var fileExist = await platformActions.fs.exists(fileLocation);
								if (fileExist)
									try {
										await platformActions.fs.deleteFile(fileLocation, 'utf8');
									} catch {
										console.log('Error deleting file:', fileLocation);
									}
							}
						}
						catch (err) {
							console.warn('REMOVE_PROJECTS_STORAGE error:' + projectId);
							console.warn(err);
						}
					}));
				}));
					
        //return { }
      } catch (error) {
        console.log("REMOVE_PROJECTS_STORAGE error: " + error)
        throw error;
      }
    }

    //console.log('REMOVE_PROJECTS_STORAGE - AFTER', projectIdsArray, projectStateToRemove);

    return {
      type: REMOVE_PROJECTS_STORAGE,
      payload: getPromise()
    };
  };
}


const debouncedGetUserProjectsListener = AwesomeDebouncePromise(funcToRun => funcToRun(), 5000);
export function getUserProjects(user, adminMode) {
  return ({ dispatch, firebaseDatabase }) => {
  	const getPromise = async () => {
			try {
				var ref = null;
				const listenToAllProjects = user.adminMode || adminMode;
				if (listenToAllProjects)
					ref = firebaseDatabase().ref('projects');
				else
					ref = firebaseDatabase().ref('users-projects/' + user.id);
				
			  ref.on('value', function(snapshot) {
			  	var projects = snapshot.val();
					const runDispatch = () =>
						dispatch({
							type: GET_PROJECTS,
							payload: {
								adminMode: user.adminMode,
								projects
							}
						});
					
					if (listenToAllProjects)
						debouncedGetUserProjectsListener(runDispatch);
					else
						runDispatch();
				});
			} catch (error) {
				throw error;
			}
		}

    return {
      type: GET_PROJECTS_STARTED,
      payload: getPromise()
    };
  };
}

export function endUserProjectsListener(user) {
  return ({ firebaseDatabase }) => {
		firebaseDatabase().ref('users-projects/' + user.id).off('value');
		firebaseDatabase().ref('projects').off('value');
    return {
      type: END_PROJECTS_LISTENER,
      payload: {}
    };
  };
}

export function getProjectsByCompany(companyId) {
	return ({ dispatch, firebaseDatabase, getState }) => {
		  const getPromise = async () => {
				var snapshot = await firebaseDatabase().ref('projects').orderByChild('companyId').equalTo(companyId).once('value');

				return snapshot.val();
		  }
	  return {
		type: GET_PROJECTS_DETAILS_BY_COMPANY,
		payload: getPromise()
	  };
	};
  }
  

export function getProjectDetails(projectId, forceUpdate) {
    return {
      type: GET_PROJECT_DETAILS,
      payload: getSnapshotData({api:'projects'},projectId)
  };
}

export function getLocalProjectDetails(project) {
  return {
    type: GET_LOCAL_PROJECT_DETAILS + "_SUCCESS",
    payload: project
  }
}

export function endProjectsDetailsListener(projectId) {
  return ({ firebaseDatabase }) => {
  	const getPromise = async () => {
		  firebaseDatabase().ref('projects/' + projectId).off('value');
		}

    return {
      type: END_PROJECT_DETAILS_LISTENER,
      payload: getPromise()
    };
  };
}



export function updateExternalSettings(projectFields, projectId, viewer) {
	return ({ firebase, removeEmpty }) => {
		const getPromise = async () => {
				let projectFieldsCopy = projectFields.toJS ? projectFields.toJS() : Object.assign({}, projectFields)
				projectFieldsCopy = removeEmpty(projectFieldsCopy, 'updateExternalSettings');
		  	var updates = {};

				// The source of the project info is on the project page - if we update the data - all data under it will dissapir!
				projectFieldsCopy.loopEach((key, value) => updates['settings/' + projectId + '/' + key] = value);
				await firebase.update(updates)

				return { project: projectFieldsCopy };
		};

		return {
			type: UPDATE_EXTERNAL_SETTINGS_FIELDS,
			payload: getPromise()
		};
	};
}

//TODO: IGNORE AND CLEAN AFTER 31.12.2020
export function getProjectExternalSettings(projectId) {
  return ({ firebaseDatabase }) => {
    const getPromise = async () => {
      var externalSettings = (await firebaseDatabase().ref('settings/' + projectId).once('value')).val();

      return { externalSettings };
    };

    return {
      type: GET_EXTERNAL_SETTINGS,
      payload: getPromise()
    };
  };
}

export function updateProjectFields(projectFields, projectId, viewer) {
	return ({ firebase, removeEmpty }) => {
		const getPromise = async () => {
				let projectFieldsCopy = projectFields.toJS ? projectFields.toJS() : Object.assign({}, projectFields)
				// if (!projectFieldsCopy.createdAt) {
        //   projectFieldsCopy.createdAt = new Date().getTime();
				// 	if (viewer)
        //   projectFieldsCopy.owner = {id: viewer.id, avatar: viewer.avatar, displayName: viewer.displayName};
				// }

        projectFieldsCopy = removeEmpty(projectFieldsCopy, 'updateProjectFields');

		  	let updates = {};

				// The source of the project info is on the project page - if we update the data - all data under it will dissapir!
        Object.entries(projectFieldsCopy).forEach(([key, value]) => updates['projects/' + projectId + '/' + key] = value);

        await firebase.update(updates)
				return { project: projectFieldsCopy };
		};

		return {
			type: UPDATE_PROJECT_FIELDS,
			payload: getPromise()
		};
	};
}

export function updateLocalProject(projectId, project) {
  return {
    type: UPDATE_LOCAL_PROJECT,
    payload: { projectId, project }
  };
}


export function setProjectPermissions(project, confirmArray, confirm2Array, irrelevantArray, partialArray) {
	return ({ firebase }) => {
		const getPromise = async () => {
	  	var updates = {};
			
			if (!project || !project.id || (!confirmArray && !confirm2Array))
				return {};

			let permissions = Object.assign({}, project.permissions)
			let permChecklistItems = permissions.checklistItems || {};
			permissions.checklistItems = permChecklistItems;

			if (confirmArray && confirmArray.length) {
				permChecklistItems.confirm = { companies: {} };
				confirmArray.loopEach((index, value) => permChecklistItems.confirm.companies[value.id] = value.checked ? { id: value.id } : null);
			}
			if (confirm2Array && confirm2Array.length) {
				permChecklistItems.confirm2 = { companies: {} };
				confirm2Array.loopEach((index, value) => permChecklistItems.confirm2.companies[value.id] = value.checked ? { id: value.id } : null);
			}
			if (irrelevantArray && irrelevantArray.length) {
				permChecklistItems.irrelevant = { companies: {} };
				irrelevantArray.loopEach((index, value) => permChecklistItems.irrelevant.companies[value.id] = value.checked ? { id: value.id } : null);
			}
			if (partialArray && partialArray.length) {
				permChecklistItems.partial = { companies: {} };
				partialArray.loopEach((index, value) => permChecklistItems.partial.companies[value.id] = value.checked ? { id: value.id } : null);
			}
			
			updates['projects/' + project.id + '/permissions'] = permissions;
			await firebase.update(updates);
			return { projectId: project.id };
		};

		return {
			type: SET_PROJECT_PERMISSIONS,
			payload: getPromise()
		};
	};
}


export function updateProjectImage(project, projectImageUri, viewer) {
	return ({ firebase }) => {
		const getPromise = async () => {
			if (!projectImageUri)
				return;

	  	var updates = {};
			updates['projects/' + project.id + '/images/main'] = projectImageUri;
			await firebase.update(updates)
			return projectImageUri;
		};

		return {
			type: UPDATE_PROJECT_IMAGE,
			payload: getPromise()
		};
	};
}

export function setProjectMembers(inProject, members, memberStatus, viewer) {
  return ({ firebase, removeEmpty }) => {
    const getPromise = async () => {
	  	let updates = {};
      let project = Object.assign({}, inProject.toJS ? inProject.toJS() : inProject);
	  	if (memberStatus) {
	  		//firebaseDatabase().ref('projects/' + project.id + '/members/' + member.id).set(removeEmpty(member));
	  		project.members = Object.assign({}, project.members);
        
	  		for (let i=0; i < members.length; i++) {
	  			const member = members[i];
		  		const simpleMember = {displayName: member.displayName, id: member.id, addedByMember: { id: viewer.id }};
					updates['projects/' + project.id + '/members/' + member.id] = removeEmpty(simpleMember, 'setProjectMembers_addedByMember');
		  		project.members[member.id] = simpleMember;

					//var slimProject = Object.assign({}, project);
					//delete slimProject['members']; // I think i can remove the 'members' from here since it get filled from the origin project object
					//updates['users-projects/' + member.id + '/' + project.id] = removeEmpty(slimProject);
				}
	  	}
			else 
				for (let i=0; i < members.length; i++) {
					const member = members[i];
					updates['projects/' + project.id + '/members/' + member.id] = null;
          project.members = Object.assign({}, project.members)
          delete project.members[member.id];					
					//updates['users-projects/' + member.id + '/' + project.id] = null;
				}

			await firebase.update(updates)
			return { project, members, memberStatus };
		};

		return {
			type: UPDATE_PROJECT_MEMBERS,
			payload: getPromise()
		};
	};
}

export function setProjectIntl(intl) {
	return {
		type: SET_PROJECT_INTL,
		payload: { intl }
	};
}

export function enterProject(projectId, lastVisitedProjectId) {
	return {
		type: ENTER_PROJECT,
		payload: { projectId, lastVisitedProjectId}
	};
}


export function setProjectAddress(address, id) {
	return () => ({
		type: SET_PROJECT_ADDRESS,
		payload: {
			id: id,
			address: address,
		}
	});
}

export function setProjectClients(name, id) {
	return () => ({
		type: SET_PROJECT_CLIENTS,
		payload: {
			id: id,
			clients: {name: name},
		}
	});
}

export function setProjectImage(projectId, uri) {
	return {
		type: SET_PROJECT_IMAGE,
    payload: {
			projectId,
			uri
    }
	};
}

export function getNewProjectId() {
	return ({ firebaseDatabase }) => {
		const getPromise = async () => {
			var push = firebaseDatabase().ref('projects/').push();
			return { projectId: push.key };
		}
		return {
			type: GET_NEW_PROJECT_ID,
			payload: getPromise()
		};
	};
}

export function createLocalProject(viewer, project) {
	project.createdAt = new Date().getTime();
	project.owner = {id: viewer.id};

	if (viewer.avatar)
		project.owner.avatar = viewer.avatar;

	if (viewer.displayName)
		project.owner.displayName = viewer.displayName;

	project.updatedTS = new Date().getTime();

	return {
		type: CREATE_NEW_PROJECT,
		payload: { project }
	};
}

export function cleanLocalProject(viewer, project) {
	return {
		type: CLEAN_NEW_PROJECT
	};
}

export function updateLocalProjectAddress(address, city, title) {
	return {
		type: UPDATE_PROJECT_ADDRESS,
		payload: { address, city, title }
	};
}

export function updateLocalProjectType(projectType) {
	return {
		type: UPDATE_PROJECT_TYPE,
		payload: { projectType }
	};
}

export function updateLocalProjectFloors(minFloor, maxFloor) {
	return {
		type: UPDATE_PROJECT_FLOORS,
		payload: { minFloor, maxFloor }
	};
}

export function clearCurrentProject() {
	return () => ({
		type: SET_CURRENT_PROJECT
	});
}

export function getError() {	
  return ({ dispatch, firebaseDatabase, bugsnag }) => {
  	const getPromise = async () => {
			var tomer = "tomer";
	  	var error = new ExtraError('extra error with missing content of my error but its good', {"myVal":"this is the metadata 2"});
	    //throw error;

	    console.log('getError')
	    var x = 1;
	    var y = 0;
	    var z = x / y;

	    console.log("z:" + z)
	  };

    return {
      type: 'END_PROJECTS_LISTENER fsdf',
      payload: getPromise()
    };
  }
}