import * as actions from './actions';
import * as postsActions from '../posts/actions';
import { getAppState } from '../configureMiddleware';
import SubCategory from './subCategory';
import RequiredAction from './requiredAction';
import { Map } from 'immutable';
import { Record } from '../transit';

const MIN_RECOMMENDED_SUBCATEGORYS = 5;

const InitialState = Record({
  //map: Map(),
  subCategoriesMap: Map(),
  requiredActionsMap: Map(),
  formUniversalIdsMap: Map(),
  lastUpdateTS: 0,
  didLoad: false,
  projectSubCategories: Map(),
  recentlySubCategories: Map(),
  groupsSubTypes: Map(),
  professions: Map,
}, 'quasiStatics');

export default function quasiStaticsReducer(state = new InitialState, action) {
  switch (action.type) {
    case actions.GET_QUASISTATICS + "_SUCCESS": {
      if (!action.payload)
        return state;

      const { lastUpdateTS, quasiStatics } = action.payload;

      let subCategoriesMap = new Map();
      let requiredActionsMap = new Map();
      let formUniversalIdsMap = new Map();
      if (quasiStatics && quasiStatics.subCategories)
        (quasiStatics.subCategories || {}).loopEach((tradeKey, TradeSubCategories) => 
          (TradeSubCategories || {}).loopEach((key, subCategory) => { 
            if (subCategory) {
              let subCategoriesVals = { 
                id: subCategory.id, 
                types: subCategory.types, 
                title: subCategory.title, 
                trade: tradeKey,
                systemTitle: subCategory.getNested(['title', 'en'], '')
              };
              if (subCategory.color) subCategoriesVals.color = subCategory.color;
              subCategoriesMap = subCategoriesMap.setIn([tradeKey, subCategory.id], new SubCategory(subCategoriesVals));
            }
          })
        );

        
      if (quasiStatics && quasiStatics.requiredActions)
      (quasiStatics.requiredActions || {}).loopEach((reqKey, reqActions) => {
          if (reqActions) {
            let reqActionsVals = { 
              id: reqActions.id, 
              types: reqActions.subCategory, 
              title: reqActions.title, 
              trade: reqActions.trade
            };
            requiredActionsMap = requiredActionsMap.setIn([reqActions.id], new RequiredAction(reqActionsVals));
          }
        });

      if (quasiStatics && quasiStatics.forms)
        (quasiStatics.forms || {}).loopEach((reqKey, formUniversalId) => {
        if (formUniversalId) {
          let universalIdObject = { 
            id: formUniversalId.id, 
            label:formUniversalId.id + " - " + formUniversalId.getNested(['description','he'], ''),
            title:formUniversalId.id + " - " + formUniversalId.getNested(['description','he'], ''),
            description: formUniversalId.getNested(['description'], {})
          };
          formUniversalIdsMap = formUniversalIdsMap.setIn([universalIdObject.id], universalIdObject);
        }
      });

        
      let standardBusinessTypesList = ['professions', 'groupsSubTypes'];//, 'equipmentTypes', 'inspectorType', 'equipmentStatus', 'excavationsTestReason', 'LevelTowersTestReason'];
      standardBusinessTypesList.forEach(currType => {
        let currTypeMap= new Map();
        if (quasiStatics && quasiStatics[currType])
          (quasiStatics[currType] || {}).loopEach((reqKey, currTypeItem) => {
              if (currTypeItem) {
                currTypeMap = currTypeMap.setIn([currTypeItem.id], currTypeItem);
            }
          });
          state = state.set(currType, currTypeMap);
        });
        
      state = state.set('didLoad', true);
      state = state.set('lastUpdateTS', lastUpdateTS ? lastUpdateTS : 0);
      state = state.set('requiredActionsMap', requiredActionsMap);
      state = state.set('formUniversalIdsMap', formUniversalIdsMap);
      return  state.set('subCategoriesMap', subCategoriesMap);
    }

    case postsActions.CREATE_NEW_POST + "_SUCCESS": {
      if (!action.payload)
        return state;

      const { projectId, post } = action.payload;
      
      if (!post.subCategory || !post.subCategory.id || !projectId) 
        return state;

      state = state.setIn(['recentlySubCategories', projectId, post.subCategory.id], {lastUse: Date.now()});

      // Add to recently subCategories
      if (state.getIn(['recentlySubCategories', projectId]).size > MIN_RECOMMENDED_SUBCATEGORYS) {
       
        var newRecentlySubCategories = state.recentlySubCategories.sort(function(a, b){
          return (b.lastUse || 0)-(a.lastUse || 0);
        });
        var lastSubCategory = newRecentlySubCategories.last();
        state = state.deleteIn(['recentlySubCategories', lastSubCategory.id]);
      }
      return state;
    }

    case actions.LOAD_PROJECT_QUASISTATICS + "_SUCCESS": {
      if (!action.payload)
        return state;

      const { projectId, subCategories } = action.payload;
      
      if (!subCategories || !projectId) 
        return state;

      state = state.setIn(['projectSubCategories', projectId], subCategories);

      return state;
    }

    case actions.CLEAN_CACHED_QUASISTATICS: {
      state.set('subCategoriesMap',  Map());
      state.set('requiredActionsMap',  Map());
      
      return state.set('lastUpdateTS', 0);
    }
  }
  
  return state;
}
