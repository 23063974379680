import React from 'react';
import {newId} from './funcs';
import _ from 'lodash';
import {getUniqueFirebaseId} from '../../../common/lib/utils/utils';
import _fp from 'lodash/fp';
import Image from './Image';
import theme from '../../assets/css/theme';
import {PictureUpload} from '../index';
import {NoValueComponent} from './InputField';
import propertiesMessages from '../../../common/propertiesTypes/propertiesMessages';
import PDF from '../../assets/img/icons/pdf.png';
import moment from 'moment';
import systemMessages from '../../../common/app/systemMessages';
import Text from './Text';
import Button from '../../app/standardComponents/Button';
import {injectIntl} from 'react-intl';

const ICON_IMAGE_MAX_WIDTH = 160;
const DEFAULT_MAX_CHARS = 150;
const ICON_IMAGE_MAX_SIZE = 68;

class UploadsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.onRemoveFile = this.onRemoveFile.bind(this);
    this.onFileUploadSubmit = this.onFileUploadSubmit.bind(this);
    this.setComponentData = this.setComponentData.bind(this);
    this.id = newId('UploadsComponent');
    this.state = {
      valArray: [],
      isArchivable: false,
      visibleArchives: {},
      replace: false,
      fileRows: [],
    };
  }

  componentWillMount() {
    this.setComponentData({ firstMount: true }, this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  setComponentData(props, nextProps) {
    let newStateChanges = {};

    if (_.get(props, ['settings', 'isArchivable']) !== _.get(nextProps, ['settings', 'isArchivable']))
      newStateChanges.isArchivable = _.get(nextProps, ['settings', 'isArchivable'], false);

    if (props.type !== nextProps.type)
      newStateChanges.replace = (nextProps.type == 'Picture' || nextProps.type == 'PDF');

    if (_.get(props, ['settings', 'accept']) != _.get(nextProps, ['settings', 'accept']) || props.type !== nextProps.type)
      newStateChanges.accept = _.get(nextProps, ['settings', 'accept'], (nextProps.type == 'Picture' ? '.png,.jpeg,.jpg' : nextProps.type == 'PDF' ? '.pdf' : '.png,.jpeg,.jpg,.pdf'));

    if (Object.values(newStateChanges).length != 0)
      this.setState(newStateChanges);
  }

  onFileUploadSubmit(uploadedChange, refFileIndex, uriPropPath) {
    const { onChange, value, multiple } = this.props;
    const { replace, isArchivable } = this.state;

    let valArray = value;
    let uploadedArray = Array.isArray(uploadedChange) ? [...uploadedChange] : [ uploadedChange ];

    if (uriPropPath)
      uploadedArray = uploadedArray.map(uploadRow => {
        uploadRow = Object.assign({}, {...uploadRow}, {[uriPropPath]: uploadRow.data});
        delete uploadRow.data;
        return uploadRow;
      });


    let newValArray = [];
    const hasRefFileIndex = !_.isNil(refFileIndex);

    if (isArchivable)
      uploadedArray = uploadedArray.map(file => Object.assign({}, file, { version: 1, fileRefId: getUniqueFirebaseId() }));

    if (replace)
      newValArray = uploadedArray;
    else {
      newValArray = valArray.slice();
      if (hasRefFileIndex) {
        const originalRef = newValArray[refFileIndex];
        if (originalRef) {
          const updatedOriginalFileRef = Object.assign({}, originalRef, { isArchive: true, fileRefId: originalRef.fileRefId || getUniqueFirebaseId(), version: originalRef.version || 1 });
          newValArray[refFileIndex] = updatedOriginalFileRef;
          uploadedArray[0].version = updatedOriginalFileRef.version + 1;
          uploadedArray[0].fileRefId = updatedOriginalFileRef.fileRefId;
        }
      }

      newValArray = newValArray.concat(uploadedArray);
    }

    if (hasRefFileIndex)
      this.setState({ visibleArchives: {} });

    if (onChange)
      onChange(multiple ? newValArray : newValArray[0]);
  }

  onRemoveFile(index) {
    const { onChange, value, multiple } = this.props;
    const { visibleArchives } = this.state;

    let newValArray = null;

    const isArchivesVisible = Boolean(Object.values(visibleArchives).filter(Boolean).length);

    let newStateChanges = {};
    if (multiple && Number.isInteger(index)) {
      newValArray = _fp.set([index, 'isDeleted'], true, value);
      const file = _.get(newValArray, [index], null);
      if (file && file.fileRefId) {
        const [indexOfLatestFileVersion, latestArchiveFile] = newValArray.reduce(([indexOfLatestArchiveFile, latestFileArchive], currFile, index) => {
          const isFileArchiveOfTargetFile = currFile && !currFile.isDeleted && currFile.isArchive && (currFile.fileRefId === file.fileRefId);
          return (
            isFileArchiveOfTargetFile && (!latestFileArchive || currFile.version > latestFileArchive.version)
              ? [index, currFile]
              : [indexOfLatestArchiveFile, latestFileArchive]
          );
        }, []);

        if (latestArchiveFile)
          newValArray = _fp.set([indexOfLatestFileVersion, 'isArchive'], false, newValArray);
        else if (isArchivesVisible)
          newStateChanges.visibleArchives = {};
      }
      else if (isArchivesVisible)
        newStateChanges.visibleArchives = {};
    }

    if (_.keys(newStateChanges).length)
      this.setState(newStateChanges);

    if (onChange)
      onChange(newValArray);
  }

  getFileRows = (nextState = this.state, nextProps = this.props) => {
    const { disabled, mode, value } = nextProps;
    const { isArchivable, visibleArchives } = nextState;

    const isArchivesVisible = Boolean(Object.values(visibleArchives).filter(Boolean).length);
    const filesArray = value;
    let maxImagesPerRow = mode == "card" ? 3 : 5;
    let filesByRefId = filesArray.reduce((acc, file, index) => !file || (file && file.isDeleted) ? acc : _.set(acc, [file.fileRefId || this.getFileSrc(file), _.get(acc, [file.fileRefId || this.getFileSrc(file)], []).length || 0], { ...file, originalIndex: index }), {});
    let fileComponents = [];
    Object.entries(filesByRefId).forEach(([fileRefId, files]) => {
      files.sort((a, b) => b.originalIndex - a.originalIndex)// Because we sort, first file is the original file
      .forEach((file, index) => {

        if (isArchivable && !disabled && index === 0 && isArchivesVisible && visibleArchives[fileRefId])
          fileComponents.push({
            fileRefId,
            renewButton: true,
            component: this.getUploadImageComponent(file.originalIndex, 'uri')
          });

        if ((isArchivesVisible && visibleArchives[fileRefId]) || (!isArchivesVisible && !file.isArchive))
          fileComponents.push(file);
      });
    });

    let fileRows = [[]];
    fileComponents.forEach((file, index) => {
      if (fileRows[fileRows.length - 1].length == maxImagesPerRow)
        fileRows.push([]);
      fileRows[fileRows.length - 1].push(file);
    });

    if (!disabled && !isArchivesVisible) {
      if (fileRows[fileRows.length - 1].length == maxImagesPerRow)
        fileRows.push([]);

      fileRows[fileRows.length-1].push({
        addButton: true,
        component: this.getUploadImageComponent(undefined, 'uri')
      });
    }

    return fileRows;
  };

  getImageIcon = (file, index, src, onSelect) => {
    const { multiple, disabled, onImageSelect, mode } = this.props;
    const { isArchivable, visibleArchives } = this.state;
    src = src || this.getFileSrc(file);
    const isArchivesVisible = Object.values(visibleArchives).filter(Boolean).length;
    const IMAGE_ICON_MAX_WIDTH = ICON_IMAGE_MAX_SIZE;
    return (
      <Image
        regularImage={true}
        onRemove={Boolean(!isArchivable || isArchivesVisible && !file.isArchive) && (() => this.onRemoveFile(multiple ? index : null))}
        showButtonsOnHover={!disabled}
        onClick={(onSelect || onImageSelect) ? (onSelect || onImageSelect) : undefined}
        src={src}
        imageContainerStyle={{maxWidth: IMAGE_ICON_MAX_WIDTH}}
      />
    );
  };

  getUploadImageComponent = (refFileIndex, uriPropPath) => {
    const { mode, multiple, size, withResize, resizePercent } = this.props;
    const { accept } = this.state;

    return (
      <div style={{ display: 'flex', flex: 1, justifyContent: 'center', border: '1px solid ' + theme.brandNeutralLight + '80', height: '100%' }}>
        <div style={{ alignSelf: 'center', padding: 10 }}>
          <PictureUpload
            mode={mode}
            multiple={!_.isNil(refFileIndex) ? false : multiple}
            accept={accept}
            height={size}
            withResize={withResize}
            resizePercentFromDefaultResize={resizePercent}
            onSubmit={(uploadedArray) => this.onFileUploadSubmit(uploadedArray, refFileIndex, uriPropPath)}
          />
        </div>
      </div>
    );
  };


  getFileSrc = (file) => file && (file.data ? file.data : file.uri)

  render() {
    const {
      onImageSelect, openPDFInWebPage, mode,
      disabled, intl, noValueComponentValueStyle,
      onCardClick,
    } = this.props;

    const { visibleArchives, isArchivable } = this.state;

    const maxImagesPerRow = mode === "card" ? 3 : 5;
    const fileRows = this.getFileRows();
    const isArchivesVisible = Boolean(Object.values(visibleArchives).filter(Boolean).length);

    if (fileRows.length === 1 && _.isEmpty(_.head(fileRows)))
      return <NoValueComponent defaultText={propertiesMessages.empty} noValueComponentValueStyle={noValueComponentValueStyle}/>;

    return (
      <div style={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'flex-start' }}>
        {fileRows.map((row) => (
          <>
            <div style={{ display:'flex', flex:1, flexDirection: 'row', justifyContent: 'flex-start' }}>
              {row.map((file) => {
                const fileUri = file.uriPdf || file.uri;
                let imageComp = null;

                if (file.addButton || file.renewButton)
                  imageComp = file.component;
                else if (['pdf', 'application/pdf'].includes(file.type) || ['pdf', 'application/pdf'].includes(file.extension) || file.uriPdf)
                  imageComp = (
                    <div style={{ display: 'flex', flex: 1, flexDirection:'column', position:'relative', width: '100%', height: '100%', alignItems: 'center'  }}>
                      {Boolean(openPDFInWebPage)
                        ? this.getImageIcon(file, file.originalIndex, PDF, () => { if (onImageSelect) onImageSelect(fileUri, true)})
                        : Boolean(fileUri)
                          ? <a href={fileUri} target="_blank">{this.getImageIcon(file, file.originalIndex, PDF, () => {})}</a>
                          : this.getImageIcon(file, file.originalIndex, PDF, () => {})
                      }
                    </div>
                  );
                else
                  imageComp = (
                    <div style={{ display: 'flex', flex: 1, flexDirection:'column', position:'relative', alignItems: 'center' }}>
                      {this.getImageIcon(file, file.originalIndex)}
                    </div>
                  );
                return (
                  <div key={this.getFileSrc(file)} style={{ alignItems: 'center', padding:5, flex: ('1 0 ' + (100 / maxImagesPerRow) + '%'), maxWidth: ICON_IMAGE_MAX_WIDTH }}>
                    {imageComp}
                  </div>
                );
              })}
            </div>
            <div style={{ display:'flex', flexDirection:'row', justifyContent: 'flex-start' }}>
              {(!row || !row.length) ?
                <ValueComponent defaultText={propertiesMessages.empty} />
                :
                row.map((file) => {
                  if (file.addButton)
                    return null;
                  const isShowArchivesButton = isArchivable && ((isArchivesVisible && file.renewButton || (disabled && !file.isArchive)) || (!isArchivesVisible && !file.isArchive && !file.addButton));
                  const fileRefId = file.fileRefId || this.getFileSrc(file);
                  const fileTitle = file.title || moment(file.uploadTS || file.updatedTS).format(intl.formatMessage(systemMessages.fullDateFormat))

                  return (
                    <div key={this.getFileSrc(file)} style={{ display: 'flex', justifyContent: file.renewButton ? 'flex-start' : 'space-between', flexDirection: 'column', padding:5, flex: ('1 0 ' + (100 / maxImagesPerRow) + '%'), maxWidth: ICON_IMAGE_MAX_WIDTH }}>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {Boolean(isArchivesVisible && !file.renewButton) && (
                          <div style={{ fontWeight: 'bold', padding: theme.verticalMargin, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'pre-wrap' }}>
                            {intl.formatMessage(systemMessages.version)}: {file.version || 1} {Boolean(!file.isArchive) && `(${intl.formatMessage(systemMessages.latest).toLowerCase()})`}
                          </div>
                        )}
                        <Text style={{ padding: theme.verticalMargin, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'pre-wrap' }} maxChars={DEFAULT_MAX_CHARS} maxLines={1}>
                          {fileTitle}
                        </Text>
                      </div>
                      {isShowArchivesButton
                        ?  (
                          <Button
                            style={{ marginLeft: 0, marginRight: 0, maxWidth: '100%', minWidth: 'unset', width: 'unset' }}
                            onClick={() => this.setState({ visibleArchives: Object.assign({}, visibleArchives, { [fileRefId]: !visibleArchives[fileRefId] }) })}
                          >
                            {visibleArchives[fileRefId] ? systemMessages.goBack : systemMessages[disabled ? 'archive' : 'edit']}
                          </Button>
                        ) : <div />
                      }
                    </div>
                  );
                })}
            </div>
          </>
        ))}

      </div>
    );
  }
}

UploadsComponent = injectIntl(UploadsComponent);

export default UploadsComponent;