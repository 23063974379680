import React, { Component } from 'react';
import { compose, hoistStatics } from 'recompose';
import { connect } from 'react-redux';
import { connectContext } from 'react-connect-context';
import _ from 'lodash';
import StandardInput from '../../components/CementoComponents/StandardInput';
import theme from '../../assets/css/theme';
import { updateConfigurations, getConfigurations } from '../../../common/configurations/actions';
import { startLoading, hideLoading } from '../../../common/app/actions';
import systemMessages from '../../../common/app/systemMessages';
import { ProjectContext } from '../../../common/projects/contexts';

const companyViewOptions = {
  qa: { value: "qa", label: "Quality", path: ["qa", "isActive"] },
  safety: { value: "safety", label: "Safety", path: ["safety", "isActive"] },
  membersSettings: {
    value: "membersSettings",
    label: "Members settings",
    path: ["settings", "members", "isActive"],
  },
  spec: { value: "spec", label: "Spec", path: ["spec", "isActive"] },
};

class CompanyViewManager extends Component {
  constructor(props) {
    super(props);
    this.saveChanges = this.saveChanges.bind(this);
    this.refreshConfigurations = this.refreshConfigurations.bind(this);
    this.state = {
      companyViewConfigurations: {},
    };
  }

  UNSAFE_componentWillMount() {
    const { setHeaderParams } = this.props;
    setHeaderParams(null);
    this.setComponentData({ firstMount: true }, this.props);
    
    this.refreshConfigurations();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  setComponentData(prevProps, nextProps) {
    let stateChanges = {};
    if (prevProps.selectedCompanyId != nextProps.selectedCompanyId || prevProps.configurations != nextProps.configurations) {
      const companyViewConfigurations = nextProps.getNested(['configurations', 'features', 'companyView'], {});
      stateChanges.companyViewConfigurations = companyViewConfigurations.toJS ? companyViewConfigurations.toJS() : companyViewConfigurations;
      stateChanges.didChange = false;
    }

    if (_.values(stateChanges).length) this.setState(stateChanges);
  }

  handleChange(path, value) {
    const companyViewConfigurations = _.cloneDeep(this.state.companyViewConfigurations);
    _.set(companyViewConfigurations, path, value);
    const originalConfig = this.props.getNested(['configurations', 'features', 'companyView'], {});
    const didChange = !_.isEqual(companyViewConfigurations, originalConfig);
    this.setState({ companyViewConfigurations, didChange });
  }

  refreshConfigurations() {
    const { getConfigurations, selectedCompanyId } = this.props;
    if (selectedCompanyId) {
      getConfigurations('companies', selectedCompanyId);
    }
  }

  async saveChanges() {
    const { updateConfigurations, selectedCompanyId, startLoading, hideLoading } = this.props;
    const { companyViewConfigurations, didChange } = this.state;

    if (!didChange)
      return;

    let update = {};
    _.entries(companyViewConfigurations).forEach(([key, val]) => {
      _.entries(val).forEach(([innerKey, innerVal]) => {
        update[`features/companyView/${key}/${innerKey}`] = innerVal;
      });
    });
    const operationId = 'updateCompanyViewConfig';
    startLoading({ title: systemMessages.updating, operationId });
    await updateConfigurations(update, selectedCompanyId, "companies");
    hideLoading(operationId);
    setTimeout(() => this.refreshConfigurations(), 1000);
  }

  render() {
    const { companyViewConfigurations, didChange } = this.state;

    return (
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: 50,
        }}
      >
        {_.entries(companyViewOptions).map(([key, option]) => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div style={{ padding: 10 }}>{option.label}</div>
            <StandardInput
              type={"Boolean"}
              key={key}
              name={option.label}
              value={_.get(companyViewConfigurations, option.path, false)}
              onChange={(value) => {
                this.handleChange(option.path, value);
              }}
            />
          </div>
        ))}
        <div
          onClick={this.saveChanges}
          style={{
            ...(Boolean(didChange)
              ? {
                  cursor: "pointer",
                  borderColor: theme.brandPrimary,
                  borderWidth: 1,
                  borderStyle: "solid",
                }
              : {
                  cursor: "initial",
                  backgroundColor: theme.brandNeutralLight,
                }),
            borderRadius: 5,
            padding: theme.padding,
            margin: theme.margin,
            marginTop: 2 * theme.margin,
          }}
        >
          save changes
        </div>
      </div>
    );
  }
}

const enhance = compose(
  connectContext(ProjectContext.Consumer),
  connect(state => ({}), {
    updateConfigurations,
    getConfigurations,
    startLoading,
    hideLoading,
  })
);
export default enhance(CompanyViewManager);
