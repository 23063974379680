import * as actions from './actions';
import * as lastUpdatesActions from '../lastUpdates/actions';
import _ from 'lodash';
import { Map } from 'immutable';
import { Record } from '../transit';

const InitialState = Record({
  map: Map(), 
  companiesMap: Map(),
  didLoad: Map(),
  lastUpdated: Map(),
  lastUpdateAvailable: Map(),
  debugConfigurations: Map(),
}, 'configurations');

const initialState = new InitialState;

export default function configurationsReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState;

  switch (action.type) 
  {
    case lastUpdatesActions.GET_LAST_UPDATES: {
        if (!action.payload || !action.payload.projects)
          return state;
  
        const { projects } = action.payload;
        Object.keys(projects).forEach(projectId => {
          let TS = projects.getNested([projectId,'configurations','lastUpdateTS'], 1);
          let curr = state.getNested(['lastUpdateAvailable', projectId], null)
          if (curr < TS) state = state.setIn(['lastUpdateAvailable', projectId ], TS);
        });
  
        return state;
      }
  
    case actions.GET_CONFIGURATIONS + "_SUCCESS": {
      if (!action.payload)
        return state;
      const { configurations, scopeType, scopeId } = action.payload;
      state = state.setIn(['didLoad', scopeId], true);

      state = state.setIn(['lastUpdated', scopeId], state.lastUpdateAvailable.get(scopeId));
      return state.setIn([scopeType == 'companies' ? 'companiesMap' : 'map', scopeId], configurations);
    }

    case lastUpdatesActions.GET_SYNCED: {
      const projects = _.get(action, ['payload', 'projects']);
      _.forIn(projects, (project, projectId) => {
        if (project.config || _.isEqual(project.config, state.getNested(['debugConfigurations', projectId])))
          state = Boolean(project.config)
            ? state.setIn(['debugConfigurations', projectId], project.config)
            : state.removeIn(['debugConfigurations', projectId]);
      });
    }

    case actions.UPDATE_LOCAL_CONFIGURATIONS: {
      let { projectId, configuration, shouldClearBefore } = action.payload || {};
			
			if (!projectId || !configuration ) return state;

			if (shouldClearBefore) state = state.setIn(['map'], new Map()); 

			_.forIn(configuration, (config, configId) => {
				config = config.toJS ? config.toJS() : { ...config };

				if (!config.createdAt) config.createdAt = new Date().getTime();

				state = state.setIn(['map', projectId, configId], { ...config, isLocal: true });
			});

			return state;
    }
  }

  return state;
}
