import { startLoading } from "../app/actions";
import systemMessages from "../app/systemMessages";
import {getSnapshotData} from '../lib/utils/utils';

export const GET_STAGES = 'GET_STAGES';
export const GET_LOCAL_STAGES = 'GET_LOCAL_STAGES';
export const END_STAGES_LISTENER = 'END_STAGES_LISTENER';
export const CLEAR_LOCAL_STAGES = 'CLEAR_LOCAL_STAGES';
export const UPDATE_STAGES = 'UPDATE_STAGES';
export const UPDATE_LOCAL_STAGES = 'UPDATE_LOCAL_STAGES';
export const SAVE_STAGES = 'SAVE_STAGES';
export const GET_NEW_STAGE_ID = 'GET_NEW_STAGE_ID';
export const DELETE_NEW_STAGE = 'DELETE_NEW_STAGE';

export function getStages(scopeId, scopeType = 'projects') { 
  
    return {
      type: GET_STAGES,
      payload: getSnapshotData({ api: 'stages', firebase: `checklists`, firebaseSuffix:`stages` }, scopeId, scopeType == 'projects' ? 'projectId' : 'companyId')
  };
}

export function tempUpdateStages(projectId, stages) {
  return ({ firebase, firebaseDatabase }) => {
    const getPromise = async () => {

      if (!projectId || !stages)
        return false;
        
      const pathRoot = 'checklists/' + projectId + '/stages';
      
      let updates = {};
      stages.loopEach((i, stage) => {
        if (!stage.id) {
          stage.id = firebaseDatabase().ref(pathRoot).push().key;
        }
        
        if (!stage.createdAt)
          stage.createdAt = new Date().getTime();

        updates[pathRoot + '/' + stage.id] = stage;
      });

    await firebase.update(updates);
    
    return { projectId, stages };
  };

    return {
      type: UPDATE_STAGES,
      payload: getPromise()
    };
  };
}

export function updateLocalStages(projectId, stages, shouldClearBefore) {
  return {
    type: UPDATE_LOCAL_STAGES,
    payload: { projectId, stages, shouldClearBefore }
  }
}

export function removeLocalStages(projectId) {
  return {
    type: CLEAR_LOCAL_STAGES,
    payload: { projectId }
  }
}

export function deleteNewStage(projectId, stage) {
  return {
    type: DELETE_NEW_STAGE,
    payload: { projectId, stage }
  }
}

export function getNewStageId(projectId) {
  return ({ firebaseDatabase }) => {
    try {
			let push = firebaseDatabase().ref('checklists/' + projectId + '/stages').push();
			return {
				type: GET_NEW_STAGE_ID,
				payload: { id: push.key }
			};
		} catch (error) {
			throw error;
    }
  }
}
