import React from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { compose } from "recompose";
import { connectContext } from "react-connect-context";
import _ from "lodash";
import { ProjectContext } from "../../../common/projects/contexts";
import { uploadPropertiesInstances } from "../../../common/propertiesInstances/actions";
import { saveGroupsMappings } from "../../../common/propertiesMappings/actions";
import PropertyRow from "../Properties/PropertyRow.js";
import { lokiInstance, writeMixpanelLogs } from "../../../common/configureMiddleware";
import withStyles from "@material-ui/core/styles/withStyles";
import MultiCheckSelect from "../../components/CementoComponents/MultiCheckSelect";
import InputField from "../../components/CementoComponents/InputField";
import theme from "../../assets/css/theme";
import loginPageStyle from "../../assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import ParentChildView from "../Checklists/ParentChildView";
import { getGroupsInstances, getGroupsInstancesMapByParentId, getObjectsTitlesMap } from "./funcs";
import * as propertyTypes from '../../../common/propertiesTypes/propertiesTypes';
import { SYSTEM_SUBJECTS_TYPES } from "../../../common/propertiesTypes/propertiesTypes";
import { startLoading, hideLoading } from '../../../common/app/actions';
import systemMessages from '../../../common/app/systemMessages';

const FETCH_PROPERTIES_MAPPING = "fetch_properties_mapping";
const MIXPANEL_LOG_GROUP = 'PropertiesGroupsManager'

class PropertiesGroupsManager extends React.Component {
  constructor(props) {
    super(props);
    this.setComponentData = this.setComponentData.bind(this);
    this.saveAllChanges = this.saveAllChanges.bind(this);
    this.onLocationGroupsChange = this.onLocationGroupsChange.bind(this);
    this.handleGroupSelect = this.handleGroupSelect.bind(this);
    this.onSort = this.onSort.bind(this);
    this.onRowChange = this.onRowChange.bind(this);
    this.selectOrUnselectAll = this.selectOrUnselectAll.bind(this);
    this.lokiPropertyInstancesListener =
      this.lokiPropertyInstancesListener.bind(this);
    this.resetSort = this.resetSort.bind(this);
    this.state = {
      subjectName: "locationsInfo",
      mappingsChanged: false,
      isObjectsMappingChanged: false,
      groupsInstancesMap: {},
      objectsMap: {},
      allGroupsMapping: {},
      allGroupsArray: [],
      selectedGroupId: null,
      selectedGroupingPropertyId: 'groups',
      selectedScope: 'project'
    }
  }

  lokiPropertyInstancesListener(collectionName) {
    const { objectsMap, allGroupsArray, subjectName } = this.state;
    const { selectedProjectId } = this.props;
    if (collectionName == "propertyInstances") {
      let newStateChanges = {};
      let lokiPropertyInstances = this.lokiPropertyInstances?.cementoFind({
        projectId: selectedProjectId,
        subjectName: subjectName,
        propId: "groups",
      });
      newStateChanges.groupsInstancesMap = getGroupsInstances(
        selectedProjectId,
        objectsMap,
        allGroupsArray,
        lokiPropertyInstances
      );
      this.setState(newStateChanges);
    }
  }

  componentWillUnmount() {
    this.lokiPropertyInstances?.cementoOff("locationInfoPageInstancesListener");
  }

  UNSAFE_componentWillMount() {
    this.lokiPropertyInstances =
      lokiInstance.getCollection("propertyInstances");
    this.lokiPropertyInstances?.cementoOn(
      "locationInfoPageInstancesListener",
      this.lokiPropertyInstancesListener
    );
    this.setComponentData({}, this.props, {}, this.state);
  }

  componentDidMount() {
    if (this.props.getRef) this.props.getRef(this);
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (
      this.state.subjectName != nextState.subjectName ||
      this.state.selectedGroupingPropertyId !==
        nextState.selectedGroupingPropertyId
    )
      this.setComponentData(this.props, nextProps, this.state, nextState);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps, this.state, this.state);
  }

  handleGroupSelect(groupId) {
    this.setState({ selectedGroupId: groupId });
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { subjectName, selectedGroupId, selectedGroupingPropertyId, objectsMap, mergerPropertiesMapping, objectsSources } = this.state;
    const shouldComponentUpdate = (
      subjectName !== nextState.subjectName ||
      selectedGroupId !== nextState.selectedGroupId ||
      selectedGroupingPropertyId !== nextState.selectedGroupingPropertyId ||
      objectsMap != nextState.objectsMap ||
      this.state.isValDiff(nextState, ['allGroupsMapping']) ||
      this.state.isValDiff(nextState, ['groupsInstancesMap']) ||
      this.state.isValDiff(nextState, ['allGroupsArray']) ||
      mergerPropertiesMapping !== nextProps.mergerPropertiesMapping ||
      objectsSources !== nextProps.objectsSources
    )

    return shouldComponentUpdate;
  }

  setComponentData(props, nextProps, state, nextState) {
    let newStateChanges = {};

    const nextSubjectName = nextState.subjectName || state.subjectName;

    if (!_.isEqual(props.selectedProjectId, nextProps.selectedProjectId))
      newStateChanges.selectedScopeId = nextProps.selectedProjectId;

    // Get locations titles
    if (
      state.subjectName != nextSubjectName ||
      props.objects != nextProps.objects ||
      props.buildings != nextProps.buildings ||
      props.floors != nextProps.floors ||
      props.units != nextProps.units
    )
      newStateChanges.objectsMap = getObjectsTitlesMap(
        nextProps,
        nextSubjectName
      );

    // Get all groups and selectedGroupId
    if (state.subjectName != nextSubjectName || state.selectedGroupingPropertyId !== nextState.selectedGroupingPropertyId ||
        !_.isEqual(props.getNested(['propertiesTypes', nextSubjectName, nextState.selectedGroupingPropertyId]), nextProps.getNested(['propertiesTypes', nextSubjectName, nextState.selectedGroupingPropertyId]))) {
      newStateChanges.allGroupsArray = nextProps.getNested(['propertiesTypes', nextSubjectName, nextState.selectedGroupingPropertyId, 'values'])
      newStateChanges.allGroupsArray = newStateChanges.allGroupsArray ? newStateChanges.allGroupsArray.map(g => ({ id: g.id,  label: g.getCementoTitle(), title: g.getCementoTitle(),})) : null;
      newStateChanges.selectedGroupId = newStateChanges.allGroupsArray && newStateChanges.allGroupsArray.length ? newStateChanges.allGroupsArray[0].id : null;
    }

    if (
      state.subjectName != nextSubjectName ||
      state.selectedGroupingPropertyId !==
        nextState.selectedGroupingPropertyId ||
      props.selectedProjectId != nextProps.selectedProjectId ||
      newStateChanges.selectedGroupId ||
      newStateChanges.objectsMap
    ) {
      let lokiPropertyInstances = this.lokiPropertyInstances?.cementoFind({
        projectId: nextProps.selectedProjectId,
        subjectName: nextSubjectName,
        propId: "groups",
      });
      newStateChanges.groupsInstancesMap = getGroupsInstances(
        nextProps.selectedProjectId,
        newStateChanges.objectsMap,
        newStateChanges.allGroupsArray,
        lokiPropertyInstances
      );
    }

    if (props.isValDiff(nextProps, ['lang']) ||
      state.isValDiff(nextState, ['subjectName']) ||
      state.isValDiff(nextState, ['selectedGroupingPropertyId']) ||
      props.isValDiff(nextProps, ['propertiesTypes', nextState.subjectName, nextState.selectedGroupingPropertyId]) ||
      props.isValDiff(nextProps, ['propertiesSections', nextState.subjectName, nextState.selectedGroupingPropertyId]) ||
      props.isValDiff(nextProps, ['propertiesMappings', nextState.subjectName]) ||
      newStateChanges.allGroupsArray) {
      const newPropsMap = (nextProps.propertiesMappings || {}).safeToJS();
      let projectGroupsMappings = newPropsMap.getNested([nextState.subjectName], {});
      let projectProperties = nextProps.getNested(['propertiesTypes', nextState.subjectName], {});
      let allGroupsMapping = {};
      (
        newStateChanges.allGroupsArray ||
        nextState.allGroupsArray ||
        this.state.allGroupsArray ||
        []
      ).loopEach((i, group) => {
        if (!allGroupsMapping[group.id]) allGroupsMapping[group.id] = {};
        projectProperties.loopEach((i, prop) => {
          if (!prop.sectionId) return;
          let pClone = prop.toJS ? prop.toJS() : { ...prop };
          pClone.label =
            nextProps.getNested(
              [
                "propertiesSections",
                nextState.subjectName || state.subjectName,
                prop.sectionId,
                "getTitle",
              ],
              "!(no section title)!"
            ) +
              " - " +
              prop.getCementoTitle() || "!(no prop title)!";
          allGroupsMapping[group.id][prop.id] = pClone;
        });
      });

      projectGroupsMappings.loopEach((groupPropTypeId, groups) => {
        groups.loopEach((groupId, group) => {
          (group.properties || []).map((propId, index) => {
            if (_.get(allGroupsMapping, [groupId, propId])) {
              allGroupsMapping[groupId][propId].checked = true;
              allGroupsMapping[groupId][propId].checkedOrdinalNo = index;
            }
          });
        });
      });
      allGroupsMapping.loopEach(
        (gId, gMappings) =>
          (allGroupsMapping[gId] = Object.values(gMappings || []))
      );
      newStateChanges.allGroupsMapping = allGroupsMapping;
    }

    if (Object.keys(newStateChanges).length > 0) this.setState(newStateChanges);
  }

  async saveAllChanges() {
    const { uploadPropertiesInstances, saveGroupsMappings, selectedProjectId, startLoading, hideLoading } = this.props;
    const { groupsInstancesMap, allGroupsMapping, mappingsChanged, isObjectsMappingChanged, subjectName, selectedGroupingPropertyId } = this.state;
		
    startLoading({ title: systemMessages.loadingMessage, operationId: FETCH_PROPERTIES_MAPPING });

    let instancesMapByParentId = getGroupsInstancesMapByParentId(groupsInstancesMap);
    let mappings = {};
    allGroupsMapping.loopEach((groupId, groupMapping) => {
      mappings[groupId] = { properties: [] };
      groupMapping.forEach((prop) => {
        if (
          prop.checked ||
          (prop.id == "groups" && prop.id == selectedGroupingPropertyId)
        )
          mappings[groupId].properties.push(prop.id);
      });
    });

    let instancesToSave = Object.values(instancesMapByParentId);
    
    if (isObjectsMappingChanged)
      await uploadPropertiesInstances(selectedProjectId, instancesToSave, subjectName)
    if (mappingsChanged)
      await saveGroupsMappings(selectedProjectId, subjectName, selectedGroupingPropertyId, mappings);

    hideLoading(FETCH_PROPERTIES_MAPPING);

    writeMixpanelLogs({
			groupId: MIXPANEL_LOG_GROUP,
			logs: [
				{
					action: 'saveAllChanges',
					user: this.props.viewer,
					projectId: this.props.selectedProjectId,
					payload: { selectedProjectId, instancesToSave, subjectName, selectedGroupingPropertyId, mappings },
				},
			],
      flush: true,
		});
  }

  onLocationGroupsChange(newItems) {
    const { groupsInstancesMap, selectedGroupId } = this.state;

    let newGroupsInstancesMap = _.assign({}, groupsInstancesMap);
    newGroupsInstancesMap[selectedGroupId] = newItems;

    this.setState({ isObjectsMappingChanged:true, groupsInstancesMap: newGroupsInstancesMap })
    
    writeMixpanelLogs({
			groupId: MIXPANEL_LOG_GROUP,
			logs: [
				{
					action: 'onLocationGroupsChange',
					user: this.props.viewer,
					projectId: this.props.selectedProjectId,
					payload: { newItems, selectedGroupId, newGroupsInstancesMap },
				},
			],
		});
  }

  onSort(newMapping) {
    const { allGroupsMapping, selectedGroupId } = this.state;
    let clone = { ...allGroupsMapping };
    clone[selectedGroupId] = newMapping.map((prop, index) =>
      prop.checked ? prop.setNested(["checkedOrdinalNo"], index) : prop
    );
    this.setState({ mappingsChanged: true, allGroupsMapping: clone });
  }

  resetSort() {
    const { allGroupsMapping, selectedGroupId } = this.state;
    let clone = { ...allGroupsMapping };
    let currGroupMapping = (clone[selectedGroupId] || [])
      .slice()
      .sort((a, b) =>
        a.ordinalNo && b.ordinalNo
          ? a.ordinalNo - b.ordinalNo
          : a.checkedOrdinalNo - b.checkedOrdinalNo
      )
      .map((curr, index) => {
        if (curr.ordinalNo) curr.checkedOrdinalNo = index;
        return curr;
      });
    clone[selectedGroupId] = currGroupMapping;
    this.setState({ mappingsChanged: true, allGroupsMapping: clone });
  }

  onRowChange(newRowObject) {
    const { allGroupsMapping, selectedGroupId } = this.state;
    let clone = { ...allGroupsMapping };
    let currGroupMapping = (clone[selectedGroupId] || []).slice();
    if (newRowObject.checked) {
      let maxIndex = 0;
      currGroupMapping.forEach((prop) => {
        if (prop.checked && prop.checkedOrdinalNo > maxIndex)
          maxIndex = prop.checkedOrdinalNo;
      });
      newRowObject.checkedOrdinalNo = ++maxIndex;
    }
    currGroupMapping.loopEach((i, curr) => {
      if (curr.id == newRowObject.id) currGroupMapping[i] = newRowObject;
    });
    clone[selectedGroupId] = currGroupMapping;
    this.setState({ mappingsChanged: true, allGroupsMapping: clone });
  }

  selectOrUnselectAll(b) {
    const { allGroupsMapping, selectedGroupId, selectedGroupingPropertyId } =
      this.state;
    let clone = { ...allGroupsMapping };
    let currGroupMapping = (clone[selectedGroupId] || []).slice();
    currGroupMapping.loopEach((i, curr) => {
      currGroupMapping[i].checked =
        curr.id == selectedGroupingPropertyId ? true : b;
      if (!b && currGroupMapping[i].ordinalNo)
        currGroupMapping[i].checkedOrdinalNo = currGroupMapping[i].ordinalNo;
    });
    clone[selectedGroupId] = currGroupMapping;
    this.setState({ mappingsChanged: true, allGroupsMapping: clone });
  }

  render() {
    const { selectedProjectId, lang, propertiesTypes, propertiesSections, objectsSources, trades } = this.props;
    const { allGroupsArray, selectedGroupId, groupsInstancesMap, allGroupsMapping, subjectName, selectedGroupingPropertyId, selectedScope, selectedScopeId } = this.state;
    let selectPropertyValues = _.reduce(_.get(propertiesTypes, [subjectName], {}), (acc, prop, propId) => {
      if (prop.type === propertyTypes.SELECTION_LIST)
        acc.push({ id: prop.id, title: _.get(propertiesSections, [subjectName, prop.sectionId], NO_SECTION).getCementoTitle() + " - " + prop.getCementoTitle() });
      return acc;
    }, []);
    
    let objects = groupsInstancesMap.getNested([selectedGroupId], {});
    objects.loopEach((i, prop) => {
      if ((prop.title || '').indexOf('id') !== -1)
        return;

      let propTitle = prop.title;

      if (!propTitle) {
        const subject = subjectName.split('sInfo')[0] || '';
        propTitle = subject.slice(0, 1).toUpperCase() + subject.slice(1);
      }

      const propTrade = _.get(prop, ['trade', 'id']);
      if (propTrade)
        propTitle += ` / ${trades.getNested([propTrade, 'getTitle'])}`

      const propId = prop.id || _.get(prop, ['instance', 'parentId'], '');
      if (propId)
        propTitle += `  (id: ${propId})`

      
      prop.title = propTitle;
    });

    return (
      <div style={{ display:'flex', flex:1, flexDirection:'column', width:'100%', height:'100%'}}>
        <InputField
          nameSizeRatio={2/12}
          key={'subjectName'} 
          style={styles.inputStyle} 
          name={'SubjectName'}
          type={propertyTypes.SELECTION_LIST}
          allowBlank={false}
          values={SYSTEM_SUBJECTS_TYPES.map(t => ({id: t, title: t}) )}
          defaultValue={{[subjectName]: subjectName}}
          onChange={val => this.setState({ subjectName: Object.keys(val)[0]} )} />
        <InputField
          nameSizeRatio={2/12}
          key={'propertyType'} 
          style={styles.inputStyle} 
          name={'Select a property'}
          type={propertyTypes.SELECTION_LIST}
          allowBlank={false}
          values={selectPropertyValues}
          defaultValue={{[selectedGroupingPropertyId]: selectedGroupingPropertyId}}
          onChange={val => this.setState({ selectedGroupingPropertyId: Object.keys(val)[0]} )} />
        <InputField 
          nameSizeRatio={2/12} 
          type={propertyTypes.SELECTION_LIST} 
          key={selectedProjectId+'groupsPropSelection'} 
          style={styles.inputStyle} 
          name={'Select a group:'} 
          allowBlank={false}
          value={{ [selectedGroupId]: selectedGroupId }}
          onChange={(val) => this.handleGroupSelect(Object.keys(val)[0])}
          values={allGroupsArray}
        />
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            height: "inherit",
          }}
        >
          <div style={{ flex: 1 }}>
            <div style={styles.buttonStyle} onClick={() => this.selectOrUnselectAll(true)}>All</div>
            <div style={styles.buttonStyle} onClick={() => this.selectOrUnselectAll(false)}>Nothing</div>
            <div style={styles.buttonStyle} onClick={this.resetSort}>Original order</div>
            <ParentChildView
              selectedPropertyId={selectedGroupingPropertyId}
              categoriesMode={true}
              lang={lang}
              editable={true}
              disableDrag={true}
              sections={allGroupsMapping.getNested([selectedGroupId], [])}
              rowComponent={PropertyRow}
              onRowChange={this.onRowChange}
              onSort={this.onSort}
              childsTitlePath={['label']}
              objectsSources={_.get(objectsSources, [subjectName, 'groups'], {})}
              objectsSourcesKey={[selectedGroupId, 'properties']}/>
          </div>
          <div style={{ flex: 1, height: "inherit" }}>
            <MultiCheckSelect
              style={{ padding: theme.paddingSize }}
              items={objects}
              titlePropPath={["title"]}
              onChange={this.onLocationGroupsChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  textCenter: {
    textAlign: 'center', alignItems: 'center', alignContent: 'center', justify: 'center',
  },
  buttonStyle: {
    borderRadius: theme.borderRadius -1 , display:'flex', minWidth: theme.minWidth, height: theme.rowHeight / 2, alignSelf:'center', justifyContent:'center', alignItems:'center', margin: theme.verticalMargin, border: '1px solid ' + theme.brandPrimary + '85', color: theme.brandPrimary, cursor: 'pointer'
  },
  inputStyle: {
    padding:'15px 15px 0px 15px', flex:'None'
  }
};

PropertiesGroupsManager = withStyles(
  theme.combineStyles(loginPageStyle, styles)
)(PropertiesGroupsManager);
PropertiesGroupsManager = injectIntl(PropertiesGroupsManager);
const enhance = compose(
  connectContext(ProjectContext.Consumer),
  connect((state) => ({}), { uploadPropertiesInstances, saveGroupsMappings, startLoading, hideLoading })
);
export default enhance(PropertiesGroupsManager);

let NO_SECTION = {
  getTitle: () => {
    return "NO_SECTION";
  },
};
