import React from 'react';
import theme from '../../../assets/css/theme';
import _ from 'lodash';
import Text from '../Text';
import { injectIntl } from 'react-intl';

/**
 * 
 * @param {{
 *  values: string[],
 *  heighlightValues: boolean,
 * }} param0 
 * @returns 
 */
const CategoryTabValues = ({ heighlightValues, values, intl }) => {
  values = values || [];

  if (values.length === 0)
    return null;

  let valueString = values.filter(Boolean)
                          .sort((a, b) => a.localeCompare(b))
                          .slice(0, 2)
                          .join(', ')
                          + (values.slice(2).length ? `, +${values.slice(2).length}` : '');

  return (
    <Text maxLines={2} style={{ ...categoryTabValues.mainContainer, color: heighlightValues ? theme.brandPrimary : theme.brandNeutral }}>
      {valueString}
    </Text>
  )
}

export default injectIntl(CategoryTabValues);

const categoryTabValues = {
  mainContainer: {
    whiteSpace: 'pre-wrap',
  }
}