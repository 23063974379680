import React, { useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { compose, hoistStatics } from "recompose";
import { connectContext } from "react-connect-context";
import { ProjectContext } from "../../../common/projects/contexts";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import regularFormsStyle from "../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import theme from "../../assets/css/theme";
import { injectIntl } from "react-intl";
import TextFilter from "../../views/Posts/TextFilter";
import postsMenuMessages from "../../../common/posts/postsMenuMessages";
import Text from "./Text";
import _ from "lodash";
import { safeFormatMessage } from "../../../common/app/funcs";

/**
 * @typedef {{ id: string }} Item
 * @typedef MultiCheckSelectProps
 * @property {string[]} [titlePropPath] - default is ['title']
 * @property {Item[]} items
 */

class MultiCheckSelect extends React.Component {
  constructor(props) {
    super(props);
    this.setComponentData = this.setComponentData.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.selectNone = this.selectNone.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleSelectionChange = this.handleSelectionChange.bind(this);
    this.state = { filter: "" };
  }

  UNSAFE_componentWillMount() {
    this.setComponentData({}, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  setComponentData(props, nextProps) {
    let newStateChanges = {};
    if (props.items != nextProps.items) {
      let newValuesMap = {};
      (nextProps.items || {}).loopEach((i, val) => _.get(val, 'id') && (newValuesMap[val.id] = val));

      newStateChanges.values = Object.values(newValuesMap);
      newStateChanges.values = newStateChanges.values.sort((a,b) => {
        if (a.hasOwnProperty('ordinalNo') && b.hasOwnProperty('ordinalNo'))
          return (a.ordinalNo - b.ordinalNo)
        else
          return String(
            this.convertTitle(
              a.getNested(nextProps.titlePropPath || ["title"], " ")
            )
          ).localeCompare(
            this.convertTitle(
              String(b.getNested(nextProps.titlePropPath || ["title"], " "))
            )
          );
      });
      newStateChanges.filteredValues = this.getFilteredValues(
        newStateChanges.values,
        this.state.filter
      );
    }

    if (Object.keys(newStateChanges).length > 0) this.setState(newStateChanges);
  }

  handleFilterChange(newFilter) {
    let stateChanges = { filter: newFilter };
    if (!newFilter) stateChanges.filteredValues = this.state.values.slice();
    else
      stateChanges.filteredValues = this.getFilteredValues(
        this.state.values,
        newFilter
      );
    this.setState(stateChanges);
  }

  getFilteredValues = (values, filterVal) => {
    const lowercaseFilter = (filterVal || "").toLowerCase();
    return (values || []).filter((value) => {
      let titleString = value.getNested(
        this.props.titlePropPath || ["title"],
        ""
      );
      titleString = this.convertTitle(titleString);

      return titleString.toLowerCase().indexOf(lowercaseFilter) != -1;
    });
  };

  convertTitle = (title) =>
    Boolean(title && title.defaultMessage)
      ? this.props.intl.formatMessage(title)
      : title;

  selectAll() {
    const { filteredValues } = this.state;
    let indexes = [];
    filteredValues.forEach((v, i) => indexes.push(i));
    this.handleSelectionChange(indexes, true);
  }

  selectNone() {
    const { filteredValues } = this.state;
    let indexes = [];
    filteredValues.forEach((v, i) => indexes.push(i));
    this.handleSelectionChange(indexes, false);
  }

  handleSelectionChange(index, newChecedIndication) {
    const { items, onChange } = this.props;
    const { filteredValues } = this.state;
    let indexesArray = Array.isArray(index) ? index : [index];
    let isArrayFormat = Array.isArray(items);
    let allItemsNew = isArrayFormat ? items.slice() : Object.assign({}, items);
    let allCheckedMap = {};
    let allUncheckedMap = {};
    indexesArray.forEach((i) => {
      let item = filteredValues[i] || {};
      if (isArrayFormat) {
        allItemsNew.forEach((v, index) => {
          if (v.id == item.id)
            allItemsNew[index] = { ...item, checked: newChecedIndication };
        });
      } else allItemsNew[item.id] = { ...item, checked: newChecedIndication };
    });
    allItemsNew.loopEach(
      (i, item) =>
        ((item.checked ? allCheckedMap : allUncheckedMap)[item.id] = item)
    );
    if (onChange) onChange(allItemsNew, allCheckedMap, allUncheckedMap);
  }

  render() {
    const { titlePropPath, height, width, style, hideSearch, hideSelectAll, aditionalSelectButtons, intl } =
      this.props;
    const { filteredValues, filter } = this.state;

    return (
      <div
        style={{
          ...(style || {}),
          display: "flex",
          flexDirection: "column",
          flex: 1,
          width: width || "100%",
          height: height || "60vh",
        }}
      >
        {Boolean(!hideSearch) && (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <TextFilter
              containerStyle={{
                margin: theme.verticalMargin,
                borderRadius: "5px",
                backgroundColor: "transparent",
              }}
              key={"filter"}
              value={filter}
              onChange={this.handleFilterChange}
              clearFilterVal={() => this.handleFilterChange("")}
            />

            {Boolean(!hideSelectAll) && (
              <div style={{ display: "flex", alignItems: "center" }}>
                {[
                  ...(aditionalSelectButtons || []),
                  { title: postsMenuMessages.all, onClick: this.selectAll },
                  { title: postsMenuMessages.none, onClick: this.selectNone },
                 ].map(({ isDisabled, onClick, style, title }) => (
                   <HoverComponent key={'multiselect-button-' + safeFormatMessage(intl, title)}>
                     {({ isHover }) => (
                       <Text
                         style={Object.assign({
                           margin: theme.verticalMargin,
                           cursor: isDisabled ? 'not-allowed' : "pointer",
                           opacity: isDisabled ? 0.3 : 1,
                           color:  isHover && !isDisabled ? theme.brandPrimary : "unset",
                         }, styles.textButtons, style)}
                         onClick={onClick}
                       >
                         {title}
                       </Text>
                     )}
                   </HoverComponent>
                 ))}
              </div>
            )}
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            overflow: "auto",
          }}
        >
          {filteredValues.map((v, index) => (
            <CheckRow
              key={"checkrow-" + (v.id || _.uniqueId()) + v.subId}
              onSelect={this.handleSelectionChange}
              titlePropPath={titlePropPath}
              index={index}
              value={v}
            />
          ))}
        </div>
      </div>
    );
  }
}

MultiCheckSelect = injectIntl(MultiCheckSelect);
MultiCheckSelect = withStyles(regularFormsStyle)(MultiCheckSelect);
const enhance = compose(connectContext(ProjectContext.Consumer));
export default enhance(MultiCheckSelect);


const styles = {
  textButtons: {
    display: "flex",
    minWidth: 30,
    height: 35,
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
  }
}


const HoverComponent = ({ children }) => {
  const [isHover, setIsHover] = useState(false);
  return (
    <div
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {children({ isHover })}
    </div>
  );
};

class CheckRow extends React.Component {
  constructor(props) {
    super(props);
    this.handleSelectionChange = this.handleSelectionChange.bind(this);
  }

  handleSelectionChange() {
    const { onSelect, value, index } = this.props;
    if (onSelect) onSelect(index, !Boolean(value.checked));
  }

  render() {
    const { value, titlePropPath, classes, intl } = this.props;

    let titleString = titlePropPath
      ? value.getNested(titlePropPath)
      : value.title;
    titleString = (titleString || {}).defaultMessage
      ? intl.formatMessage(titleString)
      : String(titleString);
    return (
      <div style={{ alignItems: "center" }}>
        <Checkbox
          key={value.id}
          onChange={this.handleSelectionChange}
          checked={Boolean(value.checked)}
          checkedIcon={<Check className={classes.checkedIcon} />}
          icon={<Check className={classes.uncheckedIcon} />}
          classes={{ checked: classes.checked }}
        />
        {titleString}
      </div>
    );
  }
}

CheckRow = injectIntl(CheckRow);
CheckRow = withStyles(regularFormsStyle)(CheckRow);
