import { OrderedMap, Map } from 'immutable';
import { CementoRecordObject, Record } from '../transit';

import * as actions from './actions';
import * as checklistsActions from '../checklists/actions';
import * as appActions from '../app/actions';
import * as lastUpdatesActions from '../lastUpdates/actions';
import * as checklistAction from '../checklists/actions';
import { REPLACE_USER } from '../auth/actions';

import ChecklistItem from './checklistItem';
import _ from 'lodash';

const InitialState = Record({
  map: new CementoRecordObject,
  lastUpdated: new CementoRecordObject,
  lastUpdateAvailable: new CementoRecordObject,  
  didLoad: new CementoRecordObject
}, 'checklistItems', false);

const initialState = new InitialState;

export default function checklistItemReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState;

  switch (action.type) {

    case lastUpdatesActions.GET_LAST_UPDATES: {
        if (!action.payload || !action.payload.projects)
            return state;

        const { projects } = action.payload;
        Object.keys(projects).forEach(projectId => {
          let TS = projects.getNested([projectId,'checklists-items','lastUpdateTS'], 1);
          let curr = state.getNested(['lastUpdateAvailable', projectId], null)
          if (curr < TS) state = state.setIn(['lastUpdateAvailable', projectId ], TS); 
        });

        return state;
    }

    case actions.UPDATE_LOCAL_CHECKLIST_ITEMS: {
      const { projectId, checklistItems, shouldClearBefore } = action.payload;
      if (!projectId || !checklistItems) return state;
      
      if (shouldClearBefore) state.map = new CementoRecordObject(); 

      _.forIn(checklistItems, (item, itemId) => {
        item = item.toJS ? item.toJS() : item;
        state = state.setIn(['map', projectId, item.id], new ChecklistItem({ ...item, weight: Number(item.weight) || null }));
      });

      return state;
    }

    case actions.DELETE_NEW_CHECKLIST_ITEM: {
      const { projectId, checklistItem } = action.payload;

      if (!projectId || !checklistItem)
        return state;

      return state.deleteIn(['map', projectId, checklistItem.id]);
    }

    case checklistsActions.REMOVE_DUPLICATED_CHECKLIST + "_SUCCESS": 
    case checklistsActions.DUPLICATE_CHECKLIST + "_SUCCESS": {
      if (!action.payload || !action.payload.checklistItems)
        return state;

      const { projectId, checklistItems } = action.payload;

      let itemsMap = state.getNested(['map', projectId], new CementoRecordObject);
      _.forIn(checklistItems, currChecklistItem => {
        if (_.has(currChecklistItem, 'id'))
          itemsMap = itemsMap.set(currChecklistItem.id, new ChecklistItem({ ...currChecklistItem, weight: Number(currChecklistItem.weight) || null }));
      });

      state = state.setIn(['map', projectId], itemsMap);
      return state;
    };

    case actions.GET_LOCAL_CHECKLIST_ITEMS + "_SUCCESS":
    case actions.GET_CHECKLIST_ITEMS + "_SUCCESS": {
      if (!action.payload || !action.payload.checklistItems)
        return state;

      const { projectId, checklistItems } = action.payload;

      let itemsMap = new CementoRecordObject;
      checklistItems.loopEach((currChecklistItemKey, currChecklistItem) => {
        if (currChecklistItem) {
          currChecklistItem = currChecklistItem.toJS ? currChecklistItem.toJS() : currChecklistItem;
          itemsMap = itemsMap.set(currChecklistItem.id, new ChecklistItem({ ...currChecklistItem, weight: Number(currChecklistItem.weight) || null }));
        }
      });
      state = state.setIn(['map', projectId], itemsMap);
      state = state.setIn(['didLoad', projectId], true);
      state = state.setIn(['lastUpdated', projectId], state.getNested(['lastUpdateAvailable', projectId]));
      return state;
    }
    case checklistAction.CLEAN_ALL_CACHED_CHECKLIST_ITEMS_AND_INSTANCES+ "_SUCCESS":
    case actions.CLEAN_CHECKLIST_ITEMS_CACHE_DATA + "_SUCCESS":
      return initialState;
  }

  return state;
}