import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { compose, hoistStatics } from "recompose";
import { connectContext } from "react-connect-context";
import _ from "lodash";
// Components
import ChecklistPage from "../Checklists/ChecklistPage";

// Other
import { saveChecklists, updateLocalChecklists, getChecklists, getNewChecklistId } from '../../../common/checklists/actions';
import systemMessages from '../../../common/app/systemMessages';
import { ProjectManagerContext } from '../../../common/projects/contexts';
import { updateLocalChecklistItems, getChecklistItems, getNewChecklistItemId } from '../../../common/checklistItems/actions';
import { updateLocalProperties } from '../../../common/propertiesTypes/actions';

import { getStages, updateLocalStages, getNewStageId } from '../../../common/stages/actions';
import { Field } from './config/projectManagerConfig';
import theme from '../../assets/css/theme';
import { startLoading, hideLoading, startToast, updateObjectsSourcesMap } from '../../../common/app/actions';
import { baseRemoveNested } from './ProjectManager2_0';
import { saveProperties } from '../../../common/propertiesTypes/actions';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { getLocationsTitlesMap2, getWantedLocations } from '../Locations/funcs';
import ScopeSelector from './ScopeSelector';
import { getMergerResult, upsertConfig } from "../../app/funcs";
import { DEFAULT_SELECTED_SCOPE } from "../../../common/app/constants";
import { writeMixpanelLogs } from "../../../common/configureMiddleware";

const FETCH_MERGER_OPERATION_ID = 'fetchMerger';
const UPSERT_OPERATION_ID = 'upsertChecklists';
const MIXPANEL_LOG_GROUP = 'ChecklistsManager'

const largeWidth = 22;
const smallWidth = 13;

const excelSheetStructure = {
  id: { name: "ID", types: ["String"], style: { width: 30 } },
  isStage: {
    name: "Is stage?",
    types: ["Boolean"],
    style: { width: smallWidth },
  },
  isChecklist: {
    name: "Is checklist?",
    types: ["Boolean"],
    style: { width: smallWidth },
  },
  isChecklistItem: {
    name: "Is checklistItem?",
    types: ["Boolean"],
    style: { width: largeWidth },
  },
  isDeleted: {
    name: "Is deleted?",
    types: ["Boolean"],
    style: { width: smallWidth },
  },
  description: {
    name: "Description",
    types: ["String"],
    style: { width: largeWidth },
  },
  extraDescription: {
    name: "Extra Description",
    types: ["String"],
    style: { width: largeWidth },
  },
  tradeId: {
    name: "Trade ID",
    types: ["Number"],
    style: { width: smallWidth },
  },
  buildingTitles: {
    name: "Building titles",
    types: ["String"],
    style: { width: largeWidth },
  },
  floorTitles: {
    name: "Floor titles",
    types: ["String"],
    style: { width: largeWidth },
  },
  unitTitles: {
    name: "Unit titles",
    types: ["String"],
    style: { width: largeWidth },
  },
  imageRequired: {
    name: "Image required?",
    types: ["Boolean"],
    style: { width: largeWidth },
  },
  descriptionRequired: {
    name: "Description required?",
    types: ["Boolean"],
    style: { width: largeWidth },
  },
  attachmentRequired: {
    name: "Attachment required?",
    types: ["Boolean"],
    style: { width: largeWidth },
  },
  drawingRequired: {
    name: "Drawing required?",
    types: ["Boolean"],
    style: { width: largeWidth },
  },
  enablePartialButton: {
    name: "Enable partial button?",
    types: ["Boolean"],
    style: { width: largeWidth },
  },
  enableIrrelevantButton: {
    name: "Enable irrelevant button?",
    types: ["Boolean"],
    style: { width: largeWidth },
  },
  weight: { name: "Weight", types: ["Number"], style: { width: smallWidth } },
  isRoutine: {
    name: "Is routine?",
    types: ["Boolean", "Number"],
    style: { width: smallWidth },
  },
  isMandatory: {
    name: "Is mandatory?",
    types: ["Boolean"],
    style: { width: smallWidth },
  },
  isDuplicatable: {
    name: "Is duplicatable?",
    types: ["Boolean"],
    style: { width: smallWidth },
  },
  enableDistributionList: {
    name: "Enable distribution list?",
    types: ["Boolean"],
    style: { width: largeWidth },
  },
  companyReadPermissions: {
    name: "Company read permissions",
    types: ["String"],
    style: { width: largeWidth },
  },
  includeInGrade: {
    name: "Include in grade?",
    types: ["Boolean"],
    style: { width: smallWidth },
  },
};

class CheckListManager extends React.Component {
  constructor(props) {
    super(props);
    this.handleConfirmSave = this.handleConfirmSave.bind(this);
    this.handleConfirmCancel = this.handleConfirmCancel.bind(this);
    this.recalcHeader = this.recalcHeader.bind(this);
    this.getChecklistsCompleteInfo = this.getChecklistsCompleteInfo.bind(this);
    this.formatAndDisplayErrors = this.formatAndDisplayErrors.bind(this);
    this.checkForErrors = this.checkForErrors.bind(this);
    this.setChecklistsMappedByStage =
      this.setChecklistsMappedByStage.bind(this);
    this.prepareDataForExcelExport = this.prepareDataForExcelExport.bind(this);
    this.getLocationsExportTitlesArrs =
      this.getLocationsExportTitlesArrs.bind(this);
    this.extractExcelData = this.extractExcelData.bind(this);
    this.onImport = this.onImport.bind(this);
    this.setAllowEdit = this.setAllowEdit.bind(this);
    this.upsertObject = this.upsertObject.bind(this);
    this.selectItemHandle = this.selectItemHandle.bind(this);
    this.changeScopeHandler = this.changeScopeHandler.bind(this);

    this.state = {
      checklistsCompleteInfo: {},
      checklistsMappedByStage: [],
      allowToggleEdit: false,
      selectedChecklist: null,
      selectedChecklistItem: null,
      selectedStage: null,
      selectedScope: DEFAULT_SELECTED_SCOPE,
			selectedScopeId: props.selectedProjectId,
      isModalOpen: false,
      stagesBackup: null,
      checklistsBackup: null,
      checklistItemsBackup: null,
      propertiesTypesBackup: null,
      buildings: {},
      floors: {},
      units: {},
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    this.setComponentData(this.props, nextProps);
  }

  async UNSAFE_componentWillMount() {
    this.setComponentData({}, this.props);

    await this.fetchMergerResult(this.state.selectedScope, this.state.selectedScopeId);

    this.setAllowEdit(false);
  }

  async componentDidMount() {
    const { setIsChecklistManagerPage } = this.props;
    if (setIsChecklistManagerPage) setIsChecklistManagerPage(true);
    this.recalcHeader();
  }

  componentWillUnmount() {
    const { setIsChecklistManagerPage } = this.props;
    if (setIsChecklistManagerPage) setIsChecklistManagerPage(false);
    this.handleConfirmCancel();
  }

  async componentDidUpdate(prevProps, prevState) {
    const { isEditMode, loading } = this.props;
    const { allowToggleEdit, selectedScope } = this.state;
    
    if (prevProps.isEditMode !== isEditMode || prevState.allowToggleEdit !== allowToggleEdit || prevState.selectedScope !== selectedScope ) this.recalcHeader();

    const loadingTitle = _.get(prevProps.loading, ["toast", "title", "id"], "NoTitle");

    const { manage } = systemMessages;
    
    if (loadingTitle === manage.saving.id && (!loading)) {
      await this.fetchMergerResult(this.state.selectedScope, this.state.selectedScopeId);

      this.setAllowEdit(true);
    }
  }

  setComponentData(props, nextProps) {
    let newStateChanges = {};

    if (
      !nextProps.isChecklistManagerPage &&
      nextProps.setIsChecklistManagerPage
    )
      nextProps.setIsChecklistManagerPage(true);

      let { buildings, floors, units } = this.state;
      let { buildings: nextBuildings, floors: nextFloors, units: nextUnits } = nextProps;

      buildings = buildings ? buildings.safeToJS() : {};
      floors = floors ? floors.safeToJS() : {};
      units = units ? units.safeToJS() : {};

      nextBuildings = nextBuildings ? nextBuildings.safeToJS() : {};
      nextFloors = nextFloors ? nextFloors.safeToJS() : {};
      nextUnits = nextUnits ? nextUnits.safeToJS() : {};

    if (!_.isEqual(buildings, nextBuildings) || 
        !_.isEqual(floors, nextFloors) || 
        !_.isEqual(units, nextUnits)) {
      newStateChanges.buildings = nextBuildings;
      newStateChanges.floors = nextFloors;
      newStateChanges.units = nextUnits;

      newStateChanges.locationsTitles = getLocationsTitlesMap2(
        nextBuildings,
        nextFloors,
        nextUnits,
        nextProps.intl
      );
    }

    const didStagesOrChecklistsOrChecklistItemsChanged = Boolean(
      props.isValDiff(nextProps, ["stages"]) ||
      props.isValDiff(nextProps, ["checklists"]) ||
      props.isValDiff(nextProps, ["checklistItems"])
    );

    if (!nextProps.hasModifications && nextProps.isEditMode) {
      const didPropTypesHadBeenChanged = !_.isEqual(
        props.propertiesTypes,
        nextProps.propertiesTypes
      );
      if (
        (Object.keys(props.getNested(["stages"], {})).length !== 0 &&
          didStagesOrChecklistsOrChecklistItemsChanged) ||
        didPropTypesHadBeenChanged
      )
        if (nextProps.setHasModifications) nextProps.setHasModifications(true);
    }

    if (
      (props.isEditMode && !nextProps.isEditMode) ||
      (!nextProps.isEditMode &&
        (didStagesOrChecklistsOrChecklistItemsChanged ||
          props.isValDiff(nextProps, ["propertiesTypes"])))
    ) {
      const { stages, checklists, checklistItems, propertiesTypes } = nextProps;
      newStateChanges.stagesBackup = _.cloneDeep(stages);
      newStateChanges.checklistsBackup = _.cloneDeep(checklists);
      newStateChanges.checklistItemsBackup = _.cloneDeep(checklistItems);
      newStateChanges.propertiesTypesBackup = _.cloneDeep(propertiesTypes);
    }

    const { selectedProjectId, isEditMode } = nextProps;
    if (isEditMode)
      if (
        props.isValDiff(nextProps, ["stagesLastUpdateTS", selectedProjectId]) ||
        props.isValDiff(nextProps, [
          "checklistsLastUpdateTS",
          selectedProjectId,
        ]) ||
        props.isValDiff(nextProps, [
          "checklistItemsLastUpdateTS",
          selectedProjectId,
        ])
      )
        if (didStagesOrChecklistsOrChecklistItemsChanged) {
          const { startToast } = nextProps;
          const {
            unableToEditAlert: { title, message },
            refresh,
          } = systemMessages;
          startToast({
            overlay: true,
            mandatory: true,
            title,
            message,
            actions: [
              {
                message: refresh,
                onClick: () => {
                  window.location.reload();
                },
                color: "success",
              },
            ],
          });
        }

    if (
      props.isValDiff(nextProps, ["importedFile"]) &&
      nextProps.importedFile
    ) {
      this.onImport(nextProps, false);
      if (nextProps.resetImportedFile) nextProps.resetImportedFile(true);
    }

    if (
      props.isValDiff(nextProps, ["appendImportedFile"]) &&
      nextProps.appendImportedFile
    ) {
      this.onImport(nextProps);
      if (nextProps.resetImportedFile) nextProps.resetImportedFile(true);
    }

    if (!this.allowEditIsSet) {
      if (!this.debouncedSetAllowEdit)
        this.debouncedSetAllowEdit = AwesomeDebouncePromise(() => {
          this.setAllowEdit(true);
        }, 3000);
      this.debouncedSetAllowEdit();
    }

    if (Object.keys(newStateChanges).length) {
      this.setState(newStateChanges);
    }
  }

  onImport(props, appendMode = true) {
    const {
      selectedProjectId,
      importedFile,
      appendImportedFile,
      updateLocalStages,
      updateLocalChecklists,
      updateLocalChecklistItems,
      startLoading,
      hideLoading,
      startToast,
      setStages,
      setChecklists,
      setChecklistItems,
    } = props;

    startLoading({
      title: systemMessages.loadingMessage,
      overlay: true,
      hideOnBackgroundPress: false,
    });

    let excelFile = appendMode ? appendImportedFile : importedFile;
    let toastData;
    setTimeout(() => {
      try {
        let { newStages, newChecklists, newChecklistItems } =
          this.extractExcelData(excelFile, appendMode);

        if (setStages) setStages(newStages, !appendMode);
        if (setChecklists) setChecklists(newChecklists, !appendMode);
        if (setChecklistItems) setChecklistItems(newChecklistItems, !appendMode);

        if (updateLocalStages) updateLocalStages(selectedProjectId, newStages, !appendMode);
        if (updateLocalChecklists) updateLocalChecklists(selectedProjectId, newChecklists, !appendMode);
        if (updateLocalChecklistItems) updateLocalChecklistItems(selectedProjectId, newChecklistItems, !appendMode);

        toastData = { title: systemMessages.importSuccess, type: "success" };
      } catch (err) {
        toastData = {
          overlay: true,
          mandatory: true,
          title: systemMessages.importError,
          message: systemMessages.errorOnImport,
          values: { error: String(err) },
          actions: [{ message: systemMessages.ok }],
        };
      } finally {
        hideLoading();
        startToast(toastData);
      }
    }, 1);
  }

	recalcHeader() {
    const { recalcHeaderWithOptions, calcInputField, rtl, viewer } = this.props;
    const { allowToggleEdit, selectedScope, selectedScopeId } = this.state;

    if (recalcHeaderWithOptions) {
      let options = {
        confirmSaveFunc: this.handleConfirmSave,
        confirmCancelFunc: this.handleConfirmCancel,
        allowToggleEdit,
        extraOnComp: (
          <div
            style={{
              flexGrow: 1,
              justifyContent: "flex-start",
              [rtl ? "marginLeft" : "marginRight"]: theme.verticalMargin,
              display: "flex",
            }}
          >
            <ScopeSelector 
              selectedScope={selectedScope} 
              selectedScopeId={selectedScopeId} 
              submitHandler={this.changeScopeHandler} 
              key={"scopeSelectorOpen"}
              shouldRedirectAfterSubmit
            />
            {selectedScope === "projects" && calcInputField(
              new Field(null, "Excel", ["importedFile"], null, {
                settings: {
                  importMethods: [
                    {
                      id: "fullMode",
                      label: "Import and replace all",
                      viewerPermissions: [{ adminMode: 1 }],
                    },
                    {
                      id: "appendMode",
                      ...(viewer.adminMode === 1
                        ? { label: "Import and append" }
                        : {}),
                    },
                  ],
                  exportMethods: [
                    { label: 'Export all', getDataToExportFunc: () => this.prepareDataForExcelExport('fullMode'), viewerPermissions: [{ adminMode: 1 }] }, 
                    { ...(viewer.adminMode === 1 ? { label: 'Export without IDs' } : {}), getDataToExportFunc: () => this.prepareDataForExcelExport('partialMode') }
                  ], 
                }, 
                style: { flex: 0, marginBottom: 0 }, 
                innerStyle: { alignItems: 'center', color: theme.headerColorDark },
              }))}
					</div>
				),
        editOffComp: (
          <>
            <ScopeSelector 
              selectedScope={selectedScope} 
              selectedScopeId={selectedScopeId} 
              submitHandler={this.changeScopeHandler} 
              key={"scopeSelectorClose"}
              shouldRedirectAfterSubmit
            />
					</>
        )
			};

			recalcHeaderWithOptions(options);
    }
  }

  async changeScopeHandler(scope, scopeId) {
    this.setState({
      selectedScope: scope,
      selectedScopeId: scopeId
    });

    const { changeScopeAndScopeId } = this.props;
    if (changeScopeAndScopeId) await changeScopeAndScopeId(scope, scopeId);
    await this.fetchMergerResult(scope, scopeId);
  }

  selectItemHandle({ selectedStage, selectedChecklist, selectedChecklistItem }) {
    const currSelectedChecklistId = _.get(this.state, ["selectedChecklist", "id"], null);
    const currSelectedChecklistItemId = _.get(this.state, ["selectedChecklistItem", "id"], null);
    const currSelectedStageId = _.get(this.state, ["selectedStage", "id"], null);
    const newSelectedChecklistId = _.get(selectedChecklist, ["id"], null);
    const newSelectedChecklistItemId = _.get(selectedChecklistItem, ["id"], null);
    const newSelectedStageId = _.get(selectedStage, ["id"], null);
    
      if ((currSelectedChecklistId !== newSelectedChecklistId) || 
          (currSelectedChecklistItemId !== newSelectedChecklistItemId) || 
          (currSelectedStageId !== newSelectedStageId)) {
      this.setState({
        selectedChecklist,
        selectedChecklistItem,
        selectedStage
      }); 
    }
  }

  async upsertObject(targetScope, targetScopeId) {
    const { startLoading, startToast, hideLoading } = this.props;

    const shouldUpsert = await new Promise(resolve => {
      startToast({
        overlay: true, 
        mandatory: true,
        title: "זהירות", 
        message: "האם הינך רוצה לדרוס את הגדרות המטרה?",
        actions: [
          { message: systemMessages.yes, onClick: () => resolve(true), color: 'success'},
          { message: systemMessages.no, onClick: () => resolve(false) }
        ]
      });
    });

    if (!shouldUpsert) return;

		startLoading({ title: systemMessages.loadingMessage, operationId: UPSERT_OPERATION_ID });

    const convertScope = {
      project: "projects",
      projects: "projects",
      company: "companies",
      companies: "companies",
      template: "tempaltes",
      templates: "templates"
    };

    const sourceScope = convertScope[this.props.scope];
    const sourceScopeId = this.props.selectedProjectId;
    const sourceSubjectType = "checklists";

    const currSelectedChecklistId = _.get(this.state, ["selectedChecklist", "id"], null);
    const currSelectedChecklistItemId = _.get(this.state, ["selectedChecklistItem", "id"], null);
    const currSelectedStageItemId = _.get(this.state, ["selectedStage", "id"], null);

    let subjectName = "";
    let selectedProperties = {
      checklistItemId: null,
      checklistId: null,
      stageId: null
    };

    if (currSelectedStageItemId) {
      subjectName = "checklistItem";
      selectedProperties.checklistItemId = currSelectedChecklistItemId;
      selectedProperties.checklistId = currSelectedChecklistId;
      selectedProperties.stageId = currSelectedStageItemId;
    } else if (currSelectedChecklistId) {
      subjectName = "checklist";
      selectedProperties.checklistId = currSelectedChecklistId;
      selectedProperties.stageId = currSelectedStageItemId;
    }
    else if (currSelectedStageItemId) {
      subjectName = "stage";
      selectedProperties.stageId = currSelectedStageItemId;
    }

    await upsertConfig(targetScope, targetScopeId, sourceScope, sourceScopeId, sourceSubjectType, subjectName, selectedProperties);

		hideLoading(UPSERT_OPERATION_ID);

    const {
      selectedScope: scopeType,
      selectedScopeId: scopeId
    } = this.state;

    await this.fetchMergerResult(scopeType, scopeId);
  }

  prepareDataForExcelExport(mode) {
    const { selectedProjectId } = this.props;
    const { checklistsMappedByStage } = this.state;
    const joinTitlesArr = (titlesArr) =>
      titlesArr
        .filter((val) => Boolean(val))
        .join(";")
        .replace(/\"/g, "''");
    let recalculatedSheetStructure;
    switch (mode) {
      case "partialMode":
        recalculatedSheetStructure = baseRemoveNested(excelSheetStructure, [
          ["id"],
          // ['buildingTitles'],
          // ['floorTitles'],
          // ['unitTitles'],
          // ['isRoutine'],
          // ['isDuplicatable'],
          // ['companyReadPermissions'],
        ]);
        break;

      case "fullMode":
      default:
        recalculatedSheetStructure = excelSheetStructure;
    }

    let validationRulesMap = {
      weight: {
        type: "whole",
        allowBlank: true,
      },
    };

    let data = {
      checklists: {
        sheetStructure: recalculatedSheetStructure,
        validationRulesMap,
        rowsData: [],
        sheetView: [{ state: "frozen", xSplit: 4, ySplit: 1 }],
      },
    };

    checklistsMappedByStage.loopEach((i, stageObj) => {
      // Stage
      const { stage, stageId, stageTitle, checklists } = stageObj;

      data.checklists.rowsData.push({
        id: stageId,
        isStage: true,
        isDeleted: stage.getNested(["isDeleted"]) || null,
        description: stageTitle,
        includeInGrade: stage.getNested(["includeInGrade"]) || null,
      });

      checklists.loopEach((i, checklistObj) => {
        // Checklist
        const { checklist, title: checklistTitle, items } = checklistObj;
        const { buildingTitlesArr, floorTitlesArr, unitTitlesArr } =
          this.getLocationsExportTitlesArrs(checklist, "locations");
        const sempleItemCompanyReadPermissions =
          Object.keys(
            (items[0] || {}).getNested(
              ["item", "permissions", "read", "companies"],
              {}
            )
          )[0] || null;

        data.checklists.rowsData.push({
          id: checklist.getNested(["id"]),
          isChecklist: true,
          isDeleted: checklist.getNested(["isDeleted"]) || null,
          description: checklistTitle,
          buildingTitles: joinTitlesArr(buildingTitlesArr),
          floorTitles: joinTitlesArr(floorTitlesArr),
          unitTitles: joinTitlesArr(unitTitlesArr),
          isRoutine: Boolean(checklist.getNested(["type"])) || null,
          isDuplicatable: checklist.getNested(["duplicatable"]) || null,
          companyReadPermissions: sempleItemCompanyReadPermissions,
          enableDistributionList:
            checklist.getNested(["enableDistributionList"]) || null,
        });

        items.loopEach((i, itemObj) => {
          // Item
          const { item, title: itemTitle } = itemObj;

          const { buildingTitlesArr, floorTitlesArr, unitTitlesArr } =
            this.getLocationsExportTitlesArrs(item, "onlyLocations");

          data.checklists.rowsData.push({
            id: item.getNested(["id"]),
            isChecklistItem: true,
            isDeleted: item.getNested(["isDeleted"]) || null,
            description: itemTitle,
            extraDescription: item.getNested(["extraDescription"], null),
            tradeId: item.getNested(["trade"], null),
            buildingTitles: joinTitlesArr(buildingTitlesArr),
            floorTitles: joinTitlesArr(floorTitlesArr),
            unitTitles: joinTitlesArr(unitTitlesArr),
            imageRequired: item.getNested(["requirements", "img"]) || null,
            descriptionRequired:
              item.getNested(["requirements", "desc"]) || null,
            attachmentRequired:
              item.getNested(["requirements", "file"]) || null,
            drawingRequired:
              item.getNested(["requirements", "drawing"]) || null,
            enablePartialButton:
              item.getNested(["permissions", "actions", "partial"]) || null,
            enableIrrelevantButton:
              item.getNested(["permissions", "actions", "irrelevant"]) || null,
            weight: item.getNested(["weight"], null),
            isRoutine: item.getNested(["period"], null),
            isMandatory: item.getNested(["isMandatory"], null),
          });
        });
      });
    });

    return { fileName: `checklists_${selectedProjectId}`, data };
  }

  getLocationsExportTitlesArrs(checklistOrItem, rootPathToLocations) {
    const { locationsTitles } = this.state;

    let buildingTitlesArr = [];
    let floorTitlesArr = [];
    let unitTitlesArr = [];

    [
      ["buildings", buildingTitlesArr],
      ["floors", floorTitlesArr],
      ["units", unitTitlesArr],
    ].forEach(([pathRest, arrToPushInto]) =>
      checklistOrItem
        .getNested([rootPathToLocations, pathRest], {})
        .loopEach((id, val) => {
          arrToPushInto.push(
            locationsTitles.getNested(["flatMapById", id, "fullExportTitle"])
          );
        })
    );

    return {
      buildingTitlesArr,
      floorTitlesArr,
      unitTitlesArr,
    };
  }

  extractExcelData(excelObj, appendMode) {
    const {
      lang,
      selectedProjectId,
      getNewStageId,
      getNewChecklistId,
      getNewChecklistItemId,
      buildings,
      floors,
      units,
      intl,
      projectCompanies,
    } = this.props;
    const { checklistsCompleteInfo, locationsTitles } = this.state;

    const { stagesBackup: stages, checklistsBackup: checklists, checklistItemsBackup: checklistItems } = this.state;

    if (!excelObj) return false;

    let newStages = {};
    let newChecklists = {};
    let newChecklistItems = {};
    let usedIds = {};

    // Stage track
    let currStageId = null;
    let currStageName = null;
    let currStageIsDeleted = false;
    let currStageOrdinalNo = appendMode ? Object.keys(stages).length + 1 : 1;

    // Checklist track
    let currChecklistId = null;
    let currChecklistIsDeleted = false;
    let currChecklistDupChecklistIds = null;
    let currChecklistOrdinalNo = appendMode
      ? Object.keys(checklists).length + 1
      : 1;

    // ChecklistItem track
    let currChecklistItemPeriod = null;
    let currChecklistItemReadPermissions = null;
    let currChecklistItemOrdinalNo = 1;

    let recalculatedSheetStructure = !appendMode
      ? excelSheetStructure
      : baseRemoveNested(excelSheetStructure, [
          ["id"],
          // ['tradeId'],
          // ['buildingTitles'],
          // ['floorTitles'],
          // ['unitTitles'],
          // ['isRoutine'],
          // ['isDuplicatable'],
          // ['companyReadPermissions'],
        ]);

    excelObj.setGeneralSheetsStructure(recalculatedSheetStructure);
    excelObj.eachSheet((sheetName, sheet) => {
      let currChecklist = null;
      let dupIds = [];
      sheet.eachRow((row, rowNumber) => {
        const {
          id,
          isStage,
          isChecklist,
          isChecklistItem,
          isDeleted,
          description,
          extraDescription,
          tradeId,
          buildingTitles,
          floorTitles,
          unitTitles,
          imageRequired,
          descriptionRequired,
          attachmentRequired,
          drawingRequired,
          enablePartialButton,
          enableIrrelevantButton,
          weight,
          isRoutine,
          isDuplicatable,
          includeInGrade,
          companyReadPermissions,
          enableDistributionList,
          isMandatory,
        } = row.values;
        if (id && !appendMode) {
          if (stages[id] || checklists[id] || checklistItems[id]) {
            if (usedIds[id]) {
              dupIds.push({
                id,
                rowNumber
              });
            }
          } else {
            throw intl.formatMessage(systemMessages.excel.couldNotFindId, {
              rowNumber,
            });
          }

          usedIds[id] = true;
        }

        if (!isStage && !isChecklist && !isChecklistItem) {
          throw intl.formatMessage(systemMessages.excel.missingRequiredField, {
            fields: ["isStage", "isChecklist", "isChecklistItem"].join(", "),
            description,
            rowNumber,
          });
        }

        // STAGES -----------------------------------------------------------------------------
        if (isStage) {
          currChecklistId = null;

          let oldStage = stages[id];
          if (id && !oldStage) {
            throw intl.formatMessage(systemMessages.excel.couldNotFindId, {
              rowNumber,
            });
          }


          oldStage = oldStage || {};
          let stage = {
            ...(oldStage.toJS ? oldStage.toJS() : oldStage),
            id: id ? id : getNewStageId(selectedProjectId).payload.id,
            title: {
              [lang]: description,
            },
            ordinalNo: currStageOrdinalNo,
            includeInGrade: includeInGrade === true ? true : false,
            isDeleted: isDeleted ? true : null,
          };

          if (!stage.isDeleted) currStageOrdinalNo++;

          currStageId = stage.id;
          currStageName = description;
          currStageIsDeleted = Boolean(stage.isDeleted);

          newStages[stage.id] = stage;
          return;
        }
        const selectedBuildings = getWantedLocations({
          locationsString: buildingTitles,
          type: "building",
          flatMapByExportTitle: locationsTitles.flatMapByExportTitle,
        });
        const selectedFloors = getWantedLocations({
          locationsString: floorTitles,
          type: "floor",
          flatMapByExportTitle: locationsTitles.flatMapByExportTitle,
        });
        const selectedUnits = getWantedLocations({
          locationsString: unitTitles,
          type: "unit",
          flatMapByExportTitle: locationsTitles.flatMapByExportTitle,
        });

        [selectedBuildings, selectedFloors, selectedUnits].forEach(
          (locations) => {
            if (Object.keys(locations.missingLocations).length) {
              throw (
                "Could not find the following locations in row " +
                rowNumber +
                ": " +
                Object.keys(locations.missingLocations).join("; ")
              );
            }
            else if (
              !(locations && Object.keys(locations.wantedLocations).length)
            )
              return;

            locations.locations = {};
            Object.values(locations.wantedLocations).forEach((loc) => {
              locations.locations[loc.id] = { id: loc.id };
            });
          }
        );

        const locationsObj = {
          ...(selectedBuildings.locations
            ? { buildings: selectedBuildings.locations }
            : {}),
          ...(selectedFloors.locations
            ? { floors: selectedFloors.locations }
            : {}),
          ...(selectedUnits.locations
            ? { units: selectedUnits.locations }
            : {}),
        };

        if (Object.keys(locationsObj).length > 1) {
          throw intl.formatMessage(systemMessages.moreThanOneLocation, {
            rowNumber,
          });
        }

        // CHECKLISTS -----------------------------------------------------------------------------
        if (isChecklist) {
          if (!currStageId) {
            throw intl.formatMessage(systemMessages.noCorrespondingStage, {
              rowNumber,
              description,
            });
          }

          let oldChecklist = checklists[id];
          if (id && !oldChecklist) {
            throw intl.formatMessage(systemMessages.excel.couldNotFindId, {
              rowNumber,
            });
          }


          oldChecklist = oldChecklist || {};
          let checklist = {
            ...(oldChecklist.toJS ? oldChecklist.toJS() : oldChecklist),
            id: id ? id : getNewChecklistId(selectedProjectId).payload.id,
            description,
            type: isRoutine ? "routine" : null,
            duplicatable: isDuplicatable,
            ordinalNo: currChecklistOrdinalNo,
            locations: Object.keys(locationsObj).length ? locationsObj : null,
            stageId: currStageId,
            stage: currStageName,
            isDeleted: currStageIsDeleted || isDeleted ? true : null,
            enableDistributionList,
          };

          if (!checklist.isDeleted) currChecklistOrdinalNo++;

          currChecklistId = checklist.id;
          currChecklistIsDeleted = Boolean(checklist.isDeleted);
          currChecklistItemPeriod = isRoutine ? 100 : null;

          if (
            companyReadPermissions &&
            !_.get(projectCompanies, [companyReadPermissions])
          ) {
            let availableCompanyIdsNameList = Object.entries(
              projectCompanies
            ).map(
              ([companyId, companyInfo]) => `${companyInfo.name}: ${companyId}`
            );
            throw `Could not find company with company id "${companyReadPermissions}" in the system.\nAvailable options are: ${availableCompanyIdsNameList.join(
              "\n"
            )}`;
          }

          if (Boolean(checklist.duplicatable) && Boolean(checklist.type)) {
            throw `A checklist cannot be routine and duplicatable at the same time. (row ${rowNumber})`;
          }

          currChecklistItemReadPermissions = Boolean(companyReadPermissions)
            ? {
                companies: { [companyReadPermissions]: companyReadPermissions },
              }
            : null;

          currChecklistItemOrdinalNo = 1;

          currChecklist = checklist;
          newChecklists[checklist.id] = checklist;

          currChecklistDupChecklistIds = _.get(
            checklistsCompleteInfo,
            [id, "duplicatedChecklistIds"],
            null
          );

          if (currChecklistDupChecklistIds) {
            currChecklistDupChecklistIds.forEach((checklistId) => {
              const oldDupChecklist = checklists[checklistId] || {};

              let updatedChecklist = {
                ...(oldDupChecklist.toJS
                  ? oldDupChecklist.toJS()
                  : oldDupChecklist),
                description,
                ordinalNo: currChecklistOrdinalNo,
                stageId: currStageId,
                stage: currStageName,
                isDeleted: currChecklistIsDeleted ? true : null,
              };

              newChecklists[checklistId] = updatedChecklist;
            });
          }
          return;
        }

        // CHECKLIST ITEM -------------------------------------------------------------------------
        if (isChecklistItem) {
          if (!currChecklistId) {
            throw intl.formatMessage(systemMessages.noCorrespondingChecklist, {
              rowNumber,
              description,
            });
          }

          let oldChecklistItem = checklistItems[id];
          if (id && !oldChecklistItem) {
            throw intl.formatMessage(systemMessages.excel.couldNotFindId, {
              rowNumber,
            });
          }


          oldChecklistItem = oldChecklistItem || {};

          const itemChecklist =
            currChecklist || checklists.getNested([currChecklistId]);

          let checklistItem = {
            ...(oldChecklistItem.toJS
              ? oldChecklistItem.toJS()
              : oldChecklistItem),
            id: id ? id : getNewChecklistItemId(selectedProjectId).payload.id,
            description,
            extraDescription,
            isMandatory: isMandatory ? true : null,
            onlyLocations: Object.keys(locationsObj).length
              ? locationsObj
              : null,
            permissions: {
              actions: {
                irrelevant: enableIrrelevantButton ? true : null,
                partial: enablePartialButton ? true : null,
              },
              read: currChecklistItemReadPermissions,
            },
            ordinalNo: currChecklistItemOrdinalNo,
            trade: tradeId ? tradeId.toString() : null,
            period: currChecklistItemPeriod,
            weight,
            requirements: {
              desc: descriptionRequired ? true : null,
              drawing: drawingRequired ? true : null,
              file: attachmentRequired ? true : null,
              img: imageRequired ? true : null,
            },
            isDeleted:
              currStageIsDeleted || currChecklistIsDeleted || isDeleted
                ? true
                : null,
          };

          if (checklistItem.onlyLocations)
            Object.entries(checklistItem.onlyLocations).forEach(
              ([locationType, locations]) => {
                const checklistLocations = _.get(itemChecklist, [
                  "locations",
                  locationType,
                ]);
                if (
                  (!checklistLocations && Object.keys(locations).length) ||
                  Boolean(
                    Object.values(locations).filter(
                      (loc) => !checklistLocations[loc.id]
                    ).length
                  )
                ) {
                  throw `Checklist item locations can only be chosen from its parent checklist locations (row: ${rowNumber})`;
                }
              }
            );

          checklistItem.checklistIds = (
            currChecklistDupChecklistIds || []
          ).reduce(
            (accObj, checklistId) => {
              accObj[checklistId] = { id: checklistId };
              return accObj;
            },
            { [currChecklistId]: { id: currChecklistId } }
          );

          if (!checklistItem.isDeleted) currChecklistItemOrdinalNo++;

          newChecklistItems[checklistItem.id] = checklistItem;
          return;
        }


      });

      if (dupIds.length > 0) {
        throw intl.formatMessage(systemMessages.excel.foundDuplicatedId, JSON.stringify(dupIds));
      };

    });

    return { newStages, newChecklists, newChecklistItems };
  }

  getChecklistsCompleteInfo(checklistsCompleteInfo) {
    this.setState({ checklistsCompleteInfo });
  }

  convertDeltaObjectToUpdateObject(object) {
    let res = {};
    
    _.forIn(object, (val, key) => {
      _.set(res, [val.id], val);
    });

    return res;
  }

  async handleConfirmSave() {
    const {
      stages,
      checklists,
      checklistItems,
      propertiesTypes,
      saveChecklists,
      saveProperties,
      viewer,
    } = this.props;

    const {
      stagesBackup,
      checklistsBackup,
      checklistItemsBackup,
      propertiesTypesBackup,
      selectedScope,
      selectedScopeId
    } = this.state;

    const errors = this.checkForErrors();
    
    if (Object.keys(errors).length) {
      this.formatAndDisplayErrors(errors);
      return false;
    }

    const propertiesTypesSubType = "propertiesTypes";
    
    const stagesDeltas = this.convertDeltaObjectToUpdateObject(stages);
    const checklistDeltas = this.convertDeltaObjectToUpdateObject(checklists);
    const checklistItemDeltas = this.convertDeltaObjectToUpdateObject(checklistItems);

    const stagesToDelete = this.getAllDeletedObjects(stagesBackup, stages);
    const checklistsToDelete = this.getAllDeletedObjects(checklistsBackup, checklists);
    const checklistItemsToDelete = this.getAllDeletedObjects(checklistItemsBackup, checklistItems);

    if (propertiesTypes && saveProperties) {
      propertiesTypes.loopEach((subjectName, properties) => {
        let newTemplate = {};
        _.set(newTemplate, [propertiesTypesSubType, subjectName, "properties"], properties);
        saveProperties({ viewer, scope: selectedScope, scopeId: selectedScopeId, subjectNames: [subjectName], subjectTypes: [propertiesTypesSubType], newTemplate });
      });
    }

    if ((stagesDeltas || checklistDeltas || checklistItemDeltas) && saveChecklists) {
      const payload = {
        scope: selectedScope, 
        scopeId: selectedScopeId, 
        stagesDeltas: {
          ...stagesDeltas, 
          ...stagesToDelete
        }, 
        checklistDeltas: {
          ...checklistDeltas,
          ...checklistsToDelete
        }, 
        checklistItemDeltas: {
          ...checklistItemDeltas,
          ...checklistItemsToDelete
        }}
      await saveChecklists(payload);  

      writeMixpanelLogs({
        groupId: MIXPANEL_LOG_GROUP,
        logs: [
          {
            action: 'saveAllChanged',
            user: this.props.viewer,
            projectId: this.props.selectedProjectId,
            payload,
          },
        ],
        flush: true,
      });    
    }
   
  }

  getAllDeletedObjects(backup, newData) {
    return _.values(backup)
    .filter(backupObj => {
      return _.values(newData).every(newDataObj => newDataObj.id !== backupObj.id)
    })
    .reduce((acc, objToDelete) => {
      return { ...acc, [objToDelete.id]: null };
    }, {})
  }

  getDelta(newObjs, originObjs, fullObjDelta = false) {
    let delta = {};
    _.forIn(newObjs, (currNewObj, objId) => {
      currNewObj = currNewObj?.safeToJS();

      if ((fullObjDelta && delta.getNested([objId], false)) || _.isEmpty(currNewObj)) return;

      Object.keys(currNewObj).forEach((key) => {
        let currNewVal = currNewObj[key]?.safeToJS();

        let currOriginObj = originObjs.getNested([objId], {});
        currOriginObj = currOriginObj?.safeToJS();

        if (_.isEqual(currNewVal, currOriginObj[key])) return;

        if (fullObjDelta) delta = delta.setNested([objId], currNewObj);
        else delta = delta.setNested([objId, key], currNewVal);
      });
    });

    return Object.keys(delta).length ? delta : false;
  }

  checkForErrors() {
    const { checklistsCompleteInfo } = this.state;
    const { checklists, checklistItems, viewer } = this.props;

    let errors = {};
    if (!Boolean(viewer.adminMode)) {
      let errorsTemplate = {
        checklistId: null,
        itemId: null,
        missingLocation: false,
        missingTrade: false,
        isEmpty: false,
      };
      checklistsCompleteInfo.loopEach((i, info) => {
        const {
          originChecklistId,
          itemsIds,
          id: checklistId,
          duplicatedChecklistIds,
        } = info;
        let currChecklist = checklists.getNested([checklistId]);

        if (
          originChecklistId !== false ||
          currChecklist.getNested(["contentType"]) === "employeesHealthy"
        )
          return;

        if (!itemsIds.length)
          errors = errors.setNested([checklistId], {
            ...errors.getNested([checklistId], errorsTemplate),
            checklistId,
            isEmpty: true,
          });

        if (!currChecklist.getNested(["locations"], null))
          errors = errors.setNested([checklistId], {
            ...errors.getNested([checklistId], errorsTemplate),
            checklistId,
            missingLocation: true,
          });

        itemsIds.loopEach((i, itemId) => {
          if (!checklistItems.getNested([itemId, "trade"], null))
            errors = errors.setNested([itemId], {
              ...errors.getNested([itemId], errorsTemplate),
              checklistId,
              itemId,
              missingTrade: true,
            });
        });
      });
    }

    return errors;
  }

  formatAndDisplayErrors(errors) {
    const { stages, checklists, checklistItems, startToast, intl } = this.props;
    const {
      isMissingLocation,
      itemIsMissingTrade,
      isEmpty: isEmptyMessage,
    } = systemMessages;

    let formattedErrors = [];
    Object.values(errors).forEach(
      ({ checklistId, itemId, missingLocation, missingTrade, isEmpty }) => {
        let checklist = checklists.getNested([checklistId]);
        let stageName =
          checklist.getNested(["stage"]) ||
          stages.getNested([checklist.getNested(["stageId"])]);
        let checklistName = checklist.getNested(["description"]);
        let itemName = checklistItems.getNested([itemId, "description"], false);
        let errorMessages = [
          missingLocation ? intl.formatMessage(isMissingLocation) : false,
          missingTrade ? intl.formatMessage(itemIsMissingTrade) : false,
          isEmpty ? intl.formatMessage(isEmptyMessage) : false,
        ]
          .filter(Boolean)
          .join(", ");

        let errorLine = `${stageName} > ${checklistName} ${
          itemName ? `> ${itemName}` : ""
        } ${errorMessages}.`;

        formattedErrors.push(errorLine);
      }
    );

    startToast({
      overlay: true,
      mandatory: true,
      message: systemMessages.invalidChangesChecklistManager,
      values: { errors: formattedErrors.join("\n") },
      actions: [{ message: systemMessages.ok, type: "success" }],
    });
  }

  handleConfirmCancel() {
    const { setStages, setChecklists, setChecklistItems, updateLocalChecklists, updateLocalChecklistItems, updateLocalStages } = this.props;
    const { selectedScopeId: scopeId, stagesBackup, checklistsBackup, checklistItemsBackup } = this.state;
    
    this.setAllowEdit(false);

    if (setStages) setStages(stagesBackup, true);
    if (setChecklists) setChecklists(checklistsBackup, true);
    if (setChecklistItems) setChecklistItems(checklistItemsBackup, true);

    updateLocalChecklists(scopeId, checklistsBackup, true);
    updateLocalChecklistItems(scopeId, checklistItemsBackup, true);
    updateLocalStages(scopeId, stagesBackup, true);
  } 
  
  setAllowEdit(bool) {
    this.allowEditIsSet = bool;
    this.setState({ allowToggleEdit: bool });
  }

  setChecklistsMappedByStage(checklistsMappedByStage) {
    this.setState({ checklistsMappedByStage });
  }

  async fetchMergerResult(scopeType, scopeId) {
		const { selectedProjectId, startLoading } = this.props;
		startLoading({ title: systemMessages.loadingMessage, operationId: FETCH_MERGER_OPERATION_ID });

		let requiredScopeId;

		if (scopeType !== 'templates') requiredScopeId = scopeId || getDefaultScopeId(scopeType, selectedProjectId);

		const response = await getMergerResult(['checklists', 'stages', 'checklistItems', "propertiesTypes"], scopeType, requiredScopeId, true);

		await this.setMergerResult(response.mergerResult, scopeType, scopeId);
	}

  convertObjectSourceMap(objectsSources) {
    let res = {};
    
    _.entries(objectsSources || {}).forEach(([key, value]) => !_.isEmpty(key) ? _.set(res, key.split('/'), value) : null);

    return res;
  }

  async setMergerResult(mergerResultMap, scope, scopeId) {
		const { 
      hideLoading,
      updateObjectsSourcesMap,
      updateLocalChecklists,
      updateLocalChecklistItems,
      updateLocalStages,
      changeScopeAndScopeId,
      setStages,
      setChecklists,
      setChecklistItems,
      objectsSourcesMap,
      checklists,
      checklistItems,
      stages,
      propertiesTypes,
      updateLocalProperties,
    } = this.props;

    const {stagesBackup, checklistsBackup, checklistItemsBackup, propertiesTypesBackup } = this.state;
		if (!_.isEmpty(mergerResultMap) && (!_.isEqual(objectsSourcesMap, mergerResultMap.objectsSources))) {
			updateObjectsSourcesMap(this.convertObjectSourceMap(mergerResultMap.objectsSources.stages), 'stages'); 
			updateObjectsSourcesMap(this.convertObjectSourceMap(mergerResultMap.objectsSources.checklists), 'checklists');
			updateObjectsSourcesMap(this.convertObjectSourceMap(mergerResultMap.objectsSources.checklistItems), 'checklistItems');
			delete mergerResultMap.objectsSources;
    }

    if (!_.isEqual(checklists, mergerResultMap.checklists) ||
        !_.isEqual(checklistItems, mergerResultMap.checklistItems) ||
        !_.isEqual(stages, mergerResultMap.stages)) {
			updateLocalChecklists(scopeId, mergerResultMap.checklists, true);
      updateLocalChecklistItems(scopeId, mergerResultMap.checklistItems, true);
      updateLocalStages(scopeId, mergerResultMap.stages, true);

      if (setStages) setStages(mergerResultMap.stages, true);
      if (setChecklists) setChecklists(mergerResultMap.checklists, true);
      if (setChecklistItems) setChecklistItems(mergerResultMap.checklistItems, true);
    }

    if (!_.isEqual(propertiesTypes, mergerResultMap.propertiesTypes)) {
      if (updateLocalProperties) {
        _.forIn(_.get(mergerResultMap, [ "propertiesTypes"], {}), (propObject, subjetType) => {
          const allProps = _.get(propObject, [ 'properties' ], {});

          updateLocalProperties(scopeId, subjetType, allProps);
        });
      };
    }
    
    let newState = {};

    if (scope !== this.state.selectedScope || scopeId !== this.state.selectedScopeId) {
      await changeScopeAndScopeId(scope, scopeId);
      _.set(newState, ["selectedScope"], scope);
      _.set(newState, ["selectedScopeId"], scopeId);
    }

    const newStages = _.get(mergerResultMap, ["stages"], {});
    const newChecklists = _.get(mergerResultMap, ["checklists"], {});
    const newChecklistItems = _.get(mergerResultMap, ["checklistItems"], {});
    const newPropertiesTypes = _.get(mergerResultMap, ["propertiesTypes"], {});

    if (!_.isEmpty(newStages) && !_.isEqual(stagesBackup, newStages)) _.set(newState, ["stagesBackup"], newStages);

    if (!_.isEmpty(newChecklists) && _.isEqual(checklistsBackup, newChecklists)) _.set(newState, ["checklistsBackup"], newChecklists);

    if (!_.isEmpty(newChecklistItems) && _.isEqual(checklistItemsBackup, newChecklistItems)) _.set(newState, ["checklistItemsBackup"], newChecklistItems);

    if (!_.isEmpty(newPropertiesTypes) && _.isEqual(propertiesTypesBackup, newPropertiesTypes)) _.set(newState, ["propertiesTypesBackup"], newPropertiesTypes);

    if (Object.keys(newState).length > 0) {
      this.setState(newState);
    }

		hideLoading(FETCH_MERGER_OPERATION_ID);
	}

  render() {
    const { match, history, setHeaderParams } = this.props;

    return (
      <>
        <ChecklistPage
          match={match}
          history={history}
          setHeaderParams={setHeaderParams}
          onChecklistsCompleteInfoChange={this.getChecklistsCompleteInfo}
          setChecklistsMappedByStage={this.setChecklistsMappedByStage}
          onLocationItemChange={this.selectItemHandle}
          isChecklistManager={true}
          onUpsertObjectClick={this.upsertObject}
        />
      </>
    );
  }
}

CheckListManager = injectIntl(CheckListManager);

const enhance = compose(
  connectContext(ProjectManagerContext.Consumer),
  connect(
    (state) => ({
      stagesLastUpdateTS: state.stages.lastUpdated,
      objectsSourcesMap: state.app.objectsSourcesMap,
      checklistsLastUpdateTS: state.checklists.lastUpdated,
      checklistItemsLastUpdateTS: state.checklistItems.lastUpdated
    }),
    {
      saveChecklists, // stages and checklistItems saved in same action as checklists
      saveProperties,

      updateObjectsSourcesMap,
      updateLocalStages,
      updateLocalChecklists,
      updateLocalChecklistItems,
      updateLocalProperties, 

      getNewStageId,
      getNewChecklistId,
      getNewChecklistItemId,

      getStages,
      getChecklists,
      getChecklistItems,

      startLoading,
      hideLoading,
      startToast,
    }
  )
);

export default enhance(CheckListManager);
