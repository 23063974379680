import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import Select from 'react-select';
import Text from '../../components/CementoComponents/Text';
import Button from '../../components/CementoComponents/Button';
import { DEFAULT_SCOPE_OPTIONS, DEFAULT_SELECTED_SCOPE } from '../../../common/app/constants';
import { safeToJS } from '../../../common/permissions/funcs';
import { withRouter } from "react-router";

const ScopeSelector = props => {
	const {
		scopesTypesOptions = DEFAULT_SCOPE_OPTIONS,
		rtl,
		submitHandler,
		buttonText = 'Select',
		isButtonDisabled,
		selectedScopeId: propsSelectedScopeId,
		selectedScope: propsSelectedScope,
		shouldRedirectAfterSubmit
	} = props;

	const { projects, companies, setsOptions, templatesOptions } = useSelector(state => ({
		projects: state.projects.map,
		companies: state.companies.map,
		setsOptions: state.app.setsOptions,
		templatesOptions: state.app.templatesOptions,
	}));

	const [selectedScope, setSelectedScope] = useState(propsSelectedScope || DEFAULT_SELECTED_SCOPE);
	const [selectedScopeId, setSelectedScopeId] = useState(propsSelectedScopeId);
	const [scopesIdsOptions, setScopesIdsOptions] = useState({});

	const allScopesIdsOptions = useMemo(
		() => ({
			projects: safeToJS(projects),
			companies: safeToJS(companies),
			templates: templatesOptions,
			sets: setsOptions,
		}),
		[projects, companies, setsOptions, templatesOptions],
	);

	const handleScopeTypeChange = useCallback(
		newScopeType => {
			setSelectedScope(newScopeType);
			setSelectedScopeId(null);
		},
		[setSelectedScope, setSelectedScopeId],
	);

	const handleScopeIdChange = useCallback(
		newScopeId => {
			setSelectedScopeId(newScopeId);
		},
		[setSelectedScopeId],
	);

	const submitButtonHandler = useCallback(() => {
		if (submitHandler) submitHandler(selectedScope, selectedScopeId);
		if (shouldRedirectAfterSubmit) {
			if (selectedScope === 'projects') {
				const { match, history } = props;
				const newProps = { ...match.params, selectedProjectId: selectedScopeId };

				let redirectToUrl = match.path;

				_.forIn(newProps, (val, key) => {
					redirectToUrl = redirectToUrl.replace(`:${key}`, val);
				});

				history.push(redirectToUrl);
			}
		}
	}, [submitHandler, selectedScope, selectedScopeId]);

	useEffect(() => {
		let scopeIdsOptions = {};

		let relevantCompaniesMap = {};
		let relevantProjectsMap = {};

		_.values(allScopesIdsOptions.projects).forEach(project => {
			if (!project || project.isDeleted || project.isArchive) return;

			const currentProjectCompanyId = project.companyId;
			const currentProjectId = project.id;
			if (currentProjectCompanyId && !relevantCompaniesMap[currentProjectCompanyId])
				relevantCompaniesMap[currentProjectCompanyId] = {
					value: currentProjectCompanyId,
					label: companies.getNested([currentProjectCompanyId, 'name'], ''),
				};

			relevantProjectsMap[currentProjectId] = {
				value: currentProjectId,
				label: project.title || currentProjectId,
			};
		});

		let templatesOptionsMap = {};
		_.keys(allScopesIdsOptions.templates).forEach(
			option => (templatesOptionsMap[option] = { value: option, label: option }),
		);
		let setsOptionsMap = {};
		_.keys(allScopesIdsOptions.sets).forEach(option => (setsOptionsMap[option] = { value: option, label: option }));

		scopeIdsOptions['projects'] = relevantProjectsMap;
		scopeIdsOptions['companies'] = relevantCompaniesMap;
		scopeIdsOptions['templates'] = templatesOptionsMap;
		scopeIdsOptions['sets'] = setsOptionsMap;

		setScopesIdsOptions(scopeIdsOptions);
		setSelectedScopeId(props.selectedScopeId);
	}, [allScopesIdsOptions]);

	const isTemplatesIsSelected = selectedScope === "templates";

	return (
		<div style={styles.container}>
			<Text>{'Scope: '}</Text>
			<div style={styles.scopeComboboxContainer}>
				<Select
					rtl={rtl}
					onChange={value => handleScopeTypeChange(value.value)}
					options={_.values(scopesTypesOptions)}
					value={scopesTypesOptions[selectedScope]}
				/>
			</div>
			<Text>{'Scope id: '}</Text>
			<div style={styles.scopeIdComboboxContainer}>
				<Select
					rtl={rtl}
					onChange={value => handleScopeIdChange(value.value)}
					options={_.values(_.get(scopesIdsOptions, [selectedScope], {}))}
					value={_.get(scopesIdsOptions, [selectedScope, selectedScopeId])}
					isDisabled={isTemplatesIsSelected}
				/>
			</div>
			<Button title={buttonText} onClick={submitButtonHandler} disabled={isButtonDisabled} />{' '}
		</div>
	);
};

const styles = {
	container: {
		display: 'flex',
		flexDirection: 'row',
		width: 'auto',
		alignItems: 'center',
	},
	scopeComboboxContainer: {
		width: '200px',
		padding: '10px',
	},
	scopeIdComboboxContainer: {
		width: '200px',
		padding: '10px',
	},
};

export default withRouter(ScopeSelector);
