import { defineMessages } from 'react-intl';

export default defineMessages({
  menu: {
    mainTitle: {
      defaultMessage: 'Employees presence on site',
      id: 'siteControl.menu.mainTitle',
    }
  },
  presenceLogs: {
    entrance: {
      defaultMessage: 'Entrance',
      id: 'siteControl.presenceLogs.entrance',
    },
    exit: {
      defaultMessage: 'Exit',
      id: 'siteControl.presenceLogs.exit',
    },
    total: {
      defaultMessage: 'Total',
      id: 'siteControl.presenceLogs.total',
    },
    title: {
      defaultMessage: 'Presence on site',
      id: 'siteControl.presenceLogs.title',
    },
    loadingFailed: {
      defaultMessage: 'Employee presence loading failed',
      id: 'siteControl.presenceLogs.loadingFailed',
    },
    loading: {
      defaultMessage: 'Loading employee presence',
      id: 'siteControl.presenceLogs.loading',
    },
    noData: {
      defaultMessage: 'Presence not found',
      id: 'siteControl.presenceLogs.noData',
    },
    missing: {
      defaultMessage: 'MISSING',
      id: 'siteControl.presenceLogs.missing',
    },
    hours: {
      defaultMessage: 'Hours',
      id: 'siteControl.presenceLogs.hours',
    },
    noEntrance: {
      defaultMessage: 'There was no entrance',
      id: 'siteControl.presenceLogs.noEntrance',
    },
    presenceAt: {
      "defaultMessage": 'Presence at {month}',
      "id": 'siteControl.presenceLogs.presenceAt',
    },
    showPresence: {
      defaultMessage: 'Show presence',
      id: 'siteControl.presenceLogs.showPresence',
    },
    lastUpdated: {
      defaultMessage: 'Last synced {value}',
      id: 'siteControl.presenceLogs.lastUpdated',
    },
    syncNow: {
      defaultMessage: 'Sync now',
      id: 'siteControl.presenceLogs.syncNow',
    },
    presentOnSite: {
      defaultMessage: 'Present on site',
      id: 'siteControl.presenceLogs.presentOnSite',
    }
  },
  table: {
    noCompany: {
      defaultMessage: "No company",
      id: 'siteControl.table.noCompany',
    },
    employeeDetails: {
      defaultMessage: "Employee's details",
      id: 'siteControl.table.employeeDetails',
    },
    presenceDetails: {
      defaultMessage: "Presence in site",
      id: 'siteControl.table.presenceDetails',
    },
    fullName: {
      defaultMessage: 'Name',
      id: 'siteControl.table.fullName',
    },
    company: {
      defaultMessage: 'Company',
      id: 'siteControl.table.company',
    },
    profession: {
      defaultMessage: 'Profession',
      id: 'siteControl.table.profession',
    },
    totalGross: {
      defaultMessage: 'Gross total hours',
      id: 'siteControl.table.totalGross',
    },
    totalNet: {
      defaultMessage: 'Net total hours',
      id: 'siteControl.table.totalNet',
    },
    firstEntrance: {
      defaultMessage: 'First entrance',
      id: 'siteControl.table.firstEntrance',
    },
    lastExit: {
      defaultMessage: 'Last exit',
      id: 'siteControl.table.lastExit',
    },
    syncingCameras: {
      defaultMessage: 'Syncing cameras...',
      id: 'siteControl.table.syncingCameras',
    },
    offlineCameras: {
      defaultMessage: 'Cameras are offline',
      id: 'siteControl.table.offlineCameras',
    },
    syncFailed: {
      defaultMessage: 'Cameras sync failed',
      id: 'siteControl.table.syncFailed',
    },
    syncSuccess: {
      defaultMessage: 'Successfully synced',
      id: 'siteControl.table.syncSuccess',
    },
    noPresence:{
      defaultMessage: 'No presence found on the selected time range',
      id: 'siteControl.table.noPresence',
    }
  },
  noReport: {
    title: {
      defaultMessage: "Welcome to the human resources screen",
      id: "siteControl.noReport.title"
    },
    content: {
      defaultMessage: "In the human resources screen you can monitor employee attendance on site.\nTo start using the human resources screen, please contact Cemento team at info@cemento.ai",
      id: "siteControl.noReport.content"
    }
  },
  general: {
    loadingEmployees: {
      defaultMessage: 'Loading employees presence on site...',
      id: 'siteControl.general.loadingEmployees',
    },
    hr: {
      defaultMessage: 'Human resources',
      id: 'siteControl.general.hr',
    }
  },
  tooltip:{
    presentCount: {
      defaultMessage: '{value} are present on site',
      id: 'siteControl.tooltip.present',
    },
    nonPresentCount: {
      defaultMessage: '{value} was present on site',
      id: 'siteControl.tooltip.nonPresent',
    }

  }
});
