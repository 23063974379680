import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { connectContext } from "react-connect-context";
import { compose, hoistStatics } from "recompose";
import _ from "lodash";

// Components
import Text from "../../components/CementoComponents/Text";
import Modal from "../../components/CementoComponents/Modal";
import SplitViewPage from "../../layouts/SplitViewPage";
import Buildings from "../Locations/Buildings.js";
import AddNewButton from "../../components/CementoComponents/AddNewButton";

// Other
import systemMessages from "../../../common/app/systemMessages";
import projectManagerMessages from "../../../common/app/projectManagerMessages";
import theme from "../../assets/css/theme";
import { ProjectManagerContext } from "../../../common/projects/contexts";

import {
  createLocalProject,
  getLocalProjectDetails,
  enterProject,
  updateLocalProject,
  getNewProjectId,
  PROJECT_TYPE_BUILDING,
  PROJECT_TYPE_COMPLEX_BUILDINGS,
  leaveProject,
} from "../../../common/projects/actions";
import {
  endBuildingsListener,
  updateBuildings,
  removeLocalBuildings,
  getNewBuildingId,
} from "../../../common/buildings/actions";
import {
  endFloorsListener,
  updateFloors,
  removeLocalFloors,
} from "../../../common/floors/actions";
import {
  endUnitsListener,
  updateUnits,
  removeLocalUnits,
} from "../../../common/units/actions";
import sections, {
  importExportField,
} from "./config/createProjectSectionsConfig";
import { Field } from "./config/projectManagerConfig";

// TEMP
import { baseRemoveNested } from "./ProjectManager2_0";
import { navigateToProject } from "../../../common/projects/funcs";
import { saveURLParams, clearAllUrlParams } from "../../../common/ui/actions";
import { startLoading } from "../../../common/app/actions";
import { withRouter } from "react-router";

const MAX_NUM_OF_FLOORS = 500;
const MIN_FLOOR_SELECTION_VALUES = Array.from(new Array(MAX_NUM_OF_FLOORS + 1))
  .map((el, index) => ({
    id: (index + 10 - MAX_NUM_OF_FLOORS).toString(),
    title: (index + 10 - MAX_NUM_OF_FLOORS).toString(),
  }))
  .reverse();
const MAX_FLOOR_SELECTION_VALUES = Array.from(
  new Array(MAX_NUM_OF_FLOORS + 1)
).map((el, index) => ({
  id: (index - 10).toString(),
  title: (index - 10).toString(),
}));
class CreateProject extends React.Component {
  constructor(props) {
    super(props);
    this.handleChangeLocation = this.handleChangeLocation.bind(this);
    this.recalcHeader = this.recalcHeader.bind(this);
    this.handleConfirmSave = this.handleConfirmSave.bind(this);
    this.handleCreateNewProject = this.handleCreateNewProject.bind(this);
    this.cleanUpCrew = this.cleanUpCrew.bind(this);

    this.state = {
      recalculatedSections: _.cloneDeep(sections),
      fieldsBuildingIds: [],
    };
  }

  async handleConfirmSave() {
    const { projectId } = this.state;
    const {
      setProjectToReturnTo,
      selectedProjectId,
      updateProjectFields,
      modifiedValues,
      uploadImgIfNecessaryAndReturnUpdated,
      saveCurrProjectSructure,
    } = this.props;

    setProjectToReturnTo(projectId);

    let modifiedValuesCopy = _.cloneDeep(modifiedValues);

    modifiedValuesCopy = await uploadImgIfNecessaryAndReturnUpdated(
      modifiedValuesCopy,
      {
        pathInObj: ["images/main"],
        get targetFileName() {
          return "project_main/" + selectedProjectId + "/" + Date.now();
        },
        serverFolder: "projects",
      }
    );

    // Handle updates
    if (selectedProjectId) {
      const { newProjectDetails } = modifiedValuesCopy;

      if (newProjectDetails && updateProjectFields) {
        newProjectDetails.id = selectedProjectId;
        updateProjectFields(newProjectDetails, selectedProjectId);
      }

      if (saveCurrProjectSructure) saveCurrProjectSructure();
    }

    navigateToProject(
      this.props,
      selectedProjectId,
      "projectManager/projectProperties"
    );
  }

  cleanUpCrew() {
    const {
      selectedProjectId,
      projectToReturnTo,
      removeLocalBuildings,
      removeLocalFloors,
      removeLocalUnits,
    } = this.props;

    if (removeLocalBuildings) removeLocalBuildings(selectedProjectId);
    if (removeLocalFloors) removeLocalFloors(selectedProjectId);
    if (removeLocalUnits) removeLocalUnits(selectedProjectId);

    navigateToProject(
      this.props,
      projectToReturnTo,
      "projectManager/createProject"
    );
  }

  async handleCreateNewProject() {
    const {
      createLocalProject,
      viewer,
      enterProject,
      getNewProjectId,
      selectedProjectId,
      setProjectToReturnTo,
    } = this.props;

    const { projectId } = await getNewProjectId();

    let newProjectDetails = { id: projectId };

    setProjectToReturnTo(selectedProjectId);
    this.setState({ projectId });

    if (createLocalProject) createLocalProject(viewer, newProjectDetails);
    if (enterProject) enterProject(projectId);
  }

  componentDidMount() {
    const { setIsCreateProjectPage } = this.props;
    if (setIsCreateProjectPage) setIsCreateProjectPage(true);
    this.recalcHeader();
  }

  componentDidUpdate(prevProps) {
    const { isEditMode } = this.props;
    if (isEditMode != prevProps.isEditMode) this.recalcHeader();
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextState, nextContext) {
    const { recalculatedSections, fieldsBuildingIds } = this.state;
    const {
      setBuildingType,
      projectStructure,
      importedFile,
      handleInputChange,
      followStructure,
    } = this.props;
    let newStateChanges = {};
    let nextNumBuildingsSelected = _.get(nextProps, [
      "projectStructure",
      "numOfBuildings",
    ]);

    const nextProjectStructure = _.get(nextProps, ["projectStructure"], {});
    const buildingStructureChanged = !_.isEqual(
      projectStructure.buildings || {},
      nextProjectStructure.buildings || {}
    );
    if (
      buildingStructureChanged &&
      !followStructure &&
      Object.values(nextProjectStructure.buildings || {}).length
    ) {
      handleInputChange(
        ["projectStructure", "numOfBuildings"],
        Object.values(nextProjectStructure.buildings || {}).length
      );
      return;
    }

    let showSection1Conditions = Boolean(
      nextProps.getNested(
        ["modifiedValues", "newProjectDetails", "title"],
        false
      )
    );
    let prevShowSection1Conditions = Boolean(
      this.props.getNested(
        ["modifiedValues", "newProjectDetails", "title"],
        false
      )
    );
    if (
      (showSection1Conditions &&
        prevShowSection1Conditions !== showSection1Conditions) ||
      (nextNumBuildingsSelected &&
        nextNumBuildingsSelected !== projectStructure.numOfBuildings)
    ) {
      if (nextNumBuildingsSelected === 1 && setBuildingType) setBuildingType(3);
      if (nextNumBuildingsSelected > 1 && setBuildingType) setBuildingType(4);
      const { recalculatedSections, newFieldsBuildingIds } =
        this.recalcNewProjectStructureSectionFields(nextProps);
      newStateChanges.recalculatedSections = recalculatedSections;
      newStateChanges.fieldsBuildingIds = newFieldsBuildingIds;
    }

    // Show sections based on input
    if (
      showSection1Conditions !==
      (newStateChanges.recalculatedSections || recalculatedSections)[1].show
    ) {
      if (newStateChanges.recalculatedSections)
        newStateChanges.recalculatedSections[1].show = showSection1Conditions;
      else
        this.setState(({ recalculatedSections }) => {
          recalculatedSections[1].show = showSection1Conditions;
          return { recalculatedSections };
        });
    }

    let showSection2Conditions = Boolean(
      nextNumBuildingsSelected &&
        Object.entries(
          nextProps.getNested(["modifiedValues", "projectStructure"], {})
        ).length -
          1 ===
          nextNumBuildingsSelected * 3
    );
    if (showSection2Conditions !== recalculatedSections[2].show) {
      if (newStateChanges.recalculatedSections)
        newStateChanges.recalculatedSections[2].show = showSection2Conditions;
      else
        this.setState(({ recalculatedSections }) => {
          recalculatedSections[2].show = showSection2Conditions;
          return { recalculatedSections };
        });
    }

    if (Object.values(newStateChanges).length) this.setState(newStateChanges);
  }

  recalcNewProjectStructureSectionFields(nextProps) {
    const { intl } = this.props;
    const { recalculatedSections, fieldsBuildingIds } = this.state;
    const { buildingName, buildingGroundFloor, buildingTopFloor } =
      projectManagerMessages.inputLabels;

    let newFieldsBuildingIds = this.getNewFieldsBuildingIds(
      fieldsBuildingIds,
      nextProps.projectStructure,
      Boolean(
        nextProps.structureWasImportedAtLeastOnce && !nextProps.followStructure
      )
    );

    if (nextProps.structureWasImportedAtLeastOnce && !nextProps.followStructure)
      recalculatedSections[1].fields[0].props.editable = false;

    const { smFieldStyle } = styles;
    let newFieldsArray = [recalculatedSections[1].fields[0]];
    let pathsToValue = newFieldsBuildingIds.map((buildingId) => [
      "projectStructure",
      "buildings",
      buildingId,
    ]);
    pathsToValue.forEach((pathToValue, i) => {
      i = i + 1;
      newFieldsArray.push(
        new Field(
          `${intl.formatMessage(buildingName)} ${i}`,
          "String",
          [...pathToValue, "title"],
          null,
          {
            mode: { labelStyle: { marginBottom: "11.3px" } },
            style: { flexBasis: "50%" },
            prop: { mandatory: true },
            tooltip: {
              title: "Building name MUST match the sheet name in Excel",
            },
          }
        ),

        new Field(
          `${intl.formatMessage(buildingGroundFloor)}`,
          "SelectionList",
          [...pathToValue, "minFloor"],
          MIN_FLOOR_SELECTION_VALUES,
          {
            autoSort: false,
            style: smFieldStyle,
            prop: { mandatory: true },
          }
        ),

        new Field(
          `${intl.formatMessage(buildingTopFloor)}`,
          "SelectionList",
          [...pathToValue, "maxFloor"],
          MAX_FLOOR_SELECTION_VALUES,
          {
            autoSort: false,
            style: smFieldStyle,
            prop: { mandatory: true },
          }
        )
      );
    });

    if (!nextProps.followStructure) {
      let importStructure = importExportField;
      importStructure.name =
        "Or import the structure from excel, its like magic!";
      newFieldsArray.push(importStructure);
    }

    recalculatedSections[1].fields = newFieldsArray;

    return { recalculatedSections, newFieldsBuildingIds: newFieldsBuildingIds };
  }

  getNewFieldsBuildingIds(
    existingArrayOfIds,
    projectStructure,
    autoImportMode
  ) {
    const { getNewBuildingId, selectedProjectId } = this.props;
    const { numOfBuildings, buildings = {} } = projectStructure;

    let newFieldsBuildingIds = [];
    let actualNumOfBuildings = Object.keys(buildings).length;
    if (
      (actualNumOfBuildings && numOfBuildings === actualNumOfBuildings) ||
      autoImportMode
    ) {
      const { buildings = {} } = projectStructure;
      Object.keys(buildings).forEach((buildingId) =>
        newFieldsBuildingIds.push(buildingId)
      );
    } else {
      for (let i = 0; i < numOfBuildings; i++) {
        if (existingArrayOfIds[i])
          newFieldsBuildingIds.push(existingArrayOfIds[i]);
        else {
          const { buildingId } = getNewBuildingId(selectedProjectId).payload;
          newFieldsBuildingIds.push(buildingId);
        }
      }
    }

    return newFieldsBuildingIds;
  }

  recalcHeader() {
    const { recalcHeaderWithOptions, rtl } = this.props;

    const editOffComp = (
      <AddNewButton
        title={projectManagerMessages.createNewProject}
        rtl={rtl}
        propagateClick={true}
      />
    );

    if (recalcHeaderWithOptions) {
      let options = {
        confirmSaveFunc: this.handleConfirmSave,
        confirmCancelFunc: this.cleanUpCrew,
        editOffComp,
        editOffFunc: this.handleCreateNewProject,
        shouldHideEditButton: true
      };

      recalcHeaderWithOptions(options);
    }
  }

  handleChangeLocation(locationsIds) {
    const { setBuildingOverview } = this.props;

    if (setBuildingOverview) setBuildingOverview(locationsIds);
  }

  componentWillUnmount() {
    const { enterProject, projectToReturnTo, setIsCreateProjectPage } =
      this.props;

    if (setIsCreateProjectPage) setIsCreateProjectPage(false);
    // if (enterProject) enterProject(projectToReturnTo);
  }

  render() {
    const {
      isEditMode,
      calcSection,
      buildingsOverview,
      importedFile,
      resetImportedFile,
      wrapperStyles,
      isLoading,
    } = this.props;
    const { recalculatedSections } = this.state;
    const { button } = styles;

    return (
      <div style={wrapperStyles}>
        {Boolean(importedFile && !isLoading) && (
          <Modal open={true}>
            <div style={{ height: "75vh", width: "35vh" }}>
              <Buildings
                showBadges={false}
                style={{
                  marginTop: 0,
                  visibility: "visible",
                  width: "100%",
                  height: "inherit",
                  backgroundColor: theme.backgroundColor,
                }}
                maxUnitsInFloor={4}
                selectedLocation={buildingsOverview}
                onClick={this.handleChangeLocation}
              />
            </div>
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <div style={button} onClick={() => resetImportedFile(true)}>
                <Text>{systemMessages.confirm}</Text>
              </div>
              <div style={button} onClick={() => resetImportedFile()}>
                <Text>{systemMessages.cancel}</Text>
              </div>
            </div>
          </Modal>
        )}

        {isEditMode && recalculatedSections.map(calcSection)}
      </div>
    );
  }
}

const styles = {
  button: {
    textAlign: "center",
    width: 90,
    cursor: "pointer",
    margin: 5,
    border: "1px solid #00000010",
    backgroundColor: theme.backgroundColorBright,
    color: theme.brandPrimary,
    padding: "5px 15px",
    borderRadius: 25,
    boxShadow: "#00000015 0px 0px 10px 0px",
  },
  smFieldStyle: {
    minWidth: "25%",
  },
};

CreateProject = withRouter(injectIntl(CreateProject));

const enhance = compose(
  connectContext(ProjectManagerContext.Consumer),
  connect(
    (state) => ({
      viewer: state.users.viewer,
      isLoading: state.app.loading,
    }),
    {
      createLocalProject,
      updateLocalProject,
      getLocalProjectDetails,
      getNewProjectId,
      enterProject,
      leaveProject,
      getNewBuildingId,

      updateBuildings,
      removeLocalBuildings,

      updateFloors,
      removeLocalFloors,

      updateUnits,
      removeLocalUnits,

      saveURLParams,
      startLoading,
      clearAllUrlParams,
    }
  )
);
export default enhance(CreateProject);
