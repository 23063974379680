export const GET_EVENTS_COUNTERS = 'GET_EVENTS_COUNTERS';
export const END_EVENTS_LISTENER = 'END_EVENTS_LISTENER';
export const INIT_PROJECT_EVENTS_COUNTERS = 'INIT_PROJECT_EVENTS_COUNTERS';
export const INIT_POST_EVENTS_COUNTERS = 'INIT_POST_EVENTS_COUNTERS';
export const GET_PROJECT_POSTS_EVENTS_COUNTERS = 'GET_PROJECT_POSTS_EVENTS_COUNTERS';

// export function getUserEventsCounters(user) {  
//     return ({ dispatch, firebaseDatabase, bugsnag }) => {
//       const getPromise = async () => {
//       var payload;
//       var uid = user.id;
//       firebaseDatabase().ref('eventsCounters/' + uid + '/counter').once('value').then(function(snapshot) {
//         var eventsCounters = snapshot.val();
//         dispatch({ type: GET_EVENTS_COUNTERS, payload: {eventsCounters, uid }});
//       });       
//     }

//     return {
//       type: GET_EVENTS_COUNTERS,
//       payload: getPromise()
//     };
//   };
// }
 
// export function getUserProjectPostsEventsCounters(user, projectId) {  
//     return ({ dispatch, firebaseDatabase, bugsnag }) => {
//       const getPromise = async () => {
//       var payload;
//       var uid = user.id;
//       firebaseDatabase().ref('eventsCounters/' + uid + '/projects/' + projectId).on('value', function(snapshot) {
//         var eventsCounters = snapshot.val();
//         dispatch({ type: GET_PROJECT_POSTS_EVENTS_COUNTERS, payload: {projectId, eventsCounters, uid }});
//       });       
//     }

//     return {
//       type: GET_EVENTS_COUNTERS,
//       payload: getPromise()
//     };
//   };
// }

export function initUserEventsCounter(user) {  
    return ({ firebaseDatabase, firebase }) => {
      const getPromise = async () => {
      var uid = user.id;
      // firebaseDatabase().ref('eventsCounters/' + uid + '/counter').remove();
      
      // let updates = {};
      // updates['eventsCounters/' + uid + '/lastSeen'] = new Date().getTime();
      // firebase.update(updates)
    }

    return {
      type: INIT_PROJECT_EVENTS_COUNTERS,
      payload: getPromise()
    };
  };
}

export function initProjectEventsCounter(projectId, user) {  
    return ({ firebaseDatabase, firebase, bugsnag }) => {
      const getPromise = async () => {
      if (!user || user.isAdmin)
        return;

      var payload;
      var uid = user.id;
      // firebaseDatabase().ref('users-projects/' + uid + '/' + projectId + '/counter').remove();
      // firebaseDatabase().ref('users-projects/' + uid + '/' + projectId + '/fue') = new Date().getTime(); // fue - First Unseen Event

      var newFue = new Date().getTime();
      let updates = {};
      // updates['users-projects/' + uid + '/' + projectId + '/counter'] = null;
      // updates['users-projects/' + uid + '/' + projectId + '/fue'] = newFue;
      
      // await firebase.update(updates)
      return { newFue, projectId }
    }

    return {
      type: INIT_PROJECT_EVENTS_COUNTERS,
      payload: getPromise()
    };
  };
}

// export function initPostEventsCounter(projectId, postId, user) {  
//     return ({ dispatch, firebaseDatabase, firebase, bugsnag }) => {
//       const getPromise = async () => {
//       var payload;
//       var uid = user.id;
//       firebaseDatabase().ref('eventsCounters/' + uid + '/projects/' + projectId + '/posts/' + postId).remove();
//     }
//     return {
//       type: INIT_POST_EVENTS_COUNTERS,
//       payload: getPromise()
//     };
//   };
// }


// export function getProjectEvents(uid, projectId) {	
// 	  return ({ dispatch, firebaseDatabase, bugsnag }) => {
// 	  	const getPromise = async () => {
// 		  	var payload;
// 			  firebaseDatabase().ref('events/' + uid + '/' + projectId).on('value', function(snapshot) {
//           var events = {};
//           events[projectId] = snapshot.val();
//           dispatch({ type: GET_EVENTS, payload: {events, uid }});
// 				});	
// 		}

//     return {
//       type: GET_EVENTS,
//       payload: getPromise()
//     };
//   };
// }

// export function endProjectEventsListener(uid, projectId) {	
//   return ({ dispatch, firebaseDatabase }) => {
// 	  firebaseDatabase().ref('events/' + uid + '/' + projectId).off('value');
//     return {
//       type: END_EVENTS_LISTENER,
//       payload: { projectId }
//     };
//   };
// }







// firebaseDatabase().ref('eventsCounters/' + uid).child_changed('value', function(snapshot) {
//           var eventsCounters = snapshot.val();
//           dispatch({ type: EVENTS_COUNTERS_CHANGED, payload: {eventsCounters, uid }});
//         });    
//         firebaseDatabase().ref('eventsCounters/' + uid).child_changed('value', function(snapshot) {
//           var eventsCounters = snapshot.val();
//           dispatch({ type: EVENTS_COUNTERS_ADDED, payload: {eventsCounters, uid }});
//         });        
//         firebaseDatabase().ref('eventsCounters/' + uid).child_removed ('value', function(snapshot) {
//           var eventsCounters = snapshot.val();
//           dispatch({ type: EVENTS_COUNTERS_REMOVED, payload: {eventsCounters, uid }});
//         });     