import { updateConfigurations } from "../configurations/actions";
import { getDispatch } from "../configureMiddleware";

export const migrateDrawingsToV2 = async (projectId) => {
  const dispatch = getDispatch();
  const newConfigurations = {
    [`features/drawings/v2/isActive`]: true,
    [`clientMS/bulk/drawings`]: true,
    [`clientMS/V2/bulk/drawings/isActive`]: true,
  };
  const payload = await dispatch(updateConfigurations(newConfigurations, projectId, 'projects'));
  return payload;
};
