import * as actions from './actions';
import * as appActions from '../app/actions';
import * as companiesTypes from './companiesTypes';
import { REPLACE_USER } from '../auth/actions';

import Company from './company';
import { Map, OrderedMap } from 'immutable';
import { Record } from '../transit';

const InitialState = Record({
  map: Map(),
  didLoad: OrderedMap(),
  //inProcessMap: Map(),
  lastClientUpdatePerProject: Map(),
}, 'companies');

const initialState = new InitialState;

export default function companiesReducer(state = new InitialState, action) {
  switch (action.type) {

    case actions.CREATE_NEW_COMPANY:
    case actions.GET_COMPANY: {
      if (!action.payload || !action.payload.company)
        return state;

      const company = new Company({...action.payload.company});
      
      return state.setIn(['map', company.id], company);
    }

    case actions.GET_COMPANIES: {
      
      if (!action.payload)
        return state;
        
      const { companies, projectId } = action.payload;
      if (!companies)
        return state;

      let maxUpdateTS = 0;
      companies.loopEach((id, curComp) => {
        const company = new Company({...curComp});
        
        let currCompUpdatedTS = null;
        if (projectId === 'global')
          currCompUpdatedTS = company.getNested(['updatedTS']);
        else
          currCompUpdatedTS = company.getNested(['projects', projectId, 'updatedTS']);

        if (maxUpdateTS < currCompUpdatedTS)
          maxUpdateTS = currCompUpdatedTS;

        if (curComp.isDeleted)
          state = state.deleteIn(['map', company.id]);
        else 
          state = state.setIn(['map', company.id], company);
      });
      
      if (projectId && maxUpdateTS)
        state = state.setIn(['lastClientUpdatePerProject', projectId], maxUpdateTS);

      state = state.setIn(['didLoad', projectId], true);
      return state;
    }

    // case actions.GET_COMPANIES + '_ERROR':
    // case actions.GET_COMPANIES + '_START': {
    //   if (!action.payload.companyId)
    //     return state;

    //   const { companyId } = action.payload;
    //   state = state.setIn(['inProcessMap', companyId], true);
    //   return state;
    // }

    case actions.CLEAN_CACHED_COMPANIES: {
      return initialState;
    }

    case actions.END_COMPANIES_LISTENER + "_SUCCESS": {
      if (!action.payload)
        return state;
        
      const { projectId } = action.payload;

      state = state.setIn(['didLoad', projectId], false);
      return state;
    }

    case REPLACE_USER + "_SUCCESS": {
      return initialState;
    }
  }
  
  return state;
}
