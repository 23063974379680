import { Record } from '../transit';
import * as actions from './actions';
import * as usersActions from '../users/actions';
import { firebaseActions } from '../lib/redux-firebase';

export const VERIFICATION_SMS_REQUEST = 'VERIFICATION_SMS_REQUEST';
export const VERIFICATION_SMS_VERIFIED = 'VERIFICATION_SMS_VERIFIED';
export const USER_LOADED = 'USER_LOADED';
export const FIREBASE_AUTH = 'FIREBASE_AUTH';
export const SMS_VERIFICATION_REQUEST_RECIVED = 'SMS_VERIFICATION_REQUEST_RECIVED';
export const SMS_VERIFICATION_VERIFIED_ERROR = 'SMS_VERIFICATION_VERIFIED_ERROR';
export const SMS_VERIFICATION_VERIFIED = 'SMS_VERIFICATION_VERIFIED';

const InitialState = Record({
  formDisabled: false,
  error: null,
  success: null, // To get accessToken, refreshToken, whatever.
  authToken: null, // Auth0 token
  accessToken: null, // used to access api-server
  sms: null, 
  status: null,
  userId: null,
  loggedIn: null,
  inviterUid: null,
  number: null,
  firebaseToken: null,
  refreshToken: null,
  fCMRegistrationToken: null, // ***** FROM HERE ARE THE NEW PARAMS
  userStatus: null
}, 'auth');

export default function authReducer(state = new InitialState, action) {
  switch (action.type) {
    case actions.SMS_VERIFICATION_REQUEST_SENT + '_SUCCESS': {
      const { number } = action.payload;
      return state = state.set('sms', { number });
    }

    case actions.SMS_VERIFICATION_REQUEST_RECIVED: {
      const { number, userId } = action.payload;
      state = state.set('status', SMS_VERIFICATION_REQUEST_RECIVED);
      state = state.set('number', number);
      state = state.set('userId', userId);
      return state.set('sms', { number, userId });
    }

    case actions.LOAD_AUTH0_USER_DATA + "_SUCCESS":
    case actions.SMS_VERIFICATION_VERIFIED: {
      if (!action.payload || !action.payload.number)
        return state;
      
      const { number, userId, id_token, access_token, refreshToken } = action.payload;
      state = state.set('status', SMS_VERIFICATION_VERIFIED);
      state = state.set('authToken', id_token);
      state = state.set('accessToken', access_token);
      //state = state.set('userId', userId);
      state = state.set('number', number);
      state = state.set('refreshToken', refreshToken)
      //state = state.set('loggedIn', true);
      return state.set('sms', { number, userId, id_token });

    }

    case actions.GET_AUTH0_TOKEN + "_SUCCESS": {
      if (!action.payload || !action.payload.saveNewToken)
        return state;

      const { id_token,access_token, refreshToken, userId, number } = action.payload;
      if (userId)
        state = state.set('userId', userId);

      if (access_token)
        state = state.set('accessToken', access_token);

      if (number)
        state = state.set('number', number);
      if (refreshToken)
        state = state.set('refreshToken', refreshToken)

      return state.set('authToken', id_token)
    }

    case actions.SMS_VERIFICATION_VERIFIED_ERROR: {
      return state.set('status', SMS_VERIFICATION_VERIFIED_ERROR);
    }

    case actions.RESEND_SMS: {
      state = state.set('status', null);
      return state.set('sms', null);
    }

    case usersActions.GET_MY_USER_DETAILS:{
      const { userPhoneNumber } = action.payload;
      if (!userPhoneNumber)
        return state;

      return state.set('number', userPhoneNumber);
    }

    case firebaseActions.FIREBASE_ON_AUTH: {
      state = state.set('status', FIREBASE_AUTH); // TODO: Remove once web login is working with the new process
      return state.set('loggedIn', true);
    }

    case firebaseActions.FIREBASE_NO_USER_DATA: {
      //console.log('FIREBASE_NO_USER_DATA - loggedIn, before', state.loggedIn, 'after', false);
      return state.set('loggedIn', false);
    }

    case actions.SIGN_OUT + '_START': {
      state = state.set('loggedIn', false);
      state = state.set('number', null)
      state = state.set('accessToken', null);
      return state.set('authToken', null)

    }

    case firebaseActions.DELEGATION_TOKEN_RECIVED: {
      const { token } = action.payload;
     return state.set('firebaseToken', { token }); 
    }

    case firebaseActions.FCM_TOKEN_SAVED + "_SUCCESS": {
      const { registrationToken } = action.payload;
      if (!registrationToken)
        return state;
      return state.set('fCMRegistrationToken', registrationToken);
    }
  }

  return state;
}
