import { defineMessages } from 'react-intl';

export default defineMessages({
	createNewProject: {
		defaultMessage: 'Create new project',
		id: 'projectManager.createNewProject',
	},
	sideNav: {
		settings: {
			defaultMessage: 'Settings',
			id: 'projectManager.sideNav.settings',
		},
		projectProperties: {
			defaultMessage: 'Project properties',
			id: 'projectManager.sideNav.projectProperties',
		},
		authorizations: {
			defaultMessage: 'Authorizations',
			id: 'projectManager.sideNav.authorizations',
		},
		checklistManagement: {
			defaultMessage: 'Checklist management',
			id: 'projectManager.sideNav.cheklistManagement',
		},
		projectStructure: {
			defaultMessage: 'Project structure',
			id: 'projectManager.sideNav.projectStructure',
		},
		createProject: {
			defaultMessage: 'Create project',
			id: 'projectManager.sideNav.createProject',
		},
		locationsGroupsManagement: {
			defaultMessage: "Groups management",
			id: "projectManager.sideNav.locationsGroupsManagement"
		},
	},
	sections: {
		projectDetails: {
			defaultMessage: 'Project details',
			id: 'projectManager.sections.projectDetails',
		},
		displaySettings: {
			defaultMessage: 'Display settings',
			id: 'projectManager.sections.displaySettings',
		},
		units: {
			defaultMessage: 'Units',
			id: 'projectManager.sections.units',
		},
		featuresConfigurations: {
			defaultMessage: 'Features configurations',
			id: 'projectManager.sections.featuresConfigurations',
		},
		clientServerConnectivity: {
			listeners: {
				defaultMessage: 'Client server connectivity - Listeners',
				id: 'projectManager.sections.clientServerConnectivity.listeners',
			},
			bulk: {
				defaultMessage: 'Client server connectivity - Bulk fetch',
				id: 'projectManager.sections.clientServerConnectivity.bulk',
			},
		},
	},
	inputLabels: {
		projectCustomer: {
			defaultMessage: 'Customer',
			id: 'projectManager.inputLabels.projectCustomer',
		},
		projectRegion: {
			defaultMessage: 'Region',
			id: 'projectManager.inputLabels.projectRegion',
		},
		formsManager: {
			defaultMessage: 'Forms manager',
			id: 'projectManager.inputLabels.formsManager',
		},
		changesHaveNotBeenSaved: {
			defaultMessage: 'Changes have not been saved, are you sure you wish to continue?',
			id: 'projectManager.inputLabels.changesHaveNotBeenSaved',
		},
		leaveWithoutSave: {
			defaultMessage: 'Leave without save',
			id: 'projectManager.inputLabels.leaveWithoutSave',
		},
		checklistsManager: {
			defaultMessage: 'Checklists Manager',
			id: 'projectManager.inputLabels.checklistsManager',
		},
		propertiesManager: {
			defaultMessage: 'Properties Manager',
			id: 'projectManager.inputLabels.propertiesManager',
		},
		excelImport: {
			defaultMessage: 'Import from Excel',
			id: 'projectManager.inputLabels.import',
		},
		excelExport: {
			defaultMessage: 'Export to Excel',
			id: 'projectManager.inputLabels.export',
		},
		load: {
			defaultMessage: 'Load project data...',
			id: 'projectManager.inputLabels.load',
		},
		companiesTypes: {
			defaultMessage: 'Companies - Types in project',
			id: 'projectManager.inputLabels.companiesTypes',
		},
		companiesPermissions: {
			defaultMessage: 'Companies - Permissions',
			id: 'projectManager.inputLabels.companiesPermissions',
		},
		checklistsInspector: {
			defaultMessage: 'Checklsits - Inspector',
			id: 'projectManager.inputLabels.checklistsInspector',
		},
		checklistsVerifier: {
			defaultMessage: 'Checklsits - Verifier',
			id: 'projectManager.inputLabels.checklistsVerifier',
		},
		checklistsPartial: {
			defaultMessage: 'Checklsits - Partial',
			id: 'projectManager.inputLabels.checklistsPartial',
		},
		checklistsIrrelevant: {
			defaultMessage: 'Checklsits - Irrelevant',
			id: 'projectManager.inputLabels.checklistsIrrelevant',
		},
		propertiesGroups: {
			defaultMessage: 'Properties - Groups settings',
			id: 'projectManager.inputLabels.propertiesGroups',
		},
		projectMembers: {
			defaultMessage: 'Project members',
			id: 'projectManager.inputLabels.projectMembers',
		},
		projectSettings: {
			defaultMessage: 'Project settings',
			id: 'projectManager.inputLabels.projectSettings',
		},
		projectTimeZone: {
			defaultMessage: 'Time zone',
			id: 'projectManager.inputLabels.projectTimeZone',
		},
		projectLanguage: {
			defaultMessage: 'Language',
			id: 'projectManager.inputLabels.projectLanguage',
		},
		projectName: {
			defaultMessage: 'Project name',
			id: 'projectManager.inputLabels.projectName'
		},
		companyName: {
			defaultMessage: 'Company name',
			id: 'projectManager.inputLabels.companyName',
		},
		projectCreationTs: {
			defaultMessage: 'Project creation time',
			id: 'projectManager.inputLabels.projectCreationTs',
		},
		projectStartingTs: {
			defaultMessage: 'Project starting time',
			id: 'projectManager.inputLabels.projectStartingTs',
		},
		showOnlyWhenAdmin: {
			defaultMessage: 'Show on admin',
			id: 'projectManager.inputLabels.showOnlyWhenAdmin',
		},
		archiveProject: {
			defaultMessage: 'Set project as archived',
			id: 'projectManager.inputLabels.archiveProject',
		},
		includeInQACompanyViewLabel: {
			defaultMessage: 'Include project in quality company view',
			id: 'projectManager.inputLabels.includeInQACompanyView',
		},
		includeInSafetyCompanyViewLabel: {
			defaultMessage: 'Include project in safety company view ',
			id: 'projectManager.inputLabels.includeInSafetyCompanyView',
		},
		showFloorInReverseOrder: {
			defaultMessage: 'Show floor in reverse order (Ground floor are on top)',
			id: 'projectManager.inputLabels.showFloorInReverseOrder',
		},
		showSafetyTab: {
			defaultMessage: 'Enable safety',
			id: 'projectManager.inputLabels.showSafetyTab',
		},
		showQualityTab: {
			defaultMessage: 'Enable quality',
			id: 'projectManager.inputLabels.showQualityTab',
		},
		enableGeneralReportLabel: {
			defaultMessage: 'Enable general report',
			id: 'projectManager.inputLabels.enableGeneralReport',
		},
		activateDrawings: {
			defaultMessage: 'Activate drawings', 
			id: 'projectManager.inputLabels.activateDrawings'
		},
		showDrawingsTab: {
			defaultMessage: 'Show tab for drawings',
			id: 'projectManager.inputLabels.showDrawingTab',
		},
		isDrawingsInV2: {
			defaultMessage: 'Is Drawings in V2',
			id: 'projectManager.inputLabels.isDrawingsInV2',
		},
		showDailyReportTab: {
			defaultMessage: 'Enable daily report',
			id: 'projectManager.inputLabels.showDailyReportTab',
		},
		showSpecTab: {
			defaultMessage: 'Enable specifications',
			id: 'projectManager.inputLabels.showSpecTab',
		},
		projectImage: {
			defaultMessage: 'Project image',
			id: 'projectManager.inputLabels.projectImage',
		},
		companyLogo: {
			defaultMessage: 'Company logo',
			id: 'projectManager.inputLabels.companyLogo',
		},
		customerName: {
			defaultMessage: 'Customer name',
			id: 'projectManager.inputLabels.customerName',
		},
		delete: {
			defaultMessage: 'Delete',
			id: 'projectManager.inputLabels.delete',
		},
		duplicate: {
			defaultMessage: 'Duplicate',
			id: 'projectManager.inputLabels.duplicate',
		},
		saving: {
			defaultMessage: 'Saving changes...',
			id: 'projectManager.inputLabels.saving',
		},
		importMode: {
			defaultMessage: 'Import mode',
			id: 'projectManager.inputLabels.importMode',
		},
		parentsMode: {
			defaultMessage: 'Edit categories',
			id: 'projectManager.inputLabels.parentsMode',
		},
		childsMode: {
			defaultMessage: 'Edit items',
			id: 'projectManager.inputLabels.childsMode',
		},
		addLocationTitle: {
			defaultMessage: 'Add\\Remove location',
			id: 'projectManager.inputLabels.addLocationTitle',
		},
		addLocationMessage: {
			defaultMessage: 'Do you wish to apply changes to all checklist items?',
			id: 'projectManager.inputLabels.addLocationMessage',
		},
		hideChecklistTitleOnIssueDesc: {
			defaultMessage: 'Hide checklist title on records descriptions',
			id: 'projectManager.inputLabels.hideChecklistTitleOnIssueDesc',
		},
		enableDistributionList: {
			defaultMessage: 'Enable distribution list',
			id: 'projectManager.inputLabels.enableDistributionList',
		},
		projectAddress: {
			defaultMessage: 'Project address',
			id: 'projectManager.inputLabels.projectAddress',
		},
		projectCity: {
			defaultMessage: 'Project city',
			id: 'projectManager.inputLabels.projectCity',
		},
		projectCountry: {
			defaultMessage: 'Country',
			id: 'projectManager.inputLabels.projectCountry',
		},
		numberOfBuildings: {
			defaultMessage: 'Number of buildings',
			id: 'projectManager.inputLabels.numberOfBuildings',
		},
		buildingName: {
			defaultMessage: 'Name of Building No.',
			id: 'projectManager.inputLabels.buildingName',
		},
		buildingGroundFloor: {
			defaultMessage: 'Lowest floor',
			id: 'projectManager.inputLabels.buildingGroundFloor',
		},
		buildingTopFloor: {
			defaultMessage: 'Highest floor',
			id: 'projectManager.inputLabels.buildingTopFloor',
		},
		floorName: {
			defaultMessage: 'Floor name',
			id: 'projectManager.inputLabels.floorName',
		},
		unitName: {
			defaultMessage: 'Unit name',
			id: 'projectManager.inputLabels.unitName',
		},
	},
	groupUpdated: {
		defaultMessage: 'Group updated successfully',
		id: 'projectManager.edit.groupUpdated'
	},
	groupDeleted: {
		defaultMessage: 'Group deleted successfully',
		id: 'projectManager.edit.groupDeleted'
	},
});
