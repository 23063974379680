import { onError } from "../app/funcs";
import { envParams } from "../configureMiddleware";
import { platformActions } from "../platformActions";

export async function getCompanyEmployees(companyId) {
    const firebase = platformActions.firebase.getFirebase();
    return (await firebase.database().ref(`employees/companies/${companyId}`).once('value')).val() || {};
}

/**
 * @param {string} projectId
 * @param {string} employeeIdNumber - ID number of the employee
 * @param {string} employeeNationality - Selection list value from pinchas clali nationality prop (the key of the data object)
 * @returns {Promise<{} | { properties: Object<string, any>, projects: { [projectId: string]: { id: string } } }>}
*/
export const getExistingEmployee = async (projectId, employeeIdNumber, employeeNationality) => {
  const { apiServer } = envParams;
  
  let ret = {};
  try {
      let resp = await platformActions.net.fetch(apiServer + '/v1/alternative/employees/isExist', {
      method: 'POST',
      body: JSON.stringify({ projectId, idNumber: employeeIdNumber, nationality: employeeNationality })
    });

    if (resp && resp.getJson)
      ret = resp.getJson();
  }
  catch (error) {
    onError({
      errorMessage: 'error while fetching from api server /v1/alternative/employees/isExist',
      error,
      methodMetaData: {
        name: 'getExistingEmployee',
        args: { projectId, employeeIdNumber, employeeNationality },
      },
    });

    throw error;
  }
  
  return ret;
}