export const lastUpdateTSSchema = {
  name: 'lastUpdateTS',
  primaryKey: 'id',
  schemaVersion: 3,
  properties: {
    id: 'string',
    type: 'string',
    projectId: 'string',
    lastUpdateTS: { type: 'int', default: 0 },
  }
};

export const employeeSchema = {
  name: 'employee1',
  primaryKey: 'id',
  schemaVersion: 8, // original version was 6
  properties: {
    id: { type: 'string', indexed: true, saveToServer: true },
    projectId: { type: 'string', indexed: true },
    updatedTS: { type: 'int', default: 0, saveToServer: true },
    isDeleted: { type: 'bool?', saveToServer: true },
    name: 'string?',
    lastUploadTS: { type: 'int', default: 0, indexed: true },
    isLocal: { type: 'bool?' },
  }
};

export const equipmentSchema = {
  name: 'equipment1',
  primaryKey: 'id',
  schemaVersion: 7, // original version was 6
  properties: {
    id: { type: 'string', indexed: true, saveToServer: true },
    isDeleted: { type: 'bool?', saveToServer: true },
    projectId: { type: 'string', indexed: true },
    updatedTS: { type: 'int', default: 0, saveToServer: true },
    isLocal: { type: 'bool?' },
    lastUploadTS: { type: 'int', default: 0, indexed: true },
  }
};



export const checklistItemsInstanceSchema = {
  name: 'checklistItemsInstance1',
  primaryKey: 'id',
  schemaVersion: 12,
  properties: {
    id: { type: 'string', indexed: true },
    projectId: { type: 'string', indexed: true },
    checklistItemId: { type: 'string', indexed: true },
    status: { type: 'int', default: 300 },
    updatedTS: { type: 'int?', default: 0 },
    createdTS: { type: 'int?', default: 0 },
    targetTS: { type: 'int?' },
    isDeleted: { type: 'bool?' },
    isLocal: { type: 'bool?', indexed: true },
    locationId: { type: 'string?', indexed: true },
    checklistId: { type: 'string?', indexed: true },
    extraData: 'extraDataField[]'
  }
};

export const extraDataFieldSchema = {
  name: 'extraDataField',
  schemaVersion: 5,
  properties: {
    id: 'string',
    instanceId: 'string',
    ordinalNo: 'int?',
    optional: 'bool?',
    readOnly: 'bool?'
  }
};

export const propertyInstanceSchema = {
  name: 'propertyInstance1',
  primaryKey: 'id',
  schemaVersion: 10, // original version was 8
  properties: {
    id: { type: 'string', indexed: true, saveToServer: true },
    projectId: { type: 'string', indexed: true },
    subjectName: { type: 'string', indexed: true },
    propId: { type: 'string', indexed: true, saveToServer: true },
    propType: { type: 'string?', indexed: true, saveToServer: true },
    data: { type: 'string?', saveToServer: true },
    updatedTS: { type: 'int', default: 0, saveToServer: true }, // TODO: should we actually include save to server for this one?
    createdTS: { type: 'int', default: 0 },
    isDeleted: { type: 'bool?', saveToServer: true },
    isLocal: { type: 'bool?' },
    lastUploadTS: { type: 'int', default: 0, indexed: true },
    parentId: { type: 'string?', indexed: true, saveToServer: true },
  }
};

export const memberSchema = {
  name: 'member',
  primaryKey: 'id',
  schemaVersion: 2,
  properties: {
    id: { type: 'string', indexed: true },
    displayName: 'string?',
    companyId: { type: 'string?', indexed: true },
    companyName: 'string?',
  }
};


export const slimUserSchema = {
  name: 'slimUser',
  schemaVersion: 2,
  properties: {
    id: { type: 'string', indexed: true }
  }
};


export const tradeSchema = {
  name: 'trade1',
  schemaVersion: 2,
  properties: {
    id: { type: 'string', indexed: true },
  }
};

export const subCategorySchema = {
  name: 'subCategory',
  schemaVersion: 1,
  properties: {
    id: { type: 'string', indexed: true },
  }
};

export const requiredActionSchema = {
  name: 'requiredAction',
  schemaVersion: 2,
  properties: {
    id: { type: 'string', indexed: true },
    customText: { type: 'string?' },
  }
};

export const fineSchema = {
  name: 'fine',
  schemaVersion: 1,
  properties: {
    amount: { type: 'int', default: 0 },
    description: { type: 'string?' },
    fineReciver: { type: 'slimUser?' },
  }
};


export const taggedCompanySchema = {
  name: 'taggedCompany',
  schemaVersion: 2,
  properties: {
    id: { type: 'string', indexed: true },
  }
};

export const imagesSchema = {
  name: 'images1',
  schemaVersion: 2,
  properties: {
    id: 'string',
    height: { type: 'int', default: 0 },
    width: { type: 'int', default: 0 },
    uri: 'string?'
  }
};

export const attachmentsSchema = {
  name: 'attachments',
  schemaVersion: 3,
  properties: {
    id: 'string',
    uri: 'string?',
    title: 'string?',
    thumbnail: 'string?',
    extension: 'string?',
    type: 'string?',
  }
};

export const slimChecklistItemInstanceSchema = {
  name: 'slimChecklistItemInstance',
  schemaVersion: 2,
  properties: {
    id: { type: 'string', indexed: true },
  }
};

export const locationSchema = {
  name: 'location',
  schemaVersion: 2,
  properties: {
    building: "locationId?",
    floor: "locationId?",
    unit: "locationId?"
  }
};

export const locationIdSchema = {
  name: 'locationId',
  schemaVersion: 2,
  properties: {
    id: { type: 'string', indexed: true },
    num: 'int?'
  }
};

export const commentDataSchema = {
  name: 'commentDataSchema2',
  schemaVersion: 2,
  properties: {
    newAssignTo: 'slimUser?',
    status: 'int?',
  }
};

export const commentSchema = {
  name: 'comment5',
  schemaVersion: 2,
  properties: {
    id: 'string',
    createdAt: { type: 'int', default: 0 },
    owner: 'slimUser',
    content: 'string?',
    images: 'images1[]',
    type: 'string?',
    data: 'commentDataSchema2'
  }
};

export const postRefSchema = {
  name: 'postRef',
  schemaVersion: 1,
  properties: {
    id: { type: 'string', indexed: true },
    subjectName: { type: 'string', indexed: true },
    parentId: { type: 'string', indexed: true },
    propId: { type: 'string?', indexed: true },
    certificationVersionId: { type: 'string?', indexed: true },
  }
};

export const postsSchema = {
  name: 'post24',
  primaryKey: 'id',
  schemaVersion: 12,
  properties: {
    id: 'string',
    projectId: { type: 'string', indexed: true },
    checklistItemInstance: 'slimChecklistItemInstance',
    isDeleted: { type: 'bool?' },
    locationId: 'string?',
    createdAt: { type: 'date?', indexed: true },
    editedAt: 'date?',
    updatedTS: { type: 'date?', indexed: true },
    stateUpdatedTS: 'date?',
    dueDate: 'date?',
    fue: { type: 'int', default: 0 }, // First Unseen Event
    counter: { type: 'int', default: 0 },
    owner: 'slimUser',
    title: 'string?',
    images: 'images1[]',
    attachments: 'attachments[]',
    comments: 'comment5[]',
    commentsCounter: { type: 'int', default: 0 },
    trade: { type: 'trade1?' },
    taggedCompanies: 'taggedCompany[]',
    type: 'string?',
    issueOpener: 'slimUser',
    isIssue: { type: 'bool?' },
    issueState: { type: 'int', default: 0 }, // THIS IS THE STATUS OF THE ISSUE: 100 - Closed, 200 - Resolved, 300 - Open Issue
    isReported: { type: 'bool?' },
    location: 'location?',
    assignTo: 'member',
    isLocal: { type: 'bool?' },
    tempPostOriginalJson: 'string?',
    editedAtOrCreatedAt: { type: 'date?', indexed: true },
    subCategory: { type: 'subCategory?' },
    requiredAction: { type: 'requiredAction?' },
    fine: { type: 'fine?' },
    severity: { type: 'int', default: 0 },
    refs: 'postRef[]',
    isUploading: 'bool?'
  }
};

export const retryObjectsSchema = {
  name: 'retryObjects',
  schemaVersion: 3,
  properties: {
    id: 'string',
    json: 'string',
    type: { type: 'string', indexed: true },
    projectId: { type: 'string', indexed: true },
  }
};