import React from "react";
import { connect  } from 'react-redux';
import { compose, hoistStatics } from 'recompose';
import { connectContext } from 'react-connect-context';
import { ProjectContext } from '../../../common/projects/contexts';
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from "classnames";
import { DragHandleOutlined } from "@material-ui/icons";
import { SortableHandle } from 'react-sortable-hoc';
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import regularFormsStyle from "../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import ObjectSourceTag from "../ProjectManager/ObjectSourceTag";

const DragHandle = SortableHandle(() => <DragHandleOutlined id={'dragger'} />);

class PropertyRow extends React.Component {
  constructor(props) {
    super(props);
    this.setHover = this.setHover.bind(this)
    this.onClick = this.onClick.bind(this)
    this.onChange = this.onChange.bind(this)
    this.state = {
      hover:false,
    };
  }
  
  setHover() {
    const { hover } = this.state;
    this.setState({hover: !hover});
  }
  
  onClick() {
    const { onClick } = this.props;
    if (onClick) 
      onClick();
  }

  onChange() {
    const { value, onChange } = this.props;
    let clone = { ...value, checked: !Boolean(value.checked) }
    if (onChange)
      onChange(clone)
  }

  render() {
    const { classes, value, key, isActive, editable, titlePath, disableDrag, lang, selectedPropertyId, objectSourceBasePath } = this.props;
    const { hover } = this.state;
    let rowValue = value || {};

    const objectPath = objectSourceBasePath.filter((path) => path !== "propertiesTypes").concat([rowValue.id]);
    
    return (    
      <div key={key} style={{ display: 'flex', flex: 1, justifyContent:'center', alignItems:'center', backgroundColor: isActive ? '#f1f1f1' : (hover ? '#f9f9f9' : 'transparent')}} onMouseOver={this.setHover} onMouseOut={this.setHover}>
        {Boolean(!disableDrag) && <div style={{marginRight:'10px', marginLeft:'10px', cursor:'move'}}><DragHandle/></div>}
        <ObjectSourceTag key={`${rowValue.id}_source_tag`} subType={"propertiesTypes"} objectPath={objectPath} />
        <GridContainer alignItems='center' alignContent='center' justify='center' onClick={this.onClick}>  
          <GridItem xs={2} style={{ textAlign: 'center' }}>
            {Boolean(rowValue.id == selectedPropertyId) && '!-!-!'}
          </GridItem>
          <GridItem xs={5}>
            <p>{titlePath ? rowValue.getNested(titlePath) : (rowValue.getNested(['getTitle']) || rowValue.getNested(['title', lang], ''))}</p>
          </GridItem>
          <GridItem xs={4}>
            <p>{(rowValue || {}).sectionId}</p>
          </GridItem>
          <GridItem xs={1}>
          {Boolean(editable) && <Checkbox 
            onChange={rowValue.id == selectedPropertyId ? null : this.onChange}
            checked={rowValue.checked || rowValue.id == 'groups'}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{ checked: classes.checked}}/>}
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

PropertyRow = withStyles(regularFormsStyle)(PropertyRow);
const enhance = compose(
  connectContext(ProjectContext.Consumer),  
  connect(null, {})
);
export default hoistStatics(enhance)(PropertyRow);