import _ from 'lodash';
import { platformActions } from '../../common/platformActions';
import { envParams } from '../../common/configureMiddleware';
import { flattenObject, onError }  from '../../common/app/funcs';
import { DEFAULT_PERMISSIONS_PATH_DELIMETER } from '../../common/permissions/funcs';

const DEFAULT_OBJECT_SOURCE = null;


export const encodeFilterToSearch = (filterObject, originalSearch, filterUrlKey = 'filter',) => {
  const filterString = Object.entries(filterObject || {}).reduce((acc, [key, val]) => `${acc}&${key}=${val.toString()}`, '');

  let newSearch = new URLSearchParams(originalSearch);
  if (filterString)
    newSearch.set(filterUrlKey, encodeURI(filterString));
  else
    newSearch.delete(filterUrlKey);

  return newSearch.toString();
}

export const decodeFiltersFromSearch = (locationSearch, filterUrlKey = 'filter', pathDelimeter = DEFAULT_PERMISSIONS_PATH_DELIMETER) => {
  const urlSearchParamsObj = new URLSearchParams(locationSearch);
  const filterParams = decodeURI(urlSearchParamsObj.get(filterUrlKey) || '');
  const filterParamsObj = new URLSearchParams(filterParams);

  let originalFilterObj = {};
  let filterQuery = {};
  filterParamsObj.forEach((paramVal, paramKey) => {
    const objKey = paramKey.indexOf('TARGET') !== -1 ? 'target' : `target${pathDelimeter}${paramKey}`;
    const parsedVal = paramVal.split(',');
    filterQuery[objKey] = parsedVal.length === 1 ? parsedVal[0] : parsedVal;
    originalFilterObj[paramKey] = parsedVal.reduce((acc, val) => _.set(acc, [val], val), {});
  });

  return { cementoQuery: Object.keys(filterQuery).length ? [filterQuery] : null, originalFilterObj };
}

export const getMergerResult = async (subjectsArray, scope, scopeId, includeObjectsSourceMap = false) => {
  let returnedMap = {};
  let success = true;
  try {
    let getMergedResult = await (platformActions.net.fetch(`${envParams.apiServer}/v1/services/templates/getMerged?templateSubjects=${JSON.stringify(subjectsArray)}&scope=${scope}&scopeId=${scopeId}&returnObjectSource=${includeObjectsSourceMap}&excludeNull=true`));
    returnedMap = await getMergedResult.getJson();
  } catch (error) {
    onError({errorMessage: 'getMergerResult error', error, methodMetaData: {subjectsArray, scope, scopeId}});
    success = false;
  }
  finally {
    return { success, mergerResult: returnedMap};
  }
}

export const getObjectSources = (objectPath, sourceMap) => {
  let objectSources = new Set();
  const objectValue = _.get(sourceMap, objectPath);
  if (typeof objectValue === 'object') {
    const flattenedObject = flattenObject(objectValue);
    _.values(flattenedObject).forEach(source => objectSources.add(source));
  } else if (typeof objectValue  === 'string') objectSources.add(objectValue);

  return Array.from(objectSources);
}

export const upsertConfig = async (targetScope, targetScopeId, sourceScope, sourceScopeId, sourceSubjectType, subjectName, selectedProperties) => {
  try {
    await platformActions.net.fetch(`${envParams.apiServer}/v1/services/templates/upsertConfig`, {
      method: "PUT",
      body: JSON.stringify({
        targetScope,
        targetScopeId,
        sourceScope,
        sourceScopeId,
        sourceSubjectType,
        subjectName,
        selectedProperties
      }),
    });
  } catch (error) {
    onError({errorMessage: 'upsertConfig error', error, methodMetaData: {targetScope, targetScopeId, sourceScope, sourceScopeId, sourceSubjectType, subjectName, selectedProperties}});
  }
}