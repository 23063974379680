import React from 'react';
import * as propertyTypes from '../../../common/propertiesTypes/propertiesTypes';
import theme from '../../assets/css/theme';
import TableColumnHeader from './TableColumnHeader';
import TableCollapsibleCell from './TableCollapsibleCell';
import TableOptimizedCell from './TableOptimizedCell';
import { cellContentCalculator, columnTypes } from '../../../common/analytics/funcs';
import usersMessages from '../../../common/users/usersMessages';
import Text from '../../components/CementoComponents/Text';
import {v4 as uuidv4} from 'uuid';
import safetyMessages from '../../../common/safety/safetyMessages';
import _ from 'lodash';
import analyticsMessages from '../../../common/analytics/analyticsMessages';
import systemMessages from '../../../common/app/systemMessages';
import companiesMessages from '../../../common/companies/companiesMessages';
import registrationMessages from '../../../common/auth/registrationMessages';
import siteControlMessages from '../../../common/siteControl/siteControlMessages';
import moment from 'moment';
import { EXIT, getTSArrayByTimeUnit, getVisitsInfo, prepareEmployeePresenceDataForTable } from '../../../common/siteControl/funcs';
import { isEmptyValue } from '../../../common/app/funcs';
import EmployeesPresenceLogs, { Day as EmployeePresenceDayHeader} from '../../../common/app/components/EmployeesPresenceLogs';
import { ActivityIndicator } from '../../components/SiteControl/CamerasMonitor'
import SiteControlToolTip, { SITE_CONTROL_TOOLTIP_TYPES } from '../../components/SiteControl/SiteControlToolTip';


const sectionHeight = 17 * 3; 
const marginRight = 20;
const cellHeight = 40;
const borderColor = 'rgb(215, 215, 215)';
const customTop = 51;
const NO_VALUE = ' ';

export class TableDataObject {
  /**
   * 
   * @param {{ sections?: { [sectionId: string]: TableSection }, isSubTable: boolean, isCollapsableTable: boolean }} param0 
   */
  constructor({ sections = {}, isSubTable = false, isCollapsableTable } = {}) {
    this.sections = sections;
    this.isSubTable = isSubTable;
    this.isCollapsableTable = isCollapsableTable;
  }
}

export class TableSection {
  /**
   * @param {{ id: string, title: any, columns?: { [columnId: string]: TableColumn }, num: number } }} param0
   */
  constructor({ id, title, columns = {}, num } = {}) {
    this.id = id;
    this.title = title || '';
    this.columns = columns;
    this.num = num;
  }
}

export class TableColumn {
  /**
   * @param {{ id: string, title: any, isPrimary: boolean, valuesType?: string, num?: number, universalId?: string, rows?: { [rowId: string]: TableCell }, settings: any }, isDynamicallyGenerated: boolean, pathInObject: string }} param0
   */
  constructor({ id, title, isPrimary, valuesType = 'String', num = null, universalId = null, rows = {}, settings, valueOptions, businessType, isDynamicallyGenerated = false, pathInObject, HeaderInnerComponent } = {},) {
    this.id = id;
    this.settings = settings;
    this.title = title || '';
    this.isPrimary = isPrimary || null;
    this.valuesType = valuesType;
    this.num = num;
    this.rows = rows;
    this.universalId = universalId;
    this.valueOptions = valueOptions;
    this.businessType = businessType;
    this.isDynamicallyGenerated = isDynamicallyGenerated;
    this.pathInObject = pathInObject;
    this.HeaderInnerComponent = HeaderInnerComponent
  }
}
export const ORIGINAL_VALUE_KEY_TERM = 'originalValue';
export class TableCell {
  /**
   * @param {{ id: string, title: any, value: value: { [ORIGINAL_VALUE_KEY_TERM]: any, displayValue: any, displayType: string, displayParams: { direction: 'rtl' | 'ltr', isCounterRow: boolean }, subCells: { [subCellId: string]: TableCell }, objectType: string, dismissClickHandler: boolean, ordinalNum: number } }} param0
   */
  constructor({ id, title = null, value, subCells = {}, objectType, dismissClickHandler, ordinalNum, sideCardOptions } = {}) {
    this.id = id;
    this.title = title;
    this.value = value;
    this.subCells = subCells;
    this.objectType = objectType;
    this.dismissClickHandler = dismissClickHandler;
    this.ordinalNum = ordinalNum;
    this.sideCardOptions = sideCardOptions;
  }
}

// WARNING !! the id of each row should include at least one letter
export const getTableWrapperProps = ({ tableDataObj, rtl, originalTableData = {}, sectionHeaderFunc = calcSectionHeader, columnHeaderFunc = calcColumnHeader, cellFunc = calcCellFunc, displayEmptyRows = true, sortFunc }) => {
  if (!tableDataObj || !tableDataObj.sections)
    return null;

  const { sections } = tableDataObj;

  let sectionsObj = {};
  let rowsData = {};
  let primaryColumn = null;
  const pathInObjectColumns = Object.values(originalTableData.properties || {}).filter(col => col.pathInObject);

  Object.values(sections).sort((sectionA, sectionB) => sectionA.num - sectionB.num).forEach(section => {
    const { id: sectionId, title: sectionTitle, columns, ...rest } = section;

    let currSection = {
      original: { ...section, section: sectionTitle || "" },
      style: { borderTopStyle: 'none', zIndex: theme.zIndexesLevels.eighteen, width: '100%', outlineOffset: -0.5, outline: '0.5px solid rgb(215,215,215)', position: 'sticky', top: 0 },
      Header: sectionHeaderFunc,
      columns: [],
    };
    
    Object.values(columns).sort((a,b) => a.num - b.num).forEach(column => {
      const { id: mainColumnId, title, rows, subColumns = null, isPrimary, settings, num, valuesType = propertyTypes.STRING, ...rest } = column;
      const widthRatio = _.get(settings, 'widthRatio', 1);
      let columnCustomMaxChars = null;
      let customJustifyContent = null;
      let customWidth = 125 * widthRatio;
      let customAlignItems = null;

      // TODO: change all this to be come as props 
      if (mainColumnId == 'locations-group-table-locationsColumn') {
        columnCustomMaxChars = 105;
        customJustifyContent = 'inherit';
        customWidth = 860;
        customAlignItems = 'start';
      }

      let currMainColumn = {
        id: mainColumnId,
        alwaysShow: isPrimary ? false : true, // TODO: needs some filter
        mainColumnId,
        className: 'TableData',
        HeaderValue: mainColumnId,
        headerClassName: 'column header',
        title,
        customMaxChars: columnCustomMaxChars,
        original: { ...column, type: valuesType, parentId: mainColumnId },
        rowStyle: { height: 40, customJustifyContent, columnCustomMaxChars, customAlignItems, width: customWidth },
        style: { position: 'sticky', top: customTop, width: customWidth },
        parentId: mainColumnId,
        valuesType,
        ...(subColumns ? { subColumns: [] } : {}),
        Header: (currColumn, extraProps) => columnHeaderFunc(currColumn, extraProps, rtl),
        Cell: cellFunc
      };

      if (isPrimary) {
        primaryColumn = { ...currMainColumn };
      }
      else if (subColumns) // Don't want to calculate subColumns of primary column, hence else if
        Object.values(subColumns).forEach(currSubColumn => {
          const { id, title, rows, num, valuesType } = currSubColumn;
          // TODO: do subColumns
          // subColumn parentId will be either
        });

      Object.values(rows).forEach(currRow => setRow(currRow, mainColumnId, currRow.id, rowsData, undefined, displayEmptyRows, pathInObjectColumns, originalTableData.objects));
      currSection.columns.push(currMainColumn);
    });

    sectionsObj[sectionId] = currSection;
  });

  let expandableTable = false;
  const rowsLevel = rowsData.rowsLevel;
  delete rowsData.rowsLevel;

  if (!primaryColumn) {
    const primaryColumnFromOriginalProps = Object.values(originalTableData.properties || {}).filter(property => property.isPrimary);
    if (primaryColumnFromOriginalProps.length) {
      let customPrimaryColumn = primaryColumnFromOriginalProps[0];
      primaryColumn = {
        id: customPrimaryColumn.id,
        alwaysShow: true,
        mainColumnId: customPrimaryColumn.id,
        className: 'TableData',
        HeaderValue: customPrimaryColumn.id,
        headerClassName: 'column header',
        maxWidth: 70,
        title: customPrimaryColumn.title,
        customMaxChars: null,
        original: { ...customPrimaryColumn, type: customPrimaryColumn.type, parentId: customPrimaryColumn.parentId },
        rowStyle: { height: 40},
        style: { position: 'sticky'},
        parentId: customPrimaryColumn.id,
        valuesType: customPrimaryColumn.type,
        Header: (currColumn, extraProps) => columnHeaderFunc(currColumn, extraProps, rtl),
        Cell: cellFunc
      }
    }
  }

  if (rowsLevel > 1)
    expandableTable = true;

  const firstColumnRenderFunc = (currCell, props) => {
    const currCellMainColumnId = currCell.column.mainColumnId;
    const currCellValues = currCell.row.values;

    return (
      <TableCollapsibleCell
        cellHeight={cellHeight}
        key={'firstColumn-'.concat(currCell.row.id)}
        id={currCell.row.id}
        title={currCellValues[currCellMainColumnId] && currCellValues[currCellMainColumnId].displayValue}
        cell={currCell}
        {...props}
      />
    );
  }
  
  const rowsDataArray = _.values(rowsData);
  const rowsDataSortedArray = expandableTable ? rowsDataArray : rowsDataArray.sort((rowA, rowB) => {
    const primaryColumnId = _.get(primaryColumn, 'id');
    const primaryColumnValuesType = _.get(primaryColumn, 'valuesType');
    const rowATitle = _.get(rowA, ['values', primaryColumnId, 'displayValue']);
    const rowBTitle = _.get(rowB, ['values', primaryColumnId, 'displayValue']);

    let compareResult = -1;
    if (['Date', 'Number'].includes(primaryColumnValuesType))
      compareResult = (rowBTitle || 0) - (rowATitle || 0);
    else if (['String'].includes(primaryColumnValuesType))
      compareResult = (rowATitle || '').localeCompare(rowBTitle || '')

    return compareResult;
  })

  return {
    originalData: { rows: rowsDataSortedArray },
    columns: Object.values(sectionsObj),
    primaryColumn,
    firstColumnRenderFunc,
    expandableTable,
    rowsLevel,
    rowsArray: rowsDataSortedArray
  };
}

/**
 * 
 * @param {TableCell} row 
 * @param {string} parentColumnId 
 * @param {string} parentId 
 * @param {{}} tableRows 
 * @param {number} rowLevel 
 * @param {boolean} showEmptyRows 
 * @param {string} pathInObjectColumns 
 * @param {{ [objectId: string]: any }} rowOriginalObjects 
 * @returns 
 */
function setRow(row, parentColumnId, parentId, tableRows, rowLevel = 1, showEmptyRows = true, pathInObjectColumns = [], rowOriginalObjects = {}) {
  if (!row || !tableRows)
    return null;

  const { id: rowId, value, subCells, sideCardOptions } = row;
  const currRowHasSubRows = Boolean(Object.values(subCells || {}).length);
  if (!currRowHasSubRows && !showEmptyRows)
    return;

  if (!tableRows[rowId]) {
    tableRows[rowId] = {
      id: rowId,
      values: {},
      rowLevel,
      dismissClickHandler: row.dismissClickHandler,
      objectType: row.objectType,
      order: row.ordinalNum,
      sideCardOptions
    };
    if (rowLevel !== 1)
      tableRows[rowId].parentId = parentId;

    pathInObjectColumns.forEach(column => { 
      const rowOriginalObject = rowOriginalObjects[rowId];
      const pathValue = _.isNil(rowOriginalObject?._aggregatedValue) 
        ? _.get(rowOriginalObject, column.pathInObject)
        : rowOriginalObject._aggregatedValue;
      tableRows[rowId].values[column.id] = { 
        displayValue: pathValue, 
        displayType: column.type, 
        displayParams: rowOriginalObject?._aggregatedValueDisplayParams || {}, 
        [ORIGINAL_VALUE_KEY_TERM]: pathValue 
      };
    });
  }

  if (!_.get(tableRows, [rowId, 'values', parentColumnId]))
    tableRows[rowId].values[parentColumnId] = value;

  if (currRowHasSubRows)
    Object.values(subCells).forEach(currSubRow => setRow(currSubRow, parentColumnId, rowId, tableRows, rowLevel + 1, undefined, pathInObjectColumns, rowOriginalObjects));

  if ((tableRows.rowsLevel || 0) < rowLevel)
    tableRows.rowsLevel = rowLevel;
}
  

export const calcSectionHeader = currColumn => {
  return (
    <div style={{
      borderLeftWidth: 2,
      borderRightWidth: 2,
      borderColor,
      display: 'flex',
      width: 'inherit',
      margin: 0,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      maxHeight: sectionHeight,
      height: sectionHeight,
    }}>
      <Text
        title={currColumn.section}
        style={{
          padding: theme.margin,
          fontSize: 18,
          textAlign: 'center',
          color: theme.brandPrimary,
          fontWeight: 700,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          textAlign: 'start',
          whiteSpace: 'nowrap',
          width: 'inherit',
        }}
      >
        {currColumn.section}
      </Text>
    </div>
  );
}

export const calcColumnHeader = (currColumn, extraProps, rtl) => (
  <TableColumnHeader
    key={'header-'.concat(currColumn.original ? currColumn.original.id : "general")}
    textStyle={{
      fontWeight: theme.strongBold,
      [rtl ? 'marginLeft' : 'marginRight']: theme.verticalMargin + 2,
    }}
    marginRight={marginRight}
    currSubColumn={currColumn}
    rtl={rtl}
    {...extraProps}
    columnType={currColumn.id == currColumn.parentId} // TODO: Can move this into the component
  >
    {currColumn.HeaderInnerComponent}
  </TableColumnHeader>
);



export const calcCellFunc = (currCell, extraProps) => (
  <TableOptimizedCell
    key={'cell-'.concat(currCell.row.id).concat(currCell.column.original.description)}
    cellData={currCell}
    {...extraProps}
  />
);

export const extractProjectsRegions = (projects, defaultRegion) => {
  let regionColumnStructure = {};
  _.values(projects).forEach(project => {
    let projectRegion = project.getNested(['region'], defaultRegion);
    if (!regionColumnStructure[projectRegion])
      regionColumnStructure[projectRegion] = { id: uuidv4(), title: projectRegion, columns: [] };
    
    regionColumnStructure[projectRegion].columns.push({ id: project.id, title: project.getCementoTitle() || project.getNested(['address']), valuesType: propertyTypes.BOOLEAN });
  })

  regionColumnStructure = _.values(regionColumnStructure).sort((sectionA, sectionB) => (sectionA.title || '').localeCompare(sectionB.title));
  _.values(regionColumnStructure).forEach(region => region.columns.sort((a,b) => a.getNested(['title'], defaultRegion).localeCompare(b.getNested(['title'], defaultRegion))));

  return regionColumnStructure;
}

// MEMBERS SPECIFIC STUFF --------------------------------------------------------------------
const getMembersData = (members) => {
  if (!members)
    return null;

  let membersData = {};
  Object.values(members).forEach(member => {
    if (!member.user_metadata)
      return;

    const { id, phoneNumber, displayName, email, phoneVerified, projects, companyId, trades, title } = member.user_metadata;
    let memberCompaniesIdsMap = {};
    _.values(projects).forEach(project => memberCompaniesIdsMap[project.companyId] = true);

    const memberObj = {
      id,
      phoneNumber,
      displayName,
      companyId,
      projects,
      email,
      phoneVerified,
      title,
      companiesIds: memberCompaniesIdsMap,
      projectIds: Object.keys(projects || {}),
      tradeIds: Object.values(trades || {}),
    };

    membersData[id] = memberObj;
  });

  membersData = Object.values(membersData).sort((a, b) => (a.displayName || '').localeCompare(b.displayName || ''));

  return membersData;
}


export const getMembersProjectsTableDataObj = (companies, currCompanyProjects, members, tradesTitlesMap, titles, activeOnly, defaultSectionTitle) => {
  const nameColumnId = 'members-table-nameColumn';
  const tradesColumnId = 'members-table-tradesColumn';
  const titlesColumnId = 'members-table-titlesColumn';
  const NO_REGION_TEXT = defaultSectionTitle || NO_VALUE;

  let membersTableStructure = [
    {
      id: 'employeesSection',
      title: usersMessages.details,
      columns: [
        { id: nameColumnId, title: registrationMessages.name, isPrimary: true, keyInMemberData: 'displayName' },
        { id: tradesColumnId, title: usersMessages.trade, keyInMemberData: 'tradeIds', valuesType: propertyTypes.STRING },
        { id: titlesColumnId, title: usersMessages.title, keyInMemberData: 'title', valuesType: propertyTypes.STRING },
      ],
    },
  ];
  tradesTitlesMap = tradesTitlesMap.toJS ? tradesTitlesMap.toJS() : tradesTitlesMap;
  titles = titles.toJS ? titles.toJS() : titles;

  let regionColumnStructure = extractProjectsRegions(currCompanyProjects, NO_REGION_TEXT);
  membersTableStructure = membersTableStructure.concat(regionColumnStructure);

  let employeesCountPerProjectPerCompany = {};
  let tableDataObj = new TableDataObject()
  const membersData = getMembersData(members);
  const relevantCompanies = getSubcontractorsData(companies);

  Object.entries(relevantCompanies || {}).forEach(([currCompanyId, currCompany]) => {
    const { name: companyName } = currCompany;
    membersTableStructure.forEach(sectionStructure => {
      const { id: sectionId, title: sectionTitle, columns } = sectionStructure;
      if (!tableDataObj.sections[sectionId])
        tableDataObj.sections[sectionId] = new TableSection({ id: sectionId, title: sectionTitle });

      (columns).forEach(column => {
        const { id: columnId, title: columnTitle, isPrimary, type, keyInMemberData, valuesType, settings } = column;

        if (!tableDataObj.sections[sectionId].columns[columnId])
          tableDataObj.sections[sectionId].columns[columnId] = new TableColumn({ id: columnId, title: columnTitle, isPrimary, valuesType, settings });

        tableDataObj.sections[sectionId].columns[columnId].rows[currCompanyId] = new TableCell({ id: currCompanyId, value: {displayValue: companyName, displayType: propertyTypes.STRING, displayParams: {}}, title: companyName, objectType: 'company', dismissClickHandler: true });

        if (!employeesCountPerProjectPerCompany[columnId])
          employeesCountPerProjectPerCompany[columnId] = {};

        (membersData || []).forEach(memberData => {
          if (!memberData.companiesIds[currCompanyId])
            return;
          let isMemberBelongsToCompanyProjects = Object.keys(memberData.projects || {}).filter(projectId => currCompanyProjects[projectId]);
          if (isMemberBelongsToCompanyProjects.length === 0) return;
          let memberDataActiveProjects = Object.entries(memberData.projects || {}).filter(([projId, proj]) => !proj.isDeleted && proj.companyId == currCompanyId && currCompanyProjects[projId]);
          if (memberDataActiveProjects.length == 0 && activeOnly) return;

          const { id: memberId } = memberData;
          let val;
          if (sectionId == 'employeesSection') {
            val = memberData[keyInMemberData] || null;
            if (columnId === titlesColumnId)
              val = titles[val] ? titles[val].getCementoTitle() : '';
            if (columnId === tradesColumnId)
              val = (memberData[keyInMemberData] || {}).map(tradeId => (tradesTitlesMap[tradeId] || {}).getCementoTitle() || null).filter(Boolean).join(', ');
          }
          else {
            if ((((memberData.projects || {})[columnId] || {}).companyId !== currCompanyId) || (((memberData.projects || {})[columnId] || {}).isDeleted))
              val = null;
            else {
              val = true;
              if (!employeesCountPerProjectPerCompany[columnId][currCompanyId])
                employeesCountPerProjectPerCompany[columnId][currCompanyId] = 1;
              else
                employeesCountPerProjectPerCompany[columnId][currCompanyId]++;
            }
          }

          if (!tableDataObj.sections[sectionId].columns[columnId].rows[currCompanyId].subCells[memberId])
            tableDataObj.sections[sectionId].columns[columnId].rows[currCompanyId].subCells[memberId] = new TableCell({ id: memberId, value: {displayValue: val, displayType: valuesType, displayParams: {}}, title: val, objectType: 'members' })
        });

        if (isPrimary) return;

        let companyVal = tableDataObj.sections[sectionId].columns[columnId].rows[currCompanyId].value.displayValue;

        if (sectionId == 'employeesSection') {
          companyVal = null;
          if (columnId === tradesColumnId) {
            companyVal = Array.from(new Set(Object.values(tableDataObj.sections[sectionId].columns[columnId].rows[currCompanyId].subCells).filter(subRow => Boolean(subRow.value.displayValue)).map(subRow => subRow.value.displayValue)))
            companyVal = companyVal.join(', ');
          }
          else if (columnId === titlesColumnId)
            companyVal = undefined;
        } else {
          companyVal = (employeesCountPerProjectPerCompany[columnId] || {})[currCompanyId];
        }

        tableDataObj.sections[sectionId].columns[columnId].rows[currCompanyId].title = companyVal;
        tableDataObj.sections[sectionId].columns[columnId].rows[currCompanyId].value = {displayValue: companyVal, displayType: 'String', displayParams: {}};
      });
    });
  });

  return tableDataObj;
};

const getDayColumnKey = ts => `day${ts}`;
const groupLogsByMonth = logs => {
  let logsByMonth = {};
  _.forIn(logs, (currDayLogs, currDayTS) => {
    let month = moment(Number(currDayTS)).format("MMMM YYYY");
    _.set(logsByMonth, [month, currDayTS], currDayLogs);
  });
  return logsByMonth;
};

export const getEmployeesPresenceTableDataObj = ({ employeesPresenceData, inFilters, inTextFilter, companyDefaultValue = siteControlMessages.table.noCompany, startTS = moment().startOf('month').valueOf(), endTS = Date.now(), intl, tablesMetadata }) => {

  const section = {
    id: 'presenceSection',
    columns: [
      {
        id: "main",
        isPrimary: true,
        num: 0
      },
      ...getTSArrayByTimeUnit({ startTS, endTS })
        .map((ts, index) => ({
          ts,
          id: getDayColumnKey(ts),
          valueGetter: targetObject => _.get(targetObject, ['log', ts]),
          title: moment(ts).format("ddd DD MMM"),
          num: index + 1,
          displayType: propertyTypes.BOOLEAN,
          HeaderInnerComponent: <EmployeePresenceDayHeader ts={ts} containerStyle={{ padding: theme.paddingX2 }} dayTitleStyle={{ fontSize: theme.fontSizeH4 }} dayDateStyle={{}} />
        }))]
  };


  let table = new TableDataObject();

  _.forIn(employeesPresenceData, employee => {
    const { id: employeeId, companyName: _employeeCompany, companyId: _companyId, fullName: employeeName, log: employeeLog } = employee;
    const formattedCompanyDefaultValue = (companyDefaultValue && companyDefaultValue.id && companyDefaultValue.defaultMessage)
      ? intl.formatMessage(companyDefaultValue)
      : companyDefaultValue;
    const employeeCompany = _employeeCompany || formattedCompanyDefaultValue;
    const companyId = _companyId ? _companyId : employeeCompany //support some old projects with string 'employCompany' prop instead of selection list
    const textFilter = _.get(inFilters, ['textFilter'], inTextFilter);
    const shouldFilterOut = textFilter && ![employeeName, employeeCompany].some(val => String(val).includes(textFilter));

    if (shouldFilterOut)
      return;

    let logsByMonth = groupLogsByMonth(employeeLog);
    _.forIn(logsByMonth, (logs, month) => {
      const sectionId = section.id;
      if (!_.get(table, ['sections', sectionId])) {
        const sectionObject = new TableSection({ id: sectionId, title: month });
        _.set(table, ['sections', sectionId], sectionObject);
      }

      _.forIn(section.columns, (column, columnIndex) => {
        const { id: columnId, pathInTargetObject, title: columnTitle, isPrimary, num: columnNum, valueGetter, displayType, ts, HeaderInnerComponent } = column;

        if (!_.get(table, ['sections', sectionId, 'columns', columnId])) {
          let columnObj = new TableColumn({ id: columnId, title: columnTitle, isPrimary, num: columnNum || columnIndex, HeaderInnerComponent });
          columnObj.ts = ts;
          _.set(table, ['sections', sectionId, 'columns', columnId], columnObj);
        }

        if (!_.get(table, ['sections', sectionId, 'columns', columnId, 'rows', month])) {
          const currDayPresentCount = _.get(tablesMetadata, [ts, 'present'], 0);
          const currDayNonPresentCount = _.get(tablesMetadata, [ts, 'nonPresent'], 0);
          const currDayTotalCount = (currDayPresentCount + currDayNonPresentCount) || null;

          let cellObj = new TableCell({
            id: month,
            value: {
              displayValue: isPrimary ? month : currDayTotalCount,
              displayType: 'String',
              displayParams: {
                isCounterRow: true,
                toolTipParams: {
                  titleComponent: <SiteControlToolTip ts={ts} type={SITE_CONTROL_TOOLTIP_TYPES.DAY} presentCount={currDayPresentCount} nonPresentCount={currDayNonPresentCount} />
                }
              },
            },
            dismissClickHandler: true,
          });
          _.set(table, ['sections', sectionId, 'columns', columnId, 'rows', month], cellObj);
        }

        const companySubCellId = `${month}_${employeeCompany}`;
        if (!_.get(table, ['sections', sectionId, 'columns', columnId, 'rows', month, 'subCells', companySubCellId])) {
          const originalCompanyId = (employeeCompany === formattedCompanyDefaultValue) ? 'undefined' : employeeCompany;
          const companyPresentCount = _.get(tablesMetadata, [ts, 'companies', originalCompanyId, 'present'], 0);
          const companyNonPresentCount = _.get(tablesMetadata, [ts, 'companies', originalCompanyId, 'nonPresent'], 0);
          const companyTotalCount = (companyPresentCount + companyNonPresentCount) || null;
          let cellObj = new TableCell({
            id: companySubCellId,
            value: {
              displayValue: isPrimary ? employeeCompany : companyTotalCount,
              displayType: 'String',
              displayParams: {
                isCounterRow: true,
                counterStyle: {
                  fontSize: theme.fontSize,
                  color: theme.textColor
                },
                toolTipParams: {
                  titleComponent: <SiteControlToolTip type={SITE_CONTROL_TOOLTIP_TYPES.COMPANY} id={companyId} companyName={employeeCompany} presentCount={companyPresentCount} nonPresentCount={companyNonPresentCount} />
                }
              }
            },
            dismissClickHandler: true,
          });
          _.set(table, ['sections', sectionId, 'columns', columnId, 'rows', month, 'subCells', companySubCellId], cellObj);
        }

        if (!_.get(table, ['sections', sectionId, 'columns', columnId, 'rows', month, 'subCells', companySubCellId, 'subCells', employeeId])) {

          let cellValue = _.isFunction(valueGetter) ? valueGetter(employee) : _.get(employee, [pathInTargetObject]);
          let toolTipParams;
          let cellInnerComponent;
          if (isPrimary)
            cellValue = employeeName;
          else if (displayType == propertyTypes.BOOLEAN) {
            cellValue = !_.isEmpty(cellValue) || null;
            if (cellValue && ts) {
              toolTipParams = {
                titleComponent: <SiteControlToolTip type={SITE_CONTROL_TOOLTIP_TYPES.EMPLOYEE} id={employeeId} logs={{ [ts]: logs[ts] }} />,
              };
            }

            if (!cellValue)
              cellValue = null;

            const isTodayAndPresent = (cellValue && _.last(_.values(logs[ts])) !== EXIT && moment().isSame(ts, 'day'));
            const isActive = _.isNil(cellValue) ? null : isTodayAndPresent;
            cellInnerComponent = <ActivityIndicator isActive={isActive} />;
          }

          let cellObj = new TableCell({
            id: employeeId,
            objectType: 'employees',
            value: {
              cellInnerComponent,
              displayValue: cellValue,
              displayType: displayType || 'String',
              displayParams: { toolTipParams }
            },
            sideCardOptions: {
              employeesPresence: employeesPresenceData,
              hideCertifications: true
            }
          });

          _.set(table, ['sections', sectionId, 'columns', columnId, 'rows', month, 'subCells', companySubCellId, 'subCells', employeeId], cellObj);
        }
      });
    });


  });

  return table;
};

const getSubcontractorsData = (subcontractors) => {
  if (!subcontractors)
    return null;

  let subcontractorsData = {};
  Object.values(subcontractors).sort((a, b) => (a.name || '').localeCompare(b.name || '')).forEach(subcontractor => {
    const { id, name, projects, trades } = subcontractor;

    const subcontractorObj = {
      id,
      name,
      projects,
      projectIds: Object.keys(projects || {}),
      tradeIds: Object.values(trades || {}),
    };

    subcontractorsData[id] = subcontractorObj;
  }
  );

  return subcontractorsData;
}


export const getSubcontractorProjectsTableDataObj = (companies, projects, tradesTitlesMap, activeOnly, defaultSectionTitle) => {
  const tradesColumnId = 'company-table-tradesColumn';
  const nameColumnId = 'company-table-nameColumn';
  const projectsCountColumnId = 'members-table-countProjectsColumn';
  const NO_REGION_TEXT = defaultSectionTitle || NO_VALUE;
  let companyTableStructure = [
    {
      id: 'subcontractorDetailsSection',
      title: usersMessages.details,
      columns: [
        { id: nameColumnId, title: companiesMessages.companyName, isPrimary: true, keyInMemberData: 'name' },
        { id: tradesColumnId, title: usersMessages.trade, keyInMemberData: 'tradeIds', valuesType: propertyTypes.STRING },
        { id: projectsCountColumnId, title: safetyMessages.MultiProjectView, valuesType: propertyTypes.STRING },
      ],
    }
  ]

  tradesTitlesMap = tradesTitlesMap.toJS ? tradesTitlesMap.toJS() : tradesTitlesMap;
  companies = companies.toJS ? companies.toJS() : companies;

  let regionColumnStructure = extractProjectsRegions(projects, NO_REGION_TEXT);
  companyTableStructure = companyTableStructure.concat(regionColumnStructure);

  let companyValidatePerProjectPerCompany = {}
  let tableDataObj = new TableDataObject();
  const companiesData = getSubcontractorsData(companies);
  Object.values(companiesData).forEach(companyData => {
    let companyActiveProjects = Object.keys(companyData.projects || {}).filter(projectId => projects.getNested([projectId]) && !projects.getNested([projectId, 'isDeleted']));
    if (companyActiveProjects.length == 0 && activeOnly) return;
    Object.entries(companyData.projects || {}).forEach(([projectId, obj]) => {
      const companyId = companyData.id;
      const isCurrProjectDeleted = obj.isDeleted;
      const isProjectBelongsToCompany = projects.getNested([projectId], false);
      if (isCurrProjectDeleted || !isProjectBelongsToCompany) return;
      if (!companyValidatePerProjectPerCompany[projectId])
        companyValidatePerProjectPerCompany[projectId] = {};

      if (!companyValidatePerProjectPerCompany[projectId][companyId])
        companyValidatePerProjectPerCompany[projectId][companyId] = true;
    });
  });


  Object.entries(companiesData || {}).forEach(([currCompanyId, currCompany]) => {
    const { name, projectIds } = currCompany;
    let companyActiveProjects = (projectIds || []).filter(projectId => projects.getNested([projectId]) && !projects.getNested([projectId, 'isDeleted']));
    if (companyActiveProjects.length == 0 && activeOnly) return;
    companyTableStructure.forEach(sectionStructure => {
      const { id: sectionId, title: sectionTitle, columns } = sectionStructure;
      if (!tableDataObj.sections[sectionId])
        tableDataObj.sections[sectionId] = new TableSection({ id: sectionId, title: sectionTitle });

      (columns).forEach(column => {
        const { id: columnId, title: columnTitle, isPrimary, valuesType, keyInMemberData, settings } = column;

        if (!tableDataObj.sections[sectionId].columns[columnId])
          tableDataObj.sections[sectionId].columns[columnId] = new TableColumn({ id: columnId, title: columnTitle, isPrimary, valuesType, settings });

        tableDataObj.sections[sectionId].columns[columnId].rows[currCompanyId] = new TableCell({ id: currCompanyId, value: {displayValue: name, displayType: valuesType, displayParams: {}}, title: name, objectType: 'company' });

        if (isPrimary) return;

        let companyVal;
        if (sectionId == 'subcontractorDetailsSection') {
          companyVal = null;
          if (columnId === tradesColumnId) {
            companyVal = Object.values(currCompany.tradeIds || {}).map(tradeId => (tradesTitlesMap[tradeId] || {}).getCementoTitle() || null).filter(Boolean).join(', ');
          }
          else if (columnId === projectsCountColumnId) {
            // let tempVal = 0;
            let companyProjects = (projectIds || []).filter(projectId => projects.getNested([projectId]) && !projects.getNested([projectId, 'isDeleted']));
            // Object.values(companyValidatePerProjectPerCompany || {}).forEach(row => row[currCompanyId] ? tempVal++ : null);
            companyVal = companyProjects.length;
          }
        }
        else {
          // companyVal = (companyValidatePerProjectPerCompany[columnId] || {})[currCompanyId] ? true : null;
          companyVal = _.get(currCompany.projects, columnId, false) ? true : null;
        }

        tableDataObj.sections[sectionId].columns[columnId].rows[currCompanyId].title = companyVal;
        tableDataObj.sections[sectionId].columns[columnId].rows[currCompanyId].value = {displayValue: companyVal, displayType: valuesType, displayParams: {}};
      });
    });
  });

  return tableDataObj;
}


export const getLocationsGroupsTableDataObj = (mainRows, groupsMap, groupsInstances, locationsMap, groupsSubTypes) => {
  const descriptionColumnId = 'locations-group-table-descriptionColumn';
  const locationsColumnId = 'locations-group-table-locationsColumn';
  const locationsCounterColumnId = 'locations-group-table-counterColumn'

  let locationsGroupTableStructure = [
    {
      id: 'aboutSection',
      title: usersMessages.details,
      columns: [
        { id: descriptionColumnId, title: systemMessages.description, isPrimary: true, keyInMemberData: ['title'] },
        { id: locationsCounterColumnId, title: systemMessages.locationsCounter, keyInMemberData: '', valuesType: propertyTypes.NUMBER },
        { id: locationsColumnId, title: analyticsMessages.locations, keyInMemberData: 'locations', valuesType: propertyTypes.STRING },
      ],
    }
  ];

  let tableDataObj = new TableDataObject();

  Object.entries(mainRows || {}).forEach(([currLocationTypeId, currLocationType]) => {
    locationsGroupTableStructure.forEach(sectionStructure => {
      const { id: sectionId, title: sectionTitle, columns } = sectionStructure;
      if (!tableDataObj.sections[sectionId])
        tableDataObj.sections[sectionId] = new TableSection({ id: sectionId, title: sectionTitle });

      (columns || []).forEach(column => {
        const { id: columnId, title: columnTitle, isPrimary, valuesType, keyInMemberData, settings } = column;

        if (!tableDataObj.sections[sectionId].columns[columnId])
          tableDataObj.sections[sectionId].columns[columnId] = new TableColumn({ id: columnId, title: columnTitle, isPrimary, valuesType, settings });

        tableDataObj.sections[sectionId].columns[columnId].rows[currLocationTypeId] = new TableCell({ id: currLocationTypeId, value: {displayValue: currLocationType.title, displayType: valuesType, displayParams: {}}, title: currLocationType.title, objectType: 'locationType', dismissClickHandler: true });

        Object.values(groupsMap || {}).forEach(group => {
          if (!group.types) return;
          let groupType = null;
          Object.keys(group.types || {}).forEach(type => {
            let currentType = _.get(groupsSubTypes, [type], false);
            if (currentType) {
              groupType = currentType.value;
              return;
            }
          });
          if (groupType !== currLocationTypeId || group.isDeleted) return;

          let val;
          let groupInstances = Object.values(groupsInstances).filter(loc => loc.data && loc.data[group.id]);
          if (columnId == descriptionColumnId)
            val = _.get(Object.values(group[keyInMemberData] || {}), 0, '');
          else if (columnId == locationsCounterColumnId) {
            val = groupInstances.length;
          }
          else if (columnId == locationsColumnId) {
            val = Array.from(groupInstances.map(loc => _.get(locationsMap, [loc.parentId, 'title'], '')));
            val = val.join(', ');
          }

          if (!tableDataObj.sections[sectionId].columns[columnId].rows[currLocationTypeId].subCells[group.id])
            tableDataObj.sections[sectionId].columns[columnId].rows[currLocationTypeId].subCells[group.id] = new TableCell({ id: group.id, value: {displayValue: val, displayType: valuesType, displayParams: {}}, title: val, objectType: 'locationsGroup' })
        })

        if (isPrimary) return;

        let groupTypeRow = null;
        tableDataObj.sections[sectionId].columns[columnId].rows[currLocationTypeId].title = groupTypeRow;
        tableDataObj.sections[sectionId].columns[columnId].rows[currLocationTypeId].value = groupTypeRow;
      })
    })
  })

  return tableDataObj;
}

const PROPERTIES_TYPES_WITH_GENERATED_COLUMNS = {
  [propertyTypes.ARRAY]: true,
  [propertyTypes.COMPLEX]: true,
}
export const getPropertiesTableDataObject = (originalRowData, filters, propertiesTypes, intl, subjectName) => {
  const isSubTable = _.get(filters, 'isSubTable', false);
  let tableDataObj = new TableDataObject({ isSubTable });
  const noData = !originalRowData || !Object.values(originalRowData).length;
  if (noData)
    return tableDataObj;

  const { data, groupByMetaData } = originalRowData;
  const { sections, properties, objects } = groupByMetaData;
  propertiesTypes = propertiesTypes.toJS ? propertiesTypes.toJS() : propertiesTypes;
  const visibilityColumns = filters && filters.columnVisibility;

  let sectionsOrder = {};

  _.values(propertiesTypes).forEach(property => {
    const isVisibleProperty = !PROPERTIES_TYPES_WITH_GENERATED_COLUMNS[property.type] && _.get(visibilityColumns, [property.id, 'table']);
    if (property.id === 'groups' || !property.isPrimary && !isVisibleProperty)
    return;
    
    const { id: currentPropertyId, ordinalNo: currentPropertyOrderNum, type: currentPropertyPropertyType, isPrimary, universalId, settings, values: propertyValues, businessType, isDynamicallyGenerated } = property;
    const currentPropertySectionId = property.sectionId;
    const currentPropertySectionTitle = _.get(sections, [currentPropertySectionId, 'title'], '');

    if (!tableDataObj.sections[currentPropertySectionId]) {
      sectionsOrder[currentPropertySectionId] = currentPropertyOrderNum;
      tableDataObj.sections[currentPropertySectionId] = new TableSection({
        id: currentPropertySectionId,
        title: currentPropertySectionTitle,
        num: currentPropertyOrderNum || sectionsOrder[currentPropertySectionId]
      });
    }
    else if (sectionsOrder[currentPropertySectionId] > currentPropertyOrderNum)
      sectionsOrder[currentPropertySectionId] = currentPropertyOrderNum;

    if (!tableDataObj.sections[currentPropertySectionId].columns[currentPropertyId])
    tableDataObj.sections[currentPropertySectionId].columns[currentPropertyId] = new TableColumn({
      id: currentPropertyId,
      title: property.getCementoTitle(),
      valuesType: currentPropertyPropertyType,
      num: currentPropertyOrderNum,
      isPrimary,
      universalId,
      settings,
      valueOptions: propertyValues,
      businessType,
      isDynamicallyGenerated,
      pathInObject: property.pathInObject,
    });  
  });
  const shouldDismissRowClick = isSubTable;
  let isCollapsableTable = false;
  let rowsWithValues = {};
  data.forEach(instance => {
    const { rowId: instanceRowId, columnId: instanceColumnId, values: instanceValues, valueOrigin: instanceValueOrigin, parentValueOrigin: instanceParentValueOrigin } = instance;
    const instanceColumnObject = properties[instanceColumnId];
    let fullInstancePropertyObject = instanceColumnObject.isDynamicallyGenerated ? instanceColumnObject : propertiesTypes[instanceColumnId];
        fullInstancePropertyObject = Object.assign({}, fullInstancePropertyObject, { subjectName });

    const isColumnVisible = Boolean(
      (instanceColumnObject.isAlwaysDisplayColumn)                                                                              ||
      (instanceColumnObject.isDynamicallyGenerated && _.get(visibilityColumns, [instanceColumnObject.originColumnId, 'table'])) || 
      (_.get(visibilityColumns, [instanceColumnId, 'table']))
    );
    const instanceObject = objects[instanceRowId];
    
    if (instanceColumnId === 'groups' || !instanceObject || (!instanceColumnObject.isPrimary && !isColumnVisible))
      return;

    const { id: propertyId , title: propertyTitle, parentId: sectionId, num: propertyOrderNum, type: propertyType, isPrimary, settings, values: propertyValues, businessType, isDynamicallyGenerated } = instanceColumnObject;
    const instanceSectionObject = sections[sectionId];
    
    
    if (!tableDataObj.sections[sectionId]) {
      sectionsOrder[sectionId] = propertyOrderNum;
      tableDataObj.sections[sectionId] = new TableSection({
        id: sectionId,
        title: instanceSectionObject.title,
        num: instanceSectionObject.num || sectionsOrder[sectionId]
      });
    }
    else if (sectionsOrder[sectionId] > propertyOrderNum)
        sectionsOrder[sectionId] = propertyOrderNum;

    
    if (!tableDataObj.sections[sectionId].columns[instanceColumnId])
      tableDataObj.sections[sectionId].columns[instanceColumnId] = new TableColumn({
        id: propertyId,
        title: propertyTitle,
        isPrimary,
        valuesType: propertyType,
        num: propertyOrderNum,
        universalId: instanceColumnObject.universalId,
        settings,
        valueOptions: propertyValues,
        businessType,
        isDynamicallyGenerated,
        pathInObject: instanceColumnObject.pathInObject,
      });

    let valueForCellContentCalculator = _.head(instanceValues);
    valueForCellContentCalculator = valueForCellContentCalculator === 0 ? null : valueForCellContentCalculator;
    let cellValue = cellContentCalculator(valueForCellContentCalculator, fullInstancePropertyObject, intl, instanceObject, instanceValueOrigin, instanceParentValueOrigin);
    
    
    const instanceParentId = instanceObject && instanceObject.parentId;
    if (!isPrimary && !rowsWithValues[instanceRowId] && !isEmptyValue(cellValue[ORIGINAL_VALUE_KEY_TERM])) {
      rowsWithValues[instanceRowId] = true;
      if (instanceParentId)
        rowsWithValues[instanceParentId] = true
    }

    if (instanceParentId) {
      if (!tableDataObj.sections[sectionId].columns[instanceColumnId].rows[instanceParentId]) {
        const parentRow = objects[instanceParentId];
        const parentValuePathInObject = isPrimary && !_.isNil(parentRow?._aggregatedValue) 
          ? '_aggregatedValue'
          : instanceColumnObject.pathInObject || 'title';
        const parentCellValue = { 
          displayValue: _.get(parentRow, parentValuePathInObject) || '', 
          displayType: (isPrimary ? parentRow._aggregatedValueType : null) || propertyTypes.STRING, 
          displayParams: (isPrimary ? parentRow._aggregatedValueDisplayParams : null) || {},
        };
        tableDataObj.sections[sectionId].columns[instanceColumnId].rows[instanceParentId] = new TableCell({ id: instanceParentId, value: parentCellValue, title: parentCellValue.displayValue, ordinalNum: parentRow.num, dismissClickHandler: true});      
        if (!isCollapsableTable)
          isCollapsableTable = true;
      }

      if (!tableDataObj.sections[sectionId].columns[instanceColumnId].rows[instanceParentId].subCells[instanceRowId])
        tableDataObj.sections[sectionId].columns[instanceColumnId].rows[instanceParentId].subCells[instanceRowId] = new TableCell({ id: instanceRowId, value: cellValue, title: cellValue.displayValue, ordinalNum: instanceObject.num, dismissClickHandler: shouldDismissRowClick});
    }
    else
      if (!tableDataObj.sections[sectionId].columns[instanceColumnId].rows[instanceRowId])
        tableDataObj.sections[sectionId].columns[instanceColumnId].rows[instanceRowId] = new TableCell({ id: instanceRowId, value: cellValue, title: cellValue.displayValue, ordinalNum: instanceObject && instanceObject.num, dismissClickHandler: shouldDismissRowClick});
  });

  tableDataObj.isCollapsableTable = isCollapsableTable;

  const shouldRemoveRowsWithoutSubRows = isCollapsableTable && isSubTable;
  if (shouldRemoveRowsWithoutSubRows) {
    let primaryColumn = null;
    const allColumns = _.flatten(_.values(tableDataObj.sections).map(section => _.values(section.columns)));
    allColumns.forEach(column => {
      if (column.isPrimary) {
        primaryColumn = column;
        return;
      }

      _.values(column.rows).forEach(row => {
        if (!rowsWithValues[row.id] || isEmptyValue(row.subCells))
          delete column.rows[row.id];
      });
    });

    if (primaryColumn)
      _.values(primaryColumn.rows).forEach(row => {
        if (!rowsWithValues[row.id])
          delete primaryColumn.rows[row.id]
        else
          _.values(row.subCells).forEach(subRow => {
            if (!rowsWithValues[subRow.id])
              delete primaryColumn.rows[row.id].subCells[subRow.id];
          });
      });
  }

  const shouldSetCounterRows = isCollapsableTable && _.get(filters, 'shouldSetCounterRows', true) && !isSubTable;
  // calculate parent rows to be a counter rows
  if (shouldSetCounterRows)
    Object.values(tableDataObj.sections).sort((sectionA, sectionB) => sectionsOrder[sectionA.id] - sectionsOrder[sectionB.id]).forEach(section => {
      Object.values(section.columns).forEach(column => {
        if (column.pathInObject)
          return;

        Object.values(column.rows).forEach(row => {
          let rowSubRows = row.subCells || {};
          let subRowsValuesCounter = {};
          if (Object.keys(rowSubRows).length) {
            tableDataObj.sections[section.id].columns[column.id].rows[row.id].value = {displayValue: row.title, displayType: 'String', displayParams: {}};
            Object.values(rowSubRows).forEach(subRow => {
              let subRowValue = subRow.value.displayValue;
              _.set(subRowsValuesCounter, [column.id, 'total'], _.get(subRowsValuesCounter, [column.id, 'total'], 0)+1);
              if (!_.isNull(subRowValue)){
                if (typeof subRowValue == 'string' && !subRowValue.length) return;
                _.set(subRowsValuesCounter, [column.id, 'exists'], _.get(subRowsValuesCounter, [column.id, 'exists'], 0)+1);
              }
            })
            Object.values(subRowsValuesCounter).forEach(values => {
              tableDataObj.sections[section.id].columns[column.id].rows[row.id].value = {displayValue: ( values.exists || 0 ) + '/' + values.total , displayType: 'String', displayParams: {direction: 'ltr', isCounterRow: true}};
              tableDataObj.sections[section.id].columns[column.id].rows[row.id].dismissClickHandler = true;

            })
          }
        })
      })
    })
  
  return tableDataObj;
}