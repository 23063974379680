
import propertiesMessages from "./propertiesMessages";
import * as propertyTypes from './propertiesTypes';
import { platformActions } from "../platformActions";
import _ from 'lodash';
import { envParams } from "../configureMiddleware";

export function getSortFunc(type, columnId) {

  const nullOrUndefinedCompare = (a, b, compareFunc) => {
    if (typeof(a['values'][columnId]) != 'undefined' && typeof(b['values'][columnId]) == 'undefined')
      return -1;
    else if (typeof(a['values'][columnId]) == 'undefined' && typeof(b['values'][columnId]) != 'undefined')
      return 1;
    else if (typeof(a['values'][columnId]) == 'undefined' && typeof(b['values'][columnId]) == 'undefined')
      return (String(a.title || "").localeCompare(String(b.title || "")));
    else if (!Boolean(a['values'][columnId]) && Boolean(b['values'][columnId]))
      return 1;
    else if (Boolean(a['values'][columnId]) && !Boolean(b['values'][columnId]))
      return -1;
    else
      return compareFunc(a,b);
  }

  switch (type) {
    case propertyTypes.STRING: {
      return (x, y) => nullOrUndefinedCompare(x, y, (a, b) => {
        return (a ? a.getNested(['values', columnId], "") : "").localeCompare(b ? b.getNested(['values', columnId], "") : "");
      });
    }    
    case propertyTypes.PICTURE: 
    case propertyTypes.PDF: 
    case propertyTypes.FILES_ARRAY:
    case propertyTypes.DRAWINGS_ARRAY: {
      return (x, y) => nullOrUndefinedCompare(x, y, (a, b) => {
        if (a.getNested(['values', columnId ,"length"], 0) == b.getNested(['values', columnId ,"length"], 0))
          return 0;
        else if (a.getNested(['values', columnId ,"length"], 0) < b.getNested(['values', columnId ,"length"], 0))
          return 1;
        else
          return -1;            
      });
    }                 
    case propertyTypes.SELECTION_LIST: {
      return (x, y) => nullOrUndefinedCompare(x, y, (a, b) => {
        if (Object.keys(a['values'][columnId] || {" ":""})[0] == Object.keys(b['values'][columnId] || {" ":""})[0])
          return 0;
        else if (Object.keys(a['values'][columnId] || {" ":""})[0] < Object.keys(b['values'][columnId] || {" ":""})[0])
          return 1;
        else
          return -1;
      });
    }   
    case propertyTypes.CERTIFICATION: {
      return (x, y) => nullOrUndefinedCompare(x, y, (a, b) => {
        if (Object.values(a['values'][columnId] || {" ":{certificationTTL:0}})[0].certificationTTL == Object.values(b['values'][columnId] || {" ":{certificationTTL:0}})[0].certificationTTL)
          return 0;
        else if (Object.values(a['values'][columnId] || {" ":{certificationTTL:0}})[0].certificationTTL < Object.values(b['values'][columnId] || {" ":{certificationTTL:0}})[0].certificationTTL) {
          return 1;
         } else {
          return -1;
         }
      });
    } 
    case propertyTypes.BOOLEAN: {            
      return (x, y) => nullOrUndefinedCompare(x, y, (a, b) => {
        if (a['values'][columnId] === b['values'][columnId])
          return 0;
        return (a['values'][columnId]) ? -1 : 1;
      });
    } 
    case propertyTypes.NUMBER: {
      return (x, y) => nullOrUndefinedCompare(x, y, (a, b) => {
        if (parseInt(a['values'][columnId] || 0) == parseInt(b['values'][columnId] || 0))
          return 0;
        else if (parseInt(a['values'][columnId] || 0) < parseInt(b['values'][columnId] || 0))
          return 1;
        else
          return -1;
      });
    }    
    case propertyTypes.DATE: 
    default: {
      return (x, y) => nullOrUndefinedCompare(x, y, (a, b) => {
        if (a['values'][columnId] == b['values'][columnId])
          return 0;
        else if (a['values'][columnId] < b['values'][columnId])
          return 1;
        else
          return -1;
      });
    }      
  }

  return null;
}


let numberRegExp = new RegExp('^[0-9]+$')
export function checkError(value, prop, subjectProperties, intl) {
  let errorMessages = [];

  if (!prop)
    return null;

  let propName = prop.getNested(['getTitle']) || prop.getNested(['title']) || '';
  if (propName && typeof propName === 'object' && propName.defaultMessage)
    propName = intl.formatMessage(propName);
    
	if (prop.mandatory && (value == undefined || value == null || String(value) == ''))
    errorMessages.push(intl.formatMessage(propertiesMessages.errors.mandatory, {propName}));
  
  if (prop.extraTypes && subjectProperties && prop.type == 'Certification' && value && Array.isArray(value)) {
    let primaryValue = value;
    primaryValue = value[value.length - 1];
    if (primaryValue != undefined && primaryValue != null) { 
      prop.extraTypes.forEach(extraProp => { 
        let extraPropId = extraProp && extraProp.id ? extraProp.id : extraProp;
        let lastCert = (value || []).slice();
        lastCert = lastCert[lastCert.length - 1] || {};
        let extraValue = lastCert[extraPropId];
        let errors = checkError(extraValue, subjectProperties[extraPropId], subjectProperties, intl);
        if (errors)
          errorMessages = errorMessages.concat(errors);
      });
    }
  }

	if (errorMessages.length > 0)
		return errorMessages;
		
	let type = prop.type;
  switch (type) {
    case propertyTypes.STRING: {
      const propertyRegex = _.get(prop, ['settings', 'validation', 'regex']);
      if (propertyRegex) {
        const regexExp = new RegExp(propertyRegex);
        if (regexExp.test(value))
          errorMessages.push(intl.formatMessage(propertiesMessages.errors.forbiddenChars, {propName}));  
      }
      break;
    }
		case propertyTypes.NUMBER: {
      if (value != null && value != undefined && !numberRegExp.test(value))
        errorMessages.push(intl.formatMessage(propertiesMessages.errors.numbersOnly, {propName}));
      break;
    }
    case propertyTypes.CERTIFICATION: {
      let lastCert = null;
      if (value && Array.isArray(value))
        lastCert = value[value.length - 1];
       
      if (!lastCert || (!lastCert.certificationTTL && _.get(prop, ['settings', 'isExpiration']))) 
        errorMessages.push(intl.formatMessage(propertiesMessages.errors.mustHaveTTL, { propName }));
 
      if (lastCert && _.get(prop, ['settings', 'isFileMandatory']) && lastCert.signatureBehaviour !== 'cementoSign' && !(lastCert.attachments || []).length) // Missing file
        errorMessages.push(intl.formatMessage(propertiesMessages.errors.mustHaveFile));

      break;
    }

    case propertyTypes.TIME_RANGE: {
      if (value) {
        if (!(value.startTS && value.endTS))
          errorMessages.push(intl.formatMessage(propertiesMessages.errors.mustHaveStartAndEndTime, { propName }));

        if (value.startTS > value.endTS)
          errorMessages.push(intl.formatMessage(propertiesMessages.errors.rangeEndMustBeBiggerThanStart, { propName }));
      }

      break;
    }
    case propertyTypes.STRING: 
    case propertyTypes.PICTURE: 
    case propertyTypes.PDF: 
    case propertyTypes.DRAWINGS_ARRAY: 
    case propertyTypes.FILES_ARRAY:
    case propertyTypes.SELECTION_LIST: 
    case propertyTypes.BOOLEAN:
    case propertyTypes.DATE: 
    case propertyTypes.SIGNATURE:
    default: {
    }      
  }

  return (errorMessages && errorMessages.length) ? errorMessages : null;
}

export const validatePropType = (propOrType) => {
  let isValid = false;

  let typeToCheck = propOrType;
  if (!typeToCheck)
    return isValid;
  
  if (propOrType instanceof Object)
    typeToCheck = propOrType.type;
  
  if (propertyTypes.VALID_PROPERTIES_ARRAY.includes(typeToCheck))
    isValid = true;

  return isValid;
}

export async function getCompanyPropertiesTypesAndSections(companyId) {
  const firebase = platformActions.firebase.getFirebase();
  const companySubjects = (await firebase.database().ref(`properties/types/companies/${companyId}`).once('value')).val() || {};

  let companySubjectSections = {};
    let companySubjectProperties = {};
    Object.entries(companySubjects).forEach(([subjectName, propsAndSections]) => {
      companySubjectSections[subjectName] = propsAndSections.sections || {};
      companySubjectProperties[subjectName] = propsAndSections.properties || {};
    });

    return {
      companySubjectSections,
      companySubjectProperties,
    }
}

export async function updateTagsOptions(newOptionMap) {
  const firebase = platformActions.firebase.getFirebase();
  let dbUpdates = {};

  _.keys(newOptionMap || {}).forEach(tagOption => {
    dbUpdates[`/quasiStatics/global/tags/${tagOption}`] = true;
  })
  if (_.keys(dbUpdates).length) {
    try {
      await firebase.database().ref().update(dbUpdates); 
    } catch (error) {
      console.error(error);
    }
  }
}

export async function getAllAvailableTags() {
  const firebase = platformActions.firebase.getFirebase();
  const allTags = (await firebase.database().ref('quasiStatics/global/tags').once('value')).val() || {};

  return allTags;
}

export async function getProjectsSelectionListValues(projectId) {
  let resp = await platformActions.net.fetch(`${envParams.apiServer}/v1/valuesLists?projectId=${projectId}`);
  return resp.json();
}

export const getBaseSelectionListId = (propId) => `${propId}_valuesList`;// if you change this function, change it also in apiServer!!!
 
export const filterIrrelevantLocationExtraData = ({ groupId, extraData, propertiesMappings, forceShowAllExtraData }) => {
  if (forceShowAllExtraData)
    return extraData;
    
  return _.pickBy(extraData, (prop, propId) => {
    return prop.subjectName !== 'locationsInfo' || _.get(propertiesMappings, [prop.subjectName, 'groups', groupId, 'properties'], []).includes(propId);
  });
};
