import React from "react";
import { connect } from "react-redux";
import { connectContext } from "react-connect-context";
import {
  ProjectContext,
  ProjectManagerContext,
} from "../../../common/projects/contexts";
import { ChecklistPageContext } from "../../../common/checklists/contexts";
import withStyles from "@material-ui/core/styles/withStyles";
import { compose } from "recompose";
import SplitViewPage from "../../layouts/SplitViewPage";
import Checklists from "./Checklists";
import buttonStyle from "../../assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";
import _ from 'lodash';
import ScopeSelector from "../ProjectManager/ScopeSelector";
import Modal from "../../components/CementoComponents/Modal";

class ChecklistPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleSideClose = this.handleSideClose.bind(this);
    this.setComponentData = this.setComponentData.bind(this);
    this.recalcHeader = this.recalcHeader.bind(this);
    this.setChecklistsCompleteInfo = this.setChecklistsCompleteInfo.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.setMergerResult = this.setMergerResult.bind(this);
    this.setUpsertTargetHandler = this.setUpsertTargetHandler.bind(this);
    this.openSelectUpsertTarget = this.openSelectUpsertTarget.bind(this);

    let locationsDataType = this.props.match.params.unitId ? 'unit' : (this.props.match.params.floorId ? 'floor' : 'building');
    this.state = {
      locationsData: {
        buildingId: this.props.match.params.buildingId,
        floorId: this.props.match.params.floorId,
        unitId: this.props.match.params.unitId,
        type: locationsDataType,
      },
      selectedStage: null,
      selectedChecklist: null,
      selectedChecklistItem: null,
      isModalOpen: false
    };
  }

  onRowClick(
    selectedStage,
    selectedChecklist,
    selectedChecklistItem,
    locationId,
    isNewLocation = false
  ) {
    const { onLocationItemChange } = this.props;
    let updates = {
      selectedStage,
      selectedChecklist,
      selectedChecklistItem,
      selectedLocationId: locationId,
      isNewLocation,
    };
    if (onLocationItemChange) onLocationItemChange(updates);
    this.setState(updates);
  }

  setChecklistsCompleteInfo(checklistsCompleteInfo) {
    const { onChecklistsCompleteInfoChange } = this.props;

    this.setState({ checklistsCompleteInfo });
    if (onChecklistsCompleteInfoChange)
      onChecklistsCompleteInfoChange(checklistsCompleteInfo);
  }

  recalcHeader() {
    const { setHeaderParams } = this.props;
    if (setHeaderParams)
      setHeaderParams({
        headerComponent: <></>,
        sideBarParams: { open: false },
      });
  }

  UNSAFE_componentWillMount() {
    this.recalcHeader();
    this.setComponentData({}, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  setComponentData(props, nextProps) {
    const { changedOccurred, mergedObjects, didFetchedOnce, selectedStage } = this.state;
    let newStateChanges = {};
    let shouldUpdateSideCard = false;

    if (
      props.isValDiff(nextProps, ["match", "params", "unitId"]) ||
      props.isValDiff(nextProps, ["match", "params", "floorId"]) ||
      props.isValDiff(nextProps, ["match", "params", "buildingId"])
    ) {
      let location = {
        buildingId: nextProps.match.params.buildingId,
        floorId: nextProps.match.params.floorId,
        unitId: nextProps.match.params.unitId,
      };
      const nextPropsUnitId = _.get(nextProps, ['match', 'params', 'unitId'], '');
      const nextPropsFloorId = _.get(nextProps, ['match', 'params', 'floorId'], '');
      const type = nextPropsUnitId !== '_' ? 'unit' : nextPropsFloorId !== '_' ? 'floor' : 'building';
      newStateChanges.selectedLocationId = location[`${type}Id`];
      newStateChanges.locationsData = {
        type,
        ...location,
      };
    }
    shouldUpdateSideCard = (selectedStage && props.isValDiff(nextProps, ["stages", selectedStage.id]));


    if (Object.values(newStateChanges).length || shouldUpdateSideCard)
      this.setState((prevState) => {
        const { selectedStage, selectedChecklist, selectedChecklistItem } =
          prevState;
        if (
          (newStateChanges.selectedLocationId ||
            prevState.selectedLocationId) &&
          newStateChanges.selectedLocationId != prevState.selectedLocationId
        ) {
          newStateChanges.selectedStage = null;
          newStateChanges.selectedChecklist = null;
          newStateChanges.selectedChecklistItem = null;
        } else {
          if (selectedStage) {
            let currSelectedStageId = selectedStage.getNested(["id"]);
            if (props.isValDiff(nextProps, ["stages", currSelectedStageId])) {
              newStateChanges.selectedStage = nextProps.getNested([
                "stages",
                currSelectedStageId,
              ]);
            }
          }

          if (selectedChecklist) {
            let currSelectedChecklistId = selectedChecklist.getNested(["id"]);
            if (
              props.isValDiff(nextProps, [
                "checklists",
                currSelectedChecklistId,
              ])
            )
              newStateChanges.selectedChecklist = nextProps.getNested([
                "checklists",
                currSelectedChecklistId,
              ]);
          }

          if (selectedChecklistItem) {
            let currSelectedChecklistItemId = selectedChecklistItem.getNested([
              "id",
            ]);
            if (
              props.isValDiff(nextProps, [
                "checklistItems",
                currSelectedChecklistItemId,
              ])
            )
              newStateChanges.selectedChecklistItem = nextProps.getNested([
                "checklistItems",
                currSelectedChecklistItemId,
              ]);
          }
        }

        return newStateChanges;
      });
  }

  handleSideClose() {
    let updates = {
      selectedStage: null,
      selectedChecklist: null,
      selectedChecklistItem: null,
      selectedLocationId: null,
    };

    const { onLocationItemChange } = this.props;
    if (onLocationItemChange) onLocationItemChange(updates);

    this.setState(updates);
  }

  setMergerResult(mergerResultMap) {
    if (_.isEmpty(mergerResultMap))
      return;
    
    let objectsSources = {};
    _.entries(mergerResultMap.objectsSources || {}).forEach(([key, value]) => _.set(objectsSources, key.split('/'), value));
    delete mergerResultMap.objectsSources;
    this.setState({mergedObjects: mergerResultMap, objectsSources});
  }

  async setUpsertTargetHandler(scope, scopeId) {
    const { onUpsertObjectClick } = this.props;
    
    if (onUpsertObjectClick) await onUpsertObjectClick(scope, scopeId);
  }

  openSelectUpsertTarget() {
    this.setState({ isModalOpen: true });
  }

  render() {
    const { rtl, uiParams, isChecklistManager, isEditMode, trades, checklists, checklistItems, projectCompanies, setChecklistsMappedByStage, inDraftMode, currProjectId, stages } = this.props;
    let { selectedProjectId } = this.props;

    const { locationsData, selectedStage, selectedChecklist, selectedChecklistItem, selectedLocationId, checklistsCompleteInfo, isNewLocation, mergedObjects, objectsSources, isModalOpen } = this.state;
    let size = uiParams.getNested(['screenBootstrapWidth'])
    let maxChars = size == 'xl' ? 100 : size == 'lg' ? 70 : size == 'md' ? 30 : 20;
    let sideCardType = null;
    let sideCardObject = null;

    let sideCardProps = {
      stage: selectedStage,
      checklist: selectedChecklist,
      checklistItem: selectedChecklistItem,
      locationId: selectedLocationId,
      focusOnInput: isNewLocation,
      locationsData,
    };

    if (
      isChecklistManager &&
      (selectedChecklistItem || selectedChecklist || selectedStage)
    )
      sideCardType = "checklistManager";
    else if (selectedChecklistItem && !inDraftMode)
      sideCardType = "checklistItem";
    else if (selectedChecklist && !inDraftMode) {
      sideCardType = "checklists";
      sideCardProps.selectedChecklists = { [selectedChecklist.id]: true };
    }
    // else if (selectedStage)
    //   sideCardType = 'stage';

    sideCardObject = {
      type: sideCardType,
      props: sideCardProps,
    };

    selectedProjectId = currProjectId ? currProjectId : selectedProjectId;

    return (
      <ChecklistPageContext.Provider value={{
        isChecklistManager,
        isEditMode,
        selectedProjectId,
        trades,
        checklistsCompleteInfo,
        projectCompanies,
        sideCardClosed: !Boolean(selectedStage),
        checklists,
        checklistItems,
        objectsSources,
        stages,
        setChecklistsCompleteInfo: this.setChecklistsCompleteInfo,
        openSelectUpsertTarget: this.openSelectUpsertTarget,
      }}>
        <Modal open={isModalOpen} onClose={() => this.setState({isModalOpen: false})}>
          <div style={{padding: 20}}>
            <ScopeSelector buttonText={'Upsert'} submitHandler={this.setUpsertTargetHandler}/>
          </div>
        </Modal>

        <SplitViewPage
          rtl={rtl}
          onSideClose={this.handleSideClose}
          SideStack={Boolean(sideCardObject) && [sideCardObject]}
          Main={
            <div style={{}}>
              <Checklists
                maxCharsInItem={Boolean(sideCardObject) ? maxChars : null}
                minMode={false}
                locationsData={locationsData}
                onClick={this.onRowClick}
                setChecklistsMappedByStage={setChecklistsMappedByStage}
              />
            </div>
          }
        />
      </ChecklistPageContext.Provider>
    );
  }
}

ChecklistPage = withStyles(buttonStyle)(ChecklistPage);
const enhance = compose(
  connectContext(ProjectManagerContext.Consumer),
  connectContext(ProjectContext.Consumer),
  connect(
    (state) => ({
      uiParams: state.ui.uiParams,
      inDraftMode: state.ui.inDraftMode,
    }),
    {}
  )
);
export default enhance(ChecklistPage);
