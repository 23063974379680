import React from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { compose, hoistStatics } from "recompose";
import { connectContext } from "react-connect-context";
import { PrintOutlined, BarChart } from "@material-ui/icons";
import Datetime from "react-datetime";
import moment from "moment";
import FormControl from "@material-ui/core/FormControl";
import { ProjectContext, FiltersSortsContext } from '../../../common/projects/contexts';
import * as issueStates from '../../../common/issues/issueStates';
import * as propertyTypes from '../../../common/propertiesTypes/propertiesTypes';
import { GridContainer, GridItem } from '../../components';
import issuesMessages from "../../../common/issues/issuesMessages";
import Posts from "./Posts.js";
import Sorts from "../Menus/Sorts";
import theme from "../../assets/css/theme";
import "../../assets/css/slider.css";
import FilterStatusLine from "../../components/CementoComponents/FilterStatusLine";
import { draftValidator, saveURLParams } from "../../../common/ui/actions";
import { exportPostsPDF } from "../../../common/posts/actions";
import { startToast } from "../../../common/app/actions";
import notificationsStyle from "../../assets/jss/material-dashboard-pro-react/views/notificationsStyle.jsx";
import validationFormsStyle from "../../assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import view_blocks_active from "../../assets/img/tasks/view_blocks_active.png";
import view_lines_active from "../../assets/img/tasks/view_lines_active.png";
import view_lines from "../../assets/img/tasks/view_lines.png";
import view_blocks from "../../assets/img/tasks/view_blocks.png";
import SplitViewPage from "../../layouts/SplitViewPage";
import systemMessages from "../../../common/app/systemMessages";
import Text from "../../components/CementoComponents/Text";
import postsMenuMessages from "../../../common/posts/postsMenuMessages";
import postsMessages from "../../../common/posts/postsMessages";
import newPostMessages from "../../../common/posts/newPostMessages";
import { lokiInstance } from "../../../common/configureMiddleware";

import pdfMessages from "../../../common/app/pdfMessages";
import TextFilter from "./TextFilter";
import ImageCarousel from "../../components/CementoComponents/ImageCarousel";
import FilterMenuHOC from "../../components/CementoComponents/FilterMenu";
import _ from "lodash";
import safetyMessages from "../../../common/safety/safetyMessages";
import analyticsMessages from "../../../common/analytics/analyticsMessages";
import { injectIntl } from "react-intl";
import AddNewButton from "../../components/CementoComponents/AddNewButton";
import linksMessages from "../../../common/app/linksMessages";
import { safeToJS } from "../../../common/permissions/funcs";
import { ALL_BUILDINGS_ID } from "../../../common/app/constants";
import { POSTS_FILTER_URL_KEY } from "../../app/constants";

const maxExpandablePosts = 100;

const minPostWidth = 460;
export const postRowHeight = 195;
export const postRowHeightLines = 110;
export const sectionTitleHeight = 50;
const defaultExpandAll = false;

const membersToOptions = (members) => {
  members = members && members.toJS ? members.toJS() : members;

  let membersOptionsById = {};
  Object.values(members || {}).forEach((member) => {
    const option = { id: member.id, title: member.displayName };
    _.set(membersOptionsById, [member.id], option);
  });

  return membersOptionsById;
};

const companiesToOptions = (companies) => {
  companies = companies && companies.toJS ? companies.toJS() : companies;

  let companiesOptionsById = {};
  Object.values(companies).forEach((company) => {
    const option = { id: company.id, title: company.name };
    _.set(companiesOptionsById, [company.id], option);
  });

  return companiesOptionsById;
};

const tradesToOptions = (trades) => {
  trades = trades && trades.toJS ? trades.toJS() : trades;

  let companiesOptionsById = {};
  Object.values(trades || {}).forEach((trade) => {
    const option = { id: trade.id, title: trade.getCementoTitle() };
    _.set(companiesOptionsById, [trade.id], option);
  });

  return companiesOptionsById;
};

const subCategoriesToOptions = (subCategories) => {
  subCategories = safeToJS(subCategories);

  let subCategoriesOptionsById = {};

  _.values(subCategories).forEach((subCategoriesGroup) => {
    _.values(subCategoriesGroup).forEach((subCategory) => {
      const option = {
        id: subCategory.id,
        title: subCategory.getCementoTitle(),
      };
      _.set(subCategoriesOptionsById, option.id, option);
    });
  });

  return subCategoriesOptionsById;
};

class PostsPage extends React.Component {
  constructor(props) {
    super(props);
    this.today = new Date().getTime();
    this.handleEditModeChange = this.handleEditModeChange.bind(this);
    this.getContainerRef = this.getContainerRef.bind(this);
    this.getScrollRef = this.getScrollRef.bind(this);
    this.getPostsListRef = this.getPostsListRef.bind(this);
    this.onWidthChange = this.onWidthChange.bind(this);
    this.calculatePostPerRow = this.calculatePostPerRow.bind(this);
    this.onRelativeDateChange = this.onRelativeDateChange.bind(this);
    this.onSortChangeDirection = this.onSortChangeDirection.bind(this);
    this.onCollapseExpandClick = this.onCollapseExpandClick.bind(this);
    this.lokiPostsListener = this.lokiPostsListener.bind(this);
    this.onAllGroupsCollapseOrExpand =
      this.onAllGroupsCollapseOrExpand.bind(this);
    this.exportAllSelectedGroupPosts =
      this.exportAllSelectedGroupPosts.bind(this);
    this.handleExportSelectionModeChange =
      this.handleExportSelectionModeChange.bind(this);
    this.closeSelectedPost = this.closeSelectedPost.bind(this);
    this.addPost = this.addPost.bind(this);
    this.setComponentData = this.setComponentData.bind(this);
    this.onPostSelect = this.onPostSelect.bind(this);
    this.blocksMode = this.blocksMode.bind(this);
    this.linesMode = this.linesMode.bind(this);
    this.chartMode = this.chartMode.bind(this);
    this.recalcHeader = this.recalcHeader.bind(this);

    this.state = {
      relativeDate: this.today,
      sortDirection: 1,
      scrollToTop: 0,
      postsPerRow: 0,
      postsArray: [],
      groupKeys: {},
      imagesModalObject: null,
      selectedPost: null,
      exportSelectionMode: false,
      expandAll: props.hasOwnProperty("expandAll")
        ? props.expandAll /* props.postsArray.length < maxExpandablePosts */
        : defaultExpandAll,
      viewMode: "blocks",
    };
  }

  handleEditModeChange(isEditMode) {
    this.setState({ isEditMode });
  }

  lokiPostsListener(collectionName) {
    const { selectedPost, isEditMode } = this.state;
    if (collectionName == 'posts' && selectedPost && !isEditMode) {
      const updatedSelectedPost = this.lokiPosts.cementoFind({id: _.get(selectedPost, ['id'])});
      this.setState({ selectedPost: _.head(updatedSelectedPost) });
    }
  }

  componentWillUnmount() {
    this.lokiPosts.cementoOff("postsPageListener");
    this.props.saveURLParams({ page: null });
  }

  componentDidMount() {
    window.addEventListener(
      "resize",
      (() => {
        this.setState(this.onWidthChange());
      }).bind(this)
    );
    this.lokiPosts = lokiInstance.getCollection('posts');
    this.lokiPosts.cementoOn("postsPageListener", this.lokiPostsListener);
    this.setComponentData({ firstMount: true }, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (!prevState.selectedPost && this.state.selectedPost) ||
      (prevState.selectedPost && !this.state.selectedPost)
    )
      this.setState({ postsPerRow: this.calculatePostPerRow() });

    var shouldHeaderComponentUpdate =
      this.state != prevState ||
      this.props.isValDiff(prevProps, ["uiParams"]) ||
      this.props.isValDiff(prevProps, ["data"]) ||
      this.props.isValDiff(prevProps, ["location"]) ||
      this.props.isValDiff(prevProps, ["sorts"]) ||
      this.props.isValDiff(prevProps, ["selectedProjectId"]) ||
      this.props.isValDiff(prevProps, ["itemsMode"]) ||
      this.props.isValDiff(prevProps, ["rtl"]) ||
      this.props.filterVal !== prevProps.filterVal;

    if (shouldHeaderComponentUpdate) this.recalcHeader();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state != nextState) return true;

    const shouldComponentUpdate =
      this.props.isValDiff(nextProps, ["uiParams"]) ||
      !_.isEqual(
        (this.props.match || {}).params,
        (nextProps.match || {}).params
      ) ||
      this.props.isValDiff(nextProps, ["data"]) ||
      this.props.isValDiff(nextProps, ["location"]) ||
      this.props.isValDiff(nextProps, ["sorts"]) ||
      this.props.isValDiff(nextProps, ["selectedProjectId"]) ||
      this.props.isValDiff(nextProps, ["itemsMode"]) ||
      this.props.isValDiff(nextProps, ["rtl"]) ||
      this.props.isValDiff(nextProps, ["postsArray", "length"]);

    return shouldComponentUpdate;
  }

  setComponentData(props, nextProps) {
    const { scrollToTop, selectedPost, isEditMode } = this.state;
    let newStateChanges = {};

    if (
      props.firstMount ||
      props.getNested(["uiParams", "sidebarLoaded"]) !=
        nextProps.getNested(["uiParams", "sidebarLoaded"])
    ) {
      newStateChanges = { ...this.onWidthChange() };
    }

    if (props.itemsMode != nextProps.itemsMode) {
      this.props.saveURLParams({ page: nextProps.itemsMode });
      newStateChanges.dataSortFunc = (a, b) => {
        return (
          (b.editedAt || b.createdAt || 0) - (a.editedAt || a.createdAt || 0)
        );
      };
    }

    let differentLocationParams =
      props.getNested(["match", "params", "buildingId"]) !=
        nextProps.getNested(["match", "params", "buildingId"]) ||
      props.getNested(["match", "params", "floorId"]) !=
        nextProps.getNested(["match", "params", "floorId"]) ||
      props.getNested(["match", "params", "unitId"]) !=
        nextProps.getNested(["match", "params", "unitId"]);

    let differentPostsData =
      props.getNested(["data"]) != nextProps.getNested(["data"]);
    if (differentPostsData)
      newStateChanges.selectedLocationId =
        nextProps.getNested(["match", "params", "unitId"]) ||
        nextProps.getNested(["match", "params", "floorId"]) ||
        nextProps.getNested(["match", "params", "buildingId"]);

    if (differentLocationParams || differentPostsData) {
      let postsArray = [];
      // Run over all posts and include only relevant posts for wanted location
      // Todo: Select locationPosts when working with DB

      // If on building only and the building is the only one then show all
      let showAll = nextProps.showAll || (nextProps['buildings'].size == 1 && nextProps.getNested(['match','params','unitId']) == "_" && nextProps.getNested(['match','params','floorId']) == "_");

      nextProps.getNested(['data'], []).loopEach((id, post) => {
        let isInLocation = showAll || nextProps.getNested(['match','params','buildingId']) == ALL_BUILDINGS_ID;
        if (!isInLocation && nextProps.getNested(['match','params','unitId'], '_') != '_')
          isInLocation = post.getNested(['location', 'unit', 'id'], '_') === nextProps.getNested(['match','params','unitId'], '_')
        else if (!isInLocation && nextProps.getNested(['match', 'params', 'floorId'], '_') != '_')
          isInLocation = 
            (nextProps.locationsAggregationView && post.getNested(['location', 'floor', 'id'], '_') === nextProps.getNested(['match','params','floorId'], '_')) ||
            (!nextProps.locationsAggregationView && post.getNested(['location', 'floor', 'id'], '_') === nextProps.getNested(['match','params','floorId'], '_') && !post.getNested(['location', 'unit', 'id']))
        else if (!isInLocation && nextProps.getNested(['match', 'params', 'buildingId'], '_') != '_')
          isInLocation = 
            (nextProps.locationsAggregationView && post.getNested(['location', 'building', 'id'], '_') === nextProps.getNested(['match','params','buildingId'], '_')) || 
            (!nextProps.locationsAggregationView && post.getNested(['location', 'building', 'id'], '_') === nextProps.getNested(['match','params','buildingId'], '_') && !post.getNested(['location', 'floor', 'id']) && !post.getNested(['location', 'unit', 'id']))
    
        if (isInLocation) 
          postsArray.push(post)

        if (selectedPost && selectedPost.id == post.id && !isEditMode)
          newStateChanges.selectedPost = post;
      });

      newStateChanges.postsArray = postsArray;
      if (differentLocationParams) {
        newStateChanges.scrollToTop = scrollToTop - 1;
      }

      if (props.filterVal != nextProps.filterVal && nextProps.filterVal)
        newStateChanges.expandAll = postsArray.length < maxExpandablePosts;
      else if (!nextProps.filterVal)
        newStateChanges.expandAll = nextProps.expandAll < defaultExpandAll;
    }

    if (differentLocationParams) {
      newStateChanges.selectedPost = null;
      newStateChanges.imagesModalObject = null;
    }

    if (props.filters != nextProps.filters || props.sorts != nextProps.sorts)
      newStateChanges.scrollToTop = scrollToTop - 1;

    if (props.selectedProjectId != nextProps.selectedProjectId ||
        props.members != nextProps.members ||
        props.trades != nextProps.trades ||
        props.companies != nextProps.companies ||
        props.floors != nextProps.floors ||
        props.units != nextProps.units ||
        props.itemsMode != nextProps.itemsMode ||
        props.urlParams.getNested(['buildingId']) != nextProps.urlParams.getNested(['buildingId'])) {
      let buildingId = nextProps.urlParams.getNested(['buildingId']);
      let floorsMap = nextProps.floors.getNested([buildingId])
      let unitsMap = nextProps.units.getNested([buildingId])
      if (buildingId == ALL_BUILDINGS_ID) {
        floorsMap = {}; unitsMap = {};
        nextProps.floors.loopEach((bId, b) => b.loopEach((id, value) => floorsMap[id] = value));
        nextProps.units.loopEach((bId, b) => b.loopEach((id, value) => unitsMap[id] = value));
      }

      newStateChanges.groupKeys = {
        dueDate: { key: "dueDate", valuePath: ["dueDate"], isDate: true },
        owner: {
          key: "owner",
          valuePath: ["owner", "id"],
          titleFetchLocation: nextProps.allMembers,
          titleFetchKeyPath: "displayName",
        },
        createdAt: { key: "createdAt", valuePath: ["createdAt"], isDate: true },
        trade: {
          key: "trade",
          valuePath: ["trade", "id"],
          titleFetchLocation: nextProps.trades,
          titleFetchKeyPath: "getTitle",
        },
        subCategory: {
          key: "subCategory",
          valuePath: ["subCategory", "id"],
          titleFetchLocation: subCategoriesToOptions(nextProps.subCategories),
          titleFetchKeyPath: "title",
        },
        assignTo: {
          key: "assignTo",
          valuePath: ["assignTo", "id"],
          titleFetchLocation: nextProps.allMembers,
          titleFetchKeyPath: "displayName",
        },
        issueState: {
          key: "issueState",
          valuePath: ["issueState"],
          titleFetchLocation: issuesMessages.counterStatus,
          defaultGroupKey:
            nextProps.itemsMode == "records"
              ? null
              : issueStates.ISSUE_STATE_OPENED,
        },
        company: {
          key: "company",
          valuePath: ["assignTo", "id"],
          titleFetchLocation: nextProps.allMembers,
          valuePath2: [
            ["projects", nextProps.selectedProjectId, "companyId"],
            ["companyId"],
          ],
          titleFetchLocation2: nextProps.allCompanies,
          titleFetchKeyPath: "name",
        },
        floor: {
          key: "floor",
          valuePath: ["location", "floor", "id"],
          titleFetchLocation: floorsMap,
          sortFunc: (a, b) => a.localeCompare(b),
          titleFetchKeyPath: "description",
        },
        unit: {
          key: "unit",
          valuePath: ["location", "unit", "id"],
          titleFetchLocation: unitsMap,
          sortFunc: (a, b) => a.localeCompare(b),
          titleFetchKeyPath: "title",
        },
      };
    }

    if (Object.keys(newStateChanges).length > 0) this.setState(newStateChanges);
  }

  onWidthChange(ref) {
    const { postsContainerWidth } = this.state;
    if (this.postsContainerRef || ref) {
      let prevWidth = postsContainerWidth;
      let currWidth = this.postsContainerRef
        ? this.postsContainerRef.clientWidth
        : ref.clientWidth;
      if (prevWidth / 10 != currWidth / 10) {
        let postsPerRow = this.calculatePostPerRow(currWidth);
        return { postsContainerWidth: currWidth, postsPerRow: postsPerRow };
      }
    }

    return {};
  }

  calculatePostPerRow(containerWidth, nextViewMode) {
    const { viewMode } = this.state;
    if (nextViewMode == "lines" || (viewMode == "lines" && !nextViewMode))
      return 1;
    let width =
      containerWidth ||
      (this.postsContainerRef
        ? this.postsContainerRef.clientWidth
        : minPostWidth);
    let countPerRow = Math.floor(width / minPostWidth);
    let postsPerRow = Math.max(1, countPerRow); //- (selectedPost ? 1 : 0);
    return postsPerRow;
  }

  closeSelectedPost() {
    this.setState({ selectedPost: null });
  }

  addPost() {
    const { match, itemsMode, draftValidator } = this.props;

    let location = {};
    if (match.getNested(["params", "buildingId"], "_") != "_")
      location.building = { id: match.getNested(["params", "buildingId"]) };
    if (match.getNested(["params", "floorId"], "_") != "_")
      location.floor = { id: match.getNested(["params", "floorId"]) };
    if (match.getNested(["params", "unitId"], "_") != "_")
      location.unit = { id: match.getNested(["params", "unitId"]) };

    draftValidator(() =>
      this.setState({
        selectedPost: {
          isIssue: itemsMode != "records" ? true : null,
          mode: "draft",
          location,
        },
      })
    );
  }

  onPostSelect(post, skipDraftValidator = false) {
    const { draftValidator, startToast, inDraftMode } = this.props;
    const { selectedPost } = this.state;

    if (!selectedPost || skipDraftValidator) {
      this.setState({ selectedPost: post });
      return;
    }

    draftValidator(() => this.setState({ selectedPost: post }));
  }

  onSortChangeDirection() {
    this.setState({ sortDirection: this.state.sortDirection * -1 });
  }

  onCollapseExpandClick() {
    this.setState({ expandAll: !this.state.expandAll });
  }

  onAllGroupsCollapseOrExpand(isAllExpand) {
    this.setState({ expandAll: isAllExpand });
  }

  getContainerRef(node) {
    this.postsContainerRef = node;
  }

  getScrollRef(node) {
    this.postsScrollRef = node;
  }

  chartMode() {
    this.setState({ viewMode: "chart" });
  }

  linesMode() {
    this.setState({
      viewMode: "lines",
      postsPerRow: this.calculatePostPerRow(null, "lines"),
    });
  }

  blocksMode() {
    this.setState({
      viewMode: "blocks",
      postsPerRow: this.calculatePostPerRow(null, "blocks"),
    });
  }

  onRelativeDateChange(e) {
    let newVal = e.valueOf();
    this.setState({ relativeDate: newVal });
  }

  handleExportSelectionModeChange() {
    const { startToast } = this.props;
    const { exportSelectionMode } = this.state;
    let newMode = !exportSelectionMode;
    if (newMode) startToast({ title: pdfMessages.selectGroupsToExport });

    this.setState({ exportSelectionMode: newMode });
  }

  getPostsListRef(r) {
    this.getPostsListRef = r;
  }

  async exportAllSelectedGroupPosts() {
    const { selectedProjectId, viewer, exportPostsPDF, startToast } =
      this.props;

    let pdfDisplay = null;
    let groupPosts = this.getPostsListRef.getSelectedGroupsPosts();
    if (!groupPosts || groupPosts.length == 0)
      startToast({ title: pdfMessages.noGroupsSelected });
    else {
      let pdf = await exportPostsPDF(groupPosts, selectedProjectId, viewer);

      if (pdf && pdf.uri && pdf.uri.startsWith("http")) pdfDisplay = pdf.uri;

      this.setState({ exportSelectionMode: false, pdfDisplay });
    }
  }

  hidePdfDisplay = () => this.setState({ pdfDisplay: null });

  getSortsMenu = () => {
    const { intl } = this.props;
    return [
      { title: postsMenuMessages["trade"], key: "trade" },
      { title: newPostMessages.subCategory, key: "subCategory" },
      { title: postsMenuMessages["issueState"], key: "issueState" },
      {
        title:
          intl.formatMessage(postsMenuMessages.assignTo) +
          " - " +
          intl.formatMessage(analyticsMessages.viewType.filterByUser),
        key: "assignTo",
      },
      {
        title:
          intl.formatMessage(postsMenuMessages.assignTo) +
          " - " +
          intl.formatMessage(postsMenuMessages.company),
        key: "company",
      },
      {
        title:
          intl.formatMessage(postsMenuMessages.owner) +
          " - " +
          intl.formatMessage(analyticsMessages.viewType.filterByUser),
        key: "owner",
      },
      { title: postsMenuMessages["createdAt"], key: "createdAt" },
      { title: postsMenuMessages["dueDate"], key: "dueDate" },
    ].map((mItem, index) => _.set(mItem, "ordinalNo", index + 1));
  };

  // To add a filter, follow the 'Add here'
  getFilterMenu = (postsArray, props = this.props) => {
    const {
      urlParams,
      itemsMode,
      allMembers,
      trades,
      projectCompanies,
      allPosts,
      intl,
      subCategories,
    } = props;

    postsArray = postsArray || allPosts;

    const properties = {
      trades: ["trade", "id"],
      subCategories: ["subCategory", "id"],
      owner: ["owner", "id"],
      assignTo: ["assignTo", "id"],
      issueState: ["issueState"],
      severity: ["severity"],
      ownerCompanies: ["owner", "companyId"],
      assigneeCompanies: ["assignTo", "companyId"],
      // Add here if it is simple to get the value from the post...
      // [optionId]: pathArrToValueWithinThePost
    };

    let relevantOptionsIds = {};
    Object.values(postsArray || {}).forEach((post) => {
      Object.entries(properties).forEach(([optionPath, valPath]) => {
        const val = _.get(post, valPath);
        if (!val) return;
        _.set(relevantOptionsIds, [optionPath, val], val);
      });

      // ...Otherewise manually add here the relevant option ids to relevantOptionIds
    });

    const allEmployeesOptionsByEmployeeId = membersToOptions(allMembers),
      tradeOptionsByTradeId = tradesToOptions(trades),
      subCategoriesBysubCategoryId = subCategoriesToOptions(subCategories),
      companyOptionsByCompanyId = companiesToOptions(projectCompanies),
      issueStateOptionsByIssueState = [
        issueStates.ISSUE_STATE_CLOSED,
        issueStates.ISSUE_STATE_OPENED,
        issueStates.ISSUE_STATE_RESOLVED,
      ].reduce(
        (acc, currIssueState) =>
          _.set(acc, [currIssueState], {
            id: currIssueState,
            title: issuesMessages.counterStatus[currIssueState],
          }),
        {}
      ),
      severityOptionsBySeverity = {
        ["1"]: { id: 1, title: safetyMessages.safetyTable.openSafetyIssuesLow },
        ["2"]: {
          id: 2,
          title: safetyMessages.safetyTable.openSafetyIssuesMedium,
        },
        ["3"]: {
          id: 3,
          title: safetyMessages.safetyTable.openSafetyIssuesHigh,
        },
      };
    // Add here and define your options objects

    const extractRelevantOptions = (optionObj, relevantOptionsPath) =>
      Object.values(optionObj || {}).filter((option) =>
        Boolean((relevantOptionsIds[relevantOptionsPath] || {})[option.id])
      );

    let filterCategories = {
      ["postOwner"]: {
        id: "owner-id",
        title:
          intl.formatMessage(postsMenuMessages.owner) +
          " - " +
          intl.formatMessage(analyticsMessages.viewType.filterByUser),
        ordinalNo: 5,
        options: extractRelevantOptions(allEmployeesOptionsByEmployeeId, 'owner'),
        type: propertyTypes.SELECTION_LIST,
      },
      ["postOwnerCompany"]: {
        id: "owner-companyId",
        title:
          intl.formatMessage(postsMenuMessages.owner) +
          " - " +
          intl.formatMessage(postsMenuMessages.company),
        ordinalNo: 6,
        options: extractRelevantOptions(companyOptionsByCompanyId, 'ownerCompanies'),
        type: propertyTypes.SELECTION_LIST,
      },
      ["trade"]: {
        id: "trade-id",
        title: systemMessages.trade,
        ordinalNo: 2,
        options: extractRelevantOptions(tradeOptionsByTradeId, 'trades'),
        type: propertyTypes.SELECTION_LIST,
      },
      ["subCategory"]: {
        id: "subCategory-id",
        title: newPostMessages.subCategory,
        ordinalNo: 3,
        options: extractRelevantOptions(subCategoriesBysubCategoryId, 'subCategories'),
        type: propertyTypes.SELECTION_LIST,
      },
      // TODO: support checking if key has a value and value has values (right now the schema for posts has default values of [] for attachments and images which means that the object exist but it is empty)
      // attachments: {
      //   id: 'attachments',
      //   title: 'Attachements',
      //   ordinalNo: 7,
      //   options: [
      //     { id: 'attachments', title: 'PDF' },
      //     { id: 'images', title: 'Images' },
      //   ]
      // }
      // Add here the category if is generic...
    };

    // ...Otherwise add here based on itemType...
    switch (itemsMode) {
      case "tasks": {
        filterCategories = {
          ...filterCategories,
          ["postAssignee"]: {
            id: "assignTo-id",
            title:
              intl.formatMessage(postsMenuMessages.assignTo) +
              " - " +
              intl.formatMessage(analyticsMessages.viewType.filterByUser),
            ordinalNo: 7,
            options: extractRelevantOptions(allEmployeesOptionsByEmployeeId, 'assignTo'),
            type: propertyTypes.SELECTION_LIST,
          },
          ["issueState"]: {
            id: "issueState",
            title: postsMenuMessages.issueState,
            ordinalNo: 1,
            options: Object.values(issueStateOptionsByIssueState),
            type: propertyTypes.SELECTION_LIST,
          },
          ["postAssigneeCompany"]: {
            id: "assignTo-companyId",
            title:
              intl.formatMessage(postsMenuMessages.assignTo) +
              " - " +
              intl.formatMessage(postsMenuMessages.company),
            ordinalNo: 8,
            options: extractRelevantOptions(companyOptionsByCompanyId, 'assigneeCompanies'),
            type: propertyTypes.SELECTION_LIST,
          },
        };
        break;
      }
      default:
        break;
    }

    const contentType = (
      (urlParams || {}).toJS ? urlParams.toJS() : urlParams || {}
    ).contentType;

    // ...Or maybe add here based on contentType.
    switch (contentType) {
      case "safety": {
        filterCategories = {
          ...filterCategories,
          ["severity"]: {
            id: "severity",
            title: newPostMessages.severity,
            ordinalNo: 4,
            options: Object.values(severityOptionsBySeverity),
            type: propertyTypes.SELECTION_LIST,
          },
        };
        break;
      }
      default:
        break;
    }

    return [
      {
        id: "defaultView",
        title: "defaultView",
        categories: Object.values(filterCategories),
      },
    ];
  };

  recalcHeader() {
    const {
      classes,
      rtl,
      setHeaderParams,
      filterVal,
      clearFilterVal,
      setFilterVal,
      itemsMode,
      intl,
    } = this.props;
    const {
      postsPerRow,
      sortDirection,
      viewMode,
      expandAll,
      relativeDate,
      exportSelectionMode,
      postsArray,
    } = this.state;
    const standardSeparationMargin = `0 ${theme.verticalMargin}px`;
    let isCementoTeamViewer = this.props.getNested([
      "uiParams",
      "isCementoTeamViewer",
    ]);
    let headerComponent = (
      <GridItem
        xs={12}
        style={{
          paddingLeft: (rtl ? 1 : 1) * theme.paddingSize,
          paddingRight: (rtl ? 1 : 1) * theme.paddingSize,
          borderBottom: theme.borderLineNeutralLight + "40",
          height: theme.headerHeightSecondary,
        }}
      >
        <GridContainer
          style={{
            height: "100%",
            justifyContent: "space-between",
            flexWrap: "nowrap",
          }}
          alignContent="center"
          alignItems="center"
          justifyContent="center"
        >
          <GridItem
            xs={postsPerRow == 1 ? 6 : 8}
            md={postsPerRow == 1 ? 6 : 6}
            style={{
              display: "flex",
              flexDirection: viewMode == "chart" ? "row-reverse" : "row",
            }}
          >
            {Boolean(viewMode != "chart") ? (
              <FilterStatusLine
                onCollapseExpandClick={this.onCollapseExpandClick}
                expandStatus={!expandAll}
              />
            ) : (
              <FormControl
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {"Last day to compare: "}
                <Datetime
                  timeFormat={false}
                  value={moment(relativeDate)}
                  onChange={this.onRelativeDateChange}
                />
              </FormControl>
            )}
          </GridItem>
          {Boolean(exportSelectionMode) ? (
            <GridItem
              xs={postsPerRow == 1 ? 6 : 4}
              md={postsPerRow == 1 ? 6 : 3}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                paddingLeft: theme.verticalMargin,
                paddingRight: theme.verticalMargin,
              }}
            >
              <div
                style={{ cursor: "pointer" }}
                onClick={this.handleExportSelectionModeChange}
              >
                <Text style={{ margin: 5, fontSize: 16 }}>
                  {systemMessages.cancel}
                </Text>
              </div>
              <div
                style={{ cursor: "pointer" }}
                onClick={this.exportAllSelectedGroupPosts}
              >
                <Text
                  style={{
                    margin: 5,
                    fontSize: 16,
                    color: theme.brandPrimary,
                    [rtl ? "marginLeft" : "marginRight"]: 0,
                  }}
                >
                  {postsMenuMessages.exportTitle}
                </Text>
              </div>
            </GridItem>)
            :
           ( <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', padding: standardSeparationMargin }}>
              <Sorts hideSortDirection sortValuesFunc={(a, b) => (a.ordinalNo || 0) - (b.ordinalNo || 0)} direction={sortDirection} onSortChangeDirection={this.onSortChangeDirection} sortMenu={this.getSortsMenu()} sortByLocation={true}/>
              <FilterMenuHOC hideEmptyCategory filters={this.getFilterMenu()} buttonStyle={{ margin: standardSeparationMargin }} filterUrlKey={POSTS_FILTER_URL_KEY} />
              <TextFilter containerStyle={{ margin: standardSeparationMargin }} value={filterVal} onChange={setFilterVal} clearFilterVal={clearFilterVal} />
              <div style={{ display: 'flex', margin: `0 ${theme.verticalMargin}px 0 ${theme.margin + 10}px` }}>
                <img src={viewMode == 'blocks' ? view_blocks_active : view_blocks} style={{ [rtl ? 'marginLeft' : 'marginRight']: theme.verticalMargin, cursor: 'pointer' }} onClick={this.blocksMode}/>
                <img src={viewMode == 'lines' ? view_lines_active : view_lines}    style={{ cursor: 'pointer' }} onClick={this.linesMode}/>
              </div>
              <PrintOutlined
                style={{
                  fontSize: 16,
                  lineHeight: "16px",
                  zIndex: theme.zIndexesLevels.twentyOne,
                  margin: `0 ${theme.margin + 5}px`,
                  cursor: "pointer",
                }}
                onClick={this.handleExportSelectionModeChange}
              />
              {Boolean(false && isCementoTeamViewer) && (
                <BarChart
                  className={classes.icons}
                  style={{
                    [rtl ? "paddingRight" : "paddingLeft"]: theme.margin,
                    cursor: "pointer",
                    color: viewMode == "chart" ? "#211811" : "#bdbab8",
                  }}
                  onClick={this.chartMode}
                />
              )}
              <AddNewButton
                onClick={() => this.addPost(null, true)}
                title={
                  itemsMode == "records"
                    ? linksMessages.newPost
                    : postsMessages.newIssue
                }
                style={{
                  fontWeight: theme.strongBold,
                  margin: standardSeparationMargin,
                }}
              />
            </div>
          )} 
        </GridContainer>
      </GridItem>
    );

    if (setHeaderParams)
      setHeaderParams({ headerComponent, sideBarParams: { open: false } });
  }

  render() {
    const { sorts, rtl, defaultGroupBy } = this.props;
    const {
      postsArray,
      selectedLocationId,
      selectedPost,
      postsPerRow,
      scrollToTop,
      sortDirection,
      viewMode,
      expandAll,
      groupKeys,
      dataSortFunc,
      relativeDate,
      exportSelectionMode,
      pdfDisplay,
    } = this.state;

    return (
      <SplitViewPage
        rtl={rtl}
        onSideClose={this.closeSelectedPost}
        getMainContainerRef={this.getContainerRef}
        getMainContainerScroll={this.getScrollRef}
        onEditModeChange={this.handleEditModeChange}
        ratio={
          viewMode == "blocks" ? (postsPerRow == 1 ? 1 / 2 : 1 / 3) : 1 / 3
        }
        Main={
          <>
            {Boolean(pdfDisplay) && (
              <ImageCarousel
                items={[{ src: pdfDisplay }]}
                pdfMode={true}
                onClose={this.hidePdfDisplay}
                toolbar={true}
              />
            )}
            <Posts
              getRef={this.getPostsListRef}
              viewMode={viewMode}
              relativeDate={relativeDate == this.today ? null : relativeDate}
              scrollToTop={scrollToTop}
              expandAll={expandAll}
              exportSelectionMode={exportSelectionMode}
              onAllGroupsCollapseOrExpand={this.onAllGroupsCollapseOrExpand}
              scrollRef={this.postsScrollRef}
              rowHeight={
                viewMode == "lines" ? postRowHeightLines : postRowHeight
              }
              sectionTitleHeight={sectionTitleHeight}
              postsPerRow={postsPerRow}
              onPostSelect={this.onPostSelect}
              selectedPostId={selectedPost ? selectedPost.id : null}
              selectedLocationId={selectedLocationId}
              postsArray={postsArray}
              sortDirection={sortDirection}
              groupBy={groupKeys[sorts && sorts[0] ? sorts[0] : defaultGroupBy]}
              dataSortFunc={dataSortFunc}
            />
          </>
        }
        SideStack={
          Boolean(selectedPost) && [
            {
              type: "post",
              props: {
                style: {
                  flex: 1,
                  backgroundColor: theme.backgroundColorBright,
                  height: "100%",
                  boxShadow: "none",
                },
                onPostSave: (selectedPost) => {
                  this.setState({ selectedPost });
                },
                onSelect: this.onPostSelect,
                post: selectedPost,
              },
            },
          ]
        }
      />
    );
  }
}

const styles = {
  slider: {
    height: "100%",
    width: "100%",
    margin: "0 auto",
    background: "transparent",
  },
};

PostsPage = injectIntl(PostsPage);
PostsPage = withStyles(
  theme.combineStyles(notificationsStyle, validationFormsStyle, styles)
)(PostsPage);
const enhance = compose(
  connectContext(ProjectContext.Consumer),
  connectContext(FiltersSortsContext.Consumer),
  connect(
    (state) => ({
      allCompanies: state.companies.map,
      trades: state.trades.map,
      allMembers: state.members.map,
      urlParams: state.ui.urlParams,
      uiParams: state.ui.uiParams,
      inDraftMode: state.ui.inDraftMode,
      rtl: state.app.rtl,
      subCategories: state.quasiStatics.subCategoriesMap,
    }),
    { draftValidator, startToast, saveURLParams, exportPostsPDF }
  )
);
export default enhance(PostsPage);

PostsPage.defaultProps = {
  defaultGroupBy: "trade",
};
