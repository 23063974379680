import React from "react";
import { connect } from "react-redux";
import { compose, hoistStatics } from "recompose";
import { connectContext } from "react-connect-context";
import { ProjectContext } from "../../../common/projects/contexts";
import {
  revokeUsersCache,
  updateProjectLastUpdateTS,
} from "../../../common/lastUpdates/actions";
import Select from "react-select";
import withStyles from "@material-ui/core/styles/withStyles";
import MemberCard_deprecated from "./MemberCard_deprecated";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import theme from "../../assets/css/theme";
import Button from "../../components/CustomButtons/Button.jsx";
import buttonStyle from "../../assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";
import MembersManagerTable from "./MembersManagerTable";

const projectRevokeOptions = [
  "buildings",
  "checklists.checklists",
  "checklists.itemInstances",
  "checklists.items",
  "checklists.stages",
  "checklists.subscriptions",

  "comments",
  "configurtaions",
  "drawings",
  "employees",
  "equipment",
  "floors",

  "forms.certification",
  "forms.dailyReport",
  "forms.general",
  "forms.objects",
  "forms.others",
  "forms.safety",
  "forms.temp",
  
  "groups",
  "posts",
  "projects",
  
  "properties.instances.checklistItemsInfo",
  "properties.instances.companiesInfo",
  "properties.instances.employeesInfo",
  "properties.instances.equipmentInfo",
  "properties.instances.formsInfo",
  "properties.instances.locationsInfo",
  "properties.instances.postsInfo",
  "properties.instances.projectsFinance",
  "properties.instances.projectsInfo",

  "properties.mappings.checklistItemsInfo",
  "properties.mappings.companiesInfo",
  "properties.mappings.employeesInfo",
  "properties.mappings.equipmentInfo",
  "properties.mappings.formsInfo",
  "properties.mappings.locationsInfo",
  "properties.mappings.postsInfo",
  "properties.mappings.projectsFinance",
  "properties.mappings.projectsInfo",

  "properties.types.checklistItemsInfo",
  "properties.types.companiesInfo",
  "properties.types.employeesInfo",
  "properties.types.equipmentInfo",
  "properties.types.formsInfo",
  "properties.types.formsInfo.-pouringsPouringBetonType",
  "properties.types.locationsInfo",
  "properties.types.postsInfo",
  "properties.types.projectsFinance",
  "properties.types.projectsInfo",

  "reports.summary",
  "units",
];

class MembersManager extends React.Component {
  constructor(props) {
    super(props);
    this.setComponentData = this.setComponentData.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.revokeCache = this.revokeCache.bind(this);
    this.onRevokeFeatureSelect = this.onRevokeFeatureSelect.bind(this);
    this.onRevokeCacheClick = this.onRevokeCacheClick.bind(this);
    this.onLastUpdateTSClick = this.onLastUpdateTSClick.bind(this);
    this.state = {
      selectedMember: null,
    };
  }

  UNSAFE_componentWillMount() {
    this.setComponentData({}, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  setComponentData(props, nextProps) {
    let newStateChanges = {};
    if (Object.keys(newStateChanges).length > 0) this.setState(newStateChanges);
  }

  onSelect(selected) {
    this.setState({ selectedMember: selected });
  }

  onRevokeFeatureSelect(e) {
    let selectedRevokeFeature = e;
    this.setState({ selectedRevokeFeature });
  }

  onRevokeCacheClick() {
    const { members, selectedProjectId } = this.props;
    const { selectedRevokeFeature } = this.state;
    this.revokeCache(
      selectedRevokeFeature,
      members ? Object.keys(members) : null,
      selectedProjectId ? selectedProjectId : null
    );
  }

  onLastUpdateTSClick() {
    const { members, selectedProjectId, updateProjectLastUpdateTS } =
      this.props;
    const { selectedRevokeFeature } = this.state;
    if (!selectedProjectId) return;
    updateProjectLastUpdateTS(selectedRevokeFeature.value, selectedProjectId);
  }

  revokeCache(feature, usersIds, projectsIds) {
    const { revokeUsersCache } = this.props;
    const { selectedRevokeFeature } = this.state;
    if (usersIds && projectsIds && feature)
      revokeUsersCache(feature, false, usersIds, projectsIds);
    else if (selectedRevokeFeature.value)
      revokeUsersCache(selectedRevokeFeature.value, true);
  }

  render() {
    const { members, projectMembers, viewer, projectScope, usersGroupsOptions } = this.props;
    const { selectedMember, selectedRevokeFeature } = this.state;
    const adminMode = (viewer || {}).adminMode;
    const options = projectRevokeOptions.map((feature) => {
      return { value: feature, label: feature };
    });

    return (
      <div style={{ width: "100%", padding: theme.paddingSize }}>
        <GridContainer spacing={16}>
          {projectScope && (
            <>
              <GridItem xs={4}>
                <Select
                  value={selectedRevokeFeature}
                  onChange={this.onRevokeFeatureSelect}
                  styles={theme.basicSelectStyles}
                  options={options}
                />
              </GridItem>
                <GridItem xs={4}>
                  <Button
                    fullWidth
                    disabled={Boolean(adminMode != 1)}
                    onClick={this.onLastUpdateTSClick}
                  >
                    {projectScope
                      ? "Revoke all project members cache"
                      : "Revoke all cemento members cache"}
                  </Button>
                </GridItem>
            </>
          )}
          <GridItem xs={8}>
            <MembersManagerTable
              style={{ marginTop: theme.paddingSize }}
              onSelect={this.onSelect}
              members={projectScope ? projectMembers : members}
            />
          </GridItem>
          <GridItem xs={4}>
            <MemberCard_deprecated
              style={{
                backgroundColor: theme.backgroundColor,
                color: theme.brandNeutral,
              }}
              revokeCache={this.revokeCache}
              member={selectedMember}
              usersGroupsOptions={usersGroupsOptions}
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

MembersManager = withStyles(buttonStyle)(MembersManager);
const enhance = compose(
  connectContext(ProjectContext.Consumer),
  connect(
    (state) => ({
      members: state.members.map,
    }),
    { revokeUsersCache, updateProjectLastUpdateTS }
  )
);
export default enhance(MembersManager);
