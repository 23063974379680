export const STRING                                                     = 'String';
export const NUMBER                                                     = 'Number';
export const BOOLEAN                                                    = 'Boolean';
export const DATE                                                       = 'Date';
export const PDF                                                        = 'PDF';
export const PICTURE                                                    = 'Picture';
export const CERTIFICATION                                              = 'Certification';
export const SELECTION_LIST                                             = 'SelectionList';
export const DRAWINGS_ARRAY                                             = 'DrawingsArray';
export const FILES_ARRAY                                                = 'FilesArray';
export const SIGNATURE                                                  = 'Signature';
export const COMPANIES_DAILY_LOG                                        = 'CompaniesDailyLog';
export const TIME_RANGE                                                 = 'TimeRange';
export const ARRAY                                                      = 'Array';
export const COMPLEX                                                    = 'Complex';
export const LOCATION                                                   = 'Location';

export const VALID_PROPERTIES_ARRAY  = [ // Any property type added up there should also be added to this array
    STRING,
    NUMBER,
    BOOLEAN,
    DATE,
    PDF,
    PICTURE,
    CERTIFICATION,
    SELECTION_LIST,
    // DRAWINGS_ARRAY,
    FILES_ARRAY,
    SIGNATURE,
    COMPANIES_DAILY_LOG,
    TIME_RANGE,
    ARRAY,
    COMPLEX,
    LOCATION,
];

export const PROPERTIES_VIEW_TYPES = {
    array: 'array',
    string: 'string',
  }

export const isMultiSupportedTypes = {
  [SELECTION_LIST]: SELECTION_LIST,
  [FILES_ARRAY]: FILES_ARRAY
}

// SIGNING METHODS:

export const UPLOAD_FILE = 'picture'; // regular behaviour...
export const SIGN_ON_PICTURE = 'signOnPicture';
export const SIGN_ON_TEXT = 'cementoSign';

export const CEMENTO_SIGN_BEHAVIOUR = {
    complex: 'complex',
    oneSignature: 'oneSignature',
    oneMandatorySignature: 'oneMandatorySignature'
}
// CERTIFICATIONS LANGUAGES

export const LANGUAGES_MAP = {
    en: { code: 'en', name: 'English' },
    es: { code: 'es', name: 'Español' },
    he: { code: 'he', name: 'Hebrew' },
    ar: { code: 'ar', name: 'Arabic' },
    ru: { code: 'ru', name: 'Russian' },
    zh: { code: 'zh', name: 'Chinese' },
    tr: { code: 'tr', name: 'Turkish' },
    ro: { code: 'ro', name: 'Romanian' },
    ti: { code: 'ti', name: 'Tigrinya' }
}


export const CERTIFICATIONS_TTL_TYPES = {
    'certificationMonthsTTL': 'certificationMonthsTTL',
    'certificationDaysTTL': 'certificationDaysTTL'
};

export const CERTIFICATIONS_TYPES = {
  roleAppointment: "roleAppointment"
};


export const UNIVERAL_IDS = {
	isActive: 'isActive',
  status: 'status',
}

export const BUSINESS_TYPES = {
  companies: 'companies',
  trades: 'trades',
};

export const GROUP_PROP_ID = 'groups';

export const AVAILABLE_BUSINESS_TYPES = {
	serialNumber: 'serialNumber',
}

export const SYSTEM_SUBJECTS_TYPES = [
  "locationsInfo",
  "employeesInfo",
  "equipmentInfo",
  "projectsInfo",
  "companiesInfo",
  "formsInfo",
  "postsInfo",
];

/**
 * 
 * @param {string} type 
 * @param {any} value 
 * @returns 
 */
export const normalizeInputValue = (type, value) => {
    let normalizedValue = value;
    
    if (value) {
      switch (type) {
        case (STRING):
          if (typeof value !== 'string')
            normalizedValue = null;
          break;
  
        case (SELECTION_LIST):
          if (typeof value !== 'object' || Array.isArray(value))
            normalizedValue = null;
          break;
  
        default:
          break;
      }
    }
  
    return normalizedValue;
  }

