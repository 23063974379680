import { removeProjectsStorage } from "../projects/actions";
import { getSnapshotData } from '../lib/utils/utils';
import { envParams } from '../configureMiddleware';

export const GET_PROPERTIES_MAPPINGS = 'GET_PROPERTIES_MAPPINGS';
export const SAVE_GROUPS_MAPPINGS = 'SAVE_GROUPS_MAPPINGS';
export const CLEAN_PROPERTIES_MAPPINGS_CACHE_DATA = 'CLEAN_PROPERTIES_MAPPINGS_CACHE_DATA';

export function getPropertiesMappings(projectId, forceUpdate) {
  return {
    type: GET_PROPERTIES_MAPPINGS,
    payload: getSnapshotData({ api: 'properties/mappings', firebase: 'properties/mapping/projects', resourceName: 'propertiesMappings' }, projectId)
  };
}

export function saveGroupsMappings(scopeId, subjectName, propertyTypeId, groupsMappingsMap) {
  return ({ platformActions }) => {
    const getPromise = async () => {
      const scope = "projects";
      const subjectType = "propertiesMapping";

      if (!scopeId || !groupsMappingsMap || Object.keys(groupsMappingsMap).length == 0) return;
      
      let newTemplate = {};
      _.set(newTemplate, [subjectType, subjectName, propertyTypeId], groupsMappingsMap);

      try {
        await platformActions.net.fetch(`${envParams.apiServer}/v1/templates`,
          {
            method: 'PUT',
            body: JSON.stringify({ scope, scopeId, subjectNames: [subjectName], subjectTypes: [subjectType], newTemplate })
          });
      }
      catch (error) {
        console.error(error);
        success = false
      }
      finally {
        return { projectId: scopeId };
      }
    };

    return {
      type: SAVE_GROUPS_MAPPINGS,
      payload: getPromise()
    };
  };
}



export function cleanPropertiesMappingsCachedData() {
  return ({ dispatch, getState, realmInstance, platformActions }) => {
    const getPromise = async () => {
      let projectIdsArray = [];
      let projectStateToRemove = [
        ['propertiesMappings', 'locationsMapping'],
        ['propertiesMappings', 'groupsMapping'],
        ['propertiesMappings', 'lastUpdated'],
        ['propertiesMappings', 'map'],
      ];

      getState().getNested(['projects', 'map'], {}).loopEach((k, p) => projectIdsArray.push(p.id));
      dispatch(removeProjectsStorage(projectIdsArray, projectStateToRemove));

    };
    return {
      type: CLEAN_PROPERTIES_MAPPINGS_CACHE_DATA,
      payload: getPromise()
    };
  };
}

