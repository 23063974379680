import { platformActions } from "../platformActions";
import { envParams } from "../configureMiddleware";
import { validateUpdateCompletion } from "../firebase/funcs";
import { threadSleep } from "../lib/utils/utils";

export async function updateForms (scopeId, scope, formId, formValues) {
    const firebase = platformActions.firebase.getFirebase();
    const { apiServer } = envParams;
    const scopeIdIfNeeded = scope !== "templates" ? `/${scopeId}` : ""; 
    let updates = {};

    updates[`templates/configurations/${scope}${scopeIdIfNeeded}/forms/${formId}`] = Object.assign({}, formValues, {id: formId});

    try {
        await validateUpdateCompletion(firebase.database().ref(), updates);
        await threadSleep(5000);
        const body = {
            templateSubject: ["configurations"],
            scope,
            scopeId
        };
        await platformActions.net.fetch(apiServer + `/v1/services/templates/merge?${new URLSearchParams(body).toString()}`);

        return true;
    }
    catch {
        return false;
    }
}