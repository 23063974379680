import React from "react";
import cx from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import defaultAvatar from "../../assets/img/defaultAvatar.png";
import defaultUser from "../../assets/img/defaultUser.png";
import defaultCompany from "../../assets/img/defaultCompany.png";
import defaultProject from "../../assets/img/defaultProject.png";
import defaultPicture from "../../assets/img/defaultPicture.png";

class Avatar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    let defaultSrc                               = defaultAvatar;
    if (props.type == 'user')         defaultSrc = defaultUser;
    else if (props.type == 'company') defaultSrc = defaultCompany;    
    else if (props.type == 'project') defaultSrc = defaultProject;
    else if (props.type == 'picture') defaultSrc = defaultPicture;
    this.state = { defaultSrc };
  }

  onClick() {
    const { onClick, src } = this.props;
    if (onClick)
      onClick(src);
  }

  render() {
    const { src, avatarContainerStyle, avatarStyle, classes, shadow, onClick } = this.props;    
    const { defaultSrc } = this.state;
    const avatarContainerClass = classes.avatarImgContainer + " " + cx({ [classes.shadow] : shadow});
    return (
      <div 
        color="transparent"
        onClick={this.onClick}
        className={avatarContainerClass}
        style={Object.assign(avatarContainerStyle || {}, { cursor: onClick ? 'pointer': 'default' })}>
        <img 
          src={src || defaultSrc} 
          className={classes.avatarImg}
          style={avatarStyle} 
          alt="..." />
      </div>
    );
  }
}

const styles = {
  avatarImgContainer: { 
    position: 'relative',
    width: '100%',
    paddingTop: '100%',
    borderRadius: '50%',
    overflow: 'hidden',
  }, 
  shadow: {
    boxShadow: "2px 2px 4px 0 rgba(0, 0, 0, 0.3)"
  },
  avatarImg: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',
    right: 0,
    left: 0,
    transform: 'translateY(-100%)',
  }
};

export default withStyles(styles)(Avatar);

