export const DEFAULT_SYSTEM_LANGUAGE = 'en';

export const SAFETY_TRADE_ID = "1038";

export const ALL_BUILDINGS_ID = 'ALL';

export const DEFAULT_VIEWS_CONFIGURATIONS = {
  "base" : {
    "pages" : {
      "employees" : {
        "List" : {
          "titles" : { 
            primary: {
              universalId: "fullName"
            }, 
            secondary: {
              universalId: "employeeCompany"
            }, 
            tertiary: {
              universalId: "employeeTitle",
            }, 
          },
          "images": {
            primary: {
              universalId: "avatar",
            }
          },
          "groupBy": {
            primary: {
              universalId: 'employeeCompany',
            }
          }
        }
      },
      "equipment": {
        "List": {
          "titles": {
            primary: { 
              universalId: 'equipmentType'
            },
            secondary: {
              universalId: "equipmentLocation"
            },
            tertiary: {
              universalId: 'equipmentDetails',
            }
          },
          "groupBy": {
            primary: {
              universalId: 'equipmentType',
            }
          }
        }
      },
      "companies": {
        "List": {
          "images": {
            primary: {
              universalId: "logo"
            }
          }
        }
      },
      "posts": {
        "List" : {
          "titles": {
            primary: {
              prefix: {
                universalId: 'room'
              }
            },
          }
        }
      }
    }
  }
}

//TODO: Translate labels
export const DEFAULT_SCOPE_OPTIONS = {
  projects : {value: 'projects', label: 'projects'},
  companies: {value: 'companies', label: 'companies'},
  templates: {value: 'templates', label: 'templates'},
  sets: {value: 'sets', label: 'sets'}
};
export const DEFAULT_SELECTED_SCOPE = 'projects';