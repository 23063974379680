import { useCallback, useEffect, useMemo, useState } from 'react';
import usePosts from './hooks/usePosts';
import _ from 'lodash';

/**
 * @typedef {{ [postId: string]: string } | null} PostsSelectorValue
 * @typedef PostsSelectorHOCProps
 * @property {string} id - MANDATORY -> Unique Identifier for the posts listener
 * @property {(newVal: PostsSelectorValue) => void} [onChange]
 * @property {{ isOnlySafety?: boolean, postsType?: import('./hooks/usePosts').PostsType }} settings
 * @property {PostsSelectorValue} [value]
 * @property {(childrenProps: {
 *  onPostSelect: (post: { id: string }) => void;
 *  currViewPosts: any[],
 *  filteredPosts: any[],
 *  selectedPostsMap: { [postId: string]: {} },
 *  selectedPostsArr: {}[],
 *  postsType: import('./hooks/usePosts').PostsType,
 *  isSafety: boolean,
 * })} children
 * @param {PostsSelectorHOCProps} props
 * @returns 
 */
const PostsSelectorHOC = (props) => {
  const { onChange, value, settings } = props;

  const isOnlySafety = useMemo(() => _.get(settings, ['isOnlySafety'], false), [settings]);
  const postsType = useMemo(() => _.get(settings, ['postsType']), [settings]);

  const { currViewPosts, filteredPosts, currViewPostsMap } = usePosts({ contentType: isOnlySafety ? 'safety' : null, postsType });

  const [stateValue, setStateValue] = useState(value);

  useEffect(() => {
    if (!_.isEqual(value, stateValue))
      setStateValue(value);
  }, [value]);

  const handlePostSelect = useCallback(
    /**
    * @param {{ id: string }} post
    * @returns
    */
   (post) => {
    let newVal = Object.assign({}, stateValue);

    if (newVal[post.id])
      delete newVal[post.id];
    else
      newVal[post.id] = post.id;

    setStateValue(newVal);
    if (onChange)
      onChange(newVal);
  }, [stateValue, onChange]);

  const selectedPostsMap = useMemo(() => Object.keys(value || {}).reduce((acc, pId) => currViewPostsMap[pId] ? _.set(acc, [pId], currViewPostsMap[pId]) : acc, {}), [stateValue, currViewPostsMap]);
  const selectedPostsArr = useMemo(() => Object.values(selectedPostsMap), [selectedPostsMap]);
  const childrenProps = useMemo(() => ({
    onPostSelect: handlePostSelect,
    currViewPosts, 
    filteredPosts,
    selectedPostsMap,
    selectedPostsArr,
    value: stateValue,
    postsType,
    isSafety: isOnlySafety,
  }), [
    handlePostSelect, 
    currViewPosts, 
    filteredPosts,
    selectedPostsArr,
    stateValue,
    postsType,
    isOnlySafety,
  ]);
  
  return props.children(childrenProps);
}

export default PostsSelectorHOC;