import React from 'react';
import { connect } from 'react-redux';
import { signOut, loadAuth0UserDataFromAsyncStorage, getValidAuth0Token } from '../common/auth/actions';
import { getMyUserInfo } from '../common/users/actions';
import { startAuthLogin, retriveFCMToken } from '../common/lib/redux-firebase/actions';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

class UserValidator extends React.Component {
  constructor(props) {
    super(props);
    this.onLoginHandler = this.onLoginHandler.bind(this); 
    this.state = {
      cardAnimaton: "cardHidden",
    };
  }

  componentWillUnmount(){
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }
  
  async componentDidMount() {
    const { startAuthLogin, getMyUserInfo, number, viewer, originViewer, loadAuth0UserDataFromAsyncStorage, signOut, retriveFCMToken } = this.props;
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );

    let isMicrosoftBrowser = 
      (/MSIE 10/i.test(navigator.userAgent)) ||   // This is internet explorer 9 or 10
      (/MSIE 9/i.test(navigator.userAgent)) ||    // This is internet explorer 9 or 11
      (/rv:11.0/i.test(navigator.userAgent)) ||   // This is internet explorer 9 or 11
      (/Edge\/\d./i.test(navigator.userAgent))    // This is Microsoft Edge
    
    if (isMicrosoftBrowser) {
      this.props.history.push('/pages/browser-not-supported');
      return;
    }

    let reDirect = this.props.history.location.pathname + (this.props.history.location.search || '') + (this.props.history.location.hash || '');
    var auth0_data = await loadAuth0UserDataFromAsyncStorage();
    if (auth0_data && auth0_data.id_token) {
      startAuthLogin(number, auth0_data.id_token);
    }

    if (!viewer && (!auth0_data || !auth0_data.id_token)) {
      await signOut(true);
      reDirect = '/pages/login-page';
    } else if (!this.didNotifyLogin) {
      if (!originViewer)
        this.onLoginHandler();
    }


    if (!reDirect || reDirect == '' || reDirect == '/')
      reDirect = '/main';

    if (reDirect) this.props.history.push(reDirect);
  }

  async onLoginHandler() {
    const { getMyUserInfo, originViewer, authToken, onLogin, getValidAuth0Token } = this.props;
    let shouldCallOnLogin = false;
    if (!this.didNotifyLogin) {
      this.didNotifyLogin = true;
      shouldCallOnLogin = true;
    }

    if (!originViewer) {
      let validAuthToken = ((await getValidAuth0Token(authToken)) || {}).id_token;
      if (validAuthToken)
        await getMyUserInfo(validAuthToken);
    }

    if (shouldCallOnLogin) {
      if (onLogin) onLogin();
    }
  }
  
  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { number, loggedIn, startAuthLogin, location, onSignOut } = this.props;

    //nextProps.authToken != authToken ||
    if (nextProps.authToken && (!this.didTryToLogin && nextProps.number != number)) {
      // Re-Connect to firebase
    	if (!nextProps.loggedIn && nextProps.number) {
        this.didTryToLogin = true;
    		startAuthLogin(nextProps.number, nextProps.authToken);
    	}
    }

    if (loggedIn && !nextProps.loggedIn) {
      onSignOut();
      this.props.history.push('/pages/login-page');
    } else if (loggedIn && nextProps.loggedIn && location.pathname === '/pages/login-page') {
      setTimeout(() => {
        this.props.history.push('/main');
      }, 50);
    }
    
    if (!this.didNotifyLogin && nextProps.loggedIn && nextProps.viewer) {
      this.onLoginHandler();
    }
  }

  render() {
    const { children, loggedIn, viewer, storageLoaded } = this.props;
    if (!storageLoaded || (viewer && !loggedIn))
      return <div>Please wait</div>

    return <div>{children}</div>
  }
}

UserValidator = withRouter(UserValidator);
export default connect(state => ({
  loggedIn: state.auth.loggedIn,
  viewer: state.users.viewer,
  authToken: state.auth.authToken,
  number: state.auth.number,
  originViewer: state.users.originViewer,
  storageLoaded: state.app.storageLoaded,
}), { retriveFCMToken, startAuthLogin, loadAuth0UserDataFromAsyncStorage, getMyUserInfo, signOut, getValidAuth0Token })(UserValidator);

