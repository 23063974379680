import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import theme from "../../assets/css/theme";
import { ReportContext } from '../../../common/analytics/contexts'; 
import iconResolve from '../../assets/img/icons/CheckBrandPrimary.png';
import attachment from '../../assets/img/icons/attachment.png';
import iconRejected from '../../assets/img/icons/CheckFalse.png';
import issue_full from '../../assets/img/icons/issue_full.png'
import issue_warn from '../../assets/img/icons/issue_warn.png'
import _ from 'lodash';
import Text from "../../components/CementoComponents/Text";
import Date from "../../components/CementoComponents/Date";
import systemMessages from "../../../common/app/systemMessages";
import safetyMessages from '../../../common/safety/safetyMessages';
import CounterCell from "../../components/CementoComponents/CounterCell";
import { Fade, withStyles } from "@material-ui/core";
import { Tooltip } from "../../components";
import DotStatusText from "../../components/CementoComponents/DotStatusText";
import useMemoizedValue from '../../../common/hooks/useMemoizedValue';
import { useSelector } from 'react-redux';

const ICON_SIZE = 16;
const DEFAULT_MAX_CHARS = 15;
const TOOLTIP_LEAVE_DELAY = 50;
const cellContentCalculator = (cellValue, rtl, cellStyles) => {
  if (_.isNil(cellValue))
      return undefined;

  const { displayValue, displayType, displayParams, cellInnerComponent } = cellValue;

  let cellContent;
  const typesAlignedToStart = {"Date": false,
                                "Number": false,
                                "Files": false, 
                                "String": true};
  let justifyContent;
  let alignItems;
  if (_.has(typesAlignedToStart, displayType)) {
    if (typesAlignedToStart[displayType]) {
      justifyContent = _.get(displayParams, 'justifyContent', 'start');
      alignItems = _.get(displayParams, 'alignItems', 'start');
    }
    else {
      justifyContent = _.get(displayParams, 'justifyContent', 'center');
      alignItems = _.get(displayParams, 'alignItems' , 'center');
    }
  }

  if (cellInnerComponent)
    cellContent = cellInnerComponent;
  else if (_.isNull(displayValue))
    cellContent = null;
  else if (_.isUndefined(displayValue))
    cellContent = undefined;
  else {
    switch (displayType) {
      case 'Boolean':
        cellContent = typeof displayValue === 'boolean'
          ? <img src={displayValue ? iconResolve : iconRejected} style={{ height: ICON_SIZE, width: ICON_SIZE }} />
          : null;
        break;

      case 'Date':
        cellContent = (<div style={{ ...cellStyles, flexDirection: 'row', display: 'flex', justifyContent, alignItems }}>
          {Boolean((displayParams.isWarning || displayParams.isExpired) && displayValue) && <img style={{ [rtl ? 'marginLeft' : 'marginRight']: theme.verticalMargin }} src={displayParams.isExpired ? issue_full : issue_warn} />}
          {displayParams.missingRequirementList ?
            <Text style={{ color: theme.brandDanger }}>{safetyMessages.missing}</Text> :
            <Date format={{ custom: displayParams.dateFormat }} style={{ ...theme.defaultFont, fontWeight: (displayParams.isWarning || displayParams.isExpired) ? theme.strongBold : theme.noBold, color: displayParams.isExpired ? theme.brandDanger : null }}>{displayValue}</Date>
          }
        </div>
        );
        break;

      case 'Files':
        if (!displayValue)
          cellContent = null;
        else
          cellContent = (<div style={{ ...cellStyles, flexDirection: 'row', display: 'flex', justifyContent, alignItems }}>
            <img src={attachment} style={{ color: theme.brandPrimary, height: 13, [rtl ? 'marginLeft' : 'marginRight']: theme.verticalMargin }} />
            <Text style={{ fontWeight: theme.noBold }} values={{ filesCounter: displayValue }}>{systemMessages.fileCount}</Text>
          </div>
          );
        break;
      
      case 'Number':
        cellContent = (
          <Text
            style={{ direction: displayParams.direction || 'inherit', display: 'flex', flex: 1, flexDirection: 'row', padding: 5, justifyContent, alignItems, overflow: 'hidden', fontWeight: 'initial', whiteSpace: 'nowrap' }}
            title={displayValue}
            maxChars={cellStyles.columnCustomMaxChars || DEFAULT_MAX_CHARS}
            maxLines={1}
            tagsMode={'hide'}
          >
            {displayValue}
          </Text>
        );
        break;

      default:
      case 'String':
        if (displayParams.isCounterRow)
          cellContent = (<CounterCell value={displayValue} style={displayParams.counterStyle} />);
        else if (displayParams.isStatusRow) {
          const dotSize = 10;
          const dotStyle = { height: dotSize, width: dotSize, marginInlineEnd: theme.margin / 3 };
          cellContent = (
            <DotStatusText
              label={displayParams.message}
              color={displayParams.color}
              dotStyle={dotStyle}
              containerStyle={{ flex: 1, justifyContent: displayParams.justifyContent || 'center' }}
              labelStyle={{ flexBasis: '50%' }}
            />
          );
        }
        else
          cellContent = (
            <Text
              style={{ direction: displayParams.direction || 'inherit', display: 'inline-block', flex: 1, flexDirection: 'row', padding: theme.padding, justifyContent, alignItems, 
              textOverflow: 'ellipsis', overflow: 'hidden', fontWeight: 'initial', whiteSpace: 'nowrap' }}
              title={displayValue}
              maxChars={cellStyles.columnCustomMaxChars}
              maxLines={1}
              tagsMode={'hide'}
            >
              {displayValue}
            </Text>
          );
        break;
    }
    
    if (displayParams.toolTipParams) {
      const { titleComponent, style, titleGeneratorFunction } = displayParams.toolTipParams || {};
      const LightTooltip = withStyles(theme => ({
        tooltip: {
          backgroundColor: theme.palette.common.white,
          color: 'rgba(0, 0, 0, 0.87)',
          boxShadow: theme.shadows[1],
          ...(style || {})
          // fontSize: theme.mediumFontSize,
        },
      }))(Tooltip);
      cellContent = (
        <LightTooltip
          interactive
          leaveDelay={TOOLTIP_LEAVE_DELAY}
          titleComponent={titleComponent}
          titleGeneratorFunction={titleGeneratorFunction}
          title={cellContent}
          placement={rtl ? 'left' : 'right'}
          TransitionComponent={Fade}
        >
          <div>
            {cellContent}
          </div>
        </LightTooltip>
      );
    }
  }

  return cellContent;
}


const TableCell = props => {
	const { cellData, isFirstRow, rowIndex, cellClickHandler, isRowSelected, isRTL } = props;

	let cellStyles = Object.assign({}, cellData?.style, { borderHeight: 1 });
	const cellContent = useMemo(() => {
		if (_.isNil(cellData)) return undefined;

		const currColumnId = cellData.column.mainColumnId;
		let cellValue = cellData.title || cellData.row.values[currColumnId];

		return cellContentCalculator(cellValue, isRTL, cellStyles);
	}, [cellData, isRTL]);

	if (!cellData) return null;

	const dismissClickHandler = cellData?.row?.dismissClickHandler;
	const addClassNames = cellData?.className || '';

  const undefinedCell = typeof cellContent === 'undefined';
  if (!undefinedCell || !dismissClickHandler) cellStyles.cursor = 'pointer';

	return (
    <td
      className={undefinedCell ? addClassNames.concat(' EmptyCell') : addClassNames}
      key={cellData.row.id}
      style={{
        ...cellStyles,
        paddingBottom: isRowSelected && isFirstRow ? cellStyles.borderHeight : 0,
        paddingTop: isRowSelected && isFirstRow ? cellStyles.borderHeight : 0,
      }}
      onClick={() =>
        cellClickHandler(
          cellData,
          false,
          cellData.row.id.concat(cellData.column.HeaderValue),
          rowIndex,
        )
      }
    >
      {isRowSelected && (
        <div style={{ backgroundColor: theme.brandPrimary, height: cellStyles.borderHeight }}></div>
      )}
      <div
        className={cellData.row.isActive === false ? addClassNames.concat(' NotActiveCell') : null}
        style={{
          ...cellStyles,
          alignItems: cellStyles.customAlignItems || 'center',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          marginTop: isFirstRow && !isRowSelected ? cellStyles.borderHeight : 0,
          height: cellStyles.height - (isRowSelected ? cellStyles.borderHeight * 3 : 0 + (isFirstRow ? 0 : 0)),
        }}
      >
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {cellContent}
        </div>
      </div>
      {isRowSelected && (
        <div style={{ backgroundColor: theme.brandPrimary, height: cellStyles.borderHeight }}></div>
      )}
    </td>
	);
};

const TableOptimizedCell = props => {
  const { cellData, isFirstRow, rowIndex } = props;
  const { selectedCell, cellClickHandler } = useContext(ReportContext);
  const isRTL = useSelector(state => state.app.rtl);
  const memoizedCellData = useMemoizedValue(cellData);
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    const newIsSelected = selectedCell?.cell?.row?.id === memoizedCellData?.row?.id;
    if (isSelected !== newIsSelected)
      setIsSelected(newIsSelected);
  }, [selectedCell?.cell?.row?.id, memoizedCellData?.row?.id]);

  const memoizedTableOptimizedCell = useMemo(() => {
    return (
      <TableCell
        isFirstRow={isFirstRow}
        rowIndex={rowIndex}
        cellData={memoizedCellData}
        cellClickHandler={cellClickHandler}
        isRowSelected={isSelected}
        isRTL={isRTL}
      />
    );
  }, [isRTL, isSelected, cellClickHandler, memoizedCellData, isFirstRow, rowIndex]);

  return memoizedTableOptimizedCell;
}

export default TableOptimizedCell;