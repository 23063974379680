import React from "react";
import { injectIntl } from 'react-intl';
import { connect  } from 'react-redux';
import { compose, hoistStatics } from 'recompose';
import { connectContext } from 'react-connect-context';
import { ProjectContext } from '../../../common/projects/contexts';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import withStyles from "@material-ui/core/styles/withStyles";
import Accordion from "../../components/Accordion/Accordion.jsx";
import CardHeader from "../../components/Card/CardHeader.jsx";
import CardBody from "../../components/Card/CardBody.jsx";
import buttonStyle from "../../assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";
import systemMessages from '../../../common/app/systemMessages';
import { startToast } from '../../../common/app/actions';
import { getChecklists, getNewChecklistId } from '../../../common/checklists/actions'
import { getChecklistItems, getNewChecklistItemId } from '../../../common/checklistItems/actions'
import theme from "../../assets/css/theme";
import Text from "../../components/CementoComponents/Text";
import _ from 'lodash'


const SortableItem = SortableElement(({value}) => value);
const SortableList = SortableContainer(({items}) => {
  return (
    <div style={{ flexDirection: 'column', flex:1, cursor: "pointer", }}> 
      {items.map((value, index) => <SortableItem key={`item-${index}`} index={index} onClick={value.props.onClick ? value.props.onClick : undefined} value={value} />)}
    </div>);
});


class ParentChildView extends React.Component {
  constructor(props) {
    super(props);
    this.changeMode = this.changeMode.bind(this);
    this.changeMode = this.changeMode.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
    this.onRowSelect = this.onRowSelect.bind(this);
    this.onRowChange = this.onRowChange.bind(this);
    this.setComponentData = this.setComponentData.bind(this);
    this.onItemDelete = this.onItemDelete.bind(this);
    this.onItemDuplicate = this.onItemDuplicate.bind(this);
    this.onItemCreate = this.onItemCreate.bind(this);
    this.state = { 
      editCategoriesMode: props.categoriesMode || false,
    };
  }
  
  componentWillMount() {
    this.setComponentData({}, this.props)
  }

  componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps)
  }

  setComponentData(props, nextProps) {
    const {  } = props;
    let newStateChanges = {};
    if (Object.keys(newStateChanges).length > 0)
      this.setState(newStateChanges);
  }

  onRowSelect(parentIndex, childIndex) {
    const { onRowSelect } = this.props;
    if (onRowSelect)
      onRowSelect(parentIndex, childIndex);
  }
  
  onRowChange(newRowObject, parentIndex, childIndex) {
    const { onRowChange } = this.props;
    if (onRowChange)
      onRowChange(newRowObject, parentIndex, childIndex);
  }

  onSortEnd({oldIndex, newIndex}, sectionIndex) {
    const { onSort, sections } = this.props;
    let newSections = (sections || []).slice()
    if (!sectionIndex && sectionIndex != 0) 
      newSections = arrayMove(sections, oldIndex, newIndex);
    else {
      let newOrder = arrayMove(sections[sectionIndex].childs, oldIndex, newIndex);
      newSections[sectionIndex].childs = newOrder;
    }

    if (onSort)
      onSort(newSections);
  };
  
  changeMode() {
    const { onChangeMode } = this.props;
    const newMode = !this.state.editCategoriesMode;
    this.setState({ 
      editCategoriesMode: newMode, 
    });

    if (onChangeMode) onChangeMode(newMode);
  }

  onItemDuplicate() {
    const { selectedParentIndex, selectedChildIndex, sections, onItemDuplicate } = this.props;
    if (!onItemDuplicate || !sections || ((!selectedParentIndex && selectedParentIndex != 0) && (!selectedChildIndex && selectedChildIndex != 0)))
      return;
    
    onItemDuplicate(selectedParentIndex, selectedChildIndex);
  }

  onItemDelete() { 
    const { selectedParentIndex, selectedChildIndex, sections, onItemDelete, onItemLogicDelete } = this.props;
    if ((!onItemDelete && !onItemLogicDelete) || !sections || ((!selectedParentIndex && selectedParentIndex != 0) && (!selectedChildIndex && selectedChildIndex != 0)))
      return;
    let deletedItem = _.get(sections, [selectedParentIndex, 'childs', selectedChildIndex])
    let newSections = sections.slice();
    if (onItemDelete) {
      if (selectedChildIndex || selectedChildIndex === 0) 
        newSections[selectedParentIndex].childs.splice(selectedChildIndex, 1);   
      else if (selectedParentIndex || selectedParentIndex === 0) 
        newSections.splice(selectedParentIndex, 1);
      onItemDelete(newSections, selectedParentIndex, deletedItem);
    }
    else if (onItemLogicDelete) {
      if (selectedChildIndex || selectedChildIndex === 0)
        newSections[selectedParentIndex].childs[selectedChildIndex] = newSections[selectedParentIndex].childs[selectedChildIndex].setNested(['isDeleted'], !Boolean(newSections[selectedParentIndex].childs[selectedChildIndex].isDeleted))
      else if (selectedParentIndex || selectedParentIndex === 0) {
        newSections[selectedParentIndex].parent = newSections[selectedParentIndex].parent.setNested(['isDeleted'], !Boolean(newSections[selectedParentIndex].parent.isDeleted));
        newSections[selectedParentIndex].childs.forEach((v,i) => newSections[selectedParentIndex].childs[i] = newSections[selectedParentIndex].childs[i].setNested(['isDeleted'], !Boolean(newSections[selectedParentIndex].childs[i].isDeleted)))
      }
      onItemLogicDelete(newSections);
    }
  }

  onItemCreate(sectionId) { 
    const { onItemCreate } = this.props;

    if (onItemCreate) {
      onItemCreate(sectionId);
    }
  }
  
  render() {
    const { style, onItemCreate, selectedParentIndex, sourceBasePath = [], objectsSourcesKey, onItemLogicDelete, selectedChildIndex, sections, editable, rowComponent, parentSectionIndications, parentSectionTitlePath, childsTitlePath, disableDrag, onChangeMode, onItemDuplicate, onItemDelete, lang, rtl, selectedPropertyId } = this.props;
    const { editCategoriesMode } = this.state;
    const Row = rowComponent;
    let btnStyle = { borderRadius:'5px', display:'flex', padding:'0px 5px', minWidth: 75, height:30, alignSelf:'center', justifyContent:'center', alignItems:'center', margin: 5, border: '1px solid ' + theme.brandPrimary + '85', color: theme.brandPrimary, cursor: 'pointer' };
    return (
      <div style={style}>
        <CardHeader> 
          <div style={{display:'flex', flex:1, flexDirection: 'row-reverse'}}>
            {Boolean(onChangeMode) && <div style={{...btnStyle, backgroundColor: theme.brandPrimary + '10'}} onClick={this.changeMode}><Text>{editCategoriesMode ? systemMessages.manage.childsMode : systemMessages.manage.parentsMode}</Text></div>}
            {Boolean(onItemDuplicate) && <div style={btnStyle} onClick={this.onItemDuplicate}><Text>{systemMessages.manage.duplicate}</Text></div>}
            {Boolean(onItemDelete||onItemLogicDelete) && <div style={btnStyle} onClick={this.onItemDelete}><Text>{systemMessages.manage.delete}</Text></div>}
            {Boolean(onItemCreate && editCategoriesMode) && <div style={btnStyle} onClick={() => this.onItemCreate()}><Text>יצירת קטגוריה חדש</Text></div>}
          </div>
        </CardHeader>
        <CardBody>
          {Boolean(editCategoriesMode) ? 
            <SortableList
              key={'sortableParents'}
              useDragHandle={true}
              onSortEnd={disableDrag ? null : ((e) => { this.onSortEnd(e)})}
              items={sections.map((currSection, currSectionIndex) => { 
                let parent = currSection.parent ? currSection.parent : currSection;
                return (
                <Row 
                  key={currSectionIndex+'parent'}
                  selectedPropertyId={selectedPropertyId}
                  lang={lang}
                  isParent={true}
                  editable={editable}
                  disableDrag={disableDrag}
                  titlePath={childsTitlePath}
                  indications={parentSectionIndications}
                  isActive={(currSectionIndex == selectedParentIndex)}
                  onChange={(newRowObject) => this.onRowChange(newRowObject, currSectionIndex, null)}
                  onClick={() => this.onRowSelect(currSectionIndex, null)}
                  value={parent}
                  objectSourceBasePath={sourceBasePath.concat('sections')}/>
                )
              }
            )}/> 
          :
          <Accordion rtl={rtl} collapses={ sections.map((currSection, currSectionIndex) => {  
            let parent = currSection.parent ? currSection.parent : currSection;
            const parentId = _.get(currSection, ['parent', 'id']);
            let childs = currSection.childs ? currSection.childs : [];
            let title = parent.stage ? parent.stage + " - ": "";
            let color = null;
            let source = null;
            let opacity = 1;
            title += parent.getNested(parentSectionTitlePath);
            if (parentSectionIndications && parentSectionIndications.source) {
              source = parent.getNested(parentSectionIndications.source.path);
              color = parentSectionIndications.source.colors[source];
            }
            if (parentSectionIndications && parentSectionIndications.isDeleted) {
              opacity = Boolean(parent.getNested(parentSectionIndications.isDeleted.path)) ? 0.25 : 1;
            }
            if (parentSectionIndications && parentSectionIndications.isDuplicated && parent.getNested(parentSectionIndications.isDuplicated.path)) {
              opacity = 0.75;
              if (parentSectionIndications.isDuplicated.titleExtraInfo && parent.getNested(parentSectionIndications.isDuplicated.titleExtraInfo.path))
                title += " - " + parent.getNested(parentSectionIndications.isDuplicated.titleExtraInfo.path);
            }

            return {
              title: 
                <div style={{display:'flex', flex:1, opacity: opacity }}>
                  <div style={{flex: 1}}>{title}</div>
                  {Boolean(source) && <div style={{backgroundColor:color, margin: '0px 10px', width:140, fontSize:10, overflow:'hidden', borderRadius: 15, padding: '0px 10px', textAlign:'center', color: theme.backgroundColorBright}}>{parent.id}</div>}
                  {Boolean(source) && <div style={{backgroundColor:color, margin: '0px 10px', width:100, fontSize:14, overflow:'hidden', borderRadius: 15, padding: '0px 10px', textAlign:'center', color: theme.backgroundColorBright}}>{source}</div>}
                </div>,
              content: 
                <>
                  {Boolean(onItemCreate) && <div style={btnStyle} onClick={() => this.onItemCreate(parent.id)}><Text>יצירת מאפיין חדש</Text></div>}
                    <SortableList
                      key={parent.id}
                      useDragHandle={true}
                      onSortEnd={disableDrag ? null : (e) => { this.onSortEnd(e, currSectionIndex)}}
                      items={childs.map((currChild, currChildIndex) => 
                        <Row 
                          key={currSectionIndex + '-' + currChildIndex}
                          selectedPropertyId={selectedPropertyId}
                          lang={lang}
                          isParent={false}
                          editable={editable}
                          disableDrag={disableDrag}
                          titlePath={childsTitlePath}
                          indications={parentSectionIndications}
                          isActive={(currSectionIndex == selectedParentIndex && currChildIndex == selectedChildIndex)}
                          onClick={() => this.onRowSelect(currSectionIndex, currChildIndex)}
                          onChange={(newRowObject) => this.onRowChange(newRowObject, currSectionIndex, currChildIndex)}
                          value={currChild}
                          objectSourceBasePath={sourceBasePath.concat('properties')}/>
                      )}/>
                </> 
              }})
            }/>
          }
        </CardBody>
      </div>
    );
  }
}


ParentChildView = withStyles(buttonStyle)(ParentChildView);
ParentChildView = injectIntl(ParentChildView);
const enhance = compose(
  connectContext(ProjectContext.Consumer),  
  connect(null,
  { startToast, getChecklists, getChecklistItems, getNewChecklistId, getNewChecklistItemId })
);
export default enhance(ParentChildView);