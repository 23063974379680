import React, { Component, useEffect, useState } from 'react';
import { hoistStatics, compose } from 'recompose';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import _ from 'lodash';

import Post from '../views/Posts/Post';
import ObjectPropertiesPage from '../views/Properties/ObjectPropertiesPage';
import ChecklistsCard from '../views/Checklists/ChecklistsCard';
import ChecklistItemCard from '../views/Checklists/ChecklistItemCard';
import DrawingCard from '../views/Drawings/DrawingCard';
import ChecklistManagerCard from '../views/ProjectManager/ChecklistManagerCard';
import AddNewCertification from '../views/Properties/AddNewCertification';
import { Card, CardHeader, CardBody, CardTabs } from '../components';
import Text from '../components/CementoComponents/Text';

import { PrintOutlined } from '@material-ui/icons';
import editPen from '../assets/img/icons/editPen.png';
import trash from '../assets/img/icons/trash.png';

import theme from '../assets/css/theme';
import postsMessages from '../../common/posts/postsMessages';
import issuesMessages from '../../common/issues/issuesMessages';
import systemMessages from '../../common/app/systemMessages';
import TradeBadge from '../components/CementoComponents/TradeBadge';
import * as issueStates from '../../common/issues/issueStates.js';
import ProjectStructureCard from '../views/ProjectManager/ProjectStructureCard';
import UsersManagementCard from '../views/Members/UsersManagementCard';
import LocationsGroupsManagerCard from '../views/ProjectManager/LocationsGroupsManagerCard';
import Modal from '../components/CementoComponents/Modal';
import FormsCard from '../views/Forms/FormsCard';
import { Children } from 'react';
import MenuScrollbar from '../components/CementoComponents/MenuScrollbar';
import NothingToDisplay from '../components/CementoComponents/NothingToDisplay';
import DotStatusText from '../components/CementoComponents/DotStatusText';
import ObjectPropertiesPageUI from '../views/Properties/ObjectPropertiesPageUI';
/**
 * @typedef {'_blankCard' | 'post' | 'forms' | 'objectProperties' | 'connectedObjectProperties' | 'addCertification' | 'stage' | 'checklists' | 'checklistItem' | 'checklistManager' | 'drawing' | 'projectStructure' | 'member' | 'company' | 'locationsGroup'} CardTypes
 * @typedef {{ props?: object, type: CardTypes }} SideObject
 * @typedef SideCardProps
 * @property {SideObject} sideObject
 * @property {boolean} [editMode]
 * @property {(newState?: boolean) => void} [onEditModeChange]
 *
 * @extends React.Component<SideCardProps>
 */
class SideCard extends Component {
	constructor(props) {
		super(props);
		this.onLoad = this.onLoad.bind(this);
		this.handleEditModeChange = this.handleEditModeChange.bind(this);
		this.handleSave = this.handleSave.bind(this);

		this.initialState = {
			isCreationMode: false,
			contentType: null,
			headerParams: null,
			tabsParams: null,
			loadTS: null,
		};

		this.state = this.initialState;
	}

	componentWillMount() {
		const { onRef } = this.props;
		if (onRef) onRef(this);
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.isValDiff(nextProps, ['sideObject', 'type'])) this.setState(this.initialState);
	}

	onLoad(headerParams, tabsParams, editMode, isCreationMode, contentType = '') {
		const { onEditModeChange } = this.props;
		let stateChanges = {
			loadTS: new Date().getTime(),
			headerParams,
			tabsParams,
			contentType,
			isCreationMode: Boolean(isCreationMode),
		};

		if (!_.isNil(editMode) && onEditModeChange) onEditModeChange(editMode);

		this.setState(stateChanges);
	}

	async handleSave() {
		const { headerParams = {} } = this.state;
		const { onSave } = headerParams || {};

		const success = onSave && (await onSave());
		if (success !== false) this.handleEditModeChange(false);
	}

	handleEditModeChange(bool, isCreationMode = false, contentType) {
		const { onEditModeChange } = this.props;

		if (onEditModeChange) onEditModeChange(bool);

		let stateChanges = { isCreationMode };

		if (contentType) stateChanges.contentType = contentType;

		this.setState(stateChanges);
	}

	getPostStateBadgeParams = postState => {
		let statusText = postsMessages.documentation;
		let statusColor = theme.brandNeutral;

		switch (postState) {
			case issueStates.ISSUE_STATE_OPENED: {
				statusText = issuesMessages.openStatus;
				statusColor = theme.brandDanger;
				break;
			}
			case issueStates.ISSUE_STATE_RESOLVED: {
				statusText = issuesMessages.resolveStatus;
				statusColor = theme.brandWarning;
				break;
			}
			case issueStates.ISSUE_STATE_CLOSED: {
				statusText = issuesMessages.closeStatus;
				statusColor = theme.brandSuccess;
				break;
			}
		}

		return { statusText, statusColor };
	};

	handleSetHeaderParams = newHeaderParams => {
		const { headerParams } = this.state;

		this.setState({ headerParams: Object.assign({}, headerParams, newHeaderParams) });
	};

	handleCancel = async () => {
		const { onCancel } = this.state.headerParams || {};

		let isCancelSuccess = true;

		if (onCancel) {
			const onCancelRes = await onCancel();
			if (onCancelRes === false) isCancelSuccess = false;
		}

		return isCancelSuccess;
	};

	render() {
		const { headerParams, tabsParams, loadTS, contentType, isCreationMode } = this.state;
		const { sideObject, rtl, intl, children, editMode, viewType } = this.props; //viewType == 'modal' || 'side'
		const {
			onPrint,
			editable,
			onDelete,
			title,
			tradeIds,
			postState,
			onCancel,
			statusBadgeParams,
			middleComponent: headerMiddleComponent,
		} = headerParams || {};

		if (!sideObject) return children && children({});

		let header, alert, tabs;
		let objectName = _.get(sideObject, ['props', 'objectName'], contentType);
		objectName =
			_.has(objectName, 'id') && _.has(objectName, 'defaultMessage') ? intl.formatMessage(objectName) : objectName;

		const headerTextStyle = Object.assign(
			{},
			styles.headerText,
			{ [rtl ? 'marginLeft' : 'marginRight']: theme.paddingSize },
			viewType === 'modal' && { fontSize: styles.editModeTitle.fontSize },
		);

		let badgeParams = statusBadgeParams;
		if (postState) badgeParams = this.getPostStateBadgeParams(postState);

		let status = null;
		if (badgeParams) {
			const { statusText, statusColor } = badgeParams;
			const badgeSize = viewType === 'modal' ? 10 : 7;
			status = (
				<DotStatusText
					label={statusText}
					dotStyle={{
						height: badgeSize,
						width: badgeSize,
						marginLeft: theme.margin / 3,
						marginRight: theme.margin / 3,
					}}
					labelStyle={headerTextStyle}
					color={statusColor}
				/>
			);
		}
		
		const shouldShowSideDiv = 
			(((tradeIds || []).length > 0) || 
			((headerParams || {}).customIcons) || 
			(onPrint && !editMode) || 
			(onDelete && !editMode) || 
			(editable));
		
		header = (
			<CardHeader style={styles.headerContainer}>
				<div style={{ display: 'flex', flex: 1 }}>
					{Boolean(!editMode) && status}
					<div style={{ flex: 1 }}>
						{Boolean(editMode)
							? Boolean(objectName) && (
									<Text style={styles.editModeTitle} values={{ contentType: objectName }}>
										{systemMessages[`${isCreationMode ? 'add' : 'edit'}Object`]}
									</Text>
							  )
							: Boolean(title) && <Text style={headerTextStyle}>{title}</Text>}
					</div>
				</div>

				{Boolean(headerMiddleComponent) && (
					<div style={{ display: 'flex', flex: 1, justifyContent: `${rtl ? 'flex-end' : 'flex-start'}`, height: '100%', alignItems: 'center' }}>
						{headerMiddleComponent}
					</div>
				)}

				{Boolean(shouldShowSideDiv) && (
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							[`margin${rtl ? 'Left' : 'Right'}`]: theme.margin / 3,
							justifyContent: 'flex-end',
							flex: 1,
						}}
					>
						{Boolean((tradeIds || []).length > 0) && <TradeBadge ids={tradeIds} />}

						{((headerParams || {}).customIcons || []).map(icon => {
							if (icon.onClick && icon.component && (!editMode || icon.showOnEditMode))
								return (
									<div style={styles.icon} onClick={icon.onClick}>
										{icon.component}
									</div>
								);
						})}

						{Boolean(onPrint && !editMode) && (
							<PrintOutlined style={{ fontSize: 16, lineHeight: '16px', ...styles.icon }} onClick={onPrint ? onPrint : undefined} />
						)}

						{Boolean(onDelete && !editMode) && (
							<div style={styles.icon} onClick={onDelete ? onDelete : undefined}>
								<img style={{ width: 16, height: 16, marginTop: -4 }} src={trash} />
							</div>
						)}

						{Boolean(editable) && (
							<div style={{ display: 'flex', height: '100%', alignItems: 'center', ...styles.icon }}>
								{editMode ? (
									<>
										{Boolean(onCancel) && (
											<Text style={{ margin: 5, fontSize: 16 }} onClick={this.handleCancel}>
												{systemMessages.cancel}
											</Text>
										)}
										<Text style={{ color: theme.brandPrimary, margin: 5, fontSize: 16 }} onClick={this.handleSave}>
											{systemMessages.save}
										</Text>
									</>
								) : (
									<img style={{ width: 13, height: 15 }} src={editPen} onClick={this.handleEditModeChange} />
								)}
							</div>
						)}
					</div>
				)}
			</CardHeader>
		);

		if (headerParams && headerParams.alert)
			alert = (
				<CardHeader style={{ ...styles.headerContainer, justifyContent: 'center' }}>
					<Text style={{ color: 'red' }}>{headerParams.alert}</Text>
				</CardHeader>
			);

		if (!editMode && tabsParams && (tabsParams.tabs || []).length)
			tabs = (
				<CardHeader style={styles.tabsContainer}>
					<CardTabs
						maxLines={1}
						maxChars={15}
						key={`CardTabs${loadTS}`}
						onClick={tabsParams.onTabSelect}
						tabs={tabsParams.tabs}
					/>
				</CardHeader>
			);

		return (
			children &&
			children({
				header,
				alert,
				tabs,
				cardBody: (
					<InnerSideCardBody
						sideObject={sideObject}
						editMode={editMode}
						onCardLoad={this.onLoad}
						changeEditMode={this.handleEditModeChange}
						setCardHeaderParams={this.handleSetHeaderParams}
					/>
				),
			})
		);
	}
}

SideCard = injectIntl(SideCard);
const enhance = compose(
	connect(
		state => ({
			rtl: state.app.rtl,
		}),
		{},
	),
);

export default enhance(SideCard);

const styles = {
	headerContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		minHeight: theme.headerHeightSecondary,
		paddingLeft: 2 * theme.paddingSize,
		paddingRight: 2 * theme.paddingSize,
		backgroundColor: theme.backgroundColor,
		borderBottom: theme.borderLineHeaderInfo,
	},
	headerText: {
		flex: 1,
		...theme.subFont,
		color: '#2e231d',
		fontSize: theme.fontSize,
		fontWeight: theme.strongBold,
		width: 'auto',
	},
	editModeTitle: {
		color: theme.brandPrimary,
		fontWeight: theme.bold,
		fontSize: theme.fontSizeH5,
	},
	tabsContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: theme.headerHeightSecondary,
		backgroundColor: theme.backgroundColorBright,
		borderBottom: '2px solid ' + theme.brandNeutralLight + '30',
		paddingLeft: 2 * theme.paddingSize,
		paddingRight: 2 * theme.paddingSize,
	},
	icon: {
		marginLeft: theme.margin / 3,
		marginRight: theme.margin / 3,
		cursor: 'pointer',
	},
};

const InnerSideCardBody = props => {
	let { sideObject, editMode, onCardLoad, changeEditMode, setCardHeaderParams } = props;
	let { type, props: sideObjectProps, Component } = sideObject;

	const originalEditMode = sideObjectProps.editMode;
	const cardProps = Object.assign({}, sideObjectProps, { editMode, onCardLoad, changeEditMode, setCardHeaderParams });

	const { selectedCell } = cardProps;
	let card = <></>;

	switch (type) {
		case 'post':
			card = <Post key={'postcard'} {...cardProps} />;
			break;
		case 'forms':
		case 'connectedObjectProperties': // This version of the object properties page is connected directly to the reducer and handles savin of the instances logic
			card = <ObjectPropertiesPage useCollapsibleSections={type === 'forms'} {...cardProps} />;
			break;
		case 'objectPropertiesPage': { // This version of the object properties page receive all of the properties mechanism as props ad relies on the user of the page to handle all of the saving, deleting logic and other things
			card = <ObjectPropertiesPageUI {...cardProps} />;
			break;
		}
		case 'addCertification':
			card = <AddNewCertification {...cardProps} />;
			break;
		case 'stage': // TODO: create stage side card
			break;
		case 'checklists':
			if (selectedCell) {
				Object.assign(cardProps, {
					..._.pick(selectedCell, ['posts', 'locationsData', 'isAggregatedCell', 'alert']),
					selectedChecklists: selectedCell.checklists,
					selectedChecklistItems: selectedCell.checklistItems,
				});
			}
			card = <ChecklistsCard {...cardProps} />;
			break;
		case 'checklistItem':
			card = <ChecklistItemCard {...cardProps} />;
			break;
		case 'checklistManager':
			card = <ChecklistManagerCard {...cardProps} />;
			break;
		case 'drawing':
			card = <DrawingCard {...cardProps} />;
			break;
		case 'projectStructure':
			card = <ProjectStructureCard {...cardProps} />;
			break;
		case 'member':
		case 'company':
			card = <UsersManagementCard {...cardProps} />;
			break;
		case 'locationsGroup':
			card = <LocationsGroupsManagerCard {...cardProps} />;
			break;

		case 'nothingToDisplay':
			card = <NothingToDisplay />;
			break;

		case '_blankCard': // WARNING !!! if using _blankCard, you must provide a "componentId" so the card doesnt rerender completely at every render
			card = <BlankCard originalEditMode={originalEditMode} Component={Component} cardProps={cardProps} />;
			break;

		default:
			card = null;
			break;
	}

	return <CardBody style={{ maxHeight: '100%', height: '100%' }}>{card}</CardBody>;
};

const BlankCard = props => {
	const { Component, originalEditMode, cardProps = {} } = props;
	const { headerParams, tabsParams, isCreationMode, contentType, onCardLoad, componentId, noScrollBars } = cardProps;

	const [node, setNode] = useState(null);

	// will/did Mount
	useEffect(() => {
		if (onCardLoad) onCardLoad(headerParams, tabsParams, originalEditMode, isCreationMode, contentType);
	}, []);

	useEffect(() => {
		setNode(React.createElement(Component, cardProps, null));
	}, [componentId]);

	return (
		<div style={{ display: 'flex', flexDirection: 'column', height: 'inherit', width: 'inherit' }}>
			{Boolean(headerParams && headerParams.title && !headerParams.noTitle) && (
				<Text
					style={{ fontSize: theme.fontSizeH4, fontWeight: 'bold', alignSelf: 'flex-start', padding: theme.margin * 2 }}
				>
					{headerParams.title}
				</Text>
			)}
			{Boolean(noScrollBars) ? (
				<div style={{ flex: 1 }}>{node}</div>
			) : (
				<MenuScrollbar scrollbarsStyle={{ overflowX: 'hidden', flex: 1 }} isSmooth={true}>
					{node}
				</MenuScrollbar>
			)}
		</div>
	);
};
