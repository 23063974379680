import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose, hoistStatics } from 'recompose';
import { connectContext } from 'react-connect-context';
import { ProjectContext } from '../../../common/projects/contexts';
import { withRouter } from 'react-router-dom';
import _, { isEmpty } from 'lodash';

// @material-ui/icons
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import { PrintOutlined } from '@material-ui/icons';

// core components
import TableWrapper from '../Reports/TableWrapper';
import TextFilter from '../Posts/TextFilter';
import InputField from '../../components/CementoComponents/InputField';
import Text from '../../components/CementoComponents/Text';
import TradesSelector from '../../components/CementoComponents/TradesSelector';
import HoverWrapper from '../../components/CementoComponents/HoverWrapper';
import TableFilters from '../Reports/TableFilters';
import FilterMenuHOC from '../../components/CementoComponents/FilterMenu';

// actions
import { onDraftModeChange } from '../../../common/ui/actions';
import { getEmployees, getEmployeeNewId } from '../../../common/employees/actions';
import { getEquipment, getEquipmentNewId } from '../../../common/equipment/actions';
import {
	upsertForm,
	getNewFormId,
	startFormsListenerByType,
	endFormsListenerByType,
} from '../../../common/forms/actions';
import { startToast, startLoading, hideLoading, saveMenus } from '../../../common/app/actions';
import { getNewPropertyInstanceId, uploadPropertiesInstances } from '../../../common/propertiesInstances/actions';
import { exportFormPDF } from '../../../common/pdf/actions';
import { track } from '../../../common/lib/reporting/actions';

import theme from '../../assets/css/theme';

import { lokiInstance } from '../../../common/configureMiddleware';

import 'react-table/react-table.css';
import '../../assets/css/table.css';
import plus_primary from '../../assets/img/icons/plus_primary.png';

// funcs
import { getPropertiesInfo, getMembers, getCompanies, getLocationsObjectsForTable } from '../Reports/funcs';
import { populateObject } from '../../../common/propertiesInstances/funcs';
import { convertCementoML, isEmptyValue, onError, safeFormatMessage } from '../../../common/app/funcs';
import { validatePropType } from '../../../common/propertiesTypes/funcs';
import { getObjectsTitlesMap } from './funcs';
import { fetchQuasiStatics } from '../../../common/quasiStatics/funcs';
import { getAllLocationTitlesMap, setFlatLocationsTitlesMap } from '../Locations/funcs';
import { preProcessData } from '../../../common/analytics/funcs';

import EditModeToggle from '../../components/CementoComponents/EditModeToggle';
import * as propertyTypes from '../../../common/propertiesTypes/propertiesTypes';

import { getMembersProjectsTableDataObj, getTableWrapperProps, getSubcontractorProjectsTableDataObj, getLocationsGroupsTableDataObj, getPropertiesTableDataObject, getEmployeesPresenceTableDataObj,TableDataObject, ORIGINAL_VALUE_KEY_TERM, TableCell } from "../Reports/tableWrapperHelpers";
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import ImageCarousel from "../../components/CementoComponents/ImageCarousel";

// messages
import usersMessages from '../../../common/users/usersMessages';
import pdfMessages from '../../../common/app/pdfMessages';
import safetyMessages from '../../../common/safety/safetyMessages';
import propertiesMessages from '../../../common/propertiesTypes/propertiesMessages';
import siteControlMessages from '../../../common/siteControl/siteControlMessages';
import analyticsMessages from '../../../common/analytics/analyticsMessages';
import unitsMessages from '../../../common/units/unitsMessages';
import buildingsMessages from '../../../common/buildings/buildingsMessages';
import floorsMessages from '../../../common/floors/floorsMessages';
import newProjectMessages from '../../../common/projects/newProjectMessages';
import systemMessages from '../../../common/app/systemMessages';
import * as utils from '../../../common/lib/utils/utils';
import reportsMessages from '../../../common/reports/reportsMessages';
import { QCReportContext } from '../../../common/analytics/contexts';

import { getFormStatusParams, formStatusesArray, getStatusMessage } from '../../../common/forms/formsStatusStates';
import Calendar from '../../components/CementoComponents/Calendar/index';
import { getRoundedDate } from '../../../common/lib/utils/utils';
import Modal from '../../components/CementoComponents/Modal';
import { CardHeader } from '../../components';
import { getEmployeesPresence, getLastSyncTSFromCamerasMonitor, syncCameras, startMonitorListener, getEmployeesTablesMetadata } from '../../../common/siteControl/funcs'
import StandardInput from "../../components/CementoComponents/StandardInput";
import { SUPPORTED_FILTER_TYPES } from '../../components/CementoComponents/FilterMenu/FilterMenuComponent';
import DateRangePicker from '../../components/CementoComponents/DateRangePicker';
import { TABLES_FILTER_URL_KEY } from "../../app/constants";
import moment from "moment";
import { safeToJS } from '../../../common/permissions/funcs';
import MonthPicker from '../../../common/app/components/MonthPicker';
import Select from 'react-select';
import CamerasMonitor from '../../components/SiteControl/CamerasMonitor';

// Const
const cellHeight = 40;
const customWidth = 171;
const customTop = 51;
const largeWidth = 22;
const smallWidth = 13;
const defaultSubMenuType = 'analytics';

const CALENDAR_HEADER_HEIGHT = 48;

const SAFE_DELETE_KEYWORD = 'SAFE_DELETE';

const WITH_IDS = 'withIds';
const WITH_FILES = 'withFiles';
const WITH_FILE_URLS = 'withFileUrls';

const FILTER_MENU_PATH_DELIMETER = '/--_thisIsADelimeter_--/';

const messagesTypesMap = {
  safety: safetyMessages,
  siteControl: siteControlMessages,
  properties: propertiesMessages
};


const PAGE_CONFIGURATIONS = {
  subjectTypes: {
    forms: {
      shouldSetCounterRows: false,
    },
    employees: {
      shouldSetCounterRows: true,
    },
    equipment: {
      shouldSetCounterRows: true,
    },
    locations: {
      shouldSetCounterRows: true,
    },
  }
}

const CONTENT_TO_FEATURE_MAP = {
  safety: 'safety',
  info: 'spec',
  forms: 'dailyReport',
  siteControl: 'siteControl'
};

class PropertyAnalytics extends React.Component {
  constructor(props) {
    super(props);

    this.handleUpdateSelectedFilterSet = this.handleUpdateSelectedFilterSet.bind(this);
    this.setComponentData = this.setComponentData.bind(this);
    this.calculateTableDataProps = this.calculateTableDataProps.bind(this);
    this.displayMembersView = this.displayMembersView.bind(this);
    this.displayLocationGroupsView = this.displayLocationGroupsView.bind(this);
    this.propertiesToTableProps = this.propertiesToTableProps.bind(this);
    this.filtersHandler = this.filtersHandler.bind(this);
    this.getBusinessTypesColumn = this.getBusinessTypesColumn.bind(this);
    this.buildTradesList = this.buildTradesList.bind(this);
    this.handleCardChangeStatus = this.handleCardChangeStatus.bind(this);
    this.loadSubjectType = this.loadSubjectType.bind(this);
    this.handleObjectCreate = this.handleObjectCreate.bind(this);
    this.handleObjectCreateCancel = this.handleObjectCreateCancel.bind(this);
    this.lokiListener = this.lokiListener.bind(this);
    this.handleSaveFilter = this.handleSaveFilter.bind(this);
    this.handleObjectDelete = this.handleObjectDelete.bind(this);
    this.getFilterMenuComponent = this.getFilterMenuComponent.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleFilterClear = this.handleFilterClear.bind(this);
    this.prepareDataForExport = this.prepareDataForExport.bind(this);
    this.prepareLocationsGroupDataForExport = this.prepareLocationsGroupDataForExport.bind(this);
    this.getPopulatedObjects = this.getPopulatedObjects.bind(this);
    this.onExcelImport = this.onExcelImport.bind(this);
    this.getUpdatedPropertiesFromExcel = this.getUpdatedPropertiesFromExcel.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.setEditMode = this.setEditMode.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleConfirmCancel = this.handleConfirmCancel.bind(this);
    this.handleConfirmSave = this.handleConfirmSave.bind(this);
    this.onSetHasModifications = this.onSetHasModifications.bind(this);
    this.onEditModeOff = this.onEditModeOff.bind(this);
    this.onLocalInstancesChange = this.onLocalInstancesChange.bind(this);
    this.exportRegistrationForm = this.exportRegistrationForm.bind(this);
    this.closeImageCarousel = this.closeImageCarousel.bind(this);
    this.onMemberUpdate = this.onMemberUpdate.bind(this);
    this.onCompanyUpdate = this.onCompanyUpdate.bind(this);
    this.onGroupUpdate = this.onGroupUpdate.bind(this);
    this.reCalcHeader = this.reCalcHeader.bind(this);
    this.displayEmployeesPresenceView = this.displayEmployeesPresenceView.bind(this);
    this.reCalcEmployeesPresence = this.reCalcEmployeesPresence.bind(this);
    this.handleGetEmployeesPresence = this.handleGetEmployeesPresence.bind(this);
    this.handleMonthPicker = this.handleMonthPicker.bind(this);
    this.onMonitorUpdated = this.onMonitorUpdated.bind(this);
    
    const defaultDateRange = {
      startTS: moment().startOf('month').valueOf(),
      endTS: moment().endOf('month').valueOf(),
    }

    this.promiseSetState = (stateChanges) => new Promise(resolve => this.setState(stateChanges, () => resolve(true)));

    this.state = {
      reportId: this.props.getNested(['match', 'params', 'reportId']),
      isEditMode: false,
      hasModifications: false,
      cardIsDisabled: true,
      displayPdf: false,
      formType: 'dailyReport',
      formTemplateId: '-dailyReportForm',
      selectedRange: defaultDateRange,
      hasUnSavedChanges: false,
    };
  }

  setHasUnSavedChanges = (value) => {
		this.setState({hasUnSavedChanges : value});
	}

	hasUnSavedChanges = () => {
		return this.state.hasUnSavedChanges;
	}

  componentWillMount() {
    const { subjectType } = this.state;
    const { contentType, selectedProjectId: projectId } = this.props;
    this.lokiPropertyInstances = lokiInstance.getCollection('propertyInstances');
    this.lokiPropertyInstances.cementoOn('lokiInstancesObjectAnalytics', this.lokiListener);

    const isSiteControlMonitorListenerAlreadySet = Boolean(this.removeSiteControlMonitorListener);
    if (contentType === 'siteControl' && !isSiteControlMonitorListenerAlreadySet) {
      this.removeSiteControlMonitorListener = startMonitorListener({ projectId, callback: this.onMonitorUpdated });
    }

    this.setComponentData({ firstMount: true }, this.props, subjectType);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  async onMonitorUpdated() {
    await this.promiseSetState({ shouldForceEmployeePresenceRefresh: true, shouldSkipLoadingOnEmployeePresenceFetching: true });
    const newState = await this.calculateTableDataProps(this.props, this.state);
    this.setState(newState);
  }

  componentWillUnmount() {
    const { selectedProjectId, endFormsListenerByType } = this.props;
    const { formType } = this.state;

    this._isMounted = false;

    if (this.lokiPropertyInstances)
      this.lokiPropertyInstances.cementoOff('lokiInstancesObjectAnalytics');
    if (this.lokiObjectAnalytics)
      this.lokiObjectAnalytics.cementoOff('lokiObjectAnalytics');
    if (this.removeSiteControlMonitorListener)
      this.removeSiteControlMonitorListener()
    endFormsListenerByType(selectedProjectId, formType);
  }

  componentWillReceiveProps(nextProps) {
    const { subjectType } = this.state;

    this.setComponentData(this.props, nextProps, subjectType);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { subjectType } = nextState;
    let nextSubjectType = subjectType === 'locationsGroupsManagement' ? 'locations' : subjectType;

    const shouldComponentUpdate = !_.isEqual(this.state, nextState) || (
      this.props.isValDiff(nextProps, ['selectedProjectId']) ||
      this.props.isValDiff(nextProps, ['viewer']) ||
      this.props.isValDiff(nextProps, ['configurations']) ||
      this.props.isValDiff(nextProps, ['buildings']) ||

      this.props.isValDiff(nextProps, ['propertiesTypes', nextSubjectType + 'Info']) ||
      this.props.isValDiff(nextProps, ['propertiesSections', nextSubjectType + 'Info']) ||
      this.props.isValDiff(nextProps, ['propertiesMappings', nextSubjectType + 'Info']) ||

      this.props.isValDiff(nextProps, ['units']) ||
      this.props.isValDiff(nextProps, ['floors']) ||
      this.props.isValDiff(nextProps, ['buildings'])
    );

    return shouldComponentUpdate;
  }

  onTableDataUpdate = async (props, state) => {
    let stateChanges = this.getPopulatedObjects(props, state);
        stateChanges = Object.assign(stateChanges, await this.calculateTableDataProps(props, Object.assign({}, state, stateChanges)));
    this._isMounted && this.setState(stateChanges);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { subjectType, filters, updatedPropertiesMap, hasModifications, isEditMode, } = this.state;
    const { originalColumns, originalSections, selectedFilterSet, employeesPresenceData } = this.state;

    if (prevState.hasModifications !== hasModifications       ||
        prevState.isEditMode !== isEditMode                   ||
        prevState.filters != filters                          ||
        prevState.selectedFilterSet != selectedFilterSet      ||
        prevState.subjectType != subjectType                  ||
        prevState.originalSections != originalSections        ||
        prevProps.menus != this.props.menus                   ||
        prevState.originalColumns != originalColumns          ||
        _.get(prevState, ['employeesPresenceData', 'monitor']) != _.get(employeesPresenceData, ['monitor'])
    ) {
      let filtersMenu = this.getFilterMenuComponent(this.props, this.state);
      this.reCalcHeader(filtersMenu);
      this.setState({ filtersMenu });
    }

    if (!subjectType)
      return;

    if (prevState.subjectType != subjectType) {
      this.loadSubjectType(this.props, this.state, subjectType); // TODO: Should this be with an await?
    }

    if ((prevState.subjectType != subjectType) || !_.isEqual(prevState.updatedPropertiesMap, updatedPropertiesMap) || !_.isEqual(prevState.filters, filters)) {
      this.onTableDataUpdate(this.props, this.state);
    }
  }


  lokiListener(collectionName, immediate) {
    const { subjectType } = this.state;
    if (!subjectType)
      return;

    if (collectionName == 'propertyInstances' || collectionName == subjectType || immediate)
      this.onTableDataUpdate(this.props, this.state);
  }

  async loadSubjectType(nextProps, nextState, subjectType, contentType) {
    const { getEmployees, getEquipment, selectedProjectId, startFormsListenerByType } = nextProps;
    const { formType } = nextState;

    let showRowsWithEmptyValue = false;

    let revokeEmployees = nextProps.getNested(["employeesLastRevoked", selectedProjectId], 0) < nextProps.getNested(["employeesLastRevokeAvailable", selectedProjectId], 0);
    let revokeEquipment = nextProps.getNested(["equipmentLastRevoked", selectedProjectId], 0) < nextProps.getNested(["equipmentLastRevokeAvailable", selectedProjectId], 0);

    let stateChanges = { showRowsWithEmptyValue, subjectType };
    this.setState(stateChanges);

    if (["employees", "equipment", "forms"].includes(subjectType)) {
      this.lokiObjectAnalytics = lokiInstance.getCollection(subjectType);
      if (subjectType == "employees")
        await getEmployees(nextProps.viewer, selectedProjectId, revokeEmployees);
      else if (subjectType == "equipment")
        await getEquipment(nextProps.viewer, selectedProjectId, revokeEquipment);
      else if (subjectType == "forms")
        await startFormsListenerByType(nextProps.viewer.id, selectedProjectId, formType);

      this.lokiObjectAnalytics.cementoOn('lokiObjectAnalytics', this.lokiListener);
      showRowsWithEmptyValue = true;
    }
  }

  async setComponentData(props, nextProps, subjectType) {
    const { menus, viewer, configurations, customReportId } = nextProps;

    let newChanges = {};
    if (props.viewer != nextProps.viewer || props.rtl != nextProps.rtl) {
      newChanges.filtersMenu = this.getFilterMenuComponent(nextProps, this.state);
    }

    let subMenus = menus ? menus[nextProps.contentType] : null;
    let newSubjectType = subjectType;
    if(nextProps.contentType === 'siteControl')
      newSubjectType = 'employees'

    let currFavSelected = null;

    let reportId = this.state.reportId;
    let nextReportId = nextProps.getNested(['match', 'params', 'reportId']);

    const featureName = _.get(this.state, ['featureName']);
    const nextFeatureName = CONTENT_TO_FEATURE_MAP[_.get(nextProps, ['contentType'])];

    let subMenusSectionTitle = nextProps.customSubMenuType || defaultSubMenuType;
    if (!nextReportId && subMenus && subMenus.getNested([subMenusSectionTitle, "options"], []).length > 0) {
      let subjectDefaultMenu = subMenus.getNested([subMenusSectionTitle, "options"], []).filter(x => x.subjectType == subjectType);
      if (subjectDefaultMenu.length)
        nextReportId = subjectDefaultMenu[0].id;
      else if (customReportId)
        nextReportId = customReportId;
      else
        nextReportId = subMenus.getNested([subMenusSectionTitle, "options"])[0].id;
    }

    // If reports did not load yet
    // TODO: remove this false
    if (false   &&  nextReportId && (!subMenus || !subMenus.getNested([subMenusSectionTitle, "options"], []).filter(x => x.id == nextReportId).length > 0))  
      return;

    newChanges.didLoadReport = true;
    if (((!props || reportId != nextReportId || featureName != nextFeatureName) || menus != props.menus)) {
      let favSelected = subMenus && subMenus.getNested([subMenusSectionTitle, "options"], []).filter(x => x.id == nextReportId);
      if (favSelected && favSelected.length) {
        currFavSelected = favSelected[0];
        newSubjectType = currFavSelected.subjectType;
        newChanges.subjectType = newSubjectType;
        newChanges.selectedFilterSet = currFavSelected;
        newChanges.filters = currFavSelected.values;
        newChanges.reportId = nextReportId;
        newChanges.filtersMenu = this.getFilterMenuComponent(nextProps, newChanges);
        const nextPropsContentType = _.get(nextProps, ['contentType']);
        const isCompanyPage = nextPropsContentType === 'settings';
        const featureName = CONTENT_TO_FEATURE_MAP[nextPropsContentType];
        newChanges.featureName = featureName;
        let isFeatureActive = isCompanyPage || 
          viewer.adminMode == 1 ||
          configurations.getNested(['features', featureName, 'isActive'], false) ||
          (featureName=='siteControl' && configurations.getNested(['cameras'], false));

        if (isCompanyPage)
          newChanges.noReportMenus = false;
        else
          newChanges.noReportMenus = !isFeatureActive || (subMenus.getNested([subMenusSectionTitle, 'options'], []).filter(option => (!Boolean(option.adminOnly) || viewer.adminMode == 1)).length == 0);
          
        this.reCalcHeader(newChanges.filtersMenu, newChanges.noReportMenus)
      }

      let nextProjId = nextProps.selectedProjectId;
      if (nextProps.projectLokiLoaded &&
        (props.projectLokiLoaded != nextProps.projectLokiLoaded) ||
        (newSubjectType != subjectType && newSubjectType) ||
        (props.selectedProjectId != nextProjId) ||
        (nextProps.storageCleaned && props.storageCleaned != nextProps.storageCleaned))
          this.loadSubjectType(nextProps, Object.assign({}, this.state, newChanges), newSubjectType);
    }

    const shouldSetCounterRows = PAGE_CONFIGURATIONS.subjectTypes[newSubjectType]?.shouldSetCounterRows;
    if (newChanges.filters) {
      newChanges.filters = { ...newChanges.filters, shouldSetCounterRows  };
    }

    const propertiesChanged =
      !_.isEqual(_.get(props, ['propertiesSections', newSubjectType + 'Info']), _.get(nextProps, ['propertiesSections', newSubjectType + 'Info'])) ||
      !_.isEqual(_.get(props, ['propertiesTypes', newSubjectType + 'Info']), _.get(nextProps, ['propertiesTypes', newSubjectType + 'Info'])) ||
      !_.isEqual(_.get(props, ['propertiesMappings', newSubjectType + 'Info']), _.get(nextProps, ['propertiesMappings', newSubjectType + 'Info'])) ||
      props.employeesDidLoad !== nextProps.employeesDidLoad || props.equipmentDidLoad !== nextProps.equipmentDidLoad;

    if (subjectType !== newSubjectType || propertiesChanged )
      newChanges = Object.assign(newChanges, this.getPopulatedObjects(nextProps, Object.assign({}, this.state, newChanges), true));

    this.setState(newChanges, async () => {
      let newChanges2 = {};
      if (newSubjectType && nextProps.selectedProjectId && nextProps.menus &&
        (propertiesChanged ||
          (nextProps.selectedProjectId != props.selectedProjectId) ||
          (reportId != nextReportId) ||
          (nextProps.getNested(['projects', nextProps.selectedProjectId]) != props.getNested(['projects', props.selectedProjectId])) ||
          (newSubjectType != subjectType) ||
          (nextProps.menus != props.menus))) {
        let newStateData = await this.calculateTableDataProps(nextProps, Object.assign({}, this.state, newChanges), currFavSelected ? { ...currFavSelected.values, shouldSetCounterRows } : null, null);
        if (newStateData)
          Object.assign(newChanges2, newStateData);
        newChanges2.defaultSubMenu = subMenus;
      }

      if (this._isMounted && Object.keys(newChanges2).length != 0)
        this.setState(newChanges2);
    });
  }

  reCalcHeader(filtersMenu, noReportMenusParam) {
    const { setHeaderParams } = this.props;
    const { noReportMenus } = noReportMenusParam || this.state;
    
    let headerComponent = 
        <div style={{ display: 'flex', justifyContent:'center', flex: 1, flexDirection: 'column', backgroundColor: 'white'}}>
          <div style={{ marginRight: 3, marginLeft: 3, display: 'flex', justifyContent: 'flex-end', height: 48 }}>
            {filtersMenu}
          </div>
        </div>

    let sideBarParams = { open: false, outerClickClose: true };

    if (noReportMenus) {
      sideBarParams = { hidden: true };
      headerComponent = null;
    }

    if (setHeaderParams)
      setHeaderParams({ headerComponent, sideBarParams });
  }

  getPopulatedObjects(props, state, skipSetState = false) {
    const { updatedPropertiesMap, subjectType } = state;
    let { selectedProjectId, propertiesTypes, propertiesMappings, intl, units, propertiesSections } = props;

    let newStateChanges = {};

    let inObjects = null;
    if (subjectType === 'locations')
      inObjects = Object.values(units.toJS ? units.toJS() : units).map(o => Object.values(o)).flat();

    let { allProps } = populateObject({ 
      selectedProjectId, 
      subjectType,
      propertiesSections,
      propertiesTypes,
      inPropertiesMappings: propertiesMappings, 
      intl, 
      inObjects, 
      extraLocalInstances: updatedPropertiesMap && Object.values(updatedPropertiesMap),
      skipPopulatedObjects: true
    });

    // process data
    const processedDataForTables = preProcessData({
      allProps,
      propertiesTypes,
      propertiesMappings,
      subjectType,
      companiesMap: props.projectCompanies,
      lokiInstance,
      intl,
      scopeId : selectedProjectId,
    });
    
    newStateChanges.processedDataForTables = processedDataForTables;
    newStateChanges.allProps = processedDataForTables.allProps;

    if (!skipSetState)
      this.setState(newStateChanges);

    return newStateChanges;
  }

  reCalcMember(members, inFilters, inTextFilter) {
    const { trades, rtl, titles, intl, companyProjects, projectCompanies } = this.props;
    const filters = inFilters || this.state.filters;
    const displayEmptyRows = false;
    const defaultSectionTitle = intl.formatMessage(usersMessages.titles.projects);
    let filteredMembers = {};
    let activeOnly = filters && filters.activeOnly;
    if (inTextFilter) {
      let phone = null;

      if (inTextFilter[0] == 0)
        phone = inTextFilter.slice(1);

      Object.values(members).forEach(member => {
        const { user_metadata } = member;
        if (user_metadata) {
          if ((user_metadata.displayName || '').trim().includes(inTextFilter) || (user_metadata.phoneNumber || '').includes(phone))
            filteredMembers[user_metadata.id] = members[user_metadata.id];
        }
      });
    }
    else
      filteredMembers = members;
    const tableDataObj = getMembersProjectsTableDataObj(projectCompanies || {}, companyProjects || {} , filteredMembers || {}, trades || {}, titles || {}, activeOnly, defaultSectionTitle);
    const tableWrapperProps = getTableWrapperProps({ tableDataObj, rtl, displayEmptyRows });

    return tableWrapperProps;
  }

  onMemberUpdate(member) {
    const { members } = this.state;
    if (members && member) {
      let newMembers = members.setNested([member.id, 'user_metadata'], member);
      let tableWrapperProps = this.reCalcMember(newMembers);

      this.setState({ members: newMembers, ...tableWrapperProps });
    }
  }

  reCalcCompany(companies, inFilters, inTextFilter) {
    const { trades, rtl, intl, companyProjects} = this.props;
    const { filters } = this.state;
    const defaultSectionTitle = intl.formatMessage(usersMessages.titles.projects);
    let activeOnly = filters && filters.activeOnly;
    let filteredCompanies = {};
    if (inTextFilter) {
      Object.values(companies).forEach(company => {
        if ((company.name || '').includes(inTextFilter))
          filteredCompanies[company.id] = companies[company.id];
      });
    } else
      filteredCompanies = companies;
    const tableDataObj = getSubcontractorProjectsTableDataObj(filteredCompanies || {}, companyProjects, trades || {}, activeOnly, defaultSectionTitle);
    const tableWrapperProps = getTableWrapperProps({ tableDataObj, rtl });

    return tableWrapperProps;
  }

  onCompanyUpdate(company) {
    const { companies } = this.state;
    if (company) {
      let newCompanies = companies.setNested([company.id], company);
      let tableWrapperProps = this.reCalcCompany(newCompanies);

      this.setState({ companies: newCompanies, ...tableWrapperProps });
    }
  }
  
  async displayCompaniesView(props, inFilters, inTextFilter) {
    const relevantProjectIds = props.relevantProjectIds || [];
    const companies = await getCompanies(relevantProjectIds);
    let tableWrapperProps = this.reCalcCompany(companies, inFilters, inTextFilter);

    let newState = {...tableWrapperProps, companies, rowsLevel: 1 };

    return newState;
  }

  async displayMembersView(props, inFilters, inTextFilter) {
    const relevantProjectIds = props.relevantProjectIds || [];
    const members = await getMembers(relevantProjectIds);
    let tableWrapperProps = this.reCalcMember(members, inFilters, inTextFilter);

    let newState = { ...tableWrapperProps, members, rowsLevel: 2 };

    return newState;
  }

  async displayLocationGroupsView(props, inFilters, inTextFilter) {
    let groupsArray = _.get(props, ['propertiesTypes', 'locationsInfo', 'groups', 'values']);
    let { groupsSubTypes } = await fetchQuasiStatics('groupsSubTypes');
    let groupsMap = {};
    (groupsArray || []).forEach(g => {
      _.set(groupsMap, g.id, { id: g.id, title: { [props.viewer.lang]: g.getCementoTitle() }, types: g.types });
    });

    let tableWrapperProps = this.reCalcGroups(groupsMap, groupsSubTypes, inFilters, inTextFilter);

    let newState = { ...tableWrapperProps, groupsMap, groupsSubTypes, rowsLevel: 2 };

    return newState;
  }

  async handleGetEmployeesPresence(currEmployeesPresenceParams) {
    const { prevEmployeesPresenceParams, employeesPresenceData: prevEmployeesPresenceData, shouldForceEmployeePresenceRefresh, shouldSkipLoadingOnEmployeePresenceFetching } = this.state;

    if (!shouldForceEmployeePresenceRefresh && _.isEqual(prevEmployeesPresenceParams, currEmployeesPresenceParams)) {
      return prevEmployeesPresenceData;
    }

    const { projectId, startTS, endTS } = currEmployeesPresenceParams;
    //await this.promiseSetState({ prevEmployeesPresenceParams: currEmployeesPresenceParams })
    const employeesPresenceData = await getEmployeesPresence({ projectId, startTS, endTS, includeMissingDays: true, excludeEmptyLogs: true, populate: true, includeMonitor: true, skipLoading: shouldSkipLoadingOnEmployeePresenceFetching });
    this.setState({ employeesPresenceData, prevEmployeesPresenceParams: currEmployeesPresenceParams, shouldForceEmployeePresenceRefresh: false, shouldSkipLoadingOnEmployeePresenceFetching: false });
    return employeesPresenceData;
  }

  async handleMonthPicker(selectedRange) {
    if (selectedRange) {
      await this.promiseSetState({ selectedRange });
      const newState = await this.calculateTableDataProps(this.props, this.state);
      this.setState(newState);
    }
  }


  async displayEmployeesPresenceView(props, inFilters, inTextFilter) {
    const { selectedProjectId: projectId } = props;
    const { selectedRange } = this.state;
    const { startTS, endTS } = selectedRange;

    const employeesPresenceParams = {
      projectId,
      startTS: moment(startTS).startOf('month').valueOf(),
      endTS: moment(endTS).endOf('day').valueOf()
    };
    const employeesPresenceData = await this.handleGetEmployeesPresence(employeesPresenceParams);
    const tableWrapperProps = this.reCalcEmployeesPresence(_.get(employeesPresenceData, ['employees']), inFilters, inTextFilter, startTS, endTS);
    const newState = { ...tableWrapperProps, employeesPresenceData };

    return newState;
  }

  reCalcEmployeesPresence(employeesPresenceData, inFilters, inTextFilter, startTS, endTS) {
    const { rtl, selectedProjectId, intl, viewer } = this.props;
    const tablesMetadata = getEmployeesTablesMetadata(employeesPresenceData)
    const tableDataObj = getEmployeesPresenceTableDataObj({ employeesPresenceData, intl, inFilters, inTextFilter, startTS, endTS, tablesMetadata });
    const tableWrapperProps = getTableWrapperProps({ tableDataObj, rtl });
    return tableWrapperProps;
  }

  reCalcGroups(groupsMap, groupsSubTypes, inFilters, inTextFilter) {
    const { rtl, selectedProjectId, intl, viewer } = this.props;
    const locationsMap = getObjectsTitlesMap(this.props, 'locationsInfo');
    let groupsInstances = this.lokiPropertyInstances.cementoFind({ 'projectId': selectedProjectId, subjectName: 'locationsInfo', propId: 'groups' });
    _.forIn(groupsMap, x => {!x.types && (x.types = {'-units': true})}); // Init groups that have no type to be of type units by default

    const mainRows = {
      buildings: { id: '-buildings', title: intl.formatMessage(newProjectMessages.locationTypes.buildings) },
      floors: { id: '-floors', title: intl.formatMessage(newProjectMessages.locationTypes.floors) },
      units: { id: '-units', title: intl.formatMessage(newProjectMessages.locationTypes.units) },
    };
    let filteredGroups = {};

    if (inTextFilter) {
      Object.values(groupsMap).forEach(group => {
        if (_.get(group, ['title', viewer.lang], '').includes(inTextFilter))
          filteredGroups[group.id] = groupsMap[group.id];
      });
    } else {
      filteredGroups = groupsMap;
    }
    const tableDataObj = getLocationsGroupsTableDataObj(mainRows, filteredGroups, groupsInstances, locationsMap, groupsSubTypes);
    const tableWrapperProps = getTableWrapperProps({ tableDataObj, rtl });

    return tableWrapperProps;
  }

  onGroupUpdate(group) {
    const { groupsMap, groupsSubTypes } = this.state;

    let newGroupsMap = { ...groupsMap };
    if (group)
      newGroupsMap = _.set(newGroupsMap, group.id, group);

    let tableWrapperProps = this.reCalcGroups(newGroupsMap, groupsSubTypes);

    this.setState({ ...tableWrapperProps, groupsMap: newGroupsMap })
  }

  calculateTableDataProps = AwesomeDebouncePromise(async (props, state, inFilters, inTextFilter) => {
    const { rtl, intl, contentType } = props;
    let { processedDataForTables, subjectType, noReportMenus } = state;
    if (noReportMenus != undefined && noReportMenus)
      return;
    let filters = inFilters || state.filters;
    const textFilter = inTextFilter || _.get(filters, 'textFilter');
    let propertiesTypes = _.get(processedDataForTables, ['propertiesTypes'], {});
    
    let tableWrapperProps = {};
   
    if (['members', 'companies', 'locationsGroupsManagement'].includes(subjectType) || contentType === 'siteControl')
      tableWrapperProps = await this.dataToTableProps(props, subjectType, filters, textFilter);
    else{
      let { originalRowsData, subjectProperties } = this.propertiesToTableProps(props, state);
      
      if (!originalRowsData)
        return tableWrapperProps;
      const subjectName = subjectType + "Info"
      let tableDataObj = getPropertiesTableDataObject(originalRowsData, filters, subjectProperties, intl, subjectName);
      const originalTableData = originalRowsData.groupByMetaData;
      tableWrapperProps = getTableWrapperProps({ tableDataObj, rtl, originalTableData});
      const currentState = Object.assign({}, state, tableWrapperProps);
      const filteredState = this.filtersHandler(filters, currentState, textFilter);

      tableWrapperProps = filteredState;
      tableWrapperProps.originalColumns = originalRowsData.groupByMetaData.properties;
      tableWrapperProps.originalSections = originalRowsData.groupByMetaData.sections;
      tableWrapperProps.isPropertiesTable = true;
      tableWrapperProps.categoryFilters = this.getTableDataObjectFilterMenuSet(tableDataObj);
      tableWrapperProps.tableDataObject = tableDataObj;
    }
    return tableWrapperProps;
  }, 5);
  /**
   * 
   * @param {TableDataObject} tableDataObj 
   * @returns 
   */
  getTableDataObjectFilterMenuSet(tableDataObj) {
    const { intl } = this.props;
    let categories = {};

    let fallbackOrdinalNo = 1;
    _.values(tableDataObj.sections).forEach(section => {
      _.values(section.columns).forEach(column => {
        if ((!tableDataObj.isSubTable && column.isDynamicallyGenerated) || !SUPPORTED_FILTER_TYPES.includes(column.valuesType))
          return;

        let category = { 
          id: ['values', column.id, ORIGINAL_VALUE_KEY_TERM].join(FILTER_MENU_PATH_DELIMETER),
          ordinalNo: isEmptyValue(column.num) ? fallbackOrdinalNo : column.num,
          type: column.valuesType, 
          title: column.getCementoTitle(),
          inputSettings: column.settings,
        };

        let shouldSetNoValueOption = false;
        if (column.universalId === propertyTypes.UNIVERAL_IDS.status) {
          category.type = propertyTypes.SELECTION_LIST;
        
          const relevantStatusOptions = _.values(column.rows).reduce((acc, row) => {
            const rowsToGoThrough = _.keys(row.subCells).length ? _.values(row.subCells) : [row];
            
            rowsToGoThrough.forEach(r => {
              const rowValue = r.value[ORIGINAL_VALUE_KEY_TERM];
              const status = _.toNumber(_.trim(rowValue));
              if (!isEmptyValue(status) && formStatusesArray.includes(status))
                _.set(acc, status, { id: status, title: String(safeFormatMessage(intl, getStatusMessage(status).message)) });
            });

            return acc;
          }, {});

          category.options = _.values(relevantStatusOptions);
        }
        else {
          shouldSetNoValueOption = true;
          switch (column.valuesType) {

            case propertyTypes.DATE: {
              let existingValues = {};
              
              _.values(column.rows).forEach(row => {
                const rowsToGoThrough = _.keys(row.subCells).length ? _.values(row.subCells) : [row];
                rowsToGoThrough.forEach(row => {
                  const rowValue = _.get(row, ['value', ORIGINAL_VALUE_KEY_TERM]);
                  if (rowValue && !existingValues[rowValue])
                    existingValues[rowValue] = rowValue;
                });
              });

              category.existingValues = existingValues;
              category.type = 'DateRange';
              break;
            }

            case propertyTypes.SELECTION_LIST: {
              const originalValueByRow = _.values(column.rows).reduce((acc, row) => {
                const rowsToGoThrough = _.keys(row.subCells).length ? _.values(row.subCells) : [row];
                rowsToGoThrough.forEach(r => !isEmptyValue(_.get(r, ['value', ORIGINAL_VALUE_KEY_TERM])) && _.set(acc, _.first(_.keys(r.value[ORIGINAL_VALUE_KEY_TERM])), true));
                return acc;
              }, {});
              const relevantOptions = _.values(column.valueOptions)
                .filter(o => originalValueByRow[o.id] && o.getCementoTitle())
                .reduce((acc, o) => _.set(acc, o.id, { id: o.id, title: o.getCementoTitle() }), {});
              category.options = _.values(relevantOptions);
              break;
            }

            case propertyTypes.BOOLEAN: {
              category.type = propertyTypes.SELECTION_LIST;
              category.options = [{ id: 'true', title: systemMessages.yes }, { id: 'false', title: systemMessages.no }];
              break;
            }
            
            case propertyTypes.NUMBER:
            case propertyTypes.STRING: {
              const relevantOptions = _.values(column.rows).reduce((acc, row) => {
                const rowsToGoThrough = _.keys(row.subCells).length ? _.values(row.subCells) : [row];
                
                rowsToGoThrough.forEach(r => {
                  const rowValue = r.value[ORIGINAL_VALUE_KEY_TERM];
                  const trimmedValue = _.trim(rowValue);
                  if (!isEmptyValue(trimmedValue))
                    _.set(acc, trimmedValue, { id: rowValue, title: trimmedValue });
                });

                return acc;
              }, {});

              category.type = propertyTypes.SELECTION_LIST;
              category.options = _.values(relevantOptions);
              break;
            }

            default: break;
          }
        }

        if (shouldSetNoValueOption && category.options && category.options.length)
          category.options.push({ id: 'undefined', title: `(${safeFormatMessage(intl, propertiesMessages.empty)})` });

        categories[column.id] = category;
        fallbackOrdinalNo++;
      });
    });

    return [{
      id: 'defaultView',
      title: 'defaultView', // give real name if more than one filter set
      categories: _.values(categories),
    }];
  }

  async dataToTableProps(props, subjectType, inFilters, inTextFilter) {
    const { buildings, floors, units, intl, selectedProjectId, viewer } = props;

    switch (subjectType) {
      case ('members'):
        return await this.displayMembersView(props, inFilters, inTextFilter);
      case ('companies'):
        return await this.displayCompaniesView(props, inFilters, inTextFilter);
      case ('locationsGroupsManagement'):
        return await this.displayLocationGroupsView(props, inFilters, inTextFilter);
      case ('employees'):
        return await this.displayEmployeesPresenceView(props, inFilters, inTextFilter);   

      default:
        return {};
    }
  }

  propertiesToTableProps(props, state) {
    let { updatedPropertiesMap, allProps, processedDataForTables, subjectType, formType, filters } = state;
    const { buildings, floors, units, intl, selectedProjectId, viewer } = props;

    if (!subjectType || !selectedProjectId)
      return;

    let subjectName = subjectType + "Info";
    let propertiesInstances = this.lokiPropertyInstances.cementoFind({ 'projectId': selectedProjectId, subjectName });
    if (propertiesInstances && updatedPropertiesMap) {
      let instancesMap = {};
      propertiesInstances.forEach(instance => instancesMap[instance.id] = instance);
      Object.values(updatedPropertiesMap).forEach(prop => instancesMap[prop.id] = { ...prop, subjectName });
      propertiesInstances = Object.values(instancesMap);
    }

    let objectValues = null;
    let innerPropertiesTypes = _.get(processedDataForTables, ['propertiesTypes', subjectName]);
    if (["employees", "equipment"].includes(subjectType))
      objectValues = props.employees || (lokiInstance.getCollection(subjectType) ? lokiInstance.getCollection(subjectType).cementoFind({ 'projectId': selectedProjectId }) : []);
    else if (subjectType === 'forms')
      objectValues = lokiInstance.getCollection(subjectType) ? lokiInstance.getCollection(subjectType).cementoFind({ 'projectId': selectedProjectId, 'type': formType }) : [];
    else if (subjectType === 'locations')
      objectValues = getLocationsObjectsForTable(buildings, floors, units, intl);
    
    let fakePrimaryProp = null;
    if (subjectType === 'forms') {
      fakePrimaryProp = {
        id: `-formDate-${subjectType}Table`,
        settings: { dateFormat: intl.formatMessage(systemMessages.fullDateFormat), timezone: 'Asia/Jerusalem' },
        title: intl.formatMessage(analyticsMessages.dashboards.axesLabels.date),
        type: propertyTypes.DATE,
        pathInObject: 'reportDate',
      };
    }
    else if (subjectType === 'locations') {
      fakePrimaryProp = {
        id: `-locationName-${subjectType}Table`,
        title: intl.formatMessage(analyticsMessages.location),
        type: propertyTypes.STRING,
        pathInObject: 'title',
      };
    }

    const subjectSections = safeToJS(props.getNested(['propertiesSections', subjectName]));
    if (fakePrimaryProp) {
      const randomSectionId = _.get(_.values(subjectSections), [0, 'id']);
      if (!randomSectionId)
        fakePrimaryProp = null;
      else {
        fakePrimaryProp.isPrimary = true;
        fakePrimaryProp.sectionId = randomSectionId; // It doesnt matter which section as this is the primary column and doesnt show within a section, just needs this so the rest of the functions can operate normally
      }
    }

    let showColumnEvenWhenNoDataExists = (Boolean(viewer && viewer.adminMode == 1));
    let originalRowsData = getPropertiesInfo({
        subjectProperties: innerPropertiesTypes,
        pathInObjectProperties: fakePrimaryProp ? { [fakePrimaryProp.id]: fakePrimaryProp } : null,
        subjectSections,
        subjectMappings: props.getNested(['propertiesMappings', subjectName]),
        objects: objectValues,
        showColumnEvenWhenNoDataExists,
        allProps: processedDataForTables.allProps,
        isExpandedView: _.get(filters, 'isSubTable', false),
    });
    
    let subjectProperties = fakePrimaryProp ? Object.assign({}, innerPropertiesTypes, { [fakePrimaryProp.id]: fakePrimaryProp }) : innerPropertiesTypes;
    subjectProperties = _.mapValues(subjectProperties, prop => _.set(prop, 'generalDisplayParams', _.get(prop, 'UIStruct.table[0].general')));

    return { originalRowsData, subjectProperties };
  }

  filtersHandler(filters, currentState, textFilter) {
    const { columns, allColumns, rowsArray, primaryColumn, rowsLevel } = currentState;

    let newRowsArray = [...rowsArray || []];
    let entireTradesIds = this.buildTradesList(allColumns, rowsArray);
    let innerTextFilter = (filters && filters.textFilter) || textFilter;
    const primaryColumnId = _.get(primaryColumn, 'id');

    if (innerTextFilter) {
      newRowsArray = newRowsArray.filter(row => {
        if (row.rowLevel !== rowsLevel) {
          let subRowsMatchTheFilter = Object.values(row.subRows || {}).filter(subRow => (subRow.value.displayValue || '').toLowerCase().indexOf(innerTextFilter.toLowerCase()) != -1);
          return subRowsMatchTheFilter.length ? true : false;
        };
        const rowTitle = String(_.get(row, ['values', primaryColumnId, 'displayValue']) || '');
        return (rowTitle.toLowerCase().indexOf(innerTextFilter.toLowerCase()) != -1)
      })
    }

    if (filters && filters.activeOnly) {
      let activeOnlyColumns = this.getBusinessTypesColumn(columns, null, "isActive");
      let activeOnlyColumnId = Object.keys(activeOnlyColumns || {}).length > 0 ? Object.keys(activeOnlyColumns)[0] : null;
      newRowsArray = activeOnlyColumnId ? newRowsArray.filter(row => row.values[activeOnlyColumnId] && row.values[activeOnlyColumnId].displayValue) : [];
    }

    if (filters && filters.filterExpiration) {
      let expirationColumns = this.getBusinessTypesColumn(columns, null, null, [{ path: ['type'], val: propertyTypes.CERTIFICATION }, { path: ['settings', 'isWarning'], val: true }, { path: ['settings', 'isExpiration'], val: true }]);

      newRowsArray = newRowsArray.filter(row => {
        let ret = false;
        Object.keys(expirationColumns || {}).forEach(columnId => {
          if (ret)
            return;

          let cellValue = row.values[columnId];
          if (!cellValue)
            return;

          ret = (cellValue.displayParams.isWarning || cellValue.displayParams.isExpired)
        })

        return ret;
      });
    }

    let filteredState = Object.assign({}, currentState, { originalData: { rows: newRowsArray }, entireTradesIds, allColumns, rowsArray: newRowsArray});
    let filtersMenu = this.getFilterMenuComponent(this.props, filteredState);
    let newState = { ...filteredState, filtersMenu};
    return newState;
  }

  getBusinessTypesColumn(columns, businessType, universalId, props) {
    let resultColumns = {};
    (columns || []).forEach(section => {
      (section.columns || []).forEach(column => {
        if (businessType && !resultColumns[column.original.id] && column.original && column.original.businessType == businessType)
          resultColumns[column.original.id] = column.original;
        if (universalId && !resultColumns[column.original.id] && column.original && column.original.universalId == universalId)
          resultColumns[column.original.id] = column.original;
        if (props)
          props.forEach(propPath => {
            if (column.original && !resultColumns[column.original.id] && column.original.getNested(propPath.path) == propPath.val)
              resultColumns[column.original.id] = column.original;
          });
      });
    });
    return resultColumns;
  }

  buildTradesList(columns, rowsArray) {

    let tradesColumn = this.getBusinessTypesColumn(columns, 'trades');

    let entireTradesIds = {};
    Object.keys(tradesColumn || {}).forEach(tradeColumnId => {
      rowsArray.forEach(row => {
        Object.keys(row.values[tradeColumnId] || {}).forEach(tradeId => {
          if (!entireTradesIds[tradeId])
            entireTradesIds[tradeId] = true;
        });
      });
    });

    return entireTradesIds;
  }
  handleObjectCreateCancel() {
    this.setState({ sideCardObject: null });
  }

  handleObjectCreate(newObjectGroupId, newObjectId) { // gets called twice: once when click addWorker and once on save after the object is created;
    this.handleAddObjectClick(newObjectGroupId, null, newObjectId);
  }

  handleCardChangeStatus(isDisable) {
    this.setState({ cardIsDisabled: isDisable });
  }

  handleObjectDelete(wasDeleted, objectId) {
    const { updatedPropertiesMap } = this.state;
    if (!wasDeleted) return;

    if (updatedPropertiesMap && updatedPropertiesMap[wasDeleted]) {
      const newUpdatedProperties = Object.values(updatedPropertiesMap).filter(prop => prop.parentId === objectId)
        .map(prop => prop.isDeleted = true);
      this.onLocalInstancesChange(newUpdatedProperties);
    }

    this.setState({ sideCardObject: null });
  }

  onLocalInstancesChange(newInstancesMap) {
    const { updatedPropertiesMap: prevUpdatedProperties, newPropInstanceIDs: prevNewPropInstanceIDs } = this.state;
    let updatedPropertiesMap = { ...prevUpdatedProperties };
    let newPropInstanceIDs = { ...prevNewPropInstanceIDs };

    Object.values(newInstancesMap).forEach(newInstance => {
      delete newInstance.valueScope;
      newInstance.updatedTS = Date.now();
      if (newInstance.isDeleted) {
        delete newPropInstanceIDs[newInstance.id];
        delete updatedPropertiesMap[newInstance.id];
      }
      else {
        if (!newPropInstanceIDs[newInstance.id])
          newPropInstanceIDs[newInstance.id] = newInstance.id;

        updatedPropertiesMap[newInstance.id] = newInstance;
      }
    });

    this.setState({ updatedPropertiesMap, newPropInstanceIDs });
  }

  getRelevantLocalInstancesByPropId(updatedPropertiesMap, objectId) {
    let localInstancesByPropertyMap = {};

    Object.values(updatedPropertiesMap).forEach(propInstance => {
      if (propInstance.parentId !== objectId) return;
      localInstancesByPropertyMap[propInstance.propId] = propInstance;
    });

    return localInstancesByPropertyMap;
  }

  defineCardType(subjectType) {
    let cardType;

    switch (subjectType) {
      case ('forms'):
        cardType = 'forms';
        break;
      case ('members'):
        cardType = 'member';
        break;
      case ('companies'):
        cardType = 'company';
        break;
      case ('locationsGroupsManagement'):
        cardType = 'locationsGroup';
        break;
      default:
        cardType = 'connectedObjectProperties';
    }

    return cardType;
  }

  calcSideCardObject = (objectId, objectType, editMode = false, options) => {
    const { subjectType, filters, primaryColumn, updatedPropertiesMap = {}, formType, formTemplateId, allProps, cardIsDisabled } = this.state;
    const { startToast } = this.props;

    const { scrollTargetSectionId, objectExtraInfo, tableMethods, isCreation, isFiredByUser = false, callback, initialGroupId, employeesPresence, hideCertifications } = options || {};

    let subjectName = subjectType + 'Info';
    let filteredProperties = null;
    const columnVisibility = _.get(filters, 'columnVisibility');
    if (columnVisibility) {
      filteredProperties = {};
      _.entries(columnVisibility).forEach(([propertyId, propertyShowOptions]) => {
        if (propertyShowOptions.card)
          filteredProperties[propertyId] = true;
      });
    }

    const cardType = this.defineCardType(subjectType);

    // TODO: change all the on[Subject]Update to be one function that calls the relevant update
    const sideCardObject = {
      type: cardType,
      props: {
        tableMethods: tableMethods ? tableMethods : undefined,
        objectId,
        subjectName,
        subjectType,
        objectExtraInfo: Object.assign({ formType, formTemplateId }, objectExtraInfo),
        allProps,
        initialGroupId,
        scrollTargetSectionId,
        primaryPropId: (primaryColumn ? primaryColumn.id : null),
        filteredProperties,
        localInstancesByPropertyMap: this.getRelevantLocalInstancesByPropId(updatedPropertiesMap, objectId),
        dataWasImported: Boolean(Object.keys(updatedPropertiesMap).length),
        objectType, // used only by UsersManagerCard
        employeesPresence, 
        hideCertifications,

        isDisabled: isCreation ? false : !editMode,
        createObjectMode: isCreation ? subjectType : undefined,
        creation: Boolean(isCreation),

        onLocalInstancesChange: this.onLocalInstancesChange,
        onDisableChange: this.handleCardChangeStatus,
        onMemberUpdate: this.onMemberUpdate,
        onCompanyUpdate: this.onCompanyUpdate,
        onGroupUpdate: this.onGroupUpdate,
        disableEditMode: this.handleConfirmCancel,

        onObjectCreate: isCreation ? this.handleObjectCreate : undefined,
        onCancel: isCreation ? this.handleObjectCreateCancel : undefined,
        onSave: !isCreation ? this.handleSave : undefined,
        onDeleteObject: !isCreation ? this.handleObjectDelete : undefined,
        setHasUnSavedChanges: this.setHasUnSavedChanges,
      }
    };

    if (this.state.sideCardObject && !cardIsDisabled && (isFiredByUser || isCreation)) {
      startToast({
        overlay: true,
        mandatory: true,
        title: systemMessages.manage.leaveWithoutSave,
        message: systemMessages.manage.changesHaveNotBeenSaved,
        actions: [
          { message: systemMessages.yes, color: 'success', onClick: () => { if (callback) callback(sideCardObject); this.setState({ sideCardObject }); } },
          { message: systemMessages.no }
        ]
      });

      return null;
    }
    else
      this.setState({ sideCardObject });

    return sideCardObject;
  }


  handleDisplayCarousel = (itemsArr, pdfMode) => {
    this.setState({ isCarouselVisible: true, isPDFCarousel: pdfMode, carouselItems: itemsArr });
  }

  handleCloseCarousel = () => {
    this.setState({ isCarouselVisible: false, isPDFCarousel: null, carouselItems: null });
  }

  getSideCardObject = (cellData, isAggregatedCell, selectedCell, tableMethods, callback, editMode = false) => {
    const objectId = cellData.row.id;
    const sideCardOptions = _.get(cellData, ['row', 'sideCardOptions'], {});
    const { subjectType } = this.state;
    if (subjectType === 'forms') {
      const form = ((this.lokiObjectAnalytics && this.lokiObjectAnalytics.cementoFind({ id: objectId })) || [])[0] || null;
      const formTemplateId = _.get(form, 'formTemplateId');
      const formTemplate = this.props.getNested(['configurations', 'forms', formTemplateId], {});
      if (_.get(form, 'isDailyReportV1') || !formTemplate.hasProperties) {
        if (form.uri)
          this.handleDisplayCarousel([{ src: form.uri }], true);
        else
          onError({
            errorMessage: `closed form is missing pdf uri`,
            methodMetaData: {
              args: { cellData },
              name: 'getSideCardObject',
            },
            errorMetaData: { objectId, subjectType, object: form, formTemplate },
            alertParams: {
              title: pdfMessages.missingPdf.title,
              message: pdfMessages.missingPdf.message,
              overlay: true,
              actions: [{ message: systemMessages.ok, color: 'success' }]
            }
          });

        return null;
      }
    }

    return this.calcSideCardObject(
      objectId,
      cellData.row.objectType,
      editMode,
      { ...(sideCardOptions || {}), tableMethods, scrollTargetSectionId: cellData.getNested('column', 'mainColumnId'), callback },
    );
  }

  handleAddObjectClick = (initialGroupId, isFiredByUser, newObjectId, initialObjectData) => {
    const { contentType, selectedProjectId, getEmployeeNewId, getEquipmentNewId, getNewFormId } = this.props;
    const { subjectType, formType, allProps } = this.state;


    if (newObjectId) { // show card of newly created object
      this.calcSideCardObject(newObjectId, contentType, false);
      return;
    }

    const formRequestedReportDate = _.get(initialObjectData, 'reportDate');
    let id = null;
    if (subjectType === 'employees')
      id = getEmployeeNewId().payload.key;
    else if (subjectType === 'equipment')
      id = getEquipmentNewId().payload.key;
    else if (subjectType === 'forms') {
      if (!formRequestedReportDate) {
        this.setState({ isShowSelectDateModal: true });
        return;
      }
      else {
        const existingForm = _.find(_.values(allProps), (form) => !_.get(form, 'isDeleted') && _.get(form, 'reportDate') === formRequestedReportDate);
        if (_.get(existingForm, 'id')) {
          const formStatusParams = getFormStatusParams(existingForm);
          this.calcSideCardObject(existingForm.id, contentType, formStatusParams.isEditable);
          return;
        }
        else
          id = getNewFormId(selectedProjectId, formType || undefined).payload.id;
      }
    }

    this.calcSideCardObject(
      id,
      contentType,
      true,
      {
        isCreation: true, 
        isFiredByUser, 
        objectExtraInfo: { formInitStatus: 300, reportDate: formRequestedReportDate },
        initialGroupId,
      }
    );
  }

  async handleSaveFilter(filterProps, isDelete) {
    const { selectedProjectId, saveMenus, contentType } = this.props;
    const { filters, selectedFilterSet, subjectType } = this.state;

    await saveMenus(selectedProjectId, filters, { label: selectedFilterSet.label, id: (filterProps || {}).id, num: parseInt(selectedFilterSet.num || "0") }, contentType, 'analytics', subjectType, isDelete);
  }

  handleUpdateSelectedFilterSet(selectedFilterSet) {
    let stateChanges = { selectedFilterSet };
    this.setState(stateChanges);
  }

  handleFilterChange = AwesomeDebouncePromise((text)=>{
    const { filters } = this.state;
    const newFilters = Object.assign({}, filters, { textFilter: text });
    this.setState({filters: newFilters});
  }, 500)

  handleFilterClear() {
    this.handleFilterChange('');
  }

  closeImageCarousel() {
    this.setState({ displayPdf: false });
  }

  async exportRegistrationForm() {
    const { project, viewer, getNewFormId, upsertForm, exportFormPDF, startLoading } = this.props;
    let displayPdf = false;
    try {
      startLoading({ title: systemMessages.loadingMessage, overlay: true });
      let { payload } = getNewFormId(project.id);
      let { id } = payload;
      let form = { id, formTemplateId: "-certificatesList", type: 'others' };
      await upsertForm(project.id, viewer, form, form.type);
      let res = await exportFormPDF({ project, formId: id, formType: form.type, isListenerMode: true });
      if (res && res.uri)
        displayPdf = res.uri;
    }
    catch (error) {
      console.log("failed to create registration form :", error);
    }
    finally {
      if (this.state.displayPdf != displayPdf)
        this.setState({ displayPdf });
    }
  }

  getFilterMenuComponent(nextProps, nextState) {
    const { intl, classes, viewer, rtl, contentType } = nextProps;
    const { subjectType, selectedFilterSet, filters, originalColumns, originalSections, entireTradesIds, textFilter } = nextState;
    const { isEditMode, hasModifications, columns, categoryFilters, selectedRange, employeesPresenceData } = nextState;
    const projectCreationDate = nextProps.getNested(['projects', nextProps.selectedProjectId, 'createdAt']);
    const isSiteControlTable = contentType === 'siteControl';

    let filtersMenu = null;
    let excelImportExport = null;
    let editModeToggle = null;
    let siteControlHeaderComponents = null
    let filterStyle = { display: 'flex', flexDirection: 'row-reverse', justifyContent: 'flex-start', [rtl ? 'marginLeft' : 'marginRight']: theme.verticalMargin, alignItems: 'center' };
    let isAdmin = Boolean(_.get(viewer, 'adminMode') === 1);
    const isShowEditButton = !['settings', 'projectManager', 'forms'].includes(contentType);
    const isShowAddButton = !_.get(filters, ['isSubTable']) && !(isSiteControlTable);
    const textFilterComponent = <TextFilter containerStyle={{ maxWidth: 250 }} value={textFilter} onChange={this.handleFilterChange} clearFilterVal={this.handleFilterClear} />

    if (isAdmin && !isSiteControlTable) {
      filtersMenu = (
        <TableFilters
          filters={filters}
          subjectName={`${subjectType}Info`}
          selectedFilterSet={selectedFilterSet}
          updateSelectedFilterSet={this.handleUpdateSelectedFilterSet}
          originalSections={originalSections}
          originalColumns={originalColumns}
          handleSaveFilter={this.handleSaveFilter}
          updateFilters={newFilters => this.setState({ filters: newFilters })}
          showColumnSelector={true} />
      );

      editModeToggle = (
        <EditModeToggle
          onClickEdit={() => this.setEditMode(true)}
          onClickSave={this.handleSave}
          onClickCancel={this.handleCancel}
          hasModifications={hasModifications}
          isEditMode={isEditMode}
          mainContainerStyle={{ margin: '0 ' + theme.margin + 'px' }}
        />
      );
    }
    excelImportExport = (
      <>
       {Boolean(isAdmin || ['locations', 'forms'].includes(subjectType)) && (
         <StandardInput
           type={'Excel'}
           containerStyle={{ flex: 0 }}
           settings={{
             exportMethods: [
               { id: 'default', label: 'Export', getDataToExportFunc: subjectType == 'locationsGroupsManagement' ? this.prepareLocationsGroupDataForExport : () => this.prepareDataForExport(null, true) },
               ...(
                 subjectType === 'locationsGroupsManagement' 
                   ? []
                   : [
                       { id: WITH_IDS, label: 'Export with IDs', getDataToExportFunc: () => this.prepareDataForExport(WITH_IDS), viewerPermissions: [{ adminMode: 1 }, { adminMode: 2 }] },
                       { id: `${WITH_FILES}`, label: 'Export with Files', getDataToExportFunc: () => this.prepareDataForExport(`${WITH_FILES}`), viewerPermissions: [{ adminMode: 1 }, { adminMode: 2 }] },
                       { id: `${WITH_FILES}_${WITH_IDS}`, label: 'Export with File DB Paths', getDataToExportFunc: () => this.prepareDataForExport(`${WITH_FILES}_${WITH_IDS}`), viewerPermissions: [{ adminMode: 1 }, { adminMode: 2 }] },
                       { id: `${WITH_FILE_URLS}`, label: 'Export with File URLs', getDataToExportFunc: () => this.prepareDataForExport(WITH_FILE_URLS), viewerPermissions: [{ adminMode: 1 }, { adminMode: 2 }] },
                   ]
               )
             ],
           }}
           innerStyle={{ marginRight: theme.margin - 5 }}
         />
       )}
        {Boolean(isEditMode && isAdmin) && (
          <StandardInput
            type={'Excel'}
            innerStyle={{ flex: 0 }}
            containerStyle={{ flex: 0 }}
            settings={{
              importMethods: [
                { id: 'default', label: 'Import', viewerPermissions: [{ adminMode: 1 }, { adminMode: 2 }] },
                { id: WITH_IDS, label: 'Import with IDs', viewerPermissions: [{ adminMode: 1 }, { adminMode: 2 }] },
                { id: WITH_FILES, label: 'Import with Files', viewerPermissions: [{ adminMode: 1 }, { adminMode: 2 }] },
                { id: `${WITH_FILES}_${WITH_IDS}`, label: 'Import with Files and IDs', viewerPermissions: [{ adminMode: 1 }, { adminMode: 2 }] },
              ],
            }}
            onChange={this.onExcelImport}
          />
        )}
      </>
    );

    if (isSiteControlTable) {
      const lastSynced = getLastSyncTSFromCamerasMonitor(_.get(employeesPresenceData, ['monitor']));
      const isCameraActive = _.every(_.get(employeesPresenceData, ['monitor']), camera => camera.isActive);
      editModeToggle = null;
      const monthPickerRenderFunc = _props => {
        const options = _.map(_props.options, o => ({ value: o.id, label: o.title }));
        const selectedValueId = _.head(_.values(_props.value));
        const value = _.head(_.filter(options, option => option.value === selectedValueId));
        const props = {
          ..._props,
          options,
          value,
          styles: {
            ...theme.selectStyles,
            container: styles => ({
              ...theme.selectStyles.container(styles),
              marginRight: 0,
            }),
          }
        };

        return <Select {...props} />;
      };

      return (
        <div style={{ display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'space-between', alignItems: 'center' }}>
          <CamerasMonitor isActive={isCameraActive} lastSynced={lastSynced} />
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
            {textFilterComponent}
            <div style={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'flex-start', paddingLeft: theme.padding, paddingRight: theme.padding, alignItems: 'center', width: 200 }}>
              <MonthPicker
                shouldHideTitle
                minTS={projectCreationDate}
                value={_.get(selectedRange, ['startTS'])}
                onChange={this.handleMonthPicker}
                placeholder={intl.formatMessage(analyticsMessages.viewType.filterByDate)}
                renderFunc={monthPickerRenderFunc}
              />
            </div>
          </div>
        </div>
      );
    }

    if (subjectType && subjectType != "locations") {
      const hasIsActiveColumn = _.values(this.getBusinessTypesColumn(columns, null, 'isActive')).length === 1;
      const isShowActiveOnlyFilter = contentType !== 'projectManager' && hasIsActiveColumn;
      const isShowFilterExpiration = !['settings', 'projectManager', 'forms'].includes(contentType) //TODO : change to locationsGroups instead of projectManager
      filtersMenu = (
        <div style={{ display: 'flex', flex: 1, flexDirection: 'row-reverse', margin: '0px 5px', alignItems: 'center' }}>
          {Boolean(isShowAddButton) && (
            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.handleAddObjectClick(null, true)}>
              <div><img src={plus_primary} /></div>
              <Text style={{ margin: theme.verticalMargin, fontWeight: theme.strongBold }} values={{ contentType: safetyMessages.objectsNames[subjectType] }}>{subjectType === 'forms' ? reportsMessages.newReport.title : systemMessages.addObject}</Text>
            </div>
          )}
          {Boolean(isShowFilterExpiration) && (
            <>
              {filtersMenu}
              <div style={{ ...filterStyle, alignItems: 'center' }}>
                <span>{intl.formatMessage(safetyMessages.expiredSoon)}</span>
                <Checkbox
                  onChange={() => {
                    let newFilters = Object.assign({}, filters, { filterExpiration: !Boolean(filters && filters.filterExpiration) });
                    this.setState({filters : newFilters});
                  }}
                  checked={Boolean(filters && filters.filterExpiration)}
                  icon={<Check className={classes.uncheckedIcon} />}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  style={{ cursor: 'pointer' }}
                  classes={{ checked: classes.checked }} />
              </div>
            </>
          )}
          {Boolean(isShowActiveOnlyFilter) && (
            <div style={{ ...filterStyle, alignItems: 'center' }}>
              <span>{intl.formatMessage(safetyMessages.activeOnly)}</span>
              <Checkbox
                onChange={() => {
                  let newFilters = Object.assign({}, filters, { activeOnly: !Boolean(filters && filters.activeOnly) });
                  this.setState({filters: newFilters});
                }}
                checked={Boolean(filters && filters.activeOnly)}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                style={{ cursor: 'pointer' }}
                classes={{ checked: classes.checked }} />
            </div>)}

          <FilterMenuHOC hideEmptyCategory filters={categoryFilters || []} buttonStyle={{ margin: theme.margin }} pathDelimeterOverwrite={FILTER_MENU_PATH_DELIMETER} filterUrlKey={TABLES_FILTER_URL_KEY} />
          {textFilterComponent}
          {false && <div style={filterStyle}>
            <TradesSelector
              options={entireTradesIds}
              selectedTrades={filters ? filters.selectedTrades : null}
              onChange={(selectedTrades) => {
                let newFilters = Object.assign({}, filters, { selectedTrades });
                this.setState({ filters: newFilters });
              }}
            />
          </div>}
        </div>
      );
    }
    return (
      <>
        {
          Boolean(subjectType == 'employees' && !isSiteControlTable) &&
          <HoverWrapper
            props={{ color: 'inherit' }}
            hoverProps={{ color: theme.brandPrimary }}
            renderItem={({ color }) => (
              <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'flex-end', cursor: 'pointer' }} onClick={this.exportRegistrationForm}  >
                <PrintOutlined style={{ fontSize: 18, lineHeight: '18px', margin: 5, color }} />
                <Text style={{ fontWeight: 700, color }}>{pdfMessages.exportRegistrationForm}</Text>
              </div>
            )}
          />
        }
        {filtersMenu}
        {Boolean(contentType !== 'settings' && excelImportExport) && excelImportExport}
        {Boolean(isShowEditButton && isAdmin && editModeToggle) && editModeToggle}
        {Boolean(siteControlHeaderComponents) && siteControlHeaderComponents}
      </>
    );
  }

  setEditMode(bool) {
    this.setState({ isEditMode: bool });

    if (!bool)
      this.onEditModeOff();
  }

  onEditModeOff() {
    let stateChanges = {};

    stateChanges.hasModifications = false;
    stateChanges.updatedPropertiesMap = {};
    stateChanges.newPropInstanceIDs = {};

    this.setState(stateChanges, () => this.onSetHasModifications(false));
  }

  onSetHasModifications(bool) {
    const { onDraftModeChange } = this.props;
    onDraftModeChange(bool);
  }

  handleSave() {
    const { startToast } = this.props;
    const { hasModifications } = this.state;
    const { title, content, yes, no } = systemMessages.confirmSaveChangesAlert;

    if (hasModifications) {
      startToast({
        overlay: true,
        mandatory: true,
        title: title,
        message: content,
        actions: [{ message: yes, onClick: this.handleConfirmSave, color: 'success' }, { message: no }],
      });
      return;
    }

    this.handleConfirmSave();
  }

  handleConfirmSave() {
    const { selectedProjectId, startLoading, hideLoading, uploadPropertiesInstances, startToast, intl } = this.props;
    const { updatedPropertiesMap, hasModifications, subjectType } = this.state;

    if (hasModifications) { // TODO: change for support of employees and equipment
      const updatedPropertiesArr = Object.values(updatedPropertiesMap);

      let propertiesHaveValidTypes = true;
      updatedPropertiesArr.forEach(prop => {
        if (!propertiesHaveValidTypes)
          return;

        if (!validatePropType(prop.propType))
          propertiesHaveValidTypes = false;
      });

      if (!propertiesHaveValidTypes) {
        startToast({ title: systemMessages.errorOnSave, type: 'error', });
        return;
      }

      const subjectName = subjectType + 'Info';
      const callback = success => {
        hideLoading();
        if (!success) {
          startToast({ title: systemMessages.errorOnSave, type: 'error' });
          return;
        }

        startToast({ title: systemMessages.objectSavedSuccessfully, type: 'success', values: { objectName: intl.formatMessage(systemMessages.properties) } });
      };
      startLoading({ title: systemMessages.loadingMessage, overlay: true });
      // setTimeout otherwise startLoading doesnt start;
      setTimeout(() => {
        uploadPropertiesInstances(selectedProjectId, updatedPropertiesArr, subjectName, callback);
      }, 1);
    }

    this.setEditMode(false);
  }

  handleCancel() {
    const { startToast } = this.props;
    const { hasModifications } = this.state;
    const { title, content, yes, no } = systemMessages.confirmCancelChangesAlert;

    if (hasModifications) {
      startToast({
        overlay: true,
        mandatory: true,
        title: title,
        message: content,
        actions: [{ message: yes, onClick: this.handleConfirmCancel, color: 'success' }, { message: no }],
      });
      return;
    }

    this.handleConfirmCancel();
  }

  handleConfirmCancel() {
    this.setEditMode(false);
  }

  prepareLocationsGroupDataForExport() {
    const { tableWrapperProps } = this.state;
    const { selectedProjectId, buildings, floors, units, intl, rtl } = this.props;
    const excelSheetStructure = {
      id: { name: 'ID', types: ['String'], style: { width: largeWidth } },
      description: { name: 'Description', types: ['String'], style: { width: largeWidth, } },
      type: { name: 'Type', types: ['String'], style: { width: smallWidth, } },
      locations: { name: 'Locations', types: ['String'], style: { width: 60, } },
    };
    const exportLocationsTitle = setFlatLocationsTitlesMap(buildings, floors, units, intl);

    let data = {
      locations: {
        sheetStructure: excelSheetStructure,
        rowsData: [],
        sheetView: [
          { state: 'frozen', xSplit: 2, ySplit: 1, rightToLeft: rtl }
        ]
      }
    };

    let originalRows = _.get(tableWrapperProps, ['originalData', 'rows'], []);
    let tableColumns = _.get(tableWrapperProps, ['columns', 0, 'columns'], []);
    let groupsMap = {};

    tableColumns.forEach(column => {
      let { original } = column;
      if (column.id !== 'locations-group-table-locationsColumn') return;
      Object.values(_.get(original, ['rows'], {})).forEach(row => {
        if (Object.keys(row.subRows || {}).length) {
          Object.values(row.subRows || {}).forEach(subRow => {
            let locationsArray = subRow.title.split(',');
            let exportTitleArray = [];
            _.forEach(locationsArray, loc => {
              let location = _.find(exportLocationsTitle[row.id], exportLocation => exportLocation.title.trim() == loc.trim());
              if (location) exportTitleArray.push(location.exportTitle);
            });
            _.set(groupsMap, [subRow.id, 'locations'], String(exportTitleArray) || subRow.title);
          });
        }
      });
    });

    originalRows.forEach(row => {
      if (row.rowLevel === 1) return;
      data.locations.rowsData.push({
        id: row.id,
        description: row.title,
        type: row.parentId,
        locations: _.get(groupsMap, [row.id, 'locations'], ''),
      });
    });

    return { fileName: `locations_groups_${selectedProjectId}`, data };

  }

  prepareDataForExport(mode, isFromTableDataObject = false) {
    const { subjectType, selectedFilterSet, rowsArray, tableDataObject, allProps } = this.state;
    const { buildings, floors, units, intl, rtl, project, selectedProjectId } = this.props;
    
    if (isFromTableDataObject && !mode)
      return this.getExportDataFromTableObject(tableDataObject);


    mode = mode || '';
    const isWithIdsMode = mode.indexOf(WITH_IDS) !== -1;
    const isWithFilesMode = mode.indexOf(WITH_FILES) !== -1;
    const isWithFileUrlsMode = mode.indexOf(WITH_FILE_URLS) !== -1;
    const sheetName = 'Properties';
    
    let relevantPopulatedObjects = _.values(allProps || {}).filter(o => (_.findIndex(rowsArray, x => x.id == o.id) !== -1 && !_.isNil(_.get(o, ['props', 'groups', 'data']))));
    let primaryColumns = [];
    let columnsToExport = {};
    if (subjectType === 'locations') {
      primaryColumns = [
        intl.formatMessage(buildingsMessages.building),
        intl.formatMessage(floorsMessages.floor),
        intl.formatMessage(unitsMessages.unit),
      ];

      let selectedMenuColumns = _.get(selectedFilterSet, ['values', 'columnVisibility'], {});
      Object.entries(selectedMenuColumns).forEach(([colId, options]) => {
        if (_.get(options, ['table'], false))
          columnsToExport = _.set(columnsToExport, colId, true);
      });
    }
    else {
      if (isWithIdsMode)
        primaryColumns = ['ID'];
      else
        primaryColumns = relevantPopulatedObjects.reduce((acc, populatedObject) => {
          const currPrimaryColName = populatedObject.getNested(['primaryProp', 'propTitle'], null);
          if (!currPrimaryColName || acc.includes(currPrimaryColName))
            return acc;

          return [...acc, currPrimaryColName];
        }, []);
    }

    let data = {
      [sheetName]: {
        rowsData: [],
        sheetStructure: {},
        sheetView: [
          { state: 'frozen', xSplit: primaryColumns.length, ySplit: 1, rightToLeft: rtl }
        ],
      }
    };

    primaryColumns.forEach(colName => data = data.setNested([sheetName, 'sheetStructure', colName], { name: colName }));
    const titlesMap = subjectType === 'locations' ? getAllLocationTitlesMap(buildings, floors, units, intl) : null;

    const getInstanceDataPath = instanceId => `properties/instances/projects/${selectedProjectId}/${subjectType + 'Info'}/${instanceId}/data`;

    let dynamicSheetStructurePerSection = {};
    relevantPopulatedObjects.forEach(populatedObject => {
      const { props: properties, id } = populatedObject;
      let rowData = {};

      if (isWithIdsMode)
        rowData['ID'] = id;

      if (subjectType === 'locations') {
        const { buildingTitle, unitTitle, floorTitle } = titlesMap[id];
        [buildingTitle, floorTitle, unitTitle].loopEach((index, value) => rowData = rowData.setNested([primaryColumns[index]], value));
      }

      Object.values(properties || {}).sort((propA, propB) => (propA.fullProp && propA.fullProp.ordinalNo) - (propB.fullProp && propB.fullProp.ordinalNo)).forEach(property => {
        let propId = property.fullProp && property.fullProp.id;
        if (propId === 'groups' || (subjectType === 'locations' && !_.get(columnsToExport, [propId], false)))
          return;

        let { data: propData, dataText, section, propTitle, type, lastCertExpirationTS, instanceId } = property;

        if (!_.isNil(propData))
          switch (type) {
            case (propertyTypes.DATE):
              dataText = new Date(propData);
              break;            

            case (propertyTypes.PICTURE):
            case (propertyTypes.PDF):
            case (propertyTypes.FILES_ARRAY):
            case (propertyTypes.DRAWINGS_ARRAY):
              if (isWithFilesMode)
                dataText = getInstanceDataPath(instanceId);
              else if (isWithFileUrlsMode) {
                let filesData = propData;
                if (type === propertyTypes.PICTURE)
                  filesData = [{ uri: propData }];
                else if (type === propertyTypes.PDF)
                  filesData = [propData];

                filesData = (Array.isArray(filesData) ? filesData : [filesData]).filter(Boolean);
                
                dataText = filesData.length 
                  ? filesData.map(fileMeta => fileMeta.uri).filter(Boolean).join(', ')
                  : null;
              }
              else {
                let filesData = (Array.isArray(propData) ? propData : [propData]).filter(Boolean);
  
                dataText = filesData.length
                  ? intl.formatMessage(systemMessages.fileCount, { filesCounter: filesData.length })
                  : null;
              }
              break;

            case (propertyTypes.CERTIFICATION):
              if (isWithFilesMode)
                dataText = getInstanceDataPath(instanceId);
              else if (isWithFileUrlsMode) {
                let lastCert = Array.isArray(propData) && _.last(propData);
                if (lastCert && lastCert.attachments)
                  dataText = lastCert.attachments.map(fileMeta => fileMeta.data || fileMeta.uri).filter(Boolean).join(', ');
                else
                  dataText = null;
              }
              else
                dataText = lastCertExpirationTS ? new Date(lastCertExpirationTS) : null;
              break;

            case (propertyTypes.BOOLEAN):
              dataText = !_.isNil(propData) ? Boolean(propData) : null;
              break;

            case (propertyTypes.STRING):
              dataText = propData ? convertCementoML(propData, string => string, string => string, '\n').filter(Boolean).join('') : null;
              break;

            default:
              break;
          }

        const sectionTitle = section.getNested(['title'], '').split('\n').join(' ').trim();
        const isPrimaryColumn = primaryColumns.includes(propTitle);
        let columnName = null;
        let richColName = [];
        if (isPrimaryColumn) {
          columnName = propTitle;
        }
        else {
          columnName = `${sectionTitle}\n${propTitle.split('\n').join(' ').trim()}`;
          richColName.push({ text: sectionTitle + '\n', style: { color: theme.brandPrimary, bold: true } });
        }

        richColName.push({ text: propTitle.split('\n').join(' ').trim(), style: { bold: true } });
        rowData[columnName] = dataText;

        if (!dynamicSheetStructurePerSection.getNested([sectionTitle, columnName], false))
          dynamicSheetStructurePerSection = dynamicSheetStructurePerSection.setNested([sectionTitle, columnName], { name: richColName, style: { alignment: { wrapText: true } }, ordinalNo: isPrimaryColumn ? 0 : property.fullProp.ordinalNo });
      });

      data[sheetName].rowsData.push(rowData);
    });


    let orderedSheetStructure = {};
    dynamicSheetStructurePerSection.loopEach((sectionTitle, sheetStructure) => {
      orderedSheetStructure = {
        ...orderedSheetStructure,
        ['emptyCol' + sectionTitle]: {
          name: '',
          style: {
            width: 2,
            backgroundColor: '#d9d9d9',
          },
          ordinalNo: _.chain(orderedSheetStructure).values().last().get('ordinalNo').value(), // these are styling columns, we give same ordinalNo as last column of the section so they stick together later when they get ordered
        },
        ...sheetStructure,
      };
    });
    data = data.setNested([sheetName, 'sheetStructure'], { ...data[sheetName].sheetStructure, ...orderedSheetStructure });

    return { fileName: `${(project || {}).title || selectedProjectId}_${subjectType === 'locations' ? 'units' : subjectType}_properties`, data };
  }

  onExcelImport(excelObj, mode) { // 'default || withIds'
    const { updatedPropertiesMap = {}, newPropInstanceIDs = {} } = this.state;
    const { startLoading, hideLoading, startToast } = this.props;

    startLoading({ title: systemMessages.loadingMessage, overlay: true });
    // setTimeout because the process of importing takes over and startLoading doesn't start otherwise;
    setTimeout(async () => {
      try {
        let prevNewPropInstanceIDs = { ...newPropInstanceIDs };
        let prevUpdatedPropertiesMap = { ...updatedPropertiesMap };
        let { updatedPropertiesMap: newMap, newPropInstanceIDs: newInstanceIDs } = await this.getUpdatedPropertiesFromExcel(excelObj, mode);
        newMap.loopEach((propId, prop) => {
          if (prop.isDeleted && newPropInstanceIDs[prop.id]) {
            delete newMap[prop.id];
            delete prevUpdatedPropertiesMap[prop.id];
            delete prevNewPropInstanceIDs[prop.id];
          }
        });

        let newStateChanges = {};
        newStateChanges.updatedPropertiesMap = { ...prevUpdatedPropertiesMap, ...newMap };
        newStateChanges.newPropInstanceIDs = { ...prevNewPropInstanceIDs, ...newInstanceIDs };
        newStateChanges.hasModifications = true;
        this.setState(newStateChanges, () => { hideLoading(); this.onSetHasModifications(true); });
        startToast({ title: systemMessages.importSuccess, type: 'success' });

      } catch (err) {
        hideLoading();
        startToast({
          title: systemMessages.importError,
          message: err,
          overlay: true,
          mandatory: true,
          actions: [{ message: systemMessages.ok }],
        });
      }
    }, 1);
  }

  /**
   * 
   * @param {TableDataObject} tableDataObject 
   */
  getExportDataFromTableObject = (tableDataObject) => {
    const { subjectType, selectedFilterSet } = this.state;
    const { buildings, floors, units, intl, rtl, project, selectedProjectId } = this.props;

    tableDataObject = tableDataObject || this.state.tableDataObject;
    const sheetName = 'Properties';

    let primaryColumns = [];
    let columnsToExport = {};
    let locationTitlesMap = null;
    if (subjectType === 'locations') {
      primaryColumns = [
        intl.formatMessage(buildingsMessages.building),
        intl.formatMessage(floorsMessages.floor),
        intl.formatMessage(unitsMessages.unit),
      ];

      locationTitlesMap = getAllLocationTitlesMap(buildings, floors, units, intl);

      let selectedMenuColumns = _.get(selectedFilterSet, ['values', 'columnVisibility'], {});
      Object.entries(selectedMenuColumns).forEach(([colId, options]) => {
        if (_.get(options, ['table'], false))
          columnsToExport = _.set(columnsToExport, colId, true);
      });
    }

    const shouldAutoSelectPrimaryColumns = primaryColumns.length === 0;

    let data = {
      [sheetName]: {
        rowsData: [],
        sheetStructure: {},
        sheetView: [
          { state: 'frozen', xSplit: primaryColumns.length, ySplit: 1, rightToLeft: rtl }
        ],
      }
    };

    if (!shouldAutoSelectPrimaryColumns)
      primaryColumns.forEach(colName => _.set(data, [sheetName, 'sheetStructure', colName], { name: colName }));

    let dynamicSheetStructurePerSection = {};
    let rowsData = {};
    const { isCollapsableTable, sections } = tableDataObject;
    _.values(sections).forEach(section => {
      const { title: sectionTitle } = section;
      _.values(section.columns).forEach(column => {
        const { id: columnId, rows, isPrimary: isPrimaryColumn, title: columnTitle, num: columnOrdinalNo } = column;
        if (columnId === 'groups' || (subjectType === 'locations' && !_.get(columnsToExport, [columnId], false)))
          return;

        let columnNameId = null;
        let richColName = [];
        if (isPrimaryColumn)
          columnNameId = columnTitle;
        else {
          columnNameId = `${sectionTitle}\n${columnTitle.split('\n').join(' ').trim()}`;
          richColName.push({ text: sectionTitle + '\n', style: { color: theme.brandPrimary, bold: true } });
        }

        richColName.push({ text: columnTitle.split('\n').join(' ').trim(), style: { bold: true } });

        if (shouldAutoSelectPrimaryColumns && isPrimaryColumn && !primaryColumns.includes(columnNameId))
          primaryColumns.push(columnNameId);

        if (!isPrimaryColumn || shouldAutoSelectPrimaryColumns)
          _.set(dynamicSheetStructurePerSection, [sectionTitle, columnNameId], {
            name: richColName,
            style: {
              alignment: { wrapText: true },
              bold: true,
            },
            ordinalNo: isPrimaryColumn ? 0 : columnOrdinalNo,
          });

        _.values(rows).forEach(row => {
          /** @type {TableCell[]} */
          const rowsToGoThrough = isCollapsableTable ? _.values(row.subCells) : [ row ];
          (rowsToGoThrough).forEach(row => {
            const { value, id: rowId } = row;

            if (subjectType === 'locations') {
              const { buildingTitle, unitTitle, floorTitle } = locationTitlesMap[rowId];
              [buildingTitle, floorTitle, unitTitle].forEach((title, index) => {
                if (!_.get(rowsData, [rowId, primaryColumns[index]]))
                  _.set(rowsData, [rowId, primaryColumns[index]], title);
              });

              if (column.isPrimary)
                return;
            }
  
            const { displayType, displayValue, originalValue, displayParams } = value;
  
            let stringValue = null;
            switch (displayType) {
              case propertyTypes.DATE:
                stringValue = moment(displayValue).format(intl.formatMessage(systemMessages.fullDateFormat));
                break;
  
              case propertyTypes.STRING:
                if (_.get(displayParams, 'isStatusRow'))
                  stringValue = safeFormatMessage(intl, displayParams.message);
                else
                  stringValue = displayValue;
                break;

              case 'Files':
                if (!isEmptyValue(displayValue) && displayValue != 0)
                  stringValue = intl.formatMessage(systemMessages.fileCount, { filesCounter: String(displayValue) });
                break;
  
              case propertyTypes.NUMBER:
              default:
                stringValue = displayValue;
                break;
            }
  
            if (!isEmptyValue(stringValue))
              _.set(rowsData, [rowId, columnNameId], stringValue);
          });
        });
      });
    });

    _.set(data, [sheetName, 'sheetView', 0, 'xSplit'], primaryColumns.length);

    const stylingColumnTemplate = {
      name: '',
      style: {
        width: 2,
        backgroundColor: '#d9d9d9',
      },
    }
    let isPrimaryColumnStyleColumnSet = false;
    let orderedSheetStructure = {};
    Object.entries(dynamicSheetStructurePerSection).forEach(([sectionTitle, sectionSheetStructure]) => { // Set styling columns
      orderedSheetStructure = Object.assign({},
        orderedSheetStructure, 
        {
          ['emptyCol' + sectionTitle]: {
            ...stylingColumnTemplate,
            ordinalNo: (_.chain(sectionSheetStructure).values().maxBy('ordinalNo').get('ordinalNo').value() || 0) + 0.00000001, // these are styling columns, we give same ordinalNo as last column of the section so they stick together later when they get ordered
          },
        }, 
        sectionSheetStructure,
      );

      if (!isPrimaryColumnStyleColumnSet)
        _.values(sectionSheetStructure).forEach(column => {
          if (primaryColumns.indexOf(_.get(column, ['name', 0, 'text'])) === primaryColumns.length - 1) {// if it is the last of the primary columns
            isPrimaryColumnStyleColumnSet = true;
            orderedSheetStructure['emptyCol' + sectionTitle + column.name[0].text] = {
              ...stylingColumnTemplate,
              ordinalNo: (column.ordinalNo || 0) + 0.00000001,
            }
          } 
        });
    });

    data[sheetName].rowsData = _.values(rowsData);
    data[sheetName].sheetStructure = Object.assign({}, data[sheetName].sheetStructure, orderedSheetStructure);

    return { fileName: `${(project || {}).title || selectedProjectId}_${subjectType === 'locations' ? 'units' : subjectType}_properties`, data };
  }

  /**
   * 
   * @param {import('../../components/CementoComponents/ImportExportExcel').CustomWorkbook} excelObj 
   * @param {string} mode 
   * @returns 
   */
  async getUpdatedPropertiesFromExcel(excelObj, mode) {
    const { subjectType, allProps } = this.state;
    const { intl, lang, buildings, floors, units, getNewPropertyInstanceId, selectedProjectId } = this.props;
    const { populatedObjectsTitlesMapByParentId, allExistingSectionsAndProps } = this.getMapPopulatedObjectsPropsToTitlesMapByParentId();

    const concatNames = (...namesArr) => namesArr.join('');

    mode = mode || '';
    const isWithIdsMode = mode.indexOf(WITH_IDS) !== -1;
    const isWithFilesMode = mode.indexOf(WITH_FILES) !== -1;
    let primaryColNames = [];
    let parentIdByIdentifier = {};
    if (subjectType === 'locations') {
      const titlesMap = getAllLocationTitlesMap(buildings, floors, units, intl);
      titlesMap.loopEach((locId, locInfo) => {
        const { buildingTitle, floorTitle, unitTitle, data } = locInfo;

        if (!unitTitle)
          return;

        parentIdByIdentifier = parentIdByIdentifier.setNested([concatNames(buildingTitle, floorTitle, unitTitle)], data.getNested(['id']));
      });

      primaryColNames = [
        intl.formatMessage(buildingsMessages.building),
        intl.formatMessage(floorsMessages.floor),
        intl.formatMessage(unitsMessages.unit),
      ];
    }
    else {
      if (isWithIdsMode) {
        primaryColNames = ['ID'];
        _.values(allProps).forEach(populatedObject => _.set(parentIdByIdentifier, [populatedObject.id], populatedObject.id));
      }
      else
        _.values(allProps).forEach(populatedObject => {
          const objId = populatedObject.id;
          const objTitle = populatedObject.getNested(['primaryProp', 'data'], null); // if more than one primary prop, change this to be concat of all the primary column names;

          if (!objId || !objTitle) {
            console.warn("getUpdatedPropertiesFromExcel -> no objId or objTitle!!!", { objId, objTitle, propInfo: populatedObject });
            // throw `Internal error. Please contact support ( a.k.a. Ilann ;) ).`; // TODO: changed for production translate
            return;
          }

          parentIdByIdentifier = parentIdByIdentifier.setNested([objTitle], objId);

        let primaryPropTitle = populatedObject.getNested(['primaryProp', 'propTitle']);
        if (!primaryColNames.includes(primaryPropTitle))
          primaryColNames.push(primaryPropTitle);
      });
    }
    let parentObjIdentifierName;
    switch (subjectType) {
      case ('locations'):
        parentObjIdentifierName = 'location';
        break;
      case ('employees'):
        parentObjIdentifierName = 'employee';
        break;
      case ('equipments'):
        parentObjIdentifierName = 'equipment';
        break;
      default:
        break;
    }

    let newPropInstanceIDs = {};
    let updatedPropertiesMap = {};
    let pastParentIds = {};
    await Promise.all(excelObj.eachSheet(async (sheetName, sheet, colNames) => {
      await Promise.all(sheet.eachRow(async (row, rowNumber) => {
        const parentIdentifier =
          subjectType === 'locations'
            ? concatNames(...primaryColNames.map(colName => row.getValueInColumn(colName)))
            : row.getValueInColumn(primaryColNames[0]); // TODO: if more than 1 primary prop, change this to be concat of all the primary columns

        const currParentId = parentIdByIdentifier[parentIdentifier]; // TODO: get primary column values to get the id of the item we need to modify

        if (!currParentId) {
          console.error("getUpdatedPropertiesFromExcel -> primVal0)), primVal1), primVal2", { primVal0: row.getValueInColumn(primaryColNames[0]), primVal1: row.getValueInColumn(primaryColNames[1]), primVal2: row.getValueInColumn(primaryColNames[2]), cellFromGetCell0: row.getCellInColumn(primaryColNames[0]), cellFromGetCell1: row.getCellInColumn(primaryColNames[1]), cellFromGetCell2: row.getCellInColumn(primaryColNames[2]), row, rowNumber, parentIdByTitle: parentIdByIdentifier, currParentId, parentIdentifier, concat: concatNames(row.getValueInColumn(primaryColNames[0]), row.getValueInColumn(primaryColNames[1]), row.getValueInColumn(primaryColNames[2])) }); // TODO: remove on production
          throw `Could not resolve ${parentObjIdentifierName}. Please check the primary column(s) in row ${rowNumber}`; // TODO: translate
        }

        if (!pastParentIds[currParentId])
          pastParentIds[currParentId] = { rowNumber };
        else {
          console.error(`Found twice the same ${parentObjIdentifierName} in row ${pastParentIds[currParentId].rowNumber} and ${rowNumber}.`, { pastParentIds, currParentId, rowNumber });
          throw `Found twice the same ${parentObjIdentifierName} in row ${pastParentIds[currParentId].rowNumber} and ${rowNumber}. Please remove one and try again.`; // TODO: translate
        }

        await Promise.all(row.eachCell(async (cell, colName) => {
          if (primaryColNames.includes(colName))
            return;

          const splitName = colName.split('\n').map(n => n.trim()).filter(n => Boolean(n));
          const [sectionTitle, propTitle] = splitName;
          if (splitName.length !== 2) {
            console.error("getUpdatedPropertiesFromExcel -> splitName", splitName);
            throw `Column "${sectionTitle} ${propTitle}" does not have a section and/or property name`; // TODO: translate 
          }


          if (!allExistingSectionsAndProps.getNested([sectionTitle]))
            throw `Could not find section matching "${sectionTitle}"`; // TODO: make it intl


          if (!allExistingSectionsAndProps.getNested([sectionTitle, propTitle]))
            throw `Could not find property matching "${propTitle}" in section "${sectionTitle}"`; // TODO: make it intl

          let newDataVal = cell.value;
          if (_.isNil(newDataVal)) {
            return;
          }

          if (!allExistingSectionsAndProps.getNested([sectionTitle, propTitle, currParentId])) {
            console.error("getUpdatedPropertiesFromExcel -> newDataVal", cell.value);
            throw `This object cannot be assigned the property "${propTitle}" (row ${rowNumber})`; // TODO: translate
          }

          const currPopulatedObj = populatedObjectsTitlesMapByParentId.getNested([currParentId, sectionTitle, propTitle]);
          if (!currPopulatedObj) {
            console.error("getUpdatedPropertiesFromExcel -> currPopulatedObj", { currPopulatedObj, currParentId, sectionTitle, propTitle });
            throw `Internal error. Please contact support ( a.k.a. Ilann ;) )`; // TODO: change for production
          }

          const { type, data, instanceId } = currPopulatedObj;
          let { fullProp } = currPopulatedObj;
          fullProp = fullProp && fullProp.toJS ? fullProp.toJS() : fullProp;
          if (!fullProp) {
            console.error("getUpdatedPropertiesFromExcel -> currPopulatedObj", { fullProp, currPopulatedObj, currParentId, sectionTitle, propTitle });
            throw `Internal error. Please contact support ( a.k.a. Ilann ;) )`; // TODO: change for production
          }

          const propId = fullProp.getNested(['id']);

          const deleteProperty = newDataVal === SAFE_DELETE_KEYWORD;
          if (deleteProperty)
            newDataVal = data;
          else {
            switch (type) {
              case (propertyTypes.STRING): {
                newDataVal = String(newDataVal);
                break;
              }

              case (propertyTypes.BOOLEAN): {
                cell.checkValType([type]);
                newDataVal = Boolean(newDataVal);
                break;
              }

              case (propertyTypes.NUMBER): {
                cell.checkValType([type]);
                newDataVal = Number(newDataVal)
                break;
              }

              case (propertyTypes.DATE): {
                if (!(newDataVal instanceof Date))
                  throw `Unable to validate the date entered in column "${sectionTitle} ${propTitle}" row ${rowNumber}.\nPlease make sure it is properly formatted.` // TODO: intl it

                newDataVal = newDataVal.getTime();
                break;
              }

              case (propertyTypes.SELECTION_LIST): {
                newDataVal = String(newDataVal);
                let options = fullProp.getNested(['values']);
                if (!options)
                  throw `Could not find any options related to the property "${propTitle}" in section "${sectionTitle}"`;

                let optionId;
                let availableOptionsTitles = [];
                options.loopEach((i, option) => {
                  const optionTitle = option.getCementoTitle();
                  availableOptionsTitles.push(optionTitle);

                  if (newDataVal.trim().toLowerCase() !== optionTitle.trim().toLowerCase())
                    return;

                  optionId = option.id;
                });

                if (!optionId) {
                  console.error("getUpdatedPropertiesFromExcel -> no optionId", { optionId, newDataVal, currPopulatedObj, propId, fullProp: fullProp.toJS ? fullProp.toJS() : fullProp });
                  throw `Could not find option "${newDataVal}" for property "${propTitle}" in section "${sectionTitle}".\nAvailable options are:\n${availableOptionsTitles.join('\n')}`; // TODO: translatte on col ... on row
                }

                newDataVal = { [optionId]: optionId };
                break;
              }

              // For now other types are not supported
              case (propertyTypes.PDF):
              case (propertyTypes.PICTURE):
              case (propertyTypes.CERTIFICATION):
              case (propertyTypes.FILES_ARRAY):
              case (propertyTypes.DRAWINGS_ARRAY):
              case ('PredefinedComponent'): // TODO: add to constants once defined
                if (!isWithFilesMode)
                  throw `We do not yet support file imports. Please, remove the value located in column "${sectionTitle} ${propTitle}", row ${rowNumber} and try again.`; // TODO: translate
                
                const firebasePath = newDataVal;
                newDataVal = await utils.firebaseGet(firebasePath);

                if (!newDataVal)
                  throw `We were unable to find the data that you requested at path "${firebasePath}". Please, make sure it exists and try again. (section: "${sectionTitle}; column: ${propTitle}"; row ${rowNumber})`; // TODO: translate
                
                break;
              
              default:
                console.error("getUpdatedPropertiesFromExcel -> type", { type, sectionTitle, propTitle, rowNumber, newDataVal, cell, currParentId, currPopulatedObj, propId });
                throw `Could not match the property type. Please contact support ( a.k.a. Ilann ;) )`; // TODO: change for production
            }

            if (_.isEqual(newDataVal, data)) {

              return;
            }

          }


          let newProperty = {
            id: instanceId,
            data: newDataVal,
            parentId: currParentId,
            propId,
            propType: type,
            updatedTS: Date.now(),
            isDeleted: deleteProperty ? true : null,
          };

          if (!newProperty.id && !newProperty.isDeleted) {
            const { instanceId: newInstanceId } = getNewPropertyInstanceId(selectedProjectId, subjectType + 'info').payload;
            newProperty.id = newInstanceId;
            newProperty.createdTS = Date.now();
            newPropInstanceIDs[newProperty.id] = newProperty.id;
          }

          if (newProperty.id)
            updatedPropertiesMap[newProperty.id] = newProperty;
        }));
      }));
    }));

    return { updatedPropertiesMap, newPropInstanceIDs };
  }

  getMapPopulatedObjectsPropsToTitlesMapByParentId() {
    const { allProps } = this.state;
    let allExistingSectionsAndProps = {};
    let populatedObjectsTitlesMapByParentId = {};
    _.values(allProps).forEach(populatedObject => {
      const { id: parentId, props } = populatedObject;

      if (!props) return;

      props.loopEach((propId, propInfo) => {
        if (propId === 'groups') {
          populatedObjectsTitlesMapByParentId = populatedObjectsTitlesMapByParentId.setNested2([parentId, 'groups'], propInfo);
          return;
        }

        let { propTitle = '', section: { title: sectionTitle = '' } = {} } = propInfo;
        sectionTitle = sectionTitle.split('\n').join(' ').trim();
        propTitle = propTitle.split('\n').join(' ').trim();
        allExistingSectionsAndProps = allExistingSectionsAndProps.setNested2([sectionTitle, propTitle, parentId], true);
        populatedObjectsTitlesMapByParentId = populatedObjectsTitlesMapByParentId.setNested2([parentId, sectionTitle, propTitle], propInfo);
      });
    });

    return { populatedObjectsTitlesMapByParentId, allExistingSectionsAndProps };
  }

  handleSelectDateDone = (dateTS) => {
    this.handleAddObjectClick(null, true, undefined, { reportDate: dateTS });
    this.setState({ isShowSelectDateModal: false });
  }

  onSideClose = () => {
    this.setState({ sideCardObject: null, cardIsDisabled: true });
  }

  render() {
    const { contentType, match } = this.props;
    const {
      rowsLevel, originalData, columns, showRowsWithEmptyValue,
      defaultSubMenu, subjectType, selectedFilterSet, primaryColumn,
      sideCardObject, noReportMenus, displayPdf, firstColumnRenderFunc,
      isShowSelectDateModal, allProps, isCarouselVisible,
      carouselItems, isPDFCarousel, filters, featureName
    } = this.state;

    let tableWrapperProps = {
      columns,
      originalData,
      primaryColumn,
      expandableTable: Boolean(rowsLevel > 1),
      shouldExpandSingleFirstLevel: true,
      rowsLevel,
      shouldCalculateAggregatedCells: _.get(filters, 'isSubTable', false),
      filterUrlKey: TABLES_FILTER_URL_KEY,
    };


    if (noReportMenus) {
      return (
        <div style={{ justifyContent: 'start', alignItems: 'center', display: 'flex', flex: 1, flexDirection: 'column' }}>
          <div style={{ flexDirection: 'column', margin: theme.margin * 3 }}>
            <Text style={{ fontWeight: 'bold', fontSize: theme.fontSizeH3, color: theme.brandPrimary }}>
              {(_.get(messagesTypesMap, [featureName], propertiesMessages)).noReport.title}
            </Text><br />
            <Text style={{ fontSize: theme.fontSizeH4, lineHeight: 1.4 }}>
              {(_.get(messagesTypesMap, [featureName], propertiesMessages)).noReport.content}
            </Text>
          </div>
        </div>
      );
    }

    const columnCount = Math.min(Math.max(Boolean(columns) ? columns.length : 0, 10), 100);
    const paginationStep = Math.round(1000 / columnCount);
    return (
      <QCReportContext.Provider value={noValue}>
       {Boolean(isCarouselVisible && _.get(carouselItems, 'length')) && (
          <ImageCarousel
            pdfMode={isPDFCarousel}
            onClose={this.handleCloseCarousel}
            initialSlide={0}
            items={carouselItems} 
          />
        )}
        {Boolean(isShowSelectDateModal) && (
          <Modal 
            open
            onClose={() => this.setState({ isShowSelectDateModal: false })}
            style={{ zIndex: theme.zIndexes.propertyAnalyticsDatePicker }}
          >
            <div style={{ height: '100%', width: '100%' }}>
              <CardHeader style={{ width: '100%', height: CALENDAR_HEADER_HEIGHT, backgroundColor: theme.backgroundColor, borderBottom: theme.borderLineHeaderInfo }}>
                <Text style={{ fontSize: theme.fontSizeH6, fontWeight: theme.strongBold, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>{reportsMessages.newReport.title}</Text>
              </CardHeader>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: `0 ${theme.padding * 4}px` }}>
                <Text style={{ fontSize: theme.fontSizeH6, margin: theme.padding }}>{reportsMessages.selectADate}</Text>
                <div style={{ margin: `${theme.padding}px 0` }}>
                  <Calendar
                    markedDates={!Boolean(_.keys(allProps).length) ? null : _.values(allProps).reduce((acc, form) => {
                      if (form && form.reportDate && form.status) {
                        if (!acc[form.reportDate])
                          acc[form.reportDate] = { colors: [] };

                        acc[form.reportDate].colors.push(getFormStatusParams(form).color);
                      }

                      return acc;
                    }, {})}
                    onDone={this.handleSelectDateDone}
                    initialValue={getRoundedDate().timestamp}
                    doneButtonTitle={reportsMessages.buttons.createNewReport}
                    maxTS={Date.now()}
                  />
                  <style jsx>{`
                    .rdtDays {
                      width: 260px;
                    }
                  `}</style>
                </div>
              </div>
            </div>
          </Modal>
        )}

        <TableWrapper
          showRowsWithEmptyValue={showRowsWithEmptyValue}
          defaultSubMenu={defaultSubMenu}
          selectedFilterSet={selectedFilterSet}
          secRowHeight={75}
          paginationStep={paginationStep}
          subjectType={subjectType}
          match={match}
          infoCardMode={['forms'].includes(subjectType) ? 'modal' : 'side'}
          rowsLevel={rowsLevel}
          contentType={contentType}
          cellHeight={cellHeight}
          getSideCardObject={this.getSideCardObject}
          onSideClose={this.onSideClose}
          sideCardObject={sideCardObject}
          customWidth={contentType === 'settings' && customWidth}
          firstColumnTitle={primaryColumn && primaryColumn.original.title}
          firstColumnRenderFunc={firstColumnRenderFunc}
          filterPathDelimeter={FILTER_MENU_PATH_DELIMETER}
          hasUnSavedChanges={this.hasUnSavedChanges}
          setHasUnSavedChanges={this.setHasUnSavedChanges}
          {...tableWrapperProps}
        />

        {Boolean(displayPdf) &&
          <ImageCarousel
            toolbar={true}
            pdfMode={true}
            onClose={this.closeImageCarousel}
            initialSlide={0}
            items={[{ src: displayPdf }]} />

        }
      </QCReportContext.Provider>
    );
  }
}
const noValue = {};

const styles = {
	textCenter: {
		textAlign: 'center',
		alignItems: 'center',
		alignContent: 'center',
		justify: 'center',
	},
	checkedIcon: {
		color: theme.brandPrimary,
		width: '20px',
		height: '20px',
		border: '1px solid rgba(0, 0, 0, .54)',
		borderRadius: '3px',
	},
	uncheckedIcon: {
		color: theme.brandPrimary,
		width: '0px',
		height: '0px',
		padding: '9px',
		border: '1px solid rgba(0, 0, 0, .54)',
		borderRadius: '3px',
	},
};

PropertyAnalytics = injectIntl(PropertyAnalytics);
PropertyAnalytics = withStyles(styles)(PropertyAnalytics);
PropertyAnalytics = withRouter(PropertyAnalytics);
const enhance = compose(
	connectContext(ProjectContext.Consumer),
	connect(
		state => ({
			rtl: state.app.rtl,
			storageCleaned: state.app.storageCleaned,
			employeesLastRevokeAvailable: state.employees.lastRevokeAvailable,
			employeesLastRevoked: state.employees.lastRevoked,
			employeesDidLoad: state.employees.didLoad,
			equipmentLastRevokeAvailable: state.equipment.lastRevokeAvailable,
			equipmentLastRevoked: state.equipment.lastRevoked,
			equipmentDidLoad: state.equipment.didLoad,
			companies: state.companies.map,
			titles: state.titles.map,
			trades: state.trades.map,
		}),
		{
			saveMenus,
			startLoading,
			hideLoading,
			startToast,
			getEmployees,
			getEquipment,
			getEmployeeNewId,
			getEquipmentNewId,
			getNewPropertyInstanceId,
			startLoading,
			hideLoading,
			onDraftModeChange,
			uploadPropertiesInstances,
			getNewFormId,
			upsertForm,
			exportFormPDF,
			startFormsListenerByType,
			endFormsListenerByType,
			track,
		},
	),
);

export default enhance(PropertyAnalytics);
