import { Record } from '../transit';

const Drawing = Record({
	type: null,
	id: null,
	category: null,
	ext: null,
	title: null,
	updateDate: null,
	uriThumbnail: null,
	uri: null,
	uriPdf: null,
	version: null,
	number: null,
	status: null
}, 'drawing');

export default Drawing;
