import React, { Component } from 'react';
import { Provider, connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import '../assets/scss/material-dashboard-pro-react.css?v=1.3.0';
import withStyles from '@material-ui/core/styles/withStyles';
import Alerts from './Alerts';
import notificationsStyle from '../assets/jss/material-dashboard-pro-react/views/notificationsStyle.jsx';
import { initData } from '../../common/lib/reporting/actions';
import {
	cleanDynamicCachedData,
	checkUpdateVersion,
	checkAndUpdateSettingsFromDB,
	hideAllLoading,
} from '../../common/app/actions';
import { getMyUserInfo } from '../../common/users/actions';
import { setAppIntl } from '../../common/app/actions';
import start from '../../common/app/start';
import { getValidAuth0Token } from '../../common/auth/actions';
import { track, trackStart, trackEnd } from '../../common/lib/reporting/actions';
import UserValidator from '../UserValidator';
import { mainRoutes } from '../routes/index';
import { lastDeploy } from './lastDeploy';
import '../assets/css/app.css';
import analyticsMessages from '../../common/analytics/analyticsMessages';
import safetyMessages from '../../common/safety/safetyMessages';
import ScopeContextPage from '../views/Projects/ScopeContextPage';
import CompanyContextPage from '../views/Companies/CompanyContextPage';
import PdfContainerView from '../views/PdfView/PdfViewContainer';
//import { getAppState } from '../../common/configureMiddleware';

const hist = createBrowserHistory();

// window.onbeforeunload = function() {
//     if (getAppState && getAppState() && getAppState().ui && getAppState().ui.inDraftMode)
//       return "Are you sure you want to navigate away?";
// }

class App extends Component {
	constructor(props, context) {
		super(props, context);
		this.didLoggedIn = false;
		this.runAfterLogin = this.runAfterLogin.bind(this);
		this.state = {};
	}

	componentWillMount() {
		const { setAppIntl, intl } = this.props;
		setAppIntl(intl);
		console.log(lastDeploy);
	}

	componentDidUpdate(prevProps, prevState) {
		const { loggedIn, toast, viewer, storageLoaded, checkAndUpdateSettingsFromDB, checkUpdateVersion } = this.props;
		if (!this.didLoggedIn && loggedIn) this.didLoggedIn = true;

		if (this.didLoggedIn && viewer && !this.didRunAfterLogin) {
			this.didRunAfterLogin = true;
			this.runAfterLogin();
		}

		if (loggedIn && storageLoaded && !this.didRunLoginAndStorageOnDone) {
			this.didRunLoginAndStorageOnDone = true;
			checkUpdateVersion();
			checkAndUpdateSettingsFromDB();
		}
	}
	componentDidMount() {
		const { hideAllLoading } = this.props;
		window.onpopstate = e => hideAllLoading();
	}

	runAfterLogin() {
		const { getValidAuth0Token, storageLoaded, initData, track, viewer } = this.props;
		const retryFunc = (async () => {
			if (!storageLoaded) return;

			getValidAuth0Token();
		}).bind(this);

		track('login');
		initData(Boolean(viewer && viewer.toJS) ? viewer.toJS() : viewer);
		if (!this.retryMessagesInterval) this.retryMessagesInterval = setInterval(retryFunc, 1000 * 60 * 2);
	}

	handleSignOut() {
		clearInterval(this.retryMessagesInterval);
	}

	render() {
		const { store, toast, intl, loading, rtl, alert } = this.props;
		const isPdfMode = hist.getNested(['location', 'pathname'], '').includes('/pdf');

		return (
			<div style={{ direction: rtl ? 'rtl' : 'ltr', position: 'relative', overflow: 'hidden' }}>
				<Provider store={store}>
					{
						isPdfMode ? (
							<PdfContainerView history={hist} />
						) : (
							//<CompanyContextPage> // TODO: If we want to have companies context in the future, where we can see all employees for example, this is the start of it
							<ScopeContextPage>
								<Router history={hist}>
									<Switch>
										<UserValidator onSignOut={this.handleSignOut.bind(this)}>
											<Alerts intl={intl} rtl={rtl} toast={toast} loading={loading} alert={alert} />
											{mainRoutes.map((prop, key) => (
												<Route path={prop.path} component={prop.component} key={key} />
											))}
										</UserValidator>
									</Switch>
								</Router>
							</ScopeContextPage>
						)
						//</CompanyContextPage>
					}
				</Provider>
			</div>
		);
	}
}

App = injectIntl(App);
App = connect(
	state => ({
		loggedIn: state.auth.loggedIn,
		viewer: state.users.viewer,
		loading: state.app.loading,
		toast: state.app.toast,
		alert: state.app.alert,
		rtl: state.app.rtl,
		storageLoaded: state.app.storageLoaded,
		auth: state.auth,
		users: state.users,
		formUniversalIdsMap: state.quasiStatics.formUniversalIdsMap,
		urlParams: state.ui.urlParams,
	}),
	{
		hideAllLoading,
		getValidAuth0Token,
		checkUpdateVersion,
		cleanDynamicCachedData,
		getMyUserInfo,
		track,
		trackStart,
		trackEnd,
		start,
		initData,
		checkAndUpdateSettingsFromDB,
		setAppIntl,
	},
)(App);

App = withStyles(notificationsStyle)(App);
export default start(App);
