import moment from 'moment';
import { platformActions } from '../platformActions';
import { getAppState, realmInstance, lokiInstance } from '../configureMiddleware';
import theme from '../app/theme';
import safetyMessages from '../safety/safetyMessages';
import systemMessages from '../app/systemMessages';
import * as propertyTypes from '../propertiesTypes/propertiesTypes';
import _ from 'lodash';
import { getFullLocationDetailsByIdNoProps, getLocationTitleNoProps } from '../locations/func';

export function getRelevantPropertyIds(propertiesMappings, subjectName, objectGroupId, objectInstances) {
  let maps = _.get((propertiesMappings && propertiesMappings.toJS) ? propertiesMappings.toJS() : propertiesMappings, subjectName);
  let ret = new Set();
  let relevantPropertyIds = _.values(_.get(maps, ['groups', objectGroupId, 'properties']));

  let alreadyAddedPropIds = {};

  while (relevantPropertyIds.length) {
    let currPropId = relevantPropertyIds.shift();
    ret.add(currPropId);
    alreadyAddedPropIds[currPropId] = true;

    let propMap = _.get(maps, currPropId);
    let propInstance = _.get(objectInstances, currPropId);

    if (currPropId != 'groups' && propMap && propInstance) {
      let currInstanceData;
      try { currInstanceData = JSON.parse(propInstance.data); }
      catch (err) { currInstanceData = propInstance.data; };
      currInstanceData = (propInstance.propType == propertyTypes.SELECTION_LIST) ? _.keys(currInstanceData) : [currInstanceData];

      currInstanceData.forEach(currInstanceDataObj => {
        let newRelevantPropertyIds = _.get(propMap, [currInstanceDataObj, 'properties'], []).filter(newPropId => !alreadyAddedPropIds[newPropId]);
        relevantPropertyIds.push(...newRelevantPropertyIds);
      })
    }
  }

  return Array.from(ret);
}

function andOrBuilder(ids, idField) {
  if (!ids) return '';

  let extraQuery = ' ';

  if (_.isArray(ids) && ids.length == 0)
    extraQuery += `LIMIT(0)`;
  else {
    extraQuery = 'AND ( ';
    extraQuery += ids.map(id => `(${idField}  = "${id}")`).join(" OR ");
    extraQuery += ')';
  }

  return extraQuery;
}

export const statusColorOrdinalNo = {
  [theme.brandRealDanger]: 1,
  [theme.brandDanger]: 2,
  [theme.brandWarning]: 3,
  [theme.brandSuccess]: 4,
  [theme.darkSeparatorColor]: 5,
};

export function populateObject({
  selectedProjectId,
  subjectType,
  propertiesSections,
  propertiesTypes,
  inPropertiesMappings,
  intl,
  instancesDataUpdatesByPropId,
  objectsIds,
  inAllProps = {},
  inSpecificProps,
  inObjects = null,
  extraLocalInstances = null,
  inInstances = null,
  extraLocalObjects = null,
  skipPopulatedObjects = false,
  groupedObjectsColorMapper: injectedGroupColorMapper
}) {
  let isWeb = platformActions.app.getPlatform() == 'web';

  let specificProps = null;
  if (inSpecificProps) {
    specificProps = {};
    inSpecificProps.forEach(x => {specificProps[x] = true});
  } 

  let propertiesMappings = inPropertiesMappings && Boolean(inPropertiesMappings.toJS) ? inPropertiesMappings.toJS() : inPropertiesMappings;
    //todayTS = moment(moment().format("YYYY-MM-DD")).utc().valueOf();
    let subjectName = subjectType + 'Info';
    let sectionsMap = propertiesSections && propertiesSections[subjectName] || {};

    let objects = inObjects || [];
    let instances = inInstances || [];
    let primaryPropId = null;
    let universalsPropsIds = {};
    let instancesByParentAndProp = {};
    let allProps = inAllProps || {};
    if (objectsIds && allProps) {
      objectsIds.forEach(x => {delete allProps[x]});
      inSpecificProps = null;
    }
    
    if (isWeb) {
      let objectQuery =    {'projectId' : selectedProjectId };
      let instancesQuery = {'projectId' : selectedProjectId, subjectName: subjectName };

      if (objectsIds && objectsIds.length) {
        objectQuery['id'] = {'$in' : objectsIds };
        instancesQuery['parentId'] = {'$in' : objectsIds };
      }

      if (subjectType == 'projects')
        objects = [{ id: selectedProjectId }];
      else if (subjectType === 'companies')
        objects = inObjects || [];
      else if (!inObjects)
        objects = lokiInstance.getCollection(subjectType) ? lokiInstance.getCollection(subjectType).cementoFind(objectQuery) : []; // TODO: Add filter on requested objectId
      if (!inInstances)
        instances = lokiInstance.getCollection('propertyInstances').cementoFind(instancesQuery);
    }
    else {
      let realmSchemaName = subjectType == 'employees' ? 'employee1' : subjectType == 'equipment' ? 'equipment1' : null;
      if (subjectType == 'projects')
        objects = [{ id: selectedProjectId }];
      else if (subjectType === 'companies')
        objects = inObjects || [];
      else if (!inObjects) 
        objects = realmInstance[subjectType].objects(realmSchemaName).filtered('projectId = "' + selectedProjectId + '"' + andOrBuilder(objectsIds, "id"));
      if (!inInstances)
        instances = realmInstance.propertyInstances.objects('propertyInstance1').filtered(`projectId = "${selectedProjectId}" AND subjectName = "${subjectName}"  ${andOrBuilder(objectsIds, "parentId")}`);
    }

    if (extraLocalInstances && instances)
      instances = _.concat(instances, extraLocalInstances.filter(instance => !Boolean(instance.isDelete))
                                                        .map(instance => instance.setNested2(['subjectName'], subjectName)))
    if (extraLocalObjects && objects)
      objects = _.concat(objects, Object.values(extraLocalObjects).filter(obj => !Boolean(obj.isDeleted)))

    instances.forEach(inst => {  
      if (!instancesByParentAndProp[inst.parentId]) 
        instancesByParentAndProp[inst.parentId] = {};
      instancesByParentAndProp[inst.parentId][inst.propId] = inst;
    });
 
    let originalSubjectProperties = (propertiesTypes||{})[subjectName];
    let subjectProperties = {};
    (originalSubjectProperties||{}).loopEach((id, prop) => { 
      if (prop.isPrimary) 
        primaryPropId = prop.id;
      if (prop.universalId)
        universalsPropsIds[prop.id] = prop.universalId;

      subjectProperties[prop.id] = prop;
    })
    
    objects.forEach(currRealmObject => {
      const currObject = currRealmObject.realmToObject()
      if (currObject.isDeleted)
        return null;

      let objectId = currObject.id;
      let currRelevantPropTypesIdsArray = [];
      let objectInstances = instancesByParentAndProp.getNested2([objectId], {});
      let objectGroupData = (instancesDataUpdatesByPropId || {}).getNested2(['groups']) || objectInstances.getNested2(['groups', 'data'], null);
      
      if (objectGroupData && platformActions.app.getPlatform() != 'web' && typeof objectGroupData === 'string')
        try { objectGroupData = JSON.parse(objectGroupData); } catch (error) { console.log(`funcs.js ~ line 160 ~ objects.loopEach ~ JSON.parse error`, error); }
      
      let objectGroupId = null;
      if (objectGroupData) 
        objectGroupId = Object.keys(objectGroupData)[0]; 

      currRelevantPropTypesIdsArray = getRelevantPropertyIds(propertiesMappings, subjectName, objectGroupId, objectInstances);

      if (!allProps[objectId])
        allProps[objectId] = _.assign({}, currObject);
      let isMissingRequirementArray = [];
      currRelevantPropTypesIdsArray.forEach(propId => {
        let currProp = subjectProperties[propId];// propertiesTypes.getNested2([subjectName, propId]);
        
        if (!currProp)
          return null;

        if (specificProps && !specificProps[currProp.type] && !specificProps[currProp.universalId] && !(specificProps["isPrimary"] && currProp.id == primaryPropId))
          return null;

        let currInstance = objectInstances[currProp.id];
        if (currInstance && currInstance.isDeleted)
          currInstance = null;

        let isPropRequired = currProp.settings && Boolean(currProp.settings.isRequired);
        if (currInstance == null && isPropRequired)
          isMissingRequirementArray.push(currProp);
        
        let data = (instancesDataUpdatesByPropId || {}).getNested2([currProp.id], (!_.isNil((currInstance || {}).data) ? (currInstance || {}).data : null));
        if (!_.isNil(data) && platformActions.app.getPlatform() != 'web' && typeof data === 'string')
          try { data = JSON.parse(data); }
           catch (error) { console.log(`funcs.js ~ line 203 ~ currRelevantPropTypesIdsArray.loopEach ~ JSON.parse`, error); }
        
        if (data === null && !_.isNil(currProp.getNested2(['settings', 'defaultVal'])))
          data = currProp.getNested2(['settings', 'defaultVal']);

        let populatedProp = { 
          data,
          fullProp: currProp, 
          type: currProp.type,
          isRequired: isPropRequired,
          instanceId: currInstance ? currInstance.id : null,
          businessType: currProp.businessType
        };

        let propTitle = currProp.getCementoTitle();
        if (currProp.type == propertyTypes.CERTIFICATION) {
          let currSignatureBehaviour = _.get(_.last(data), ['signatureBehaviour']);
          let signatureBehaviourText = _.get(currProp, ['settings', 'signatureBehaviour', currSignatureBehaviour, 'text']);
          if (signatureBehaviourText) propTitle += ` (${signatureBehaviourText})`;
        }
        populatedProp.propTitle = propTitle;

        if (currProp.sectionId && sectionsMap[currProp.sectionId])
          populatedProp.section = { id: sectionsMap[currProp.sectionId], title: _.get(sectionsMap, [currProp.sectionId], {}).getCementoTitle() };
          

        let dataText = instanceDataToString(currProp, populatedProp.data, intl, true);
        if (currProp.type == propertyTypes.DRAWINGS_ARRAY || currProp.type == propertyTypes.FILES_ARRAY || currProp.type == propertyTypes.PICTURE || currProp.type == propertyTypes.PDF) {
          populatedProp.uri = dataText;
        } else if ((currProp.type == propertyTypes.CERTIFICATION) ||
                   (currProp.type == propertyTypes.DATE && (currProp.settings && (currProp.settings.isExpiration || currProp.settings.isWarning)))) {
          if (populatedProp.data) {
            let info = checkPropSettings(currProp, populatedProp.data, intl)
            populatedProp.dataText = dataText;
            populatedProp = Object.assign(populatedProp, info);
            if (info.isExpired || info.isWarning) {
              _.set(allProps, [objectId, (info.isExpired ? 'expiredList' : 'warningList'), currProp.id], populatedProp);
              if (info.isExpired)
                _.set(allProps, [objectId, 'isExpired'], info.isExpired);
              if (info.isWarning){
                _.set(allProps, [objectId, 'isWarning'], info.isWarning);
              }
            }
          }
        }
        else 
          populatedProp.dataText = dataText;

          if (currProp.type == propertyTypes.CERTIFICATION && !populatedProp.statusColor)
          populatedProp.statusColor = theme.brandSuccess;

        _.set(allProps, [objectId, 'props', currProp.id], populatedProp);

        if (currProp.id == primaryPropId)
          _.set(allProps, [objectId, 'primaryProp'], populatedProp);
        if (universalsPropsIds[currProp.id]) {
          _.set(allProps, [objectId, 'universal', currProp.universalId], populatedProp);
        }
      });
      // Check per object if - isMissingRequirement;
      isMissingRequirementArray.forEach(missingReq => {
        _.set(allProps, [objectId, 'props', missingReq.id, 'isExpired'], true);
        _.set(allProps, [objectId, 'props', missingReq.id, 'statusColor'], theme.brandRealDanger);
        _.set(allProps, [objectId, 'isExpired'], true);
      });
    });

    let objectsToReturn = { subjectProperties, universalsPropsIds, allProps };
    
    // Run over all items and do last changes

  const defaultGroupColorMapper = x => {
    if (x.isExpired && x.isWarning)
      delete x['isWarning'];

    if (x.isWarning)
      x.color = theme.brandWarning;

    if (x.missingRequirementList)
      x.color = theme.brandRealDanger;

    if (x.isExpired)
      x.color = theme.brandRealDanger;

    if (!x.color)
      x.color = theme.brandSuccess;
    return x;
  };


  if (!skipPopulatedObjects) {
    let populatedObjects = Object.values(allProps).map(_.isFunction(injectedGroupColorMapper) ? injectedGroupColorMapper : defaultGroupColorMapper);

      populatedObjects.sort((b, a) => {
        let aPriority = a.isExpired ? 3 : Boolean(a.missingRequirementList) ? 2 : (a.isWarning ? 1 : 0);
        let bPriority = b.isExpired ? 3 : Boolean(b.missingRequirementList) ? 2 : (b.isWarning ? 1 : 0);
        return (aPriority - bPriority);
      });

      objectsToReturn.populatedObjects = populatedObjects;
    }

    return objectsToReturn;
}

function checkDate(settings, ts, intl) {
  let ret = {}
  if (!ts)
    return ret;

  let daysToWarning = null;
  let isWarning = false;
  let isExpired = false; 
  let value = parseInt(ts);
  const todayTS = moment(moment().format("YYYY-MM-DD")).utc().valueOf();
  
  let isDailyExpiration = Boolean(_.get(settings, ['certificationDaysTTL']) == 1);
  if (_.has(settings, 'isWarning'))
    daysToWarning = isDailyExpiration ? 1 : Number(_.get(settings, ['daysToWarning'], 30));

  let startOfToday = moment(todayTS).startOf('day').valueOf();
  let startOfExpirationPeriod = moment(value).startOf('day').add(1, 'day').valueOf();
  let startOfWarningPeriod = moment(startOfExpirationPeriod).subtract(daysToWarning, 'days').valueOf();
  
  if (_.has(settings, 'isWarning'))
    isWarning = startOfToday >= startOfWarningPeriod;
  if (_.has(settings, 'isExpiration'))
    isExpired = startOfToday >= startOfExpirationPeriod
  
  let dateColor   = isExpired ? theme.brandRealDanger : (isWarning ? theme.brandWarning : theme.brandInfo);
  let statusColor = isExpired ? theme.brandRealDanger : (isWarning ? theme.brandWarning : theme.brandSuccess);

  if (isExpired || isWarning) {
    let daysGap = Math.round(Math.abs(todayTS - value)/(24 * 60 * 60 * 1000));
    ret.dataText = intl.formatMessage(isExpired ? safetyMessages.expiredXDaysAgo : safetyMessages.validForXMoreDays, {value:daysGap});
  }

  ret = Object.assign(ret, {isExpired, isWarning, color: dateColor, statusColor, lastCertExpirationTS: value});
  return ret;
}

export function checkPropSettings(prop, data, intl) {
  let info = {};
  if (prop && prop.type == propertyTypes.DATE) {
    info = checkDate(prop.settings, data, intl);
  } else if (prop && prop.type == propertyTypes.CERTIFICATION) {
    let certArray = data || [];
    if (certArray.length) {
      //certArray = certArray.sort((a,b) => a.certificationTTL - b.certificationTTL);
      let latestCert = certArray[certArray.length - 1]
      info = checkDate(prop.settings || {isExpiration: true, isWarning: true}, latestCert.certificationTTL, intl);
    } else {
      if (_.get(prop, ['settings', 'isRequired']))
        info.missingRequirementList = true;
    }
  }

  return info;
}
export const instanceDataToStringNoIntl = (prop, data, withURI) => {
  const appIntl = getAppState().getNested(['app', 'intl']);
  return instanceDataToString(prop, data, appIntl, withURI);
}
export function instanceDataToString(prop, data, intl, withURI) {
  let textToRet = ''; 
  if (_.isNil(data) && _.isNil(_.get(prop, ['settings', 'defaultVal'])))
    return textToRet;

  if (prop.type == propertyTypes.SELECTION_LIST && prop.values) {
    let selectedId = Object.keys(data)[0]
    prop.values.forEach(x => {
      if (x.id == selectedId)
        textToRet = x.getCementoTitle();
    })
  } else if (prop.type == propertyTypes.STRING)
    textToRet = data;
  else if (withURI && (prop.type == propertyTypes.DRAWINGS_ARRAY || prop.type == propertyTypes.FILES_ARRAY) && data.length) {
    textToRet = ((data[0].type) && data[0].type.toLowerCase() == propertyTypes.PDF.toLowerCase()) ? null : data[0].uri;
  } else if (withURI && prop.type == propertyTypes.PICTURE) {
    textToRet = data.uri;
  } else if (prop.type == propertyTypes.NUMBER) {
    textToRet = String(data);
  } else if (withURI && (prop.type == propertyTypes.PDF)) {
    textToRet = data.uri;
  } else if (prop.type == propertyTypes.BOOLEAN) {
    let val = (data == null || data == undefined) ? prop.getNested2(['settings','defaultVal'], false) : data;
    textToRet = intl.formatMessage(val ? systemMessages.yes : systemMessages.no);
  }
  else if (prop.type == propertyTypes.DATE || prop.type == propertyTypes.CERTIFICATION) {
    let info = checkPropSettings(prop, data, intl);
    if (info.dataText)
      textToRet = info.dataText;
  }
  else if (data && prop.type === propertyTypes.LOCATION && typeof getAppState === 'function') {
    let locationString = null;

    const firstId = _.head(_.keys(data)); // TODO: support multi
    const fullLocationDetails = getFullLocationDetailsByIdNoProps(firstId); // TODO: support multi
    if (fullLocationDetails.locationFound)
      locationString = getLocationTitleNoProps({ intl, locationObj: fullLocationDetails.locationIds, separator: ' | ' });
    
    return locationString;
  }

  return textToRet
}

export function instancesDataToString (subjectPropertiesTypes, dataByPropId, intl, withURI) {
  const dataStringByPropId = {};
  
  Object.values(dataByPropId).forEach(([propId, data]) => {
    const prop = (subjectPropertiesTypes || {}).getNested([propId]);
    if (prop)
      dataStringByPropId[propId] = instanceDataToString(prop, data, intl, withURI);
  });

  return dataStringByPropId;
}

export async function getPropInstancesPerProject(companyProjects) {
  const firebase = platformActions.firebase.getFirebase();
  let instancesPerProject = {};
  
  await Promise.all(Object.keys(companyProjects).map(async currProjectId => {
    const projectInstances = (await firebase.database().ref(`properties/instances/projects/${currProjectId}`).once('value')).val() || null;
    if (!projectInstances) return;
    
    instancesPerProject[currProjectId] = projectInstances;
  }));
  
  return instancesPerProject;
}

export function getObjectsPrimaryProperty(objectsIds, subjectName, projectId, intl) {

  if (platformActions.app.getPlatform() == 'web')
    return;

  let primaryProp;
  let propTypes = _.isFunction(getAppState) && getAppState().getNested(['propertiesTypes', 'projectProperties', projectId]);

  propTypes = (propTypes && propTypes.toJS) ? propTypes.toJS() : propTypes;

  _.forIn(_.get(propTypes, [subjectName]), prop => {
    if (prop.isPrimary)
      primaryProp = prop;
  });

  if (!primaryProp || !primaryProp.id)
    return;

  let objectsPrimaryProperty = {};

  let query = `projectId = "${projectId}" AND subjectName = "${subjectName}" AND propId = "${primaryProp.id}" ${andOrBuilder(objectsIds, "parentId")}`;
  let instances = realmInstance.propertyInstances.objects('propertyInstance1').filtered(query);

  _.forIn(instances.realmToObject(), inst => {
    let data = inst.data;
    try { data = JSON.parse(data); }
    catch (err) { }
    _.set(objectsPrimaryProperty, [inst.parentId], instanceDataToString(primaryProp, data, intl));
  });

  return objectsPrimaryProperty;

}
