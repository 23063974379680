import AwesomeDebouncePromise from 'awesome-debounce-promise';
import _ from 'lodash';
import { envParams } from '../configureMiddleware';
import { uploadNewPost } from '../posts/actions';
import { uploadPropertiesInstances } from '../propertiesInstances/actions';
import { uploadFailedChecklistItemInstance } from '../checklistItemsInstances/actions';
import { hideLoading, startLoading } from '../app/actions';
import systemMessages from '../app/systemMessages';
import { track } from '../lib/reporting/actions';
import { debugParams } from '../lib/utils/utils';

export const GET_LAST_UPDATES = 'GET_LAST_UPDATES';
export const ON_CHECK_APP_VERSION = 'ON_CHECK_APP_VERSION';
export const GET_SYNCED = 'GET_SYNCED';
export const SYNCED = 'SYNCED';
export const END_SYNC_LISTENER = 'END_SYNC_LISTENER';

let map = {}

function bouncerWrapper(key, callback) {
	let exists = map[key]
	if (exists) 
		return debouncedFunc(callback, key);
	else {
		map[key] = true;
		return callback()
	}
}

function debouncedFuncWrapper(callback, key) {
	return callback()
}

const debouncedFunc = AwesomeDebouncePromise(debouncedFuncWrapper, 500);


export function getLastUpdatesByViewer(viewer) {
  return ({ dispatch, firebaseDatabase }) => {
    const getPromise = async () => {
      const path = `lastUpdates/members/${viewer.id}`;
      // setListeners('v1/lastUpdates/members',{scope:'members',scopeId:viewer.id,viewer},function(snapshot){
      firebaseDatabase().ref(path).on('value', (snapshot) => {
				if (debugParams.disableGetLastUpdateTS) return;
        bouncerWrapper(path, () => dispatch({ type: GET_LAST_UPDATES, payload: { projects: snapshot.val() } }));
      });
    };

    return {
      type: GET_LAST_UPDATES,
      payload: getPromise()
    };
  };
}

export function getLastUpdatesForProjects(projectId) {	
	  return ({ dispatch, firebaseDatabase, }) => {
	  	const getPromise = async () => {
			var path = 'lastUpdates/projects';
			if (projectId)
				path += '/' + projectId;

			firebaseDatabase().ref(path).on('value', function(snapshot) {
				let projects = {};
				if (snapshot.key == 'projects')  {
					projects = snapshot.val();
				}
				else { 
					projects[snapshot.key] = snapshot.val();
				}

				bouncerWrapper(path, () => { 
					if (debugParams.disableGetLastUpdateTS) return;
					dispatch({ type: GET_LAST_UPDATES, payload: { projects } }); 
				});
			});
		}

    return {
      type: GET_LAST_UPDATES,
      payload: getPromise()
    };
  };
}

export function updateProjectLastUpdateTS(feature, projectId) {	
	return ({ firebase }) => {
		const getPromise = async () => {
		let updates = {};
		let lastUpdateTS = new Date().getTime();
		const featurePath = (feature || "").split('.');

		_.set(updates, ['lastUpdates', 'v2', 'revokes', 'projects', projectId, ...featurePath, 'lastUpdateTS'].join('/'), lastUpdateTS)
		firebase.update(updates);
	}

	return {
		type: GET_LAST_UPDATES,
		payload: getPromise()
	};
};
}

export function revokeUsersCache(feature, allUsers, usersIds, projectIds) {	
	return ({ firebase }) => {
		const getPromise = async () => {
		let updates = {};
		let lastUpdateTS = new Date().getTime();
		const featurePath = (feature || "").split('.');

		if (allUsers) {
			(projectIds || [])
				.forEach(projId =>
					 _.set(updates, ['lastUpdates', 'v2', 'revokes', 'projects', projId, ...featurePath, 'lastUpdateTS'].join('/'), lastUpdateTS)
				);
		} else {
			(projectIds || [])
				.forEach(projId =>
					(usersIds || []).forEach(userId =>
						_.set(updates, ['lastUpdates', 'v2', 'revokes', 'members', userId, 'projects', projId, ...featurePath, 'lastUpdateTS'].join('/'), lastUpdateTS)
					)
				);
		}
		firebase.update(updates);
	}

	return {
		type: GET_LAST_UPDATES,
		payload: getPromise()
	};
};
}

export function syncUsersLocalStorage(feature, usersIds, projectIds, shouldUpload) {	
	return ({ firebase }) => {
		const getPromise = async () => {
		let updates = {};
		let lastUpdateTS = new Date().getTime();
		(usersIds || []).forEach(userId => {
			(projectIds || []).forEach(projId => {
				updates['lastUpdates/sync/' + userId + '/' + projId + '/' + feature + '/lastUpdateTS'] = lastUpdateTS;
				updates['lastUpdates/sync/' + userId + '/' + projId + '/' + feature + '/onlyDiff'] = true;
				updates['lastUpdates/sync/' + userId + '/' + projId + '/' + feature + '/shouldUpload'] = shouldUpload ? true : false;
			})
		})
		firebase.update(updates);
	}

	return {
		type: GET_LAST_UPDATES,
		payload: getPromise()
	};
};
}

export function startRecordLogRocket(userId) {	
	return ({ firebase }) => {
		const getPromise = async () => {
		let updates = {};
		const lastUpdateTS = Date.now();
		const tenMins = 10 * 60 * 1000;
		updates['lastUpdates/sync/' + userId + '/global/logrocket/lastUpdateTS'] = lastUpdateTS;
		updates['lastUpdates/sync/' + userId + '/global/logrocket/activateUntilTS'] = lastUpdateTS + tenMins;
		updates['lastUpdates/sync/' + userId + '/global/logrocket/activatedBy'] = userId;
		firebase.update(updates);
	}

	return {
		type: GET_LAST_UPDATES,
		payload: getPromise()
	};
};
}



export function startSyncListener(viewerId) {
	return ({ firebaseDatabase, dispatch }) => {
		const getPromise = async () => {
			const path = `lastUpdates/sync/${viewerId}`;
			firebaseDatabase().ref(path).on('value', (snapshot) => {
				bouncerWrapper(path, () => dispatch({ type: GET_SYNCED, payload: { projects: snapshot.val() } }));
			});
		};

		return {
			type: GET_SYNCED,
			payload: getPromise()
		};
	};
}

export function endSyncListener(viewerId) {
	return ({ firebaseDatabase }) => {

		const getPromise = async () => {
			firebaseDatabase()
				.ref(`lastUpdates/sync/${viewerId}`)
				.off('value');
		};

		return {
			type: END_SYNC_LISTENER,
			payload: getPromise()
		};
	};

}


function getLocalObjectsForSync(realmInstance, objectType, projectId, isLocalOnly, locationId) {
	let query = `projectId = "${projectId}" `;
	if (isLocalOnly) query += ' AND isLocal = true';

	let instances, retryInstances;

	switch (objectType) {
		case 'posts':
			if (locationId) query += ` AND ( location.building.id = "${locationId}" OR location.floor.id = "${locationId}" OR location.unit.id = "${locationId}" )`;
			instances = realmInstance.posts.objects('post24').filtered(query);
			retryInstances = realmInstance.retry_posts.objects('post24').filtered(query);
			break;

		case 'checklistInstances':
			if (locationId) query += ` AND locationId = "${locationId}"`;
			instances = realmInstance.checklistItemsInstances.objects('checklistItemsInstance1').filtered(query);
			retryInstances = realmInstance.retry_checklistItemsInstances.objects('checklistItemsInstance1').filtered(query);
			break;

		case 'propertiesInstances':
			if (locationId) 
				query += ` AND ((parentId = "${locationId}" AND subjectName = "locationsInfo") OR (subjectName = "checklistItemsInfo"))`;

			instances = realmInstance.propertyInstances.objects('propertyInstance1').filtered(query);
			instances = instances.map(i => {
				try {
					let data = JSON.parse(i.data);
					return { ...i, data }
				}
				catch (err) {
					return i;
				}
			});
			break;
	}

	let localInstances = {}
	_.forIn(instances, instance => localInstances[instance.id] = instance.realmToObject());
	_.forIn(retryInstances, instance => localInstances[instance.id] = instance.realmToObject());

	return localInstances;
}


export function syncWithDB(objectType, viewer, projectId, syncParams) {
	return ({ realmInstance, firebase, platformActions, dispatch }) => {
		const actionType = `${SYNCED}_${_.toUpper(_.snakeCase(objectType))}`;
		
		const getPromise = async () => {
			if (platformActions.app.getPlatform() === 'web') {
				return { projectId, lastSynced: 0 };
			}

			if (!viewer || !viewer.id)
				throw ('missing viewer id');

			let loadingOpId = `sync_${Date.now}`;
			dispatch(startLoading({ title: systemMessages.syncing, overlay: true, operationId: loadingOpId }));

			const { lastUpdateTS: syncTS, onlyDiff = true, shouldUpload, log = true, locationId } = syncParams;

			let eventProps = { objectType, projectId, syncParams:Object.assign({}, syncParams), viewer: { displayName: viewer.displayName, id: viewer.id } };
			let firebaseLog = { metadata: { syncParams } };
			let allLocalInstances = getLocalObjectsForSync(realmInstance, objectType, projectId, false, locationId);
			let onlyIsLocalInstances = _.pickBy(allLocalInstances, i => i.isLocal);
			dispatch(track('syncWithDB - start checking', eventProps));

			// // faking locally stuck Object:
			// localInstances.someFakeObjectId = { ..._.last(_.values(localInstances)), id: "someFakeObjectId", isLocal: true };
			// //

			_.set(firebaseLog, ['metadata', 'stats', 'localInstances', 'total'], _.values(allLocalInstances).length);
			_.set(firebaseLog, ['metadata', 'stats', 'localInstances', 'isLocal', 'beforeSync'], _.values(onlyIsLocalInstances).length);


			if (onlyDiff) {
				// fetch remote instances
				let url = `${envParams.apiServer}/v1/${objectType}?projectId=${projectId}&fields=${JSON.stringify(['id', 'updatedTS'])}`;

				let remoteInstances = {};

				if (objectType == 'propertiesInstances') {
					let subjectsArray = realmInstance.propertyInstances.objects('propertyInstance1').filtered(`projectId = "${projectId}" DISTINCT(subjectName)`).map(x => x.subjectName);
					const propertiesSubjects = Boolean(locationId) ? ['locationsInfo', 'checklistItemsInfo'] : subjectsArray;

					await Promise.all(propertiesSubjects.map(async subjectName => {
						let currUrl = url + `&subjectName=${subjectName}`;
						if (subjectName == 'locationsInfo' && locationId)
							currUrl += `&locationId=${locationId}`;

						let subjectInstances = await platformActions.net.fetch(currUrl);
						subjectInstances = await subjectInstances.getJson();
						_.assign(remoteInstances, subjectInstances);
					}));
				} else {
					remoteInstances = await platformActions.net.fetch(url);
					remoteInstances = await remoteInstances.getJson();
				}

				_.set(firebaseLog, ['metadata', 'stats', 'remoteInstances', 'total'], _.values(remoteInstances).length);

				// filter only local that is different
				allLocalInstances = _.pickBy(allLocalInstances, currLocal => {
					let currRemote = _.get(remoteInstances, [currLocal.id]);
					return (!currRemote || currLocal.isLocal);
				});

				_.set(firebaseLog, ['metadata', 'stats', 'localInstances', 'diff', 'total'], _.values(allLocalInstances).length);
				_.set(firebaseLog, ['metadata', 'stats', 'localInstances', 'diff', 'isLocal'], _.values(allLocalInstances).filter(i => i.isLocal).length);

			}

			_.set(firebaseLog, ['instances'], allLocalInstances);

			let uploadSuccess = false;
			if (shouldUpload) { // upload local instqances
				try {
					switch (objectType) {
						case 'posts':
							await Promise.all(_.values(allLocalInstances).map(async post => await dispatch(uploadNewPost(viewer, projectId, post, true))));
							break;

						case 'checklistInstances':
							await Promise.all(_.values(allLocalInstances).map(async checklistInstance => await dispatch(uploadFailedChecklistItemInstance(checklistInstance))));
							break;

						case 'propertiesInstances':
							let groupedBySubjectName = _.groupBy(allLocalInstances, i => i.subjectName);
							await Promise.all(_.toPairs(groupedBySubjectName).map(async ([subjectName, subjectInstances]) => {
								await dispatch(uploadPropertiesInstances(projectId, _.values(subjectInstances), subjectName));
							}
							));
							break;
					}
					uploadSuccess = true;
					let localObjectsAfterUpload = getLocalObjectsForSync(realmInstance, objectType, projectId, true, locationId);
					_.set(firebaseLog, ['metadata', 'stats', 'localInstances', 'isLocal', 'afterSync', 'count'], _.values(localObjectsAfterUpload).length);
					_.set(firebaseLog, ['metadata', 'stats', 'localInstances', 'isLocal', 'afterSync', 'instances'], localObjectsAfterUpload);
				}
				catch (err) {
					uploadSuccess = false;
					_.set(firebaseLog, ['metadata', 'uploadError'], JSON.stringify(err));
				}
				finally {
					_.set(firebaseLog, ['metadata', 'actualSyncTS'], Date.now());
					_.set(firebaseLog, ['metadata', 'uploadSuccess'], uploadSuccess);
					eventProps.uploadSuccess = uploadSuccess;
				}
			}


			/// save that shit to a separate firebase object in /sync/{viewer.id}/{projectId}/{type} = { metadata, instances }
			if (log) {
				const deviceName = platformActions.app.getPlatform() == "web" ? 'desktop' : platformActions.app.getModel() + "_" + platformActions.app.getUniqueID();
				const logPath = `_internal/logs/instancesSync/${viewer.id}/${projectId}/${objectType}/${syncTS}/${deviceName}`;
				eventProps.logPath = logPath;
				firebase.update({ [logPath]: firebaseLog });
				dispatch(track('syncWithDB - sync finished', eventProps));
			}

			dispatch(hideLoading(loadingOpId));
			return { projectId, lastSynced: syncTS };
		};


		return {
			type: actionType,
			payload: getPromise()
		};
	};
}