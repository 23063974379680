import React from "react";
import { connect } from "react-redux";
import { compose, hoistStatics } from "recompose";
import { connectContext } from "react-connect-context";
import { ProjectContext } from "../../../common/projects/contexts";
import FormManager from "./FormsManager";
import { DEFAULT_SELECTED_SCOPE } from "../../../common/app/constants";
import { getDefaultScopeId } from "../Properties/funcs";
import { getMergerResult, upsertConfig } from "../../app/funcs";
import { startLoading, hideLoading, startToast } from '../../../common/app/actions';
import { updateLocalChecklists } from '../../../common/checklists/actions';
import { updateLocalChecklistItems } from '../../../common/checklistItems/actions';
import { updateLocalStages } from '../../../common/stages/actions';
import { updateLocalConfigurations } from '../../../common/configurations/actions';
import systemMessages from '../../../common/app/systemMessages';
import theme from "../../assets/css/theme";
import ScopeSelector from "../ProjectManager/ScopeSelector";
import Text from "../../components/CementoComponents/Text";
import UpButton from '../../assets/img/icons/sortArrow.png';
import Modal from "../../components/CementoComponents/Modal";
import { safeToJS } from "../../../common/permissions/funcs";

const UPSERT_CONFIGURATION_REQUEST_ID = 'upsertRequest';
const FETCH_MERGER_OPERATION_ID = 'fetchMerger';

class FormManagerWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.recalcHeader = this.recalcHeader.bind(this);
    this.openModal = this.openModal.bind(this);
    this.changeScopeHandler = this.changeScopeHandler.bind(this);
    this.upsertAllConfigsSubmitHandler = this.upsertAllConfigsSubmitHandler.bind(this);
    this.selectedFormChangeHandler = this.selectedFormChangeHandler.bind(this);

    this.state = {
      selectedScope: DEFAULT_SELECTED_SCOPE,
			selectedScopeId: props.selectedProjectId,
      isModalOpen: false,
      selectedForm: null
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    this.setComponentData(this.props, nextProps);
  }

  async UNSAFE_componentWillMount() {
    this.setComponentData({}, this.props);

    await this.fetchMergerResult(this.state.selectedScope, this.state.selectedScopeId);
  }

  async componentDidMount() {
    this.recalcHeader();
  }

  componentWillUnmount() {
  }

  async componentDidUpdate(prevProps, prevState) {
    const { loading } = this.props;
    const { selectedScope } = this.state;
    
    if ( prevState.selectedScope !== selectedScope ) this.recalcHeader();

    const { manage } = systemMessages;
    const loadingTitle = _.get(prevProps.loading, ["toast", "title", "id"], "NoTitle");
    
    if (loadingTitle === manage.saving.id && (!loading)) {
      await this.fetchMergerResult(this.state.selectedScope, this.state.selectedScopeId);

      this.setAllowEdit(true);
    }
  }

  setComponentData(props, nextProps) {
    let newStateChanges = {};

    if (Object.keys(newStateChanges).length) {
      this.setState(newStateChanges);
    }
  }

  getPageLang = () => {
    const { selectedProjectId, detailedProjects } = this.props;
    const defaultLang = "en";
    let project = detailedProjects.getNested([selectedProjectId]) || {};
    let projectToManage = project.toJS
      ? project.toJS()
      : Object.assign({}, project);
    let lang = projectToManage.getNested(["lang"], defaultLang) || defaultLang;

    return lang;
  };

  openModal = () => {
    if (_.isNull(this.state.selectedForm)) return;

    this.setState({isModalOpen: true});
  }

  recalcHeader() {
    const { setHeaderParams, rtl } = this.props;
    const { selectedScope, selectedScopeId, selectedForm } = this.state;

    if (setHeaderParams) {
      const headerComponent = (
        <div
            style={{
              flexGrow: 1,
              
              [rtl ? "marginLeft" : "marginRight"]: theme.verticalMargin,
              display: "flex",
            }}
          >
            <div>
              <ScopeSelector 
                selectedScope={selectedScope} 
                selectedScopeId={selectedScopeId} 
                submitHandler={this.changeScopeHandler} 
                shouldRedirectAfterSubmit 
                key={"scopeSelectorOpen"}
              />
            </div>

            <div 
              style={{
                "flex": 1,
                "display": "flex",
                "justifyContent": "flex-end",
                "alignItems": "center",
            }}>
              <div 
                onClick={this.openModal}
                style={{ 
                  display: 'flex', 
                  flexDirection: 'row', 
                  cursor: _.isNull(selectedForm) ? 'not-allowed': 'pointer', 
                  padding: "0px 5px 0px 5px", 
                  margin: "0px 5px 0px 5px", 
                  justifyContent: `center`}} 
              >
                <Text>Upsert</Text>
                <img style={{ padding: "0px 3px 0px 3px" }} src={UpButton}/>
              </div>
            </div>
					</div>
      );
      
      setHeaderParams({
				headerComponent: headerComponent,
				sideBarParams: { alwaysOpen: false },
			});
    }
  }

  changeScopeHandler = async (scopeType, scopeId) => {
    const {scopeType: currScopeType, scopeId: currScopeTypeId } = this.state;
    this.setState({
      selectedScope: scopeType,
      selectedScopeId: scopeId
    });

    await this.fetchMergerResult(scopeType, scopeId);
  }

  setMergerResult(mergerResultMap, scope, scopeId) {
		const { 
      hideLoading,
      updateLocalConfigurations,
      updateLocalChecklists,
      updateLocalStages,
      checklists,
      stages,
      configurations
    } = this.props;

    if (!_.isEqual(configurations, mergerResultMap.configurations) ||
        !_.isEqual(checklists, mergerResultMap.checklists) ||
        !_.isEqual(stages, mergerResultMap.stages)) {
      updateLocalConfigurations(scopeId, mergerResultMap.configurations, true);
      updateLocalChecklists(scopeId, mergerResultMap.checklists, true);
      updateLocalStages(scopeId, mergerResultMap.stages, true);
    }

		hideLoading(FETCH_MERGER_OPERATION_ID);
	}

  async fetchMergerResult(scopeType, scopeId) {
		const { startLoading } = this.props;
		startLoading({ title: systemMessages.loadingMessage, operationId: FETCH_MERGER_OPERATION_ID });

		let requiredScopeId = scopeId;

		const response = await getMergerResult(['configurations', 'stages', 'checklists'], scopeType, requiredScopeId, true);

		this.setMergerResult(response.mergerResult, scopeType, scopeId);
	}

  askUserIfHeSure = async () => {
    const { startToast } = this.props;

    return await new Promise(resolve => {
      startToast({
        overlay: true, 
        mandatory: true,
        title: "זהירות", 
        message: "האם הינך רוצה לדרוס את הגדרות המטרה?",
        actions: [
          { message: systemMessages.yes, onClick: () => resolve(true), color: 'success'},
          { message: systemMessages.no, onClick: () => resolve(false) }
        ]
      });
    });
  }

  upsertAllConfigsSubmitHandler = async (targetScope, targetScopeId) => {
		const shouldUpsert = await this.askUserIfHeSure();
	  
		if (!shouldUpsert) return;

		const { selectedScope: sourceScope, selectedScopeId: sourceScopeId, selectedForm } = this.state;

    const { hideLoading, startLoading } = this.props;
		startLoading({ title: systemMessages.loadingMessage, operationId: UPSERT_CONFIGURATION_REQUEST_ID });

    const sourceSubjectType = ["configurations"];
    const subjectNames = ["forms"];
		const response = await upsertConfig(targetScope, targetScopeId, sourceScope, sourceScopeId, sourceSubjectType, subjectNames, [{id: selectedForm.id}]);
    
		this.fetchMergerResult(sourceScope, sourceScopeId);

		hideLoading(UPSERT_CONFIGURATION_REQUEST_ID);
	};

  selectedFormChangeHandler = (newSelectedForm) => {
    this.setState({selectedForm: newSelectedForm}, () => {
      this.recalcHeader();
    });
  }

  render() {
    const { 
      configurations,
      checklists,
      stages
    } = this.props;

    const { 
      isModalOpen,
      selectedScopeId,
      selectedScope
     } = this.state;

    const lang = this.getPageLang();
    const selectedConfigurations = _.get(safeToJS(configurations), [selectedScopeId], {});

    return (
      <>
        <Modal open={isModalOpen} onClose={() => this.setState({ isModalOpen: false })}>
            <div style={{padding: 20}}>
              <ScopeSelector buttonText={'Upsert'} submitHandler={this.upsertAllConfigsSubmitHandler}/>
            </div>
        </Modal>
        <FormManager
          configurations={selectedConfigurations}
          checklists={checklists}
          stages={stages && stages.toJS ? stages.toJS() : stages}
          lang={lang}
          selectedScope={selectedScope}
          selectedScopeId={selectedScopeId}
          selectedFormChangeHandler={this.selectedFormChangeHandler}
          />
      </>
    );
  }
}

const enhance = compose(
  connectContext(ProjectContext.Consumer),
  connect(
    (state) => ({
      stages: state.stages.map,
			checklists: state.checklists.map,
      configurations: state.configurations.map
    }),
    {
      updateLocalStages,
      updateLocalChecklists,
      updateLocalConfigurations,

      startLoading,
      hideLoading,
      startToast,
    }
  )
  );

export default enhance(FormManagerWrapper);
