import { schemasInfo, uploadObjectsDispatcher } from '../lib/offline-mode/config';
import { fetchByTS, debugParams, replaceMaxUpdateTSIfNeeded } from '../lib/utils/utils';
import { platformActions } from "../platformActions";
import _ from 'lodash';

export const CREATE_EQUIPMENT 		  		= 'CREATE_EQUIPMENT';
export const GET_EQUIPMENT   		  			= 'GET_EQUIPMENT';
export const END_EQUIPMENT_LISTENER   	= 'END_EQUIPMENT_LISTENER';
export const UPSERT_EQUIPMENT    				= 'UPSERT_EQUIPMENT';
export const DELETE_EQUIPMENT         	= 'DELETE_EQUIPMENT'
export const EQUIPMENT_DONE_LOADING			= 'EQUIPMENT_DONE_LOADING';
export const CLEAN_CACHED_EQUIPMENT    	= 'CLEAN_CACHED_EQUIPMENT';
export const GET_NEW_ID    							= 'GET_NEW_ID';
export const SAVE_EQUIPMENT             = 'SAVE_EQUIPMENT';

export function getEquipment(viewer, projectId, cleanAll, uid) {
	return ({ dispatch, realmInstance, lokiInstance, platformActions }) => {

		if (cleanAll)
			setEquipmentValues({}, 0, projectId, realmInstance, lokiInstance, platformActions, cleanAll);

		const saveFunc = (_data, _lastUpdate, checkClean) => {
			if (debugParams.disableFetchByTSSave)
				return;
			return setEquipmentValues(_data, _lastUpdate, projectId, realmInstance, lokiInstance, platformActions, checkClean && cleanAll, dispatch);
		};

		let fetchParams = {
			projectId,
			viewer,
			resource: {
				name: 'equipment',
				doneLoading: EQUIPMENT_DONE_LOADING,
				firebasePath: 'equipment/projects',
			},
			saveFunc,
			getLastUpdateTS: () => getLastUpdateTS(realmInstance, lokiInstance, projectId)
		};
		fetchByTS(fetchParams);


		return {
			type: GET_EQUIPMENT,
			payload: { projectId }
		};
	};
}

function getLastUpdateTS(realmInstance, lokiInstance, scopeId) {

  var lastUpdateTS = 0;

	if (platformActions.app.getPlatform() == "web") {
		let lastUpdateTSObj = {};
    let lastUpdateTSObjArr = lokiInstance.getCollection('equipment').chain().find({projectId: scopeId, isLocal: { '$ne' : true }}).simplesort("updatedTS", true).limit(1).data();
    if (lastUpdateTSObjArr.length) lastUpdateTSObj = lastUpdateTSObjArr[0];
    lastUpdateTS = lastUpdateTSObj.updatedTS;
	}
	else {
		lastUpdateTS = realmInstance.equipment.objects('equipment1').filtered('projectId = "' + scopeId + '"').max('updatedTS');
	}

  return lastUpdateTS || 0;
}

function setEquipmentValues(equipment, lastUpdateTS, projectId, realmInstance, lokiInstance, platformActions, cleanAll) {
  if (platformActions.app.getPlatform() == "web")
  	saveToLoki(equipment, undefined, projectId, lokiInstance, undefined, cleanAll);
	else
		saveToRealm(equipment, undefined, projectId, realmInstance, undefined, cleanAll);
}

export async function removeEquipmentFromLoki(lokiInstance) {
	await lokiInstance.getCollection('equipment').cementoFullDelete();
}

function saveToRealm(equipment, lastUpdateTS, projectId, realmInstance, ignoreTimestamp, cleanAll) {
	if (!cleanAll && Object.keys(equipment || {}).length == 0) return;

	equipment = Object.values(equipment || {}).sort((equipmentA, equipmentB) => (equipmentA.updatedTS || 0) > (equipmentB.updatedTS || 0) ? -1 : 1);
	let currBatchMaxLastUpdateTS = _.get(equipment, [0, 'updatedTS'], 0);

	let realm = realmInstance.equipment;
	realm.write(() => {
		if (cleanAll) {
			let allEquipment = realm.objects('equipment1').filtered(`projectId = "${projectId}"`);
			realm.delete(allEquipment);
		}

		(equipment).forEach(curr => {
			if (curr && curr.id) {
				if (!curr.isDeleted)
					realm.create('equipment1', { ...curr.realmToObject(), projectId }, true);
				else {
					let allCurrentEquipment = realm.objects('equipment1').filtered(`projectId = "${projectId}" AND id = "${curr.id}"`);
					realm.delete(allCurrentEquipment);
				}
			}
			else
				console.warn('equipment missing ID'); // TODO: Send to bugsnag
		});
		replaceMaxUpdateTSIfNeeded(currBatchMaxLastUpdateTS, realm, 'equipment1', `projectId = "${projectId}"`);
	});
}

function saveToLoki(equipment = {}, lastUpdateTS, projectId, lokiInstance, ignoreTimestamp, cleanAll) {
	if (!cleanAll && Object.keys(equipment || {}).length == 0) return;
	let allEquipment = [];
	let deletedIds = [];

	equipment = Object.values(equipment).sort((equipmentA, equipmentB) => equipmentA.updatedTS > equipmentB.updatedTS ? -1 : 1);
	
	(equipment).forEach(curr => {
		curr.isDeleted ? 
			deletedIds.push(curr.id) : 
			allEquipment.push({...curr.realmToObject(), projectId })
	});
	if (cleanAll) {
		lokiInstance.getCollection('equipment').cementoDelete({ projectId });
	}
	if (deletedIds.length > 0) lokiInstance.getCollection('equipment').cementoDelete({ projectId, id: { '$in' : deletedIds }});
	lokiInstance.getCollection('equipment').cementoUpsert(allEquipment);
}

export function removeEquipmentFromRealm(realmInstance) {
	let realm = realmInstance.equipment;
	let allEquipment = realm.objects('equipment1');
	realm.write(() => {
		realm.delete(allEquipment);
	});
}

export function endEquipmentListener(projectId) {	
  return ({ firebaseDatabase }) => {
	  firebaseDatabase().ref('equipment/projects/' + projectId).off('value');
	  firebaseDatabase().ref('equipment/projects/' + projectId).off('child_added');
	  firebaseDatabase().ref('equipment/projects/' + projectId).off('child_changed');

    return {
      type: END_EQUIPMENT_LISTENER,
      payload: { projectId }
    };
  };
}

export function getEquipmentNewId() {	
  return ({ firebaseDatabase }) => {
		var push = firebaseDatabase().ref('equipment/objects/').push(); // Note! - althow we are working in projectal scope - we are taking the ID from shared path! - BECOUSE THERES A LISTENER FUNCTION THAT COPY THEM TO THE GLOBAL PATH

    return {
      type: GET_NEW_ID,
      payload: { key:push.key }
    };
  };
}


export function upsertEquipment(inEquipment, projectId) {
	return ({ firebaseDatabase, removeEmpty, dispatch }) => {

		if (!inEquipment) return;

		let equipmentToSave = { ...inEquipment.realmToObject(), projectId };
		if (!equipmentToSave.id) {
			const id = firebaseDatabase().ref('equipment/objects/').push().key; // Note! - althow we are working in projectal scope - we are taking the ID from shared path! - BECOUSE THERES A LISTENER FUNCTION THAT COPY THEM TO THE GLOBAL PATH
			equipmentToSave.id = id;
		}

		equipmentToSave = removeEmpty(equipmentToSave, 'upsertEquipment');

		dispatch(saveEquipment(projectId, [equipmentToSave]));

		return {
			type: UPSERT_EQUIPMENT,
			payload: { equipment: equipmentToSave }
		};
	};
}

export function deleteEquipment(equipmentId, projectId) {
	return ({ dispatch }) => {
		const equipmentToSave = { id: equipmentId, isDeleted: true };
		
		dispatch(saveEquipment(projectId, [equipmentToSave]));

		return {
			type: DELETE_EQUIPMENT,
			payload: { equipment: equipmentToSave }
		};
	};
}

const saveEquipment = (projectId, newEquipment, originalEquipment = null) => {
	if (projectId && Object.values(newEquipment || {}).length) {
		newEquipment = Object.values(newEquipment).filter(Boolean).map(equipment => ({ ...equipment, projectId }));
		
		uploadObjectsDispatcher({
			projectId,
			objectsToSave: newEquipment,
			originalObjects: originalEquipment,
			schemaInfo: schemasInfo.equipment,
		});
	}

	return {
		type: SAVE_EQUIPMENT,
		payload: { newEquipment, originalEquipment }
	}
}