import ExtraError, { errorCodes } from '../lib/errors/extraError';
import * as IssueStates from '../issues/issueStates';
import * as cliStatus from '../checklistItemsInstances/clItemInstanceStatus';
import { getFilteredMap, getActionPermissions, safeToJS } from '../permissions/funcs';
import { uploadImage } from '../images/actions';
import { upsertForm } from '../forms/actions';
import { exportFormPDF } from '../pdf/actions';
import { startToast, hideLoading, startAlert } from '../app/actions';
import reportsMessages from '../../common/reports/reportsMessages.js';
import { getAppState } from '../configureMiddleware';
import { platformActions } from "../platformActions";

import _ from 'lodash';
import { fetchByTS, debugParams, replaceMaxUpdateTSIfNeeded } from '../lib/utils/utils';

import notificationsMessages from '../notifications/notificationsMessages';
import { updateRetryRealm } from '../../common/lib/realm/funcs.js';
import { prepareRealmForFirebase } from './funcs';
import { encodeBase64, onError } from '../app/funcs';
import { ALL_BUILDINGS_ID } from '../app/constants';

export const GET_POSTS_BY_FILTER 				= 'GET_POSTS_BY_FILTER';
export const CREATE_NEW_POST 		 				= 'CREATE_NEW_POST';
export const POST_UPLOADED 		 				  = 'POST_UPLOADED';
export const UPLOAD_NEW_POST 			   		= 'UPLOAD_NEW_POST';
export const UPLOAD_NEW_POST_STARTED 		= 'UPLOAD_NEW_POST_STARTED';
export const GET_POSTS   			 	 				= 'GET_POSTS';
export const END_POSTS_LISTENER  				= 'END_POSTS_LISTENER';
export const UPDATE_POST_IMAGE_STARTED 	= 'UPDATE_POST_IMAGE_STARTED';
export const UPDATE_ISSUE_STATE 				= 'UPDATE_ISSUE_STATE';
export const DELETE_POST 								= 'DELETE_POST';
export const UPDATE_ISSUE_ASSIGN_TO 		= 'UPDATE_ISSUE_ASSIGN_TO';
export const CLEAN_CACHED_POSTS					= 'CLEAN_CACHED_POSTS'
export const GET_NEW_POST_ID 						= 'GET_NEW_POST_ID';
export const UPDATE_POST_VIEW_TYPE 			= 'UPDATE_POST_VIEW_TYPE';
export const EXPORT_POST_AS_PDF 				= 'EXPORT_POST_AS_PDF';
export const POSTS_DONE_LOADING 				= 'POSTS_DONE_LOADING';
export const RETRY_UPLOAD_LOCAL_POSTS 	= 'RETRY_UPLOAD_LOCAL_POSTS';
export const LOAD_LOCAL_POSTS 					= 'LOAD_LOCAL_POSTS';
export const CHECK_IF_LAST_STATUS				= 'CHECK_IF_LAST_STATUS';

export function uploadNewPost(viewer, projectId, inPost, isUploadRetry, callBack) {
	return ({ firebase, removeEmpty, sendNotification, dispatch, realmInstance, platformActions }) => {
		const getPromise = async () => {
			let post;
			let didReleaseIsUploading = false;
			try {
				post = prepareRealmForFirebase(inPost);
				if ((platformActions.app.getPlatform() != "web") && !isUploadRetry)
					saveToRealm([{ ...post, isLocal: true, isUploading: true }], null, 0, projectId, realmInstance, true, null, platformActions, undefined, '2');
				
				if (!post.createdAt)
					post.createdAt = post.editedAt || Date.now(); // TODO: quick fix for https://cemento.atlassian.net/browse/CEM-4245 => need to find where it happens and fix for real

				if (!post.owner)
					throw new Error('Cannot update post with no owner');
        if (post.images && Object.keys(post.images).length > 0) {
          await Promise.all(Object.values(post.images).map(async (currImage) => {
            if (currImage.uri && !currImage.uri.startsWith('http') && currImage.id) {
              let imageId = currImage.id;
              let imageRet;
              if (!post.images[imageId]) // For a case where while we were offline we remove the file
								post.images[imageId] = currImage;
              try {
								if (currImage && currImage.uri)
                	imageRet = await uploadImage({ data: currImage.uri, extension: 'jpeg' }, projectId + "_" + post.id + "_" + currImage.id, "posts", null, imageId);

                if (imageRet && imageRet.uri && imageRet.uri.startsWith('http')) {
									post.images[imageId].uri = imageRet.uri;
									delete post.images[imageId]['uploading'];
								}
								else if (!currImage.uri || (currImage.uri.startsWith && currImage.uri.startsWith('file:/') && !(await platformActions.fs.exists(currImage.uri.replace('file:/', '')))))
									throw new ExtraError('Post file is missing', null, null, errorCodes.MISSING_FILE);

							}
							catch (err) {
								let errorMessage = 'error uploading image';
								if (_.get(err, ['errorCode']) == errorCodes.MISSING_FILE) {
									errorMessage = 'error uploading image - post with missing file';
									delete post.images[imageId];
								}
								platformActions.sentry.notify(errorMessage, { isUploadRetry, imageId, projectId, uri: currImage.uri, post, viewerId: _.get(viewer,['id'])});
							}
            }
          }));

          // If some images are still on uploading mode - dont' upload
          let stillUploading = Object.values(post.images).filter(image => image.uploading || (image.uri && !image.uri.startsWith('http')));

          if (stillUploading.length > 0) {
            //throw new ExtraError('image uploading error, some images are still uploading', { stillUploading: stillUploading.length, post });
            return { post, projectId, newIssue: true, isUploadRetry };
          } else
            Object.values(post.images).forEach(image => {
              if (post.images[image.id])
                delete post.images[image.id]['data'];
            });
        }
				if (post.attachments && Object.keys(post.attachments.length > 0))
				Object.values(post.attachments).forEach(file => {
					delete file.data;
					delete file.isLocal;
					delete file.uploading;
				});

				// if ((platformActions.app.getPlatform() != "web") && (Object.keys(post.attachments || {}).length || Object.keys(post.images || {}).length)) {
				// 	saveToRealm([{ ...post, isUploading: true }], null, 0, projectId, realmInstance, true, null, platformActions, undefined, '3'); // Saves the same post with the new images
				// }

				var currTime = new Date().getTime();
				post.updatedTS = post.updatedTS || currTime;
				// Reduce assignTo user metadata
				if (post.assignTo)
					post.assignTo = removeEmpty({ id: post.assignTo.id }, 'uploadNewPost_post.assignTo.id');
				
				if (_.has(post, 'severity') && post.severity == 0)
					delete post['severity'];
				
				post = _.pick(post,
					[
						'id',
						'title',
						'assignTo',
						'createdAt',
						'editedAt',
						//'updatedTS',
						'dueDate',
						'images',
						'attachments',
						'isIssue',
						'issueOpener',
						'issueState',
						'status',
						'location',
						'owner',
						'taggedCompanies',
						'trade',
						'isReported',
						'subCategory',
						'requiredAction',
						'fine',
						'severity',
						'comments',
						'commentsCounter',
						'isDeleted',
						'checklistItemInstance',
						'stateUpdatedTS',
						'reportId',
						'refs'
					]);
					
			
				
				let updates = {};
					if (projectId && post.id && post.id != 'undefined') {
					// If this is for prev daily report - then add it
					if (post.reportId) {
						updates['reports/full/' + projectId + '/' + post.reportId + '/posts/' + post.id] = removeEmpty(post,'uploadNewPost_before_db_update1');
						delete post.reportId;
					}
						updates['posts/' + projectId + '/' + post.id] = removeEmpty(post, 'uploadNewPost_before_db_update2');
				}

					firebase.update(updates).then(() => { 
						if (platformActions.app.getPlatform() != "web"){
							saveToRealm([{ ...post, isUploading: false },], null, null, projectId, realmInstance, true, null, platformActions); // TODO: This save is taking a lot of time, since it is happen very soon after the previous one. 
							didReleaseIsUploading = true
						}
						if (post.isIssue) {
							let slimViewer = removeEmpty({ id: viewer.id, displayName: viewer.displayName }, 'uploadNewPost_slimViewer');
							sendNotification('newIssue', { projectId, postId: post.id, updater: slimViewer });
						}
	
						dispatch({ type: POST_UPLOADED, payload: { projectId, post }});
						if (callBack) callBack(); 
					});

				let retValue = { post, projectId, newIssue: true, isUploadRetry };
				return retValue;

			}
			catch (error) {
				throw error;
			}
			finally {
				if (!didReleaseIsUploading && platformActions.app.getPlatform() != "web")
					saveToRealm([{ ...post, isUploading: false, isLocal: true },], null, null, projectId, realmInstance, true, null, platformActions);
			}
		};
		
		return {
			type: UPLOAD_NEW_POST,
			payload: getPromise()
		};
	};
}

export function getPosts(viewer, projectId, cleanAll) {
	return ({ dispatch, realmInstance, lokiInstance, platformActions }) => {


		// if (cleanAll)
		// 	savePosts(viewer, projectId, [], 0, dispatch, realmInstance, lokiInstance, true, platformActions, undefined, 'getPosts');

		// const saveFunc = (_data, _lastUpdate, a, b, source) => {
		// 	if (debugParams.disableFetchByTSSave)
		// 		return;
		// 	return savePosts(viewer, projectId, _data, _lastUpdate, dispatch, realmInstance, lokiInstance, false, platformActions, undefined, 'getPosts_saveFunc');
		// };
		// let fetchParams = {
		// 	projectId,
		// 	viewer,
		// 	resource: {
		// 		name: 'posts',
		// 		doneLoading: POSTS_DONE_LOADING,
		// 		firebasePath: 'posts'
		// 	},
		// 	saveFunc,
		// 	getLastUpdateTS: () => getLastUpdateTS(realmInstance, lokiInstance, projectId)
		// };
		// fetchByTS(fetchParams);


		return {
			type: POSTS_DONE_LOADING,
			payload: { projectId, scopeId: projectId}
		};
	};
}

function getLastUpdateTS(realmInstance, lokiInstance, scopeId) {

  var lastUpdateTS = 0;

	if (platformActions.app.getPlatform() == "web") {
		let lastUpdateTSObj = {};
		let lastUpdateTSObjArr = lokiInstance.getCollection('posts').chain().find({ projectId: scopeId }).simplesort("updatedTS", true).limit(1).data();
    if (lastUpdateTSObjArr.length) lastUpdateTSObj = lastUpdateTSObjArr[0];
    lastUpdateTS = lastUpdateTSObj.updatedTS;
	}
	else {
		lastUpdateTS = realmInstance.posts.objects('post24').filtered(`projectId = "${scopeId}"`).max('updatedTS');
    lastUpdateTS = (Boolean(lastUpdateTS && lastUpdateTS.getTime) ? lastUpdateTS.getTime() : 0);
	}

  return lastUpdateTS || 0;
}

function savePosts(viewer, projectId, rawPosts, lastUpdateTS, dispatch, realmInstance, lokiInstance, cleanAll, platformActions, forceLocalSave, source) {
	if (!cleanAll && Object.keys(rawPosts || {}).length == 0)
		return;

	let deletedPosts = {};
	let posts = {};

	(rawPosts || {}).loopEach((id, curr) => {
		curr.isDeleted ? (deletedPosts[id] = curr) : (posts[id] = curr);
	});
	
	let filteredPosts = getFilteredMap(viewer, projectId, "posts", "read", posts, 'post');
	filteredPosts.unpermitted.loopEach((id, curr) => deletedPosts[id] = curr);
	if (platformActions.app.getPlatform() == "web")
		saveToLoki(filteredPosts.permitted, deletedPosts, undefined, projectId, lokiInstance, null, null, cleanAll);
	else
		saveToRealm(Object.values(filteredPosts.permitted), Object.values(deletedPosts), undefined, projectId, realmInstance, null, cleanAll, platformActions, forceLocalSave, '1');
}

export function endPostsListener(projectId) {	
  return ({ firebaseDatabase }) => {
	  firebaseDatabase().ref('posts/' + projectId).off('value');
    return {
      type: END_POSTS_LISTENER,
      payload: { projectId }
    };
  };
}

export function getNewPostId(projectId) {
	return ({ firebaseDatabase }) => {
		var push = firebaseDatabase().ref('posts/' + projectId).push();
		var payload = push.key;
		
		return {
			type: GET_NEW_POST_ID,
			payload: payload
		};
	};	
}


// export function loadAllLocalPostToState(projectId) {
// 	return ({ getState, platformActions, realmInstance }) => {

// 		var viewer = getState().users.viewer;
// 		if (platformActions.app.getPlatform() != "web") {
// 			var localPosts = [];
// 			let realmRetry = realmInstance.retry_posts;
// 			if (viewer)
// 				localPosts = realmRetry.objects('post24').filtered('isLocal = true AND projectId = "' + projectId + '" AND owner.id = "' + viewer.id + '"');
// 		}
// 		return {
// 			type: LOAD_LOCAL_POSTS,
// 			payload: {posts:localPosts, counter: localPosts.length, projectId}
// 		};
// 	};	
// }

export function getAllFailedToUploadPosts(locationId) {
	return ({ getState, platformActions, realmInstance, lokiInstance }) => {
		const getPromise = async () => {
			try {
				var viewer = getState().users.viewer;
				if (platformActions.app.getPlatform() == "web") {
					if (viewer) {
						localPosts = lokiInstance.getCollection('posts').cementoFind({'isLocal': true, 'owner.id': viewer.id });
						return ({localPosts: localPosts, counter: localPosts.length});
					}
				} else {
					var localPosts = [];
					let realmRetry = realmInstance.retry_posts;
					if (viewer) {
						realmRetry.write(() => {
							let uploadedLocalPosts = realmRetry.objects('post24').filtered('isLocal = false');
							realmRetry.delete(uploadedLocalPosts);
						});
						
						let query = `isLocal = true AND isUploading != true AND owner.id = "${viewer.id}"`;
						if (locationId) query += ` AND ( location.building.id = "${locationId}" OR location.floor.id = "${locationId}" OR location.unit.id = "${locationId}" )`;

						localPosts = realmRetry.objects('post24').filtered(query);
						return ({localPosts, counter: localPosts.length});
					}
				}
				
			} catch (error) {
				platformActions.sentry.notify('getAllFailedToUploadPosts error', {error});
				throw error;
			}
		}
		return {
			type: RETRY_UPLOAD_LOCAL_POSTS,
			payload: getPromise()
		};
	};	
}

export function createLocalPost(viewer, projectId, inPost) {
	return ({ getState }) => {
		const getPromise = async () => {
			try {
				var post = {...(inPost || {}).realmToObject()};
				var currTime = new Date().getTime();
				if (!post.createdAt) {
					post.createdAt = currTime;
					post.owner = {id: viewer.id};
				}

				if (Object.keys(post.images || {}).length)
					await Promise.all(Object.values(post.images).map(async postImage => {
						if (!postImage.uri || !postImage.uri.startsWith || postImage.uri.startsWith('http') || postImage.uri.indexOf(';base64,') !== -1)
							return;
						
						const base64Str = await encodeBase64(postImage.uri, 'image/jpeg');
						if (base64Str)
							postImage.uri = base64Str;
					}));
				
				post.updatedTS = currTime;
				
				// Reduce the tagged users info values
				if (post.taggedCompanies) {
					var taggedCompanies = {};
		      Object.values(post.taggedCompanies).forEach((comp) => {
						taggedCompanies[comp.id] = { id:comp.id };
						if (comp.explicitMembersSelection) {
							taggedCompanies[comp.id].explicitMembers = {};
							(comp.explicitMembers || {}).loopEach((i, member) => {
								taggedCompanies[comp.id].explicitMembers[member.id] = { id:member.id };
							})
						}
					});

					post.taggedCompanies = taggedCompanies;
		    }

				return { post, projectId };
			} catch (error) {
				throw error;
			}
		};

		return {
			type: CREATE_NEW_POST,
			payload: getPromise()
		};
	};
}
   
export function deletePost(post, projectId) {
	return ({ firebase }) => {
		const getPromise = async () => {
			try {
				if (!projectId || !post || !post.id || post.id == 'undefined')
					throw new Error('deletePost - missing postId');

				var currTime = new Date().getTime();
				var updates = {};
				updates['posts/' + projectId + '/' + post.id + '/isDeleted'] = true;
				updates['posts/' + projectId + '/' + post.id + '/updatedTS'] = currTime;
				firebase.update(updates)

				return { post, projectId };
			}
			catch (error) {
				console.log('deletePost error:' + error);
				throw error;
			}
		};

		return {
			type: DELETE_POST,
			payload: getPromise()
		};
	};
}
export function updateIssueState(viewer, inPost, projectId, newState) {
	return ({ firebase, removeEmpty, dispatch, realmInstance, lokiInstance, platformActions }) => {
		const getPromise = async () => {
			try {
				if (!projectId || !inPost || !inPost.id || inPost.id == 'undefined')
					throw new Error('updateIssueState - missing postId');

				let post = prepareRealmForFirebase(inPost);

				post.issueState = newState;
				post.updatedTS = null;
				post.editedAt = new Date().getTime();

				savePosts(viewer, projectId, [{...post, isLocal:true}], 0, dispatch, realmInstance, lokiInstance, false, platformActions, true, 'updateIssueState');
				
				let updates = {};
				updates['posts/' + projectId + '/' + post.id + '/assignTo'] = removeEmpty({ id: post.assignTo.id }, 'updateIssueState_assignTo');
				updates['posts/' + projectId + '/' + post.id + '/issueState'] = post.issueState;
				updates['posts/' + projectId + '/' + post.id + '/updatedTS'] = post.updatedTS;
				updates['posts/' + projectId + '/' + post.id + '/editedAt'] = post.editedAt;
				updates['posts/' + projectId + '/' + post.id + '/id'] = post.id;

				firebase.update(updates);

				//sendNotification('updateIssueState', { projectId, postId: post.id, updater: removeEmpty({id: viewer.id, displayName: viewer.displayName})});

				return { post, projectId, newState };

			}
			catch (error) {
				console.log('Notification error:' + error);
				throw error;
			}
		};

		return {
			type: UPDATE_ISSUE_STATE,
			payload: getPromise()
		};
	};
}

export function areAllOtherConnectedPosts_withWantedStatus(instanceId, currPostId, projectId, wantedState, viewer) {
	return ({ lokiInstance, realmInstance, platformActions, getState }) => {
		let relevantPosts = [];
		let allOthersWithWantedState = true;
		let myDefaultActionStatus = cliStatus.CLI_STATUS_RESOLVED;
		let connectedItemId = null;

		if (platformActions.app.getPlatform() == "web") {
			relevantPosts = lokiInstance.getCollection('posts').cementoFind({ 'projectId' : projectId, 'checklistItemInstance.id': instanceId }) || [];
			let instances = safeToJS(getState().getNested(['checklistItemsInstances', 'map', projectId]) || {});
			let instance = null;

			for (const locationsMap of Object.values(instances || {})) {
				for (const instancesMap of Object.values(locationsMap || {})) {
					for (const currInstance of Object.values(instancesMap || {})) {
						instance = instanceId == currInstance.id ? currInstance : null; 
						if (instance)
							break;
					}

					if (instance)
						break;
				}

				if (instance)
						break;
			}
			connectedItemId = instance ? instance.checklistItemId : null;
		}
		else {
			let instance = realmInstance.checklistItemsInstances.objects('checklistItemsInstance1').filtered('projectId = "' + projectId + '" AND id = "' + instanceId + '"');
			relevantPosts = realmInstance.posts.objects('post24').filtered('projectId = "' + projectId + '" AND checklistItemInstance.id = "' + instanceId + '"')
			relevantPosts = relevantPosts ? Array.from(relevantPosts) : [];
			connectedItemId = instance && instance[0] ? instance[0].checklistItemId : null;
		}
		
		if (connectedItemId) {
			let connectedItem = getState().getNested(['checklistItems', 'map', projectId, connectedItemId]);
			if (connectedItem) {
				if (getActionPermissions(viewer, projectId, "checklistItems", "confirm2", connectedItem)) myDefaultActionStatus = cliStatus.CLI_STATUS_CONFIRMED_2;
				else if (getActionPermissions(viewer, projectId, "checklistItems", "confirm", connectedItem)) myDefaultActionStatus = cliStatus.CLI_STATUS_CONFIRMED;
				else if (getActionPermissions(viewer, projectId, "checklistItems", "resolve", connectedItem)) myDefaultActionStatus = cliStatus.CLI_STATUS_RESOLVED;
			}
		}

		relevantPosts.forEach(p => {
			if (p.issueState && p.issueState != wantedState && p.id != currPostId)
				allOthersWithWantedState = false;
		})

		return {
			type: CHECK_IF_LAST_STATUS,
			payload: { allOthersWithWantedState, myDefaultActionStatus }
		};
	};	
}

export function updateIssueAssignTo(viewer, inPost, projectId, assignTo) {
	return ({ firebase, removeEmpty, dispatch, realmInstance, lokiInstance, platformActions }) => {
		const getPromise = async () => {
			try {

				if (!projectId || !inPost.id || inPost.id == 'undefined')
					throw new Error('updateIssueAssignTo - missing postId', { inPost });

				let post = prepareRealmForFirebase(inPost);
				
				if (!post.id || post.id == 'undefined') // added here because posts with 'undefined' keys was found in DB
					throw new Error('updateIssueAssignTo - missing postId', { post });

				post.assignTo = assignTo;
				post.updatedTS = null;
				post.editedAt = new Date().getTime();
				
				let updates = {};
				updates[`posts/${projectId}/${post.id}/assignTo`] = removeEmpty({ id: assignTo.id }, 'updateIssueAssignTo');
				updates[`posts/${projectId}/${post.id}/updatedTS`] = null;
				updates[`posts/${projectId}/${post.id}/editedAt`] = post.editedAt;

				let assigneeCompanyId = _.get(assignTo, ['companyId']);
				if (assigneeCompanyId && !_.get(post, ['taggedCompanies', assigneeCompanyId])) {
					updates[`posts/${projectId}/${post.id}/taggedCompanies/${assigneeCompanyId}'/id`] = assigneeCompanyId;
					_.set(post, ['taggedCompanies', assigneeCompanyId, 'id'], assigneeCompanyId);
				}

				savePosts(viewer, projectId, [{...post, isLocal:true}], 0, dispatch, realmInstance, lokiInstance, false, platformActions, true, 'updateIssueAssignTo');

				dispatch(startToast({title:notificationsMessages.forwardMessage, values:{name: assignTo.displayName}}));
				
				firebase.update(updates)

				return { post, projectId };

			} catch (error) {
				console.log('Notification error:' + error);
				throw error;
			}
		};

		return {
			type: UPDATE_ISSUE_ASSIGN_TO,
			payload: getPromise()
		};
	};
}


export function exportPostsPDF(posts, projectId, viewer) {
	return ({ dispatch, getState }) => {
		const getPromise = async () => {
			let success = false;
			try {
				if (!(getAppState && getAppState() && getAppState().getNested(['app', 'isConnected'], false))) {
					onError({
						errorMessage: 'Failed to export posts pdf due to no signal',
						methodMetaData: {
							name: 'exportPostsPDF',
							args: [posts, projectId, viewer],
						},
						alertParams: {
							title: reportsMessages.exportErrors.title,
							message: reportsMessages.exportErrors.content
						},
					});
					dispatch(hideLoading());
				}

				let project = getState().projects.map.getNested([projectId]);
				let localDate = new Date().getTime();
				let updatedReport = { 
					createdAt: localDate,
					formTemplateId: "-postTestForm",
					type: "general"
				};
				
				posts.loopEach((key, currPost) => updatedReport = updatedReport.setNested(['posts', currPost.isIssue ? 'issues' : 'records', currPost.id], true));

				let form = (await dispatch(await upsertForm(projectId, viewer, updatedReport))).form;
        let pdf = await dispatch(await exportFormPDF({ project, formId: form.id, formType: 'general', isListenerMode: true }));
				
				success = true;			
				return pdf;
			} catch (error) {
       	console.log(error)
       	throw new ExtraError('updateReport error', null, error) ;
			}
			finally {
				if (posts.length == 1) 
					dispatch({ type: EXPORT_POST_AS_PDF, payload: { success, multi:false, projectId, post: posts[0] }});
       	else 
					dispatch({ type: EXPORT_POST_AS_PDF, payload: { success, multi:true, projectId, posts }});
			}
		}
		return {
			type: EXPORT_POST_AS_PDF,
			payload: getPromise()
		};
	};
}

const modes = {
	onlyPost: 1,
	onlyDocs: 2,
	all: 3,
}
export function getPostsByFilter(posts, buildings, viewer, filters, retValue, queryMode, members) {
	let retArray = [];
	let buildingIssues = {};
	buildingIssues[ALL_BUILDINGS_ID] = 0;

	if (buildings && posts) {
		let arr = [];
		retArray = posts.slice();
		if (queryMode == modes.onlyPosts)
			retArray = retArray.filter(value => (value.isIssue));
		else if (queryMode == modes.onlyDocs) {
			retArray = retArray.filter(value => (!value.isIssue || value.issueState == 100));
}
		if (filters) {
			Object.values(filters).forEach(filter => {
				if (filter.key == "onMe")
					retArray = retArray.filter(post => post.isIssue && ((post.issueState == IssueStates.ISSUE_STATE_RESOLVED && post.owner && post.owner.id == viewer.id) ||
						(post.issueState == IssueStates.ISSUE_STATE_OPENED && post.assignTo && post.assignTo.id == viewer.id)));
				else if (filter.key == "byMe")
					retArray = retArray.filter(post => post.isIssue && ((post.issueState == IssueStates.ISSUE_STATE_OPENED && post.owner && post.owner.id == viewer.id) ||
						(post.issueState == IssueStates.ISSUE_STATE_RESOLVED && post.assignTo && post.assignTo.id == viewer.id)));
				else if (filter.key != "all" && filter.key) {
					let filterKey = filter.key.split(' ')[0];
					retArray = retArray.filter(post => {
						let path = filterKey.split('/');
						let target = post;
						for (let i = 0; (i < path.length) && target; i++) {
							if (target[path[i]] != null)
								target = target[path[i]];
							else
								target = null;
						}

						if (target == null)
							return;

						if (filter.key.split(' ')[1]) {
							let outherFilterKey = filter.key.split(' ')[1];
							let outerSource = outherFilterKey.split('_')[0];
							let outerKey = outherFilterKey.split('_')[1];

							let newTarget = members.getNested([target, outerKey]);
							return (newTarget == filter.value)
						}
						else
							return (target == filter.value)
					});
				}
			});
		}

		if (retValue != "posts") {
			for (let z = 0; z < retArray.length; z++) {
				let location = retArray[z].location;

				if (location) {
					if (location.unit && location.unit.id)
						buildingIssues[location.unit.id] = buildingIssues[location.unit.id] ? buildingIssues[location.unit.id] + 1 : 1;
					if (location.floor && location.floor.id)
						buildingIssues[location.floor.id] = buildingIssues[location.floor.id] ? buildingIssues[location.floor.id] + 1 : 1;
					if (location.building && location.building.id)
						buildingIssues[location.building.id] = buildingIssues[location.building.id] ? buildingIssues[location.building.id] + 1 : 1;
				}
			}
			buildingIssues[ALL_BUILDINGS_ID] = retArray.length;

		}
	}

	if (retValue == "posts") {
		return retArray;
	}
	else {
		return buildingIssues;
	}
}

export function removePostsFromRealm(realmInstance) {
	var postRealm = realmInstance.posts;

  let allPosts = postRealm.objects('post24')
  //let allLastUpdateTS = postRealm.objects('lastUpdateTS').filtered('type = "post"');

	postRealm.write(() => {
		//postRealm.delete(allLastUpdateTS);
		postRealm.delete(allPosts);
	});
}

export async function removePostsFromLoki(lokiInstance) {
	//await lokiInstance.getCollection('lastUpdateTS').cementoFullDelete({ type: 'posts' });
	await lokiInstance.getCollection('posts').cementoFullDelete();
}

function preparePostForRealm(post, projectId) {
	let preparePost = { ...post.realmToObject(), location: null, comments: [], images: [], attachments: [], taggedCompanies: [], projectId, isLocal: Boolean(post.isLocal), refs: []};
	
	if (preparePost.assignTo)
		preparePost.assignTo = {id:preparePost.assignTo.id}

	preparePost.createdAt = post.createdAt ? new Date(post.createdAt) : null;
	preparePost.editedAt = post.editedAt ? new Date(post.editedAt) : (post.createdAt ? new Date(post.createdAt) : null);
	preparePost.updatedTS = post.updatedTS ? new Date(post.updatedTS) : null;
	preparePost.stateUpdatedTS = post.stateUpdatedTS ? new Date(post.stateUpdatedTS) : null;
	preparePost.dueDate = post.dueDate ? new Date(post.dueDate) : null;
	preparePost.editedAtOrCreatedAt = preparePost.editedAt || preparePost.createdAt;
	
	Object.values(post.comments || {}).forEach(comment => {
		var prepareComment = {...comment, images: []};
		
		// Push images to comments
		Object.values(comment.images || {}).forEach(currCommentImage => prepareComment.images.push(currCommentImage));
		preparePost.comments.push(prepareComment)	  				 
	});
	
	// TODO: Make push or update on all of the arrays
	Object.values(post.images || {}).forEach(image => { preparePost.images.push(image) });
	Object.values(post.attachments || {}).forEach(image => { preparePost.attachments.push(image) });
	Object.values(post.taggedCompanies || {}).forEach(taggedCompany => preparePost.taggedCompanies.push(taggedCompany));
	Object.values(post.refs || {}).forEach(postReference => preparePost.refs.push(postReference));
	if (post.location)
		['building', 'floor', 'unit'].forEach(loc => _.set(preparePost, ['location', loc], post.location[loc] || null));

	return preparePost;
}

function saveToRealm(posts, deletedPosts, lastUpdateTS, projectId, realmInstance, ignoreTimestamp, cleanAll, platformActions, forceLocalSave, source) {
	if (!cleanAll && (!posts || !posts.length) && (!deletedPosts || !deletedPosts.length))
		return;

	posts = (posts || []).sort((postA, postB) => (postA.updatedTS || 0) > (postB.updatedTS || 0) ? -1 : 1);
	let posts_currBatchMaxLastUpdateTS = _.get(posts, [0, 'updatedTS'], 0);

	deletedPosts = (deletedPosts || []).sort((postA, postB) => postA.updatedTS > postB.updatedTS ? -1 : 1);
	let deletedPosts_currBatchMaxLastUpdateTS = _.get(deletedPosts, [0, 'updatedTS'], 0);

	let currBatchMaxLastUpdateTS = new Date(Math.max(posts_currBatchMaxLastUpdateTS, deletedPosts_currBatchMaxLastUpdateTS) || 0);

	let postRealm = realmInstance.posts;
	let preparedPostArray = posts.map(post => preparePostForRealm(post, projectId));

	updateRetryRealm(preparedPostArray, projectId, realmInstance.retry_posts, 'post24');
	let ignoreList = {};
	if (posts.length == 1 && !forceLocalSave) { // TODO: Performance: Update the quary to check for all ids that are on posts on 1 quary, and remove the limit of check only on 1 post case
		posts.forEach(inPost => {
			let savedPosts = postRealm.objects('post24').filtered('projectId = "' + projectId + '" AND id = "' + inPost.id + '"');
			if (savedPosts.length == 1) {
				let savedPost = savedPosts[0].realmToObject();
				if ((Boolean(inPost.isLocal) == Boolean(savedPost.isLocal)) &&
						(savedPost.updatedTS && savedPost.updatedTS.getTime && (inPost.updatedTS == savedPost.updatedTS.getTime())))
					ignoreList[inPost.id] = true;
			}
		});
	}
	
	if ((Object.keys(ignoreList || {}).length < posts.length) || (deletedPosts && deletedPosts.length)) {
		postRealm.beginTransaction();
		try {
			if (cleanAll) {
				let allPosts = postRealm.objects('post24').filtered(`projectId = "${projectId}"`);
				postRealm.delete(allPosts); 
			}
			
			preparedPostArray.forEach(post => {
				post.projectId = projectId;
				delete post['tempPostOriginalJson'];
				if (ignoreList[post.id])
					return;
				if (post && post.id) {
					postRealm.create('post24', post, 'modified');
				}
				else {
					console.warn('post missing ID'); 
					if (platformActions)
						platformActions.sentry.notify('post missing ID', {id: (post ? post.id : ""), post});
				}}
			);

			if (deletedPosts && deletedPosts.length) {
				let deletedPostsArrays = _.chunk(deletedPosts, 1000);
				deletedPostsArrays.forEach(currentDeletedPostArray => {
					let query = currentDeletedPostArray.map(post => "id = '"  + post.id + "'");
					let queryString = query.join(' OR ');
					let deletedRealms = postRealm.objects('post24').filtered(queryString);
					postRealm.delete(deletedRealms);		
				})
			}
			replaceMaxUpdateTSIfNeeded(currBatchMaxLastUpdateTS, postRealm, 'post24', `projectId = "${projectId}"`);
			postRealm.commitTransaction();
		} catch (e) {
			postRealm.cancelTransaction();
			throw e;
		}
	}
}

function saveToLoki(posts = {}, deletedPosts, undefined, projectId, lokiInstance, isLocal, ignoreTimestamp, cleanAll) {
	let postsArray = Object.values(posts || {});
	let deletedPostsArray = Object.values(deletedPosts || {})
	if (!cleanAll && !postsArray.length && !deletedPostsArray.length)
		return;

	postsArray = postsArray.sort((postA, postB) => postA.updatedTS > postB.updatedTS ? -1 : 1);
	deletedPostsArray = deletedPostsArray.sort((postA, postB) => postA.updatedTS > postB.updatedTS ? -1 : 1);

	let allPosts = [];
	postsArray.forEach(p => {
		let preparePost = { comments: [], images: [], attachments:[], taggedCompanies:[], projectId, isLocal: false, ...p }
		preparePost.editedAtOrCreatedAt = preparePost.editedAt || preparePost.createdAt;
		preparePost.isLocal = isLocal;
			
		allPosts.push(preparePost)
	});

	if (cleanAll) {
		lokiInstance.getCollection('posts').cementoDelete({ 'projectId' : projectId });
	} else if (!_.isEmpty(deletedPosts)) {
		var deletedIds = deletedPostsArray.map(p => p.id);
  	lokiInstance.getCollection('posts').cementoDelete({ id: { '$in' : deletedIds }});
	}
	
	lokiInstance.getCollection('posts').cementoUpsert(allPosts);
}