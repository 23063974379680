import * as actions from './actions';
import * as lastUpdatesActions from '../lastUpdates/actions';
import { REPLACE_USER } from '../auth/actions';
import _ from "lodash"
import Project from './project';
import Member from '../members/member';
import * as eventsCountersActions from '../events/actions';

import { Map } from 'immutable';
import { Record } from '../transit';

const InitialState = Record({
  map: Map(), 
  didLoad: false,
  detailsMap: Map(),
  projectReducersLoaded: Map(),
  projectLokiLoaded: Map(),
  tempProject: Project(),
  currentProject: Project(),
  lastUpdated: Map(),
  lastUpdateAvailable: Map(),
  intl: null,
}, 'projects');

const initialState = new InitialState;

export default function projectReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState;

  switch (action.type) {

    case actions.LOKI_DID_LOAD: {
      if (!action.payload)
        return state;
      const { projectId, status } = action.payload
      state = state.setIn(['projectLokiLoaded', projectId], status);
      return state;
    }

    case actions.SET_PROJECT_INTL: {
      if (!action.payload)
        return state;
      
      const { intl } = action.payload;
      
      if (intl) {
        state = state.set('intl', intl);
      } else {
        state = state.delete('intl');
      }

      return state;
    }

    case actions.GET_PROJECTS: {
      state = state.set('didLoad', true);

      if (!action.payload)
        return state;
      
      const { projects, adminMode } = action.payload;

      if (!projects)
        return state;

      var retArray = Object.values(projects);
      state = state.setIn(['map'], new Map());

      for (var i = 0; i < retArray.length; i++) {
        var ret = {...retArray[i]};
        var project = new Project(ret);
        if (project.id && (adminMode != 2 || ret.showOnAdmin))
          state = state.setIn(['map', project.id], project);
      }
      return state;
    }

    case actions.GET_LOCAL_PROJECT_DETAILS + "_SUCCESS":
    case actions.GET_PROJECT_DETAILS + "_SUCCESS": {
      if (!action.payload)
        return state;
      let projectDetails = action.payload;
      projectDetails = _.get(projectDetails, ['projects', projectDetails.projectId])
        ? _.get(projectDetails, ['projects', projectDetails.projectId])
        : _.get(projectDetails, ['projects']);

      if (!projectDetails)
        return state;
        
      var members = {};
      if (projectDetails.members)
        Object.values(projectDetails.members).map(member => members[member.id] = new Member(member));

      var project = new Project({...projectDetails, members: members}); 
      state = state.setIn(['lastUpdated', project.id], state.getNested(['lastUpdateAvailable', project.id], null));
      return state.setIn(['detailsMap', project.id], project);
    }

    case actions.UPDATE_PROJECT_MEMBERS + '_SUCCESS': {
      if (!action.payload)
        return state;
      const { project } = action.payload;
      if (!project)
        return state;
      return state.setIn(['detailsMap', project.id], project);
    }

    case lastUpdatesActions.GET_LAST_UPDATES: {
      if (!action.payload || !action.payload.projects)
        return state;

      const { projects } = action.payload;

      Object.keys(projects).forEach(projectId => {
        let TS = projects.getNested([projectId, 'projects', 'lastUpdateTS'], 1);
        let curr = state.getNested(['lastUpdateAvailable', projectId], null)
        if (curr < TS) state = state.setIn(['lastUpdateAvailable', projectId], TS);
      });

      return state;
    }

    case eventsCountersActions.INIT_PROJECT_EVENTS_COUNTERS + "_SUCCESS": {
      if (!action.payload)
        return state;

      const { projectId, newFue } = action.payload;

      if (!projectId)
        return state;        
      
      state = state.deleteIn(['map', projectId, 'counter']);
      if (newFue)
        state = state.setIn(['map', projectId, 'fue'], newFue);

      return state;
    }

    case actions.CREATE_NEW_PROJECT: {
      const project = action.payload;
      return state.set('tempProject', project);
    }

    case actions.GET_NEW_PROJECT_ID + '_SUCCESS': {
      const projectId = action.payload;
      return state.setIn(['tempProject', 'id'], projectId);
    }

    case actions.UPDATE_LOCAL_PROJECT: {
      const { projectId, project } = action.payload;

      if (!project)
        return state;

      return state.setIn(['detailsMap', project.id ? project.id : projectId], new Project({ ...project }));
      
    }

    case actions.UPDATE_PROJECT_ADDRESS: {
      const { address, city, projectName, title } = action.payload;
      state = state.setIn(['tempProject', 'address'], address);
      
      if (city)
        state = state.setIn(['tempProject', 'city'], city);
      
      if (title)
        state = state.setIn(['tempProject', 'title'], title);
      
      return state;
    }

    case actions.UPDATE_PROJECT_TYPE: {
      const { projectType } = action.payload;
      return state.setIn(['tempProject', 'type'], projectType);
    }
    
    case actions.UPDATE_PROJECT_FLOORS: {
      const { minFloor, maxFloor } = action.payload;
      state = state.setIn(['tempProject', 'minFloor'], minFloor);
      
      return state.setIn(['tempProject', 'maxFloor'], maxFloor);
    }
    
    case actions.CLEAN_NEW_PROJECT: {
      return state.set('tempProject', null);
    }
    
    case REPLACE_USER + "_SUCCESS": {
      return initialState;
    }
  }

  return state;
}
