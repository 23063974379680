import { Record } from '../transit';

const InitialState = Record({
  appName: '',
  appVersion: '',
  firebase: '',
  sentryUrl: '',
  mixpanel: '',
  nativeSentryDsn: '',
  webSentryDsn:'',
  notificationServer: '',
  apiServer: '',
  pdfServer: '',
  isProd: '',
  auth0: '',
}, 'config');

export default function configReducer(state = new InitialState) {
  return state;
}
