import { defineMessages } from 'react-intl';

export default defineMessages({
  statusLabels: {
    rejected: {
      defaultMessage: 'Rejected',
      id: 'analytics.statusLabels.rejected'
    },
    resolved: {
      defaultMessage: 'Resolved',
      id: 'analytics.statusLabels.resolved'
    },
    confirm: {
      defaultMessage: 'Confirmed',
      id: 'analytics.statusLabels.confirmed'
    },
    confirm2: {
      defaultMessage: 'Checked',
      id: 'analytics.statusLabels.confirmed2'
    },
    partial: {
      defaultMessage: 'Partial',
      id: 'analytics.statusLabels.partial'
    },
    irrelevant: {
      defaultMessage: 'Irrelevant',
      id: 'analytics.statusLabels.irrelevant'
    },
  },
  location: {
    defaultMessage: 'Location',
    id: 'analytics.titles.locations'
  },
  locations: {
    defaultMessage: 'Locations',
    id: 'analytics.titles.locationss'
  },
  reports: {
    defaultMessage: 'Analytics',
    id: 'analytics.filters.reports'
  },
  backToReports: {
    defaultMessage: 'Back to analytics',
    id: 'analytics.filters.backToReports'
  },
  detailView: {
    defaultMessage: 'Details',
    id: 'analytics.filters.detailView'
  },
  buildingView: {
    defaultMessage: 'Building view',
    id: 'analytics.filters.buildingView'
  },
  notSelected: {
    defaultMessage: 'Not selected',
    id: 'analytics.filters.notSelected'
  },
  allTrades: {
    defaultMessage: 'All trades',
    id: 'analytics.filters.allTrades'
  },
  trade: {
    defaultMessage: 'Trade',
    id: 'analytics.filters.trade'
  },
  badges: {
    defaultMessage: 'Badges',
    id: 'analytics.filters.badges'
  },
  taskBadge: {
    defaultMessage: 'Issues',
    id: 'analytics.filters.issues'
  },
  docBadge: {
    defaultMessage: 'Records',
    id: 'analytics.filters.documentations'
  },
  viewType: {
    viewType: {
      defaultMessage: 'View',
      id: 'analytics.viewType.viewType'
    },
    filterByDate: {
      defaultMessage: 'Dates',
      id: 'analytics.viewType.filterByDate'
    },
    filterByUser: {
      defaultMessage: 'User',
      id: 'analytics.viewType.filterByUser'
    },
    grade: {
      defaultMessage: 'Grade',
      id: 'analytics.viewType.grade'
    },
    progress: {
      defaultMessage: 'Progress',
      id: 'analytics.viewType.progress'
    },
  },
  NoValuesFound: {
    defaultMessage: 'No values found matching the current filter. Please change the filters to see the data.',
    id: 'analytics.filters.NoValuesFound'
  },
  allStages: {
    defaultMessage: 'General',
    id: 'analytics.filters.allStages'
  },
  noRangeSyncAlert: {
    defaultMessage: 'Attention: The details presented on this card are of today status',
    id: 'analytics.alerts.noRangeSyncAlert'
  },
  noUserSyncAlert: {
    defaultMessage: 'Attention: The details presented on this card are not filtered by worker',
    id: 'analytics.alerts.noUserSyncAlert'
  },
  dashboards: {
    labels: {
      allAvarage: {
        defaultMessage: 'Average',
        id: 'analytics.dashboards.labels.allAvarage'
      },
      otherCertificates: {
        defaultMessage: 'Other permits',
        id: 'analytics.dashboards.labels.otherCertificates'
      },
    },
    timeSelection: {
      startOfProject: {
        defaultMessage: 'The beginning',
        id: 'analytics.dashboards.timeSelection.startOfProject'
      },
      fromLastQuarter: {
        defaultMessage: 'Last quarter',
        id: 'analytics.dashboards.timeSelection.fromLastQuarter'
      },
      fromLastWeek: {
        defaultMessage: 'Last 7 days',
        id: 'analytics.dashboards.timeSelection.fromLastWeek'
      },
      currMonth: {
        defaultMessage: 'Current month',
        id: 'analytics.dashboards.timeSelection.currMonth'
      },
      lastMonth: {
        defaultMessage: 'Last month',
        id: 'analytics.dashboards.timeSelection.lastMonth'
      },
      avarage: {
        startOfProject: {
          defaultMessage: 'All time avarage',
          id: 'analytics.dashboards.timeSelection.avarage.startOfProject'
        },
        fromLastQuarter: {
          defaultMessage: 'Last quarter avarage',
          id: 'analytics.dashboards.timeSelection.avarage.fromLastQuarter'
        },
        fromLastWeek: {
          defaultMessage: 'Last 7 days avarage',
          id: 'analytics.dashboards.timeSelection.avarage.fromLastWeek'
        },
        currMonth: {
          defaultMessage: 'Current month avarage',
          id: 'analytics.dashboards.timeSelection.avarage.currMonth'
        },
        lastMonth: {
          defaultMessage: 'Last month avarage',
          id: 'analytics.dashboards.timeSelection.avarage.lastMonth'
        },
      }
    },
    menu: {
      main: {
        defaultMessage: 'Main',
        id: 'analytics.dashboards.main'
      },
      title: {
        defaultMessage: 'Dashboard',
        id: 'analytics.dashboards.title'
      },
    },
    noValueMessage: {
      general: {
        defaultMessage: 'There are no values to display.',
        id: 'analytics.noValueMessage.general'
      },
    },
    tooltips: {
      days: {
        defaultMessage: '{val} days',
        id: 'analytics.dashboards.tooltips.days'
      },
      tasks: {
        defaultMessage: '{val} tasks',
        id: 'analytics.dashboards.tooltips.tasks'
      },
      records: {
        defaultMessage: '{val} records',
        id: 'analytics.dashboards.tooltips.records'
      },
      employees: {
        defaultMessage: '{val} workers',
        id: 'analytics.dashboards.tooltips.employees'
      },
      employeesWithValidCertificate: {
        defaultMessage: '{val} Workers with valid permits for /n"{trade}"',
        id: 'analytics.dashboards.tooltips.employeesWithValidCertificate'
      },
      checklistsItems: {
        defaultMessage: '{val} {trade} rejected checklist items',
        id: 'analytics.dashboards.tooltips.checklistsItems'
      },
      safetyGrade: {
        defaultMessage: 'Grade: {val}',
        id: 'analytics.dashboards.tooltips.safetyGrade'
      },
      minmumGrade: {
        defaultMessage: 'Minmum grade: {val}',
        id: 'analytics.dashboards.tooltips.minmumGrade'
      },
      finalGrade: {
        defaultMessage: 'Final grade: {val}',
        id: 'analytics.dashboards.tooltips.finalGrade'
      },
      minmumGradeLegand: {
        defaultMessage: 'Minmum grade',
        id: 'analytics.dashboards.tooltips.minmumGradeLegand'
      },
      finalGradeLegand: {
        defaultMessage: 'Final grade',
        id: 'analytics.dashboards.tooltips.finalGradeLegand'
      },
      currGrade: {
        defaultMessage: 'Current grade',
        id: 'analytics.dashboards.tooltips.currGrade'
      },
      weeklyAvarage: {
        defaultMessage: 'Weekly avarage',
        id: 'analytics.dashboards.tooltips.weeklyAvarage'
      },
      checklistNumber: {
        defaultMessage: '{val} Checklists',
        id: 'analytics.dashboards.tooltips.checklistNumber'
      },
    },
    axesLabels: {
      days: {
        defaultMessage: 'Days',
        id: 'analytics.axesLabels.days'
      },
      trade: {
        defaultMessage: 'Trade',
        id: 'analytics.axesLabels.trade'
      },
      tasks: {
        defaultMessage: 'Tasks',
        id: 'analytics.axesLabels.tasks'
      },
      employeeName: {
        defaultMessage: 'Worker name',
        id: 'analytics.axesLabels.employeeName'
      },
      openedTasks: {
        defaultMessage: 'Opened tasks',
        id: 'analytics.axesLabels.openedTasks'
      },
      recordsAmount: {
        defaultMessage: 'Records',
        id: 'analytics.axesLabels.recordsAmount'
      },
      systemRecords: {
        defaultMessage: 'System records',
        id: 'analytics.axesLabels.systemRecords'
      },
      date: {
        defaultMessage: 'Date',
        id: 'analytics.axesLabels.date'
      },
      checklistsItems: {
        defaultMessage: 'Checklists items',
        id: 'analytics.axesLabels.checklistsItems'
      },
      numOfRejectedChecklistItems: {
        defaultMessage: 'Number of time checklists items was rejected',
        id: 'analytics.axesLabels.numOfRejectedChecklistItems'
      },
      checklistItemName: {
        defaultMessage: 'Checklist item name',
        id: 'analytics.axesLabels.checklistItemName'
      },
      numberOfCertificates: {
        defaultMessage: 'Number of permits',
        id: 'analytics.axesLabels.numberOfCertificates'
      },
      numberOfHeightCertificates: {
        defaultMessage: 'Number of height permits',
        id: 'analytics.axesLabels.numberOfHeightCertificates'
      },
      numberOfSiteTraining: {
        defaultMessage: 'Number of site training',
        id: 'analytics.axesLabels.numberOfSiteTraining'
      },
      itemsAmount: {
        defaultMessage: 'Items amount',
        id: 'analytics.axesLabels.itemsAmount'
      },
      itemType: {
        defaultMessage: 'Item type',
        id: 'analytics.axesLabels.itemType'
      },
      reviews: {
        defaultMessage: 'Reviews',
        id: 'analytics.axesLabels.reviews'
      },
      activeEmployees: {
        defaultMessage: 'Active workers',
        id: 'analytics.axesLabels.activeEmployees'
      },
      grade: {
        defaultMessage: 'Grade',
        id: 'analytics.axesLabels.grade'
      },
      building: {
        defaultMessage: 'Building',
        id: 'analytics.axesLabels.building'
      },
      title: {
        defaultMessage: 'Title',
        id: 'analytics.axesLabels.title'
      },
      safetyIssues: {
        defaultMessage: 'Safety issues',
        id: 'analytics.axesLabels.safetyIssues'
      },
      checklistNumber: {
        defaultMessage: 'Checklists amount',
        id: 'analytics.axesLabels.checklistNumber'
      }
    },
    titles: {
      history: {
        defaultMessage: 'History',
        id: 'analytics.dashboards.titles.history'
      },
      taskCreationSource: {
        defaultMessage: 'Tasks source',
        id: 'analytics.dashboards.titles.taskCreationSource'
      },
      waitingForFix: {
        defaultMessage: 'Rejected items - waiting for resolving',
        id: 'analytics.dashboards.titles.waitingForFix'
      },
      avarageTimeForFix: {
        defaultMessage: 'Average time for resolving rejected checklist item',
        id: 'analytics.dashboards.titles.avarageTimeForFix'
      },
      confirmedChecklistByTrade: {
        defaultMessage: 'Checklists items by trade',
        id: 'analytics.dashboards.titles.confirmedChecklistByTrade'
      },
      checklistHistory: {
        defaultMessage: 'Quality assurance history',
        id: 'analytics.dashboards.titles.checklistHistory'
      },
      highFreqRejection: {
        defaultMessage: 'High frequence rejected checklists',
        id: 'analytics.dashboards.titles.highFreqRejection'
      },
      mostActiveIssues: {
        defaultMessage: 'Active tasks users',
        id: 'analytics.dashboards.titles.mostActiveIssues'
      },
      mostActiveChecklistReporter: {
        defaultMessage: 'Active checklist users',
        id: 'analytics.dashboards.titles.mostActiveChecklistReporter'
      },
      tasksByTrade: {
        defaultMessage: 'Tasks by trade',
        id: 'analytics.dashboards.titles.tasksByTrade'
      },
      avarageTimeToCloseTask: {
        defaultMessage: 'Average time to close task (Closed tasks)',
        id: 'analytics.dashboards.titles.avarageTimeToCloseTask'
      },
      avarageAgeOfOpenTasks: {
        defaultMessage: 'Average age of unclosed tasks',
        id: 'analytics.dashboards.titles.avarageAgeOfOpenTasks'
      },
      recordsByTrade: {
        defaultMessage: 'Records by trade',
        id: 'analytics.dashboards.titles.recordsByTrade'
      },
      recordsCreationSource: {
        defaultMessage: 'Records creation source',
        id: 'analytics.dashboards.titles.recordsCreationSource'
      },
      mostActiveRecordsReporter: {
        defaultMessage: 'Most active records reporters',
        id: 'analytics.dashboards.titles.mostActiveRecordsReporter'
      },
      certificatedExpiration: {
        defaultMessage: 'Worker permit by trade',
        id: 'analytics.dashboards.titles.certificatedExpiration'
      },
      heightCertificatedExpiration: {
        defaultMessage: 'Worker height permit by trade',
        id: 'analytics.dashboards.titles.heightCertificatedExpiration'
      },
      siteCertificatedExpiration: {
        defaultMessage: 'Worker site training by trade',
        id: 'analytics.dashboards.titles.siteCertificatedExpiration'
      },
      validCertificatinEmpoylees: {
        defaultMessage: 'Permit type - active Workers per permit',
        id: 'analytics.dashboards.titles.validCertificatinEmpoylees'
      },
      equipmentCertificatedExpiration: {
        defaultMessage: 'Equipment permit expirations',
        id: 'analytics.dashboards.titles.equipmentCertificatedExpiration'
      },
      equipmentByType: {
        defaultMessage: 'Equipment by type',
        id: 'analytics.dashboards.titles.equipmentByType'
      },
      safetyGrade: {
        defaultMessage: 'SafetyGrade',
        id: 'analytics.dashboards.titles.safetyGrade'
      },
      safetyGradeHistory: {
        defaultMessage: 'Safety Grade History',
        id: 'analytics.dashboards.titles.safetyGradeHistory'
      },
      gradesByTrade: {
        defaultMessage: 'Grade by trade',
        id: 'analytics.dashboards.titles.gradesByTrade'
      },
      gradesByBuilding: {
        defaultMessage: 'Grade by building',
        id: 'analytics.dashboards.titles.gradesByBuilding'
      }
    }
  },
  companyQualityTable: {
    sections: {
      totalStats: {
        defaultMessage: 'Total Stats',
        id: 'analytics.companyQualityTable.sections.totalStats'
      },
      recentUse: {
        defaultMessage: 'Usage {value}',
        id: 'analytics.companyQualityTable.sections.recentUse'
      },
      openTasksByTrade: {
        defaultMessage: 'Open Tasks by trade',
        id: 'analytics.companyQualityTable.sections.openTasksByTrade'
      },
    },
    columns: {
      openIssues: {
        defaultMessage: 'Open tasks',
        id: 'analytics.companyQualityTable.columns.openIssues'
      },
      resolvedIssues: {
        defaultMessage: 'Resolved tasks',
        id: 'analytics.companyQualityTable.columns.resolvedIssues'
      },
      closedIssues: {
        defaultMessage: 'Closed tasks',
        id: 'analytics.companyQualityTable.columns.closedIssues'
      },
      records: {
        defaultMessage: 'Records',
        id: 'analytics.companyQualityTable.columns.records'
      },
      completedChecklists: {
        defaultMessage: 'Completed checklists',
        id: 'analytics.companyQualityTable.columns.completedChecklists'
      },
      partialChecklists: {
        defaultMessage: 'Partial checklists',
        id: 'analytics.companyQualityTable.columns.partialChecklists'
      },
      newIssues: {
        defaultMessage: 'New tasks',
        id: 'analytics.companyQualityTable.columns.newIssues'
      },
      recentClosedIssues: {
        defaultMessage: 'Closed issues',
        id: 'analytics.companyQualityTable.columns.recentClosedIssues'
      },
      newRecords: {
        defaultMessage: 'New records',
        id: 'analytics.companyQualityTable.columns.newRecords'
      },
      recentCompletedChecklists: {
        defaultMessage: 'Completed checklists',
        id: 'analytics.companyQualityTable.columns.recentCompletedChecklists'
      },
      recentPartialChecklists: {
        defaultMessage: 'Partial checklists',
        id: 'analytics.companyQualityTable.columns.recentPartialChecklists'
      },
      
    }
  }
});
