import { Range } from 'immutable';
import ExtraError from '../lib/errors/extraError'
import { removeProjectsStorage } from '../projects/actions';
import {getSnapshotData} from '../lib/utils/utils';

export const GET_DRAWING_SETTINGS = 'GET_DRAWING_SETTINGS';
export const GET_DRAWINGS = 'GET_DRAWINGS';
export const UPDATE_DRAWINGS = 'UPDATE_DRAWINGS';
export const CLEAN_DRAWINGS_CACHE_DATA = 'CLEAN_DRAWINGS_CACHE_DATA';

export const BUILDINGS = "buildings";
export const BUILDING = "building";
export const FLOORS = "floors";
export const FLOOR = "floor";
export const UNITS = "units";
export const UNIT = "unit";

//NEW  - WITH utils get snaptshotdata
// export function getDrawingSettings(projectId) { 
//   //var snapshot = await firebaseDatabase().ref('drawings/' + projectId + '/settings').once('value');
//   return {
//     type: GET_DRAWING_SETTINGS,
//     payload: getSnapshotData({api:'drawings',firebase:'drawings', firebaseSuffix:'settings'},projectId)
// };
// }

export function getDrawingSettings(projectId) { 
    return {
      type: GET_DRAWING_SETTINGS,
      payload: getSnapshotData({api:'drawings',resourceName:'settings',firebaseSuffix:'settings'},projectId)
  };
}

export function getDrawings(projectId, drawingType) {
  const getPromise = async () => {
    let snapshot = await getSnapshotData({
      api: 'drawings',
      firebaseSuffix: drawingType,
      queryParams: {
        locationType: drawingType,
        legacyMode: true
      }
    }, projectId
    ) || {};

    let ret = { ...(snapshot || {}), drawingType }
    return ret;
  };

  return {
    type: GET_DRAWINGS,
    payload: getPromise()
  };
}

export function cleanDrawingsCachedData() {  
  return ({dispatch, getState, realmInstance, platformActions}) => {
    const getPromise = async () => {
      let projectIdsArray = [];
      let projectStateToRemove = [
        ['drawings', 'map'],
        ['drawings', 'lastUpdated'],
      ];

      getState().getNested(['projects', 'map'],{}).loopEach((k,p) => projectIdsArray.push(p.id))
      dispatch(removeProjectsStorage(projectIdsArray, projectStateToRemove));

    }
    return {
      type: CLEAN_DRAWINGS_CACHE_DATA,
      payload: getPromise()
    };
  };
}
