import React from "react";
import theme from "../../../common/app/theme";
import Text from "./Text";
import _ from "lodash";

class CounterCell extends React.Component {
  render() {
    const { value, style = {} } = this.props;
    let innerComponent;
    if (_.isNumber(value)) {

      innerComponent = (
        <Text style={{ color: theme.brandPrimary, fontSize: theme.fontSizeH5 + 1, transform: 'translate3d(-5px, -3px, 0px)', ...style }}>
          {String(value)}
        </Text>
      );

    }
    else {
      const splittedValue = String(value).split('/');
      innerComponent = (
        <>
          <Text style={{ color: theme.brandPrimary, fontSize: theme.fontSizeH5 + 1, transform: 'translate3d(-5px, -3px, 0px)' }}>
            {String(splittedValue[0])}
          </Text>
          <Text style={{ color: 'rgba(162, 162, 162, 0.5)', fontSize: theme.fontSizeH5, transform: 'translateY(0px)' }}>
            {'/'}
          </Text>
          <Text style={{ color: 'rgba(162, 162, 162, 0.5)', fontSize: theme.fontSize, transform: 'translateY(3px)' }}>
            {String(splittedValue[1])}
          </Text>
        </>
      );
    }


    return (
      <div style={{ display: 'flex', flexDirection: 'row', direction: 'ltr', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
        {innerComponent}
      </div>
    );
  }
}

export default CounterCell;