import { platformActions } from '../platformActions';
import { uploadImage } from '../images/actions';
import * as reportTypes from '../../common/reports/reportTypes'
import ExtraError from '../lib/errors/extraError'
import { startAlert, hideLoading } from '../app/actions';
import reportsMessages from './reportsMessages';
import React from 'react';
import _ from 'lodash';
import { onError } from '../app/funcs';
import { syncWithDB } from '../lastUpdates/actions';

export const InspectionReviewContext = React.createContext({});

export const REPORT_STATE_CLOSED = 100;
export const REPORT_STATE_WAITING_APPROVAL = 200;
export const REPORT_STATE_OPENED = 300;

export const GET_NEW_REPORT_ID 		 				  									 = 'GET_NEW_REPORT_ID';
export const GET_REPORTS_SUMMARY   			 	  									 = 'GET_REPORTS_SUMMARY';
export const GET_REPORTS_SUMMARY_STARTED    									 = 'GET_REPORTS_SUMMARY_STARTED';
export const END_REPORTS_SUMMARY_LISTENER   									 = 'END_REPORTS_SUMMARY_LISTENER';
export const GET_FULL_REPORT 		 					  									 = 'GET_FULL_REPORT';
export const END_FULL_REPORT_LISTENER 		  									 = 'END_FULL_REPORT_LISTENER';
export const GET_REPORT_BY_DATE 		 		 	  									 = 'GET_REPORT_BY_DATE';
export const UPDATE_REPORT 		 		 	 			  									 = 'UPDATE_REPORT';
export const DELETE_REPORT 		 		 	 			  									 = 'DELETE_REPORT';
export const GET_INSPECTION_REPORTS 		 											 = 'GET_INSPECTION_REPORTS';
export const CREATE_NEW_REPORT_ID 		 		  									 = 'CREATE_NEW_REPORT_ID'; 
export const SEND_FORM_VIA_MAIL 		 	 	    									 = 'SEND_FORM_VIA_MAIL';
export const ADD_NEW_FORM                   									 = 'ADD_NEW_FORM';
export const DELETE_FORM                                       = 'DELETE_FORM';
export const GET_INSPECTION_REPORTS_STARTED                    = 'GET_INSPECTION_REPORTS_STARTED';
export const END_INSPECTION_REPORTS_LISTENER                   = 'END_INSPECTION_REPORTS_LISTENER';

export function getNewFormId(projectId) {
	return ({ firebaseDatabase }) => {
		let id = firebaseDatabase().ref('forms/' + projectId + '/full/safety').push().key;
		return {
			type: CREATE_NEW_REPORT_ID,
			payload: { id }
		};
	}
}

export function sendFormViaMailServer(projectId, formId, formType, targetEmails, subject, text) {
	return ({ apiServer, platformActions }) => {
	  const getPromise = async () => {
			 	await platformActions.net.fetch(apiServer + "/v1/services/email/send/forms", { 
					method: 'POST',
					body: JSON.stringify({
						projectId, 
						formId, 
						formType, 
						targetEmails, 
						subject, 
						text, 
					})});

			  return { projectId, formId, formType, targetEmails, subject, text };
	  };
  
	  return {
		type: SEND_FORM_VIA_MAIL,
		payload: getPromise()
	  };
	};
}


export function upsertForm(projectId, viewer, form, type = "general", generatePDF = false) {
	return ({ firebase, firebaseDatabase, removeEmpty, dispatch, getState }) => {
		const getPromise = async () => {
			const originalGenerator = getState().getNested(['reports', 'inspectorReports', projectId, form.id, 'generator']);
			const generator = originalGenerator || { id: viewer.id, displayName: viewer.displayName };

			if (!type)
				throw new ExtraError('upsertForm error - form type missing', { form, type });

      let newForm = _.pick(form, [
				'certifications',
				'formTemplateId',
				'id',
				'title',
				'uri',
				'owner',
				'status',
				'checklists',
				'location',
				'universalIds',
				'posts',
				'signatures',
				'reportDate',
				'targetEmails',
				'type',
				'usersToNotify',
      ]);
      
			if (!newForm.type)
				newForm.type = type;


			
			if (newForm.location) { 
				const { unitId, buildingId, floorId } = newForm.location;
				const locationId = unitId || floorId || buildingId;

				const shouldSyncPosts = 							getState().getNested(['configurations', 'map', projectId, 'sync', 'beforePdfExport', 'posts'],  true);
				const shouldSyncChecklistInstances =  getState().getNested(['configurations', 'map', projectId, 'sync', 'beforePdfExport', 'checklistInstances'],  true);
				const shouldSyncPropertiesInstances = getState().getNested(['configurations', 'map', projectId, 'sync', 'beforePdfExport', 'propertiesInstances'], true)

				if (locationId) {
					let syncParam = {
						locationId,
						lastUpdateTS: Date.now(),
						onlyDiff: true,
						shouldUpload: true
					};

					const promises = [
						shouldSyncPosts && dispatch(syncWithDB('posts', viewer, projectId, syncParam)),
						shouldSyncChecklistInstances && dispatch(syncWithDB('checklistInstances', viewer, projectId, syncParam)),
						shouldSyncPropertiesInstances && dispatch(syncWithDB('propertiesInstances', viewer, projectId, syncParam))
					];

					await Promise.all(promises);
				}
			}

			newForm.id = newForm.id ? newForm.id : firebaseDatabase().ref('forms/' + projectId + '/full/' + type).push().key;
			
			let now = Date.now()
			newForm.updatedTS = now;
			if (generatePDF)
			newForm.readyToGenerateTS = now;
			newForm.generator = generator;

			newForm = removeEmpty(newForm, 'upsertForm_newForm');

			const timeout = 45 * 1000;
			let promise = function (projectId, type, newForm) {
				return new Promise(async function (resolve, reject) {
					if (!projectId || !newForm)
						reject('Missing projectId or form');

					setTimeout(async () => {
						if (!didResolved) {
							didRejected = true;
							reject('Action canceled by timeout: Could not contact server in a reasonable amount of time');
						}
					}, timeout);

					let updates = {};
					updates['forms/' + projectId + '/full/' + type + '/' + newForm.id] = newForm;

					let didResolved = false;
					let didRejected = false;

					firebase.update(updates, () => {
						if (didRejected)
							return;

						didResolved = true;
						resolve(newForm);
					});
				});
			};
			try {
				await (promise(projectId, newForm.type, newForm));
			} catch (error) {
				onError({
					errorMessage: 'Failed to upsert form',
					error,
					alertParams: {
						title: reportsMessages.exportErrors.title,
						message: reportsMessages.exportErrors.content,
					},
					methodMetaData: {
						name: 'upsertForm',
						args: { projectId, viewer, form, type },
					},
				});
				hideLoading();
				return { projectId, success: false };
			}
			return { projectId, reportId: newForm.id, form: newForm, success: true };
		};

		return {
			type: ADD_NEW_FORM,
			payload: getPromise()
		};
	};
}

export function deleteForm(projectId, form, type = "general") {
	return ({ firebase }) => {
	  const getPromise = async () => {
  
				let updates = {};

				updates['forms/' + projectId + '/full/' + type + '/' + form.id + '/updatedTS'] = Date.now();
			  updates['forms/' + projectId + '/full/' + type + '/' + form.id + '/isDeleted'] = true;
			  await firebase.update(updates);
  
			  return { projectId, reportId: form.id, form };
	  };
  
	  return {
		type: DELETE_FORM,
		payload: getPromise()
	  };
	};
}

export function getInspectionReports(uid, projectId) {	
	return ({ dispatch, firebaseDatabase, getState }) => {
		const getPromise = async () => {
		try {
			var lastUpdateTS = 0;
		  	if (getState().reports && getState().reports.inspectorReportsLastUpdateTS && getState().reports.inspectorReportsLastUpdateTS.get(projectId))
					lastUpdateTS = getState().reports.inspectorReportsLastUpdateTS.get(projectId) + 1;
					
			firebaseDatabase().ref('forms/' + projectId + '/full/safety').orderByChild('updatedTS').startAt(lastUpdateTS).on('value', function(snapshot) {
				dispatch({ type: GET_INSPECTION_REPORTS, payload: { reports: snapshot.val(), viewerId: uid }, projectId: projectId});
			});
			// firebaseDatabase().ref('forms/' + projectId + '/full/safety').orderByChild('updateTS').startAt(lastUpdateTS).on('value', function(snapshot) {
			// 	dispatch({ type: GET_INSPECTION_REPORTS, payload: { reports: snapshot.val(), viewerId: uid }, projectId: projectId});
			// });
		} catch (error) {
			throw new ExtraError('getInspectionReports error', {uid, projectId}, error)     
		}
	}

		return {
			type: GET_INSPECTION_REPORTS_STARTED,
			payload: getPromise()
		};
	};
}

export function endInspectionReportsListener(projectId) {	
  return ({ firebaseDatabase }) => {
	  firebaseDatabase().ref('forms/' + projectId + '/full/safety').off('value');
    return {
      type: END_INSPECTION_REPORTS_LISTENER,
      payload: { projectId }
    };
  };
}

export function getReports(uid, projectId) {	
	  return ({ dispatch, firebaseDatabase, getState }) => {
	  	const getPromise = async () => {
			try {
		  	var lastUpdateTS = 0;
		  	if (getState().reports && getState().reports.lastUpdateTS && getState().reports.lastUpdateTS.get(projectId))
		  		lastUpdateTS = getState().reports.lastUpdateTS.get(projectId) + 1;
		  	
			  firebaseDatabase().ref('reports/summary/' + projectId).orderByChild('updatedTS').startAt(lastUpdateTS).on('value', function(snapshot) {
				  dispatch({ type: GET_REPORTS_SUMMARY, payload: { reports: snapshot.val(), viewerId: uid }, projectId: projectId});
				});		  	
			} catch (error) {
				throw new ExtraError('getReports error', {uid, projectId}, error)     
			}
		}

    return {
      type: GET_REPORTS_SUMMARY_STARTED,
      payload: getPromise()
    };
  };
}

export function endReportsListener(projectId) {	
  return ({ firebaseDatabase }) => {
	  firebaseDatabase().ref('reports/summary/' + projectId).off('value');
    return {
      type: END_REPORTS_SUMMARY_LISTENER,
      payload: { projectId }
    };
  };
}

export function getFullReport(uid, projectId, reportId) {	
	  return ({ dispatch, firebaseDatabase, bugsnag, getState }) => {
	  	const getPromise = async () => {
			try {
			  firebaseDatabase().ref('reports/full/' + projectId + '/' + reportId).on('value', function(snapshot) {
				  dispatch({ type: GET_FULL_REPORT, payload: { report: snapshot.val(), viewerId: uid, reportId, projectId }, projectId: projectId});
				});		  	
			} catch (error) {
				throw new ExtraError('getFullReport error', {uid, projectId, reportId}, error);
			}
		}

    return {
      type: GET_FULL_REPORT,
      payload: getPromise()
    };
  };
}

export function endFullReportListener(projectId, reportId) {	
  return ({ firebaseDatabase }) => {
	  firebaseDatabase().ref('reports/full/' + projectId + '/' + reportId).off('value');
    return {
      type: END_FULL_REPORT_LISTENER,
      payload: { projectId }
    };
  };
}

export function getNewReportId(projectId, type) {
	return ({ firebaseDatabase }) => {
		const getPromise = async () => {
			try {
				let reportType = type || "full";
				//IDAN - check
				var push = firebaseDatabase().ref('reports/'+reportType+'/' + projectId).push();
				return (push.key);
			} catch (error) {
				throw new ExtraError('getNewReportId error', {projectId}, error);
			}
		}
		return {
			type: GET_NEW_REPORT_ID,
			payload: getPromise()
		};
	};
}

function getEpochDayDate(year, month, day) {

	// get epoch time of target date
	var targetUTCEpochTime = Date.UTC(year, month, day)
	if (year == null || month == null || day == null) {
		var currentTime = new Date()
		var currDay = currentTime.getDate()
		var currMonth = currentTime.getMonth()
		var currYear = currentTime.getFullYear()

		targetUTCEpochTime = Date.UTC(currYear, currMonth, currDay)
	}
		
  return targetUTCEpochTime;
}

export function getReportByDate(projectId, reportType, createIfNotExist, year, month, day) {
	return ({ firebase, firebaseDatabase, getState, dispatch }) => {
		const getPromise = async () => {
			var targetUTCEpochTime = getEpochDayDate(year, month, day);

			var ret = getState().reports.fullReports.get(projectId) && getState().reports.fullReports.get(projectId).filter(report => (report.dateTS == targetUTCEpochTime && report.type == reportType));
			var report = null;
			var exist = false;
			if (!ret || ret.size == 0) {
				// Check on server
				var reportsRef = firebaseDatabase().ref('reports/full/' + projectId);
				var reportRet = await reportsRef.orderByChild('dateTS').equalTo(targetUTCEpochTime).once('value');
		  	var reportsRet = reportRet.val();
		  	if (reportsRet)
		  		var report = Object.values(reportsRet)[0]
		  }
		  else {
				var localReport = ret.first();
		  	if (localReport)
		  		report = localReport.toJS();
		  }

		  exist = Boolean(report);

		  if (!exist) {
				var reportId = await dispatch(getNewReportId(projectId));
				var updates = {};
				var currEpochTime = new Date().getTime();
				var additions = {};
				if (reportType == reportTypes.DAILY) 
					additions = {createdAT: currEpochTime };
				else if (reportType == reportTypes.ROUTINE_CHECKLIST) {
					additions = {};
				}
				report = {id: reportId, type: reportType, dateTS: targetUTCEpochTime, status: REPORT_STATE_OPENED, updatedTS: currEpochTime, createdTS: currEpochTime, ...additions};

				if (createIfNotExist) { // TODO: Get some values from last report as defaults - Posts, Approver
			  	updates['reports/full/' + projectId + '/' + reportId] = report;
					await firebase.update(updates)
					exist = true;
				}
		  }

			return ({report, exist});
		}
		return {
			type: GET_REPORT_BY_DATE,
			payload: getPromise()
		};
	};
}

export function updateReport(projectId, inReport, signature) {
	return ({ firebase, removeEmpty, getState, dispatch, notificationServer, platformActions }) => {
		const getPromise = async () => {
			var report = {...inReport};

			if (!report.dateTS)
				throw new Error('Cannot update report with no date');
				
			report.updatedTS = new Date().getTime();
			report = removeEmpty(report, 'updateReport1');

			// Reduce assignTo user metadata
			if (report.owner)
				report.owner = removeEmpty({ id: report.owner.id, displayName: report.owner.displayName }, 'updateReport2')

			if (report.approver)
				report.approver = removeEmpty({id: report.approver.id, displayName: report.approver.displayName, workingHours:report.approver.workingHours})

			if (report.competents)
				Object.values(report.competents).forEach(competent => report.competents[competent.id] = removeEmpty({ id: competent.id, displayName: competent.displayName }, 'updateReport3'))
					
			// If this is the approval
			var isApproval = inReport && inReport.type != reportTypes.ROUTINE_CHECKLIST && inReport.status == REPORT_STATE_CLOSED && !inReport.signature;
			if (isApproval) {
				report.signature = {userId: getState().users.viewer.id}
				if (signature) {
					var sigUri = await uploadImage({uri:signature.uri}, 'sig_' + Date.now(), 'reports/' + projectId + '/daily/');
					report.signature['uri'] = sigUri;
				}
			}

	    var updates = {};
			updates['reports/full/' + projectId + '/' + report.id] = report;
			await firebase.update(updates)

			var lang = getState().app.lang;

			if (isApproval) {
				try {
					await platformActions.net.fetch(notificationServer + '/pdfReport', { 
						method: 'POST', 
						headers: { 'Content-Type' : 'application/json', 'Accept' : 'application/json'}, 
						body: JSON.stringify({
							'projectId': projectId,
							'reportId': inReport.id,
							'reportType': 'DailyReport',
							'lang': lang
						})});
				}
				catch (error) {
					throw new ExtraError('updateReport error', {projectId, inReport, signature}, error)     
				}
			}

			return { report, projectId };
		};

		return {
			type: UPDATE_REPORT,
			payload: getPromise()
		};
	};
}

export function deleteReport(projectId, reportId) {
	return ({ firebase }) => {
		const getPromise = async () => {

			var updates = {};
			updates['reports/full/' + projectId + '/' + reportId + '/updatedTS'] = true;
			updates['reports/full/' + projectId + '/' + reportId + '/isDeleted'] = true;
			await firebase.update(updates)

			return { reportId, projectId };
		};

		return {
			type: DELETE_REPORT,
			payload: getPromise()
		};
	};
}