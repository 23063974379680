import * as actions from './actions';
import * as authActions from '../auth/actions';
import * as contactsActions from '../contacts/actions';
import * as stagesActions from '../stages/actions';
import * as checklistsActions from '../checklists/actions';
import * as postsActions from '../posts/actions';
import * as companiesActions from '../companies/actions'
import * as pdfActions from '../pdf/actions';
import * as propInstancesActions from '../propertiesInstances/actions';
import * as propertiesTypesActions from '../propertiesTypes/actions'
import systemMessages from '../app/systemMessages';
import { Map } from 'immutable';
import { Record } from '../transit';
import _ from 'lodash';

const GENERAL_LOADING_MODAL = 'general';
const loadingTimeout = 1000 * 5;

const InitialState = Record({
  online: false,
  storageCleaned: 0,
  storageLoaded: false,
  failedQueries: Map(),
  navNextFunc: null,
  status: '0',
  toast: null,
  alert: null,
  loading: false,
  loadingMap: Map(),
  lang: null,
  rtl: null,
  lastProjectScreen: 'posts',
  intl: null,
  isConnected: true,
  canceledOperations: Map(),
  setsAndTemplatedOptionsDidLoad: false,
  setsOptions: {},
  templatesOptions: {},
  objectsSourcesMap: {}
}, 'app');

export const rtlLanguages = {
  'he' : true,
  'ar' : true
}

export default function appReducer(state = new InitialState, action) {
  if (process.env.NODE_ENV !== 'production' && action.type != "REDUX_STORAGE_SAVE" && action.type != 'GET_NATIVE_CONTACTS_COMPLETED' && action.type != "CHECK_PERMISSIONS")
    try {
      // console.log('Action Type: ' + action.type, ' payload:' + JSON.stringify(action.payload || {}));
    } catch (e) {
      console.log('Action Type: ERROR action is not a json');
    }
    

  switch (action.type) {
    case actions.APP_START + '_SUCCESS': {
      // if (didStart)
      //   return state;
        
      if (!action.payload)
        return state;
        
        // TODO: Ask or about his
      const { lang, didChanged, didStart } = action.payload;
      
      if (didStart)
        return;

      if (didChanged) {
        state = state.set('lang', lang);
        state = state.set('rtl', Boolean(rtlLanguages[lang]))
      }
      return state;
    }

    case actions.SET_APP_INTL: {
      if (!action.payload)
        return state;
      
      const { intl } = action.payload;

      if (intl)
        state = state.set('intl', intl);
      
      return state;
    }

    case actions.SET_LANG: {
      if (!action.payload)
        return state;
      const { lang } = action.payload;
      state = state.set('rtl', Boolean(rtlLanguages[lang]))
      return state.set('lang', lang);
    }

    case actions.APP_STORAGE_NOT_LOAD: {
      return state.set('storageLoaded', true);
    }

    case actions.CLEAN_DYNAMIC_CACHE_DATA + "_SUCCESS": {
      return state.set('storageCleaned', state.get('storageCleaned') + 1);
    }

    // Status change for login
    case authActions.SMS_VERIFICATION_SENT + "_SUCCESS":
    case authActions.START_SMS_LISTENER:
      return state.set('status', '1');

    case contactsActions.GET_NATIVE_CONTACTS + "_SUCCESS":
      return state.set('status', '2');

    // case contactsActions.NATIVE_CONTACTS_LOADED:
    //   return state.set('status', '3');

    case actions.START_TOAST: {
      const { toast } = action.payload;
      return state.set('toast', toast);
    }

    case actions.START_ALERT: {
      const { alert } = action.payload;
      return state.set('alert', alert);
    }

    case actions.HIDE_TOAST:
      return state.set('toast', null);

    case actions.START_LOADING: {
      const { toast, overlay, hideOnBackgroundPress, cancelOnBackgroundPress, operationId = GENERAL_LOADING_MODAL, startTS } = action.payload;
      let newLoadingObject = { show: true, toast, overlay, hideOnBackgroundPress, cancelOnBackgroundPress, operationId, startTS };

      if (state.getNested(['loadingMap', operationId]))
        return state;

      state = state.setNested(['loadingMap', operationId], newLoadingObject);
      return state.set('loading', newLoadingObject);
    }    
    
    case postsActions.EXPORT_POST_AS_PDF: 
    case checklistsActions.EXPORT_CHECKLIST_AS_PDF: 
    case checklistsActions.GET_CHECKLIST_STATUS_JSON: 
    case stagesActions.SAVE_STAGES:
    case checklistsActions.SAVE_CHECKLISTS: 
    case checklistsActions.DUPLICATE_CHECKLIST:
    case checklistsActions.EDIT_CHECKLIST_EXTRA_DATA:
    case checklistsActions.REMOVE_DUPLICATED_CHECKLIST:
    case checklistsActions.GET_CHECKLISTS_TEMPLATES:
    case stagesActions.SAVE_STAGES + 'ERROR':
    case checklistsActions.SAVE_CHECKLISTS + 'ERROR': 
    case checklistsActions.DUPLICATE_CHECKLIST + 'ERROR':
    case checklistsActions.EDIT_CHECKLIST_EXTRA_DATA + 'ERROR':
    case checklistsActions.REMOVE_DUPLICATED_CHECKLIST + 'ERROR':
    case checklistsActions.GET_CHECKLISTS_TEMPLATES + 'ERROR':
    case propInstancesActions.UPDATE_PROPERTIES_INSTANCE + 'ERROR':
    case propInstancesActions.REMOVE_PROPERTIES_INSTANCE + 'ERROR':
    case propertiesTypesActions.SAVE_PROPERTIES + 'ERROR':
    case propertiesTypesActions.PUT_SELECTION_LIST_OPTION + "ERROR":
    case propertiesTypesActions.DELETE_SELECTION_LIST_OPTION + "ERROR":
    case companiesActions.CREATE_NEW_COMPANY:    
    case pdfActions.DOWNLOAD_PDF: {
      const { success } = action.payload
      if (action.payload.hasOwnProperty('success') && !success) {
        var alert = {message:systemMessages.error, title: systemMessages.connectionError, date: Date.now() }
        state = state.set('alert', alert);
        state = state.set('loadingMap', state.get('loadingMap').clear());
        state = state.set('loading', false);
      }

      return state;
    }

      
    case actions.HIDE_ALL_LOADING: {
      state = state.set('loadingMap', state.get('loadingMap').clear());
      return state.set('loading', false);
     }

    case actions.HIDE_LOADING:
    case actions.LOADING_TIMEOUT:
    case postsActions.EXPORT_POST_AS_PDF + "_SUCCESS": 
    case checklistsActions.EXPORT_CHECKLIST_AS_PDF + "_SUCCESS": 
    case pdfActions.EXPORT_FORM_AS_PDF + "_SUCCESS": 
    case checklistsActions.GET_CHECKLIST_STATUS_JSON + "_SUCCESS":
    case stagesActions.SAVE_STAGES + "_SUCCESS":
    case checklistsActions.SAVE_CHECKLISTS + "_SUCCESS":
    case checklistsActions.DUPLICATE_CHECKLIST + "_SUCCESS":
    case checklistsActions.REMOVE_DUPLICATED_CHECKLIST + "_SUCCESS":
    case checklistsActions.EDIT_CHECKLIST_EXTRA_DATA + '_SUCCESS':
    case checklistsActions.GET_CHECKLISTS_TEMPLATES + "_SUCCESS":
    case companiesActions.CREATE_NEW_COMPANY + "_SUCCESS":
    case propInstancesActions.UPDATE_PROPERTIES_INSTANCE + "_SUCCESS":
    case propInstancesActions.REMOVE_PROPERTIES_INSTANCE + "_SUCCESS":
    case propertiesTypesActions.SAVE_PROPERTIES + "_SUCCESS":
    case propertiesTypesActions.PUT_SELECTION_LIST_OPTION + "_SUCCESS":
    case propertiesTypesActions.DELETE_SELECTION_LIST_OPTION + "_SUCCESS":
    case pdfActions.DOWNLOAD_PDF + "_SUCCESS": {
      const operationId = _.get(action, ['payload','operationId'], GENERAL_LOADING_MODAL);
      state = state.deleteIn(['loadingMap', operationId]);
      let latestLoadingObject = state.get('loadingMap').sort(l => l.startTS).first() || false;
      return state.set('loading', latestLoadingObject);
    }

    case actions.UPDATE_CONNECTION_STATUS: {
      const { isConnected } = action.payload
      return state.set('isConnected', isConnected);
    }
    
    case actions.CANCEL_OPERATION: {
      if (action.payload.operationId) {
        const { operationId } = action.payload;
        return state.setIn(['canceledOperations', operationId], true);
      }
    }

    case actions.GET_SETS_AND_TEMPLATES + "_SUCCESS": {
      const { sets = {}, templates = {} } = action.payload;
      state = state.set('setsAndTemplatedOptionsDidLoad', true);
      state = state.set('setsOptions', sets);
      state = state.set('templatesOptions', templates)
    
      return state;
    }

    case actions.UPDATE_OBJECTS_SOURCES_MAP: {
      const { subjectType, subjectName, newObjectsSourcesMap } = action.payload; 
  
      let path = ['objectsSourcesMap', subjectType];

      if (subjectName) path.concat(subjectName);

      const currentObjectsSourcesMap = state.getNested(path);

      if (_.isEqual(currentObjectsSourcesMap, newObjectsSourcesMap)) return state;
      else state = state.setNested(path, newObjectsSourcesMap)

      return state;
    }
  }

  return state;
}
