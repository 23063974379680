import mapFirebaseUserToAppUser from '../lib/redux-firebase/mapFirebaseUserToAppUser';
import { startToast } from '../app/actions';
import usersMessages from './usersMessages';
import ExtraError from '../lib/errors/extraError';
import { onError } from '../app/funcs';
import _ from 'lodash';

export const ON_USERS_PRESENCE = 'ON_USERS_PRESENCE';
export const GET_USER = 'GET_USER';
export const GET_USER_STARTED = 'GET_USER_STARTED';
export const UPLOAD_AVATAR_STARTED = 'UPLOAD_AVATAR_STARTED';
export const UPLOAD_MY_AVATAR = 'UPLOAD_MY_AVATAR';
export const UPDATE_MY_BACKGROUND = 'UPDATE_MY_BACKGROUND';
export const UPDATE_MY_METADATA = 'UPDATE_MY_METADATA';
export const SET_ADMIN_MODE = 'SET_ADMIN_MODE';
export const ON_PROFILE_UPDATE = 'ON_PROFILE_UPDATE';
export const AUTH0_SAVE_USER = 'AUTH0_SAVE_USER';
export const SET_USER_GROUPS = 'SET_USER_GROUPS';
export const SET_LAST_LOCATION_REPORTED = 'SET_LAST_LOCATION_REPORTED';
export const SET_LAST_VISITED_PROJECT_ID = 'SET_LAST_VISITED_PROJECT_ID';
export const GET_MY_USER_DETAILS_SENT = 'GET_MY_USER_DETAILS_SENT';
export const GET_MY_USER_DETAILS = 'GET_MY_USER_DETAILS';
export const SEND_NEW_USER_EMAIL = 'SEND_NEW_USER_EMAIL';

export const cementoTeamIds = {
  'sms|57b07abce618d33da2fe3f10': true, // Tomer
  'sms|57c707ffe618d33da210c477': true, // Dov
  'sms|5cf3aabb2d5f842a1600b256': true, // Idan
  'sms|5d023f8c027519401347859b': true, // Yaara  
  'sms|61d6dad2d6496fcf665097de': true, // Nimrod
  'sms|5e369e8411484d6634f61cf4': true, // Ilann
  'sms|615416facb7c3b76c698637d': true, // Cemento iPhone 11 Red
  'sms|61dc3851d6496fcf6627331d': true, // Ivan
  'sms|61e5b699d6496fcf66de6dfa': true, // Yonatan
  'sms|62138474c1c7f4327ef646b6': true, // Lihi
  'sms|62389c05c3befb7f0a9c41c2': true, // Ron
  'sms|6230435a800664d95b0e7d3b': true, // Ohad
  'sms|62a5c092948d7066d58e3cd0': true, // Zohar
  'sms|62a5c2bc948d7066d5bc418f': true, // Anat
  'sms|62823b141e2b004f28a00d3e': true, // Eden
  'sms|625d7429000d34e36f799d64': true, // Ron Staging
  'sms|619f4a9ad6496fcf66941353': true, // Slava
  'sms|63f3414adce7a1aa1219f33e': true, // YUVAL
  'sms|639ada30344ca9b2853ca5b9': true, // Hadar 
  'sms|643ab32b498264565636b1dd': true, // Refael
  'sms|6450ecbac6ceba04271f7a3b': true, // Tamar
  'sms|6450e420c6ceba0427881313': true, // Mikka
  'sms|6439313c4982645656a297a9': true, // Erez
  'sms|64ae2c928171bbdfe33efa4d': true, // Maor
  'sms|5c0d26b5e8fd56b4e522f787': true, // Roee
};

// Get the user info from Auth0 user_metadata
export function getMyUserInfo(id_token, callerApi, viewer, auth0_data) {
  return ({ dispatch, platformActions, apiServer, errorReport, getState }) => {
    const getPromise = async () => {

      try {
        let resp = await platformActions.net.fetch(apiServer + '/v1/services/users/tokeninfo', {
          method: 'POST',
          body: JSON.stringify({ id_token })
        });

        var response = await (resp.getJson());

        if (!response.user_metadata && !response.user_id || response.error) {
          throw new ExtraError('user_metadata is missing.', { user_metadata: response.user_metadata, user_id: response.user_id, serverError: response.error, id_token, callerApi, viewer, auth0_data });
        }
        var user = response.user_metadata || {}; // In case of a new user
        user.id = response.user_id;
        const userPhoneNumber = _.get(response, ['phone_number']);

        var mixpanelIgnore = Boolean(getState().users.originViewer);
        dispatch({ type: GET_MY_USER_DETAILS, payload: { mixpanelIgnore, user, scopeKey: 'global', immediateGlobalStorageSave: true, userPhoneNumber } });

        return user;

      } catch (err) {
        // TODO: Check if in case of  error, the err may contain only a string with "Unauthorized" so it will unable to parse it to JSON later
        errorReport('GET_MY_USER_DETAILS_SENT', new ExtraError('getMyUserInfo error', null, err));
      }
    };
    return {
      type: GET_MY_USER_DETAILS_SENT,
      payload: getPromise()
    };
  };
}


export function updateMyUserMetadata(key, value) {
  return ({ getState, dispatch, platformActions, apiServer }) => {
    const getPromise = async () => {
      if (!key)
        throw new Error('Missing update key for value ' + value);

      var id_token = getState().auth.authToken;
      var userId = getState().auth.userId;

      let user_metadata = {};
      user_metadata[key] = value;
      var bodyString = JSON.stringify(user_metadata);

      if (!userId || !getState().users || !getState().users.viewer)
        return { key, newValue: value };

      try {
        var response = await platformActions.net.fetch(encodeURI(`${apiServer}/v1/users/` + userId), {
          method: 'PATCH',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + id_token
          },
          body: bodyString
        });

        if (usersMessages.updateSuccess[key])
          dispatch(startToast({ title: usersMessages.updateSuccess[key] }));

        return { user_metadata: user_metadata, key, newValue: value, myId: getState().users.viewer.id };

      } catch (error) {
        console.log(error);
        if (usersMessages.updateFailed[key]) {
          dispatch(startToast({ title: usersMessages.updateFailed[key] }));
        }
        return {};
      }
    };

    return {
      type: UPDATE_MY_METADATA,
      payload: getPromise(),
    };
  };
};

// TODO: Merge this and the initialProfile togther
export function updateProfile(id, fields, scopeFields, scopeKey) {
  return ({ validate, dispatch, removeEmpty }) => {

    const getPromise = async () => {

      await validate({ id, ...fields })
        .prop('id')
        .required()

        .prop("displayName")
        .required().promise;

      const user = mapFirebaseUserToAppUser({
        uid: id,
        ...fields,
        ...scopeFields,
      });
      dispatch(saveUser(user, id, scopeKey));
      return { user: removeEmpty(user, 'updateProfile'), scopeKey, initial: true };
    };
    return {
      type: ON_PROFILE_UPDATE,
      payload: getPromise(),
    };
  };
}

export function saveUser(user_metadata, userId, scopeKey) {
  return ({ removeEmpty, apiServer, platformActions, getState }) => {
    const getPromise = async () => {
      let userDetails = user_metadata.toJS
        ? user_metadata.toJS()
        : Object.assign({}, user_metadata);
      userDetails = removeEmpty(userDetails, 'saveUser');
      userDetails.lang = getState().app.lang;
      delete userDetails.projects;
      try {
        let response = await (platformActions.net.fetch(apiServer + '/v1/users/' + encodeURIComponent(userId), {
          method: 'PUT',
          body: JSON.stringify({
            'uid': userId,
            'scopeKey': scopeKey,
            'userDetails': userDetails
          })
        }));

        let userRet = await (response.getJson());
        return ({ user: userRet.user_metadata });
      }
      catch (error) {
        console.log(error);
      };
    };
    return {
      type: AUTH0_SAVE_USER,
      payload: getPromise()
    };
  };
};

export function setAdminMode(newMode) {
  return ({ dispatch }) => {
    return {
      type: SET_ADMIN_MODE,
      payload: { newMode, immediateGlobalStorageSave: true },
    };
  };
}

export function setLastLocationReported(lastLocationReported, viewer) {
  return ({ dispatch, platformActions }) => {
    const getPromise = async () => {
      try {
        if (viewer)
          var userId = viewer.displayName + ' / ' + viewer.id;

        await (platformActions.net.fetch('https://cemento-location-staging.herokuapp.com/locations', {
          'method': 'POST',
          'headers': { 'Content-Type': 'application/json', 'Accept': 'application/json' },
          'body': JSON.stringify({
            location: lastLocationReported,
            userId,
            device: {
              model: platformActions.app.getModel(),
              uuid: platformActions.app.getUniqueID()
            }
          })
        }));
      }
      catch (error) {
        console.warn(error);
      }
      finally {
        dispatch({ type: SET_LAST_LOCATION_REPORTED, payload: { lastLocationReported } });
      }

      return { lastLocationReported };
    };
    return {
      type: SET_LAST_LOCATION_REPORTED,
      payload: getPromise()
    };
  };
}

export function setLastVisitedProject(currentProjectIdInUse, newVisitedProject, lastVisitedProject, didEnterProject, missedLeavingSite, isPhysicalSite) {
  return {
    type: SET_LAST_VISITED_PROJECT_ID,
    payload: { currentProjectIdInUse, newVisitedProject, lastVisitedProject, didEnterProject, missedLeavingSite, isPhysicalSite, immediateGlobalStorageSave: true }
  };
}

export function onUsersPresence(snap) {
  const presence = snap.val();

  return {
    type: ON_USERS_PRESENCE,
    payload: { presence },
  };
}

// Send email upon new user registretion
export function sendNewUserEmail(viewer) {
  return ({ platformActions, apiServer, errorReport }) => {
    const getPromise = async () => {

      var debug = process.env.NODE_ENV !== 'production';
      var user = {
        "displayName": viewer.displayName,
        "phoneNumber": viewer.phoneNumber,
        "title": viewer.title,
        "companyType": viewer.companyType,
        "companyName": viewer.companyName,
        "info": viewer.info
      };

      try {
        let resp = await platformActions.net.fetch(apiServer + '/v1/services/users/newUserRegistrationEmail', {
          method: 'POST',
          body: JSON.stringify({ debug, user })
        });
      } catch (err) {
        // TODO: Check if in case of  error, the err may contain only a string with "Unauthorized" so it will unable to parse it to JSON later
        console.log("sentEmail error: ", err);
        errorReport('sendNewUserEmail error', new ExtraError('sendNewUserEmail error', null, err));
      }
    };
    return {
      type: SEND_NEW_USER_EMAIL,
      payload: getPromise()
    };
  };
}

export function setUserGroups({ userId, newGroups, projectId }) {
  return ({ apiServer, platformActions }) => {
    const getPromise = async () => {
      let requestBody;
      if (projectId) {
        const userDataResponse = await (platformActions.net.fetch(`${apiServer}/v1/users?ids=["${userId}"]&fields=["user_metadata"]`, {
          method: 'GET'
        }));
        const userData = await (userDataResponse.getJson());
        const projectData = _.get(userData, [userId, 'user_metadata', 'projects', projectId]);
        requestBody = {
          'id': userId,
          'projects': {
            [projectId]: {
              'scopeDetails': {
                ...(projectData || {}),
                'groups': newGroups
              }
            }
          }
        };
      }
      else {
        requestBody = {
          'id': userId,
          'groups': newGroups,
        };
      }
      try {
        const response = await (platformActions.net.fetch(`${apiServer}/v1/users`, {
          method: 'PUT',
          body: JSON.stringify(requestBody)
        }));
        const userRet = await (response.getJson());
        return ({ user: userRet.user_metadata });
      }
      catch (error) {
        onError({
          errorMessage: 'Failed to set user groups',
          error,
          methodMetaData: {
            name: 'setUserGroups',
            args: { userId, projectId, newGroups },
          },
        });
        throw error;
      };
    };
    return {
      type: SET_USER_GROUPS,
      payload: getPromise()
    };
  };
}
// const tradeObjectsToArray = (user) => {
//   if (!user)
//     return user;

//   var retUser = Object.assign({}, user);
//   if (user.trades)
//     retUser.trades = Object.keys(user.trades);

//   if (user.projects)
//     Object.keys(user.projects).forEach(userProjectId => {
//       if (retUser.projects[userProjectId].trades)
//         retUser.projects[userProjectId].trades = Object.keys(retUser.projects[userProjectId].trades);
//     })

//   return retUser;
// }