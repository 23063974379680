import { defineMessages } from 'react-intl';

export default defineMessages({
  empty: {
    defaultMessage: 'No trades found',
    id: 'trades.trade.empty'
  },
  loading: {
    defaultMessage: 'Loading trades',
    id: 'trades.trade.loading'
  }, 
  select: {
    defaultMessage: 'Member\'s trade',
    id: 'trades.trade.select'
  },
  search: {
    defaultMessage: 'Search for a trade',
    id: 'trades.trade.search'
  }, 
  generalTrades: {
    defaultMessage: 'General trades',
    id: 'trades.trade.generalTrades'
  }, 
  suppliersAndSubContractors: {
    defaultMessage: 'Suppliers And SubContractors',
    id: 'trades.trade.suppliersAndSubContractors'
  },
  reviewsCounter: {
    defaultMessage: '{counter} Reviews',
    id: 'trades.trade.reviewsCounter'
  },
  call: {
    defaultMessage: 'Call',
    id: 'trades.buttons.call'
  },
  message: {
    defaultMessage: 'Message',
    id: 'trades.buttons.message'
  },
  recentlyUsed: {
    defaultMessage: 'Recently used trades',
    id: 'trades.sections.recentlyUsed'
  },
  projectsTrades: {
    defaultMessage: 'Project\'s trades',
    id: 'trades.sections.projectsTrades'
  },
  moreTrades: {
    defaultMessage: 'MORE TRADES',
    id: 'trades.sections.moreTrades'
  },
});
