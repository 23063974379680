import { getAllLocationTitlesMap } from '../Locations/funcs'
import { platformActions } from '../../../common/platformActions';
import { envParams } from '../../../common/configureMiddleware';
import * as propertyTypes from '../../../common/propertiesTypes/propertiesTypes';
import { getArrayTotalCount, getArrayNameProp, getNestedInnerTypesMap, isArrayOfArraysProp } from '../../../common/propertiesTypes/ArrayComponentHOC';
import { instanceDataToStringNoIntl } from '../../../common/propertiesInstances/funcs';
import _ from 'lodash';
import { isEmptyValue } from '../../../common/app/funcs';
import { safeToJS } from '../../../common/permissions/funcs';
import moment from 'moment';
import systemMessages from '../../../common/app/systemMessages';

export function getLocationsObjectsForTable(buildings, floors, units, intl) {
	if (!buildings || !floors || !units) 
		return null;

	let locationsInfoMap = getAllLocationTitlesMap(buildings, floors, units, intl);
	let locationObject = {};
	Object.keys(locationsInfoMap).forEach(locId => {
		let locationInfoObj = locationsInfoMap[locId];
		let locationMeta = {};
		if (locationInfoObj.type == 'floors') return;
		if (locationInfoObj.type == 'buildings') locationMeta = { id: locId, title: locationInfoObj.buildingTitle, num: locationInfoObj.ordinalNo, type: 'building', rowLevel: 1,};
		else if (locationInfoObj.type == 'units') locationMeta = { id: locId, title: locationInfoObj.floorTitle + ' | ' + locationInfoObj.unitTitle, num: locationInfoObj.ordinalNo, parentId: locationInfoObj.buildingId, type: 'unit', rowLevel: 2 };
		
		locationObject[locId] = locationMeta;
	})

	return locationObject;
}


const getComplexColumnsData = (propertiesTypes, prop, propInstance, isExpandedView) => {
	let columns = [];

	_.keys(_.get(prop, 'innerTypes')).forEach(innerTypeId => {
		let innerType = _.get(propertiesTypes, [innerTypeId], null);
		if (innerType) {
			const innerTypeData = _.get(propInstance, ['data', innerTypeId], null);
			const innerTypeInstance = Object.assign({}, propInstance, { data: innerTypeData, propType: innerType.type, propId: innerType.id });
			const { columns: innerTypeColumns } = getPropColumnsData(propertiesTypes, innerType, innerTypeInstance, isExpandedView);
			columns = columns.concat(innerTypeColumns);
		}
	});

  return columns.sort((a, b) => a.ordinalNo - b.ordinalNo);
}

const getPropFromStructValue = (propertiesTypes, struct) => {
  let prop = null;

  if (_.get(struct, 'propId'))
    prop = _.get(propertiesTypes, [struct.propId], null);
  else if (_.get(struct, 'tag'))
    prop = _.find(propertiesTypes, prop => _.get(prop, ['tags', struct.tag], false));

  return prop;
}

const getArrayCollapsedColumnsData = (propertiesTypes, prop, propInstance) => {
  let columns = {};
  
  const tableUIStruct = _.get(prop, ['UIStruct', 'table', 0, 'collapsed'], {});

  if (_.isEmpty(tableUIStruct.columns))
    return _.values(columns);

  let currOrdinalNo = 1;

  const innerTypesMap = getNestedInnerTypesMap(propertiesTypes, prop);
  const columnsTitleProp = getPropFromStructValue(innerTypesMap, _.get(tableUIStruct, 'columns.title')) || {};
  const columnsValueProp = getPropFromStructValue(innerTypesMap, _.get(tableUIStruct, 'columns.value')) || {};
  
  const localPrimaryTitle = 
		(tableUIStruct.primaryColumn || {}).getCementoTitle() || 
		({ title: columnsValueProp.settings?.columnDisplayTitle }).getCementoTitle() ||
		columnsValueProp.getCementoTitle();
  const localPrimaryColumn = {
    id: prop.id,
    title: localPrimaryTitle,
    ordinalNo: currOrdinalNo,
		sectionId: prop.sectionId,
		defaultValue: 0,
		isDynamicallyGenerated: true,
  };
  columns[localPrimaryColumn.id] = localPrimaryColumn;
  currOrdinalNo++;

  

  const instanceData = _.get(propInstance, 'data');
  const instanceParentId = _.get(propInstance, 'parentId');
  const isArrayOfArrays = isArrayOfArraysProp(propertiesTypes, prop);
  const { count: primaryColumnValue, counterProp } = getArrayTotalCount(propertiesTypes, prop, instanceData);
  const arrayNameProp = isArrayOfArrays ? getArrayNameProp(prop) : null;
  _.values(instanceData).forEach(_data => {
		if (_data.isDeleted)
			return;

    const dataProp = _.get(propertiesTypes, _data.propId);
    const innerData = _data.data;

    let columnTitleData = null;
    let columnValueData = null;
    let columnTitleDataString = null;

		switch (_.get(dataProp, 'type')) {
			case propertyTypes.ARRAY: {
				columnTitleData = _data.title;
				columnValueData = getArrayTotalCount(propertiesTypes, dataProp, innerData).count;
				columnTitleDataString = instanceDataToStringNoIntl(arrayNameProp, columnTitleData);
				break;
			}

			case propertyTypes.COMPLEX: {
				columnTitleData = _.get(innerData, columnsTitleProp.id);
				columnValueData = Number(counterProp ? (_.get(innerData, columnsValueProp.id) || 0) : 1);
				columnTitleDataString = instanceDataToStringNoIntl(columnsTitleProp, columnTitleData);
				break;
			}

			default: {
				columnTitleData = innerData;
				columnValueData = 1;
				columnTitleDataString = instanceDataToStringNoIntl(dataProp, columnTitleData);
				break;
			}
		}

		if (columnTitleDataString) {
			const columnId = `${prop.id}_${columnTitleDataString}`;
			if (!columns[columnId]) {
				columns[columnId] = {
					id: columnId,
					title: columnTitleDataString,
					ordinalNo: currOrdinalNo,
					sectionId: prop.sectionId,
					isDynamicallyGenerated: true,
				};
				currOrdinalNo++;
			}

			if (!isEmptyValue(columnValueData)) {
				if (!_.get(columns, [columnId, 'rows', instanceParentId]))
					_.set(columns, [columnId, 'rows', instanceParentId], { 
						id: instanceParentId, 
						value: columnValueData,
						valueOrigin: innerData,
						parentValueOrigin: instanceData,
					});
				else
					columns[columnId].rows[instanceParentId].value += columnValueData;
			}
		}
  }); 

  _.set(columns, [localPrimaryColumn.id, 'rows', instanceParentId], { 
		id: instanceParentId, 
		value: primaryColumnValue,
		valueOrigin: instanceData,
	});

  return _.values(columns).sort((a, b) => a.ordinalNo - b.ordinalNo);
}

const IN_BETWEEN_COLUMN_ORDINAL_NO_DIVIDER = 100000;

const getArrayExpandedColumnsData = (propertiesTypes, prop, propInstance) => {
	const isExpandedView = true;
	const isArrayOfArrays = isArrayOfArraysProp(propertiesTypes, prop);

	let columns = {};
	const instanceParentId = _.get(propInstance, 'parentId');
	const instanceData = _.get(propInstance, 'data');
	const tableUIStruct = _.get(prop, ['UIStruct', 'table', 0, 'expanded'], {});
	const innerTypesMap = getNestedInnerTypesMap(propertiesTypes, prop);
  const arrayNameProp = isArrayOfArrays ? getArrayNameProp(prop) : null;
	const localPrimaryColumnTitleProp = (
		isArrayOfArrays && arrayNameProp 
			? arrayNameProp 
			: (getPropFromStructValue(innerTypesMap, _.get(tableUIStruct, 'primaryColumn.title')) || {})
	);
	const localPrimaryColumnValueProp = (
		isArrayOfArrays && arrayNameProp
			? arrayNameProp
			: (getPropFromStructValue(innerTypesMap, _.get(tableUIStruct, 'primaryColumn.value')) || {})
	);
	const primaryColumnTitle = (
		(tableUIStruct.primaryColumn || {}).getCementoTitle() ||
		({ title: localPrimaryColumnTitleProp.settings?.columnDisplayTitle }).getCementoTitle() ||
		localPrimaryColumnTitleProp.getCementoTitle()
	);

	let { counterProp: counterColumnProp } = getArrayTotalCount(innerTypesMap, prop, instanceData);
	counterColumnProp = counterColumnProp || {};
	
	let currOrdinalNo = 1;
	let localPrimaryColumn = null;
	if (!_.isEmpty(primaryColumnTitle)) {
		localPrimaryColumn = {
			id: localPrimaryColumnValueProp.id,
			title: primaryColumnTitle,
			ordinalNo: currOrdinalNo,
			sectionId: localPrimaryColumnValueProp.sectionId,
			isDynamicallyGenerated: true,
			values: localPrimaryColumnValueProp.values || null,
			type: localPrimaryColumnValueProp.type,
		};
		columns[localPrimaryColumn.id] = localPrimaryColumn;
		currOrdinalNo++;
	}

	let counterColumn = null;
	if (!_.isEmpty(counterColumnProp)) {
		counterColumn = {
			id: counterColumnProp.id,
			title: counterColumnProp.getCementoTitle(),
			ordinalNo: currOrdinalNo,
			sectionId: counterColumnProp.sectionId,
			isDynamicallyGenerated: true,
			type: counterColumnProp.type,
			rows: {
				// [instanceParentId]: {
				// 	id: instanceParentId,
				// 	value: 0, // Calculated in TableWrapper because it needs to change dynamically with filters
				// }
			}
		};
		columns[counterColumn.id] = counterColumn;
		currOrdinalNo++;
	}

	_.values(instanceData).forEach(_data => {
		if (_data.isDeleted)
			return;

		const innerDataId = _data.id;
		const dataProp = _.get(propertiesTypes, _data.propId);
		const innerData = _data.data;

		const instance = {
			id: `${instanceParentId}_${innerDataId}`,
			data: innerData,
			parentId: innerDataId,
			propId: _data.propId,
			propType: dataProp.type,
		};

		const { columns: propColumns } = getPropColumnsData(propertiesTypes, dataProp, instance, isExpandedView);
		propColumns.forEach(column => {
			if (isArrayOfArrays && counterColumn && column.id === counterColumn.id) // Skip the inner array counter row when it's an array of arrays
				delete column.rows[instance.parentId];

			if (!columns[column.id]) {
				column.ordinalNo = currOrdinalNo;
				column.isDynamicallyGenerated = true;
				columns[column.id] = column;
				currOrdinalNo++;
			}
			else
				columns[column.id].rows = Object.assign((columns[column.id].rows || {}), column.rows);
				
			if (isArrayOfArrays && localPrimaryColumn && _data.title) { // If array of arrays, set the array title value to the primary column
				_.values(column.rows).forEach(row => {
					if (!_.get(localPrimaryColumn, ['rows', row.id]))
						_.set(localPrimaryColumn, ['rows', row.id], {
							id: row.id,
							value: _data.title,
							valueOrigin: _data,
						});
				});
			}
		});
	});

	return _.values(columns).sort((a, b) => a.ordinalNo - b.ordinalNo);
};

const getArrayColumnsData = (propertiesTypes, prop, propInstance, isExpandedView) => 
  (isExpandedView 
    ? getArrayExpandedColumnsData 
    : getArrayCollapsedColumnsData
  )(propertiesTypes, prop, propInstance);

const getPropColumnsData = (subjectPropTypes, prop, propInstance, isExpandedView) => {
  let columns = [];

  switch (_.get(prop, 'type')) {
    case propertyTypes.COMPLEX:
      columns = getComplexColumnsData(subjectPropTypes, prop, propInstance, isExpandedView);
      break;
    case propertyTypes.ARRAY:
      columns = getArrayColumnsData(subjectPropTypes, prop, propInstance, isExpandedView);
      break;
    default:
			let column;
			if (prop) {
				const title = ({ title: prop.settings?.columnDisplayTitle }).getCementoTitle() || prop.getCementoTitle();
				column = {
					id: prop.id, 
					type: prop.type, 
					title,
					sectionId: prop.sectionId, 
					isPrimary: prop.isPrimary, 
					settings: prop.settings, 
					ordinalNo: prop.ordinalNo,
					businessType: prop.businessType, 
					universalId: prop.universalId,
					values: prop.values,
				};

				if (!_.isNil(_.get(propInstance, 'data')))
					_.set(column, ['rows', propInstance.parentId], {
						id: propInstance.parentId,
						value: propInstance.data,
						valueOrigin: propInstance.data,
					});
			}

			if (column)
				columns.push(column);
      break;
  }

  return { columns };
}

export function getPropertiesInfo({subjectProperties, subjectSections, subjectMappings, objects, showColumnEvenWhenNoDataExists, allProps, isExpandedView, pathInObjectProperties}) {
	if (!subjectProperties || !subjectSections || !subjectMappings || !objects)
		return null;

	let metaData = { objects:{}, properties:{}, sections: {} };
	
	Object.values(objects || {}).forEach(object => {
		metaData.objects[object.id] = object;
	});

	// Get sections metadata
	Object.values(subjectSections || {}).forEach(section =>
		metaData.sections[section.id] = { id: section.id, title: section.getCementoTitle()}
	);

	let propertiesOrdinalNo = {};
	let ordinalNoCounter = 0;
	subjectMappings.loopEach((groupingPropertyId, groupingPropertyGroups) => {
		groupingPropertyGroups.loopEach(groupId => {
			groupingPropertyGroups.getNested2([groupId,'properties'], []).forEach((mappedPropId, index) => {
				propertiesOrdinalNo[mappedPropId] = ordinalNoCounter;
				ordinalNoCounter ++;
			});
		});
	});
	
	subjectProperties = safeToJS(subjectProperties);
	if (pathInObjectProperties)
		subjectProperties = Object.assign({}, subjectProperties, pathInObjectProperties);

	const primaryColumnProp = _.find(subjectProperties, { isPrimary: true });
	const isDatePrimaryColumn = primaryColumnProp?.type === propertyTypes.DATE;
	let groupRowsData = {};
	let dataToRet = [];
	let primaryRows = {};
	let primaryColumnId = null;
	let generatedRows = {};
	let generatedColumnsAndRowsByOriginPropId = {};
	Object.entries(allProps || {}).forEach(([objectId, populatedObject]) => {
		let { props } = populatedObject;
		
		if (pathInObjectProperties) {
			const _object = objects ? _.get(objects, objectId, populatedObject) : populatedObject;
			props = Object.assign({}, 
				props, 
				_.values(pathInObjectProperties).reduce((acc, prop) => {
					acc[prop.id] = Object.assign({}, 
						prop, 
						{ 
							fullProp: prop, 
							data: _.get(_object, prop.pathInObject, null), 
							instanceId: `${objectId}_${prop.id}`,
							section: metaData.sections[prop.sectionId],
						}
					);

					return acc;
				}, {}),
			);
		}
		// add data to group props by month if primary column is a date property
		if (isDatePrimaryColumn) {
			const objectDateData = props[primaryColumnProp.id]?.data;
			if (objectDateData) {
				const monthTS = moment(objectDateData).startOf('month').valueOf();
				if (!groupRowsData[monthTS])
					groupRowsData[monthTS] = {};
				groupRowsData[monthTS][objectId] = { objectId, dateTS: objectDateData };
			}
		}

		Object.values(props || {}).forEach(populatedProp => {
			const { fullProp, data, type } = populatedProp;

			if (fullProp.isExtra)
				return;

			const currPropOrdinalNo = fullProp.ordinalNo || fullProp.ordinalNo === 0 ? fullProp.ordinalNo : propertiesOrdinalNo[fullProp.id];

			const instance = {
				id: populatedProp.instanceId,
				data,
				propId: fullProp.id,
				parentId: objectId,
				propType: type,
			};

			// Columns is an array of columns info for each property. Most properties will return only one column, but some may return more than one such as Array and Complex types.
			const { columns } = getPropColumnsData(subjectProperties, fullProp, instance, isExpandedView);

			let currOrdinalNo = currPropOrdinalNo;
			columns.forEach(column => {
				const isColumnDynamicallyGenerated = Boolean(column.isDynamicallyGenerated);
				if (!metaData.properties[column.id])
					metaData.properties[column.id] = {
						id: column.id,
						type: column.type,
						title: column.getCementoTitle(),
						isPrimary: Boolean(column.isPrimary),
						num: column.isPrimary ? 0 : currOrdinalNo,
						parentId: column.sectionId,
						universalId: column.universalId,
						settings: column.settings,
						isDynamicallyGenerated: isColumnDynamicallyGenerated,
						values: isColumnDynamicallyGenerated && column.type === propertyTypes.SELECTION_LIST ? column.values : null,
						originColumnId: fullProp.id,
						isAlwaysDisplayColumn: isColumnDynamicallyGenerated && !isExpandedView,
						pathInObject: fullProp.pathInObject,
						generalDisplayParams: _.get(fullProp, 'UIStruct.table[0].general'),
					};

				currOrdinalNo += (1 / IN_BETWEEN_COLUMN_ORDINAL_NO_DIVIDER);

				if (column.isPrimary)
					primaryColumnId = column.id;

				if (isColumnDynamicallyGenerated && !_.get(generatedColumnsAndRowsByOriginPropId, [fullProp.id, 'columns', column.id]))
					_.set(generatedColumnsAndRowsByOriginPropId, [fullProp.id, 'columns', column.id], column);

				
				let columnRows = _.values(column.rows);
				if (!columnRows.length)
					columnRows = [{ id: objectId }];

				_.values(columnRows).forEach(row => {
					if (isColumnDynamicallyGenerated && !_.get(generatedColumnsAndRowsByOriginPropId, [fullProp.id, 'rows', row.id]))
						_.set(generatedColumnsAndRowsByOriginPropId, [fullProp.id, 'rows', row.id], row);

					if (isColumnDynamicallyGenerated && !_.get(generatedColumnsAndRowsByOriginPropId, [fullProp.id, 'rowsPerColumn', column.id, row.id]))
						_.set(generatedColumnsAndRowsByOriginPropId, [fullProp.id, 'rowsPerColumn', column.id, row.id], row);

					if (isColumnDynamicallyGenerated && !_.get(generatedRows, row.id))
						generatedRows[row.id] = Object.assign({}, row, { originObjectId: objectId });

					if (!metaData.objects[row.id])
						metaData.objects[row.id] = Object.assign({}, metaData.objects[objectId], { id: row.id });

					const value = _.get(row, 'value', (!_.isNil(column.defaultValue) ? column.defaultValue : null));
					const rowData = {
						rowId: row.id, 
						columnId: column.id,
						values: [ value ],
						valueOrigin: row.valueOrigin,
						parentValueOrigin: row.parentValueOrigin,
					}

					if (column.isPrimary)
						_.set(primaryRows, [row.id], rowData);

					dataToRet.push(rowData);
				});
			});
		});
	});

	if (isDatePrimaryColumn && Object.keys(groupRowsData).length) {
		const ordinalNoPerMonthTS = Object.keys(groupRowsData)
			.sort((a, b) => Number(b) - Number(a))
			.reduce((acc, monthTS, index) => _.set(acc, monthTS, index + 1), {});
		Object.entries(groupRowsData).forEach(([monthTS, objectIds]) => {
			monthTS = Number(monthTS);
			const rowId = `-groupingDateRow_${monthTS}`;
			const rowData = {
				rowId,
				columnId: primaryColumnProp.id,
				values: [ monthTS ],
			}
			_.set(primaryRows, [rowId], rowData);
			dataToRet.push(rowData);

			const monthOrdinalNo = ordinalNoPerMonthTS[monthTS] * 100000;
			const someObjectId = objectIds[0]?.objectId;
			metaData.objects[rowId] = Object.assign({}, 
				metaData.objects[someObjectId], 
				{ 
					id: rowId, 
					num: monthOrdinalNo,
					_aggregatedValue: monthTS, 
					_aggregatedValueType: propertyTypes.DATE, 
					_aggregatedValueDisplayParams: { dateFormat: systemMessages.fullMonthAndYear },
				}
			);

			let currOrdinalNo = monthOrdinalNo + 1;
			const generatedObjectIds = Object.values(generatedRows)
					.filter(row => objectIds[row.originObjectId])
					.map(row => ({ objectId: row.id, dateTS: objectIds[row.originObjectId].dateTS }));
			const aggregatedObjectIds = [ ...Object.values(objectIds), ...generatedObjectIds ];
			aggregatedObjectIds
				.sort((a, b) => b.dateTS - a.dateTS)
				.forEach(({ objectId }) => {
				metaData.objects[objectId].parentId = rowId;
				metaData.objects[objectId].num = currOrdinalNo++;
			});
		});
	}

	// _.values(metaData.objects).forEach(object => {
	// 	if (primaryRows[object.id] || !(object.parentId && primaryRows[object.parentId]))
	// 		return;

	// 	dataToRet.push(Object.assign({}, primaryRows[object.parentId], { rowId: object.id }));
	// });

	if (!_.isEmpty(generatedRows))
		_.values(generatedRows).forEach(row => {
			if (!primaryRows[row.originObjectId] || row.id === row.originObjectId || !(row.originObjectId && primaryRows[row.originObjectId]))
				return;

			dataToRet.push(Object.assign(
				{},
				primaryRows[row.originObjectId],
				{ rowId: row.id },
			));
		});

	if (!_.isEmpty(generatedColumnsAndRowsByOriginPropId)) // For dynamically generated columns, we need to add the rows to all generated columns so that they wont display on the table as not mapped
		_.values(generatedColumnsAndRowsByOriginPropId).forEach(({ columns, rows, rowsPerColumn }) => {
			_.values(columns).forEach(column => {
				_.values(rows).forEach(row => {
					if (_.get(rowsPerColumn, [column.id, row.id]))
						return;

					const value = !_.isNil(column.defaultValue) ? column.defaultValue : null;
					dataToRet.push({
						rowId: row.id, 
						columnId: column.id,
						values: [ value ],
						valueOrigin: null,
					});
				});
			});
		});

	// Display table with no data
	if (showColumnEvenWhenNoDataExists && Object.keys(metaData.objects).length === 0) {
		subjectProperties.loopEach((propId, currProp) => {

			if (currProp.isExtra)
				return;
			
			const currOrdinalNo = currProp.ordinalNo || currProp.ordinalNo === 0 ? currProp.ordinalNo : propertiesOrdinalNo[propId];
			metaData.properties[propId] = { 
				id: propId, 
				type: currProp.type, 
				title: currProp.getCementoTitle(), 
				num: currProp.isPrimary ? 0 : currOrdinalNo,
				parentId: currProp.sectionId, 
				isPrimary: currProp.isPrimary, 
				settings: currProp.settings, 
				businessType: currProp.businessType, 
				universalId: currProp.universalId,
				generalDisplayParams: _.get(currProp, 'UIStruct.table[0].general'),
			};
		});
	}

  return { data: dataToRet, groupByMetaData: metaData };
}

export async function getMembers(projectsIds) {
  let res = await platformActions.net.fetch(`${envParams.apiServer}/v1/users?includeGroups=true&projectIds=[${(projectsIds || []).map(id => `"${id}"`).join(', ')}]`);
      res = await res.getJson();

  return res;
}

export async function getCompanies(projectIds) {
	let res = {};

	res = await platformActions.net.fetch(`${envParams.apiServer}/v1/companies?projectIds=[${(projectIds || []).map(id => `"${id}"`).join(', ')}]&returnOnlyRequestedProjects=false`);
	res = await res.getJson();
    
  return res;
}

export async function getObjectFromServer(objectId, scope) {
	let res = await platformActions.net.fetch(`${envParams.apiServer}/v1/${scope}/${objectId}`);
  res = await res.getJson();

  return res;
}