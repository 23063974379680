import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import _fp from 'lodash/fp';
import { useSelector } from 'react-redux';
import { decodeFiltersFromSearch } from '../../../web/app/funcs';
import { lokiInstance, realmInstance } from '../../configureMiddleware';
import { getFilteredResults } from '../../permissions/funcs';
import { ProjectContext } from '../../projects/contexts';
import { unknownCompanyId } from '../../companies/companiesTypes';
import { v4 as uuidv4 } from 'uuid';
import { platformActions } from '../../platformActions';
import { POSTS_FILTER_URL_KEY } from '../../../web/app/constants';

const setCompanyIdAndGroupsToOwnerAndAssignToOnPosts = (posts, members) => {
	return Object.values(posts || {}).map(post => {
		['owner', 'assignTo'].forEach(memberPropKey => {
			const memberId = _.get(post, [memberPropKey, 'id']);
			if (!memberId) return;

			const memberCompanyId = _.get(members, [memberId, 'companyId'], unknownCompanyId);
			const memberGroups = _.get(members, [memberId, 'groups']);
			post = _fp.set([memberPropKey, 'companyId'], memberCompanyId, post);
			post = _fp.set([memberPropKey, 'groups'], memberGroups, post);
		});

		return post;
	});
};

const addInstancesValuesToPosts = (posts, postsInstances) => {
	if (!posts || !postsInstances) return posts;

	let postsMap = {};
	posts.forEach(post => {
		postsMap[post.id] = Object.assign({}, post);
	});

	postsInstances.forEach(instance => {
		const postId = instance.parentId;
		const propId = instance.propId;
		if (!postId || !propId) return;

		const currentProp = postsMap[postId];
		if (currentProp) _.set(postsMap, [postId, 'instances', propId], instance);
	});

	return _.values(postsMap);
};

// function to create a query. filterVal = value to query, data = [{source : [array of data], key : which field to search }]
// if the field you want to search is in different array,  pass another object in the data array. Example:  [{ source: allMembers, key: ["companyId"] }, { source: allCompanies, key: ["name"] }] .
// here we are looking for members that their companyName begins with filterVal
const generateLokiQuery = (filterVal, data, path) => ({
	$or: Object.values(
		data[0].source
			.filter(item =>
				data.length < 2
					? item.getNested(data[0].key, '').toLowerCase().includes(filterVal)
					: data[1].source.getNested([item.getNested(data[0].key)])
					? data[1].source
							.getNested([item.getNested(data[0].key)], {})
							.getNested(data[1].key, '')
							.toLowerCase()
							.includes(filterVal)
					: false,
			)
			.map(item => ({ [path]: item.get('id') }))
			.toJS(),
	),
});

const orStringBuilder = orsArr => `(${orsArr.join(' OR ')})`;
const andStringBuilder = andsArr => `${andsArr.join(' AND ')}`;
const realmQueryStringBuilder = queryArr =>
	andStringBuilder(queryArr.map(a => (Array.isArray(a) ? orStringBuilder(a) : a)));

/**
 * @typedef {'records' | 'tasks'} PostsType
 *
 * @typedef {'safety'} ContentType
 *
 * @typedef Filters
 * @property {ContentType} [contentType]
 * @property {PostsType} [postsType]
 * @property {string} [locationSearch] - Router location.search
 * @param {Filters} postsFilters
 * @returns
 */

const usePosts = postsFilters => {
	// props
	const storeProps = useSelector(state => ({
		allCompanies: state.companies.map,
		allMembers: state.members.map,
		trades: state.trades.map,
	}));
	const projectContextProps = useContext(ProjectContext);
	postsFilters = _.isNil(postsFilters) ? {} : postsFilters;

	// state
	const [_postsFilters, set_postsFilters] = useState(postsFilters);
	const [currViewPostsMap, setCurrViewPostsMap] = useState({});
	const [currViewPosts, setCurrViewPosts] = useState([]);
	const [filteredPosts, setFilteredPosts] = useState([]);

	// refs
	const lokiPostsRef = useRef(null);
	const realmPostsRef = useRef(null);
	const lokiPostsInstancesRef = useRef(null);

	const isNative = platformActions.app.isNative();

	const getRealmFilteredPosts = useCallback(() => {
		const { contentType, postsType, filterValue } = _postsFilters; // TODO: support filterValue
		const { configurations, projectMembers } = projectContextProps;

		let nextViewPosts = [];
		let nextFilteredPosts = [];
		if (realmPostsRef.current) {
			let queryArr = [];

			if (contentType === 'safety') queryArr.push('trade.id == "1038"');
			else if (configurations.getNested(['features', 'safety', 'isActive'])) queryArr.push(`trade.id != "1038"`);

			switch (postsType) {
				case 'records': {
					queryArr.push(['issueState == 0', 'issueState == null']);
					queryArr.push(['isIssue == false', 'isIssue == null']);
					break;
				}

				case 'tasks': {
					queryArr.push(['isIssue == true']);
					break;
				}
			}

			nextViewPosts = realmPostsRef.current.filtered(realmQueryStringBuilder(queryArr));
			nextViewPosts = setCompanyIdAndGroupsToOwnerAndAssignToOnPosts(
				nextViewPosts.map(p => p.realmToObject()),
				projectMembers,
			);
			nextViewPosts = nextViewPosts.sort((a, b) => (b.editedAt || b.createdAt || 0) - (a.editedAt || a.createdAt || 0));

			nextFilteredPosts = nextViewPosts;
		}

		return {
			filteredPostsArr: nextFilteredPosts,
			viewPostsArr: nextViewPosts,
			viewPostsMap: nextViewPosts.reduce((acc, p) => _.set(acc, [p.id], p), {}),
		};
	}, [_postsFilters, projectContextProps.configurations, projectContextProps.projectMembers]);

	const getLokiFilteredPosts = useCallback(() => {
		const { contentType, postsType, filterValue, locationSearch } = _postsFilters;
		const { selectedProjectId, configurations, projectMembers } = projectContextProps;
		const { allCompanies, allMembers, trades } = storeProps;

		let nextViewPosts = [];
		let nextFilteredPosts = nextViewPosts;
		if (selectedProjectId && lokiPostsRef.current) {
			let lokiQuery = { projectId: selectedProjectId, $and: [] };
			const instancesQuery = {
				projectId: selectedProjectId,
				subjectName: 'postsInfo',
			};
			const relevantPostsInstances = lokiPostsInstancesRef.current.cementoFind(instancesQuery);

			if (filterValue) {
				const regExp = {
					$regex: new RegExp(filterValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i'),
				};
				const companysQuery = generateLokiQuery(
					filterValue,
					[
						{ source: allMembers, key: ['companyId'] },
						{ source: allCompanies, key: ['name'] },
					],
					'assignTo.id',
				);
				const tradesQuery = generateLokiQuery(filterValue, [{ source: trades, key: ['getTitle'] }], 'trade.id');
				const membersQuery = generateLokiQuery(
					filterValue,
					[{ source: allMembers, key: ['displayName'] }],
					'assignTo.id',
				);

				lokiQuery['$and'].push({
					['$or']: [
						{ title: regExp },
						{ [`extraData.images.${filterValue}`]: { $gt: 0 } },
						companysQuery,
						tradesQuery,
						membersQuery,
					],
				});
			}

			if (contentType === 'safety') lokiQuery['$and'].push({ 'trade.id': '1038' });
			else if (configurations.getNested(['features', 'safety', 'isActive']))
				lokiQuery['$and'].push({ 'trade.id': { $ne: '1038' } });

			switch (postsType) {
				case 'records': {
					lokiQuery['$and'].push({
						$or: [{ issueState: 0 }, { issueState: { $exists: false } }],
					});
					lokiQuery['$and'].push({
						$or: [{ isIssue: false }, { isIssue: { $exists: false } }],
					});
					break;
				}

				case 'tasks': {
					lokiQuery['$and'].push({
						$or: [{ isIssue: true }, { isIssue: { $exists: false } }],
					});
					lokiQuery['$and'].push({ issueState: { $exists: true } });
					break;
				}

				default:
					break;
			}

			nextViewPosts = lokiPostsRef.current.cementoFind(lokiQuery);
			nextViewPosts = addInstancesValuesToPosts(nextViewPosts, relevantPostsInstances);
			nextViewPosts = setCompanyIdAndGroupsToOwnerAndAssignToOnPosts(nextViewPosts, projectMembers); // TODO: do this directly from loki
			nextViewPosts = nextViewPosts.sort((a, b) => (b.editedAt || b.createdAt || 0) - (a.editedAt || a.createdAt || 0));

			nextFilteredPosts = nextViewPosts;
			const cementoQuery = decodeFiltersFromSearch(locationSearch, POSTS_FILTER_URL_KEY).cementoQuery;
			if (cementoQuery)
				nextFilteredPosts = Object.values(
					getFilteredResults({ cementoQuery, targetList: nextFilteredPosts }).permitted,
				);
		}

		return {
			filteredPostsArr: nextFilteredPosts,
			viewPostsArr: nextViewPosts,
			viewPostsMap: nextViewPosts.reduce((acc, p) => _.set(acc, [p.id], p), {}),
		};
	}, [
		_postsFilters,
		storeProps.allCompanies,
		storeProps.trades,
		storeProps.allMembers,
		projectContextProps.selectedProjectId,
		projectContextProps.configurations,
		projectContextProps.projectMembers,
		lokiPostsInstancesRef.current,
	]);

	const getFilteredPosts = useCallback(
		() => (isNative ? getRealmFilteredPosts() : getLokiFilteredPosts()),
		[getLokiFilteredPosts, getRealmFilteredPosts, isNative],
	);
	const updateFilteredPosts = useCallback(() => {
		const {
			filteredPostsArr: nextFilteredPosts,
			viewPostsArr: nextViewPosts,
			viewPostsMap: nextViewPostsMap,
		} = getFilteredPosts();

		if (!_.isEqual(filteredPosts, nextFilteredPosts) || _.isEqual(currViewPosts, nextViewPosts)) {
			setFilteredPosts(nextFilteredPosts);
			setCurrViewPosts(nextViewPosts);
			setCurrViewPostsMap(nextViewPostsMap);
		}
	}, [getFilteredPosts, isNative]);

	const updateFilteredPostsRef = useRef();
	useEffect(() => {
		updateFilteredPostsRef.current = updateFilteredPosts;
	}, [updateFilteredPosts]);

	const lokiPostsListener = useCallback(collectionName => {
		if ((collectionName === 'posts' || collectionName === 'propertyInstances') && updateFilteredPostsRef.current)
			updateFilteredPostsRef.current(); // using a ref because the function is passed to a listener and otherwise would run with old params
	}, []);

	const realmPostsListener = useCallback((values, changes) => {
		const didChange = Boolean(changes.insertions.length || changes.deletions.length || changes.modifications.length);

		if (didChange && updateFilteredPostsRef.current) updateFilteredPostsRef.current(); // using a ref because the function is passed to a listener and otherwise would run with old params
	}, []);

	// didMount
	useEffect(() => {
		let cleanUp;

		if (isNative) {
			const realmPosts = realmInstance.posts
				.objects('post24')
				.filtered(`projectId == "${projectContextProps.selectedProjectId}"`);
			realmPostsRef.current = realmPosts;

			realmPosts.addListener(realmPostsListener);
			cleanUp = () => {
				realmPosts.removeListener(realmPostsListener);
			};
		} else {
			const lokiPosts = lokiInstance.getCollection('posts');
			lokiPostsRef.current = lokiPosts;

			const lokiPostsPropertiesInstances = lokiInstance.getCollection('propertyInstances');
			lokiPostsInstancesRef.current = lokiPostsPropertiesInstances;

			const postsListenerId = uuidv4();
			const instancesListenerId = uuidv4();
			lokiPosts.cementoOn(postsListenerId, lokiPostsListener);
			lokiPostsPropertiesInstances.cementoOn(instancesListenerId, lokiPostsListener);

			cleanUp = () => {
				lokiPosts.cementoOff(postsListenerId);
				lokiPostsPropertiesInstances.cementoOff(instancesListenerId);
			};
		}

		// willUnmount
		return cleanUp;
	}, []);

	useEffect(() => {
		if (!_.isEqual(_postsFilters, postsFilters)) set_postsFilters(postsFilters);
	}, [postsFilters]);

	useEffect(() => {
		updateFilteredPosts();
	}, [
		_postsFilters,
		storeProps.allCompanies,
		storeProps.trades,
		storeProps.allMembers,
		projectContextProps.selectedProjectId,
		projectContextProps.configurations,
		projectContextProps.projectMembers,
	]);

	return useMemo(
		() => ({ filteredPosts, currViewPosts, currViewPostsMap }),
		[filteredPosts, currViewPosts, currViewPostsMap],
	);
};

export default usePosts;

/**
 * @callback ChilrenRenderProps
 * @param {{ filteredProps: any[], currViewPosts: any[] }}
 */
/**
 *
 * @param {{ filters: Filters, children: ChilrenRenderProps }} props
 * @returns {ChilrenRenderProps}
 */
export const PostsHOC = props => {
	const postsProps = usePosts(props.filters);

	return props.children(postsProps);
};
