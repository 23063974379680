import systemMessages from "../../../common/app/systemMessages";

export const cameraMonitorInputFields = [
    {
        title: systemMessages.camerasMessages.active,
        type: 'Boolean',
        path: ['isActive']
    },
    {
        title: systemMessages.camerasMessages.lastKeepAlive,
        type: 'Date',
        path: ['lastCameraKeepAlive']
    },
];

export const cameraConfigurationsInputFields = [
    {
        title: 'IP',
        type: 'String',
        path: ['ip']
    },
    {
        title: 'Port',
        type: 'Number',
        path: ['port']
    },
    {
        title: systemMessages.camerasMessages.username,
        type: 'String',
        path: ['user']
    },
    {
        title: systemMessages.camerasMessages.password,
        type: 'String',
        path: ['pass']
    },
    {
        title: systemMessages.camerasMessages.disabled,
        type: 'Boolean',
        path: ['disabled']
    },
];

export const CAMERA_LOCATIONS = {
    entrance: {
        port: 5001,
        id: 'entrance',
        disabled: false,
        title: {
            he: 'כניסה',
            en: 'entrance'
        }
    },
    exit: {
        port: 6001,
        id: 'exit',
        disabled: false,
        title: {
            he: 'יציאה',
            en: 'exit'
        }
    },
    entrance1: {
        port: 5001,
        id: 'entrance1',
        disabled: false,
        title: {
            he: 'כניסה 1',
            en: 'entrance 1'
        }
    },
    exit1: {
        id: 'exit1',
        port: 6001,
        disabled: false,
        title: {
            he: 'יציאה 1',
            en: 'exit 1'
        }
    }
};