import React from "react";
import _ from "lodash";

// Components
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Text from "../CementoComponents/Text";
import theme from "../../assets/css/theme";
import { compose, hoistStatics } from "recompose";
import { connect } from "react-redux";
import { viewerMathConditions } from "../../../common/permissions/funcs";

// Must receive component that will receive onMenuClick prop
const defaultProps = {
  OriginComponent: null,
  menuItems: [],
  menuTitle: null,
  closeOnOptionClick: false,
  highlightOnHover: false,
  offsetMenuTop: theme.margin - 5,
  offsetMenuRight: 0,
  offsetMenuLeft: 0,
  mainContainerStyle: {},
  originComponentContainerStyle: {},
};

class DropdownMenu extends React.Component {
  constructor(props) {
    super(props);
    this.handleChangeOpen = this.handleChangeOpen.bind(this);

    this.state = {
      isOpen: false,
    };
  }

  handleChangeOpen() {
    if (!this.props.menuItems || !this.props.menuItems.length) return;
    this.setState({ isOpen: !this.state.isOpen });
  }

  handleOptionClick(onClickFunc) {
    const { closeOnMenuItemClick } = this.props;

    if (onClickFunc) onClickFunc();

    if (closeOnMenuItemClick) this.handleChangeOpen();
  }

  render() {
    const {
      OriginComponent,
      menuItems,
      menuTitle,
      originComponentContainerStyle,
      highlightOnHover,
      viewer,
      offsetMenuTop,
      offsetMenuRight,
      offsetMenuLeft,
      mainContainerStyle,
      menuStyles,
    } = this.props;
    const { isOpen } = this.state;
    const { originComponentContainer } = styles;
    return (
      <div style={mainContainerStyle}>
        <div
          className={highlightOnHover ? "HeaderLinks-itemLink-260" : null}
          onClick={this.handleChangeOpen}
          style={{
            ...originComponentContainerStyle,
            ...originComponentContainer,
          }}
        >
          {Boolean(OriginComponent) && (OriginComponent)}
        </div>
        {Boolean(menuItems) && (
          <Popper open={isOpen} transition disablePortal style={{marginTop:theme.padding, top:"unset", ...menuStyles}}>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-list-grow"
                style={{
                  marginTop: offsetMenuTop,
                  marginRight: offsetMenuRight,
                  marginLeft: offsetMenuLeft,
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={this.handleChangeOpen}>
                    <MenuList>
                      {Boolean(menuTitle) && (
                        <MenuItem
                          button={false}
                          style={{
                            fontWeight: 600,
                            color: theme.brandPrimary,
                            cursor: "default",
                          }}
                        >
                          <span
                            style={{
                              borderBottom: "2px solid",
                              borderColor: theme.brandPrimary,
                              paddingLeft: 3,
                              marginLeft: -3,
                              paddingRight: 3,
                              marginRight: -3,
                            }}
                          >
                            <Text>{menuTitle}</Text>
                          </span>
                        </MenuItem>
                      )}
                      {menuItems.map((menuItem, i) => {
                        const {
                          label,
                          onClick,
                          viewerPermissions,
                          permittedBool,
                          htmlFor,
                        } = menuItem;

                        let show = true;
                        if (typeof permittedBool !== "undefined")
                          show = permittedBool;
                        else if (viewerPermissions && viewer)
                          show = viewerMathConditions(
                            viewer,
                            viewerPermissions
                          );

                        if (!show) return null;
                        return (
                          <label htmlFor={htmlFor}>
                            <MenuItem
                              key={`dropdownMenuItem-${
                                (label || {}).defaultMessage
                                  ? label.defaultMessage
                                  : label
                              }-${i}`}
                              onClick={() => this.handleOptionClick(onClick)}
                            >
                              <Text style={{ color: theme.textColor }}>{label}</Text>
                            </MenuItem>
                          </label>
                        );
                      })}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        )}
      </div>
    );
  }
}

DropdownMenu.defaultProps = defaultProps;

const styles = {
  resetClassNameStyles: {
    minWidth: "unset",
    width: "unset",
    position: "unset",
    flexDirection: "unset",
  },
  originComponentContainer: {
    cursor: "pointer",
  },
};

const enhance = compose(
  connect(
    (state) => ({
      viewer: state.users.viewer,
    }),
    {}
  )
);

export default enhance(DropdownMenu);
