import React from 'react';
import theme from '../../assets/css/theme';
import PropertiesManager from './PropertiesManager';
import InputField from '../../components/CementoComponents/InputField';
import SplitViewPage from '../../layouts/SplitViewPage';
import ScopeSelector from '../ProjectManager/ScopeSelector';
import { getMergerResult, upsertConfig } from '../../app/funcs';
import _ from 'lodash';
import { SELECTION_LIST, SYSTEM_SUBJECTS_TYPES } from '../../../common/propertiesTypes/propertiesTypes';
import { compose, hoistStatics } from 'recompose';
import { connect } from 'react-redux';
import { startLoading, hideLoading, updateObjectsSourcesMap, startToast } from '../../../common/app/actions';
import systemMessages from '../../../common/app/systemMessages';
import { getDefaultScopeId } from './funcs';
import { DEFAULT_SELECTED_SCOPE } from '../../../common/app/constants';
import Text from '../../components/CementoComponents/Text';
import UpButton from '../../assets/img/icons/sortArrow.png';
import Modal from '../../components/CementoComponents/Modal';
import { envParams, writeMixpanelLogs } from '../../../common/configureMiddleware';
import StandardInput from '../../components/CementoComponents/StandardInput';
import Button from '../../components/CementoComponents/Button';
import { platformActions } from '../../../common/platformActions';
import { v4 as uuidV4 } from 'uuid';

const FETCH_MERGER_OPERATION_ID = 'fetchMerger';
const UPSERT_CONFIGURATION_REQUEST_ID = 'upsertRequest';
const MIXPANEL_LOG_GROUP = 'PropertiesManager'

class PropertiesManagerPage extends React.Component {
	constructor(props) {
		super(props);
		this.recalcHeader = this.recalcHeader.bind(this);
		this.fetchMergerResult = this.fetchMergerResult.bind(this);
		this.setMergerResult = this.setMergerResult.bind(this);
		this.upsertConfigSubmitHandler = this.upsertConfigSubmitHandler.bind(this);
		this.upsertAllConfigsSubmitHandler = this.upsertAllConfigsSubmitHandler.bind(this);
		
		this.state = {
			subjectName: 'locationsInfo',
			selectedScope: DEFAULT_SELECTED_SCOPE,
			selectedScopeId: props.selectedProjectId,
			isModalOpen: false,
			setPinchasParams: {
        isNewProject: false,
        copyMenus: true,
      },
		};
	}

	componentDidMount() {
		const { match } = this.props;
		const { selectedScope } = this.state;
		const defaultScopeId = _.get(match, ['params', 'selectedProjectId']);

		this.recalcHeader(defaultScopeId);
		this.fetchMergerResult(selectedScope, defaultScopeId);
	}

	shouldComponentUpdate(nextProps, nextState) {
		const { subjectName, mergedObjects, objectsSources, isModalOpen, setPinchasParams } = this.state;
		const shouldComponentUpdate =
			subjectName !== nextState.subjectName ||
			mergedObjects !== nextState.mergedObjects ||
			objectsSources !== nextState.objectsSources || 
			isModalOpen !== nextState.isModalOpen ||
			setPinchasParams !== nextState.setPinchasParams;

		return shouldComponentUpdate;
	}

	recalcHeader(defaultScopeId) {
		const { selectedScopeId } = this.state;
		const { setHeaderParams, selectedProjectId, rtl } = this.props;

		const headerComponent = (
			<div
				style={{
				flexGrow: 1,
				justifyContent: "flex-start",
				[rtl ? "marginLeft" : "marginRight"]: "0px",
				display: "flex",
				}}
			>
				<ScopeSelector
					selectedScopeId={selectedScopeId || defaultScopeId}
					submitHandler={this.fetchMergerResult}
					isButtonDisabled={selectedScopeId === selectedProjectId}
					shouldRedirectAfterSubmit
				/>
				<div 
					onClick={() => this.setState({isModalOpen: true})}
					style={{ 
						display: "flex",
						flex: 1,
						flexDirection: "row",
						cursor: "pointer",
						padding: "0px 10px",
						margin: "0px 5px",
						justifyContent: "flex-end",
						alignItems: "center"
					}} 
				>
					<Text>Upsert All</Text>
					<img 
						src={UpButton}
						style={{ 
							padding: "0px 3px",
							height: "20px"
					}}/>
				</div>
			</div>
		);

		if (setHeaderParams) setHeaderParams({ headerComponent, sideBarParams: null });
	}

	async fetchMergerResult(scope, scopeId) {
		const { projectDetails, startLoading, hideLoading } = this.props;
		startLoading({ title: systemMessages.loadingMessage, operationId: FETCH_MERGER_OPERATION_ID });

		let requiredScopeId;

		if (scope !== 'templates') requiredScopeId = scopeId || getDefaultScopeId(scope, projectDetails);

		const response = await getMergerResult(['propertiesTypes'], scope, requiredScopeId, true);

		this.setMergerResult(response.mergerResult, scope, scopeId);

		hideLoading(FETCH_MERGER_OPERATION_ID);
	}

	setMergerResult(mergerResultMap, scopeType, scopeId) {
		const { updateObjectsSourcesMap } = this.props;

		if (!_.isEmpty(mergerResultMap)) {
			let objectsSources = {};
			_.entries(mergerResultMap.objectsSources.propertiesTypes || {}).forEach(([key, value]) =>
				_.set(objectsSources, key.split('/'), value),
			);
			
			updateObjectsSourcesMap(objectsSources, 'propertiesTypes');
			delete mergerResultMap.objectsSources;

			const propertiesTypesMergerResult = _.get(mergerResultMap, 'propertiesTypes');

			this.setState({
				mergedObjects: propertiesTypesMergerResult,
				objectsSources,
				selectedScope: scopeType,
				selectedScopeId: scopeId,
			});
		}
	}

	askUserIfHeSure = async () => {
    	const { startToast } = this.props;

		return await new Promise(resolve => {
			startToast({
				overlay: true, 
				mandatory: true,
				title: "זהירות", 
				message: "האם הינך רוצה לדרוס את הגדרות המטרה?",
				actions: [
				{ message: systemMessages.yes, onClick: () => resolve(true), color: 'success'},
				{ message: systemMessages.no, onClick: () => resolve(false) }
				]
			});
		});
	}

	upsertConfigSubmitHandler = async (targetScope, targetScopeId, selectedProperties) => {
		const shouldUpsert = await this.askUserIfHeSure();
	  
		if (!shouldUpsert) return;

		const { selectedScope: sourceScope, selectedScopeId: sourceScopeId, subjectName } = this.state;

    	const { hideLoading, startLoading } = this.props;
		startLoading({ title: systemMessages.loadingMessage, operationId: UPSERT_CONFIGURATION_REQUEST_ID });

		selectedProperties = Array.isArray(selectedProperties) ? selectedProperties : [selectedProperties];
		
		selectedProperties = selectedProperties.map((prop) => {
			return {
				id: prop.id,
				subjectName: subjectName
		}});

		const response = await upsertConfig(targetScope, targetScopeId, sourceScope, sourceScopeId, "propertiesTypes", subjectName, selectedProperties);
    
		this.fetchMergerResult(sourceScope, sourceScopeId);

		hideLoading(UPSERT_CONFIGURATION_REQUEST_ID);

		writeMixpanelLogs({
			groupId: MIXPANEL_LOG_GROUP,
			logs: [
				{
					action: 'upsertConfigSubmitHandler',
					user: this.props.viewer,
					payload: {
						targetScope,
						targetScopeId,
						sourceScope,
						sourceScopeId,
						subjectName,
						selectedProperties,
					},
					response,
				},
			],
			flush: true,
		});
	};

	upsertAllConfigsSubmitHandler = async (targetScope, targetScopeId) => {
		const shouldUpsert = await this.askUserIfHeSure();
	  
		if (!shouldUpsert) return;

		const { selectedScope: sourceScope, selectedScopeId: sourceScopeId, mergedObjects } = this.state;

		const sourceSubjectType = "propertiesTypes";
		let selectedProperties = [];
		let subjectNames = [];

		_.forIn(mergedObjects, (sbjTypeObj, subjectName) => {
			subjectNames.push(subjectName);
			
			_.forIn(sbjTypeObj.properties, (prop, propId) => {
				let currSelectedProp = {
					id: propId,
					subjectName
				};

				if (prop.sectionId) currSelectedProp.sectionId = prop.sectionId;

				selectedProperties.push(currSelectedProp);
			});
		});

		const { startLoading, hideLoading } = this.props;

		startLoading({ title: systemMessages.loadingMessage, operationId: UPSERT_CONFIGURATION_REQUEST_ID });

		const response = await upsertConfig(targetScope, targetScopeId, sourceScope, sourceScopeId, sourceSubjectType, subjectNames, selectedProperties);
    
		this.fetchMergerResult(sourceScope, sourceScopeId);

		hideLoading(UPSERT_CONFIGURATION_REQUEST_ID);

		writeMixpanelLogs({
			groupId: MIXPANEL_LOG_GROUP,
			logs: [
				{
					action: 'upsertAllConfigsSubmitHandler',
					user: this.props.viewer,
					payload: {
						targetScope,
						targetScopeId,
						sourceScope,
						sourceScopeId,
						sourceSubjectType,
						subjectNames,
						selectedProperties,
					},
					response,
				},
			],
			flush: true,
		});
	};

	handleSetPinchasProperties = async () => {
    const { startToast, hideLoading, startLoading } = this.props;
		const { selectedScopeId, selectedScope } = this.state;
    const { isNewProject, copyMenus } = this.state.setPinchasParams;
    const { apiServer } = envParams;

		if (selectedScope !== 'projects') {
			startToast({ title: 'Can\'t touch this', message: 'Can\'t set pinchas on scope that is not projects' })
			return;
		}
    
    const url = `${apiServer}/v1/services/properties/duplicatePropertiesTypes`;
    const body = {
      projectIds: [selectedScopeId],
      updateCompany: 'isInitialMigration',
      copyMenus,
      isNewProject,
    }

    const operationId = 'setPinchas_' + uuidV4();

    startLoading({ operationId });
    let toastParams = { overlay: true };
    try {
      await platformActions.net.fetch(url, { method: 'POST', body: JSON.stringify(body) });
      toastParams.title = 'Successfully set pinchas clali';
      toastParams.message = 'You may reload a couple times at your convenience to get the changes if they are not already visible.\nIf still no luck, try taking a nap and if that still doesnt help, please contact a dev ❤️';
    }
    catch (error) {
      console.error('Error setting pinchas ->', error);
      toastParams.title = 'Failed to set pinchas';
      toastParams.message = 'I don\'t want you to panic but something went wrong while setting the pinchas clali.\nPlease open the console and contact the dev closest to your heart ❤️\n(just not me, thanks 😘).';
    }
    finally {
      hideLoading(operationId);
      startToast(toastParams);
    }
  }

	render() {
		const { history, match } = this.props;
		const { subjectName, mergedObjects, objectsSources, selectedScope, selectedScopeId, isModalOpen } = this.state;
		const currentSubjectMergedObjects = _.get(mergedObjects, subjectName);
		const currentSubjectObjectsSources = _.get(objectsSources, subjectName);

		return (
			<SplitViewPage
				Main={
					<div
						style={{ flex: 1, padding: theme.paddingSize, display: 'flex', flexDirection: 'column', minHeight: '100%' }}
					>
						<div style={{ padding: theme.padding, borderTop: `2px solid ${theme.brandPrimary}`, borderBottom: `2px solid ${theme.brandPrimary}` }}>
              <Text style={{ fontWeight: theme.bold, fontSize: theme.fontSizeH5, marginBottom: theme.margin }}>Copy pinchas clali properties to this project</Text>
              <StandardInput 
                title={'Is new project?'}
                disabled={false}
                type={'Boolean'}
                tooltip={{title: 'only required to know if we should initialize the projectsInfo subject'}}
                value={this.state.setPinchasParams.isNewProject}
                onChange={(value) => {
                  this.setState({
                    setPinchasParams: {
                      ...this.state.setPinchasParams,
                      isNewProject: value,
                    }
                  })
                }}
              />
              <StandardInput 
                title={'Copy menus?'}
                disabled={false}
                type={'Boolean'}
                tooltip={{title: 'this will copy the tables from pinchas project. Do not set if you are just copying the properties again and set up different tables already'}}
                value={this.state.setPinchasParams.copyMenus}
                onChange={(value) => {
                  this.setState({
                    setPinchasParams: {
                      ...this.state.setPinchasParams,
                      copyMenus: value,
                    }
                  })
                }}
              />
              <Button title={'Submit'} onClick={this.handleSetPinchasProperties} />
            </div>
						<InputField
							style={{ flex: 'none' }}
							type={SELECTION_LIST}
							key={'subjectName'}
							name={'SubjectName:'}
							value={{ [subjectName]: subjectName }}
							onChange={val => this.setState({ subjectName: Object.keys(val)[0] })}
							isClearable={false}
							values={SYSTEM_SUBJECTS_TYPES.map(subj => ({ id: subj, title: subj }))}
						/>
						<PropertiesManager
							history={history}
							match={match}
							subjectName={subjectName}
							mergerResult={currentSubjectMergedObjects}
							objectsSources={currentSubjectObjectsSources}
							scopeType={selectedScope}
							scopeId={selectedScopeId}
							submitHandler={this.upsertConfigSubmitHandler}
						/>
						<Modal open={isModalOpen} onClose={() => {this.setState({isModalOpen: false})}}>
							<div style={{padding: 20}}>
								<ScopeSelector buttonText={'Upsert All'} submitHandler={this.upsertAllConfigsSubmitHandler}/>
							</div>
						</Modal>
					</div>
				}
			/>
		);
	}
}

const enhance = compose(
	connect(
		null,
		{ startLoading, hideLoading, updateObjectsSourcesMap, startToast },
	),
);

export default hoistStatics(enhance)(PropertiesManagerPage);
