import React from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { compose, hoistStatics } from "recompose";
import { connectContext } from "react-connect-context";
import { ProjectContext } from "../../../common/projects/contexts";
import webActions from "../../webActions";
import moment from "moment";
import { CSVLink } from "react-csv";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import * as checklistTypes from "../../../common/checklists/checklistTypes";
import * as checklistItemPeriods from "../../../common/checklistItemsInstances/clItemInstancePeriods";
import ChecklistEditCard from "./ChecklistEditCard.js";
import ChecklistRow from "./ChecklistRow.js";
import buttonStyle from "../../assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";
import FloatingSticky from "../../components/FloatingSticky/FloatingSticky.js";
import issuesMessages from "../../../common/issues/issuesMessages";
import systemMessages from "../../../common/app/systemMessages";
import { startToast } from "../../../common/app/actions";
import {
  getChecklists,
  saveChecklists,
  getMergedChecklistsFromTemplates,
  getNewChecklistId,
} from "../../../common/checklists/actions";
import {
  getChecklistItems,
  getNewChecklistItemId,
} from "../../../common/checklistItems/actions";
import { convertCSVtoChecklistObjectsArray } from "./funcs";
import theme from "../../assets/css/theme";
import ParentChildView from "./ParentChildView";
import SplitViewPage from "../../layouts/SplitViewPage";
import CollapsibleSection_DEPRECATED from "../../components/CementoComponents/CollapsibleSection_DEPRECATED";
import { setFlatLocationsTitlesMap } from "../Locations/funcs";
import { writeMixpanelLogs } from "../../../common/configureMiddleware";

const PapaParse = require("papaparse/papaparse.min.js");
const MIXPANEL_LOG_GROUP = 'ChecklistsManager'
class ChecklistsManager extends React.Component {
  constructor(props) {
    super(props);
    //this.onToggleModeChange = this.onToggleModeChange.bind(this);
    this.setComponentData = this.setComponentData.bind(this);
    // this.setFlatLocationsTitlesMap = this.setFlatLocationsTitlesMap.bind(this);
    this.setChecklistsArray = this.setChecklistsArray.bind(this);
    this.onRowSelect = this.onRowSelect.bind(this);
    this.onDataChange = this.onDataChange.bind(this);
    this.onItemDelete = this.onItemDelete.bind(this);
    this.onItemDuplicate = this.onItemDuplicate.bind(this);
    this.saveAllChanged = this.saveAllChanged.bind(this);
    this.onChangeMode = this.onChangeMode.bind(this);
    this.onSort = this.onSort.bind(this);
    this.recalcHeader = this.recalcHeader.bind(this);
    this.handleChangeChecklistLocation =
      this.handleChangeChecklistLocation.bind(this);
    this.onImportChecklistsExcel = this.onImportChecklistsExcel.bind(this);
    this.fetchChecklistsData = this.fetchChecklistsData.bind(this);
    this.onExport = this.onExport.bind(this);

    this.state = {
      activeChanges: false,
      fromExcelMode: false,
      editChecklistsMode: true,
      checklists: {},
      checklistItems: {},
      scope: "templates",
      sections: [],
      selectedProjectLocations: {},
    };
  }

  recalcHeader() {
    const { setHeaderParams } = this.props;
    if (setHeaderParams)
      setHeaderParams({ headerComponent: null, sideBarParams: null });
  }

  UNSAFE_componentWillMount() {
    this.recalcHeader();
    this.setComponentData({}, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  onChangeMode() {
    this.setState({
      selectedChildIndex: null,
      selectedParentIndex: null,
    });
  }

  onRowSelect(parentIndex, childIndex) {
    this.setState({
      selectedChildIndex: childIndex,
      selectedParentIndex: parentIndex,
    });
  }

  onSort(sections) {
    this.setState({
      activeChanges: true,
      selectedChildIndex: null,
      selectedParentIndex: null,
      sections,
    });
  }

  onItemDelete(newSections) {
    const oldSections = this.state.sections
    this.setState({
      activeChanges: true,
      selectedChildIndex: null,
      selectedParentIndex: null,
      sections: newSections,
    });
    writeMixpanelLogs({
			groupId: MIXPANEL_LOG_GROUP,
			logs: [
				{
					action: 'onItemDelete',
					user: this.props.viewer,
					projectId: this.props.selectedProjectId,
					payload: {
						newSections,
						oldSections,
					},
				},
			],
		});
  }

  async fetchChecklistsData(scope, scopeId) {
    const { getMergedChecklistsFromTemplates } = this.props;
    let templates = await getMergedChecklistsFromTemplates(scope, scopeId);
    this.setState({
      sections: this.setChecklistsArray(
        templates.checklists,
        templates.checklistItems
      ),
    });
  }

  setComponentData(props, nextProps) {
    const { checklists, checklistItems, units, floors, buildings, trades } =
      props;

    let newStateChanges = {};

    //if (nextProps.checklists &&
    //    nextProps.checklistItems &&
    //    (checklists != nextProps.checklists ||
    //     checklistItems != nextProps.checklistItems))
    //  newStateChanges.sections = this.setChecklistsArray(nextProps.checklists, nextProps.checklistItems)

    if (props.isValDiff(nextProps, ["match", "params", "selectedCompanyId"])) {
      if (
        nextProps.getNested(["match", "params", "selectedCompanyId"]) &&
        nextProps.getNested(["match", "params", "selectedCompanyId"]) != "_" &&
        nextProps.getNested(["match", "params", "selectedCompanyId"]) !=
          ":selectedCompanyId"
      ) {
        newStateChanges.scope = "companies";
        newStateChanges.scopeId = nextProps.getNested([
          "match",
          "params",
          "selectedCompanyId",
        ]);
        this.fetchChecklistsData(
          newStateChanges.scope,
          newStateChanges.scopeId
        );
      }
    }
    if (props.isValDiff(nextProps, ["match", "params", "selectedProjectId"])) {
      if (
        nextProps.getNested(["match", "params", "selectedProjectId"]) &&
        nextProps.getNested(["match", "params", "selectedProjectId"]) != "_" &&
        nextProps.getNested(["match", "params", "selectedProjectId"]) !=
          ":selectedProjectId"
      ) {
        newStateChanges.scope = "projects";
        newStateChanges.scopeId = nextProps.getNested([
          "match",
          "params",
          "selectedProjectId",
        ]);
        this.fetchChecklistsData(
          newStateChanges.scope,
          newStateChanges.scopeId
        );
      }
    }

    if (
      (newStateChanges.scope == "projects" || this.state.scope == "projects") &&
      nextProps.buildings &&
      nextProps.floors &&
      nextProps.units &&
      (props.buildings != nextProps.buildings ||
        props.floors != nextProps.floors ||
        props.units != nextProps.units)
    ) {
      let locations = setFlatLocationsTitlesMap(
        nextProps.buildings,
        nextProps.floors,
        nextProps.units,
        nextProps.intl
      );
      let sortedLocations = {};
      locations.loopEach((typeId, type) => {
        sortedLocations[typeId] = Object.values(type).sort((a, b) =>
          a.id.localeCompare(b.id)
        );
      });
      newStateChanges.selectedProjectLocations = sortedLocations;
      newStateChanges.selectedProjectLocationsMap = locations;
      newStateChanges.selectedParentIndex = null;
      newStateChanges.selectedChildIndex = null;
    }

    if (Object.keys(newStateChanges).length > 0) this.setState(newStateChanges);
  }

  // setFlatLocationsTitlesMap(buildings, floors, units) {
  //   const { intl } = this.props;
  //   let locations = { buildings: {}, floors: {}, units: {}};
  //   let countOfBuildings = 0;
  //   let buildingIndex  = 1;
  //   (buildings || {}).loopEach((bid, b,) => {
  //     let currTitle = (b.title || `building ${buildingIndex}`);
  //     countOfBuildings++;
  //     locations.buildings[bid] = {
  //       id: bid,
  //       exportTitle: currTitle,
  //       title: currTitle
  //     }
  //     buildingIndex++;
  //   });
  //   (floors || {}).loopEach((bid, b) => { b.loopEach((fid, f) => {
  //     let currTitle = (f.description || intl.formatMessage(issuesMessages.floorNumber, {floorNumber: String(f.num)}));
  //     locations.floors[fid] = {
  //       id: fid,
  //       exportTitle: countOfBuildings > 1 ? `${locations.buildings[bid].title}@${currTitle}` : currTitle,
  //       title: locations.buildings[bid].title + ' \\ ' + currTitle
  //     }
  //   })});
  //   (units || {}).loopEach((bid, b) => { b.loopEach((uid, u) => {
  //     let currTitle = u.title;
  //     locations.units[uid] = {
  //       id: uid,
  //       exportTitle: countOfBuildings > 1 ?  `${locations.buildings[bid].title}@${currTitle}` : currTitle,
  //       title: locations.buildings[bid].title + ' \\ ' + intl.formatMessage(issuesMessages.floorNumber, {floorNumber: String(u.floor.num)}) + ' \\ ' + currTitle
  //     }
  //   })});

  //   return locations;
  // }

  setChecklistsArray(checklists, checklistItems) {
    let sectionsMap = {};
    if (!checklistItems || !checklists) return [];

    Object.values(checklistItems || {}).forEach((item) => {
      Object.keys(item.checklistIds || {}).forEach((itemParentId) => {
        if (checklists.getNested([itemParentId])) {
          if (!sectionsMap[itemParentId])
            sectionsMap[itemParentId] = {
              parent: checklists.getNested([itemParentId]),
              childs: [],
            };

          if (
            !sectionsMap.getNested([itemParentId, "parent", "originChecklist"])
          )
            sectionsMap[itemParentId].childs.push(item);
        }
      });
    });

    let sortedArray = Object.values(sectionsMap);
    sortedArray = sortedArray.sort((a, b) =>
      a.getNested(["parent", "ordinalNo"]) ==
      b.getNested(["parent", "ordinalNo"])
        ? a.getNested(["parent", "duplicationNo"], 1) -
          b.getNested(["parent", "duplicationNo"], 1)
        : a.getNested(["parent", "ordinalNo"], 0) -
          b.getNested(["parent", "ordinalNo"], 0)
    );

    sortedArray.forEach(
      (element) =>
        (element.childs = element.childs.sort(
          (a, b) => (a.ordinalNo || 0) - (b.ordinalNo || 0)
        ))
    );
    return sortedArray;
  }

  onDataChange(field, value, changeAllChilds) {
    const { selectedParentIndex, selectedChildIndex, sections, activeChanges } =
      this.state;
    const { startToast } = this.props;

    let newActiveChanges = activeChanges || false;
    let newSections = (sections || []).slice();
    let selectedChecklist = sections.getNested([selectedParentIndex, "parent"]);
    let selectedChecklistItem = sections.getNested([
      selectedParentIndex,
      "childs",
      selectedChildIndex,
    ]);

    if (
      (!selectedChecklist && !selectedChecklistItem) ||
      !field ||
      (value === undefined && value != 0)
    )
      return;

    if (changeAllChilds) {
      newActiveChanges = true;
      for (
        let i = 0;
        newSections[selectedParentIndex].childs &&
        i < newSections[selectedParentIndex].childs.length;
        i++
      )
        newSections[selectedParentIndex].childs[i] = newSections[
          selectedParentIndex
        ].childs[i].setNested(field, value);
    } else {
      if (selectedChecklistItem) {
        newActiveChanges = true;
        let updatedItem = selectedChecklistItem.setNested(field, value);
        newSections[selectedParentIndex].childs[selectedChildIndex] =
          updatedItem;
        if (field[0] == "checklistIds") {
          newSections[selectedParentIndex].childs.splice(selectedChildIndex, 1);
          sections.forEach((currSection) => {
            if (currSection.parent.id == Object.keys(value)[0])
              currSection.childs.push(updatedItem);
          });
        }
      } else if (selectedChecklist) {
        let updatedChecklist = selectedChecklist.setNested(field, value);

        if (field[0] == "locations") {
          let deltaAdded = {};
          let deltaRemoved = {};
          let locationType = field[1];
          let oldLocations = Object.assign(
            {},
            selectedChecklist.locations || {}
          );
          let newLocations = Object.assign(
            {},
            updatedChecklist.locations || {}
          );
          (oldLocations[locationType] || {}).loopEach((id, loc) => {
            if (!newLocations.getNested([locationType, id]))
              deltaRemoved[id] = loc;
          });
          (newLocations[locationType] || {}).loopEach((id, loc) => {
            if (!oldLocations.getNested([locationType, id]))
              deltaAdded[id] = loc;
          });

          // If checklist has been removed from location - we dont mind what the user say! - we remove it also from the relevant items
          if (Object.values(deltaRemoved).length != 0)
            newSections = this.handleChangeChecklistLocation(
              true,
              oldLocations,
              deltaAdded,
              deltaRemoved,
              field[1]
            );
          else
            startToast({
              overlay: true,
              mandatory: true,
              title: systemMessages.manage.addLocationTitle,
              message: systemMessages.manage.addLocationMessage,
              actions: [
                {
                  message: systemMessages.ok,
                  onClick: () =>
                    this.handleChangeChecklistLocation(
                      true,
                      oldLocations,
                      deltaAdded,
                      deltaRemoved,
                      locationType
                    ),
                  color: "success",
                },
                {
                  message: systemMessages.no,
                  onClick: () =>
                    this.handleChangeChecklistLocation(
                      false,
                      oldLocations,
                      deltaAdded,
                      deltaRemoved,
                      locationType
                    ),
                },
              ],
            });
        }

        newSections[selectedParentIndex].parent = updatedChecklist;
        newActiveChanges = true;
      }
    }

    this.setState({ activeChanges: newActiveChanges, sections: newSections });
  }

  handleChangeChecklistLocation(
    addNewLocationToItems,
    oldLocations,
    deltaAdded,
    deltaRemoved,
    locationType,
    bSetState
  ) {
    const { selectedParentIndex, sections } = this.state;
    let checklistItems = sections.getNested(
      [selectedParentIndex, "childs"],
      []
    );
    checklistItems.forEach((checklistItem, itemIndex) => {
      if (!addNewLocationToItems) {
        if (!checklistItem.onlyLocations)
          checklistItems[itemIndex] = checklistItem.setNested(
            ["onlyLocations"],
            Object.assign({}, oldLocations)
          );
      } else {
        if (checklistItem.onlyLocations) {
          deltaAdded.loopEach((id, addedLoc) => {
            let updatedItem = checklistItem.setNested(
              ["onlyLocations", locationType, id],
              Object.assign({}, addedLoc)
            );
            checklistItems[itemIndex] = updatedItem;
          });
          deltaRemoved.loopEach((id) => {
            let updatedItem = checklistItem.setNested(
              ["onlyLocations", locationType, id],
              null
            );
            checklistItems[itemIndex] = updatedItem;
          });
        }
      }
    });

    if (bSetState) this.setState({ sections });
    else return sections;

    writeMixpanelLogs({
			groupId: MIXPANEL_LOG_GROUP,
			logs: [
				{
					action: 'handleChangeChecklistLocation',
					user: this.props.viewer,
					projectId: this.props.selectedProjectId,
					payload: {
						oldLocations,
						deltaAdded,
						deltaRemoved,
						locationType,
						bSetState,
						sections,
					},
				},
			],
		});
  }

  onItemDuplicate(selectedParentIndex, selectedChildIndex) {
    const { selectedProjectId, getNewChecklistId, getNewChecklistItemId } =
      this.props;
    const { sections, activeChanges } = this.state;

    let newActiveChanges = activeChanges || false;
    let selectedChecklist = sections.getNested([selectedParentIndex, "parent"]);
    let selectedChecklistItem = sections.getNested([
      selectedParentIndex,
      "childs",
      selectedChildIndex,
    ]);

    try {
      if (selectedChecklistItem) {
        let newItemId = getNewChecklistItemId(selectedProjectId).payload.id;
        let itemClone = selectedChecklistItem.setNested(["id"], newItemId);
        itemClone = itemClone.setNested(["onlyLocations"], null);
        itemClone = itemClone.setNested(
          ["description"],
          itemClone.description +
            " (copy " +
            moment(new Date()).format("HH:mm:ss") +
            ")"
        );
        sections[selectedParentIndex].childs.splice(
          selectedChildIndex + 1,
          0,
          itemClone
        );
        newActiveChanges = true;
      } else if (selectedChecklist) {
        let newChecklistId = getNewChecklistId(selectedProjectId).payload.id;
        let newChecklsitSection = { parent: null, childs: [] };
        let checklistClone = selectedChecklist.setNested(
          ["id"],
          newChecklistId
        );
        //checklistClone = checklistClone.setNested(['locations'], {});
        checklistClone = checklistClone.setNested(
          ["description"],
          checklistClone.description +
            " (copy " +
            moment(new Date()).format("HH:mm:ss") +
            ")"
        );
        newChecklsitSection.parent = checklistClone;

        sections
          .getNested([selectedParentIndex, "childs"], [])
          .forEach((checklistItem) => {
            let newItemId = getNewChecklistItemId(selectedProjectId).payload.id;
            let parentChecklistIds = {};
            let itemClone = checklistItem.setNested(["id"], newItemId);
            parentChecklistIds[newChecklistId] = { id: newChecklistId };
            itemClone = itemClone.setNested(
              ["checklistIds"],
              parentChecklistIds
            );
            itemClone = itemClone.setNested(["onlyLocations"], null);
            newChecklsitSection.childs.push(itemClone);
          });

        sections.splice(selectedParentIndex + 1, 0, newChecklsitSection);
        newActiveChanges = true;
      }

      this.setState({
        selectedChildIndex: null,
        selectedParentIndex: null,
        activeChanges: newActiveChanges,
        sections: this.state.sections,
      });
      writeMixpanelLogs({
				groupId: MIXPANEL_LOG_GROUP,
				logs: [
					{
						action: 'onItemDuplicate',
						user: this.props.viewer,
						projectId: this.props.selectedProjectId,
						payload: {
							selectedChecklistItem,
							selectedParentIndex,
							selectedChildIndex,
							selectedChecklist,
              sections,
						},
					},
				],
			});
    } catch (err) {
      console.error(err);
    }
  }

  async saveAllChanged() {
    const { saveChecklists } = this.props;
    const { sections, scope, scopeId } = this.state;
    let checklistItems = [];

    sections.forEach((currSection) =>
      currSection.childs.forEach((item, index) => {
        currSection.childs[index] = item
          .setNested(["ordinalNo"], index + 1)
          .setNested(["prefix"], String(index + 1));
        checklistItems.push(currSection.childs[index]);
      })
    );

    let checklistIndexMap = {};
    let nonDuplicatedChecklistCount = 0;

    let checklists = sections.map((currSection, index) => {
      checklistIndexMap[currSection.parent.id] = index;
      if (!currSection.parent.originChecklist) {
        currSection.parent = currSection.parent.setNested(
          ["ordinalNo"],
          nonDuplicatedChecklistCount + 1
        );
        nonDuplicatedChecklistCount++;
      }
      return currSection;
    });

    checklists = checklists.map((currSection, index, checklistsArray) => {
      let originChecklist = currSection.parent.originChecklist;
      if (originChecklist) {
        let originChecklistIndex = checklistIndexMap[originChecklist];
        let ordinalNo = checklists[originChecklistIndex].getNested([
          "parent",
          "ordinalNo",
        ]);
        currSection.parent = currSection.parent.setNested(
          ["ordinalNo"],
          ordinalNo
        );
      }
      return currSection.parent;
    });

    // TODO check it
    if ((stagesDelta || checklistsDelta || checklistItemsDelta) && saveChecklists) {
      await saveChecklists({scope, scopeId, checklistsDeltas: checklists, checklistItemsDeltas: checklistItems});
    }

    this.setState({ activeChanges: false });

    writeMixpanelLogs({
			groupId: MIXPANEL_LOG_GROUP,
			logs: [
				{
					action: 'saveAllChanged',
					user: this.props.viewer,
					projectId: this.props.selectedProjectId,
					payload: { scope, scopeId, checklistsDeltas: checklists, checklistItemsDeltas: checklistItems },
				},
			],
      flush: true,
		});
  }

  onExport() {
    const { selectedProjectLocationsMap, sections } = this.state;

    let toExport = [
      [
        "id",
        "isDeleted",
        "stage",
        "tradeID",
        "description",
        "buildingTitles",
        "floorTitles",
        "unitTitles",
        "requirements(img,desc,file,drawing)",
        "weight",
        "enablePartial",
        "enableIrrelevant",
        "isRoutine",
        "companies",
        "extraDescription",
        "enableDistributionList",
        "duplicatable",
      ],
    ];
    sections
      .filter((section) => !section.getNested(["parent", "originChecklist"]))
      .forEach((section) => {
        let buildingsArr = [];
        let floorsArr = [];
        let unitsArr = [];
        let companies = null;
        section
          .getNested(["parent", "locations", "buildings"], {})
          .loopEach((id, val) => {
            buildingsArr.push(
              selectedProjectLocationsMap.getNested([
                "buildings",
                id,
                "exportTitle",
              ])
            );
          });
        section
          .getNested(["parent", "locations", "floors"], {})
          .loopEach((id, val) => {
            floorsArr.push(
              selectedProjectLocationsMap.getNested([
                "floors",
                id,
                "exportTitle",
              ])
            );
          });
        section
          .getNested(["parent", "locations", "units"], {})
          .loopEach((id, val) => {
            unitsArr.push(
              selectedProjectLocationsMap.getNested([
                "units",
                id,
                "exportTitle",
              ])
            );
          });
        if (
          section.childs &&
          section.childs[0] &&
          section.childs[0].permissions &&
          section.childs[0].permissions.read &&
          section.childs[0].permissions.read.companies
        )
          companies = Object.keys(section.childs[0].permissions.read.companies);
        toExport.push([
          section.parent.id,
          section.parent.isDeleted,
          (section.parent.stage || "").replace(/\"/g, "''"),
          -1,
          section.parent.description.replace(/\"/g, "''"),
          buildingsArr
            .filter((loc) => Boolean(loc))
            .join(",")
            .replace(/\"/g, "''"),
          floorsArr
            .filter((loc) => Boolean(loc))
            .join(",")
            .replace(/\"/g, "''"),
          unitsArr
            .filter((loc) => Boolean(loc))
            .join(",")
            .replace(/\"/g, "''"),
          null, //section.parent.type,
          null,
          null,
          null,
          Boolean(section.parent.type == checklistTypes.ROUTINE),
          companies,
          null,
          Boolean(section.parent.enableDistributionList),
          Boolean(section.parent.duplicatable),
        ]);
        section.childs.forEach((item) => {
          let buildingsArr = [];
          let floorsArr = [];
          let unitsArr = [];
          item
            .getNested(["onlyLocations", "buildings"], {})
            .loopEach((id, val) => {
              buildingsArr.push(
                selectedProjectLocationsMap.getNested([
                  "buildings",
                  id,
                  "exportTitle",
                ])
              );
            });
          item
            .getNested(["onlyLocations", "floors"], {})
            .loopEach((id, val) => {
              floorsArr.push(
                selectedProjectLocationsMap.getNested([
                  "floors",
                  id,
                  "exportTitle",
                ])
              );
            });
          item.getNested(["onlyLocations", "units"], {}).loopEach((id, val) => {
            unitsArr.push(
              selectedProjectLocationsMap.getNested([
                "units",
                id,
                "exportTitle",
              ])
            );
          });
          toExport.push([
            item.id,
            item.isDeleted,
            null,
            item.trade,
            item.description.replace(/\"/g, "''"),
            buildingsArr.join(",").replace(/\"/g, "''"),
            floorsArr.join(",").replace(/\"/g, "''"),
            unitsArr.join(",").replace(/\"/g, "''"),
            item.requirements
              ? Object.keys(item.requirements)
                  .filter((reqKey) => item.requirements[reqKey])
                  .join(",")
              : undefined,
            item.weight != 1 ? item.weight : undefined, //item.period
            Boolean(item.getNested(["permissions", "actions", "partial"]))
              ? "True"
              : null,
            Boolean(item.getNested(["permissions", "actions", "irrelevant"]))
              ? "True"
              : null,
            section.parent.type == "routine"
              ? checklistItemPeriods.CLI_PERIODS_DAILY
              : null,
            null,
            item.extraDescription
              ? item.extraDescription.replace(/\"/g, "''")
              : null,
            null,
            null,
          ]);
        });
      });

    let uri = this.refs.toCSVcomponent.buildURI(toExport, true, null, ",");
    this.setState({ csvURI: uri }, () => {
      this.refs.CSVDownload.click();
    });
  }

  onImportChecklistsExcel(csv) {
    const {
      selectedProjectId,
      units,
      floors,
      buildings,
      getNewChecklistId,
      getNewChecklistItemId,
      intl,
    } = this.props;
    const { sections, containsMode } = this.state;
    let checklistsAddition = convertCSVtoChecklistObjectsArray(
      csv,
      buildings,
      floors,
      units,
      selectedProjectId,
      getNewChecklistId,
      getNewChecklistItemId,
      (sections || []).length,
      intl,
      containsMode,
      sections || []
    );
    if (checklistsAddition == null) {
      console.error("Error on checklist import");
      return;
    }
    //let newSections = (sections || []).slice();
    //newSections = newSections.concat(checklistsAddition);
    let newSections = checklistsAddition;
    this.setState({ activeChanges: true, sections: newSections });
    
    writeMixpanelLogs({
			groupId: MIXPANEL_LOG_GROUP,
			logs: [
				{
					action: 'onImportChecklistsExcel',
					user: this.props.viewer,
					projectId: this.props.selectedProjectId,
					payload: {
						newSections,
					},
				},
			],
		});
  }

  render() {
    const { selectedProjectId, classes, projects, intl, rtl } = this.props;
    let {
      csvURI,
      sections,
      activeChanges,
      selectedParentIndex,
      selectedChildIndex,
      selectedProjectLocations,
    } = this.state;
    let btnStyle = {
      borderRadius: "5px",
      display: "flex",
      padding: "0px 5px",
      minWidth: 75,
      height: 30,
      alignSelf: "center",
      justifyContent: "center",
      alignItems: "center",
      margin: 5,
      border: "1px solid " + theme.brandPrimary + "85",
      color: theme.brandPrimary,
      cursor: "pointer",
    };
    let selectedProject = projects.getNested([selectedProjectId], {});
    let selectedChecklist = sections.getNested([selectedParentIndex, "parent"]);
    let selectedChecklistItem = sections.getNested([
      selectedParentIndex,
      "childs",
      selectedChildIndex,
    ]);
    let showCard = selectedChecklist || selectedChecklistItem;
    let extraData = {};
    if (showCard)
      extraData.readCompanies = selectedChecklistItem
        ? selectedChecklistItem.getNested(["permissions", "read", "companies"])
        : sections.getNested([
            selectedParentIndex,
            "childs",
            0,
            "permissions",
            "read",
            "companies",
          ]);

    return (
      <SplitViewPage
        rtl={rtl}
        Main={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              minHeight: "100%",
              flex: 1,
              padding: theme.paddingSize,
              justifyContent: "space-between",
            }}
          >
            <GridContainer spacing={8}>
              <GridItem xs={showCard ? 8 : 12}>
                <CollapsibleSection_DEPRECATED alwaysOpen={true}>
                  <ParentChildView
                    style={styles.mainStyle}
                    editable={true}
                    sections={sections}
                    rowComponent={ChecklistRow}
                    selectedChildIndex={selectedChildIndex}
                    selectedParentIndex={selectedParentIndex}
                    onSort={this.onSort}
                    onRowSelect={this.onRowSelect}
                    onChangeMode={this.onChangeMode}
                    onItemDuplicate={this.onItemDuplicate}
                    onItemLogicDelete={this.onItemDelete}
                    parentSectionTitlePath={["description"]}
                    parentSectionIndications={{
                      source: {
                        path: ["source"],
                        colors: {
                          template: theme.brandDanger,
                          company: theme.brandWarning,
                          project: theme.brandPrimary,
                        },
                      },
                      isDeleted: {
                        path: ["isDeleted"],
                        colors: { true: "gray" },
                      },
                      isDuplicated: {
                        path: ["originChecklist"],
                        colors: { true: "gray" },
                        titleExtraInfo: { path: ["duplicationNo"] },
                      },
                    }}
                  />
                </CollapsibleSection_DEPRECATED>
              </GridItem>
              {Boolean(showCard) && (
                <GridItem xs={4}>
                  <FloatingSticky style={{ top: theme.paddingSize }}>
                    <CollapsibleSection_DEPRECATED alwaysOpen={true}>
                      <ChecklistEditCard
                        style={styles.cardStyle}
                        editMode={true}
                        onChange={this.onDataChange}
                        sections={sections}
                        locations={selectedProjectLocations}
                        itemMode={Boolean(selectedChildIndex != null)}
                        extraData={extraData}
                        selectedChecklist={selectedChecklist}
                        selectedChecklistItem={selectedChecklistItem}
                      />
                    </CollapsibleSection_DEPRECATED>
                  </FloatingSticky>
                </GridItem>
              )}
            </GridContainer>
            <div style={{ width: "100%" }}>
              <GridContainer style={{ flexDirection: "row-reverse" }}>
                <GridItem xs={1}>
                  <div
                    style={{
                      ...btnStyle,
                      ...(!activeChanges
                        ? {
                            pointerEvents: "none",
                            color: theme.brandNeutral,
                            border: "1px solid " + theme.brandNeutral,
                            backgroundColor: theme.brandNeutral + "10",
                          }
                        : { backgroundColor: theme.brandPrimary + "10" }),
                    }}
                    onClick={this.saveAllChanged}
                  >
                    {intl.formatMessage(systemMessages.saveChanges)}
                  </div>
                </GridItem>
                <GridItem xs={1}>
                  <ImageUpload
                    style={btnStyle}
                    disabled={
                      !this.props.getNested([
                        "match",
                        "params",
                        "selectedProjectId",
                      ]) &&
                      !this.props.getNested([
                        "match",
                        "params",
                        "selectedCompanyId",
                      ])
                    }
                    readAsText={true}
                    onFileLoaded={this.onImportChecklistsExcel}
                    acceptFiles={".csv"}
                  >
                    {intl.formatMessage(systemMessages.manage.excelImport)}
                  </ImageUpload>
                </GridItem>
                <GridItem xs={1}>
                  <CSVLink ref="toCSVcomponent" data={[[]]} />
                  <a
                    ref="CSVDownload"
                    download={
                      (selectedProject.title || selectedProject.address || "") +
                      "_checklists.csv"
                    }
                    href={csvURI}
                    style={{ display: "none" }}
                  ></a>
                  <div
                    style={{
                      ...btnStyle,
                      ...((!this.props.getNested([
                        "match",
                        "params",
                        "selectedProjectId",
                      ]) &&
                        !this.props.getNested([
                          "match",
                          "params",
                          "selectedCompanyId",
                        ])) ||
                      !sections ||
                      sections.length == 0
                        ? {
                            pointerEvents: "none",
                            color: theme.brandNeutral,
                            border: "1px solid " + theme.brandNeutral,
                          }
                        : {}),
                    }}
                    onClick={this.onExport}
                  >
                    {intl.formatMessage(systemMessages.manage.excelExport)}
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        }
      />
    );
  }
}

let styles = {
  mainStyle: {
    width: "100%",
    padding: theme.paddingSize,
    boxShadow: "none",
    backgroundColor: theme.backgroundColorBright,
  },
  cardStyle: {
    width: "100%",
    height:
      "calc(100vh - " + (theme.headerHeight + theme.paddingSize * 2) + "px)",
    boxShadow: "none",
    backgroundColor: theme.backgroundColorBright,
  },
};

ChecklistsManager = withStyles(buttonStyle)(ChecklistsManager);
ChecklistsManager = injectIntl(ChecklistsManager);
const enhance = compose(
  connectContext(ProjectContext.Consumer),
  connect(
    (state) => ({
      rtl: state.app.rtl,
    }),
    {
      startToast,
      getChecklists,
      getMergedChecklistsFromTemplates,
      getChecklistItems,
      saveChecklists,
      getNewChecklistId,
      getNewChecklistItemId,
    }
  )
);
export default enhance(ChecklistsManager);

class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }
  handleImageChange(e) {
    const { readAsText } = this.props;
    try {
      e.preventDefault();
      let reader = new FileReader();
      let file = e.target.files[0];
      reader.onloadend = (() => {
        const { onFileLoaded } = this.props;
        if (onFileLoaded) {
          const csvData = PapaParse.parse(reader.result, {
            error: () => console.log("error reading csv"),
          });
          onFileLoaded(csvData.data);
        }
      }).bind(this);

      if (readAsText) reader.readAsText(file);
      else reader.readAsDataURL(file);
    } catch (e) {
      console.error(e);
    }
  }
  handleSubmit(e) {
    e.preventDefault();
  }
  handleClick() {
    this.refs.fileInput.click();
  }
  render() {
    var { acceptFiles, children, disabled, style } = this.props;
    return (
      <div
        style={{
          ...(style || {}),
          ...(disabled
            ? {
                pointerEvents: "none",
                color: theme.brandNeutral,
                border: "1px solid " + theme.brandNeutral,
              }
            : {}),
        }}
        onClick={this.handleClick}
      >
        <input
          type="file"
          className="fileinput text-center"
          style={{ display: "none" }}
          onChange={this.handleImageChange}
          ref="fileInput"
          accept={acceptFiles}
        />
        {children}
      </div>
    );
  }
}
