import React from "react";
import { hoistStatics, compose } from "recompose";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";

// Components
import Modal from "./Modal";
import Buildings from "../../views/Locations/Buildings";

// Others
import theme from "../../assets/css/theme";
import CondencedLocationNames from "./CondencedLocationNames";
import Text from "./Text";
import analyticsMessages from "../../../common/analytics/analyticsMessages";
import Button from "../../app/standardComponents/Button";
import propertiesMessages from "../../../common/propertiesTypes/propertiesMessages";

class SelectLocations extends React.Component {
  constructor(props) {
    super(props);
    this.setComponentData = this.setComponentData.bind(this);
    this.toggleShowBuildingsMode = this.toggleShowBuildingsMode.bind(this);
    this.state = {
      showBuildingsMode: false,
    };
  }

  componentDidMount() {
    this.setComponentData({}, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  setComponentData(props, nextProps) {
    let newStateChanges = {};

    if (Object.keys(newStateChanges).length) this.setState(newStateChanges);
  }

  toggleShowBuildingsMode() {
    this.setState(({ showBuildingsMode }) => ({
      showBuildingsMode: !showBuildingsMode,
    }));
  }

  handleChange = (event) => {
    const { isMulti, onSelectionChange } = this.props;

    if (onSelectionChange) onSelectionChange(event);

    if (!isMulti) this.toggleShowBuildingsMode();
  };

  render() {
    const {
      selectedLocations = null,
      onlyLocations = null,
      filterType = null,
      onChangeType = () => {},
      isEditMode = false,
      showSelectType = true,
      isMulti = false,
    } = this.props;
    const { showBuildingsMode } = this.state;
    let hasLocations =
      selectedLocations && Object.keys(selectedLocations).length;
    return (
      <>
        {Boolean(showBuildingsMode) && (
          <Modal open={true} onClose={this.toggleShowBuildingsMode}>
            <div
              style={{
                height: "75vh",
                width: "20vw",
                minWidth: 300,
                maxWidth: 350,
                minHeight: 600,
              }}
            >
              <Buildings
                isSelectionMode
                showBadges={false}
                style={{
                  marginTop: 0,
                  visibility: "visible",
                  width: "100%",
                  height: "inherit",
                  backgroundColor: theme.backgroundColor,
                }}
                maxUnitsInFloor={4}
                selectedLocation={{
                  buildingId: null,
                  floorId: null,
                  unitId: null,
                }}
                onClick={this.handleChange}
                selectedLocations={selectedLocations}
                onlyLocations={onlyLocations}
                filterType={filterType}
                isMultiSelect={isMulti}
                onChangeType={onChangeType}
                onSelectionChange={this.handleChange}
                showSelectType={showSelectType}
              />
            </div>
          </Modal>
        )}
        <div style={{ padding: theme.verticalMargin }}>
          <Text
            style={{
              fontWeight: theme.strongBold,
              fontSize: theme.fontSizeH6,
              marginBottom:
                isEditMode && hasLocations
                  ? theme.margin
                  : theme.verticalMargin,
            }}
          >
            {analyticsMessages.locations}
          </Text>
          {Boolean(!isEditMode || (isEditMode && hasLocations)) && (
            <CondencedLocationNames
              style={{
                fontSize: theme.fontSizeH6,
                margin: isEditMode ? theme.margin + "px 0" : 0,
              }}
              locations={selectedLocations}
            />
          )}
          {Boolean(isEditMode) && (
            <Button
              style={{ marginInline: "auto" }}
              onClick={this.toggleShowBuildingsMode}
            >
              {propertiesMessages.selectLocations}
            </Button>
          )}
        </div>
      </>
    );
  }
}

SelectLocations = injectIntl(SelectLocations);
const enhance = compose(connect((state) => ({}), {}));

export default enhance(SelectLocations);