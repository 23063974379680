import React from 'react';
import theme from '../../assets/css/theme';
import trash from '../../assets/img/icons/trash.png';
import Text from './Text';
import InnerCollapsible from './InnerCollapsible';
import InputField, { NoValueComponent } from './InputField';
import { hoistStatics, compose } from 'recompose';
import { connect } from 'react-redux';
import { connectContext } from 'react-connect-context';
import { ProjectContext } from '../../../common/projects/contexts';
import AddNewButton from './AddNewButton';
import link_primary from '../../assets/img/icons/link_brand_primary.png';
import InnerCollapsibleRow from './InnerCollapsibleRow';
import LinkExtraData from './LinkExtraData';
import Modal from './Modal';
import CrossListSortable from './CrossListSortable';
import propertiesMessages from '../../../common/propertiesTypes/propertiesMessages';
import { updateLocalProperties, getNewId, deleteLocalProperty } from '../../../common/propertiesTypes/actions';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import _fp from 'lodash/fp';
import systemMessages from '../../../common/app/systemMessages';
import { injectIntl } from 'react-intl';
import * as propertyTypes from '../../../common/propertiesTypes/propertiesTypes';
import Button from '../../app/standardComponents/Button';
import _ from 'lodash';
class SelectExtraData extends React.Component {
  constructor(props) {
    super(props);
    this.setComponentData = this.setComponentData.bind(this);
    this.toggleShowLinkModal = this.toggleShowLinkModal.bind(this);
    this.handleLinkExistingChange = this.handleLinkExistingChange.bind(this);
    this.handleDeleteExtraData = this.handleDeleteExtraData.bind(this);
    this.handleDeleteOption = this.handleDeleteOption.bind(this);
    this.handleAddExtraData = this.handleAddExtraData.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSort = this.onSort.bind(this);
    this.calcInputField = this.calcInputField.bind(this);
    this.calcInputSection = this.calcInputSection.bind(this);
    this.state = {
      hover: false,
      showLinkModal: false,
      openedExtraDataSections: {},
    }
  }

  componentWillMount() {
    this.setComponentData({}, this.props);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setComponentData(this.props, nextProps);
  }

  setComponentData(props, nextProps) {
    let newStateChanges = {};

    if (props.isValDiff(nextProps, ['propertiesTypes'])) {
      newStateChanges.extraDataInfos = nextProps.propertiesTypes;
    }

    if (!props.isEditMode && nextProps.isEditMode && nextProps.propertiesTypes) {
      newStateChanges.existingExtraDataRef = {};
      nextProps.propertiesTypes.loopEach((subjectName, properties) => newStateChanges.existingExtraDataRef = { ...newStateChanges.existingExtraDataRef, ...properties });
    }

    if (props.isValDiff(nextProps, ['configurations', 'forms'])) {
      let formsWithSignatures = {};
      _.values(nextProps.getNested(['configurations', 'forms'])).filter(form => form?.signatures).forEach(form => {
        formsWithSignatures[form.id] = form
      })
      newStateChanges.formsWithSignatures = formsWithSignatures;
    }

    if (newStateChanges.formsWithSignatures) {
      newStateChanges.formsBySignatureId = _.values(newStateChanges.formsWithSignatures).reduce((acc, form) => {
        _.values(form.signatures).forEach(signature => {
          if (signature && signature.id)
            acc[signature.id] = form;
        });

        return acc;
      }, {});
    }

    if (Object.keys(newStateChanges).length)
      this.setState(newStateChanges);
  }

  handleDeleteExtraData(subjectName, extraDataId) {
    const { selectedProjectId, extraDataObj, deleteLocalProperty } = this.props;
    const { existingExtraDataRef } = this.state;

    /* if (!existingExtraDataRef.getNested([extraDataId], false)) {
      if (deleteLocalProperty)
        deleteLocalProperty(selectedProjectId, subjectName, extraDataId);
    }  */


    let newExtraDataObj = Object.assign({}, extraDataObj);
    delete newExtraDataObj[extraDataId];

    this.onChange(newExtraDataObj);
  }

  onSort(sortedExtraDataObj) {
    let newExtraDataObj = {};
    sortedExtraDataObj.forEach((obj, index) => {
      newExtraDataObj = newExtraDataObj.setNested([obj.id], obj.setNested(['ordinalNo'], index + 1))
    });

    this.onChange(newExtraDataObj);
  }

  onChange(newExtraDataObj) {
    const { onChange } = this.props;

    if (onChange) onChange(newExtraDataObj);
  }

  toggleHover() {
    this.setState(({ hover }) => ({ hover: !hover }));
  }

  toggleShowLinkModal() {
    this.setState(({ showLinkModal }) => ({ showLinkModal: !showLinkModal }));
  }

  handleLinkExistingChange(newExtraDataObj) {
    this.onChange(newExtraDataObj);
  }

  handleAddExtraData() {
    const { subjectName, updateLocalProperties, selectedProjectId, lang, getNewId, extraDataObj, intl, propertiesTypes } = this.props;
    const newPropId = getNewId ? getNewId(selectedProjectId, subjectName, false).payload.id : null;
    const ordinalNo = Object.values(propertiesTypes[subjectName] || {}).length + 1;

    if (!newPropId || !subjectName)
      return;
    
    const property = {
      title: {
        [lang]: intl.formatMessage(systemMessages.newProperty),
      },
      showOnNullValue: true,
      hideOnMobile: false,
      longText: true,
      id: newPropId,
      optional: true,
      ordinalNo,
    };

    if (updateLocalProperties)
      updateLocalProperties(selectedProjectId, subjectName, { property });

    this.onSort(Object.values({ ...extraDataObj, ...{ [newPropId]: { subjectName, ...property } } }));
  }

  // ====== Important params explanation =====
  // pathToValue -> is the path on the property that we want to save into
  // extraDataObj -> It mean that we want the change to affect only the checklistItem object and not the propertyType itself
  calcInputField({name, type, pathToValue, value, values = [], subjectName, info, rowProps = {}, extraInputProps = {}, allowEdit = false, takeSelectionListObjectValue}) {
    const { isEditMode } = this.props;

    if (type === 'SelectionList' && !(value instanceof Object)) {
      // if (takeSelectionListObjectValue)
      //   value = values.filter(x => x.id == Object.keys(value || {})[0]).value;
      // else
        value = { [value]: value };
    }

    let isEditable = (isEditMode && (allowEdit || subjectName === 'checklistItemsInfo'));

    return (
      <InnerCollapsibleRow
        fullWidth
        isEditMode={isEditMode}
        mainContainerStyle={{
          height: 'min-content',
        }}
        {...rowProps}
      >
        <InputField 
          name={name}
          type={type}
          value={value}
          values={values}
          disabled={!isEditable}
          fullWidth={true}
          onChange={val => this.handleInputChange(val, pathToValue, subjectName, info)}
          style={{ fontSize: theme.fontSizeH6 }}
          {...extraInputProps}
        />
      </InnerCollapsibleRow>  
    )
  }

  calcInputSection(extraDataType) {
    const { isEditMode, lang, intl, configurations } = this.props;
    const { extraDataInfos, formsWithSignatures, formsBySignatureId } = this.state;
    const { subjectName, id, readOnly, optional, showAsChecklistSignButton } = extraDataType;

    console.log("TCL ~ file: SelectExtraData.js:203 ~ SelectExtraData ~ calcInputSection ~ extraDataType", extraDataType);


    let info = extraDataInfos.getNested([subjectName, id], null)
    if (!info)
      return <></>;
    
    let { type, values = false, businessType = '', settings, universalId } = info.toJS ? info.toJS() : info;
    let langTitle = info.getCementoTitle() || 'New property';


    if (type === null)
      type = '';

    return (
      <InnerCollapsible
        fullWidth={!isEditMode}
        key={subjectName + '-' + id + '-' + 'propInputs'}
        keyId={subjectName + '-' + id + '-' + 'propInputs'}
        title={langTitle}
        isEditMode={isEditMode}
        editIcons={[
          {icon: trash, onClick: () => this.handleDeleteExtraData(subjectName, id), style: { height: '18px' }}
        ]}
        mainContainerStyle={{
          backgroundColor: theme.innerCollapsibleRowBackground,
          border: theme.borderLineNeutralLight + '70',
        }}
      >
        {this.calcInputField({
          name: systemMessages.title,
          type: 'String',
          value: langTitle,
          pathToValue: ['title', lang],
          subjectName,
          info,
        })}
        {this.calcInputField({
          name: systemMessages.type,
          type: 'SelectionList',
          value: type,
          values: [propertyTypes.NUMBER, propertyTypes.STRING, propertyTypes.BOOLEAN, propertyTypes.DATE, propertyTypes.SELECTION_LIST, propertyTypes.SIGNATURE/* , propertyTypes.PDF, propertyTypes.PICTURE, propertyTypes.DRAWINGS_ARRAY, propertyTypes.FILES_ARRAY, propertyTypes.CERTIFICATION, 'PredefinedComponents' */].map(t => ({id: t, title: t}) ), // TODO: give them real names and translate
          pathToValue: ['type'],
          subjectName,
          info,
        })}
        
        {Boolean(type === propertyTypes.SIGNATURE) && 
          <>
          {this.calcInputField({
            name: 'signaturesFlow', // TODO: translate
            type: 'SelectionList',
            value: _.keys(_.get(settings, 'signatures')).reduce((acc, sId) => _.set(acc, _.get(formsBySignatureId, [sId, 'id']), _.get(formsBySignatureId, [sId, 'id'])), {}),
            values: Object.values(formsWithSignatures).map(f => ({ id: f.id, title: f.getNested(['title', lang], "") })),
            pathToValue: ['settings', 'signaturesOriginFormID'], /// this will be changed in this.handleInputChange
            subjectName,
            info,
          })}
          {this.calcInputField({
            name: systemMessages.showAsChecklistSignButton,
            type: 'Boolean',
            value: showAsChecklistSignButton,
            pathToValue: ['extraDataObj', id, 'showAsChecklistSignButton'], 
            allowEdit: true,
            subjectName,
            info
          })}
          {this.calcInputField({
            name: systemMessages.isShowSignatureValue,
            type: 'Boolean',
            value: _.get(settings, 'isShowSignatureValue', false),
            pathToValue: ['settings', 'isShowSignatureValue'], 
            allowEdit: true,
            subjectName,
            info
          })}
          </>
        }
        
        {this.calcInputField({
          name: systemMessages.readOnly,
          type: 'Boolean',
          value: readOnly,
          pathToValue: ['extraDataObj', id, 'readOnly'], 
          allowEdit: true,
          subjectName,
          info
        })}
        {this.calcInputField({
          name: systemMessages.optional,
          type: 'Boolean',
          value: Boolean(optional),
          pathToValue: ['extraDataObj', id, 'optional'], 
          allowEdit: true,
          subjectName,
          info,

        })}
        {this.calcInputField({
          name: 'Universal ID',
          type: 'String',
          value: universalId,
          pathToValue: ['universalId'],
          allowEdit: true,
          subjectName,
          info
        })}

        {Boolean(type === 'SelectionList') && (
          this.calcInputField({
          name: systemMessages.isMulti,
          type: 'Boolean',
          value:  _.get(settings, 'isMulti', false),
          pathToValue: ['settings', 'isMulti'], 
          allowEdit: true,
          subjectName,
          info
        }))}

        {Boolean(values && type === 'SelectionList')
          ? values.map((option, index) => 
              this.calcInputField({
                name: intl.formatMessage(systemMessages.option) + ' ' + (index + 1),
                type: 'String',
                value: option.getCementoTitle(),
                pathToValue: ['values', index, 'title', lang],
                subjectName,
                info,
                extraInputProps: {
                  key: option.id,
                  keyId: option.id,
                },
                rowProps: {
                  editIcons: [
                    {icon: trash, onClick: () => this.handleDeleteOption(subjectName, info, option.id), style: { height: '18px' }}
                  ],
                }
              })
            )
          : Boolean(type === 'PredefinedComponents') && 
              this.calcInputField({
                name: 'Components type', // TODO: translate
                type: 'SelectionList',
                value: businessType,
                values: ['companies', 'trades'].map(t => ({id: t, title: t}) ),
                pathToValue: ['businessType'],
                subjectName,
                info,
              })
        }
        {Boolean(isEditMode && type === 'SelectionList') && 
          <div style={{ padding: theme.padding }}>
            <AddNewButton 
              title={systemMessages.addNewOption}
              onClick={() => this.handleAddNewOption(subjectName, info)}
            />
          </div>
        }
      </InnerCollapsible>
    );
  }

  handleInputChange(val, pathToValue, subjectName, propObj) {
    const { updateLocalProperties, selectedProjectId, extraDataObj } = this.props;

    const { formsWithSignatures } = this.state;
    if (pathToValue[1] == 'signaturesOriginFormID') {
      const newPathToValue = ['settings', 'signatures'];
      const formId = _.head(_.keys(val));
      const newVal =_.get(formsWithSignatures, [formId, 'signatures']);
      return this.handleInputChange(newVal, newPathToValue, subjectName, propObj)
    }


    if (pathToValue[0] === 'extraDataObj') {
      const [objectName, propId, ...rest] = pathToValue || [];
      this.onChange(extraDataObj.setNested([propId, ...rest], val));
      return;
    }

    if (val instanceof Object && !['values', 'settings'].includes(pathToValue[0]) && pathToValue[1] != "signatures")
      val = Object.values(val)[0];
    
      
    propObj = propObj.toJS ? propObj.toJS() : propObj;
    propObj = _fp.set(pathToValue, val, propObj); // Using lodash because setNested on array changes it into object (fp creates a new obj instead of _.set which mutates it)
      
    if (pathToValue[0] === 'type' && (val !== 'SelectionList' || val !== 'PredefinedComponents'))
      propObj = propObj.setNested(['values'], null);      

    if (pathToValue[0] === 'type' && (val === propertyTypes.SIGNATURE)) {
      propObj = propObj.setNested(['settings', 'isShowSignatureValue'], true);
    }


    this.setState(prevState => 
      prevState.setNested(['extraDataInfos', subjectName, propObj.getNested(['id'])], propObj)
    );

    if (!this.debouncedUpdate)
      this.debouncedUpdate = AwesomeDebouncePromise((propObj) => {
        if (updateLocalProperties) 
          updateLocalProperties(selectedProjectId, subjectName, { propObj });

        this.debouncedUpdate = false;
      }, 1000);

    this.debouncedUpdate(propObj);
  }

  handleAddNewOption(subjectName, propObj) {
    const { getNewId, selectedProjectId, lang, intl } = this.props;
    let newId = getNewId ? getNewId(selectedProjectId, '', false).payload.id : null;

    if (!newId) 
      return;

    let options = propObj.getNested(['values']) || [];

    let newOption = {
      id: newId,
      title: {
        [lang]: intl.formatMessage(systemMessages.newOption),
      }
    }

    options.push(newOption);

    this.handleInputChange(options, ['values'], subjectName, propObj);
  }

  handleDeleteOption(subjectName, propObj, optionId) {
    let values = propObj.getNested(['values'], []);
        values = values.filter(option => option.id !== optionId);
    
    if (values.length === 0)
      values = null;
      
    this.handleInputChange(values, ['values'], subjectName, propObj);
  }

  render() {
    const { showLinkModal } = this.state;
    const { isEditMode, rtl, extraDataObj } = this.props;
    const { titleStyle, linkButton } = styles;
    
    let extraDataList = Object.values(extraDataObj).sort((obj1, obj2) => obj1.ordinalNo && obj2.ordinalNo ? obj1.ordinalNo - obj2.ordinalNo : 0);

    return (
      <div style={{ padding: theme.verticalMargin }}>
        <Text style={titleStyle}>{systemMessages.properties}</Text>
        <div style={{ margin: isEditMode ? theme.margin + 'px 0' : 0 }}>
          {Boolean(extraDataList && extraDataList.length) 
            ? <CrossListSortable
                masterList={extraDataList}
                onChange={this.onSort}
                sortableProps={{
                  disabled: !isEditMode,
                  group: 'checklistItems',
                  multiDrag: true,
                  multiDragKey: 'shift',
                  handle: '#dragger',
                  forceFallback: true,
                }}
              >
                {this.calcInputSection}
              </CrossListSortable>
            : Boolean(!isEditMode) && <NoValueComponent defaultText={propertiesMessages.empty} />
          }
        </div>
        {Boolean(isEditMode) && (
          <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: 'min-content' }}>
              <Button onClick={this.toggleShowLinkModal} title={systemMessages.linkExistingProperty} icon={link_primary} style={{ width: '100%' }} iconStyle={{ height: '55%' }} />
              <Button withPlus style={{ width: '100%' }} onClick={this.handleAddExtraData} title={systemMessages.addNewProperty} />
            </div>
          </div>
        )}
        {Boolean(showLinkModal) && (
          <>
          <Modal
            open={true}
            onClose={this.toggleShowLinkModal}
          >
            <LinkExtraData 
              extraDataObj={extraDataObj}
              onChange={this.handleLinkExistingChange}
            /> 
          </Modal>
          </>
        )}
     </div>
    );
  }
}

const styles = {
	mainContainer: {
		padding: theme.verticalMargin,
		alignItems: 'center',
		justifyContent: 'space-between',
		display: 'flex',
		flex: 1,
		padding: theme.padding + 'px ' + theme.paddingSize + 'px',
		height: 60,
		background: '#f6efe9',
		borderTop: '1px solid',
		borderBottom: '1px solid',
		borderColor: theme.headerInfoColor,
		cursor: 'pointer',
	},
	mainContainerHover: {
		color: theme.brandPrimary,
		borderColor: theme.brandPrimary,
	},
	titleStyle: {
		fontWeight: theme.strongBold,
		fontSize: theme.fontSizeH6,
		marginBottom: theme.verticalMargin,
	},
	linkButton: {
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
	},
};

SelectExtraData = injectIntl(SelectExtraData);

const enhance = compose(
	connectContext(ProjectContext.Consumer),
	connect(
		state => ({
			lang: state.app.lang,
			rtl: state.app.rtl,
		}),
		{
			updateLocalProperties,
			deleteLocalProperty,
			getNewId,
		},
	),
);

export default enhance(SelectExtraData);
