import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import theme from "../../assets/css/theme";
import { onError } from '../../../common/app/funcs';
import { startLoading, hideLoading } from '../../../common/app/actions';
//import PDFViewer from 'pdf-viewer-reactjs';
//import PDFViewer from 'pdf-viewer-reactjs';

import { Document, Page, pdfjs  } from 'react-pdf';
import { connect } from "react-redux";
import systemMessages from "../../../common/app/systemMessages";
//import { Document, Page } from 'react-pdf/build/entry.noworker';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
console.log('pdfjs.version', pdfjs.version);

class ZoomImage extends React.PureComponent {
  constructor(props) {
    super(props); 
    this.onMouseOver = this.onMouseOver.bind(this);
    this.onMouseOut = this.onMouseOut.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onMouseWheel = this.onMouseWheel.bind(this);
    this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);

    this.state = {
      scale: 1,
      numPages: null,
      pageNumber: 1,
    };
  } 

  componentWillMount() {
    this.setComponentData({ firstMount: true }, this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  setComponentData = (props, nextProps) => {
    if (props.src !== nextProps.src || props.pdfMode !== nextProps.pdfMode)
      this.getFileSrc(nextProps).then(fileSrc => this.setState({ fileSrc }));
  }

  onMouseOver() {
    const { pdfMode } = this.props;
    if (!pdfMode)
      this.setState({ scale: 1.2 })
  }
  onMouseOut() {
    const { pdfMode } = this.props;
    if (!pdfMode)
      this.setState({ scale: 1 })
  }
  onMouseMove(e) {
    let position = ((e.clientX - e.currentTarget.offsetLeft) / e.currentTarget.offsetWidth) * 100 + '% ' + ((e.clientY - e.currentTarget.offsetTop) / e.currentTarget.offsetHeight) * 100 + '% 0px';
    this.setState({ position })
  }
  onMouseWheel(e) {
    const { scale } = this.state
    let direction = e.deltaY < 0 ? 1 : -1;
    let newScale = scale + (direction * 0.2)
    newScale = newScale < 1 ? 1 : newScale;
    this.setState({ scale: newScale });
  }

  onDocumentLoadSuccess({ numPages }){
    this.setState({ numPages });
  }

  getFileSrc = async (props) => {
    const { src, pdfMode, startLoading, hideLoading } = props || this.props;

    let fileSrc = src;

    
    if (pdfMode) { // Firebase sends the file with content-type application/octet-stream so we need to try convert it to application/pdf
      const loadingId = 'loading-image';

      startLoading({ title: systemMessages.loadingMessage, operationId: loadingId });

      try {
        let blob = await fetch(src).then(r => r.blob());

        if (blob.type !== 'application/pdf') {
          blob = blob.slice(0, blob.size, 'application/pdf');
          fileSrc = URL.createObjectURL(blob);
        }
      }
      catch (error) {
        onError({
          errorMessage: 'Failed to download file to display',
          error,
          methodMetaData: {
            name: 'ImageCarousel.getFileSrc',
            args: { src, pdfMode },
          },
          errorMetaData: { src, pdfMode },
        });
      }

      hideLoading(loadingId);
    }

    return fileSrc;
  }

  render() {
    const { maxWidth, maxHeight, classes, pdfMode, toolbar = false } = this.props;
    const { scale, position, fileSrc } = this.state;

    return (
      <div
        style={{
          overflow: pdfMode ? 'auto' : 'hidden',
          overflowY: pdfMode ? 'auto' : 'hidden',
          height: maxHeight,
        }}
        onWheel={this.onMouseWheel}
        onMouseOver={this.onMouseOver}
        onMouseOut={this.onMouseOut}
        onMouseMove={this.onMouseMove}
      >
        {pdfMode ?
          <embed
            style={{ height: maxHeight, width: maxWidth, overFlow: "hidden", }}
            type="application/pdf"
            src={`${fileSrc}#toolbar=${Number(toolbar)}`}
          />
          :
          <img
            className={classes.pic}
            style={{
              padding: theme.margin,
              objectFit: 'contain',
              width: maxWidth,
              height: maxHeight,
              'transform': 'scale(' + scale + ')',
              'transform-origin': position
            }}
            src={fileSrc}
          />
        }
      </div>
    );
  }
}


let styles = {
  pic: {
//    transition: 'transform .5s ease-out'
  }
}

ZoomImage = connect(state => ({}), { startLoading, hideLoading })(ZoomImage);
export default withStyles(styles)(ZoomImage);