import * as appActions from '../app/actions';
import * as actions from './actions';
import { REPLACE_USER } from '../auth/actions';
import { OrderedMap, Map } from 'immutable';
import { Record } from '../transit';

const InitialState = Record({
  map: OrderedMap(),
  didLoad: OrderedMap(),
  lastUpdated: Map(),
  lastUpdateAvailable: Map(),  
  aggregated: Map(),
}, 'forms');

const initialState = new InitialState;

export default function formsReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState;

  switch (action.type) {    

    case actions.GET_FORMS_AGGREGATED_VALUES: {
      const { aggregatedValues, projectId } = action.payload;
      if (!projectId)
        return state;

      return state.setIn(['aggregated', projectId], aggregatedValues);
    }

    case actions.GET_FORMS_BY_TYPE: {
      if (!action.payload)
        return state;
      const { forms, projectId, formType = 'general' } = action.payload || {};

      if (!projectId || !forms)
        return state;

      state = state.setIn(['didLoad', projectId, formType], true);
      
      let formsMap = state.map.get(projectId) || new OrderedMap();
      Object.entries(forms).forEach(([formId, formDetails = {}]) => {
        formsMap = formsMap.set(formId, formDetails);
      });

      state = state.setIn(['map', projectId], formsMap);
      state = state.setIn(['lastUpdated', projectId, formType], state.getNested(['lastUpdateAvailable', projectId, formType], Date.now()));
      
      return state;
    }

    case actions.END_FORMS_LISTENER_BY_TYPE : {
      if (action.payload && action.payload.projectId)
        state = state.setIn(['didLoad', action.payload.projectId, action.payload.formType || 'general'], false);
      return state;
    }

    case appActions.CLEAN_CACHE: {
      return state = initialState;
    }

    case REPLACE_USER + "_SUCCESS": {
      return initialState;
    }
  }

  return state;
}
